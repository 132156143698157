import { Colors } from "../colors/colors";

export const getOrderInvoiceColor = (orderInvoice) => {
  if (!orderInvoice) {
    return Colors.darkGray2;
  }

  if (
    !orderInvoice.valid_invoice?.issue_date &&
    !orderInvoice.invoice?.issue_date
  ) {
    return Colors.red;
  }

  return Colors.green;
};
