export const findPossibleProductFieldByMarket = (
  queuesOptions = [],
  marketId,
  products = [],
  field = "_id"
) =>
  queuesOptions
    .filter((option) => option.value.market === marketId)
    .map(
      (option) =>
        products.find((product) => product._id === option.value.product)[field]
    );
