import React, { useMemo, useState } from "react";
import { deleteQueueGroup } from "../../../../../../API/repositories/queueGroups";
import Loading from "../../../../../../common/components/Loading";
import PopUp from "../../../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../../../common/hooks/requestHook";
import AddGroupView from "../AddGroupView";
import Table from "../../../../../../common/components/Table";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const QueueGroups = ({
  queueGroups,
  users,
  handleGetQueueGroup,
  addMessage,
}) => {
  const [showAddGroup, setShowAddGroup] = useState(false);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const hadnleDeleteQueueGroup = async (e, id) => {
    const response = await makeRequest(deleteQueueGroup.bind(null, id));
    if (response.data) {
      handleGetQueueGroup();
    }
  };

  const handleCreateQueueGroup = (e) => {
    e.preventDefault();
    setShowAddGroup((prev) => !prev);
  };
  
  const raws = useMemo(() => {
    if (queueGroups) {
      return getRaws({
        queueGroups,
        users,
        setShowAddGroup,
        hadnleDeleteQueueGroup,
      })
    }
  }, [users]);

  return (
    <div>
      {hasUnfilledRequest(deleteQueueGroup) && <Loading />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 0px 20px 0px",
        }}
      >
        <h3>
          <u>Queue groups:</u>
        </h3>
        <button
          onClick={(e) => handleCreateQueueGroup(e)}
          className="btn btn-primary"
        >
          add
        </button>
      </div>
      <div>
        {queueGroups && (
          <Table
            className="styled-table styled-table-2"
            headers={HEADERS}
            raws={raws}
          />
        )}
      </div>
      {showAddGroup ? (
        <PopUp setShow={setShowAddGroup}>
          <AddGroupView
            close={() => setShowAddGroup(false)}
            handleGetQueueGroup={handleGetQueueGroup}
            savedGroup={showAddGroup}
            addMessage={addMessage}
            users={users}
          />
        </PopUp>
      ) : (
        ""
      )}
    </div>
  );
};

export default QueueGroups;
