import Table from "@/common/components/Table";
import React from "react";
import { getRaws } from "./helpers/getRaws";

const OrderLangStatsTable = ({ langStats }) => {
  return (
   <div style={{ maxHeight: "calc(100vh - 400px)", overflow: "scroll"}}>
    <Table
      className="styled-table sticky-header"
      headers={langStats[0]}
      raws={getRaws(langStats.slice(1))}
    />
   </div>
  );
};

export default OrderLangStatsTable;
