import { errorHandler } from "../common";
import Api from "../api";
const queryString = require("query-string");
const { tokenManager, axios: api } = Api;

export const getNumbersFromCloudTalk = () =>
  errorHandler(async () => {
    const data = await api.get(`/calling/get_numbers_from_cloud_talk`);
    return { data: data.data };
  });

export const findCallsByFilter = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/calling/find_calling_by_filter`, payload);
    return { data: data.data };
  });

export const findSMSByFilter = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/calling/find_sms_by_filter`, payload);
    return { data: data.data };
  });

export const getAllSMSTemplate = () =>
  errorHandler(async () => {
    const data = await api.get(`/calling/get_sms_template`);
    return { data: data.data };
  });

export const getCallingStatistics = () =>
  errorHandler(async () => {
    const data = await api.get("/calling/statistics");

    return { data: data.data };
  });

export const updateSMSTemplate = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/calling/update_sms_template/` + id, payload);
    return { data: data.data };
  });

export const createSMSTemplate = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/calling/create_sms_template`, payload);
    return { data: data.data };
  });

export const deleteSMSTemplate = (_id) =>
  errorHandler(async () => {
    const data = await api.delete(`/calling/delete_sms_template/` + _id);
    return { data: data.data };
  });

export const getCallingDayConsultantStatistics = () =>
  errorHandler(async () => {
    const data = await api.get(
      `/calling/get-calling-day-consultant-statistics/`
    );
    return { data: data.data };
  });

export const getConsultantRecordedCalls = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/calling/get-consultant-recorded-calls/?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });
export const getConsultantManagerRecordedCalls = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/calling/get-consultant-manager-recorded-calls/?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

export const getAdminRecordedCalls = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/calling/get-admin-recorded-calls/?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
