import React from "react";
import { ButtonWrapper, SearchBar } from "../../Tickets.styled";
import SelectInput from "@/common/components/SelectInput";
import {
  TICKET_STATUS_OPTION,
  TICKET_TYPES,
} from "../../constants/ticketsOptions";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { getToday } from "@/common/functions/getToday";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const TicketsSearchBar = ({
  hadnleGetConsultantTickets,
  selectedTypes,
  setSelectedTypes,
  fromRef,
  toRef,
  setSelectedTicketStatuses,
  selectedTicketStatuses,
  setSelectedConsultantsSenders,
  selectedConsultantsSenders,
  setSelectedConsultantsRecivers,
  selectedConsultantsRecivers,
}) => {
  const {
    options: { usersOptions },
  } = useCommonDataContext();

  const today = getToday();

  return (
    <SearchBar onSubmit={(e) => hadnleGetConsultantTickets(e)}>
      <div>
        <SelectInput
          options={TICKET_TYPES}
          color={Colors.darkBlue}
          selected={selectedTypes}
          setSelected={setSelectedTypes}
          multiple
          name={"Type"}
          width={70}
          selectWidth={250}
          required
        />
        <Input
          color={Colors.darkBlue}
          inputRef={fromRef}
          width={70}
          name={"From"}
          type="date"
          inputWidth={250}
          value={today}
          required
          requiredSign
        />
        <Input
          color={Colors.darkBlue}
          inputRef={toRef}
          width={70}
          name={"To"}
          type="date"
          inputWidth={250}
          value={today}
          required
          requiredSign
        />
      </div>
      <div>
        <SelectInput
          width={100}
          color={Colors.darkBlue}
          name="Status"
          setSelected={setSelectedTicketStatuses}
          selected={selectedTicketStatuses}
          options={TICKET_STATUS_OPTION}
          multiple
          selectWidth={250}
        />
        <SelectInput
          width={100}
          color={Colors.darkBlue}
          name="Sender"
          setSelected={setSelectedConsultantsSenders}
          selected={selectedConsultantsSenders}
          options={usersOptions}
          selectWidth={250}
          multiple
        />
        <SelectInput
          width={100}
          color={Colors.darkBlue}
          name="Reciver"
          options={usersOptions}
          setSelected={setSelectedConsultantsRecivers}
          selected={selectedConsultantsRecivers}
          selectWidth={250}
          multiple
        />
      </div>
      <ButtonWrapper>
        <CustomButtonSave
          text="Search"
          styles={{ margin: 0 }}
          color={Colors.orange}
        />
      </ButtonWrapper>
    </SearchBar>
  );
};

export default TicketsSearchBar;
