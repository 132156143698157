import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../../../common/components/Card";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import DataTable from "./queueShowElements/DataTable";
import QueueGroups from "./queueShowElements/queueGroups/QueueGroups";
import QueueForm from "./queueShowElements/QueueForm";
import styled from "styled-components";
import QueuePoints from "./queueShowElements/QueuePoints";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import {
  getOneQueue,
  getQueuePoints,
  getQueueStack,
  getQueueCurrentWorkers,
} from "../../../../API/repositories/queue";
import { getQueueGroups } from "../../../../API/repositories/queueGroups";
import moment from "moment";
import QueueCurrentConsultant from "./queueShowElements/QueueCurrentConsultant";
import ChangeLog from "../../../../common/components/ChangeLog";
import Loading from "../../../../common/components/Loading";
import Circle from "../../../../common/components/Circle";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 30px;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const QUEUE_STACK_COL = [
  " ",
  "phone",
  "mail",
  "status",
  "created at",
  "dequeued count",
  "dequeued after",
  "order created at",
  "dequeued consultant",
];

const QueueShow = () => {
  const { id } = useParams();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const [queueStack, setQueueStack] = useState(null);
  const [queue, setQueue] = useState(null);
  const [queuePoints, setQueuePoints] = useState(null);
  const [queueGroups, setQueueGroups] = useState(null);
  const [changes, setChanges] = useState(null);
  const [currentConsultants, setCurrentConsultants] = useState(null);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { commonData, options } = useCommonDataContext();
  const { users } = commonData;
  const { marketsOptions, productsOptions, usersOptions } = options;

  const requests = [
    getOneQueue,
    getQueueGroups,
    getQueueStack,
    getQueuePoints,
    getQueueCurrentWorkers,
  ];

  const changeToTableDisplay = (data, usersData) => {
    let result = [];

    data.forEach((data, id) => {
      let element = [];

      element.push(id + 1);
      element.push(data._contact.phone_number);
      element.push(data._contact.email);
      element.push(data._order.status);
      element.push(moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"));
      element.push(data.dequeued_count);
      element.push(moment(data.dequeue_after).format("YYYY-MM-DD HH:mm:ss"));
      element.push(moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"));

      const currentUser = usersData.find(
        (user) => user._id === data.dequeue_consultant
      )?.username;

      element.push(currentUser ? currentUser : "--------");
      element.push(data._contact._id);
      element.push(data._order._id);

      result.push(element);
    });

    return result;
  };

  const handleGetQueueGroup = async () => {
    const response = await makeRequest(getQueueGroups.bind(null, id));
    if (response.data) {
      setQueueGroups(() => response.data);
    }
  };

  const handleGetCurrentConsulants = async () => {
    const response = await makeRequest(getQueueCurrentWorkers.bind(null, id));

    if (response.data) {
      setCurrentConsultants(() => response.data);
    }
  };

  const handleGetQueue = async () => {
    const response = await makeRequest(getOneQueue.bind(null, id));
    if (response.data) {
      setQueue(() => response.data);
      setChanges(() => response.data._changes);
    } else {
      console.log(response.error);
    }
  };

  const handleGetQueueStack = async () => {
    const response = await makeRequest(getQueueStack.bind(null, id));

    if (response.data) {
      setQueueStack(() => changeToTableDisplay(response.data, users));
    } else {
      console.log(response.error);
    }
  };

  const handleGetQueuePoints = async () => {
    const response = await makeRequest(getQueuePoints.bind(null, id));

    if (response.data) {
      setQueuePoints(() => response.data);
    } else {
      console.log(response.error);
    }
  };

  const loadData = async () => {
    const promisesArray = [
      handleGetCurrentConsulants,
      handleGetQueueStack,
      handleGetQueue,
      handleGetQueuePoints,
      handleGetQueueGroup,
    ];

    await Promise.all(promisesArray.map((promise) => promise()));
  };

  const refreshData = async () => {
    const promisesArray = [
      handleGetQueueStack,
      handleGetQueuePoints,
      handleGetCurrentConsulants,
    ];
    await Promise.all(promisesArray.map((promise) => promise()));
  };

  const getUsersForQueue = () => {
    return usersOptions.filter((user) =>
      queue.consultants.includes(user.value)
    );
  };

  useLayoutEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {((requests.some((request) => hasUnfilledRequest(request)) &&
        (!queue || !currentConsultants)) ||
        !marketsOptions.length ||
        !usersOptions.length ||
        !productsOptions.length) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {queue && (
        <Wrapper>
          <SearchBarWrapper>
            <QueueForm
              markets={marketsOptions}
              products={productsOptions}
              queue={queue}
              users={usersOptions}
              addMessage={addMessage}
              loadData={loadData}
            />
            <Circle refreshTime={60} getData={refreshData} />
          </SearchBarWrapper>
          <Flex>
            {queueGroups && (
              <Card>
                <QueueGroups
                  queueGroups={queueGroups}
                  users={getUsersForQueue()}
                  handleGetQueueGroup={() => handleGetQueueGroup()}
                  addMessage={addMessage}
                />
              </Card>
            )}
            {queuePoints && (
              <Card>
                <QueuePoints queuePoints={queuePoints} />
              </Card>
            )}
            <QueueCurrentConsultant currentConsultants={currentConsultants} />
          </Flex>
          {queueStack && (
            <Card>
              <h3>
                <u>Queue Stack:</u>
              </h3>
              {queueStack ? (
                <DataTable rows={queueStack} columns={QUEUE_STACK_COL} />
              ) : (
                ""
              )}
            </Card>
          )}
          {changes && changes.length > 0 && (
            <ChangeLog changes={changes} minWidth={900} />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default QueueShow;
