import { formatToDateTamplate } from "@/common/functions/dateFormater";

export const getRaws = (data) =>
  data.value.map((data) => (
    <tr>
      <td>{data.contract_type}</td>
      <td>
        {data.name} {data.surname}
      </td>
      <td>{formatToDateTamplate(data.from)}</td>
      <td>{formatToDateTamplate(data.to)}</td>
      <td> {data.hoursSum} h</td>
      <td>
        {data.sum} {data.currency}
      </td>
    </tr>
  ));
