import moment from "moment";

export const getLabel = (label, type, i) => {
  switch (type) {
    case "iterator":
      return i + 1;
    case "date":
      return label ? moment(label).format("YYYY-MM-DD HH:mm") : "-----";
    case "number":
      return label;
    default:
      return label || "-----";
  }
};
