import CSV from "@/common/components/CSV";
import { countDelivered } from "./countDelivered";
import { countReturned } from "./countReturned";
import { findMarketFromOptions } from "./findMarketFromOptions";
import { findProductById } from "./findProductById";
import { HEADERS_CSV } from "../constants/headers";
import { generateData } from "./generateData";

export const getRaws = (data, marketsOptions, products) =>
  data.map((element) => (
    <tr>
      <td>
        {findMarketFromOptions(element.value[0].market, marketsOptions).label}
      </td>
      <td>{findProductById(element.value[0].product, products).name}</td>
      <td>{element.value.length}</td>
      <td>{countReturned(element)}</td>
      <td>{countDelivered(element)}</td>
      <td>
        <CSV
          filename="sent-orders-report.csv"
          header={HEADERS_CSV}
          data={generateData(element, marketsOptions, products)}
        />
      </td>
    </tr>
  ));
