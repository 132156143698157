import { COLORS_ARRAY } from "@/common/colors/colorsArray";
import { round } from "@/common/functions/round";

export const createDataSets = (data, markets) => {
  return markets.map((c, i) => {
    const filtredData = data.map((d) => d.result[c._id]);

    const marketData = filtredData.map(
      (fd) => round(round(fd?.cod_delivered_sum) / round(fd?.contacts)) || null
    );
    return {
      label: c.name,
      data: marketData,
      borderColor: COLORS_ARRAY[i],
      backgroundColor: COLORS_ARRAY[i],
      yAxisID: "y",
    };
  });
};
