import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import Card from "./Card";

const Flex = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const navigateToCloudTalkLink = (e, link) => {
  e.preventDefault();
  window.open(link, { traget: "_blank" });
};

const OrderCallingElement = ({ data }) => {
  return (
    <Flex>
      <p>
        Consultant: <b>{data._user?.username || "------"}</b>{" "}
      </p>
      <p>
        Started at:{" "}
        <b>{moment(data.started_at).format("YYYY-MM-DD HH:mm:ss")}</b>{" "}
      </p>
      <p>
        Ended at: <b>{moment(data.ended_at).format("YYYY-MM-DD HH:mm:ss")}</b>
      </p>
      <p>
        Call Center number: <b>{data.call_center_phone_number}</b>{" "}
      </p>
      {data.recording_link && (
        <p>
          Recording:
          <b
            className="animation-scale"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={(e) => navigateToCloudTalkLink(e, data.recording_link)}
          >
            Go cloudTalk
          </b>
        </p>
      )}
    </Flex>
  );
};

const CallingInfo = ({ callings }) => {
  return (
    <Card>
      <h4 style={{ marginBottom: "10px" }}>
        <u>Calling hisotry:</u>
      </h4>
      {callings
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .map((calling) => (
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "80px",
                fontSize: "14px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                textAlign: "center",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  position: "relative",
                  border: "1px solid black",
                  top: "5px",
                  left: "76.00px",
                  borderRadius: "50%",
                }}
              ></div>
              {moment(calling.created_at).format("HH:mm:ss")}
            </div>
            <div
              style={{
                borderLeft: "1px solid black",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              <div>
                {moment(calling.created_at).format("YYYY-MM-DD")}
                <b
                  style={{
                    color: calling.type === "outgoing" ? "green" : "red",
                  }}
                >
                  {calling.type}
                </b>
              </div>
              <div>
                <OrderCallingElement data={calling} />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  padding: "10px 20px 10px 10px",
                }}
              ></div>
            </div>
          </div>
        ))}
    </Card>
  );
};

export default CallingInfo;
