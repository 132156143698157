import React, { useRef, useState } from "react";
import { ChartWrapper, TitleSection, Wrapper } from "../shared.styles";
import Input from "@/common/components/Input";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { getCPLStats } from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import CustomLine from "@/common/charts/Line";
import { Icon, SubTitle } from "./CPLStats.styled";

const CPLStats = () => {
  const [chartData, setChartData] = useState();

  const timeRangeRef = useRef();

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);

    if (!parseInt(timeRangeRef.current?.value)) return;

    const payload = {};

    payload.days = timeRangeRef.current.value;

    const response = await makeRequest(getCPLStats.bind(null, payload));

    if (response.data) {
      setChartData(response.data);
    }
  };

  return (
    <Wrapper width={800}>
      <TitleSection>
        <SubTitle>
          <i className="fa fa-money" />
          <div>Cost per lead (Netto)</div>
        </SubTitle>
        <SubTitle>
          <Input
            inputRef={timeRangeRef}
            inputWidth={50}
            showLabel={false}
            value={0}
          />
          <Icon
            className="fa-solid fa-arrows-rotate animation-scale"
            onClick={() => handleLoadData()}
          />
        </SubTitle>
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <CustomLine data={chartData} />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default CPLStats;
