import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const HtmlEditorWrapper = styled.div`
  display: flex;
  width: 900px;
`;

export const HtmlEditorTextArea = styled.textarea`
  border: ${({ htmlError }) => (htmlError ? "2px solid red" : "2px solid gray")};
  outline: none;
  height: 550px;
  resize: none;
  width: 50%;
  padding: 8px;
`;

export const HtmlEditorPreview = styled.div`
  background-color: ${Colors.lightGray};
  border: 2px solid gray;
  width: 50%;
  padding: 8px;
  overflow: scroll;
  height: 550px;
  border-left: none;
`;
