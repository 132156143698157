import Card from '@/common/components/Card'
import React from 'react'
import { ButtonWrapper, SearchBar, Title } from '../../ValidClaims.styled'
import SelectInput from '@/common/components/SelectInput'
import { Colors } from '@/common/colors/colors'
import { CLAIM_ROLE_STATUSES } from '@/common/constants/claims'
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import userManager from '@/API/userManager'

const ClaimSearchBar = ({
  selectedMarkets,
  setSelectedMarkets,
  selectedStatus,
  setSelectedStatus,
  handleSearch,
}) => {
  const { options: { marketsOptions } } = useCommonDataContext();

  return (
    <Card>
      <SearchBar>
        <div>
          <Title>Eou Sp. z o.o.</Title>
        </div>
        <div style={{ display: 'flex'}}>
          <SelectInput
            name="Markets"
            width={70}
            selectWidth={725}
            color={Colors.darkBlue}
            options={marketsOptions}
            setSelected={setSelectedMarkets}
            selected={selectedMarkets}
            multiple
          />
          <SelectInput
            name="Status"
            width={70}
            selectWidth={400}
            color={Colors.darkBlue}
            options={Object.values(CLAIM_ROLE_STATUSES[userManager.getUser().role])}
            setSelected={setSelectedStatus}
            selected={selectedStatus}
          />
        </div>
        <ButtonWrapper>
          <CustomButtonAdd defaultText="Search" onClick={() => handleSearch()} />
        </ButtonWrapper>
      </SearchBar>
    </Card>
  )
}

export default ClaimSearchBar
