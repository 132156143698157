import Api from "../api";
import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const addHoursByDateRange = (payload) =>
  errorHandler(async () => {
    const data = await api.post(
      "/consultant-hour/add-by-hours-range-admin",
      payload
    );
    return { data: data.data };
  });

export const addHoursByDateRangeConsultant = (payload) =>
  errorHandler(async () => {
    const data = await api.post(
      "/consultant-hour/add-by-hours-range",
      payload
    );
    return { data: data.data };
  });

export const saveConsultantHours = (hours, hoursDate) =>
  errorHandler(async () => {
    const data = await api.post("/consultant-hour/worked", {
      hours,
      hoursDate,
    });
    return { data: data.data };
  });

export const saveConsultantHoursByManager = (hours, hoursDate, consultantId) =>
  errorHandler(async () => {
    const data = await api.post("/consultant-hour/worked-by-manager", {
      hours,
      hoursDate,
      consultantId,
    });
    return { data: data.data };
  });

export const consultantHours = () =>
  errorHandler(async () => {
    const data = await api.get("/consultant-hour/consultant-hours");
    return { data: data.data };
  });

export const consultantHoursLastMonth = () =>
  errorHandler(async () => {
    const data = await api.get("/consultant-hour/consultant-hours-last-month");
    return { data: data.data };
  });

export const addHoursForDay = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/consultant-hour/add-hours-for-day", payload);

    return { data: data.data };
  });

export const getHoursForCCManager = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      "/consultant-hour/manager-search/?" +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

export const acceptConsultantMonthHours = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/consultant-hour/manager-accept", {
      payload,
    });
    return { data: data.data };
  });

export const findMonthInfoWithPayoutSlips = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/consultant-hour/payout-slips?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
