import React from "react";
import CustomSkeleton from "./CustomSkeleton";

const Table = ({
  headers,
  loading,
  headersArray,
  raws = [],
  className,
  style = {},
  noDataCols,
  stickyHeader,
}) => {
  return (
    <table
      className={className}
      style={
        !loading
          ? { ...style, position: "relative" }
          : { width: "800px", height: "390px", position: "relative" }
      }
    >
      <thead style={stickyHeader ? { position: "sticky", top: 0 } : {}}>
        {headersArray?.map((element, i) => (
          <tr key={i}>
            {element.map((header, j) => (
              <React.Fragment key={j}>{header}</React.Fragment>
            ))}
          </tr>
        ))}
        {headers && (
          <tr>
            {headers?.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        )}
      </thead>
      <tbody className="queue">
        {loading ? (
          <tr>
            <td
              colSpan={
                noDataCols ||
                headers?.length ||
                headersArray[1]?.length ||
                headersArray[0]?.length
              }
            >
              <CustomSkeleton />
            </td>
          </tr>
        ) : raws[0] ? (
          raws.map((raw) => raw)
        ) : (
          <tr>
            <td
              colSpan={
                noDataCols ||
                headers?.length ||
                headersArray[1]?.length ||
                headersArray[0]?.length
              }
            >
              There is no data
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
