import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  gap: 10px;
`;

const THead = styled.thead`
  th {
    background-color: #7b0b8c;
  }
`;

const TBody = styled.tbody`
  td {
    background-color: #ffefd0;
  }
`;

const CallingNow = ({ data = [] }) => {
  const navigate = useNavigate();

  return (
    <table
      className="styled-table "
      style={{ minWidth: "650px", maxWidth: "650px" }}
    >
      <THead>
        <tr>
          <th colSpan={7}>Calling Users</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>Coun.</th>
          <th>Username</th>
          <th>Number</th>
          <th>Calling at</th>
          <th>Actions</th>
        </tr>
      </THead>
      <TBody className="queue">
        {data.length > 0 ? (
          data
            .sort(
              (a, b) =>
                new Date(b.calling_at).getTime() -
                new Date(a.calling_at).getTime()
            )
            .map((element, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{element.country}</td>
                <td>{element.username}</td>
                <td>
                  {element.phone_number
                    .match(/.{1,3}/g)
                    .map((substring) => substring + " ")}
                </td>
                <td>{moment(element.calling_at).format("DD/MM HH:mm:ss")}</td>
                <td>
                  <IconsWrapper>
                    <i
                      className="fa fa-user animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/settings/user/show/" + element.consultant)
                      }
                    />
                    <i
                      className="fa fa-address-book animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/dashboard/contact/" + element.contact)
                      }
                    />
                    <i
                      className="fa fa-address-card animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/dashboard/order/" + element.order)
                      }
                    />
                  </IconsWrapper>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={7}>There is no users</td>
          </tr>
        )}
      </TBody>
    </table>
  );
};

export default CallingNow;
