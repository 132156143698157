export const getMessagePlaceHolder = (selectedTicket, isDisableCommunication) => {
  if (!selectedTicket) {
    return "Select ticket to start conversation";
  }

  if (isDisableCommunication) {
    return "Change ticket status to start conversation";
  }

  return "Type your message here...";
} 