import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper } from './File.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import MessageQueue, { useMessageQueue } from '@/common/messageProvider';
import CallCenterPositionForm from './components/fileForm/FileForm';
import CallCenterPositionTable from './components/fileTable/FileTable';
import { getAllFiles } from '@/API/repositories/file';
import { getFileById } from '@/API/repositories/storedDocument';

const File = () => {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState([]);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const loadPages = async () => {
    setFiles([]);
    const response = await makeRequest(getAllFiles);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setFiles(response.data);
  };

  const handleDownloadFile = async (file) => {
    const { _id: id, extension, filename, stored_document } = file;
    setLoadingFiles((prev) => [...prev, id]);

    const response = await makeRequest(getFileById.bind(null, stored_document));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.${extension}`);
      document.body.appendChild(link);
      link.click();
    }

    setLoadingFiles((prev) => prev.filter((el) => el !== id));
  };


  useEffect(() => {
    loadPages();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getAllFiles) && <Loading />}
      <ContentWrapper>
        <CustomButtonAdd 
          defaultText="Add file" 
          onClick={() => {
            setSelectedFile(null)
            setShow(true)
          }}  
        />
        <MessageQueue removeMessage={removeMessage} messages={messages} />
      </ContentWrapper>
      {show && <CallCenterPositionForm selectedFile={selectedFile} reload={loadPages} addMessage={addMessage} setShow={setShow} />}
      {!!files?.length && (
        <CallCenterPositionTable 
          loadingFiles={loadingFiles}
          handleDownloadFile={handleDownloadFile} 
          setSelectedFile={setSelectedFile} 
          setShow={setShow} 
          files={files} 
        />
      )}
    </PageWrapper>
  )
}

export default File
