import styled from "styled-components";
import { Colors } from "@/common/colors/colors";
import { BORDER_RADIUS } from "../../constants/borderRadius";

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background-color: white;
  min-height: 200px;
`;

export const TabWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 60px;
  background-color: ${(props) => (props.selected ? Colors.lightGray : "white")};
  border-top-left-radius: ${(props) => (props.top ? BORDER_RADIUS : 0)};
  border-bottom-left-radius: ${(props) => (props.bottom ? BORDER_RADIUS : 0)};
`;

export const Tab = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => (props.selected ? Colors.darkBlue : Colors.blue)};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  background-color: ${(props) => (props.selected ? "white" : Colors.lightGray)};
  border-bottom-left-radius: ${(props) =>
    props.bottomLeft ? BORDER_RADIUS : 0};
  border-bottom-right-radius: ${(props) =>
    props.bottomRight ? BORDER_RADIUS : 0};
  border-top-left-radius: ${(props) => (props.topLeft ? BORDER_RADIUS : 0)};
  border-top-right-radius: ${(props) => (props.topRight ? BORDER_RADIUS : 0)};
`;

export const RotatedText = styled.span`
  rotate: -90deg;
`;
