import Icon from "@/common/components/Icon";
import { LimitedSizeTableCell } from "../DynamicMailContentTable.styled";

export const getRaws = (dynamicMailContent, handleSetDepartmentEdit) =>
  dynamicMailContent?.map((mail, i) => (
    <tr key={mail._id}>
      <td>{i + 1}</td>
      <td>{mail.title}</td>
      <LimitedSizeTableCell>
        {mail.subject}
      </LimitedSizeTableCell>
      <td>
        <Icon
          onClick={() => handleSetDepartmentEdit(mail)}
          name="fa fa-edit"
        />
      </td>
    </tr>
  ));
