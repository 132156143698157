export const SEARCHING_STATUS_OPTIONS = [
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Shift",
    value: "shift",
  },
  {
    label: "Resigned",
    value: "resigned",
  },
  {
    label: "Trash",
    value: "trash",
  },
  {
    label: "No response",
    value: "no_response",
  },
  {
    label: "Contact",
    value: "",
  },
];
