import { Colors } from "@/common/colors/colors";
import React from "react";
import styled from "styled-components";
import CustomButtonSave from "../buttons/CustomButtonSave";

const Wrapper = styled.form`
  border-radius: 10px;
  background-color: ${Colors.lightGray};
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  min-width: 800px;
  padding: 5px 40px;
  font-style: italic;

  input {
    border: none;
    color: ${Colors.darkBlue};
  }

  label: {
    width: fit-content;
  }
`;

const SearchWrapper = ({ children, onSubmit }) => {
  return (
    <Wrapper onSubmit={onSubmit} className="fade__in__animation">
      {children}
      <CustomButtonSave text="Search" styles={{ marginBottom: "0" }} />
    </Wrapper>
  );
};

export default SearchWrapper;
