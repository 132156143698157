import { DEFAULT_FILTER } from "../constants/filter";

export const filterReducer = (state, action) => {
	if (action.type === 'RESET') {
		return DEFAULT_FILTER;
	}

	return {
		...state,
		[action.type]: action.payload,
	}
};