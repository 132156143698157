import React, { useMemo } from "react";
import Table from "@/common/components/Table";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const WorkTimeTable = ({ data }) => {
  const { commonData: { queues }} = useCommonDataContext();

  const raws = useMemo(() => {
    if (data) {
      return getRaws(data, queues);
    }
  }, [data]);

  return <Table className="styled-table" headers={HEADERS} raws={raws} />;
};

export default WorkTimeTable;
