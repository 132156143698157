import { updateListeningScoring } from "@/API/repositories/listeningScoring";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import TextArea from "@/common/components/TextArea";
import { SCORING_OPTIONS } from "@/common/constants/scoringOptions";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { scoreToOption } from "@/pages/admin/listeningScores/helpers/scoreToOption";
import React, { useState } from "react";

const Scoring = ({
  setShowEditCurrent,
  showEditCurrent,
  handleLoadData,
  setShowEdit,
}) => {
  const [editScoring, setEditScoring] = useState(showEditCurrent.scores);
  const { makeRequest } = useRequestsContext();

  const handleUpdateOnChange = (id, option) => {
    const toChange = editScoring.find((s) => s.score_id === id) || {};

    if (!toChange.grade) {
      toChange.label = "Additional Info";
      toChange.score_id = id;
    }

    toChange.grade = option.value;

    setEditScoring((prev) => [
      ...(prev.filter((c) => c.score_id !== id) || []),
      toChange,
    ]);
  };

  const handleUpdateScore = async (e) => {
    const payload = {};
    payload._id = showEditCurrent._id;
    payload.scores = editScoring;

    const response = await makeRequest(
      updateListeningScoring.bind(null, payload)
    );

    if (response.data) {
      await handleLoadData();
      setShowEditCurrent(() => null);
      setShowEdit(() => null);
    }
  };

  return (
    <PopUp setShow={setShowEditCurrent}>
      <table className="styled-table">
        <thead>
          <tr>
            <th colSpan={2}>
              Rating Call - Yes = 1, No = 0 - Explain here if 0 or not
              applicable (na)
            </th>
          </tr>
          <tr>
            <th>Description</th>
            <th>Mark</th>
          </tr>
        </thead>
        <tbody className="queue">
          {showEditCurrent.scores
            .filter((score) => score.score_id !== "additional_info")
            .map((element, i) => (
              <tr key={element.score_id + i}>
                <td>{element.label}</td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <SelectInput
                    showLabel={false}
                    selectWidth={160}
                    selected={scoreToOption(
                      editScoring.find(
                        (savedScore) => savedScore.score_id === element.score_id
                      )
                    )}
                    setSelected={handleUpdateOnChange.bind(
                      this,
                      element.score_id
                    )}
                    id={element.score_id}
                    options={SCORING_OPTIONS}
                  />
                </td>
              </tr>
            ))}
          <tr>
            <td>Additional Info</td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <TextArea
                width="400px"
                onChange={(e) =>
                  handleUpdateOnChange("additional_info", e.target)
                }
                id={"additional_info"}
                defaultValue={
                  showEditCurrent?.scores?.find(
                    (s) => s.score_id === "additional_info"
                  )?.grade
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "30px",
          marginBottom: "-20px",
        }}
      >
        <button className="btn btn-warning" onClick={() => handleUpdateScore()}>
          Update
        </button>
      </div>
    </PopUp>
  );
};

export default Scoring;
