import { STARS_PERCENTAGE } from "../../../../components/user/components/orders/constants/orders";

export const getStarCoverWidth = ({
  maximumStarsAmount,
  prev = 0,
  totalAmount,
  step,
  orderWidth,
}) => {
  return (
    (orderWidth / maximumStarsAmount) *
    STARS_PERCENTAGE *
    (totalAmount >= step ? 1 : totalAmount > prev ? totalAmount / step : 0)
  );
};
