import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import {
  SHIPPING_ON_THE_WAY_STATUSES,
} from "@/common/constants/shipping";
import AdditionalOrderCreate from "@/common/components/additionalOrderCreate/AdditionalOrderCreate";
import { TableItemHover } from "@/common/styles/Hover";
import Icon from "@/common/components/Icon";
import { getOrderInvoiceColor } from "@/common/functions/getOrderInvoiceColor";
import MailsTimeline from "@/components/mailsTimeline/MailsTimeline";
import ShippingTimeline from "@/components/shippingTimeline/ShippingTimeline";
import { addMailConditionalStyles } from "@/common/functions/addMailConditionalStyles";
import { addShippingConditionalStyles } from "@/common/functions/addShippingConditionalStyles";
import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";

const Title = styled.h2`
  color: ${Colors.darkBlue};
  text-align: center;
  display: flex;
  justify-content: space-between;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AddOrdersWrapper = styled.div`
  display: flex;
  margin: 20px;
  justify-content: flex-end;
  gap: 20px;
`;

const OrdersInBound = ({
  dataToDisplay,
  handleShowOrderEdit,
  translation,
  reloadFn,
}) => {
  const [showAdditionalOrderCreate, setShowAdditionalOrderCreate] = useState();
  const [mailsTimeline, setMailsTimeline] = useState();
  const [shippingTimeline, setShippingTimeline] = useState();

  const handleShowAdditionalOrderCreate = () => {
    setShowAdditionalOrderCreate(true);
  };

  const checkOnTheWay = (order) =>
    SHIPPING_ON_THE_WAY_STATUSES.includes(order?.shipping?.status);

  const handleCheckAddOrder = () =>
    !dataToDisplay?.orders?.some((order) => checkIsOrderEditable(order)) &&
    !dataToDisplay?.orders?.some((order) => checkOnTheWay(order));

  const handleCheckAdditionalOrder = () =>
    !dataToDisplay?.orders?.some((order) => checkIsOrderEditable(order)) &&
    dataToDisplay?.orders?.some((order) => checkOnTheWay(order));

  const isCzechWithoutPromotion = () =>
    dataToDisplay?.country === "cz" && !dataToDisplay?.hasPromotion;

  return (
    <div>
      <div>
        <Flex>
          <Title>{translation.orders}:</Title>
          <AddOrdersWrapper>
            {handleCheckAdditionalOrder() && (
              <CustomButtonAdd
                onClick={() => handleShowAdditionalOrderCreate()}
                disabled={isCzechWithoutPromotion()}
                defaultText="Add additional order"
              />
            )}
            {handleCheckAddOrder() && (
              <CustomButtonAdd
                onClick={(e) => handleShowOrderEdit(e, {}, true)}
                disabled={isCzechWithoutPromotion()}
                defaultText="Add Order"
              />
            )}
          </AddOrdersWrapper>
        </Flex>
        <div>
          <table className="styled-table" style={{ margin: "0px" }}>
            <thead>
              <tr>
                <th> {translation.no}</th>
                <th> {translation.status}</th>
                <th> {translation.currency}</th>
                <th> {translation.product}</th>
                <th> {translation.variants}</th>
                <th> {translation.price}</th>
                <th> {translation.shippingStatus}</th>
                <th>{translation.timeline}</th>
                <th>{translation.actions}</th>
              </tr>
            </thead>
            <tbody className="queue">
              {dataToDisplay?.orders.map((data, i) => (
                <tr>
                  <td>{i + 1}.</td>
                  <td>{data.status}</td>
                  <td> {data.currency}</td>
                  <td>{data._product.name}</td>
                  <td>
                    {data.order_items?.length > 0
                      ? data.order_items
                          .map((item) => item.product_variant)
                          .join(", ")
                      : "--------"}
                  </td>
                  <td>
                    {data.order_items?.length > 0
                      ? data.order_items.reduce(
                          (prev, next) => prev + next.price,
                          0
                        )
                      : "------"}
                  </td>
                  <td>
                    {data.shipping?.status === "delivered_no_payment"
                      ? "delivered"
                      : data.shipping?.status || "------"}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <TableItemHover label={translation.invoice_status}>
                        <Icon
                          color={getOrderInvoiceColor(data?._invoice)}
                          cursor="default"
                          name="fa fa-file-invoice"
                        />
                      </TableItemHover>
                      <TableItemHover label={translation.mail_history}>
                        <Icon
                          onClick={() => setMailsTimeline(data?._mails)}
                          name="fa fa-envelope"
                          {...addMailConditionalStyles(data)}
                        />
                      </TableItemHover>
                      <TableItemHover label={translation.shipping_history}>
                        <Icon
                          onClick={() => setShippingTimeline(data?.shipping)}
                          name="fa fa-truck"
                          {...addShippingConditionalStyles(data)}
                        />
                      </TableItemHover>
                    </div>
                  </td>
                  <td>
                    {checkIsOrderEditable(data) &&
                    !isCzechWithoutPromotion() ? (
                      <div onClick={(e) => handleShowOrderEdit(e, data, false)}>
                        <i
                          className="fa fa-edit animation-scale"
                          onClick={(e) => handleShowOrderEdit(e, data, false)}
                          style={{
                            cursor: "pointer",
                            color: Colors.darkBlue,
                          }}
                        />
                      </div>
                    ) : data?.payment?.length > 0 ? (
                      <div onClick={(e) => handleShowOrderEdit(e, data, false)}>
                        <i
                          className="fa fa-edit animation-scale"
                          onClick={(e) => handleShowOrderEdit(e, data, false)}
                          style={{
                            cursor: "pointer",
                            color: Colors.darkBlue,
                          }}
                        />
                      </div>
                    ) : (
                      "------"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showAdditionalOrderCreate && (
        <AdditionalOrderCreate
          setShow={setShowAdditionalOrderCreate}
          orderById={
            dataToDisplay?.orders?.find((order) => checkOnTheWay(order))?._id
          }
          reloadFn={reloadFn}
        />
      )}
      {mailsTimeline?.length && (
        <MailsTimeline setShow={setMailsTimeline} mails={mailsTimeline} />
      )}
      {shippingTimeline && (
        <ShippingTimeline
          setShow={setShippingTimeline}
          shipping={shippingTimeline}
        />
      )}
    </div>
  );
};

export default OrdersInBound;
