import React, { useEffect, useRef, useState } from "react";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import { getLastMarketDeliveirty } from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import Input from "@/common/components/Input";
import CustomLine from "@/common/charts/Line";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import { OPTIONS } from "./constants/options";
import * as moment from "moment";

const TIME_TO = moment().weekday() > 4 ? 1 : 2;

const DeliveryMarket = () => {
  const [selectedDate, setSelectedDate] = useState(OPTIONS[0]);
  const [chartData, setChartData] = useState();
  const {
    commonData: { products },
  } = useCommonDataContext();
  const timeRangeRef = useRef();
  const sentRef = useRef();
  const resignedRef = useRef();

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);

    const payload = {};
    payload.timeFrom = timeRangeRef.current.value;
    payload.timeTo = TIME_TO;
    payload.date = selectedDate.value;
    payload.isSent = sentRef.current?.checked;
    payload.resigned = resignedRef.current?.checked;

    const response = await makeRequest(
      getLastMarketDeliveirty.bind(null, payload)
    );

    if (response.data) {
      setChartData(() =>
        response.data
      );
    }
  };

  useEffect(() => {
    if (products[0]) handleLoadData();
  }, [products]);

  return (
    <Wrapper width={800}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa-brands fa-usps" />
          Weekly market deliverability (%)
        </TopContentWrapper>
        <TopContentWrapper>
          <SelectInput
            selected={selectedDate}
            setSelected={setSelectedDate}
            options={OPTIONS}
            showLabel={false}
            selectWidth={200}
          />
          <Input
            inputRef={sentRef}
            name="sent"
            type="checkbox"
            inputWidth={20}
            width={30}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={resignedRef}
            name="no resign"
            type="checkbox"
            inputWidth={20}
            width={90}
            color={Colors.darkBlue}
          />
          <Input
            showLabel={false}
            inputWidth={50}
            value={TIME_TO}
            inputRef={timeRangeRef}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <CustomLine
            data={chartData}
            options={{
              scales: {
                y: {
                  type: "linear",
                  display: true,
                  position: "left",
                },
              },
            }}
          />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default DeliveryMarket;
