import Icon from "@/common/components/Icon";
import { FilterItem } from "../ValidClaimsFeedbackReport.styled";
import { FILTER_ACTIONS } from "../constants/filter";
import { Colors } from "@/common/colors/colors";

export const getHeadersArray = (filter, setOpenAction) => (
	[
		[
			<th>No.</th>,
			<th>
				<FilterItem>
					Market 
					<Icon 
						onClick={() => setOpenAction(FILTER_ACTIONS.SET_MARKETS)} 
						color={filter[FILTER_ACTIONS.SET_MARKETS]?.length
							? Colors.orange
							: "#fff"
						}
						name="fa fa-filter" 
					/>
				</FilterItem>
			</th>,
			<th>
				<FilterItem>
					Product 
					<Icon 
						onClick={() => setOpenAction(FILTER_ACTIONS.SET_PRODUCTS)} 
						color={filter[FILTER_ACTIONS.SET_PRODUCTS]?.length
							? Colors.orange
							: "#fff"
						} 
						name="fa fa-filter" 
					/>
				</FilterItem>
			</th>,
			<th>Sell date</th>,
			<th>Client name</th>,
			<th>
				<FilterItem>
					Claim type 
					<Icon 
						onClick={() => setOpenAction(FILTER_ACTIONS.SET_CLAIM_TYPES)} 
						color={filter[FILTER_ACTIONS.SET_CLAIM_TYPES]?.length 
							? Colors.orange 
							: "#fff"
						} 
						name="fa fa-filter" 
					/>
				</FilterItem>
			</th>,
			<th>Claim entry date</th>,
			<th>Claim method</th>,
			<th>After delivery days</th>,
			<th>
				<FilterItem>
					Reason 
					<Icon 
						onClick={() => setOpenAction(FILTER_ACTIONS.SET_REASONS)} 
						color={filter[FILTER_ACTIONS.SET_REASONS]?.length 
							? Colors.orange 
							: "#fff"
						}
						name="fa fa-filter" 
					/>
				</FilterItem>
			</th>,
			<th>Feedback</th>,
			<th>
				<FilterItem>
					Tag 
					<Icon 
						onClick={() => setOpenAction(FILTER_ACTIONS.SET_TAGS)} 
						color={filter[FILTER_ACTIONS.SET_TAGS]?.length
							? Colors.orange
							: "#fff"
						}
						name="fa fa-filter" 
					/>
				</FilterItem>
			</th>
		]
	]
);
