import React, { useEffect, useMemo } from "react";
import PopUp from "../../../../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../../../../common/colors/colors";
import { round } from "../../../../../../common/functions/round";
import { preventFn } from "@/common/functions/preventEvent";

const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListeningObjections = ({ setShow, ticket, recording }) => {
  const mediaPlayer = useMemo(
    () => (
      <audio
        controlsList={"nodownload"}
        controls="controls"
        source
        src={[recording]}
        type="audio/mp3"
      />
    ),
    [recording]
  );

  useEffect(() => {
    document.addEventListener("contextmenu", preventFn);

    return () => document.removeEventListener("contextmenu", preventFn);
  }, []);

  return (
    <PopUp setShow={setShow}>
      <Wrapper>
        <Title>
          Number:{" "}
          {ticket.calling_information[0].type === "outgoing"
            ? ticket.calling_information[0].to
            : ticket.calling_information[0].from}{" "}
          ({round(ticket.listeningScoring[0].score)}%)
        </Title>
        {mediaPlayer}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={2}>
                Rating Call - Yes = 1, No = 0 - Explain here if 0 or not
                applicable (na)
              </th>
            </tr>
            <tr>
              <th>Description</th>
              <th>Mark</th>
            </tr>
          </thead>
          <tbody>
            {ticket.listeningScoring[0].scores.map((ti) => (
              <tr key={ti.score_id}>
                <td>{ti.label}</td>
                <td style={{ minWidth: "180px" }}>{ti.grade}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Wrapper>
    </PopUp>
  );
};
