import Api from "../api";
import { errorHandler } from "../common";

const { tokenManager, axios: api } = Api;
const queryString = require("query-string");

export const getFiltersForUser = () =>
  errorHandler(async () => {
    const data = await api.get(`/filter/order/`);
    return { data: data.data };
  });

export const createFilter = (filterData) =>
  errorHandler(async () => {
    const data = await api.post(`/filter/order/`, {
      ...filterData,
    });
    return { data: data.data };
  });

export const updateFilter = (_id, filterData) =>
  errorHandler(async () => {
    const data = await api.patch(`/filter/order/` + _id, {
      ...filterData,
    });
    return { data: data.data };
  });
