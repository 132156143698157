import React, { useEffect, useMemo, useState } from "react";
import {
  NameWrapper,
  TableWrapper,
  TopWrapper,
  Wrapper,
} from "./ManagerStatsTable.styled";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getMarketWppkReportForConsultant } from "@/API/repositories/reports";
import moment from "moment";
import { useRequestsContext } from "@/common/hooks/requestHook";
import userManager from "@/API/userManager";
import { findUserById } from "@/common/functions/findUserById";
import Table from "@/common/components/Table";
import Icon from "@/common/components/Icon";
import { getHeaders } from "./helpers/getHeaders";
import { getRaws } from "./helpers/getRaws";
import { findSuperior } from "@/common/functions/findSuperior";

const ManagerStatsTable = ({ translations }) => {
  const [tableData, setTableData] = useState();
  const currentUserId = userManager.getUser().id;

  const {
    commonData: { queues, users },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setTableData(null);

    const allConsultants = queues.reduce(
      (prev, next) => [...prev, ...next.consultants],
      []
    );
    const managerConsultants = allConsultants.filter(
      (consultantId) =>
        findSuperior(consultantId, users)?._id === currentUserId
    );

    const payload = {};

    payload.ids = managerConsultants;
    payload.from = moment().startOf("day");
    payload.to = moment().endOf("day");
    payload.weights = false;
    payload.type = ["inbound", "outbound"];

    const response = await makeRequest(
      getMarketWppkReportForConsultant.bind(null, payload)
    );

    if (!response?.data) {
      return;
    }

    setTableData(Object.entries(response.data));
  };

  const headers = useMemo(() => {
    return getHeaders(translations);
  }, [translations]);

  const raws = useMemo(() => {
    if (tableData) {
      return getRaws(tableData, users);
    }
  }, [tableData, users]);

  useEffect(() => {
    handleLoadData();
  }, [users, queues]);

  return (
    <Wrapper>
      <TopWrapper>
        <NameWrapper>
          <h3>{translations.team_stats}</h3>
          <Icon
            size={30}
            color={Colors.darkBlue}
            cursor="default"
            name="fa fa-table"
          />
        </NameWrapper>
      </TopWrapper>
      <TableWrapper>
        {raws && (
          <Table
            style={{
              width: "100%",
              height: "100%",
            }}
            className="styled-table sticky-header"
            headers={headers}
            raws={raws}
          />
        )}
      </TableWrapper>
    </Wrapper>
  );
};

export default ManagerStatsTable;
