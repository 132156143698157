import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createInvoiceTemplate = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/invoice-template/create", payload);

    return { data: data.data };
  });

export const updateInvoiceTemplate = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/invoice-template/update/${id}`, payload);

    return { data: data.data };
  });

export const getAllInvoiceTemplates = () =>
  errorHandler(async () => {
    const data = await api.get("/invoice-template/get-all");

    return { data: data.data };
  });
