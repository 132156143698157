import React, { useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { ButtonWrapper } from "../../MetaAccountForm.styled";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { FormContentWrapper } from "./AddAddsAccount.styled";

const AddAddsAccount = ({ setIsAddAddsAccountsOpen, setAddsAccounts }) => {
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const nameRef = useRef();
  const accountIdRef = useRef();

  const {
    options: { marketsOptions, productsOptions },
  } = useCommonDataContext();

  const handleAddAddsAccount = (e) => {
    e && e.preventDefault();
    setIsAddAddsAccountsOpen(() => false);

    const newAddAccount = {};
    newAddAccount.name = nameRef.current?.value;
    newAddAccount.add_account_id = accountIdRef.current?.value;
    newAddAccount.market = selectedMarket.value;
    newAddAccount.product = selectedProduct.value;
    setAddsAccounts((prev) => [...(prev || []), newAddAccount]);
  };

  return (
    <PopUp setShow={setIsAddAddsAccountsOpen}>
      <form onSubmit={(e) => handleAddAddsAccount(e)}>
        <FormContentWrapper>
          <Input
            name="Name"
            color={Colors.darkBlue}
            width={100}
            required
            inputRef={nameRef}
            placeholder="Type account name..."
          />
          <Input
            name="Account id"
            color={Colors.darkBlue}
            width={100}
            required
            inputRef={accountIdRef}
            placeholder="Type account id..."
          />
          <SelectInput
            width={100}
            name="Market"
            color={Colors.darkBlue}
            required
            placeholder="Select market..."
            options={marketsOptions}
            setSelected={setSelectedMarket}
          />
          <SelectInput
            width={100}
            name="Product"
            color={Colors.darkBlue}
            required
            placeholder="Select product..."
            options={productsOptions}
            setSelected={setSelectedProduct}
          />
        </FormContentWrapper>
        <ButtonWrapper>
          <CustomButtonSave text="Add" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default AddAddsAccount;
