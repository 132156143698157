import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createLanguage = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/language/create", { ...payload });

    return { data: data.data };
  });

export const updateLanguage = (languageId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/language/update/${languageId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const getLanguages = () =>
  errorHandler(async () => {
    const data = await api.get("/language/get");

    return { data: data.data };
  });
