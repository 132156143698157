import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
  InputsWrapper,
  TopicItem,
  TopicItemsWrapper,
} from "./DepartmentForm.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { ButtonWrapper } from "../../Department.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import TopicForm from "./components/topicForm/TopicForm";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  createDepartment,
  updateDepartment,
} from "@/API/repositories/department";

const DepartmentForm = ({
  setShow,
  addMessage,
  selectedDepartment,
  reload,
}) => {
  const [isTopicFormOpen, setIsTopicFormOpen] = useState();

  const {
    options: { usersOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleSubmit = async () => {
    const payload = formatBeforeSubmit(formik.values);

    if (!payload) {
      return;
    }

    const req = selectedDepartment ? updateDepartment : createDepartment;

    const response = await makeRequest(
      req.bind(null, payload, selectedDepartment?._id)
    );

    if (!response?.data) {
      return addMessage("Something went wron", "error");
    }
    setShow(false);
    reload();
  };

  const formatBeforeSubmit = (values) => {
    const valuesCopy = { ...values };

    if (!valuesCopy.name) {
      addMessage("Add department name", "error");
      return null;
    }

    valuesCopy.head_of_department = values.head_of_department?.value;
    valuesCopy.users = values.users?.map((user) => user.value);

    return valuesCopy;
  };

  const formik = useFormik({
    initialValues: {
      head_of_department:
        usersOptions.find(
          (o) => o.value === selectedDepartment?.head_of_department
        ) || null,
      users:
        usersOptions.filter((o) =>
          selectedDepartment?.users?.includes(o.value)
        ) || [],
      name: selectedDepartment?.name || null,
      topics: selectedDepartment?.topics || [],
    },
    onSubmit: () => handleSubmit(),
  });

  return (
    <PopUp padding="10px 20px 15px" setShow={setShow}>
      <form onSubmit={formik.handleSubmit}>
        <InputsWrapper>
          <Input
            name="Name"
            id="name"
            width={100}
            inputWidth={500}
            color={Colors.darkBlue}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <SelectInput
            name="Head user"
            id="head_of_department"
            width={100}
            selectWidth={500}
            color={Colors.darkBlue}
            setSelected={(value) =>
              formik.setFieldValue("head_of_department", value)
            }
            selected={formik.values.head_of_department}
            options={usersOptions}
          />
          <SelectInput
            name="Users"
            id="users"
            width={100}
            selectWidth={500}
            color={Colors.darkBlue}
            selected={formik.values.users}
            setSelected={(value) => formik.setFieldValue("users", value)}
            options={usersOptions}
            multiple
          />
          <CustomButtonAdd
            onClick={() => setIsTopicFormOpen(true)}
            text="add topic"
            style={{ marginLeft: "45px" }}
          />
          <TopicItemsWrapper>
            {formik.values.topics.map((topic) => (
              <TopicItem
                onClick={() =>
                  formik.setFieldValue(
                    "topics",
                    formik.values.topics.filter((t) => t.value !== topic.value)
                  )
                }
                key={topic.value}
              >
                {topic.label} - {topic.value}
              </TopicItem>
            ))}
          </TopicItemsWrapper>
          {isTopicFormOpen && (
            <TopicForm setShow={setIsTopicFormOpen} formik={formik} />
          )}
        </InputsWrapper>
        <ButtonWrapper>
          <CustomButtonSave styles={{ margin: 0 }} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default DepartmentForm;
