import React, { useEffect, useState } from "react";
import { getConsultantTests } from "../../API/repositories/test";
import { useRequestsContext } from "../../common/hooks/requestHook";
import styled from "styled-components";
import Table from "../../common/components/Table";
import PopUp from "../../common/components/PopUp";
import { Colors } from "../../common/colors/colors";
import userManager from "../../API/userManager";
import { createTestResult } from "../../API/repositories/testResult";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
`;

const CircleNumber = styled.h4`
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: bold;
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
`;

const Line = styled.span`
  width: 50px;
  height: 2px;
  background-color: ${(props) => props.color};
`;

const Title = styled.h2`
  text-align: center;
  text-decoration: underline;
  color: ${Colors.darkBlue};
  margin: -20px 0 40px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 25px -30px 25px;
`;

const QuestionName = styled.h3`
  color: ${Colors.darkBlue};
  text-align: center;
  margin-top: 40px;
  font-style: italic;
`;

const AnswerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  min-height: 80px;
  height: fit-content;
  border: 3px solid
    ${(props) => (props.selected ? Colors.green : Colors.darkBlue)};
  transform: scale(${(props) => (props.selected ? 1.05 : 1)});
  gap: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  :hover {
    border: 2px solid ${Colors.green};
    background-color: ${Colors.lightGreen};
    color: white;
  }
`;

const AnswersWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TestReultWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  color: ${(props) => props.resultColor};
  font-size: 22px;
  font-weight: bold;
  max-width: 600px;
  text-align: center;
  flex-direction: column;
`;

const TestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScoreWrapper = styled.h1`
  display: flex;
  justify-content: center;
  margin: -60px 0 20px 0;
  color: ${Colors.darkBlue};
`;

const getTitleOfPercentPoints = (percent) => {
  if (percent < 10) {
    return `Hold onto your trophies! Your competition score increased by ${percent}%. It's like I leveled up from 'participant' to 'participant with a hint of potential!`;
  }
  if (percent < 20) {
    return `Breaking news: Your competition score just went up by ${percent}%! You 're upgraded from 'just blending in' to 'slightly standing out' in the race for glory!`;
  }

  if (percent < 30) {
    return `Attention, fellow competitors! Your score just took a leap of ${percent}%! It's as if my skills decided to go from 'ordinary' to 'extraordinary' and caught everyone by surprise!`;
  }

  if (percent < 40) {
    return `Guess what? Your competition score just rose by ${percent}%! It's like my talent woke up from a nap, dusted itself off, and said, 'Hey, let's show 'em what we've got!`;
  }

  if (percent < 50) {
    return `Hold onto your medals! Your competition score just soared by ${percent}%! It's as if I discovered a secret stash of excellence hidden behind the judges' table`;
  }
  if (percent < 60) {
    return `Attention, competitors! Your score just skyrocketed by ${percent}%. It's like my skills took a shortcut through the maze of challenges and emerged as the champion of quirkiness`;
  }
  if (percent < 70) {
    return `Prepare to be amazed! Your competition score is spiking with a ${percent}% increase. It's as if my talent found the hidden cheat code and started to break the rules of awesomeness!`;
  }
  if (percent < 80) {
    return `Hold onto your lucky charms! Your competition score just hit an ${percent}% increase! It's like my skills unlocked a new level of wizardry, leaving the competition spellbound!`;
  }
  if (percent < 90) {
    return `Breaking records here! Your competition score just reached a ${percent}% increase! It's official: my talent is doing the victory dance, leaving competitors in awe of my magical abilities!`;
  }

  return `Stop the presses! Your competition score just reached a perfect ${percent}%! It's as if my skills are high-fiving each other, basking in the glory of being crowned the ultimate champion!`;
};

const getColorForResult = (percent) => {
  if (percent < 40) {
    return Colors.red;
  }
  if (percent < 80) {
    return Colors.yellow;
  }

  return Colors.green;
};

const MultilineProgresBarElement = ({ id, current, showFrom }) => {
  const color = id < current ? Colors.darkBlue : "grey";
  return (
    <>
      {id != showFrom && <Line color={color} />}
      <Circle color={color}>
        <CircleNumber color={color}>{id + 1}</CircleNumber>
      </Circle>
    </>
  );
};
const MultilineProgresBar = ({ current, max }) => {
  const showFrom = Math.min(current - 1, Math.max(max - 8, 0));
  const showTo = Math.min(current + 7, max);

  return (
    <CircleWrapper>
      {[...Array(max).keys()].map(
        (e, i) =>
          i >= showFrom &&
          i < showTo && (
            <MultilineProgresBarElement
              current={current}
              id={i}
              showFrom={showFrom}
              max={max}
            />
          )
      )}
    </CircleWrapper>
  );
};

const ResolveTest = ({ test, setShow }) => {
  const [showResult, setShowResult] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [userQuestion, setUserQuestion] = useState(1);
  const [canNext, setCanNext] = useState(false);
  const { makeRequest } = useRequestsContext();

  const handleSave = async () => {
    const payload = {};

    payload.test_id = test._id;
    payload.answers = userAnswers;

    const response = await makeRequest(createTestResult.bind(null, payload));

    if (response.data) {
    }
    setShowResult(() => true);
  };

  return (
    <PopUp setShow={setShow}>
      <TestWrapper>
        <Title>
          {test.translations.find(
            (t) => userManager.getUser().lang === t.lang.toLowerCase()
          )?.value || test.name}
        </Title>
        {!showResult && (
          <>
            <MultilineProgresBar
              max={test.questions.length}
              current={userQuestion}
            />
            {test.questions.map(
              (question, i) =>
                i + 1 === userQuestion && (
                  <div>
                    <QuestionName>
                      {question.translations.find(
                        (t) =>
                          userManager.getUser().lang === t.lang.toLowerCase()
                      )?.value || question.value}
                    </QuestionName>
                    <AnswersWrapper>
                      {question.answers.map((answer) => (
                        <AnswerName
                          selected={userAnswers.some(
                            (ua) =>
                              ua.question.id === question.id &&
                              ua.answerId === answer.id
                          )}
                          className="animation-scale"
                          onClick={() => {
                            setCanNext(() => true);
                            setUserAnswers((prev) => [
                              ...prev.filter(
                                (p) => p.question.id !== question.id
                              ),
                              {
                                answerId: answer.id,
                                correct: answer.is_correct,
                                question: question,
                              },
                            ]);
                          }}
                        >
                          {answer.translations.find(
                            (t) =>
                              userManager.getUser().lang ===
                              t.lang.toLowerCase()
                          )?.value || answer.value}
                        </AnswerName>
                      ))}
                    </AnswersWrapper>
                  </div>
                )
            )}
            <ButtonWrapper>
              <button
                className="btn btn-danger"
                onClick={() => setUserQuestion((prev) => prev - 1)}
                disabled={userQuestion <= 1}
              >
                Prev
              </button>
              {userQuestion === test.questions.length ? (
                <button
                  className="btn btn-success"
                  onClick={() => handleSave()}
                >
                  End Test
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setUserQuestion((prev) => prev + 1);
                    setCanNext(() => false);
                  }}
                  disabled={!canNext}
                >
                  Next
                </button>
              )}
            </ButtonWrapper>
          </>
        )}
        {showResult && (
          <TestReultWrapper
            resultColor={getColorForResult(
              (userAnswers.reduce(
                (prev, next) => prev + (next.correct ? 1 : 0),
                0
              ) /
                userAnswers.length) *
                100
            )}
          >
            <ScoreWrapper>
              {userAnswers.reduce(
                (prev, next) => prev + (next.correct ? 1 : 0),
                0
              )}{" "}
              / {userAnswers.length}
            </ScoreWrapper>
            {getTitleOfPercentPoints(
              (userAnswers.reduce(
                (prev, next) => prev + (next.correct ? 1 : 0),
                0
              ) /
                userAnswers.length) *
                100
            )}
            <div style={{ margin: "20px 0 -20px 0" }}>
              <button
                className="btn btn-warning"
                onClick={() => setShow(() => null)}
              >
                Okay
              </button>
            </div>
          </TestReultWrapper>
        )}
      </TestWrapper>
    </PopUp>
  );
};

const ConsultantTests = () => {
  const headers = ["no", "name", "actions"];
  const { makeRequest } = useRequestsContext();
  const [data, setData] = useState([]);
  const [test, setTest] = useState();

  const handleLoadData = async () => {
    const response = await makeRequest(getConsultantTests);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [test]);

  return (
    <Wrapper>
      <Table
        headers={headers}
        raws={data.map((d, i) => (
          <tr>
            <td>{i + 1}.</td>
            <td>{d.name}</td>
            <td>
              <i
                className="fa fa-graduation-cap fa-fade"
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => setTest(() => d)}
              />
            </td>
          </tr>
        ))}
        className={"styled-table"}
      />
      {test && <ResolveTest test={test} setShow={setTest} />}
    </Wrapper>
  );
};

export default ConsultantTests;
