import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const InvoiceEditableData = styled.div`
  margin-bottom: 40px;
`;

export const TextareaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  gap: 30px;
`;

export const TextareaLabel = styled.p`
  font-weight: bold;
  color: ${Colors.darkBlue};
  font-size: 18px;
`;

export const Textarea = styled.textarea`
  resize: none;
  outline: none;
`;
