import Table from '@/common/components/Table';
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import React from 'react'
import { TableWrapper } from '../../CallCenterPosition.styled';
import Icon from '@/common/components/Icon';
import { CALL_CENTER_POSITION_OPTIONS } from '../../constants/callCenterPositions';

const CallCenterPositionTable = ({ positions, setShow, setSelectedPosition }) => {
  const { commonData: { markets }} = useCommonDataContext();

  const headers = ["No.", "Title", "Market", "Actions"]
  const raws = positions.sort((a, b) => a.market.localeCompare(b.market)).map((position, index) => {
    return (
      <tr 
        key={`${position._id}`}
      >
        <td>{index + 1}</td>
        <td>{CALL_CENTER_POSITION_OPTIONS.find(o => o.value === position.title)?.label}</td>
        <td>{markets.find(p => p._id === position.market)?.name}</td>
        <td>
          <Icon 
            onClick={() => {
              setShow(true);
              setSelectedPosition(position);
            }}
            name="fa fa-edit"
          />
        </td>
      </tr>
    )
  })
  
  return (
    <TableWrapper>
      <Table stickyHeader className="styled-table" headers={headers} raws={raws} />
    </TableWrapper>
  )
}

export default CallCenterPositionTable
