import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { findMarketById } from "@/common/functions/findMarketById";
import { findProductById } from "@/common/functions/findProductById";
import { getDeliveryDate } from "@/common/functions/getDeliveryDate";
import { findClaimMethodConfig, findValidClaimReasonConfig } from "../../validClaims/helpers/findClaimConfigs";
import { getDaysBetweenDates } from "@/common/functions/getDaysBetweenDates";
import { DEFAULT_LIMIT } from "../constants/searchParams";

export const getCSVBody = ({
	data, markets, products, page
}) => {
	const result = [];

	data.forEach((claim, index) => {
		const temp = [];

		temp.push((page - 1) * DEFAULT_LIMIT + index + 1);
		temp.push(findMarketById(claim._order.market, markets)?.name);
		temp.push(findProductById(claim._order.product, products)?.name);
		temp.push(formatToDateTamplate(getDeliveryDate(claim._order.shipping)));
		temp.push(claim._contact.full_name);
		temp.push(claim.type);
		temp.push(formatToDateTamplate(claim.created_at));
		temp.push(findClaimMethodConfig(claim.payment_method)?.label);
		temp.push(getDaysBetweenDates(claim.created_at, getDeliveryDate(claim._order.shipping)));
		temp.push(findValidClaimReasonConfig(claim.reason)?.label);
		temp.push(claim.feedback);
		temp.push(claim.tags.map(tag => tag.label).join(', '));

		result.push(temp);
	})

	return result;
};