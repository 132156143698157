import React from "react";
import { Icon } from "../../ContactSearch.styled";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { Colors } from "@/common/colors/colors";
import {
  ContactOrderItem,
  ContactOrderWrapper,
  ContactOrdersWrapper,
} from "./ContactOrders.styled";

const ContactOrders = ({ selectedContact, checkedOrder, handleOrderCheck }) => {
  return (
    <ContactOrdersWrapper>
      {selectedContact.orders.map((order) => (
        <ContactOrderWrapper key={order._id}>
          <ContactOrderItem>{order.status}</ContactOrderItem>
          <ContactOrderItem>
            {order.shipping?.waybill_number || "----"}
          </ContactOrderItem>
          <ContactOrderItem>
            {order.shipping?.status_delivered_no_payment_at
              ? formatToDateTamplate(
                  order.shipping?.status_delivered_no_payment_at
                )
              : "----"}
          </ContactOrderItem>
          <Icon
            className={
              checkedOrder === order._id ? "fa fa-check" : "fa fa-plus"
            }
            color={checkedOrder === order._id ? Colors.green : ""}
            onClick={() => handleOrderCheck(order._id)}
          />
        </ContactOrderWrapper>
      ))}
    </ContactOrdersWrapper>
  );
};

export default ContactOrders;
