export const AVAILABLE_CONTACT_FILEDS = [
  { label: "Full Name", value: "full_name" },
  { label: "Phone Number", value: "phone_number" },
  { label: "Orginal Phone Number", value: "orginal_phone_number" },
  { label: "City", value: "city" },
  { label: "Country", value: "country" },
  { label: "Postal Code", value: "postal_code" },
  { label: "Email", value: "email" },
  { label: "Street", value: "street" },
  { label: "Created_at", value: "created_at" },
];
