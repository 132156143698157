import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { findMarketById } from "@/common/functions/findMarketById";
import { findProductById } from "@/common/functions/findProductById";
import { getDeliveryDate } from "@/common/functions/getDeliveryDate";
import { findClaimMethodConfig, findValidClaimReasonConfig } from "../../validClaims/helpers/findClaimConfigs";
import { getDaysBetweenDates } from "@/common/functions/getDaysBetweenDates";
import { DEFAULT_LIMIT } from "../constants/searchParams";
import { TableCell } from "../ValidClaimsFeedbackReport.styled";

export const getRaws = ({
	data, markets, products, page
}) => data.map((claim, index) => (
	<tr key={claim._id}>
		<TableCell>{(page - 1) * DEFAULT_LIMIT + index + 1}</TableCell>
		<TableCell>{findMarketById(claim._order.market, markets)?.name}</TableCell>
		<TableCell>{findProductById(claim._order.product, products)?.name}</TableCell>
		<TableCell>{formatToDateTamplate(getDeliveryDate(claim._order.shipping))}</TableCell>
		<TableCell>{claim._contact.full_name}</TableCell>
		<TableCell>{claim.type}</TableCell>
		<TableCell>{formatToDateTamplate(claim.created_at)}</TableCell>
		<TableCell>{findClaimMethodConfig(claim.payment_method)?.label}</TableCell>
		<TableCell>{getDaysBetweenDates(claim.created_at, getDeliveryDate(claim._order.shipping))}</TableCell>
		<TableCell>{findValidClaimReasonConfig(claim.reason)?.label}</TableCell>
		<TableCell>{claim.feedback}</TableCell>
		<TableCell>{claim.tags.map(tag => tag.label).join(', ')}</TableCell>
	</tr>
));