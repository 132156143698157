import React from "react";

const DownloadTable = ({ data, consultants, name }) => {
  const calcByStatus = (data, status) => {
    return data.reduce(
      (prev, next) => prev + (next.status === status ? 1 : 0),
      0
    );
  };

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th colspan="7">Download per order STATUS ({name})</th>
        </tr>
        <tr>
          <th>Consultant</th>
          <th>Download</th>
          <th>Confirmed</th>
          <th>No Response</th>
          <th>Shift</th>
          <th>Trash</th>
          <th>Resigned</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data.length > 0 &&
          data.map(
            (element) =>
              element[0] && (
                <tr>
                  <td>
                    {element[0] &&
                      consultants.find((c) => c._id === element[0].consultant)
                        ?.username}
                  </td>
                  <td>{element.length}</td>
                  <td>
                    {calcByStatus(element, "confirmed")} (
                    {Math.round(
                      (calcByStatus(element, "confirmed") / element.length) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcByStatus(element, "no_response")} (
                    {Math.round(
                      (calcByStatus(element, "no_response") / element.length) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcByStatus(element, "shift")}(
                    {Math.round(
                      (calcByStatus(element, "shift") / element.length) * 100
                    )}
                    %)
                  </td>
                  <td>
                    {calcByStatus(element, "trash")}(
                    {Math.round(
                      (calcByStatus(element, "trash") / element.length) * 100
                    )}
                    %)
                  </td>
                  <td>
                    {calcByStatus(element, "resigned")}(
                    {Math.round(
                      (calcByStatus(element, "resigned") / element.length) * 100
                    )}
                    %)
                  </td>
                </tr>
              )
          )}
      </tbody>
    </table>
  );
};

export default DownloadTable;
