import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";

const CommentElementWrapper = styled.div`
  color: black;
  margin: 10px;
  display: grid;
`;

const Comment = styled.span`
  width: 100%;
  font-size: 18px;
  color: ${Colors.darkBlue};
`;

const Name = styled.span`
  color: black;
  font-style: italic;
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CommentElement = ({ name, comment, createdAt }) => {
  return (
    <CommentElementWrapper>
      <Comment>{comment}</Comment>
      <Name>
        <span style={{ fontSize: "16px" }}>{name}</span>
        <span style={{ fontSize: "14px" }}>
          {moment(createdAt).format("YYYY MMMM DD HH:mm").toLocaleString()}
        </span>
      </Name>
    </CommentElementWrapper>
  );
};

export default CommentElement;
