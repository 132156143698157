import React from "react";
import { ConversationItem } from "../../ConversationGroup.styled";
import { getUserById } from "../../../../helpers/getUserById";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getUserName } from "../../../../helpers/getUsername";

const ConversationUsers = ({ selectedTicket, departments }) => {
  const {
    commonData: { users },
  } = useCommonDataContext();

  return (
    <div>
      <ConversationItem fontSize="16px">
        <strong>
          From:{" "}
          {selectedTicket.created_by
            ? getUserName(
              getUserById(
                selectedTicket.sender ||
                selectedTicket.created_by ||
                  selectedTicket.created_by,
                users
              )
            ) || "System"
            : "System"}
        </strong>
      </ConversationItem>
      <ConversationItem fontSize="12px">
        To:{" "}
        {(selectedTicket.reciver &&
          getUserName(getUserById(selectedTicket?.reciver, users))) ||
          departments.find((d) => selectedTicket?.department === d._id)?.name ||
          "System"}
      </ConversationItem>
    </div>
  );
};

export default ConversationUsers;
