import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createInvoiceConfig = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/invoice-config/create", payload);

    return { data: data.data };
  });

export const updateInvoiceConfig = (payload) =>
  errorHandler(async () => {
    const data = await api.patch("/invoice-config/update", payload);

    return { data: data.data };
  });

export const getInvoiceConfigs = () =>
  errorHandler(async () => {
    const data = await api.get("/invoice-config/get-all");

    return { data: data.data };
  });
