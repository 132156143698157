import { COLORS_ARRAY } from "@/common/colors/colorsArray";
import { round } from "@/common/functions/round";

export const createDataSets = (selectedMarket, users, data) => {
  return selectedMarket.value.consultants.map((c, i) => {
    const filtredData = data.map((d) => d.result[c]);
    const consultantData = filtredData.map(
      (fd) => round(round(fd?.cod_sum) / round(fd?.weight_all_sum)) || null
    );

    return {
      label: users?.find((e) => e._id === c)?.username,
      data: consultantData,
      borderColor: COLORS_ARRAY[i],
      backgroundColor: COLORS_ARRAY[i],
      yAxisID: "y",
    };
  });
};
