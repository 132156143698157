import { Colors } from "@/common/colors/colors";

export const getRaws = (data) =>
  data.map((item, i) => (
    <tr key={item.product_name + i}>
      <td>
        {item.product_name}
      </td>
      <td>
        {item.payment_method}
      </td>
      <td>
        {item.to_pay}
      </td>
      <td>
        {item.priority}
      </td>
      <td style={{ backgroundColor: item.overdue && Colors.orange }}>
        {item.overdue}
      </td>
      <td>
        {item.to_check}
      </td>
      <td>
        {item.check_in_progress}
      </td>
    </tr>
  ));