import React from "react";
import {
  SumUpItem,
  SumUpItemElementImage,
  SumUpItemElementsWrapper,
  SumUpItemElementImageWrapper,
  SumUpItemElements,
  SumUpItemElementInfo,
  SumUpInfo,
} from "../../SumUp.styled";
import { Colors } from "@/common/colors/colors";
import Packages from "@/images/icons/gamificatin-packages.svg";
import { SubTitle } from "@/components/consultant/dashboard/panel/gamification/Gamification.styled";
import { formatDate } from "@/common/functions/dateFormater";
import { getPercent } from "@/common/functions/getPercent";

const DeliverySumUp = ({ sumUpData }) => {
  return (
    <SumUpItem>
      <SubTitle>
        {sumUpData.length === 1
          ? "Last month"
          : `${sumUpData.length} last months`}{" "}
        - PackageDelivery
      </SubTitle>
      <SumUpItemElementsWrapper>
        {sumUpData.map((sumUpMonth) => (
          <SumUpItemElements>
            <SumUpItemElementInfo>
              {formatDate(sumUpMonth.month, "MMMM")}
            </SumUpItemElementInfo>
            <SumUpItemElementImageWrapper size="104px">
              <SumUpItemElementImage src={Packages} alt="package" />
              <SumUpInfo color={Colors.darkBlue}>
                {Math.round(
                  getPercent(
                    sumUpMonth.delivered_amount,
                    sumUpMonth.total_amount
                  )
                )}
                %
              </SumUpInfo>
            </SumUpItemElementImageWrapper>
          </SumUpItemElements>
        ))}
      </SumUpItemElementsWrapper>
    </SumUpItem>
  );
};

export default DeliverySumUp;
