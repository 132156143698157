import Icon from "@/common/components/Icon";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { getClaimColor } from "./getClaimColor";
import { findClaimStatusConfig } from "./findClaimConfigs";
import { Colors } from "@/common/colors/colors";
import { FlexCell } from "../components/claimTable/ClaimTable.styled";

export const getPaidRaws = (validClaims, setSelectedClaim, setAddFile) => 
  validClaims.map((claim, index) => {
    return (
      <tr 
        style={{ 
          backgroundColor: getClaimColor(claim), 
          fontWeight: "bold" 
        }} 
        key={claim._id}
      >
        <td>{index + 1}</td>
        <td>{claim._contact.country.toUpperCase()}</td>
        <td>{claim._contact.full_name}</td>
        <td>{claim._contact.email}</td>
        <td>{formatToDateTamplate(claim._order.shipping.status_delivered_at 
          || claim._order.shipping.status_delivered_no_payment_at)}</td>
        <td>{formatToDateTamplate(claim.transfers.at(-1).initiated_at)}</td>
        <td>{claim.transfers?.at(-1)?.provider}</td>
        <td>{findClaimStatusConfig(claim.status)?.label}</td>
        <td>
          <Icon name="fa fa-edit" onClick={() => setSelectedClaim(claim)} />
        </td>
        <td>
          <FlexCell>
            {claim.stored_documents?.length 
              ? <Icon cursor="auto" name="fa fa-check" color={Colors.green} />
              : "----"}
            <Icon onClick={() => setAddFile(claim._id)} name="fa fa-plus" />
          </FlexCell>
        </td>
      </tr>
    )
  });
