import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getContactWithOrders,
  markContactAsPostalCodePromotion,
  saveConsultantContact,
} from "../../API/repositories/contact";
import Card from "../../common/components/Card";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { COUNTRIES } from "../../common/constants/countries";
import SelectInput from "../../common/components/SelectInput";
import EditItem from "../../common/components/EditItem";
import PopUp from "../../common/components/PopUp";
import { getProductByUserQueue } from "../../API/repositories/product";
import userManager from "../../API/userManager";
import OrderEditInbound from "../../components/consultant/order/OrderEditInbound";
import Comments from "../../components/consultant/contact/comments/Comments";
import { Colors } from "../../common/colors/colors";
import { saveInboundCall } from "../../API/repositories/queue";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import AdditionalOrderCreate from "@/common/components/additionalOrderCreate/AdditionalOrderCreate";
import { SHIPPING_ON_THE_WAY_STATUSES } from "@/common/constants/shipping";
import Icon from "@/common/components/Icon";
import { getOrderInvoiceColor } from "@/common/functions/getOrderInvoiceColor";
import MailsTimeline from "@/components/mailsTimeline/MailsTimeline";
import ShippingTimeline from "@/components/shippingTimeline/ShippingTimeline";
import { TableItemHover } from "@/common/styles/Hover";
import { addMailConditionalStyles } from "@/common/functions/addMailConditionalStyles";
import { addShippingConditionalStyles } from "@/common/functions/addShippingConditionalStyles";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import { DISABLED_TOOLTIP } from "@/common/constants/suspicion";
import moment from "moment";
import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useNewMessageQueueContext } from "@/common/hooks/useNewMessageQueue";

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

const AddOrdersWrapper = styled.div`
  display: flex;
  margin: 20px;
  justify-content: flex-end;
  gap: 20px;
`;

const ConsultantContactInbound = () => {
  const user = userManager?.getUser();
  const { id } = useParams();
  const [dataToDisplay, setDataToDisplay] = useState(null);

  const canEnd = useMemo(() => {
    const threshold = moment().subtract(20, "minutes").utc();
    let isCommentAddedByUser =
      dataToDisplay?._comments.find((c) => {
        const commentTimeUTC = moment(c.created_at).utc();
        return c.user === user.id && threshold.isBefore(commentTimeUTC);
      }) || false;

    return !!isCommentAddedByUser;
  }, [dataToDisplay]);

  const navigate = useNavigate();
  const [country, setCountry] = useState();
  const [create, setCreate] = useState(false);
  const [mailsTimeline, setMailsTimeline] = useState();
  const [shippingTimeline, setShippingTimeline] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState({});
  const [promotionResult, setPromotionResult] = useState();
  const [showAdditionalOrderCreate, setShowAdditionalOrderCreate] = useState();

  const nameRef = useRef();
  const emailRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const additionalInfoRef = useRef();
  const phoneForCourierRef = useRef();

  const { addMessage } = useNewMessageQueueContext();

  const {
    commonData: { markets },
  } = useCommonDataContext();

  const market = useMemo(() => {
    return markets.find((m) => m.short === dataToDisplay?.country);
  }, [dataToDisplay]);

  const handleGetContactWithOrders = async () => {
    const response = await makeRequest(getContactWithOrders.bind(null, id));
    const responseProduct = await makeRequest(
      getProductByUserQueue.bind(null, userManager.getUser().id)
    );

    if (response.data && responseProduct.data) {
      setCountry(
        COUNTRIES.find((country) => country.value === response.data.country)
      );

      setDataToDisplay(() => null);
      setDataToDisplay(() => response.data);
    } else {
      setDataToDisplay(() => null);
    }
  };

  const handleShowOrderEdit = (e, data, create) => {
    e.preventDefault();
    setCreate(() => create);
    setOrder(() => data);
    setShow(() => true);
  };

  const handleShowAdditionalOrderCreate = () => {
    setShowAdditionalOrderCreate(true);
  };

  const checkExtraCondition = (order) => {
    return order?.shipping?.status === 'return'
      && !order?.payment?.length
      && order?._product?.short === 'er';
  }

  useEffect(() => {
    handleGetContactWithOrders();
  }, [show]);

  const handleSave = async () => {
    const payload = Object();


    if (phoneForCourierRef?.current?.value
      && !validatePhoneForCourier(phoneForCourierRef.current.value)) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    payload.full_name = nameRef.current?.value || dataToDisplay.full_name;
    payload.email = emailRef.current?.value || dataToDisplay.email;
    payload.country = country.value;
    payload.postal_code =
      postalCodeRef.current?.value || dataToDisplay.postal_code;
    payload.city = cityRef.current?.value || dataToDisplay.postal_code;
    payload.street = streetRef.current?.value || dataToDisplay.street;
    payload.additional_info =
      additionalInfoRef.current?.value || dataToDisplay.additional_info;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;

    const response = await makeRequest(
      saveConsultantContact.bind(null, id, payload)
    );

    if (response.data) {
      await handleGetContactWithOrders();
    } else {
      console.error(response.error);
    }
  };

  const handleEndInboundContact = async (e) => {
    e.preventDefault();

    const response = await makeRequest(saveInboundCall);

    if (response.data) {
      navigate("/consultant/dashboard");
    }
  };

  const getOrderItems = (order) => {
    return order.shipping?.order_items?.length
      ? order.shipping?.order_items
      : order.order_items;
  };

  const handleCheckPromotion = async (e) => {
    e && e.preventDefault();

    let hasPromotion = {
      region: null,
      message:
        "A province with a postal code was not found " +
        dataToDisplay.postal_code,
      isGood: false,
    };

    try {
      market.regions.forEach((m) => {
        const postalCode = parseInt(dataToDisplay.postal_code.substring(0, 3));

        const foundCode = m.codes.find(
          (c) => c.from <= postalCode && c.to >= postalCode
        );

        if (foundCode) {
          hasPromotion.region = m;
          hasPromotion.message =
            "Error! Province:" +
            m.label +
            "We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)";
        }
      });

      market.promotion.forEach((prom) => {
        if (
          prom.regions.includes(hasPromotion.region._id) &&
          new Date(prom.from) - new Date(dataToDisplay.created_at) <= 0 &&
          new Date(prom.to) - new Date(dataToDisplay.created_at) >= 0
        ) {
          hasPromotion.isGood = true;
          hasPromotion.message =
            "Postal code correct!  Province: " +
            hasPromotion.region.label.toUpperCase() +
            " The customer can take advantage of the promotional offer.";
        }
      });
    } catch (e) {
      console.log(e.message);
    }

    await makeRequest(
      markContactAsPostalCodePromotion.bind(
        null,
        dataToDisplay._id,
        hasPromotion.isGood
      )
    );

    await handleGetContactWithOrders();

    setPromotionResult(() => hasPromotion);
  };

  const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, dataToDisplay._id)
    );
    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    await handleGetContactWithOrders();
    return addMessage("Success", "success");
  };

  const checkOnTheWay = (order) =>
    SHIPPING_ON_THE_WAY_STATUSES.includes(order?.shipping?.status);

  const handleCheckAddOrder = () =>
    !dataToDisplay?.orders?.some((order) => checkIsOrderEditable(order)) &&
    !dataToDisplay?.orders?.some((order) => checkOnTheWay(order));

  const handleCheckAdditionalOrder = () =>
    !dataToDisplay?.orders?.some((order) => checkIsOrderEditable(order)) &&
    dataToDisplay?.orders?.some((order) => checkOnTheWay(order));

  const isCzechWithoutPromotion = () =>
    dataToDisplay?.country === "cz" && !dataToDisplay?.hasPromotion;

  return (
    <Wrapper>
      {hasUnfilledRequest(
        saveConsultantContact,
        getContactWithOrders,
        createSuspicionTicket
      ) && <Loading />}
      {dataToDisplay && (
        <CardWrapper>
          <button
            className={`btn btn-${canEnd ? "warning" : "danger"}`}
            onClick={(e) => handleEndInboundContact(e)}
            disabled={!canEnd}
          >
            {canEnd
              ? " End Inbound Contact "
              : "Please add a comment to end a contact"}
          </button>
          <Card>
            <div style={{ width: "1000px", display: "grid", gap: "40px" }}>
              <h3 style={{ color: Colors.darkBlue }}>Contact:</h3>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    inputRef={nameRef}
                    name="Full Name"
                    value={dataToDisplay.full_name}
                    width={140}
                  />
                  <Input
                    inputRef={emailRef}
                    name="Email"
                    value={dataToDisplay.email}
                    width={140}
                  />
                  <SelectInput
                    options={COUNTRIES}
                    name="Country"
                    selected={country}
                    setSelected={setCountry}
                    width={140}
                  />
                  <Input
                    name="Phone for courier"
                    value={dataToDisplay.phone_number_for_courier}
                    width={140}
                    inputRef={phoneForCourierRef}
                  />
                  <Input
                    name="Phone Number"
                    disabled={true}
                    value={dataToDisplay.phone_number}
                    width={140}
                  />
                </div>
                <div>
                  <Input
                    inputRef={postalCodeRef}
                    name="Postal Code"
                    value={dataToDisplay.postal_code}
                    width={140}
                  />
                  <Input
                    inputRef={cityRef}
                    name="City"
                    value={dataToDisplay.city}
                    width={140}
                  />
                  <Input
                    inputRef={streetRef}
                    name="Street"
                    value={dataToDisplay.street}
                    width={140}
                  />
                  <Input
                    inputRef={additionalInfoRef}
                    name="Company name (if necessary)"
                    value={dataToDisplay.additional_info}
                    width={140}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "right",
                  marginTop: "-40px",
                  gap: "30px",
                }}
              >
                {dataToDisplay.country === "cz" && (
                  <div>
                    <CustomButtonAdd
                      onClick={(e) => handleCheckPromotion(e)}
                      disabled={!dataToDisplay.postal_code}
                      defaultText="Check promotion"
                      color={Colors.blue}
                    />
                  </div>
                )}
                <div>
                  <CustomButtonAdd
                    tooltip={
                      dataToDisplay.shouldDisableSuspicion
                        ? DISABLED_TOOLTIP
                        : null
                    }
                    defaultText="Suspect"
                    disabled={dataToDisplay.shouldDisableSuspicion}
                    color={Colors.red}
                    onClick={() => handleCreateSuspicionTicket()}
                  />
                </div>
                <div>
                  <CustomButtonAdd defaultText="Save" onClick={handleSave} />
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div style={{ width: "1000px" }}>
              <h3 style={{ color: Colors.darkBlue, marginBottom: "30px" }}>
                Orders:
              </h3>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <table className="styled-table" style={{ margin: "0px" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        <h5>status</h5>
                      </th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        <h5>currency</h5>
                      </th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        <h5>product</h5>
                      </th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        <h5>variants</h5>
                      </th>
                      <th style={{ width: "150px", textAlign: "center" }}>
                        <h5>value</h5>
                      </th>
                      <th style={{ width: "150px", textAlign: "center" }}>
                        <h5>shipping status</h5>
                      </th>
                      <th style={{ width: "150px", textAlign: "center" }}>
                        <h5>timeline</h5>
                      </th>
                      <th style={{ width: "150px", textAlign: "center" }}>
                        <h5>actions</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="queue">
                    {dataToDisplay.orders.map((data) => (
                      <tr key={data?._id}>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {data?.status}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {" "}
                          {data?.shipping?.currency || data?.currency}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {data?._product?.name}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {getOrderItems(data).length
                            ? getOrderItems(data)
                              .map((item) => item.product_variant)
                              .join(", ")
                            : "--------"}
                        </td>
                        <td style={{ width: "150px", textAlign: "center" }}>
                          {getOrderItems(data).length
                            ? getOrderItems(data)
                              .reduce((prev, next) => prev + next.price, 0)
                              ?.toFixed(2)
                            : "------"}
                        </td>
                        <td style={{ width: "150px", textAlign: "center" }}>
                          {data?.shipping?.status === "delivered_no_payment"
                            ? "delivered"
                            : data.shipping?.status || "------"}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <TableItemHover label="Invoice status by color">
                              <Icon
                                color={getOrderInvoiceColor(data?._invoice)}
                                cursor="default"
                                name="fa fa-file-invoice"
                              />
                            </TableItemHover>
                            <TableItemHover label="Mail history">
                              <Icon
                                onClick={() => setMailsTimeline(data?._mails)}
                                name="fa fa-envelope"
                                {...addMailConditionalStyles(data)}
                              />
                            </TableItemHover>
                            <TableItemHover label="Shipping history">
                              <Icon
                                onClick={() =>
                                  setShippingTimeline(data?.shipping)
                                }
                                name="fa fa-truck"
                                {...addShippingConditionalStyles(data)}
                              />
                            </TableItemHover>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              justifyContent: "center",
                            }}
                          >
                            {(checkIsOrderEditable(data)
                              || (checkExtraCondition(data))) &&
                              !isCzechWithoutPromotion() ? (
                              <div
                                onClick={(e) =>
                                  handleShowOrderEdit(e, data, false)
                                }
                              >
                                <EditItem width={30} />
                              </div>
                            ) : data?.payment?.length > 0 ? (
                              <div
                                onClick={(e) =>
                                  handleShowOrderEdit(e, data, false)
                                }
                              >
                                <EditItem width={30} />
                              </div>
                            ) : (
                              "------"
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <AddOrdersWrapper>
              {handleCheckAdditionalOrder() && (
                <CustomButtonAdd
                  onClick={() => handleShowAdditionalOrderCreate()}
                  disabled={isCzechWithoutPromotion()}
                  defaultText="Add additional order"
                />
              )}
              {handleCheckAddOrder() && (
                <CustomButtonAdd
                  onClick={(e) => handleShowOrderEdit(e, {}, true)}
                  disabled={isCzechWithoutPromotion()}
                  defaultText="Add Order"
                />
              )}
            </AddOrdersWrapper>
          </Card>
          {dataToDisplay && (
            <Card>
              <div style={{ width: "1000px" }}>
                <h3 style={{ color: Colors.darkBlue }}>Comments:</h3>
                <Comments
                  comments={dataToDisplay?._comments || []}
                  contactId={id}
                  translation={{ comentInfo: "info", add: "add" }}
                  handleGetDawnContact={() => handleGetContactWithOrders()}
                />
              </div>
            </Card>
          )}
        </CardWrapper>
      )}
      {promotionResult && (
        <PopUp setShow={setPromotionResult}>
          <h3
            style={{
              color: promotionResult.isGood ? Colors.green : Colors.red,
              maxWidth: "400px",
              textAlign: "center",
            }}
          >
            {promotionResult.message}
          </h3>
        </PopUp>
      )}
      {show && (
        <OrderEditInbound
          comments={dataToDisplay?._comments}
          inbound={true}
          order={order}
          setShow={setShow}
          setOrder={setOrder}
          contact={dataToDisplay}
          country={country}
          market={market}
          create={create}
        />
      )}
      {showAdditionalOrderCreate && (
        <AdditionalOrderCreate
          setShow={setShowAdditionalOrderCreate}
          orderById={
            dataToDisplay?.orders?.find((order) => checkOnTheWay(order))?._id
          }
          reloadFn={handleGetContactWithOrders}
        />
      )}
      {mailsTimeline?.length && (
        <MailsTimeline setShow={setMailsTimeline} mails={mailsTimeline} />
      )}
      {shippingTimeline && (
        <ShippingTimeline
          setShow={setShippingTimeline}
          shipping={shippingTimeline}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantContactInbound;
