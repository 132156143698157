export const validate = (values) => {
  if (!values.products?.length) {
    return "Select some products";
  }

  if (!values.time_period) {
    return "Select time period";
  }

  if (!values.period_from || !values.period_to) {
    return "Enter periods";
  }

  return null;
};
