import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const createListeningGenerator = (body) =>
  errorHandler(async () => {
    const data = await api.post(`/listening-generator`, body);
    return { data: data.data };
  });

export const getAllListeningGenerator = () =>
  errorHandler(async () => {
    const data = await api.get(`/listening-generator`);
    return { data: data.data };
  });

export const updateListeningGenerator = (body) =>
  errorHandler(async () => {
    const data = await api.patch(`/listening-generator`, body);
    return { data: data.data };
  });

export const generateListeningToScoring = () =>
  errorHandler(async () => {
    const data = await api.get(
      `/listening-generator/generate-scoring-for-consultant`
    );
    return { data: data.data };
  });

export const deleteListeningGenerator = (id) =>
  errorHandler(async () => {
    const data = await api.delete(`/listening-generator/${id}`);

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
