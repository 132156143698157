export const HEADERS_ARRAY = [
  [<th colSpan={8}>Valid claim summary</th>],
  [
    <th>Product</th>,
    <th>To pay</th>,
    <th>Overdue</th>,
    <th>To check</th>,
    <th>Check in progress</th>,
    <th>To correct</th>,
    <th>Correct in progress</th>,
    <th>Total</th>
  ]
]