const { Colors } = require("@/common/colors/colors");
const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
  height: 100%;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${Colors.darkBlue};
  height: 40px;
  padding: 40px;
  border-radius: 15px;
`;
