import React, { useEffect, useState } from "react";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import SelectInput from "@/common/components/SelectInput";
import { getWorkingHoursLastSevenDays } from "@/API/repositories/reports";
import CustomBar from "@/common/charts/Bar";
import { handleMapData } from "./helpers/handleMapData";

const HoursConsultant = () => {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const {
    commonData: { users },
    options: { queuesOptions },
  } = useCommonDataContext();

  const [selectedMarket, setSelectedMarket] = useState(queuesOptions[0]);

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);
    const payload = {};
    payload.consultants = selectedMarket.value.consultants;

    const response = await makeRequest(
      getWorkingHoursLastSevenDays.bind(null, payload)
    );

    if (response.data) {
      setData(() =>
        response.data.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      );
    }
  };

  useEffect(() => {
    if (data) handleMapData(selectedMarket, data, setChartData, users);
  }, [data]);

  useEffect(() => {
    if (queuesOptions[0]) setSelectedMarket(() => queuesOptions[0]);
  }, [queuesOptions]);

  useEffect(() => {
    if ((queuesOptions[0], selectedMarket)) handleLoadData();
  }, [queuesOptions, selectedMarket]);

  return (
    <Wrapper width={800}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa fa-clock" />
          Current week consultant hours(all)
        </TopContentWrapper>
        <SelectInput
          options={queuesOptions}
          selected={selectedMarket}
          setSelected={setSelectedMarket}
          selectWidth={200}
          showLabel={false}
        />
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <CustomBar data={chartData} />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default HoursConsultant;
