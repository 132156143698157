import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { calculateTotalPrice } from "./calculateTotalPrice";
import { getStatusLabel } from "./getStatusLabel";
import {
  ActionIcon,
  FlexTableCell,
  LimitedWidthCell,
} from "../ValidInvoicesTable.styled";
import { Colors } from "@/common/colors/colors";
import { sortTicketsByInvoiceNumber } from "@/common/functions/sortTicketsByInvoiceNumber";
import Icon from "@/common/components/Icon";
import { getRefundDate } from "./getRefundDate";


export const getAcceptedRaws = (tickets, handleDownloadFile, markets, isRestricted) =>
  sortTicketsByInvoiceNumber(tickets).map((ticket, i) => (
    <tr key={`${ticket.created_at}_${ticket.valid_invoice.invoice_type}`}>
      <td>{i + 1}</td>
      <td>{ticket._contact.email}</td>
      <td>{markets.find(m => m.short === ticket._contact.country)?.name}</td>
      <td>{ticket.valid_invoice.invoice_type}</td>
      <td>
        {ticket.valid_invoice.invoice_number ||
          ticket.valid_invoice.correction_number}
      </td>
      <LimitedWidthCell>
        {ticket.valid_invoice.company_name ||
          ticket.valid_invoice.full_name ||
          "----"}
      </LimitedWidthCell>
      <td>{formatToDateTamplate(ticket.valid_invoice.issue_date)}</td>
      <td>{formatToDateTamplate(ticket.valid_invoice.sold_date)}</td>
      <td>
        {getRefundDate(ticket)}
      </td>
      <td>
        {calculateTotalPrice(ticket.valid_invoice.order_items)}{" "}
        {ticket._order.currency}
      </td>
      <td>{getStatusLabel(ticket.status)}</td>
      <FlexTableCell>
        {ticket._stored_document && (
          <ActionIcon
            color={Colors.darkBlue}
            onClick={() => handleDownloadFile(ticket)}
            className="fa fa-file-pdf"
          />
        )}
        {!isRestricted && ticket.valid_invoice.fakturownia_link && (
          <Icon
            onClick={() =>
              window.open(ticket.valid_invoice.fakturownia_link, "_blank")
            }
            name="fa fa-compass"
          />
        )}
      </FlexTableCell>
    </tr>
  ));
