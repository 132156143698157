import { errorHandler } from "../common";
import Api from "../api";

const { axios: api } = Api;

export const convertSpeechToText = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/speech-to-text`, payload);

    return { data: data.data };
  });
