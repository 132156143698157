export const HEADERS = [
  "No.",
  "Status",
  "Type",
  "Title",
  "Updated at",
  "Last updated by",
  "Last message",
  "Email",
  "Actions",
];
