export const generateSHortInfoData = (consultantData) => {
  const map = new Map();

  consultantData.forEach((data) => {
    if (map.get(data.contract_type)) {
      map.get(data.contract_type).push(data);
      return;
    }

    map.set(data.contract_type, [data]);
  });

  return Array.from(map, ([name, value]) => ({ name, value }));
};
