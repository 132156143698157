import React from "react";
import userManager from "@/API/userManager";
import {
  OldTicketsWrapper,
  OldMessageText,
  OldMessageItem,
  OnlineRound,
  OldMessageSenderMarket,
  OldMessageSenderNameWrapper,
  OldMessageInfoWrapper,
  OldMessageStatus,
  PriorityWrapper,
  LastMessageTimeWrapper,
} from "./OldTicketsList.styled";
import { handleOnline } from "../../helpers/handleOnline";
import { getOutdatedTicketBackgroundColor } from "../../helpers/getOutdatedTicketBackgound";
import { findLabelByTitle } from "../ticketView/helpers/findLabelByTitle";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import Icon from "@/common/components/Icon";
import moment from "moment";
import { getMessageTime } from "../../helpers/getMessageTime";
import { getLastTicketNotification } from "../../helpers/getLastTicketNotification";

const OldTicketsList = ({
  oldTickets,
  selectedTicket,
  handleSelectTicket,
  departments,
}) => {
  const currentUserId = userManager.getUser().id;

  return (
    <OldTicketsWrapper>
      <div>
        {oldTickets?.map((ticket) => (
          <OldMessageItem
            onClick={() => handleSelectTicket(ticket)}
            backgroundColor={getOutdatedTicketBackgroundColor(
              ticket,
              selectedTicket,
              currentUserId
            )}
            color={"black"}
            key={ticket._id}
          >
            <OldMessageSenderMarket>
              {ticket._orders && ticket._orders[0]?._product?.name && (
                <span>
                  {ticket._orders[0]._product.name.split(" ")[1]}
                  <br />
                  {ticket._orders[0]._product.name.split(" ")[0]}
                </span>
              )}
            </OldMessageSenderMarket>
            <OldMessageInfoWrapper>
              {ticket.prioritized && (
                <PriorityWrapper>
                  <Icon color="#fff" size={10} name="fa fa-exclamation" />
                </PriorityWrapper>
              )}
              <OldMessageText>
                {findLabelByTitle(ticket, departments)}
              </OldMessageText>
              <OldMessageSenderNameWrapper>
                <span>{ticket._last_status_by?.username || "System"}</span>
                <OnlineRound
                  backgroundColor={handleOnline(
                    ticket._last_status_by?.last_ping
                  )}
                />
                <LastMessageTimeWrapper>
                  {moment(
                    getMessageTime(getLastTicketNotification(ticket))
                  ).format("YYYY-MM-DD HH:mm")}
                </LastMessageTimeWrapper>
              </OldMessageSenderNameWrapper>
            </OldMessageInfoWrapper>
            <OldMessageStatus
              bgColor={
                QUESTION_TICKET_STATUS_OPTIONS.find(
                  (o) => o.value === ticket.status
                )?.color
              }
            >
              {
                QUESTION_TICKET_STATUS_OPTIONS.find(
                  (o) => o.value === ticket.status
                )?.label
              }
            </OldMessageStatus>
          </OldMessageItem>
        ))}
      </div>
    </OldTicketsWrapper>
  );
};

export default OldTicketsList;
