import React from "react";
import {
  HoverRegulation,
  Info,
  SubTitle,
  TitleIcon,
} from "../../../../Gamification.styled";
import {
  WppkDeliveredWrapper,
  WppkDeliveredPackage,
  ProgressVerticalBar,
  ProgressBarVerticalItem,
  ProgressBarItemVerticalFiller,
} from "./PackageDelivery.styled";
import Packages from "@/images/icons/gamificatin-packages.svg";
import { getProgressBarVerticalFillerHeight } from "./helpers/getProgressBarVertiaclFillerHeight";
import { getPercent } from "@/common/functions/getPercent";
import { getDeliveredRange } from "@/common/functions/getDeliveredRange";

const PackageDelivery = ({ userData, regulation, translation }) => {
  const { package_percents, delivered_amount, total_amount } = userData;
  const deliveredRange = getDeliveredRange(package_percents);
  const deliveredPercent = getPercent(delivered_amount, total_amount);

  return (
    <>
      <SubTitle>
        {translation.package_delivery}
        <HoverRegulation regulation={regulation}>
          <TitleIcon className="fa fa-thin fa-circle-info" />
        </HoverRegulation>
      </SubTitle>
      {!!deliveredRange?.length && (
        <WppkDeliveredWrapper>
          <WppkDeliveredPackage>
            <img src={Packages} alt="package-delivery" />
            <Info>
              <b>{delivered_amount}</b> / {total_amount}
            </Info>
          </WppkDeliveredPackage>
          <ProgressVerticalBar>
            {deliveredRange.map((percent, i) => (
              <ProgressBarVerticalItem
                key={percent}
                percent={`${percent}%`}
                height="100%"
              >
                <ProgressBarItemVerticalFiller
                  height={getProgressBarVerticalFillerHeight(
                    deliveredPercent,
                    percent,
                    deliveredRange[i - 1]
                  )}
                />
              </ProgressBarVerticalItem>
            ))}
          </ProgressVerticalBar>
        </WppkDeliveredWrapper>
      )}
    </>
  );
};

export default PackageDelivery;
