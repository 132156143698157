import React from "react";

const EffectiveReportMarket = ({ weights, products, markets, data }) => {
  return (
    <div style={{ width: "80vw" }}>
      <div
        style={{
          marginBottom: "40px",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <table className="styled-table">
          <thead>
            <tr>
              <th>Market</th>
              <th>Product</th>
              {weights && <th>Download no</th>}
              <th>COD number</th>
              <th>COD sum</th>
              <th>COD delivered number</th>
              <th>COD delivered sum</th>
              <th>WPPK</th>
              <th>WPPK delivered</th>
              <th>Inbounds</th>
              <th>Outbounds</th>
              <th>Contacts</th>
              <th>Weight sum</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data
              .sort((a, b) => {
                if (weights)
                  return (
                    parseInt(a._id.split("-")[1]) -
                    parseInt(b._id.split("-")[1])
                  );
                return 1;
              })
              .sort((a, b) => {
                if (weights)
                  return a._id.split("-")[0].localeCompare(b._id.split("-")[0]);
                return a._id.localeCompare(b._id);
              })
              .map((reportElementScore) => (
                <tr>
                  <td>
                    {
                      markets.find((a) => a.value === reportElementScore.market)
                        ?.label
                    }
                  </td>
                  <td>
                    {
                      products.find(
                        (a) => a.value === reportElementScore.product
                      )?.label
                    }
                  </td>
                  {weights && <td>{reportElementScore._id.split("-")[1]}</td>}
                  <td>{reportElementScore.cod_count}</td>
                  <td>{reportElementScore.cod_sum}</td>
                  <td>{reportElementScore.cod_delivered_count}</td>
                  <td>{reportElementScore.cod_delivered_sum}</td>
                  <td>
                    {reportElementScore.contacts > 0
                      ? Math.round(
                          parseFloat(
                            reportElementScore.cod_sum /
                              reportElementScore.contacts
                          ) * 100
                        ) / 100
                      : 0}
                  </td>
                  <td>
                    {reportElementScore.contacts > 0
                      ? Math.round(
                          parseFloat(
                            reportElementScore.cod_delivered_sum /
                              reportElementScore.contacts
                          ) * 100
                        ) / 100
                      : 0}
                  </td>
                  <td>{reportElementScore.inbounds}</td>
                  <td>{reportElementScore.outbounds}</td>
                  <td>{reportElementScore.contacts}</td>
                  <td>
                    {Math.round(
                      parseFloat(reportElementScore.weight_all_sum) * 100
                    ) / 100}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EffectiveReportMarket;
