import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  min-height: 80vh;
  gap: 30px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const RightFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
`;
