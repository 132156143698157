import moment from "moment";
import React, { useEffect, useState } from "react";
import { getLastMailedOrdersAndShifts } from "../../../../../API/repositories/order";
import { Colors } from "../../../../../common/colors/colors";
import Card from "../../../../../common/components/Card";
import EditItem from "../../../../../common/components/EditItem";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import CustomSkeleton from "../../../../../common/components/CustomSkeleton";

const Wrapper = styled.div`
  width: fit-content;
  height: 500px;
  width: 620px;
  gap: 20px;
  border-radius: 20px;
  color: ${(props) => props.color};
  padding: 10px 0 0 0;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  overflow: hidden;
`;

const ConsultantQueueActions = ({ text }) => {
  const [showDisplayChanger, setSchowDiplayChanger] = useState(true);
  const [data, setData] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getLastMailedOrdersAndShifts.bind(null));

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getLastMailedOrdersAndShifts)}
      <Wrapper>
        {data ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <h4 style={{ color: Colors.darkBlue, fontWeight: "bold" }}>
                  {showDisplayChanger
                    ? text["Your_shifts"]
                    : text["Your_emails"]}
                </h4>
              </div>
              <i
                className="fa fa-envelope animation-scale"
                onClick={() => setSchowDiplayChanger(false)}
                style={{
                  color: Colors.darkBlue,
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
              <i
                className="fa fa-address-book animation-scale"
                onClick={() => setSchowDiplayChanger(true)}
                style={{
                  color: Colors.darkBlue,
                  fontSize: "26px",
                  cursor: "pointer",
                }}
              />
            </div>

            {showDisplayChanger && data.shifts.length > 0 && (
              <div
                style={{
                  maxHeight: "460px",
                  overflowY: "scroll",
                }}
              >
                {data.shifts && (
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>{text["shift_date"]}</th>
                        <th>{text["full_name"]}</th>
                        <th>{text["email"]}</th>
                        <th>{text["number"]}</th>
                        <th>{text["should_call"]}</th>
                        <th>{text["actions"]}</th>
                      </tr>
                    </thead>
                    <tbody className="queue">
                      {data.shifts
                        ? data.shifts.map((shift, i) => (
                            <tr key={i}>
                              <td style={{ maxWidth: "50px" }}>
                                {moment(shift.created_at).format("DD-MM HH:mm")}
                              </td>
                              <td style={{ width: "80px" }}>
                                {shift.contact?.full_name
                                  ? shift.contact.full_name
                                  : "-------"}
                              </td>
                              <td
                                style={{
                                  maxWidth: "120px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {shift.contact?.email
                                  ? shift.contact.email
                                  : "-------"}
                              </td>
                              <td style={{ width: "150px" }}>
                                {shift.contact?.phone_number
                                  ? shift.contact.phone_number
                                      .match(/.{1,3}/g)
                                      .map((substring) => substring + " ")
                                  : "-------"}
                              </td>
                              <td style={{ width: "100px" }}>
                                {moment(shift.shift_date).format("DD-MM HH:mm")}
                              </td>
                              <td style={{ width: "20px" }}>
                                <EditItem
                                  link={`/consultant/contact/${shift.contact._id}`}
                                  width={20}
                                />
                              </td>
                            </tr>
                          ))
                        : "------------------------"}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            {!showDisplayChanger && data.mails.length > 0 && (
              <div
                style={{
                  maxHeight: "460px",
                  overflowY: "scroll",
                }}
              >
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {text["sent_date"]}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {text["email"]}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {text["full_name"]}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {text["number"]}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {text["actions"]}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="queue">
                    {data.mails.length > 0
                      ? data.mails.map((mail, i) => (
                          <tr key={i}>
                            <td style={{ maxWidth: "50px" }}>
                              {moment(mail.created_at).format("DD-MM HH:mm")}
                            </td>
                            <td>
                              <div
                                style={{
                                  maxWidth: "150px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {mail.customer}
                              </div>
                            </td>
                            <td style={{ width: "150px" }}>
                              {mail.customer
                                ? mail._contact.full_name
                                : "-------"}
                            </td>
                            <td style={{ width: "150px" }}>
                              {mail.customer
                                ? mail._contact.phone_number
                                    .match(/.{1,3}/g)
                                    .map((substring) => substring + " ")
                                : "-------"}
                            </td>
                            <td style={{ width: "20px" }}>
                              <EditItem
                                link={`/consultant/contact/${mail._contact._id}`}
                                width={20}
                              />
                            </td>
                          </tr>
                        ))
                      : "------------------------"}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CustomSkeleton count={15} width={500} height={5} />
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default ConsultantQueueActions;
