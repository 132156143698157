export const groupByMarketAndStautsField = (data) => {
  const marketsMap = new Map();

  data.forEach((element) => {
    if (!marketsMap.has(element._market.name)) {
      return marketsMap.set(element._market.name, [element]);
    }

    marketsMap.get(element._market.name).push(element);
  });

  return Array.from(marketsMap);
};
