import React from "react";
import {
  TitleIcon,
  Title,
  HoverRegulation,
} from "../../../../Gamification.styled";
import { SumUpDataWrapper, SumUpWrapper } from "./SumUp.styled";
import DeliverySumUp from "./components/deliverySumUp/DeliverySumUp";
import OrdersSumUp from "./components/ordersSumUp/OrdersSumUp";

const SumUp = ({ isSumUpOpen, sumUpData, regulation }) => {
  return (
    <SumUpWrapper isSumUpOpen={isSumUpOpen}>
      <Title>
        Sum up
        <HoverRegulation regulation={regulation}>
          <TitleIcon className="fa fa-circle-info" />
        </HoverRegulation>
      </Title>
      <SumUpDataWrapper>
        <OrdersSumUp sumUpData={sumUpData} />
        <DeliverySumUp sumUpData={sumUpData} />
      </SumUpDataWrapper>
    </SumUpWrapper>
  );
};

export default SumUp;
