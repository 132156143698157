import Api from "../api";

const { axios: api } = Api;

const PREFIX = "server-config";

export const getAllServerConfigs = async (payload) => {
  const data = await api.get(`${PREFIX}`);

  return data.data;
};

export const createServerConfig = async (payload) => {
  const data = await api.post(`${PREFIX}`, payload);

  return data.data;
};

export const triggerConfigService = async (payload) => {
  const data = await api.post(`${PREFIX}/trigger/${payload.trigger}`, payload);

  return data.data;
};

export const updateServerConfig = async (payload) => {
  const data = await api.patch(`${PREFIX}/${payload._id}`, payload);

  return data.data;
};
