import { formatToDateTamplate } from "@/common/functions/dateFormater";

export const getExcelData = (data) =>
  data.map((element) => ({
    TYP: element.contract_type,
    "IMIĘ I NAZWISKO": element.name + " " + element.surname,
    OKRES: formatToDateTamplate(element.from),
    ROZLICZENIA: formatToDateTamplate(element.to),
    "ILOSC PRZEPRACOWANYCH GODZIN": element.hoursSum,
    "BONUS BRUTTO": element.sum,
  }));
