import styled from "styled-components";
import { Colors } from "../colors/colors";

export const TableItemHover = styled.div`
  position: relative;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      display: flex;
      width: 100px;
      z-index: 2;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 4px;
      bottom: 24px;
      right: 50%;
      transform: translateX(50%);
      border-radius: 10px;
    }
  }
`;
