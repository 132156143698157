import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getSearchedContactWithOrders } from "../../API/repositories/contact";
import translationManager from "../../API/translationManager";
import { Colors } from "../colors/colors";
import { useRequestsContext } from "../hooks/requestHook";
import Loading from "./Loading";

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 15px;
`;

const ResultWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 10px;
`;

const SearchContact = ({ consultant = true }) => {
  const [showData, setShowData] = useState(null);
  const searchRef = useRef();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();

  const handleKeyUp = (e) => {
    if (e.key === "Enter") handleSearch(e);
  };

  const handleSearch = async (e) => {
    const searchData = searchRef.current.value;
    setShowData(() => null);
    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, searchData)
    );

    if (response.data) {
      setShowData(() => response.data);
    }
  };

  const handleShowSearched = (e, _id) => {
    e.preventDefault();
    setShowData(() => null);
    searchRef.current.value = "";
    if (consultant) {
      navigate(`/consultant/contact/${_id}`);
    } else {
      navigate(`/dashboard/contact/${_id}`);
    }
  };

  const [translation, setTranslation] = useState({
    placeholder: "Type email, name, phone number",
  });

  const translate = async () => {
    const translations = Object();
    translations.placeholder = await translationManager.getTranslation(
      "Type email, name, phone number"
    );
    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <SearchBox>
      {hasUnfilledRequest(getSearchedContactWithOrders) && <Loading />}
      <input
        ref={searchRef}
        className="offInputStyle"
        type="text"
        style={{
          width: "400px",
          border: `1px solid ${Colors.darkBlue}`,
        }}
        onKeyUp={(e) => handleKeyUp(e)}
        placeholder={translation.placeholder}
      />
      <i
        className="fa fa-search animation-scale"
        onClick={(e) => handleSearch(e)}
        style={{
          cursor: "pointer",
          marginLeft: "-30px",
          color: Colors.darkBlue,
        }}
      ></i>
      {showData && showData[0] && (
        <ResultWrapper className="queue">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <div
              style={{
                marginRight: "20px",
              }}
            >
              <i
                className="fa fa-remove animation-scale"
                style={{ cursor: "pointer", color: Colors.red }}
                onClick={() => setShowData(() => null)}
              />
            </div>
          </div>
          {showData.map((data, i) => (
            <tr key={i}>
              <td
                style={{
                  minWidth: "200px",
                  textAlign: "right",
                  padding: "20px",
                }}
              >
                {data.full_name}
              </td>
              <td
                style={{
                  minWidth: "100px",
                  textAlign: "left",
                }}
              >
                {data.phone_number?.replace(" ", "")}
              </td>
              <td
                style={{
                  minWidth: "240px",
                  textAlign: "left",
                }}
              >
                {data.email}
              </td>
              <td style={{ minWidth: "40px" }}>
                <i
                  className="fa fa-edit animation-scale"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleShowSearched(e, data._id)}
                />
              </td>
            </tr>
          ))}
        </ResultWrapper>
      )}
    </SearchBox>
  );
};

export default SearchContact;
