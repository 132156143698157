import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const TimelineDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 0 12px 8px;
`;

export const TimelineRound = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  color: ${Colors.darkBlue};
  border: 2px solid ${Colors.darkBlue};
  font-weight: bold;
  border-radius: 50%;
  z-index: 1;
`;

export const InfoWrapper = styled.div`
  width: ${(props) => props.width || "150px"};
`;

export const DateWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 20px;

  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 48px;
    background-color: ${Colors.darkBlue};
    position: absolute;
    top: -9px;
    right: 72.7%;
    border-radius: 10px;
  }
`;
