import Icon from "@/common/components/Icon";
import { LimitedSizeTableCell } from "../DepartmentTable.styled";

export const getRaws = (departments, usersOptions, handleSetDepartmentEdit) =>
  departments?.map((department, i) => (
    <tr key={department._id}>
      <td>{i + 1}</td>
      <td>{department.name}</td>
      <LimitedSizeTableCell>
        {usersOptions
          ?.filter((option) =>
            [department.head_of_department, ...department.users]?.includes(
              option.value
            )
          )
          ?.map((u) => u.label)
          .join(", ")}
      </LimitedSizeTableCell>
      <LimitedSizeTableCell>
        {department.topics?.map((topic) => topic.label).join(", ")}
      </LimitedSizeTableCell>
      <td>
        <Icon
          onClick={() => handleSetDepartmentEdit(department)}
          name="fa fa-edit"
        />
      </td>
    </tr>
  ));
