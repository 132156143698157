import moment from 'moment';

class TokenManager {
  constructor(api) {
    this.api = api;
    this.setApiToken(this.getToken())
  }

  setToken(token) {
    localStorage.setItem("token", token)
    this.setApiToken(token);
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem("refreshToken", refreshToken)
  }

  getToken() {
    return localStorage.getItem("token")
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken")
  }

  async isTokenActive() {
    const token = this.getToken()
    const refreshToken = this.getRefreshToken()

    if (!token) return false

    const payload = this.parseJwt(token);
    if (this.isTokenExp(payload))
      this.refreshToken(token, refreshToken)

  }

  isTokenExp(payload) {
    const expDate = moment(payload.exp);
    const today = moment();

    return expDate.isBefore(today)
  }

  async refreshToken(token, refreshToken) {
    const refreshData = await this.api.post("/user/refresh_token", { token: token, refreshToken: refreshToken })

    this.setToken(refreshData.data.token);
    this.setRefreshToken(refreshData.data.refreshToken);

  }

  setApiToken(token) {
    this.api.defaults.headers.common["authorization"] = `Bearer ${token}`;
  }

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  removeToken() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
  }

}

export default TokenManager