import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./Keys.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import KeyForm from "./components/keyForm/KeyForm";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getKeys } from "@/API/repositories/key";
import Loading from "@/common/components/Loading";
import KeyTable from "./components/keyTable/KeyTable";

const Keys = () => {
  const [keys, setKeys] = useState();
  const [isKeyFormOpen, setIsKeyFormOpen] = useState();
  const [selectedKey, setSelectedKey] = useState();

  const { messages, removeMessage, addMessage } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getKeys);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setKeys(() => response.data);
  };

  const handleSetKeyFormInEditMode = (key) => {
    setSelectedKey(() => key);
    setIsKeyFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsKeyFormOpen(() => false);
    setSelectedKey(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getKeys) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <ContentWrapper>
        <CustomButtonAdd
          style={{ height: "fit-content" }}
          defaultText="Add key"
          onClick={() => setIsKeyFormOpen(true)}
        />
      </ContentWrapper>
      {isKeyFormOpen && (
        <KeyForm
          addMessage={addMessage}
          setShow={handleCloseForm}
          loadData={loadData}
          selectedKey={selectedKey}
        />
      )}
      {!!keys?.length && (
        <KeyTable setKey={handleSetKeyFormInEditMode} keys={keys} />
      )}
    </PageWrapper>
  );
};

export default Keys;
