import React from "react";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headersArray";
import { getTeamRaws } from "./helpers/getTeamRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const PreManagerResultFinalTable = ({ consultantResults }) => {
  const { commonData: { users }} = useCommonDataContext();
  return (
    <Table
      className="styled-table"
      style={{ marginTop: "30px" }}
      headersArray={HEADERS_ARRAY}
      raws={getTeamRaws(consultantResults, users)}
    />
  );
};

export default PreManagerResultFinalTable;
