import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonsWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
`;

export const Title = styled.h3`
	margin-bottom: 20px;
	color: ${Colors.darkBlue};
`;

export const InfoTitle = styled.span`
	font-weight: bold;
	color: ${Colors.darkBlue};
	font-size: 20px;
	margin-bottom: 20px;
`;

export const AddressCompareWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const AddressPart = styled.span`
	color: ${props => props.color};
	font-weight: ${props => props.fontWeight};
	text-decoration: ${props => props.underline ? "underline" : "none"};
`;
