export const getCustomStyles = (width) => {
  return {
    container: (provided) => ({
      ...provided,
      width: `${width}px`,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      width: `${width}px`,
    }),
    menu: (provided) => ({
      ...provided,
      width: `${width}px`,
    }),
  };
};