import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import React, { useRef, useState } from "react";
import { ButtonWrapper } from "../../Keys.styled";
import {
  InputsWrapper,
  TextareaTitle,
  TextareaWrapper,
} from "./KeyForm.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { preventFn } from "@/common/functions/preventEvent";
import { KEY_TYPES_OPTIONS } from "@/common/constants/keyTypes";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createKey, updateKey } from "@/API/repositories/key";
import Loading from "@/common/components/Loading";

const KeyForm = ({ setShow, addMessage, loadData, selectedKey }) => {
  const [selectedKeyType, setSelectedKeyType] = useState();

  const valueRef = useRef();
  const labelRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleCreateKey = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.label = labelRef.current.value;
    payload.value = valueRef.current.value;
    payload.type = selectedKeyType?.value || selectedKey?.type;
    payload._id = selectedKey?._id;

    const response = selectedKey
      ? await makeRequest(updateKey.bind(null, payload))
      : await makeRequest(createKey.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setShow(() => false);
    addMessage("Success", "success");
    loadData();
  };

  return (
    <PopUp padding="10px 20px 20px" setShow={setShow}>
      {hasUnfilledRequest(createKey) && <Loading />}
      <form onSubmit={(e) => handleCreateKey(e)}>
        <InputsWrapper>
          <SelectInput
            setSelected={setSelectedKeyType}
            selected={
              selectedKeyType ||
              KEY_TYPES_OPTIONS.find(
                (option) => option.value === selectedKey?.type
              )
            }
            options={KEY_TYPES_OPTIONS}
            required
            color={Colors.darkBlue}
            width={70}
            hideStar
            name="Type"
          />
          <Input
            inputRef={labelRef}
            required
            color={Colors.darkBlue}
            width={70}
            name="Label"
            value={selectedKey?.label}
          />
        </InputsWrapper>
        <TextareaWrapper>
          <TextareaTitle>Value:</TextareaTitle>
          <textarea
            ref={valueRef}
            required
            placeholder="Type..."
            rows={2}
            cols={73}
            defaultValue={selectedKey?.value}
          />
        </TextareaWrapper>
        <ButtonWrapper>
          <CustomButtonSave
            styles={{ margin: 0 }}
            color={Colors.orange}
            text={`${selectedKey ? "Update" : "Create"} key`}
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default KeyForm;
