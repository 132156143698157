import { findSuperior } from "@/common/functions/findSuperior";

const { getNumberOfRole } = require("./getNumberOfRole");

export const formatUsers = (usersToSort, active = true) => {
  return usersToSort
    .filter((a) => a.active === active)
    .sort((a, b) => {
      return findSuperior(a?._id, usersToSort)?.username.localeCompare(findSuperior(b?._id, usersToSort)?.username);
    })
    .sort(
      (a, b) =>
        getNumberOfRole(a?.role?.toLowerCase()) -
        getNumberOfRole(b?.role?.toLowerCase())
    );
};
