export const getOrdersRange = ({
  stars_percents,
  workingDays,
  dayHours,
  constantMultiplier,
}) =>
  stars_percents
    .sort(
      (starsPercentA, starsPercentB) =>
        starsPercentA.percents - starsPercentB.percents
    )
    .map((starsPercent) => {
      return Math.round(
        (starsPercent.percents * workingDays * dayHours * constantMultiplier) /
          100
      );
    });
