import Api from '../api'
import { errorHandler } from '../common';

import userManager from '../userManager';
const { axios: api } = Api;

export const getTranslationByLang = () => errorHandler(async () => {
    const {id} = userManager.getUser(); 
    const data = await api.get(`/translation/${id}`);
    return {data: data.data };   
})

export const addNewTranslation = (word) => errorHandler(async () => {
    const data = await api.post(`/translation/`, {word: word});
    return {data: data.data };   
})

export const findTranslation = (text) => errorHandler(async () => {
    const data = await api.get(`/translation/search/${text}`);
    return {data: data.data };   
})

export const updateTranslation = (id,label) => errorHandler(async () => {
    const data = await api.patch(`/translation/${id}/${label}`);
    return {data: data.data };   
})
