import React from "react";
import PopUp from "./PopUp";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../colors/colors";

const list = [
  {
    name: "Team Cur",
    link: "/consultant/team-leader/grade",
    icon: "fa fa-table",
  },
  {
    name: "Listening Scoring",
    link: "/consultant/team-leader/listening-scoring",
    icon: "fa fa-table",
  },
  {
    name: "Last results",
    link: "/consultant/team-leader/result",
    icon: "fa fa-table",
  },
  {
    name: "Listening",
    link: "/consultant/team-leader/listening",
    icon: "fa fa-ear-listen",
  },
  {
    name: "Suspicions",
    link: "/consultant/team-leader/suspicion",
    icon: "fa fa-circle-exclamation",
  },
  {
    name: "Hours",
    link: "/consultant/team-leader/hours",
    icon: "fa fa-clock",
  },
  {
    name: "Daily Report",
    link: "/consultant/team-leader/daily-report",
    icon: "fa fa-calendar",
  },
  {
    name: "Recruitment",
    link: "/consultant/team-leader/recruitment",
    icon: "fa fa-mobile"
  }
];

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: -20px;
  max-width: 600px;
`;

const NavigateElement = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 15px;
  gap: 20px;
  width: 200px;
  color: ${Colors.darkBlue};

  &:hover {
    transform: scale(1.05);
    color: ${Colors.lightBlue};
  }
`;

const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 30px;
  margin-top: -30px;
`;

const TeamLeaderPanel = ({ setShow }) => {
  return (
    <PopUp setShow={setShow}>
      <Title>Team options:</Title>
      <Wrapper>
        {list.map((element) => (
          <NavigateElement
            key={element.name}
            to={element.link}
            onClick={() => setShow(() => null)}
          >
            <i className={element.icon} />
            {element.name}
          </NavigateElement>
        ))}
      </Wrapper>
    </PopUp>
  );
};

export default TeamLeaderPanel;
