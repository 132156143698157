import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";

const SentOrdersTable = ({ data, monthly }) => {
  return (
    <Table className="styled-table" raws={data} headers={HEADERS(monthly)} />
  );
};

export default SentOrdersTable;
