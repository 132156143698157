import React, { useState, useRef, useMemo, useEffect } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../../common/messageProvider";
import Loading from "../../../common/components/Loading";
import { getClaimsReport } from "../../../API/repositories/reports";
import Table from "../../../common/components/Table";
import { addInvoiceCorrectionNumber } from "../../../API/repositories/tickets";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SearchBar from "./components/searchBar/SearchBar";
import { getHeaders } from "./helpers/getHeaders";
import { getRaws } from "./helpers/getRaws";
import InvoiceCorrectionNumber from "./components/invoiceCorrectionNumber/InvoiceCorrectionNumber";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { saveAs } from "@/common/functions/saveAs";
import { getMarketById } from "./helpers/getMarketById";
import { findCompanyNameById } from "@/common/functions/findCompanyNameById";
import { getCompanies } from "@/API/repositories/company";
import { checkCorrection } from "./helpers/checkCorrection";
import { checkInvoiceCorrection } from "./helpers/checkInvoiceCorrection";
import { getPdfsByIds } from "@/API/repositories/storedDocument";
const JSZip = require("jszip");
const zip = new JSZip();

const ClaimsReport = () => {
  const [companies, setCompanies] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [orderWithInvoice, setOrderWithInvoice] = useState();
  const [orders, setOrders] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { removeMessage, messages, addMessage } = useMessageQueue();
  const {
    commonData: { markets },
  } = useCommonDataContext();

  const correctionNumberFilterRef = useRef();
  const nameRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const correctionNumberRef = useRef();

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (!response?.data) {
      return;
    }

    setCompanies(() => response.data);
  };

  const handleSearch = async () => {
    if (!selectedMarket?.value) {
      return addMessage("Select market", "error");
    }

    if (!selectedProduct) {
      return addMessage("Select product", "error");
    }

    if (!fromDateRef.current?.value || !toDateRef.current?.value) {
      return addMessage("Select all dates", "error");
    }

    const payload = {};

    payload.market = selectedMarket.value;
    payload.products = [selectedProduct.value];
    payload.fromDate = new Date(fromDateRef.current.value);
    payload.toDate = new Date(toDateRef.current.value);
    payload.name = nameRef.current?.value?.trim();

    const response = await makeRequest(getClaimsReport.bind(null, payload));

    if (!response.data) {
      return addMessage("Something went wrong", "error");
    }

    if (response.data) {
      setOrders(() => response.data);
    }
  };

  const handleGetAllMonthFiles = async () => {
    const ids = orders
      .filter((order) =>
        correctionNumberFilterRef.current?.checked
          ? checkCorrection(order)
          : true
      )
      .reduce(
        (acc, order) => [
          ...acc,
          ...(order._stored_docs?.map((doc) => doc._id) || []),
        ],
        []
      );

    const query = {};

    if (!ids?.length) {
      return addMessage("No stored documents", "error");
    }

    query.ids = ids;

    const response = await makeRequest(getPdfsByIds.bind(null, query));
    if (response.data) {
      const files = response.data;

      files.forEach((file) => {
        zip.file(file.name, file.data.data);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(
          content,
          `${findCompanyNameById(
            companies,
            selectedCompany.value
          )}_claim_Confirmation_${getMarketById(
            selectedMarket.value,
            markets
          )?.short.toUpperCase()}_${formatToDateTamplate(
            fromDateRef.current?.value
          )}_${formatToDateTamplate(toDateRef.current?.value)}.zip`
        );
      });

      files.forEach((file) => {
        zip.remove(file.name);
      });
    }
  };

  const handleUpdatingInvoiceCorrectionNumber = async () => {
    if (!correctionNumberRef.current?.value) {
      return addMessage("Enter correction number", "error");
    }

    const response = await makeRequest(
      addInvoiceCorrectionNumber.bind(
        null,
        checkInvoiceCorrection(orderWithInvoice)._id,
        correctionNumberRef.current.value
      )
    );

    if (!response.data) {
      addMessage("Couldn't add correction number");
    }

    if (response.data) {
      setOrderWithInvoice(() => null);
      await handleSearch();
      addMessage("Saved", "success");
    }
  };

  const headers = useMemo(() => {
    if (orders && orders[0]) {
      return getHeaders(orders);
    }
  }, [orders]);

  const raws = useMemo(() => {
    if (orders && orders[0]) {
      return getRaws({
        orders,
        correctionNumberFilterRef,
        markets,
        setOrderWithInvoice,
      });
    }
  }, [orders]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getClaimsReport, getPdfsByIds, getCompanies) && (
        <Loading />
      )}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <SearchBar
        companies={companies}
        handleGetAllMonthFiles={handleGetAllMonthFiles}
        setSelectedProduct={setSelectedProduct}
        setSelectedMarket={setSelectedMarket}
        selectedProduct={selectedProduct}
        selectedMarket={selectedMarket}
        nameRef={nameRef}
        fromDateRef={fromDateRef}
        toDateRef={toDateRef}
        correctionNumberFilterRef={correctionNumberFilterRef}
        orders={orders}
        handleSearch={handleSearch}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
      {orders && orders[0] && (
        <Table
          headers={headers}
          raws={raws}
          className="styled-table styled-table-2"
        />
      )}
      {orderWithInvoice && (
        <InvoiceCorrectionNumber
          setOrderWithInvoice={setOrderWithInvoice}
          handleUpdatingInvoiceCorrectionNumber={
            handleUpdatingInvoiceCorrectionNumber
          }
          correctionNumberRef={correctionNumberRef}
          orderWithInvoice={orderWithInvoice}
        />
      )}
    </WrapperWithScroll>
  );
};

export default ClaimsReport;
