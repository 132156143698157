import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { ButtonWrapper } from "@/common/components/todoForm/TodoForm.styled";
import React, { useRef } from "react";

const TopicForm = ({ setShow, formik }) => {
  const labelRef = useRef();
  const valueRef = useRef();
  const fileRequiredRef = useRef();
  const orderRequiredRef = useRef();

  const handleAddTopic = () => {
    if (!labelRef.current?.value || !valueRef.current?.value) {
      return;
    }

    formik.setFieldValue("topics", [
      ...formik.values.topics,
      {
        label: labelRef.current.value,
        value: valueRef.current.value,
        order_required: orderRequiredRef.current.checked,
        file_required: fileRequiredRef.current.checked,
      },
    ]);
    setShow(false);
  };

  return (
    <PopUp padding="10px 20px 10px" setShow={setShow}>
      <Input
        inputRef={labelRef}
        name="Label"
        width={120}
        color={Colors.darkBlue}
      />
      <Input
        inputRef={valueRef}
        name="Value"
        width={120}
        color={Colors.darkBlue}
      />
      <ToggleSwitch
        width={130}
        style={{ margin: "8px 0" }}
        text="File required"
        toggleRef={fileRequiredRef}
      />
      <ToggleSwitch
        width={130}
        text="Order required"
        style={{ margin: "8px 0" }}
        toggleRef={orderRequiredRef}
      />
      <ButtonWrapper>
        <CustomButtonAdd defaultText="Add" onClick={() => handleAddTopic()} />
      </ButtonWrapper>
    </PopUp>
  );
};

export default TopicForm;
