import moment from "moment";

const getWeekIndex = (date) => {
  const momentDate = moment(date);

  const startOf = momentDate.startOf("isoWeek").format("DD/MM/YYYY");
  const endOf = momentDate.endOf("isoWeek").format("DD/MM/YYYY");

  return `${startOf}-${endOf}`;
};

export const generateRaws = (
  data,
  selectedType,
  checked,
  selectedLangPair = [],
  monthly
) => {
  const mapToShow = new Map();

  data
    .filter((o) => {
      if (checked && o?.order_items.length === 1) {
        return true;
      }

      if (checked && o?.order_items.length !== 1) {
        return false;
      }

      return true;
    })
    .forEach((order) => {
      const deliveredDate =
        order.shipping.status_delivered_no_payment_at ||
        order.shipping.status_delivered_at;

      const date =
        selectedType.value === "month"
          ? moment(deliveredDate).format("MM/YYYY")
          : getWeekIndex(deliveredDate);

      order.order_items.map((oi) => {
        const index = monthly ? date : date + "_" + oi.product_variant;

        if (
          selectedLangPair?.length === 0 ||
          selectedLangPair?.map((d) => d.value).includes(oi.product_variant)
        ) {
          if (mapToShow.has(index)) {
            return mapToShow.get(index).push(order);
          }

          mapToShow.set(index, [order]);
        }
      });
    });

  const result = [];

  Array.from(mapToShow)
    .sort(([_1, value_1], [_2, value_2]) => {
      const deliveredDateA =
        value_1[0].shipping.status_delivered_no_payment_at ||
        value_1[0].shipping.status_delivered_at;
      const deliveredDateB =
        value_2[0].shipping.status_delivered_no_payment_at ||
        value_2[0].shipping.status_delivered_at;

      const a = new Date(deliveredDateA).getTime();
      const b = new Date(deliveredDateB).getTime();

      return a - b;
    })
    .map(([key, value]) => {
      const claims = value.reduce(
        (a, b) => a + (b.status === "claim" ? 1 : 0),
        0
      );
      const element = (
        <tr>
          <td>{key.split("_")[0]}</td>
          {key.split("_")[1] && <td>{key.split("_")[1]}</td>}
          <td>{value.length}</td>
          <td>{claims}</td>
          <td>
            {" "}
            {(Math.round((claims / value.length) * 10000) / 100)
              .toString()
              .replace(".", ",")}{" "}
            %
          </td>
        </tr>
      );
      result.push(element);
    });

  return result;
};

export const generateRawsCSV = (
  data,
  selectedType,
  checked,
  selectedLangPair = [],
  monthly
) => {
  const mapToShow = new Map();

  data
    .filter((o) => {
      if (checked && o?.order_items.length === 1) {
        return true;
      }

      if (checked && o?.order_items.length !== 1) {
        return false;
      }

      return true;
    })
    .forEach((order) => {
      const deliveredDate =
        order.shipping.status_delivered_no_payment_at ||
        order.shipping.status_delivered_at;

      const date =
        selectedType.value === "month"
          ? moment(deliveredDate).format("MM/YYYY")
          : getWeekIndex(deliveredDate);

      order.order_items.map((oi) => {
        const index = monthly ? date : date + "_" + oi.product_variant;

        if (
          selectedLangPair?.length === 0 ||
          selectedLangPair?.map((d) => d.value).includes(oi.product_variant)
        ) {
          if (mapToShow.has(index)) {
            return mapToShow.get(index).push(order);
          }

          mapToShow.set(index, [order]);
        }
      });
    });

  const result = [];

  Array.from(mapToShow)
    .sort(([_1, value_1], [_2, value_2]) => {
      const deliveredDateA =
        value_1[0].shipping.status_delivered_no_payment_at ||
        value_1[0].shipping.status_delivered_at;
      const deliveredDateB =
        value_2[0].shipping.status_delivered_no_payment_at ||
        value_2[0].shipping.status_delivered_at;

      const a = new Date(deliveredDateA).getTime();
      const b = new Date(deliveredDateB).getTime();

      return a - b;
    })
    .map(([key, value]) => {
      const claims = value.reduce(
        (a, b) => a + (b.status === "claim" ? 1 : 0),
        0
      );

      if (key.split("_")[1]) {
        const element = [
          key.split("_")[0],
          key.split("_")[1],
          value.length,
          claims,
          (Math.round((claims / value.length) * 10000) / 100)
            .toString()
            .replace(".", ","),
        ];

        result.push(element);
      } else {
        const element = [
          key.split("_")[0],
          value.length,
          claims,
          (Math.round((claims / value.length) * 10000) / 100)
            .toString()
            .replace(".", ","),
        ];

        result.push(element);
      }
    });

  return result;
};
