import Api from "../api";
import { errorHandler } from "../common";
const queryString = require("query-string");

const ENTITY_NAME = "gamification-points";

const { axios: api } = Api;

export const getGamificationPointsForUserByQuery = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `${ENTITY_NAME}/for-consultant-by-payload?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );

    return { data: data.data };
  });

export const getLastGrades = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `${ENTITY_NAME}/last-grades?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );

    return { data: data.data };
  });

export const getGamificationPointsByQuery = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `${ENTITY_NAME}/for-admin-by-payload?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
