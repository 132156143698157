import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TicketCreateWrapper = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${Colors.purple};
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
`;

export const TicketsSearchBarWrapper = styled.div`
  position: relative;
`;

export const TicketsSearchBar = styled.input`
  width: 100%;
  outline: none;
`;

export const TicketsSearchIcon = styled.i`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const TitleWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  background-color: ${Colors.darkBlue};
  padding: 20px 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 60%;
`;

export const GearButton = styled.button`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  border-radius: 4px;

  border: 1px solid ${Colors.darkGray};
`;

export const SettingsWrapper = styled.div`
  height: 36px;
  width: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Connector = styled.div`
  width: 30%;
  top: 0;
  bottom: 0;
  background-color: #fff;
  position: absolute;
  right: -20%;
  border-top: 1px solid ${Colors.darkGray};
  border-bottom: 1px solid ${Colors.darkGray};
  transition: all 0.4s;
  transition-delay: ${(props) => (props.visible ? 0 : "0.05s")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: ${(props) => (props.visible ? 2 : -1)};
`;

export const AdvancedSettings = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 6.8px 1px rgba(54, 95, 162, 0.3);
  position: absolute;
  left: 117%;
  border: 1px solid ${Colors.darkGray};
  border-radius: 10px;
  border-top-left-radius: 0;
  top: calc(100% - 36px);
  transition: all 0.4s;
  transition-delay: ${(props) => (props.visible ? "0.05s" : 0)};
  transform: ${(props) => (props.visible ? "scale(1)" : "scale(0.1)")};
  transform-origin: left top;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: ${(props) => (props.visible ? 1 : -1)};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const AdvancedSettingsCloser = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
`;
