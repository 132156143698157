import React from "react";
import {
  StarsWrapper,
  StarItemWrapper,
  StarWrapper,
  StarImage,
  StarCoverWrapper,
  StarImageCover,
} from "./Stars.styled";
import {} from "../../../Gamification.styled";
import { STARS_PERCENTAGE } from "../../../components/user/components/orders/constants/orders";
import GrayStar from "@/images/icons/gamification-gray-star.svg";
import FilledStar from "@/images/icons/gamification-filled-star.svg";
import Cry from "@/images/icons/gamification-cry.svg";
import { getStarCoverWidth } from "./helpers/getStarCoverWidth";

const Stars = ({
  ordersRange,
  deliveredPercent = 0,
  deliveredRange,
  totalAmount = 0,
  orderWidth,
  marginBottom = 20,
  showNotCompleted = true,
  extraStar = false,
  potentialExtraStar = false,
}) => {
  const maximumStarsAmount = potentialExtraStar
    ? ordersRange.length + 1
    : ordersRange.length;
  return (
    <StarsWrapper
      marginBottom={marginBottom}
      gap={`${(orderWidth / maximumStarsAmount) * (1 - STARS_PERCENTAGE)}px`}
    >
      {showNotCompleted
        ? ordersRange.map((step, i) => (
            <StarItemWrapper>
              <StarWrapper
                width={`${
                  (orderWidth / maximumStarsAmount) * STARS_PERCENTAGE
                }px`}
              >
                <StarImage alt="gray-star" src={GrayStar} />
              </StarWrapper>
              {deliveredPercent >=
                (deliveredRange[i] || deliveredRange.at(-1)) && (
                <StarCoverWrapper
                  width={`${getStarCoverWidth({
                    maximumStarsAmount,
                    prev: ordersRange[i - 1],
                    totalAmount,
                    step,
                    orderWidth,
                  })}px`}
                >
                  <StarImageCover alt="filled-star" src={FilledStar} />
                </StarCoverWrapper>
              )}
            </StarItemWrapper>
          ))
        : totalAmount >= ordersRange[0] && deliveredPercent >= deliveredRange[0]
        ? ordersRange.map(
            (step, i) =>
              totalAmount >= step &&
              deliveredPercent >=
                (deliveredRange[i] || deliveredRange.at(-1)) && (
                <StarItemWrapper>
                  <StarWrapper
                    width={`${
                      (orderWidth / maximumStarsAmount) * STARS_PERCENTAGE
                    }px`}
                  >
                    <StarImage alt="gray-star" src={GrayStar} />
                  </StarWrapper>
                  <StarCoverWrapper
                    width={`${getStarCoverWidth({
                      maximumStarsAmount,
                      prev: ordersRange[i - 1],
                      totalAmount,
                      step,
                      orderWidth,
                    })}px`}
                  >
                    <StarImageCover alt="filled-star" src={FilledStar} />
                  </StarCoverWrapper>
                </StarItemWrapper>
              )
          )
        : !extraStar && <img alt="cry" src={Cry} />}
      {extraStar && (
        <StarItemWrapper>
          <StarWrapper
            width={`${(orderWidth / maximumStarsAmount) * STARS_PERCENTAGE}px`}
          >
            <StarImage alt="gray-star" src={GrayStar} />
          </StarWrapper>
          <StarCoverWrapper
            width={`${getStarCoverWidth({
              maximumStarsAmount,
              prev: 0,
              totalAmount: 0,
              step: 0,
              orderWidth,
            })}px`}
          >
            <StarImageCover alt="filled-star" src={FilledStar} />
          </StarCoverWrapper>
        </StarItemWrapper>
      )}
    </StarsWrapper>
  );
};

export default Stars;
