import { Colors } from "@/common/colors/colors";
import Icon from "@/common/components/Icon";
import { CANDIDATE_INTERACTION_STATUSES, STATUSES_LABELS } from "@/common/constants/recruitmentStatuses";
import { compareDates } from "@/common/functions/compareDates";

export const getRaws = (candidates, setShowCandidateForm) => candidates
  .sort((a, b) => compareDates(new Date(a.created_at), new Date(b.created_at)))
  .sort(
  (a, b) => STATUSES_LABELS.find(l => l.value === a.status)?.weight - STATUSES_LABELS.find(l => l.value === b.status)?.weight
  )
  .map((candidate, index) => (
  <tr 
    style={{ 
      backgroundColor: candidate.status === CANDIDATE_INTERACTION_STATUSES.REJECT && Colors.darkGray2,
    }} 
    key={candidate._id}
  >
    <td>{index + 1}</td>
    <td>{candidate.first_name} {candidate.last_name}</td>
    <td>{candidate.email}</td>
    <td>{candidate.phone}</td>
    <td>{STATUSES_LABELS.find(l => l.value === candidate.status)?.label || "----"}</td>
    <td>
      <Icon onClick={() => setShowCandidateForm(candidate)} name="fa fa-edit" />
    </td>
  </tr>
));
