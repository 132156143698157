import Card from '@/common/components/Card'
import React, { useEffect, useState } from 'react'
import { ButtonWrapper, SearchBar } from '../../Recruitment.styled'
import { Wrapper } from './RecruitmentSearchBar.stlyled'
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'
import SelectInput from '@/common/components/SelectInput'
import { Colors } from '@/common/colors/colors'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { CONSULTANT_MANAGER_ROLES } from '@/common/constants/consultantRoles'
import { STATUSES_LABELS } from '@/common/constants/recruitmentStatuses'

const RecruitmentSearchBar = ({ setShowCandidateForm, setVisibleCandidates, candidates }) => {
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  
  const { options: { marketsOptions }, filterUsersByRoles } = useCommonDataContext();

  const handleFilterMarket = (value) => {
    setSelectedMarket(value);
    setVisibleCandidates(candidates.filter(candidate => (
      !value?.value || candidate.market === value.value) 
      && (!selectedManager?.value || candidate.call_center_manager === selectedManager.value) 
      && (!selectedStatus?.value || candidate.status === selectedStatus.value))
    );
  }

  const handleFilterManager = (value) => {
    setSelectedManager(value);
    setVisibleCandidates(candidates.filter(candidate => (
      !selectedMarket?.value || candidate.market === selectedMarket.value) 
      && (!value?.value || candidate.call_center_manager === value.value) 
      && (!selectedStatus?.value || candidate.status === selectedStatus.value)));
  }

  const handleFilterStatus = (value) => {
    setSelectedStatus(value);
    setVisibleCandidates(candidates.filter(candidate => (
      !selectedMarket?.value || candidate.market === selectedMarket.value) 
      && (!selectedManager?.value || candidate.call_center_manager === selectedManager.value) 
      && (!value?.value || candidate.status === value.value))
    );
  }

  const handleDefaultFilter = () => {
    setVisibleCandidates(candidates.filter(candidate => (
      !selectedMarket?.value || candidate.market === selectedMarket.value) 
      && (!selectedManager?.value || candidate.call_center_manager === selectedManager.value) 
      && (!selectedStatus?.value || candidate.status === selectedStatus.value))
    );
  }

  useEffect(() => {
    handleDefaultFilter();
  }, [candidates])

  return (
    <Card>
      <SearchBar>
        <Wrapper>
          <div style={{ display: "flex"}}>
            <SelectInput
              options={[{ label: "None", value: null }, ...filterUsersByRoles(CONSULTANT_MANAGER_ROLES)]} 
              color={Colors.darkBlue} 
              name="Manager" 
              selectWidth={200} 
              width={80}  
              selected={selectedManager}
              setSelected={handleFilterManager}
            />
            <SelectInput 
              options={[{ label: "None", value: null }, ...marketsOptions]} 
              color={Colors.darkBlue} 
              name="Market" 
              selectWidth={200} 
              width={80}
              selected={selectedMarket}
              setSelected={handleFilterMarket}  
            />
          </div>
        </Wrapper>
        <Wrapper>
            <SelectInput
              name="Status"
              options={[{ label: "None", value: null }, ...STATUSES_LABELS]}
              color={Colors.darkBlue}
              selectWidth={200} 
              width={80}
              selected={selectedStatus}
              setSelected={handleFilterStatus}
            />
        </Wrapper>
        <ButtonWrapper>
          <CustomButtonAdd
            onClick={() => setShowCandidateForm(true)}
            defaultText="Add candidate"
          />
        </ButtonWrapper>
      </SearchBar>
    </Card>
  )
}

export default RecruitmentSearchBar
