import React, { useRef } from "react";
import styled from "styled-components";
import { createMarket } from "../../../../API/repositories/market";
import Form from "../../../../common/components/Form";
import Input from "../../../../common/components/Input";
import { MarketCreate } from "../../../../common/Model/MarketModels";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import Loading from "../../../../common/components/Loading";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
`;

const MarketNew = ({ close, addMessage }) => {
  const { queryFunctions } = useCommonDataContext();
  const { refetch } = queryFunctions;
  const nameRef = useRef();
  const currenyRef = useRef();
  const shortRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    const currency = currenyRef.current.value;
    const short = shortRef.current.value;

    const market = new MarketCreate();
    market.name = name;
    market.currency = currency;
    market.short = short;

    const response = await makeRequest(createMarket.bind(null, market));

    if (response.data) {
      addMessage("Created", "success");
      refetch();
      close();
    } else {
      addMessage("Couldn't create", "error");
    }
  };

  return (
    <>
      {hasUnfilledRequest(createMarket) && <Loading />}

      <Wrapper>
        <h3>Create Market</h3>
        <Form
          onSubmit={handleSubmit}
          styled={{ display: "flex", justifyContent: "center" }}
        >
          <Input
            inputRef={nameRef}
            name="Name"
            value=""
            required={true}
            disabled={false}
            width={100}
          />

          <Input
            inputRef={currenyRef}
            name="Currency"
            value=""
            required={true}
            disabled={false}
            width={100}
          />

          <Input
            inputRef={shortRef}
            name="Short"
            value=""
            required={true}
            disabled={false}
            width={100}
          />

          <button className="btn btn-warning">Save</button>
        </Form>
      </Wrapper>
    </>
  );
};

export default MarketNew;
