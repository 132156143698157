import React from "react";

const ResultTable = ({ data }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Position</th>
          <th>Username</th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data[0] ? (
          data.map((d) => (
            <tr key={d._id}>
              <td>{d.position}</td>
              <td>{d.username}</td>
              <td>{d.score}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>There is no Data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ResultTable;
