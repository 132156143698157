import React from "react";
import Input from "../../../common/components/Input";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";

const SubTitle = styled.h5`
  margin: 10px;
  color: ${Colors.darkBlue};
`;

const UTMWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UTMsView = ({ order }) => {
  return (
    <>
      <SubTitle>
        <u>UTM:</u>
      </SubTitle>
      <UTMWrapper>
        <div>
          <Input
            name="utm_campaign"
            width={150}
            value={order.utm?.utm_campaign}
            disabled
            color={Colors.darkBlue}
          />
          <Input
            name="utm_content"
            width={150}
            value={order.utm?.utm_content}
            color={Colors.darkBlue}
            disabled
          />
          <Input
            name="utm_medium"
            width={150}
            value={order.utm?.utm_medium}
            disabled
            color={Colors.darkBlue}
          />
        </div>
        <div>
          <Input
            name="utm_source"
            width={150}
            value={order.utm?.utm_source}
            disabled
            color={Colors.darkBlue}
          />
          <Input
            name="utm_term"
            width={150}
            value={order.utm?.utm_term}
            color={Colors.darkBlue}
            disabled
          />
        </div>
      </UTMWrapper>
    </>
  );
};

export default UTMsView;
