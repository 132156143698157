import { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./common/contexts/authContext";
import MessageQueue, { useMessageQueue } from "./common/messageProvider";

import userManager from "./API/userManager";
import AccesValidator from "./AccesValidator";
import translationManager from "./API/translationManager";
import api from "./API/api";
import routes from "./common/routes/routes";

function App() {
  const { tokenManager } = api;

  const { setAuthUser } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { removeMessage, messages } = useMessageQueue();

  useEffect(() => {
    const user = tokenManager.getToken();
    if (!user) {
      navigate("/login");
      return;
    }

    if (user) {
      setAuthUser(tokenManager.parseJwt(user));
      userManager.setUser(tokenManager.parseJwt(user));
      translationManager.getLanguage();
    }
    if (pathname === "/" && user) {
      const { role } = tokenManager.parseJwt(user);
      if (role === "CALL_CENTER_MANAGER" || role === "CALL_CENTER_OPERATOR") {
        navigate("/consultant/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <>
      <AccesValidator />
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
            Component={route.component}
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
