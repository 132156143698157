import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
  max-height: 95vh;
  overflow-y: scroll;
`;

export const SearchBarStyle = styled.form`
  border-radius: 10px;
  background-color: ${Colors.lightGray};
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  min-width: 800px;
  padding: 5px 40px;

  input {
    border: none;
    color: ${Colors.darkBlue};
  }

  label: {
    width: fit-content;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const ScoreStatsWrapper = styled.div`
  display: flex;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const Text = styled.h3`
  color: ${Colors.red};
  margin-bottom: 20px;
`;

export const ButtonWrapperCenter = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;
