import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createDashboardSettings = (body) =>
  errorHandler(async () => {
    const data = await api.post("/dashboard-settings", body);

    return { data: data.data };
  });

export const getDashboardSettings = () =>
  errorHandler(async () => {
    const data = await api.get("/dashboard-settings");

    return { data: data.data };
  });

export const updateDashboardSettings = (body) =>
  errorHandler(async () => {
    const data = await api.patch(`/dashboard-settings/`, body);

    return { data: data.data };
  });
