import React, { useEffect, useState } from "react";
import {
  AddTodoButton,
  AddTodoButtonWrapper,
  CustomSkeletonWrapper,
  TodoItemsContainer,
  TodoItemsWrapper,
  TodoListCloser,
  TodoListContainer,
  TodoListTab,
  TodoListTabsWrapper,
  TodoListWrapper,
  TodoStatusWrapper,
} from "./TodoList.styled";
import Icon from "@/common/components/Icon";
import TodoForm from "@/common/components/todoForm/TodoForm";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { TODO_TYPE } from "../../constants";

import {
  getConsultantManagerTodos,
  markSeenByUser,
  updateTodoStatus,
} from "@/API/repositories/todo";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { Colors } from "@/common/colors/colors";
import TodoStatus from "./components/todoStatus/TodoStatus";
import { TODO_STATUSES } from "@/common/constants/todoStatuses";

const TodoList = ({
  setIsOpen,
  addMessage,
  loadData,
  setPersonalTodos,
  setAssignedTodos,
  personalTodos,
  assignedTodos,
  notRead,
}) => {
  const [activeType, setActiveType] = useState(TODO_TYPE.ASSIGNED);
  const [visibleTodos, setVisibleTodos] = useState(
    activeType === TODO_TYPE.PERSONAL ? personalTodos : assignedTodos
  );
  const [visibleTodosSetter, setVisibleTodosSetter] = useState(
    () => setAssignedTodos
  );
  const [isTodoFormOpen, setIsTodoFormOpen] = useState();
  const [hiddenStatuses, setHiddenStatuses] = useState(
    Object.keys(TODO_STATUSES).map((s) => TODO_STATUSES[s].value)
  );
  const [selectedTodo, setSelectedTodo] = useState();
  const [loadingTodoId, setLoadingTodoId] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleHiddenStatusesChange = (status) => {
    if (hiddenStatuses.includes(status)) {
      return setHiddenStatuses((prev) => prev.filter((s) => s !== status));
    }

    return setHiddenStatuses((prev) => [...prev, status]);
  };

  const handleMarkSeenByUser = async () => {
    const response = await makeRequest(markSeenByUser);

    if (response?.data) {
      await loadData();
    }
  };

  const updateStatus = async (todoId, status) => {
    setLoadingTodoId(() => todoId);
    const response = await makeRequest(
      updateTodoStatus.bind(null, todoId, status)
    );

    if (!response?.data) {
      setLoadingTodoId(() => null);
      return addMessage("Something went wrong", "error");
    }

    await loadData();

    setLoadingTodoId(() => null);
    return addMessage("Success", "success");
  };

  const handleEditTodo = (todo) => {
    setSelectedTodo(() => todo);
    setIsTodoFormOpen(() => true);
  };

  const handeActiveTypeChange = (type) => {
    setActiveType(() => type);

    switch (type) {
      case TODO_TYPE.ASSIGNED:
        setVisibleTodos(() => assignedTodos);
        setVisibleTodosSetter(() => setAssignedTodos);
        break;
      case TODO_TYPE.PERSONAL:
        setVisibleTodos(() => personalTodos);
        setVisibleTodosSetter(() => setPersonalTodos);
        break;
      default:
        setVisibleTodos(() => null);
        setVisibleTodosSetter(() => () => {});
    }
  };

  useEffect(() => {
    setVisibleTodos(() =>
      activeType === TODO_TYPE.ASSIGNED ? assignedTodos : personalTodos
    );
  }, [assignedTodos, personalTodos]);

  useEffect(() => {
    handleMarkSeenByUser();
  }, []);

  return (
    <TodoListContainer>
      <TodoListCloser onClick={() => setIsOpen(false)} />
      <TodoListWrapper>
        <TodoListTabsWrapper>
          <TodoListTab
            active={activeType === TODO_TYPE.ASSIGNED}
            onClick={() => handeActiveTypeChange(TODO_TYPE.ASSIGNED)}
          >
            Assigned
          </TodoListTab>
          <TodoListTab
            active={activeType === TODO_TYPE.PERSONAL}
            onClick={() => handeActiveTypeChange(TODO_TYPE.PERSONAL)}
          >
            Personal
          </TodoListTab>
        </TodoListTabsWrapper>
        <TodoItemsContainer>
          <TodoItemsWrapper>
            {!visibleTodos && hasUnfilledRequest(getConsultantManagerTodos) && (
              <CustomSkeletonWrapper>
                <CustomSkeleton
                  width="100%"
                  height="4%"
                  count={25}
                  highlightColor={Colors.darkGray2}
                  baseColor={Colors.darkGray}
                />
              </CustomSkeletonWrapper>
            )}
            {visibleTodos && (
              <>
                <TodoStatusWrapper>
                  {Object.keys(TODO_STATUSES)
                    .filter((status) =>
                      visibleTodos.find(
                        (group) => group._id === TODO_STATUSES[status].value
                      )
                    )
                    .map((status) => (
                      <TodoStatus
                        notRead={notRead}
                        loadData={loadData}
                        loadingTodoId={loadingTodoId}
                        updateStatus={updateStatus}
                        key={TODO_STATUSES[status].value}
                        status={status}
                        todos={visibleTodos}
                        hiddenStatuses={hiddenStatuses}
                        handleHiddenStatusesChange={handleHiddenStatusesChange}
                        handleEditTodo={handleEditTodo}
                      />
                    ))}
                </TodoStatusWrapper>
              </>
            )}
          </TodoItemsWrapper>
        </TodoItemsContainer>
        <AddTodoButtonWrapper>
          <AddTodoButton onClick={() => setIsTodoFormOpen((prev) => !prev)}>
            <Icon name="fa fa-plus" color="#fff" size={28} />
          </AddTodoButton>
        </AddTodoButtonWrapper>
      </TodoListWrapper>
      {isTodoFormOpen && (
        <TodoForm
          selectedTodo={selectedTodo}
          setSelectedTodo={setSelectedTodo}
          loadData={loadData}
          setTodos={visibleTodosSetter}
          addMessage={addMessage}
          setIsOpen={setIsTodoFormOpen}
        />
      )}
    </TodoListContainer>
  );
};

export default TodoList;
