import { Colors } from "@/common/colors/colors";
import { getLastTicketNotification } from "./getLastTicketNotification";

export const getTicketBackgroundColor = (
  ticket,
  selectedTicket,
  currentUserId
) => {
  if (selectedTicket?._id === ticket?._id) {
    return Colors.newBlue;
  }

  const lastNotification = getLastTicketNotification(ticket);

  if (ticket?.type === "correction") {
    return Colors.red;
  }

  if (
    (lastNotification.sender || lastNotification.created_by) !==
      currentUserId &&
    !lastNotification.seen?.includes(currentUserId)
  )
    return Colors.newLightGray;

  return "#fff";
};
