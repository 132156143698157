import { getMarketById } from "./getMarketById";
import { formatDate } from "@/common/functions/dateFormater";
import { getVatLabel } from "./getVatLabel";
import { sortByInvoiceNumbers } from "../components/invoicesReportTable/helpers/sortByInvoiceNumber";

export const getExcelData = (
  orders,
  markets,
  correctionNumber,
  netto,
  brutto
) =>
  sortByInvoiceNumbers(orders)
    .filter((order) =>
      correctionNumber ? !!order._ticket.invoice.correction_number : true
    )
    .map((order, i) => ({
      No: i + 1,
      Kraj: getMarketById(order.market, markets)?.name,
      "Miesiąc wystawienia": formatDate(
        order._ticket.invoice.issue_date,
        "YYYY-MM"
      ),
      "Data sprzedaży": formatDate(
        order.shipping.status_delivered_at ||
          order.shipping.status_delivered_no_payment_at,
        "YYYY-MM-DD"
      ),
      "Data wystawienia faktury": formatDate(
        order._ticket.invoice.issue_date,
        "YYYY-MM-DD"
      ),
      "Nr Faktury": order._ticket.invoice.invoice_number || "----",
      "Korekta Faktury": order._ticket.invoice.correction_number || "----",
      "Nazwa Firmy Klienta na wystawionej fakturze":
        order._ticket.invoice.comapny_name,
      "Nazwa Klienta (osoba fizyczna w specyfikacji excel od pośrednika)":
        order._contact?.full_name,
      [netto]: order.order_items.reduce((prev, next) => prev + next.price, 0),
      "Kwota VAT": getVatLabel(order._ticket.invoice.vat_amount),
      [brutto]: order.order_items.reduce((prev, next) => prev + next.price, 0),
    }));
