import { clear } from "@testing-library/user-event/dist/clear";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";

const Wrapper = styled.div`
  height: 40px;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  margin-top: -30px;
  margin-bottom: 30px;
  position: absolute;
  width: 100vw;
`;

export const Timer = ({
  setShouldOffContact,
  time,
  reamainingTime,
  minutes,
  tSeconds,
}) => {
  const [restTime, setRestTime] = useState(time * 60);

  const formatTime = (currentTime) => {
    const seconds = Math.floor(currentTime % 60);
    return (
      parseInt(currentTime / 60) +
      ` ${minutes} ` +
      (seconds > 9 ? seconds : "0" + seconds) +
      ` ${tSeconds}`
    );
  };

  useEffect(() => {
    let time = restTime;
    const interval = setInterval(() => {
      if (time > 0) {
        setRestTime((prev) => prev - 1);
        time -= 1;
      } else {
        setShouldOffContact(() => true);
        clearInterval(interval);
      }
    }, [1000]);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper
      style={{
        backgroundColor:
          restTime > 10 * 60
            ? Colors.green
            : restTime > 3 * 60
            ? Colors.yellow
            : Colors.red,
      }}
    >
      <span style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}>
        <u>{reamainingTime}:</u>
      </span>{" "}
      <span style={{ color: "white", fontWeight: " bold", fontSize: "22px" }}>
        {formatTime(restTime)}{" "}
      </span>
    </Wrapper>
  );
};
