export const OPERATIONS = {
  GROUP_BY: "group_by",
  MIN: "min",
  MAX: "max",
  SUM: "sum",
  COUNT: "count",
  AVERAGE: "average",
}

export const OPERATIONS_OPTIONS = [
  {
    label: "Min",
    value: OPERATIONS.MIN,
  },
  {
    label: "Max",
    value: OPERATIONS.MAX,
  },
  {
    label: "Sum",
    value: OPERATIONS.SUM,
  },
  {
    label: "Count",
    value: OPERATIONS.COUNT,
  },
  {
    label: "Average",
    value: OPERATIONS.AVERAGE,
  },
]

export const OPERATION_ACTIONS = {
  ADD: "add",
  DELETE: "delete",
  UPDATE: "update",
  RESET: "reset",
  INIT: "init"
}