import styled from "styled-components";
import cronstrue from "cronstrue";
import { ActionIcon } from "@/components/consultant/infobar/ticket/components/conversationGroup/ConversationGroup.styled";

const ColorDot = styled.div`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  margin: 0 auto;
`;

const TextEllipsisContainer = styled.div`
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MailingReportTableItem = ({ report, index, onEdit }) => {
  const handleEdit = () => {
    onEdit(report);
  };

  const getCronTimeDescription = (cronTime) => {
    let cronTimeDescription = "";
    try {
      cronTimeDescription = cronstrue.toString(cronTime, {
        use24HourTimeFormat: true,
      });
    } catch {
      cronTimeDescription = "ERROR";
    }
    return cronTimeDescription;
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{report.type}</td>
      <td>{report.subject}</td>
      <td>
        <TextEllipsisContainer>
          {report.recipients.map((r, i) => (
            <span key={r}>{r}, </span>
          ))}
        </TextEllipsisContainer>
      </td>
      <td>{getCronTimeDescription(report.cron_time)}</td>
      <td>
        {report.active ? <ColorDot color="green" /> : <ColorDot color="red" />}
      </td>
      <td>
        <ActionIcon
          onClick={handleEdit}
          className="fas fa-edit"
          style={{ fontSize: "15px" }}
        />
      </td>
    </tr>
  );
};

export default MailingReportTableItem;
