import { AccessTokenWrapper, Icon } from "../MetaAccountsTable.styled";

export const getRaws = (accounts, setSelectedAccount) =>
  accounts.map((account) => (
    <tr>
      <td>{account.email}</td>
      <td>{account._add_account?.length || 0}</td>
      <AccessTokenWrapper>{account.access_token}</AccessTokenWrapper>
      <td>
        <Icon
          onClick={() => setSelectedAccount(account)}
          className="fa fa-edit"
        />
      </td>
    </tr>
  ));
