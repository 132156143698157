import React from 'react'
import styled from 'styled-components'

const BigCircleUp = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #025072;
`;

const SmallCircleUp = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #025072;;
  margin: 0px;
  float: right;
`;

const BigCircleDown = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #E7CF49;
  float: right;
`;

const SmallCircleDown = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E7CF49;
`;

const CircleBoxUp = styled.div`
  width: 140px;
`;

const CircleBoxDown = styled.div`
  width: 140px;
  margin-top: 50px;
`;

const FlexUp = styled.div`
  height: 50%;
  display: flex;
  justify-content: space-between;
`;

const FlexDown = styled.div`
  height: 50%;
  display: flex;
  justify-content: space-between;
  padding-top: 125px;
`;

const Card = styled.div`
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  width: 1200px;
  height: 700px;
  overflow: hidden;
`;

const CircleLeftDown = styled.div`
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #025072 ;
  margin-top: 0px;
  margin-left: -280px;
`;

const BoxRight = styled.div`
width: 300px;
height: 150px;
`

const OverflowBox = styled.div`
  overflow: hidden;
  margin: -20px;
  display: flex;
  margin-top: -80px;
`;
const BoxForCircle = styled.div`
  width: 300px;
  height: 150px;
  background-color: #E7CF49;
`;
const WhiteCircle = styled.div`
  background-color: white;
  width: 500px;
  height: 400px;
  margin-top: -50px;
  border-radius: 50%;
  margin-left: -100px;
`;
const RedCircle = styled.div`
  background-color: #E7CF49;
  width: 500px;
  height: 400px;
  border-radius: 50%;
  margin-top: -215px;
  margin-right: -95px;
`;



const CardView = () => {
  return (
    <Card>
    <FlexUp>
      <CircleBoxUp>
        <BigCircleUp />
        <SmallCircleUp />
      </CircleBoxUp>
      <OverflowBox>
        <div>
          <RedCircle />
        </div>
        <BoxRight>
          <BoxForCircle />
          <WhiteCircle />
        </BoxRight>
      </OverflowBox>
    </FlexUp>
    <FlexDown>
      <CircleLeftDown />
      <CircleBoxDown>
        <SmallCircleDown />
        <BigCircleDown />
      </CircleBoxDown>
    </FlexDown>
  </Card>
  )
}

export default CardView