import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const ContactsWrapper = styled.div`
  position: relative;
`;

export const ContactsList = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 500px;
  width: ${(props) => props.width};
  overflow: scroll;
  list-style: none;
  padding: 0;
  border: 1px solid ${Colors.gray};
  border-radius: 20px;
  background-color: ${Colors.lightGray};
  transition: width 0.2s;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContactListItem = styled.li`
  padding: 16px;
  border-top: 1px solid ${Colors.gray};
  background-color: ${Colors.lightlightBlue2};
  display: flex;
  justify-content: space-between;
  align-items: center;

  :first-child {
    border-top: 0;
  }
`;

export const ContactListItemElement = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${(props) => props.width};
  text-align: center;
`;
