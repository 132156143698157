import React, { useState } from "react";
import styled from "styled-components";
import PopUp from "../../../../../common/components/PopUp";
import CreateForm from "./CreateForm";

const Wrapper = styled.div`
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 20px;
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.color};
    color: white;
  }
`;

const Create = ({ text }) => {
  const [showCreate, setShowCreate] = useState(false);

  return (
    <>
      {showCreate && (
        <PopUp setShow={setShowCreate}>
          <CreateForm />
        </PopUp>
      )}
      <Wrapper
        color="#E7CF48"
        className="animation-scale"
        onClick={() => setShowCreate(() => true)}
      >
        <i className="fa fa-plus" style={{ fontSize: "30px" }} />{" "}
        <strong>{text}</strong>
      </Wrapper>
    </>
  );
};

export default Create;
