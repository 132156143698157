export const RESIGN_OPTIONS = [
  {
    label: "złe opinie",
    value: "negative_reviews",
  },
  {
    label: "nic nie zamawiałem",
    value: "no_order",
  },
  {
    label: "brak powodu",
    value: "no_reason",
  },
  {
    label: "inne",
    value: "Other",
  },
];
