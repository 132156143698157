import { getValidClaimsReport } from '@/API/repositories/reports';
import Loading from '@/common/components/Loading';
import Table from '@/common/components/Table';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue';
import moment from 'moment';
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { getHeadersArray } from './constants/headers';
import { getRaws } from './helpers/getRaws';
import { Container, HeadButtonsContainer, HeadingWrapper, TableWrapper } from './ValidClaimsReport.styled';
import SelectInput from '@/common/components/SelectInput';
import { Colors } from '@/common/colors/colors';
import { LAST_YEAR_MONTHS } from '@/common/constants/lastYearMonths';
import Icon from '@/common/components/Icon';
import { generateZip } from './helpers/generateZip';
import { filterReducer } from './helpers/reducer';
import { DEFAULT_FILTER, FILTER_ACTIONS } from './constants/filter';
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd';
import ShowAction from './components/showAction/ShowAction';

const ValidClaimsReport = () => {
	const [filters, dispatch] = useReducer(filterReducer, DEFAULT_FILTER);
	const [action, setAction] = useState(null);
	const [data, setData] = useState();
	const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);

	const { makeRequest, hasUnfilledRequest } = useRequestsContext();
	const { addMessage } = useNewMessageQueueContext();

	const loadData = async () => {
		const payload = {
			month: moment(selectedMonth.value, 'MMMM YYYY').format("YYYY-MM")
		}

		const response = await makeRequest(getValidClaimsReport.bind(null, payload));

		if (!response?.data) {
			return addMessage("Unable to load data", "error");
		}

		setData(response.data);
	}

	const handleSelectMonth = (month) => {
		if (month.value !== selectedMonth.value) {
			setData(null);
		}

		setSelectedMonth(month);
	}


	const visibleData = useMemo(() => data?.filter((item) => {
		const availabelMarkets = filters[FILTER_ACTIONS.SET_MARKETS]?.map(({ value }) => value);
		if (availabelMarkets.length && !availabelMarkets.includes(item.order.market)) {
			return false;
		}

		const availabelCurrencies = filters[FILTER_ACTIONS.SET_CURRENCIES]?.map(({ value }) => value);

		if (availabelCurrencies.length && !availabelCurrencies.includes(item.transfers.at(-1).currency)) {
			return false;
		}

		return true;
	}), [filters, data]);

	useEffect(() => {
		loadData()
	}, [selectedMonth])

	return (
		<Container>
			{hasUnfilledRequest(getValidClaimsReport) && <Loading />}
			<HeadingWrapper>
				<div />
				<SelectInput
					name="Month"
					color={Colors.darkBlue}
					options={LAST_YEAR_MONTHS.filter(month => moment(month.value, 'MMMM YYYY').isAfter(moment('2024-09-30')))}
					selected={selectedMonth}
					setSelected={handleSelectMonth}
				/>
				<HeadButtonsContainer>
					{filters && Object.values(filters)?.some((filter) => filter.length) && (
						<CustomButtonAdd
							defaultText={"Reset filters"}
							onClick={() => dispatch({ type: FILTER_ACTIONS.RESET })}
						/>
					)}
					{(!!visibleData?.length && <Icon onClick={() => {
						generateZip(visibleData, selectedMonth.value)
					}} size={32} name="fa fa-file-zipper" />)}
				</HeadButtonsContainer>
			</HeadingWrapper>
			<TableWrapper>
				{!!visibleData?.length && (
					<Table
						stickyHeader
						className="styled-table"
						headersArray={getHeadersArray(setAction, filters)}
						raws={getRaws(visibleData)}
					/>
				)}
			</TableWrapper>
			{action && (
				<ShowAction
					setAction={setAction}
					filters={filters}
					action={action}
					dispatch={dispatch}
				/>
			)}
		</Container >
	)
}

export default ValidClaimsReport
