import Api from '../api'
import { errorHandler } from '../common';

const { tokenManager , axios: api } = Api;

export const createComment = (comment) => errorHandler(async () => {
    const data = await api.post("/comment", {
      user: comment.user,
      contact: comment.contact,
      comment: comment.comment,
    });
    return {data: data.data };   
})

export const getCommentsForContact = (_id) => errorHandler(async () => {
    const data = await api.get(`/comment/get_all_for_contact/${_id}`);
    return {data: data.data };   
})
