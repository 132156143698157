import React, { useEffect, useMemo, useState } from "react";
import Card from "@/common/components/Card";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import { Flex, Icon, RightFlex } from "../../ClaimsReport.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CSV from "@/common/components/CSV";
import { formatDate } from "@/common/functions/dateFormater";
import { getHeaders } from "../../helpers/getHeaders";
import { findMarketById } from "../../helpers/findMarketById";
import { getCsvData } from "./helpers/getCsvData";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelData } from "./helpers/getExcelData";
import Loading from "@/common/components/Loading";
import { findCompanyNameById } from "@/common/functions/findCompanyNameById";
import { findDefaultMarketFromOptions } from "@/common/functions/findDefaultMarketFromOptions";
import { findPossibleProductFieldByMarket } from "@/common/functions/findPossibleProductFieldByMarket";
import { filterEntitiesByIds } from "@/common/functions/filterEntitiesByIds";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { filterProdluctIdsByCompanyId } from "@/common/functions/filterProductIdsByCompanyId";
import ToggleSwitch from "@/common/components/ToggleSwitch";

const SearchBar = ({
  setSelectedProduct,
  setSelectedMarket,
  selectedProduct,
  selectedMarket,
  nameRef,
  fromDateRef,
  toDateRef,
  correctionNumberFilterRef,
  orders,
  handleSearch,
  handleGetAllMonthFiles,
  selectedCompany,
  setSelectedCompany,
  companies,
}) => {
  const [companiesOptions, setCompaniesOptions] = useState();
  const [productsOptions, setProductsOptions] = useState();

  const {
    options: { marketsOptions, queuesOptions },
    commonData: { markets, products },
  } = useCommonDataContext();

  const handleSetMarket = (market) => {
    const currentMarket =
      market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

    setSelectedMarket(currentMarket);
    handleMarketChange(currentMarket);
  };

  const handleMarketChange = (market) => {
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (market && companies) {
      const possibleCompanies = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products,
        "company"
      );

      const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

      const filteredCompanies = filterEntitiesByIds(
        companies,
        uniqueCompaniesIds
      );

      const newCompaniesOptions = handleMapToOptions(
        filteredCompanies,
        "name",
        "_id"
      );

      setCompaniesOptions(newCompaniesOptions);
      const company = newCompaniesOptions[0];

      handleSetCompany(company, market);
    }
  };

  const handleSetCompany = (company, market = selectedMarket) => {
    setSelectedCompany(company);
    handleCompanyChange(company, market);
  };

  const handleCompanyChange = (company, market) => {
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (company) {
      const possibleProductsIds = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products
      );

      const productIdsFilteredByCompanies = filterProdluctIdsByCompanyId(
        products,
        possibleProductsIds,
        company.value
      );

      const filteredProducts = filterEntitiesByIds(
        products,
        productIdsFilteredByCompanies
      );

      const productsOptions = handleMapToOptions(
        filteredProducts,
        "name",
        "_id"
      );

      setProductsOptions(() => productsOptions);
      setSelectedProduct(() => productsOptions[0]);
    }
  };

  const csvData = useMemo(() => {
    if (orders && orders[0]) {
      return getCsvData(orders, correctionNumberFilterRef, markets);
    }
  }, [orders]);

  const excelData = useMemo(() => {
    if (orders && orders[0]) {
      const brutto = `Wartość pobrania (Brutto ${orders[0].currency})`;
      return getExcelData(orders, correctionNumberFilterRef, markets, brutto);
    }
  }, [orders]);

  const headers = useMemo(() => {
    if (orders && orders[0]) {
      return getHeaders(orders);
    }
  }, [orders]);

  useEffect(() => {
    handleSetMarket();
  }, [queuesOptions, marketsOptions, products, companies]);

  return (
    <Card>
      {!marketsOptions?.length && <Loading />}
      <Flex>
        <SelectInput
          name="Market"
          placeholder="Select market..."
          width={120}
          options={marketsOptions}
          setSelected={handleSetMarket}
          selected={selectedMarket}
          color={Colors.darkBlue}
        />
        <SelectInput
          name="Company"
          placeholder="Select company..."
          width={120}
          options={companiesOptions}
          setSelected={handleSetCompany}
          selected={selectedCompany}
          color={Colors.darkBlue}
        />
      </Flex>
      <Flex>
        <SelectInput
          name="Product"
          placeholder="Select product..."
          width={120}
          options={productsOptions}
          selected={selectedProduct}
          setSelected={setSelectedProduct}
          color={Colors.darkBlue}
        />
        <Input
          color={Colors.darkBlue}
          inputRef={nameRef}
          width={120}
          name="Client name"
          placeholder="Type name"
        />
      </Flex>
      <Flex>
        <Input
          width={120}
          inputRef={fromDateRef}
          name="From"
          type="date"
          color={Colors.darkBlue}
        />
        <Input
          inputRef={toDateRef}
          width={120}
          name="To"
          type="date"
          color={Colors.darkBlue}
        />
      </Flex>
      <RightFlex>
        <ToggleSwitch
          toggleRef={correctionNumberFilterRef}
          text="Correction number"
        />
      </RightFlex>
      <RightFlex>
        {!!orders?.length && (
          <div>
            <Icon
              onClick={() => handleGetAllMonthFiles()}
              className="fa fa-file-zipper animation-scale"
            />
          </div>
        )}
        {orders && orders[0] && !!selectedProduct && (
          <ExportExcel
            excelData={excelData}
            fileName={`${findCompanyNameById(
              companies,
              selectedCompany.value
            )}_${findMarketById(
              selectedMarket.value,
              markets
            ).short.toUpperCase()}_Zwroty_${formatDate(
              fromDateRef.current?.value,
              "DD.MM.YYYY"
            )}-${formatDate(toDateRef.current?.value, "DD.MM.YYYY")}`}
          />
        )}
        {orders && orders[0] && !!selectedProduct && (
          <CSV
            header={headers}
            data={csvData}
            filename={`${findCompanyNameById(companies, selectedCompany.value)
              ?.split(" ")
              .join("_")}_${findMarketById(
              selectedMarket.value,
              markets
            ).short.toUpperCase()}_Zwroty_${formatDate(
              fromDateRef.current?.value,
              "DD.MM.YYYY"
            )}-${formatDate(toDateRef.current?.value, "DD.MM.YYYY")}.csv`}
          />
        )}
        <CustomButtonAdd
          defaultText="Search"
          style={{ marginTop: "10px" }}
          onClick={() => handleSearch()}
        />
      </RightFlex>
    </Card>
  );
};

export default SearchBar;
