export const filterTicketsByText = (filterText, tickets) => {
  if (!filterText) {
    return tickets;
  }

  return tickets?.filter((ticket) => {
    const messagesText = ticket[ticket.type].message
      .map((messageData) => messageData.original)
      .join(" ");

    return messagesText.includes(filterText);
  });
};
