import { caclManagerPreEffectivePaymentNew, calcManagerHours } from "@/common/functions/consultantPaymentsCalculations";
import { round } from "@/common/functions/round";

export const getSummarizeRaws = (consultantResults) => [
  <tr>
    <td rowSpan={2}>
      {calcManagerHours(consultantResults.data[0]._consultant_hours)} h
    </td>
    <td rowSpan={2}>
      {calcManagerHours(consultantResults.data[0]._consultant_hours) *
        consultantResults._month_working_info.manager_hourly_rate}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {caclManagerPreEffectivePaymentNew(consultantResults, false)}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {caclManagerPreEffectivePaymentNew(consultantResults, false) +
        calcManagerHours(consultantResults.data[0]._consultant_hours) *
          consultantResults._month_working_info.manager_hourly_rate}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td rowSpan={2}>
      {round(
        Math.max(
          caclManagerPreEffectivePaymentNew(consultantResults, true) -
          caclManagerPreEffectivePaymentNew(
              consultantResults,
              false,
            ),
          0
        )
      )}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
  </tr>,
  <tr>
    <td>
      {caclManagerPreEffectivePaymentNew(consultantResults, true)}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {" "}
      {(caclManagerPreEffectivePaymentNew(consultantResults, true) >
        0 &&
        round(
          caclManagerPreEffectivePaymentNew(consultantResults, true) +
            calcManagerHours(consultantResults.data[0]._consultant_hours) *
              consultantResults._month_working_info.manager_hourly_rate
        )) ||
        0}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
  </tr>,
];
