import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getConsultantTakenContacts } from "../../API/repositories/reports";
import { Colors } from "../../common/colors/colors";
import Input from "../../common/components/Input";
import SelectInput from "../../common/components/SelectInput";
import { CALL_HISTORY_STATUSES } from "../../common/constants/statuses";
import { copyLinkToClipboard } from "../../common/functions/copyLinkToClipboard";

import { formatToDateTimeFormaterTemplate } from "../../common/functions/dateFormater";
import {
  navigateToContact,
  navigateToOrder,
  navigateToUser,
} from "../../common/functions/naviagte";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { CONSULTANT_ROLES } from "../../components/loginPage/LoginForm/loginForm";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import Loading from "../../common/components/Loading";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";
import { PageWrapper } from "@/common/styles/Wrappers";
import { SearchBarRaw } from "@/common/styles/SearchBars";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 20px;
  gap: 10px;
  color: ${Colors.darkBlue};
`;

const ContactsRoad = () => {
  const [selctedUsers, setSelectedUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState();
  const [status, setStatus] = useState([]);

  const [data, setData] = useState();
  const fromRef = useRef();
  const toRef = useRef();
  const today = formatToDateTimeFormaterTemplate(moment());

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { filterUsersByRoles, commonData } = useCommonDataContext();
  const { users } = commonData;

  const handleSearch = async (e) => {
    e && e.preventDefault();

    if (selctedUsers.length === 0) {
      addMessage("Select user", "error");

      return;
    }

    if (!status.length) {
      addMessage("Select statuses", "error");

      return;
    }

    const payload = {
      to: toRef?.current?.value || moment(today).toISOString(),
      from: fromRef?.current?.value || moment(today).toISOString(),
      consultant: selctedUsers.value,
      status: status.map((s) => s.value) || [],
    };

    payload.to = moment(payload.to).toISOString();
    payload.from = moment(payload.from).toISOString();

    const response = await makeRequest(
      getConsultantTakenContacts.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  const loadData = async (e) => {
    e && e.preventDefault();
    setUsersOptions(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  useEffect(() => {
    loadData();
  }, [users]);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getConsultantTakenContacts) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      <SearchBarRaw>
        <div>
          <SelectInput
            name="concultant"
            selected={selctedUsers}
            setSelected={setSelectedUsers}
            options={usersOptions}
            width={80}
            selectWidth={200}
            color={Colors.darkBlue}
            placeholder="Select user..."
          />
          <SelectInput
            name="status"
            selected={status}
            setSelected={setStatus}
            options={CALL_HISTORY_STATUSES}
            width={80}
            selectWidth={200}
            color={Colors.darkBlue}
            placeholder="Select status..."
            multiple={true}
          />
        </div>
        <div>
          <Input
            name="From"
            type="datetime-local"
            inputWidth={180}
            color={Colors.darkBlue}
            width={50}
            inputRef={fromRef}
            value={today}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="datetime-local"
            width={50}
            inputWidth={180}
            color={Colors.darkBlue}
            value={today}
          />
        </div>
        <div>
          <button className="btn btn-warning" onClick={(e) => handleSearch(e)}>
            Search
          </button>
        </div>
      </SearchBarRaw>

      {data && (
        <table className="styled-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Start At</th>
              <th>End At</th>
              <th>Consultant</th>
              <th>Status</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              .map((element, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {moment(element.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </td>
                  <td>
                    {(element.updated_at &&
                      moment(element.updated_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )) ||
                      "-----"}
                  </td>
                  <td>{element.consultant.username}</td>
                  <td>{element.status || "------"}</td>
                  <td>{element.contact.email || "------"}</td>
                  <td>{element.contact.phone_number || "------"}</td>
                  <td>{element.contact.full_name || "------"}</td>
                  <td>
                    <IconWrapper>
                      <i
                        className="fa fa-user animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToUser(element.consultant._id)}
                      />
                      <i
                        className="fa fa-address-book animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToOrder(element.order._id)}
                      />
                      <i
                        className="fa fa-book animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToContact(element.contact._id)}
                      />
                      <i
                        className="fa fa-copy animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => copyLinkToClipboard(element._id)}
                      />
                    </IconWrapper>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </PageWrapper>
  );
};

export default ContactsRoad;
