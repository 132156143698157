import CSV from "@/common/components/CSV";
import { getCSVDataForShortInfo } from "./getCSVDataForShortInfo";
import { HeaderTitleWrapper } from "../ShortInfoDataTable.styled";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelData } from "./getExcelData";

export const getHeadersArray = (data) => [
  [
    <th colSpan={6}>
      <HeaderTitleWrapper>
        <div>Contract type: {data.name} </div>
        <CSV
          color={"white"}
          header={[
            "TYP",
            "IMIĘ I NAZWISKO",
            "OKRES",
            "ROZLICZENIA",
            "ILOSC PRZEPRACOWANYCH GODZIN",
            "BONUS BRUTTO",
          ]}
          data={getCSVDataForShortInfo(data.value)}
          filename={`paymentkdr.${data.name}.csv`}
        />
        <ExportExcel
          excelData={getExcelData(data.value)}
          fileName={`paymentkdr.${data.name}`}
          color="white"
        />
      </HeaderTitleWrapper>
    </th>,
  ],
  [
    <th>TYP</th>,
    <th>IMIĘ I NAZWISKO</th>,
    <th colSpan={2}>OKRES ROZLICZENIA</th>,
    <th>ILOSC PRZEPRACOWANYCH GODZIN</th>,
    <th>BONUS BRUTTO</th>,
  ],
];
