import React, { useMemo } from "react";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Table from "@/common/components/Table";
import { getQueueStackAndContacts } from "@/API/repositories/queue";

const QueueLeads = () => {
  const { data } = useAdminDashboardContext();
  const { hasUnfilledRequest } = useRequestsContext();
  const { queue_leads } = data;
  const headersArray = [
    [<th colSpan={4}>Queue leads</th>],
    [
      <th rowSpan={2}>Queue</th>,
      <th rowSpan={2}>24 hours</th>,
      <th colSpan={2}>Queues</th>,
    ],
    [<th>new</th>, <th>all</th>],
  ];
  const raws = useMemo(() => {
    if (queue_leads && queue_leads[0]) {
      return queue_leads.map((add) => (
        <tr key={add.market_short}>
          <td>{add.market_short}</td>
          <td>{add.leads_new || 0}</td>
          <td>{add.queue_leads_new}</td>
          <td>{add.queue_leads}</td>
        </tr>
      ));
    }
  }, [queue_leads]);
  const shouldShowLoading = useMemo(
    () => !queue_leads && hasUnfilledRequest(getQueueStackAndContacts),
    [queue_leads, hasUnfilledRequest]
  );
  return (
    <div
      style={{
        height: "fit-content",
        width: "810px",
        maxHeight: "400px",
        overflow: "scroll",
      }}
    >
      <Table
        style={{ width: "800px" }}
        className="styled-table"
        headersArray={headersArray}
        raws={raws}
        noDataCols={4}
        loading={shouldShowLoading}
      />
    </div>
  );
};
export default QueueLeads;
