import React, { useState } from "react";
import { INVOICE_CREATION_TYPES } from "@/common/components/invoiceTicket/contstants/invoices";
import AutoFilledInputs from "@/common/components/invoiceTicket/common/components/autoFilledInputs/AutoFilledInputs";
import InvoiceButtons from "@/common/components/invoiceTicket/common/components/invoiceButtons/InvoiceButtons";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getCompanyDataByNumber } from "@/API/repositories/invoices";
import { Colors } from "@/common/colors/colors";
import { resetWithTimeout } from "@/common/components/invoiceTicket/helpers/resetWithTimeout";

const AutoCreation = ({
  translation,
  companyNameRef,
  addressRef,
  commentRef,
  streetRef,
  cityRef,
  postalCodeRef,
  setInvoiceCreateBy,
  companyIdRef,
  selectedCountry,
  addMessage,
}) => {
  const [canChangeCreationType, setCanChangeCreationType] = useState();
  const [checkColor, setCheckColor] = useState();
  const [additionalErrorMessage, setAdditionalErrorMessage] = useState();

  const { makeRequest } = useRequestsContext();

  const handleFillDataByCompanyId = async () => {
    setCanChangeCreationType(() => false);
    setAdditionalErrorMessage(() => null);
    companyNameRef.current.value = null;
    addressRef.current.value = null;

    const companyId = companyIdRef.current?.value.replace(/\s/g, "");
    companyIdRef.current.value = companyId;

    if (!companyId || !parseInt(companyId)) {
      return addMessage("Company id must be a number", "error");
    }

    if (!selectedCountry?.value) {
      return addMessage("Add market", "error");
    }

    const payload = {};

    payload.companyNumber = companyIdRef.current.value;
    payload.marketId = selectedCountry.value;

    const response = await makeRequest(
      getCompanyDataByNumber.bind(null, payload)
    );

    if (!response.data) {
      setAdditionalErrorMessage(() => "There is no such company in database");
      setCheckColor(() => Colors.red);
      resetWithTimeout(setAdditionalErrorMessage);
      resetWithTimeout(setCheckColor, Colors.orange);
      setCanChangeCreationType(() => true);
      return addMessage("Company was not found", "error");
    }

    addressRef.current.value = response.data.companyAddress;
    companyNameRef.current.value = response.data.companyName;

    setCheckColor(() => Colors.green);
    resetWithTimeout(setCheckColor, Colors.orange);

    return addMessage("Everything filled in correctly", "success");
  };

  return (
    <>
      <AutoFilledInputs
        translation={translation}
        additionalErrorMessage={additionalErrorMessage}
        companyNameRef={companyNameRef}
        addressRef={addressRef}
        commentRef={commentRef}
        streetRef={streetRef}
        cityRef={cityRef}
        postalCodeRef={postalCodeRef}
      />
      <InvoiceButtons
        translation={translation}
        addressRef={addressRef}
        companyNameRef={companyNameRef}
        canChangeCreationType={canChangeCreationType}
        setInvoiceCreateBy={setInvoiceCreateBy}
        checkColor={checkColor}
        handleCheck={handleFillDataByCompanyId}
        differentCreationType={INVOICE_CREATION_TYPES.VAT_ID}
      />
    </>
  );
};

export default AutoCreation;
