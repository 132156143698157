import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const DynamicMailContentTable = ({ dynamicMailContent, handleSetDynamicMailContentEdit }) => {

  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(dynamicMailContent, handleSetDynamicMailContentEdit)}
    />
  );
};

export default DynamicMailContentTable;
