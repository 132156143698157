import React from "react";

export const WorkingByTake = ({ data, consultants, name }) => {
  const calcMinutes = (data, minutes) => {
    return data.reduce(
      (prev, next) =>
        prev +
        (new Date(next.updated_at).getTime() -
          new Date(next.started_at).getTime() <
          minutes * 60 * 1000 &&
        new Date(next.updated_at).getTime() -
          new Date(next.started_at).getTime() >
          (minutes - 1) * 60 * 1000
          ? 1
          : 0),
      0
    );
  };

  const calcRestMinutes = (data) => {
    return data.reduce(
      (prev, next) =>
        prev +
        (new Date(next.updated_at).getTime() -
          new Date(next.started_at).getTime() >
        9 * 60 * 1000
          ? 1
          : 0),
      0
    );
  };

  const createFilterData = (data) => {
    const confirmed = data.filter((element) => element.status === "confirmed");
    const resigned = data.filter((element) => element.status === "resigned");
    const shift = data.filter((element) => element.status === "shift");
    const no_response = data.filter(
      (element) => element.status === "no_response"
    );

    const ssss = [confirmed, resigned, shift, no_response];

    return ssss;
  };

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th colspan="11">Decile call lengths ( every 1 minute) ({name})</th>
        </tr>
        <tr>
          <th>Consultant</th>
          <th>up to 1 minute</th>
          <th>up to 2 minutes</th>
          <th>up to 3 minutes</th>
          <th>up to 4 minutes</th>
          <th>up to 5 minutes</th>
          <th>up to 6 minutes</th>
          <th>up to 7 minutes</th>
          <th>up to 8 minutes</th>
          <th>up to 9 minutes</th>
          <th>above 9 minutes</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data &&
          data.map((toFilter) =>
            createFilterData(toFilter).map(
              (element) =>
                element[0] && (
                  <tr>
                    <td>
                      {element[0]?.status}{" "}
                      {element[0] &&
                        consultants.find((c) => c._id === element[0].consultant)
                          ?.username}
                    </td>
                    <td>{calcMinutes(element, 1)}</td>
                    <td>{calcMinutes(element, 2)}</td>
                    <td>{calcMinutes(element, 3)}</td>
                    <td>{calcMinutes(element, 4)}</td>
                    <td>{calcMinutes(element, 5)}</td>
                    <td>{calcMinutes(element, 6)}</td>
                    <td>{calcMinutes(element, 7)}</td>
                    <td>{calcMinutes(element, 8)}</td>
                    <td>{calcMinutes(element, 9)}</td>
                    <td>{calcRestMinutes(element)}</td>
                  </tr>
                )
            )
          )}
      </tbody>
    </table>
  );
};
