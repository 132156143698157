import React, { useState } from "react";
import { Wrapper } from "./ccGamification.styles";
import ResultTable from "./components/ResultTable";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { getGamificationPointsByQuery } from "@/API/repositories/gamificationPoints";
import Loading from "@/common/components/Loading";
import SearchGamificationBar from "./components/SearchGamificationBar";
import { useRequestsContext } from "@/common/hooks/requestHook";

const CCGamification = () => {
  const [data, setData] = useState([]);
  const { hasUnfilledRequest } = useRequestsContext();
  const { messages, addMessage } = useMessageQueue();

  return (
    <Wrapper>
      {hasUnfilledRequest(getGamificationPointsByQuery) && <Loading />}
      <MessageQueue messages={messages} addMessage={addMessage} />
      <SearchGamificationBar setData={setData} addMessage={addMessage} />
      {data[0] && <ResultTable data={data} />}
    </Wrapper>
  );
};

export default CCGamification;
