import { confirmPaymentOrder } from "@/API/repositories/order";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ConfirmPayment = ({
  data,
  translation,
  setClose,
  inbound = false,
  callBack = false,
  callBackFN,
}) => {
  const navigate = useNavigate();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));

  const handleCreatePayment = async (e) => {
    e?.preventDefault();

    const responseDecision = await makeRequest(
      confirmPaymentOrder.bind(null, {
        _id: data._id,
        to_process_date: new Date(date).toISOString(),
        categoryName: "CC_PAYMENT_LINK_" + data._market.short.toUpperCase(),
      })
    );

    if (responseDecision.data && callBack) {
      await callBackFN();
      return;
    }

    if (responseDecision.data && !inbound) {
      navigate("/consultant/dashboard");
    }

    if (responseDecision.data && inbound) {
      setClose(() => null);
    }
  };

  return (
    <>
      {hasUnfilledRequest() && <Loading />}
      <PopUp setShow={setClose}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          Do you want to confirm Payment Order.
        </div>
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            flexDirection: "column",
          }}
          onSubmit={handleCreatePayment}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <label>Payment link will be send at</label>
            <input
              type="datetime-local"
              min={date}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              style={{
                width: "180px",
              }}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <button
              className="btn btn-danger"
              onClick={() => setClose(false)}
              style={{ fontSize: "14px", padding: "8px", width: "100px" }}
            >
              No
            </button>
            <button
              style={{ fontSize: "14px", padding: "8px", width: "100px" }}
              className="btn btn-success"
            >
              Yes
            </button>
          </div>
        </form>
      </PopUp>
    </>
  );
};

export default ConfirmPayment;
