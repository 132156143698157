import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const getProductsWithCouriers = () =>
  errorHandler(async () => {
    const data = await api.get(`/lms/get_products_with_couriers`);

    return { data: data.data };
  });
