import React, { useLayoutEffect, useState } from "react";
import { PaymentWrapper, Title } from "./PaymentData.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";
import PreResultTable from "./components/preResultTable/PreResultTable";
import PaymentSummarizeTable from "./components/paymentSummarizeTable/PaymentSummarizeTable";
import PreManagerResultTable from "./components/preManagerResultTable/PreManagerResultTable";
import PreManagerResultFinalTable from "./components/preManagerResultFinalTable/PreManagerResultFinalTable";
import ManagerPaymentSummarizeTable from "./components/managerPaymentSummarizeTable/ManagerPaymentSummarizeTable";

const PaymentData = ({ paymentData, selectedConsultant, forexRates }) => {
  const [consultants, setConsultants] = useState();

  const {
    commonData: { users },
    filterUsersByRoles,
  } = useCommonDataContext();

  const handleGetConsultants = async () => {
    setConsultants(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  useLayoutEffect(() => {
    if (users[0]) {
      handleGetConsultants();
    }
  }, [users]);

  return (
    selectedConsultant &&
    paymentData.length > 0 &&
    paymentData
      .sort((a, b) => a._id.localeCompare(b._id))
      .map(
        (consultantResults, i) =>
          selectedConsultant
            .map((consultant) => consultant.value)
            .includes(consultantResults._id) && (
            <PaymentWrapper key={i}>
              <Title>
                Results for:{" "}
                {
                  consultants?.find((c) => c.value === consultantResults._id)
                    ?.label
                }
              </Title>
              {consultantResults.data.map((payment) => (
                <PreResultTable
                  payment={payment}
                  consultantResults={consultantResults}
                />
              ))}
              {consultantResults && (
                <PaymentSummarizeTable
                  paymentData={paymentData}
                  consultantResults={consultantResults}
                />
              )}
              {!!consultantResults?.data?.filter(d => d.team?.length && !d.is_final)?.length && (
                <PreManagerResultTable consultantResults={consultantResults} />
              )}
              {!!consultantResults?.data?.filter(d => d.team?.length && d.is_final)?.length && (
                <PreManagerResultFinalTable
                  consultantResults={consultantResults}
                />
              )}
              {!!consultantResults?.data?.filter(d => d.team?.length)?.length && (
                <ManagerPaymentSummarizeTable
                  consultantResults={consultantResults}
                  forexRates={forexRates}
                />
              )}
            </PaymentWrapper>
          )
      )
  );
};

export default PaymentData;
