import { calcSalary } from "./calcSalary";

export const calcTotalSalary = (orders, payment) => {
  return orders.reduce(
    (current, next) =>
      current +
      calcSalary(next.big_items_count, next.small_items_count, payment),
    0
  );
};
