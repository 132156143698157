export const NOT_ENDED_CLAIMS_HEADERS = [
  "No.",
  "Full name",
  "Mail",
  "Product",
  "Delivered at",
  "Claim to",
  "Claim status",
  "Created at",
  "Actions",
];
