import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 700px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const Paragraph = styled.p`
  width: 100%;
`;

export const CommentsWrapper = styled.div`
  width: ${(props) => `${props.chatWidth || 300}px`};
  border: 1px solid ${Colors.newGray};
  border-radius: 4px;
  height:  ${(props) => `${props.chatHeight || 300}px`};
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateZ(0); // used for translation icon positioning
`;

export const CommentsBody = styled.div`
  overflow: auto;
`;

export const SendCommentWrapper = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
  border: 1px solid ${Colors.newGray};
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const SendButton = styled.div`
  width: 40px;
  background-color: ${Colors.purple};
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 3px;
  border-top-right-radius: 0;
`;

export const Textarea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  border-radius: 4px;
  flex-grow: 1;
  height: 45px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.darkBlue};
  overflow: hidden;
`;

export const EmptyCommentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.newGray};
`;

export const CommentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  color: ${Colors.darkBlue};
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  overflow-wrap: anywhere;
  align-self: ${(props) => props.align};
  background-color: ${(props) => props.backgroundColor};
`;

export const MessageSendingWrapper = styled.span`
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  font-weight: normal;
`;

export const MessageSenderWrapper = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const MessageInfoWrapper = styled.div`
  margin-bottom: 6px;
`;

export const TranslationIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  right: 10px;
  position: absolute;
  z-index: 1000;
  top: 4px;
`;

export const TranslateIcon = styled.i`
  right: 10px;
  top: 8px;
  font-size: 32px;
  color: ${Colors.darkGray};
  cursor: pointer;
`;
