import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;
const queryString = require("query-string");

const ENTITY_NAME = "mail-template";

export const getMailTemplates = () =>
  errorHandler(async () => {
    const data = await api.get(`/${ENTITY_NAME}/`);
    return { data: data.data };
  });

export const updateMailTemplate = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/${ENTITY_NAME}/${payload._id}`, payload);
    return { data: data.data };
  });

export const createMailTemplate = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}`, payload);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
