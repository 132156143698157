export const getRaws = (cplData) =>
  cplData.map((item) => (
    <tr key={`${item.dateFrom}_${item.productName}`}>
      <td>{item.idx}</td>
      <td>{item.dateFrom}</td>
      <td>{item.dateTo}</td>
      <td>{item.productName}</td>
      <td>{item.leads}</td>
      <td>{item.cpl}</td>
      <td>{item.totalSpend}</td>
      <td>{item.wppkConfirmed}</td>
      <td>{item.roasConfirmed}</td>
      <td>{item.wppkDelivered}</td>
      <td>{item.roasDelivered}</td>
    </tr>
  ));
