import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 500px;
  width: 620px;
  gap: 20px;
  border-radius: 20px;
  color: ${(props) => props.color};
  padding: 10px 0 0 0;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  h3 {
    color: ${Colors.darkBlue};
    font-weight: bold;
    font-size: 22px;
  }
`;

export const TableWrapper = styled.div`
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 1;
`;
