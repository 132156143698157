import React, { useState, useMemo, useEffect } from "react";
import { Wrapper } from "./claimNotEnded.styles";
import SearchBarNotEndedClaims from "./components/SearchBarNotEndedClaims";
import { createRows } from "./helpers/createRows";
import { NOT_ENDED_CLAIMS_HEADERS } from "./constants/headers";
import ClaimEditHrView from "@/pages/admin/claimNotEnded/components/claimEditHrView./ClaimEditHrView";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import {
  findClaimById,
  searchNotEndedClaimsByProduct,
} from "@/API/repositories/order";
import Loading from "@/common/components/Loading";
import Table from "@/common/components/Table";
import { PRIORITY_SORT } from "@/common/constants/priorities";
import { CLAIM_STATUS_OPTIONS_NOT_ENDED_CLAIMS } from "@/common/constants/Accounting";
import { getCompanies } from "@/API/repositories/company";
import { getFileById } from "@/API/repositories/storedDocument";

const ClaimNotEnded = () => {
  const [edit, setEdit] = useState();
  const [data, setData] = useState();

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedStatus, setSelectedStatus] = useState(
    CLAIM_STATUS_OPTIONS_NOT_ENDED_CLAIMS[0]
  );

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleSearch = async (e) => {
    e?.preventDefault();

    if (!selectedProduct || !selectedStatus) {
      return addMessage("Select all required data");
    }

    setData(() => null);

    const payload = {};
    payload.product = selectedProduct.value;
    payload.status = selectedStatus.value;

    const response = await makeRequest(
      searchNotEndedClaimsByProduct.bind(null, payload)
    );

    if (response.data) {
      const sortedData = response.data
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        .sort((a, b) => PRIORITY_SORT[a.priority] - PRIORITY_SORT[b.priority]);

      setData(() => sortedData);
    }
  };

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleSelectOne = async (_id) => {
    const response = await makeRequest(findClaimById.bind(null, _id));
    if (response.data) {
      setEdit(() => response.data);
    }
  };

  const rows = useMemo(() => {
    if (data && data[0]) {
      return createRows(data, handleDownloadFile, handleSelectOne);
    }
  }, [data]);

  useEffect(() => {
    if (!edit && selectedProduct) {
      handleSearch();
    }
  }, [edit]);

  return (
    <Wrapper>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(
        searchNotEndedClaimsByProduct,
        getFileById,
        findClaimById,
        getCompanies
      ) && <Loading />}
      <SearchBarNotEndedClaims
        addMessage={addMessage}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleSearch={handleSearch}
      />
      {!!data?.length && (
        <Table
          headers={NOT_ENDED_CLAIMS_HEADERS}
          raws={rows}
          className="styled-table-2 styled-table"
        />
      )}
      {edit && (
        <ClaimEditHrView
          setShow={setEdit}
          data={edit}
          addMessage={addMessage}
          handleSearch={handleSearch}
        />
      )}
    </Wrapper>
  );
};

export default ClaimNotEnded;
