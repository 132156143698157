import { Colors } from "@/common/colors/colors";
import moment from "moment";
import { LOGGED_MINUTES, WORKING_MINUTES } from "../constants";

export const handleOnline = (lastActivity) => {
  if (!lastActivity) return Colors.red;

  const now = moment();
  const activityDate = moment(lastActivity);
  const diff = now.diff(activityDate, "minutes");

  if (diff <= WORKING_MINUTES) {
    return Colors.green;
  }
  if (diff <= LOGGED_MINUTES) {
    return Colors.yellow;
  }

  return Colors.red;
};
