import Icon from "@/common/components/Icon";
import React, { useEffect, useState } from "react";
import TodoList from "./components/todoLIst/TodoList";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { getConsultantManagerTodos } from "@/API/repositories/todo";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { sortTodosByDateAndPriority } from "./helpers/sortTodosByDateAndPriority";
import { Colors } from "@/common/colors/colors";
import { NotSeenWrapper } from "./TodoView.styled";
import userManager from "@/API/userManager";

function TodoView() {
  const [isTodoListOpen, setIsTodoListOpen] = useState(false);
  const [assignedTodos, setAssignedTodos] = useState();
  const [personalTodos, setPersonalTodos] = useState();
  const [notSeen, setNotSeen] = useState();
  const [notRead, setNotRead] = useState();

  const currentUserId = userManager.getUser().id;

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getConsultantManagerTodos);

    if (!response?.data) {
      return addMessage("Can't load todos");
    }

    const { assigned, personal } = response.data;

    assigned.forEach((todosGroup) => {
      todosGroup.data = sortTodosByDateAndPriority(todosGroup.data);
    });

    setAssignedTodos(() => assigned);

    const newTodosAmount = [];

    assigned.forEach((todosGroup) => {
      const filteredGroup =
        todosGroup?.data?.filter((todo) => !todo.seen) || [];
      newTodosAmount.push(...filteredGroup);
    });

    const notReadTodos = [];

    assigned.forEach((todosGroup) => {
      const filteredGroup =
        todosGroup?.data?.filter((todo) =>
          todo.messages?.some(
            (message) => !message?.seen?.includes(currentUserId)
          )
        ) || [];
      notReadTodos.push(...filteredGroup);
    });

    if (notReadTodos?.length || newTodosAmount?.length) {
      addMessage("You have some updates in todo list", "error");
    }

    setNotSeen(() => newTodosAmount?.length + notReadTodos?.length);
    setNotRead(() => notReadTodos.map((todo) => todo._id));

    personal.forEach((todosGroup) => {
      todosGroup.data = sortTodosByDateAndPriority(todosGroup.data);
    });

    setPersonalTodos(() => personal);
  };

  useEffect(() => {
    const intervalId = setInterval(() => loadData(), 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <div
        onClick={() => setIsTodoListOpen(true)}
        style={{ position: "relative", cursor: "pointer" }}
      >
        <Icon
          name="fa fa-list-check"
          size={20}
          color={notSeen ? Colors.red : Colors.darkBlue}
        />
        {!!notSeen && <NotSeenWrapper>{notSeen}</NotSeenWrapper>}
      </div>
      {isTodoListOpen && (
        <TodoList
          notRead={notRead}
          loadData={loadData}
          setAssignedTodos={setAssignedTodos}
          setPersonalTodos={setPersonalTodos}
          assignedTodos={assignedTodos}
          personalTodos={personalTodos}
          addMessage={addMessage}
          setIsOpen={setIsTodoListOpen}
        />
      )}
    </>
  );
}

export default TodoView;
