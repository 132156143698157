import { getPercent } from "@/common/functions/getPercent";
import { findUserById } from "./findUserById";
import { findUserMarket } from "@/common/functions/findUserMarket";
import { getStars } from "@/common/functions/getStars";
import { getUserGoal } from "@/common/functions/getUserGoal";

export const getGamificationBonus = ({
  consultantResults,
  paymentData,
  userMarket,
  markets,
  queues,
  users,
}) => {
  if (!userMarket) {
    return 0;
  }

  const usersForMarketPayment = paymentData.filter((consultantPayment) => {
    const consultantMarket = findUserMarket(
      markets,
      queues,
      consultantPayment._id
    );
    return consultantMarket?._id === userMarket?._id;
  });

  usersForMarketPayment.sort((userPaymentA, userPaymentB) => {
    const userA = findUserById(userPaymentA._id, users);

    const starsA = userPaymentA._gamification_results
      ? getStars({
          user: userA,
          gamificationUserStats: userPaymentA._gamification_results,
          markets,
          queues,
        })
      : null;

    const userB = findUserById(userPaymentB._id, users);

    const starsB = userPaymentB._gamification_results
      ? getStars({
          user: userB,
          gamificationUserStats: userPaymentB._gamification_results,
          markets,
          queues,
        })
      : null;

    if (starsA === starsB) {
      const goalA = getUserGoal({
        gamificationUserStats: userPaymentA._gamification_results,
        user: userA,
        markets,
        queues,
      });

      const goalB = getUserGoal({
        gamificationUserStats: userPaymentB._gamification_results,
        user: userB,
        markets,
        queues,
      });

      const resultA = getPercent(
        userPaymentA._gamification_results.total_amount,
        goalA
      );
      const resultB = getPercent(
        userPaymentB._gamification_results.total_amount,
        goalB
      );

      return resultB - resultA;
    }

    return starsB - starsA;
  });

  const consultantIndex = usersForMarketPayment.findIndex(
    (user) => user._id === consultantResults._id
  );

  const consultantPlace = consultantIndex + 1;

  const attendance = consultantResults.data[0]._consultant_hours.reduce(
    (acc, hours) => acc + hours.attendance_at_work,
    0
  );

  const gamificationBonus = Math.round(
    (consultantResults._month_working_info.hourly_rate *
      attendance *
      parseInt(
        consultantResults._gamification_results?.winners_percents.find(
          (percent) => +percent.place === consultantPlace
        )?.percents
      )) /
      100
  );

  return gamificationBonus;
};
