import { countDelivered } from "./countDelivered";
import { countReturned } from "./countReturned";
import { findMarketFromOptions } from "./findMarketFromOptions";
import { findProductById } from "./findProductById";

export const generateData = (data, marketsOptions, products) => {
  const result = [];

  const elementResult = [];

  elementResult.push(
    findMarketFromOptions(data.value[0].market, marketsOptions).label
  );
  elementResult.push(findProductById(data.value[0].product, products).name);
  elementResult.push(data.value.length);
  elementResult.push(countReturned(data));
  elementResult.push(countDelivered(data));

  result.push(elementResult);

  return result;
};
