import userManager from '@/API/userManager';
import React, { useEffect, useRef, useState } from 'react'
import {
  CommentsBody,
  CommentsListWrapper,
  CommentsWrapper,
  EmptyCommentsWrapper,
  MessageInfoWrapper,
  MessageSenderWrapper,
  MessageSendingWrapper,
  SendButton,
  SendCommentWrapper,
  Textarea,
} from './ValidClaimChat.styled';
import { Colors } from '@/common/colors/colors';
import { findUserById } from '@/common/functions/findUserById';
import { formatDate } from '@/common/functions/dateFormater';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { KEYS_CODES } from '@/common/components/contactSearch/contstants/keysCodes';
import Icon from '@/common/components/Icon';
import { SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled';

const ValidClaimChat = ({
  claim,
  allMessages,
  setAllMessages,
  chatHeight,
  chatWidth,
  handleValidClaimUpdate,
  claimStatus
}) => {
  const [comment, setComment] = useState();

  const bottomRef = useRef();

  const { commonData: { users } } = useCommonDataContext();

  const currentUserId = userManager.getUser().id;

  const handleAddComment = async () => {
    const newMessage = {
      original: comment,
      created_at: new Date(),
      sender: currentUserId,
      status: claimStatus || 'none'
    }

    setComment(() => "");

    if (claim) {
      return handleValidClaimUpdate({ message: newMessage });
    }

    setAllMessages((prev) => [...prev, newMessage]);
  };

  const handleEnterSubmit = (e, fn) => {
    if (
      e.keyCode === KEYS_CODES.enter &&
      e.shiftKey === false &&
      comment?.trim()
    ) {
      e.preventDefault();
      return fn();
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);

  return (
    <SectionWrapper>
      <Subtitle>Comments:</Subtitle>
      <CommentsWrapper chatWidth={chatWidth} chatHeight={chatHeight} >
        {!allMessages?.length && (
          <EmptyCommentsWrapper>No comments yet</EmptyCommentsWrapper>
        )}
        <CommentsBody>
          {allMessages.map((message, idx) => (
            <CommentsListWrapper
              align={
                message.sender === currentUserId ? "flex-end" : "flex-start"
              }
              backgroundColor={
                message.sender === currentUserId
                  ? Colors.lightGray2
                  : "#fff"
              }
            >
              <MessageSenderWrapper>
                {findUserById(message.sender, users)?.username ||
                  "System"}
              </MessageSenderWrapper>
              <MessageInfoWrapper>
                {message.original}
              </MessageInfoWrapper>
              <MessageSendingWrapper>
                <strong>{message?.status}</strong> {formatDate(message.created_at, "YYYY-MM-DD HH:mm")}
              </MessageSendingWrapper>
            </CommentsListWrapper>
          ))}
          <div ref={bottomRef} />
        </CommentsBody>
      </CommentsWrapper>
      <SendCommentWrapper>
        <Textarea
          value={comment}
          onChange={(e) => setComment(() => e.target.value)}
          onKeyDown={(e) => handleEnterSubmit(e, handleAddComment)}
          placeholder="Add comment..."
        />
        <SendButton
          onClick={() => {
            if (comment) {
              handleAddComment();
            }
          }}
          disabled={!comment?.trim()}
        >
          <Icon
            cursor={comment?.trim() ? "pointer" : ""}
            color={comment?.trim() ? "#fff" : Colors.newDarkGray}
            name="fa fa-send"
          />
        </SendButton>
      </SendCommentWrapper>
    </SectionWrapper>
  )
}

export default ValidClaimChat
