import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(211, 211, 211, 0.78);
  border-radius: 15px;
`;

const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px 10px;
    background: white;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
`;

const FillLoading = () => {
  return (
    <Wrapper>
      <LoadingWrapper>
        <span></span>
        <span></span>
        <span></span>
      </LoadingWrapper>
    </Wrapper>
  );
};

export default FillLoading;
