import React, { useEffect, useRef, useState } from "react";
import { ChartWrapper, TitleSection, Wrapper } from "../shared.styles";
import { getDailyConfirmedChart } from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import CustomLine from "@/common/charts/Line";
import { SEARCHING_STATUS_OPTIONS } from "./constants/searchingOptions";

const CallingStats = () => {
  const [chartData, setChartData] = useState();
  const [selectedStatus, setSelectedStatus] = useState(
    SEARCHING_STATUS_OPTIONS[0]
  );
  const [selectedMarket, setSelectedMarket] = useState();

  const {
    options: { queuesOptions },
  } = useCommonDataContext();
  const timeRangeRef = useRef();

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);
    const payload = {};
    payload.consultants = selectedMarket.value.consultants;
    payload.timeFrom = timeRangeRef.current.value;
    payload.status = selectedStatus?.value;

    const response = await makeRequest(
      getDailyConfirmedChart.bind(null, payload)
    );

    if (response?.data) {
      setChartData(() => response.data);
    }
  };

  useEffect(() => {
    if (queuesOptions[0] && selectedMarket) handleLoadData();
  }, [queuesOptions, selectedMarket]);

  useEffect(() => {
    if (queuesOptions[0]) setSelectedMarket(() => queuesOptions[0]);
  }, [queuesOptions]);

  return (
    <Wrapper width={800}>
      <TitleSection>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <i className="fa-brands fa-usps" />
          <div style={{ minWidth: "100px" }}>Daily {selectedStatus.label}</div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            showLabel={false}
            inputWidth={50}
            value={0}
            inputRef={timeRangeRef}
          />
          <SelectInput
            options={SEARCHING_STATUS_OPTIONS}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            selectWidth={150}
            showLabel={false}
          />
          <SelectInput
            options={queuesOptions}
            selected={selectedMarket}
            setSelected={setSelectedMarket}
            selectWidth={200}
            showLabel={false}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </div>
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <CustomLine data={chartData} />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default CallingStats;
