import { compareDates } from "@/common/functions/compareDates";

export const getRaws = (data, queues) =>
  data
    .sort((a, b) => {
      return compareDates(new Date(b.date), new Date(a.date));
    })
    .sort((a, b) => {
      return a.consultant.localeCompare(b.consultant);
    })
    .sort((a, b) => {
      return a.queue.localeCompare(b.queue);
    })
    .map((d) => (
      <tr>
        <td>{d.date}</td>
        <td>{queues?.find(q => q._id === d.queue)?.name || "-----"}</td>
        <td>{d.consultant}</td>
        <td>{d.break}m</td>
        <td>{d.calling}m</td>
        <td>{d.talking}m</td>
        <td>{d.wraptime}m</td>
        <td>{(d.calling || 0) + (d.talking || 0) + (d.wraptime || 0)}m</td>
      </tr>
    ));
