import moment from "moment";

export const getValidatedManagers = (managers, addMessage, setShow) => {
  managers.sort((a, b) => moment(a.active_from).startOf('day').diff(moment(b.active_from).startOf('day')));

  for (let i = 0; i < managers.length - 1; i++) {
    if (
      moment(managers[i].active_to).startOf('day').isSameOrAfter(moment(managers[i + 1].active_from).startOf('day'))
    ) {
      setShow(false)
      addMessage("Wrong time periods", "error");
      return [];
    }

    if (
      moment(managers[i].active_from).startOf('day').isAfter(moment(managers[i].active_to).startOf('day'))
    ) {
      setShow(false)
      addMessage("Wrong time periods", "error");
      return [];
    }
  }

  return managers;
}