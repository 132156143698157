import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const WppkDeliveredWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 180px;
  gap: 30px;
  position: relative;
`;

export const WppkDeliveredPackage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Info = styled.span`
  font-size: 12px;
  color: ${Colors.darkGray2};
`;

export const ProgressVerticalBar = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 132px;
`;

export const ProgressBarVerticalItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 12px;
  height: ${(props) => props.height};
  background-color: ${Colors.lightGray};
  border: 1px solid ${Colors.darkGray};

  :first-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    ::before {
      content: "0%";
      position: absolute;
      bottom: -4px;
      left: 14px;
      font-size: 12px;
      color: gray;
    }
  }

  :last-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::after {
    content: "${(props) => props.percent}";
    position: absolute;
    left: 16px;
    top: -10px;
    font-size: 12px;
    color: gray;
  }
`;

export const ProgressBarItemVerticalFiller = styled.div`
  height: ${(props) => props.height};
  background-color: ${Colors.brightOrange};
  border-radius: inherit;
  width: 10px;
`;
