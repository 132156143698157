import { DEFAULT_FILTER, FILTER_ACTIONS } from "../constants/filter";

export const filterReducer = (state, action) => {
	switch (action.type) {
		case FILTER_ACTIONS.SET_MARKETS:
			return {
				...state,
				markets: action.payload,
			};
		case FILTER_ACTIONS.SET_PRODUCTS:
			return {
				...state,
				products: action.payload,
			};
		case FILTER_ACTIONS.SET_CLAIM_TYPES:
			return {
				...state,
				claimTypes: action.payload,
			};
		case FILTER_ACTIONS.SET_REASONS:
			return {
				...state,
				reasons: action.payload,
			};
		case FILTER_ACTIONS.SET_TAGS:
			return {
				...state,
				tags: action.payload,
			};
		case FILTER_ACTIONS.RESET:
			return DEFAULT_FILTER
		default:
			return state;
	}
};