export const filterMonthHourConsultants = (data, filterRegex, ids) => data
  .filter((e) =>  ids.includes(e._id) && (filterRegex
      ? (e?._month_working_info?.contract_type || e?._user?.contract_type)
          ?.toLowerCase()
          .includes(filterRegex.toLowerCase())
      : true)
  )

export const extractConsultantIds = (data) =>  {
  const extractedIdsSet = new Set();

  data.forEach((workingInfo) => {
    workingInfo.data.forEach((consultantWorkingInfo) => {
      if (
        consultantWorkingInfo._ticket?._stored_document._id 
      ) {
        extractedIdsSet.add(consultantWorkingInfo._ticket?._stored_document._id);
      }
    });
  });

  return Array.from(extractedIdsSet);
}