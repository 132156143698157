import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const CenteredTable = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
