import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getListOfQueuesInfos } from "../../../../API/repositories/queue";
import CreateButton from "../../../../common/components/CreateButton";
import ListTable from "../../../../common/components/ListTable";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import QueueNew from "./QueueNew";

const Flex = styled.div`
  display: flex;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const QueueList = () => {
  const [row, setRow] = useState([]);
  const column = ["no", "name", "users", "contacts"];
  const [showNew, setShowNew] = useState(false);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const getQueueData = async () => {
    const response = await makeRequest(getListOfQueuesInfos);

    if (response.data) {
      setRow(() =>
        response.data
          .sort((a, b) => a?.name.localeCompare(b?.name))
          .map((element) => {
            return {
              data: [element.name, element.users, element.contacts],
              _id: element._id,
            };
          })
      );
    }
  };

  useEffect(() => {
    getQueueData();
  }, [showNew]);

  return (
    <>
      {hasUnfilledRequest(getListOfQueuesInfos) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <Wrapper>
        <Flex>
          <CreateButton onClick={() => setShowNew(true)} name={"queue"} />
        </Flex>
        {row && row[0] && (
          <ListTable column={column} row={row} entityName={"queue"}></ListTable>
        )}
      </Wrapper>
      {showNew && (
        <PopUp setShow={setShowNew} top={40}>
          <QueueNew close={() => setShowNew(false)} addMessage={addMessage} />
        </PopUp>
      )}
    </>
  );
};

export default QueueList;
