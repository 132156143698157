import moment from "moment";
import { sortHours } from "./sortHours";
import { reduceData } from "./reduceData";

export const generateConsultantData = (consultantData) => {
  const days = moment(
    consultantData._month_working_info.working_from
  ).daysInMonth();

  const fullname = `${consultantData._user.name} ${consultantData._user.surname}`;

  let result = [];

  for (let i = 0; i < days; i++) {
    let currentCheckDay = moment(
      consultantData._month_working_info.working_from
    ).add(i, "days");

    const findResult = consultantData.data.find((d) => {
      let dataDay = moment(d.for_day).format("DD");
      return dataDay === currentCheckDay.format("DD");
    });

    if (findResult) {
      const hours = sortHours(findResult.hours);
      const working = hours.filter((d) => ["1", "m"].includes(d.sign));
      const workingTime = working.length / 2;

      const noWorking = hours.filter((d) =>
        ["h", "s", "r", "u", "n"].includes(d.sign)
      );
      const noWorkingTime = noWorking.length / 2;

      const workingBreak = hours.filter((d) => ["/"].includes(d.sign));

      const startWorking = [...working].shift();
      const endWorking = [...working].pop();

      const startBreak = [...workingBreak].shift();
      const endBreak = [...workingBreak].pop();

      result.push([
        currentCheckDay.format("YYYY-MM-DD"),
        fullname,
        startWorking?.hour_time || "----",
        endWorking?.hour_time || "----",
        startBreak?.hour_time || "----",
        endBreak?.hour_time || "----",
        startBreak ? "lunch" : "----",
        noWorkingTime,
        workingTime,
      ]);
    } else {
      result.push([
        currentCheckDay.format("YYYY-MM-DD"),
        fullname,
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
        "----",
      ]);
    }
  }

  result.push([
    "",
    "",
    "",
    "",
    "",
    "",
    "Celkově: ",
    reduceData(consultantData.data, [
      "holiday_leave",
      "special_leave",
      "sick_leave",
      "leave_on_reqests",
      "excused_unpaid_leave",
    ]),
    reduceData(consultantData.data, ["attendance_at_work", "manager_hours"]),
  ]);

  result.push(["", "", "", "", "", "", "", "", ""]);
  result.push(["", "", "", "", "", "", "", "Podpis zaměstnance", ""]);
  result.push(["", "", "", "", "", "", "", "Podpis zaměstnavatele", ""]);

  return result;
};
