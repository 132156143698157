import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Card from "../../../../common/components/Card";
import Form from "../../../../common/components/Form";
import Input from "../../../../common/components/Input";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import { MarketUpdate } from "../../../../common/Model/MarketModels";
import Loading from "../../../../common/components/Loading";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import {
  updateMarket,
  getOneMarket,
} from "../../../../API/repositories/market";
import MarketRegionTable from "./components/MarketRegionTable";
import MarketPromotionTable from "./components/MarketPromotionTable";
import Buttons from "./components/Buttons";
import AddPromotion from "./components/AddPromotion";
import AddRegion from "./components/AddRegion";
import { Colors } from "../../../../common/colors/colors";
import CustomButtonAdd from "../../../../common/components/buttons/CustomButtonAdd";
import FreeDaysTable from "./components/FreeDaysTable";
import AddFreeDay from "./components/AddFreeDay";
import SelectInput from "@/common/components/SelectInput";
import { getLanguages } from "@/API/repositories/language";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { getInvoiceConfigs } from "@/API/repositories/invoiceConfig";

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 1180px;
  justify-content: center;
  flex-wrap: wrap;
`;

const Grid = styled.div`
  display: grid;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
  gap: 30px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px 0px 0px 0px;
`;

const MarketShow = () => {
  const [promotionRegion, setPromotionRegion] = useState([]);
  const [addPromotion, setAddPromotion] = useState();
  const [codes, setCodes] = useState();
  const [region, setRegion] = useState([]);
  const [market, setMarket] = useState({});
  const [selectedRegionEdit, setSelectedRegionEdit] = useState();
  const [languageOptions, setLanguageOptions] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [invoiceConfigOptions, setInvoiceConfigOptions] = useState();
  const [selectedInvoiceConfig, setSelectedInvoiceConfig] = useState();

  const nameRef = useRef();
  const currenyRef = useRef();
  const ccPhonenNumberRef = useRef();
  const shortRef = useRef();
  const regionValueFromRef = useRef();
  const regionValueToRef = useRef();
  const regionLabelRef = useRef();
  const dateFromRegionRef = useRef();
  const dateToRegionRef = useRef();
  const winnersRef = useRef();
  const colorRef = useRef();
  const noResignLimit = useRef();

  const { id } = useParams();
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [addRegion, setAddRegion] = useState();
  const [promotion, setPromotion] = useState();
  const [addFreeDay, setAddFreeDay] = useState();
  const [freeDays, setFreeDays] = useState([]);

  const handleAddPromotion = (e) => {
    e.preventDefault();

    if (promotion._id) {
      const prom = {
        _id: promotion._id,
        regions: addPromotion.map((region) => region._id),
        from:
          (dateFromRegionRef?.current?.value &&
            moment(dateFromRegionRef?.current?.value).toISOString()) ||
          promotion.from,
        to:
          (dateToRegionRef?.current?.value &&
            moment(dateToRegionRef?.current?.value).toISOString()) ||
          promotion.to,
      };

      const filteredPromotions = [...promotionRegion].filter(
        (p) => p._id !== promotion._id
      );

      const sortedPromotions = [...filteredPromotions, prom].sort(
        (promotionA, promotionB) =>
          new Date(promotionB.from).getTime() -
          new Date(promotionA.from).getTime()
      );

      setPromotionRegion(() => sortedPromotions);
      setAddPromotion(() => null);
      setPromotion(() => null);
      return;
    }

    const prom = {
      _id: promotionRegion.length + 1,
      regions: addPromotion.map((region) => region._id),
      from: moment(dateFromRegionRef.current.value).toISOString(),
      to: moment(dateToRegionRef.current.value).toISOString(),
    };

    const sortedPromotions = [...promotionRegion, prom].sort(
      (promotionA, promotionB) =>
        new Date(promotionB.from).getTime() -
        new Date(promotionA.from).getTime()
    );

    setPromotionRegion(() => sortedPromotions);
    setAddPromotion(() => null);
    setPromotion(() => null);
  };

  const handleSubmit = async () => {
    const name = nameRef.current.value;
    const currency = currenyRef.current.value;
    const cc_phone_number = ccPhonenNumberRef.current.value;
    const short = shortRef.current.value;
    const winners = winnersRef.current?.value;
    const ticket_color = colorRef.current?.value;
    const not_resigned_limit = noResignLimit.current?.value;

    const market = new MarketUpdate();

    if (
      (cc_phone_number.length > 0 && cc_phone_number.length !== 9) ||
      cc_phone_number[0] === "+"
    ) {
      addMessage("Type number with 9 numbers without prefix", "error");
      return;
    }

    if (winners && winners < 0)
      return addMessage("Winners must be 0 or more", "error");

    market.name = name;
    market.currency = currency;
    market.cc_phone_number = cc_phone_number;
    market.short = short;
    market._id = id;
    market.regions = region;
    market.promotion = promotionRegion;
    market.free_days = freeDays;
    market.winners = winners;
    market.ticket_color = ticket_color;
    market.language = selectedLanguage?.value;
    market.invoice_config = selectedInvoiceConfig?.value;
    market.not_resigned_limit = not_resigned_limit;

    const response = await makeRequest(updateMarket.bind(null, id, market));

    if (response.data) {
      response.data.created_at = moment(response.data.created_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      response.data.updated_at = moment(response.data.updated_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setMarket(() => response.data);
      addMessage("Saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const handleAddRegion = (e) => {
    e.preventDefault();

    if (addRegion._id) {
      const label = regionLabelRef.current?.value || addRegion.label;

      setRegion((prev) => [
        ...prev.filter((r) => r._id !== addRegion._id),
        { _id: addRegion._id, label, codes },
      ]);

      setCodes(() => null);
      setAddRegion(() => null);
      return;
    }

    if (!regionLabelRef.current) {
      return;
    }

    const label = regionLabelRef.current.value;
    const _id = region.length + 1;

    setRegion((prev) => [...prev, { _id, label, codes }]);
    setCodes(() => null);
    setAddRegion(() => null);
  };

  const handleGetOneMarket = async () => {
    const response = await makeRequest(getOneMarket.bind(null, id));

    if (response.data) {
      response.data.created_at = moment(response.data.created_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      response.data.updated_at = moment(response.data.updated_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setMarket(() => response.data);
      setRegion(() => response.data.regions || []);

      let sortedPromotions = [];

      if (response.data.promotion) {
        sortedPromotions = response.data.promotion.sort(
          (promotionA, promotionB) =>
            new Date(promotionB.from).getTime() -
            new Date(promotionA.from).getTime()
        );
      }

      if (response.data.language) {
        setSelectedLanguage(() =>
          languageOptions?.find(
            (language) => language.value === response.data.language
          )
        );
      }

      if (response.data.invoice_config) {
        setSelectedInvoiceConfig(() =>
          invoiceConfigOptions?.find(
            (config) => config.value === response.data.invoice_config
          )
        );
      }

      setPromotionRegion(() => sortedPromotions);
      setFreeDays(() => response.data.free_days);
    } else {
      addMessage("not found", "error");
    }
  };

  const preLoadData = () => {
    loadLanguages();
    loadInvoiceConfigs();
  };

  const loadLanguages = async () => {
    const response = await makeRequest(getLanguages);

    if (!response?.data) {
      return addMessage("Error loading data", "error");
    }

    setLanguageOptions(handleMapToOptions(response.data, "label", "_id"));
  };

  const loadInvoiceConfigs = async () => {
    const response = await makeRequest(getInvoiceConfigs);

    if (!response?.data) {
      return addMessage("Error loading data", "error");
    }

    setInvoiceConfigOptions(handleMapToOptions(response.data, "name", "_id"));
  };

  useEffect(() => {
    if (languageOptions && invoiceConfigOptions) {
      handleGetOneMarket();
    }
  }, [languageOptions, invoiceConfigOptions]);

  useEffect(() => {
    preLoadData();
  }, []);

  useEffect(() => {
    colorRef.current.value = market.ticket_color;
  }, [market]);

  return (
    <>
      {hasUnfilledRequest(
        getOneMarket,
        updateMarket,
        getLanguages,
        getInvoiceConfigs
      ) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <Wrapper>
        <Card>
          <Flex>
            <Input
              inputRef={nameRef}
              name="Name"
              value={market.name}
              required={true}
              disabled={false}
              width={50}
              inputWidth={150}
              color={Colors.darkBlue}
            />
            <Input
              inputRef={currenyRef}
              name="Currency"
              value={market.currency}
              required={true}
              color={Colors.darkBlue}
              disabled={false}
              width={80}
              inputWidth={60}
            />
            <Input
              inputRef={shortRef}
              name="Short"
              value={market.short}
              required={true}
              color={Colors.darkBlue}
              disabled={false}
              width={50}
              inputWidth={60}
            />
            <Input
              inputRef={ccPhonenNumberRef}
              name="CC Phone Number"
              color={Colors.darkBlue}
              value={market.cc_phone_number}
              width={180}
              inputWidth={120}
            />
            <Input
              inputRef={winnersRef}
              name="Winners"
              type="number"
              color={Colors.darkBlue}
              value={market?.winners}
              width={80}
              inputWidth={90}
              min={0}
            />
            <Input
              color={Colors.darkBlue}
              width={120}
              inputWidth={80}
              name="Tickets color"
              type="color"
              inputRef={colorRef}
            />
            <Input
              color={Colors.darkBlue}
              width={150}
              inputWidth={100}
              name="No resign limit"
              type="number"
              value={market?.not_resigned_limit}
              inputRef={noResignLimit}
            />
            <SelectInput
              selected={selectedLanguage}
              setSelected={setSelectedLanguage}
              color={Colors.darkBlue}
              width={120}
              options={languageOptions}
              name="Language"
            />
            <SelectInput
              selected={selectedInvoiceConfig}
              setSelected={setSelectedInvoiceConfig}
              color={Colors.darkBlue}
              width={130}
              options={invoiceConfigOptions}
              name="Invoice config"
            />
          </Flex>
        </Card>
        <div>
          {freeDays && (
            <FreeDaysTable
              freeDays={freeDays}
              setSelectedFreeDay={setAddFreeDay}
            />
          )}
          <ButtonWrap>
            <CustomButtonAdd
              text={"Add free days"}
              onClick={() => setAddFreeDay({})}
            />
          </ButtonWrap>
        </div>
        <div>
          {region && (
            <MarketRegionTable
              region={region}
              setSelectedRegionEdit={setAddRegion}
            />
          )}
          <ButtonWrap>
            <CustomButtonAdd
              text={"Add region"}
              onClick={(e) => setAddRegion(true)}
            />
          </ButtonWrap>
        </div>
        <div>
          {promotionRegion && (
            <MarketPromotionTable
              promotion={promotionRegion}
              region={region}
              setPromotion={setPromotion}
              setAddPromotion={setAddPromotion}
            />
          )}
          <ButtonWrap>
            <CustomButtonAdd
              text={"Add promotion"}
              onClick={(e) => setPromotion(true)}
            />
          </ButtonWrap>
        </div>
        <Buttons
          setAddRegion={setAddRegion}
          setPromotion={setPromotion}
          onSave={handleSubmit}
        />
        {addFreeDay && (
          <AddFreeDay
            setFreeDays={setFreeDays}
            freeDay={addFreeDay}
            setFreeDay={setAddFreeDay}
          />
        )}
        {(addRegion || selectedRegionEdit) && (
          <AddRegion
            selectedRegionEdit={addRegion}
            setAddRegion={setAddRegion}
            regionLabelRef={regionLabelRef}
            regionValueFromRef={regionValueFromRef}
            regionValueToRef={regionValueToRef}
            handleAddRegion={handleAddRegion}
            codes={codes}
            setCodes={setCodes}
          />
        )}
        {promotion && (
          <AddPromotion
            promotion={promotion}
            setPromotion={setPromotion}
            region={region.map((e) => ({
              _id: e._id,
              label: e.label,
              value: e.codes,
            }))}
            addPromotion={addPromotion}
            setAddPromotion={setAddPromotion}
            dateFromRegionRef={dateFromRegionRef}
            dateToRegionRef={dateToRegionRef}
            handleAddPromotion={handleAddPromotion}
          />
        )}
      </Wrapper>
    </>
  );
};

export default MarketShow;
