import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const InputsWrapper = styled.div`
  margin-bottom: 100px;
`;

export const TopicItemsWrapper = styled.div`
  display: flex;
  margin: 10px 0 0 45px;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 600px;
`;

export const TopicItem = styled.div`
  padding: 8px;
  border-radius: 7px;
  background-color: ${Colors.green};
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;
