import React from "react";
import { getPointColor } from "../helpers/getPointColor";
import {
  Circle,
  ConsultantViewWrapper,
  EmailWrapper,
  FullName,
  GroupWrapper,
  Line,
  Point,
  RowWrapper,
  UserInfoWrapper,
} from "../consultantInfo.styles";
import { Colors } from "chart.js";

const WorkingUsers = ({ data, setShow }) => {
  return (
    <ConsultantViewWrapper>
      {data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, value }) => (
          <GroupWrapper>
            <Line>{name}</Line>
            <ConsultantViewWrapper>
              {value.map((d) => (
                <RowWrapper>
                  <Circle isMnager={d.role === "CALL_CENTER_MANAGER"}>
                    <i
                      className="fa fa-user"
                      style={{ color: Colors.darkBlue }}
                    />
                  </Circle>
                  <UserInfoWrapper>
                    <FullName>
                      {d.name} {d.surname}
                      <Point color={getPointColor(d.last_ping)} />
                    </FullName>
                    <EmailWrapper>{d.email}</EmailWrapper>
                  </UserInfoWrapper>
                  {d.bound_order_contact ? (
                    <i
                      className="fa fa-phone animation-scale"
                      style={{ color: "green", cursor: "pointer" }}
                      onClick={() => setShow(() => d.bound_order_contact)}
                    />
                  ) : (
                    <i className="fa fa-phone-slash" style={{ color: "red" }} />
                  )}
                </RowWrapper>
              ))}
            </ConsultantViewWrapper>
          </GroupWrapper>
        ))}
    </ConsultantViewWrapper>
  );
};

export default WorkingUsers;
