import moment from "moment";
import { WORKING_STATUS } from "../constants/workingStatuses";
import { compareDates } from "@/common/functions/compareDates";

export const calcCSV = (results, date, queues) => {
  results = results.sort((a, b) =>
    compareDates(new Date(a.created_at), new Date(b.created_at))
  );

  const resultRow = [];

  results.forEach((e, i) => {
    const element = [];

    const status = WORKING_STATUS.includes(e.status)
      ? "working"
      : "not_working";

    if (
      i > 0 &&
      resultRow[resultRow.length - 1][3] === status &&
      resultRow[resultRow.length - 1][2] === e._user[0].username
    ) {
      return;
    }

    const queue =
      queues
        ?.find((q) => q._id === date.split("_")[1])
        ?.name?.replaceAll(" ", "_") || "-----";

    element.push(date.split("_")[0]);
    element.push(queue);
    element.push(e._user[0].username);
    element.push(status);
    element.push(moment(e.created_at).format("YYYY-MM-DD HH:mm:ss"));

    if (i > 0) {
      resultRow[resultRow.length - 1].push(
        moment(e.created_at).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    resultRow.push(element);
  });

  return resultRow;
};
