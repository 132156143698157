import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const ENTITY_NAME = "test";

export const getAllTests = () =>
  errorHandler(async () => {
    const data = await api.get(`/${ENTITY_NAME}/`);
    return { data: data.data };
  });

export const updateTest = (_id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/${ENTITY_NAME}/${_id}`, payload);
    return { data: data.data };
  });

export const createTest = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}`, payload);
    return { data: data.data };
  });

export const getConsultantTests = () =>
  errorHandler(async () => {
    const data = await api.get(`/${ENTITY_NAME}/test_for_consultant`);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
