import moment from "moment";
import { Colors } from "@/common/colors/colors";

const WORKING_MINUTES = 5;
const LOGGED_MINUTES = 15;

export const handleOnline = (lastActivity) => {
  if (!lastActivity) return Colors.red;

  const now = moment();
  const activityDate = moment(lastActivity);
  const diff = now.diff(activityDate, "minutes");

  if (diff <= WORKING_MINUTES) {
    return Colors.green;
  }
  if (diff <= LOGGED_MINUTES) {
    return Colors.yellow;
  }

  return Colors.red;
};
