import Api from "../api";
import { errorHandler } from "../common";
const { axios: api } = Api;
const queryString = require("query-string");

export const sendMailByMailType = (categoryName, orderId) =>
  errorHandler(async () => {
    const data = await api.post("/mail/by-type", {
      categoryName: categoryName.toUpperCase(),
      orderId,
    });
    return { data: data.data };
  });

export const getOrdersToMailByQuery = (filters) =>
  errorHandler(async () => {
    const data = await api.post("/mail/orders-to-mial-by-query/", filters);
    return { data: data.data };
  });

export const getMailsForContact = (orderId) =>
  errorHandler(async () => {
    const data = await api.get("/mail/by-conatct-id/" + orderId);
    return { data: data.data };
  });

export const getMailsByFilter = (body) =>
  errorHandler(async () => {
    const data = await api.post("/mail/get_mails_by_filter", {
      body,
    });
    return { data: data.data };
  });

export const getMailsHistory = (hours) =>
  errorHandler(async () => {
    const data = await api.get(
      "/mail/get_history?" +
        queryString.stringify(cleanObject(hours), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getMailsForChart = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      "/mail/get-mails-for-chart?" +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
