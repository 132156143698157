import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./ServerRefresh.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import InvoiceConfigForm from "./components/invoiceConfigForm/InvoiceConfigForm";
import InvoiceConfigTable from "./components/invoiceConfigTable/InvoiceConfigTable";
import { useMutation } from "react-query";
import {
  getAllServerConfigs,
  triggerConfigService,
} from "@/API/query/serverConfig";
import PopUp from "@/common/components/PopUp";
import styled from "styled-components";

const MessageWrapper = styled.div`
  max-height: 600px;
  overflow: auto;
`;

const ServerRefresh = () => {
  const [serverConfig, setServerConfig] = useState();
  const [message, setMessage] = useState();
  const [isInvoiceConfigFormOpen, setIsInvoiceConfigFormOpen] = useState();

  const handleLoadMutation = useMutation({
    mutationFn: () => getAllServerConfigs(),
    onSuccess: (data) => {
      setServerConfig(data);
    },
  });

  const handleTriggerMutation = useMutation({
    mutationFn: (value) => triggerConfigService(value),
    onSuccess: (data) => {
      setMessage(data);
    },
  });

  const handleTrigger = (configId) => {
    const payload = {};
    payload.trigger = configId;

    handleTriggerMutation.mutate(payload);
  };

  const { messages, removeMessage, addMessage } = useMessageQueue();

  useEffect(() => {
    if (!isInvoiceConfigFormOpen) handleLoadMutation.mutate();
  }, [isInvoiceConfigFormOpen]);

  return (
    <>
      {message && (
        <PopUp setShow={setMessage}>
          <MessageWrapper>{message}</MessageWrapper>
        </PopUp>
      )}
      <PageWrapper>
        {(handleLoadMutation.isLoading || handleTriggerMutation.isLoading) && (
          <Loading />
        )}
        <MessageQueue removeMessage={removeMessage} messages={messages} />
        <ContentWrapper>
          <CustomButtonAdd
            style={{ height: "fit-content" }}
            defaultText="Add server config"
            onClick={() => setIsInvoiceConfigFormOpen({})}
          />
        </ContentWrapper>
        {isInvoiceConfigFormOpen && (
          <InvoiceConfigForm
            addMessage={addMessage}
            setShow={setIsInvoiceConfigFormOpen}
            loadData={handleLoadMutation}
            edit={isInvoiceConfigFormOpen}
          />
        )}
        {!!serverConfig?.length && (
          <InvoiceConfigTable
            setServerConfig={setIsInvoiceConfigFormOpen}
            serverConfig={serverConfig}
            handleTrigger={handleTrigger}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default ServerRefresh;
