import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const CheckedDataWrapper = styled.p`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${Colors.green};
  transition: color 0.3s;
  gap: 10px;

  :hover {
    color: ${Colors.red};
  }
`;

export const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const Form = styled.form`
  min-width: 600px;
`;

export const MessageBox = styled.textarea`
  margin-left: 30px;
  margin-bottom: 10px;
  resize: none;
  font-size: 16px;
  color: ${Colors.darkBlue};
`;

export const File = styled.input`
  width: 350px;
  margin-left: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const ContactsCloser = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.darkBlue};
  opacity: 0.2;
`;
