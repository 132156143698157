import moment from "moment";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  createQueueGroup,
  updateQueueGroup,
} from "../../../../../API/repositories/queueGroups";
import Input from "../../../../../common/components/Input";
import Loading from "../../../../../common/components/Loading";
import SelectInput from "../../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import DataTable from "./DataTable";
import { getQueuePointsPosibleContacts } from "../../../../../API/repositories/queue";
import { Colors } from "../../../../../common/colors/colors";

const Wrapper = styled.div`
  min-height: 400px;
  min-width: 500px;
  max-width: 900px;
`;

const QUEUE_STACK_COL = [
  " ",
  "phone",
  "mail",
  "status",
  "created at",
  "dequeued count",
  "dequeued after",
  "order created at",
  "dequeued consultant",
];

const AddGroupView = ({
  handleGetQueueGroup,
  close,
  addMessage,
  savedGroup,
  users
}) => {
  const filterUsers = () => users
  .filter(user => 
    savedGroup?.consultants?.includes(user.value)
  )

  const [selectedOption, setSelectedOption] = useState(filterUsers);
  
  const pointFromRef = useRef();
  const nameRef = useRef();
  const pointToRef = useRef();
  const minLengthRef = useRef();
  const maxLengthRef = useRef();
  const shouldntTakeNotUserShiftsRef = useRef();

  const [queueStackData, setQueueStackData] = useState();

  const params = useParams();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const changeToTableDisplay = (data, usersData) => {
    let result = [];

    data.forEach((data, id) => {
      let element = [];

      element.push(id + 1);
      element.push(data._contact.phone_number);
      element.push(data._contact.email);
      element.push(data._order.status);
      element.push(moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"));
      element.push(data.dequeued_count);
      element.push(moment(data.dequeue_after).format("YYYY-MM-DD HH:mm:ss"));
      element.push(moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"));

      const currentUser = usersData.find(
        (user) => user._id === data.dequeue_consultant
      )?.username;

      element.push(currentUser ? currentUser : "--------");
      element.push(data._contact._id);

      result.push(element);
    });

    return result;
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    let group = {};

    group._id = savedGroup?._id;
    group.queue = params.id;
    group.consultants = selectedOption.map((data) => data.value);
    group.point_from = pointFromRef.current.value;
    group.point_to = pointToRef.current.value;
    group.name = nameRef.current.value;
    group.min_length = minLengthRef.current.value;
    group.max_length = maxLengthRef.current.value;
    group.shouldnt_take_no_user_shift =
      shouldntTakeNotUserShiftsRef.current.checked;

    const request = savedGroup?._id
      ? updateQueueGroup
      : createQueueGroup;

    console.log(group);


    const queueGroup = await makeRequest(request.bind(null, group)); 

    if (queueGroup.data) {
      addMessage("Saved", "success");
      await handleGetQueueGroup();
      close();
    } else {
      addMessage("Error", "error");
    }
  };

  const handleSearchQueuePosibleContacts = async (e) => {
    e.preventDefault();

    const group = {};

    group.point_from = pointFromRef.current.value;
    group.point_to = pointToRef.current.value;
    group.name = nameRef.current.value;
    group.min_length = minLengthRef.current.value;
    group.max_length = maxLengthRef.current.value;

    if (
      !group.point_from ||
      !group.point_to ||
      !group.min_length ||
      !group.max_length
    ) {
      return;
    }

    setQueueStackData(() => null);

    const response = await makeRequest(
      getQueuePointsPosibleContacts.bind(null, group, params.id)
    );

    if (response.data) {
      setQueueStackData(() => changeToTableDisplay(response.data, users));
    }
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(createQueueGroup, updateQueueGroup) && <Loading />}
      <h3 style={{ color: Colors.darkBlue }}>Create QueueGroup:</h3>
      <form onSubmit={(e) => handleAdd(e)}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "150px",
          }}
        >
          <Input
            inputRef={nameRef}
            color={Colors.darkBlue}
            type="text"
            value={savedGroup?.name}
            name="Name"
            width={150}
            required
          />
          <Input
            inputRef={pointFromRef}
            color={Colors.darkBlue}
            type="text"
            value={savedGroup?.point_from}
            name="Point From"
            required={true}
            width={150}
          />
          <Input
            inputRef={pointToRef}
            color={Colors.darkBlue}
            type="text"
            value={savedGroup?.point_to}
            name="Point To"
            required={true}
            width={150}
          />
          <Input
            inputRef={minLengthRef}
            color={Colors.darkBlue}
            type="text"
            value={savedGroup?.min_length}
            name="Min length"
            required={true}
            width={150}
          />
          <Input
            inputRef={maxLengthRef}
            color={Colors.darkBlue}
            type="text"
            value={savedGroup?.max_length}
            name="Max length"
            required={true}
            disabled={false}
            width={150}
          />
          <SelectInput
            name="Consultants"
            color={Colors.darkBlue}
            options={users}
            setSelected={setSelectedOption}
            selected={selectedOption}
            multiple={true}
            width={150}
          />
          <Input
            inputRef={shouldntTakeNotUserShiftsRef}
            color={Colors.darkBlue}
            type="checkbox"
            name="Only user shifts"
            width={150}
            checked={savedGroup?.shouldnt_take_no_user_shift}
          />
        </div>
        <button
          className="btn btn-success"
          type="submit"
          style={{ float: "right", margin: "0px 20px" }}
        >
          Save
        </button>
      </form>
      <button
        className="btn btn-warning"
        onClick={(e) => handleSearchQueuePosibleContacts(e)}
      >
        Show QueueGroup Orders
      </button>
      {queueStackData && queueStackData.length && (
        <DataTable rows={queueStackData} columns={QUEUE_STACK_COL} />
      )}
    </Wrapper>
  );
};

export default AddGroupView;
