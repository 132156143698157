export const QUEUE_STATUSES = [
  { label: "To_check", value: "to_check" },
  { label: "Queued", value: "queued" },
  { label: "To_queue", value: "to_queue" },
  { label: "No_queue_found", value: "no_queue_found" },
  { label: "Not_apply", value: "not_apply" },
];

export const CALL_HISTORY_STATUSES = [
  { label: "Resigned", value: "resigned" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Trash", value: "trash" },
  { label: "No response", value: "no_response" },
  { label: "Shift", value: "shift" },
];

export const SHIPPING_STATUSES = [
  { label: "To send", value: "to_send" },
  { label: "Awizo", value: "awizo" },
  { label: "Return", value: "return" },
  { label: "Deliverd no payment", value: "delivered_no_payment" },
  { label: "Delivered", value: "delivered" },
  { label: "Transport", value: "transport" },
  { label: "Lms sent", value: "lms_sent" },
  { label: "In explanation", value: "in_explanation" },
  { label: "Not apply", value: "not_apply" },
  { label: "Stand by", value: "stand_by" },
];

export const SOURCE_REASONS = [
  { label: "Outbound", value: "outbound" },
  { label: "Edit", value: "edit" },
  { label: "Inbound", value: "inbound" },
];

export const CALLING_DIRECTION = [
  { label: "Outbound", value: "outgoing" },
  { label: "Inbound", value: "incoming" },
];

export const CLAIM_REASONS = [
  { label: "Product_expectation", value: "product_expectation" },
  { label: "Edit", value: "product_quality" },
  { label: "Call Center", value: "call_center" },
  { label: "Logistics", value: "logistics" },
  { label: "No Reason", value: "no_reason" },
];

export const ORDER_STATUSES = [
  { label: "New", value: "new" },
  { label: "No response", value: "no_response" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Trash", value: "trash" },
  { label: "Shift", value: "shift" },
  { label: "Foregin", value: "foreign" },
  { label: "Merged", value: "merged" },
  { label: "Keep", value: "keep" },
  { label: "Resigned", value: "resigned" },
  { label: "Inbound", value: "inbound" },
  { label: "Duplicated", value: "duplicated" },
  { label: "Archive", value: "archive" },
  { label: "ReCall", value: "recall" },
  { label: "Claim", value: "claim" },
  { label: "Informed", value: "informed" },
  { label: "To verify", value: "to_verify" },
  { label: "Lms duplicated", value: "lms_duplicated" },
  { label: "To confirm", value: "to_confirm" },
];

export const SHIFT_STATUSES = [
  { label: "No Product Info", staus: "NO_PRODUCT_INFO" },
  { label: "Wrong time", staus: "BAD_TIME" },
  { label: "other", staus: "OTHER" },
];

export const NO_RESPONSE_STATUSES = [
  { label: "rejected_call", staus: "REJECTED_CALL" },
  { label: "voicmail", staus: "VOICMAIL" },
  { label: "no answer", staus: "NO_ANSWER" },
  { label: "VOIP error", staus: "VOIP_ERROR" },
  { label: "other", staus: "OTHER" },
];

export const ALLOWED_CONSULTANT_ORDER_STATUSES = [
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Resiged",
    value: "resigned",
  },
  {
    label: "Shift",
    value: "shift",
  },
];

export const DELIVERED_STATUSES = [
  ["delivered_no_payment", "delivered", "claim"],
];

export const NOT_EDITABLE_STATUSES = ["to_verify"];
