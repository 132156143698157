export const checkIbanLength = (iban, markets, order) => {
  const orderMarket = markets.find((market) => order.market === market._id);

  switch (orderMarket.short) {
    case "lt":
      return iban?.length === 20;
    case "hr":
      return iban?.length === 21;
    case "sk":
    case "ro":
    case "cz":
      return iban?.length === 24;
    case "hu":
      return iban?.length === 28;
    default:
      return false;
  }
};
