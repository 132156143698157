export const SCORING_OPTIONS = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "0",
    value: "0",
  },
  {
    label: "na",
    value: "na",
  },
];
