import moment from "moment";
import { getColor } from "./getColor";
import { Colors } from "chart.js";

export const createRows = (data, handleDownloadFile, setEdit) =>
  data.map((element, i) => (
    <tr
      key={element?.created_at}
      style={{
        backgroundColor: getColor(element.priority).background,
        color: getColor(element.priority).color,
      }}
    >
      <td>{i + 1}</td>
      <td>{element.full_name}</td>
      <td>{element.email}</td>
      <td>{element.product_name}</td>
      <td>{moment(element.deliveredAt).format("YYYY-MM-DD")}</td>
      <td>
        {moment(element.deliveredAt).add("14", "days").format("YYYY-MM-DD")}
      </td>
      <td>{element.claimStatus || "created"}</td>
      <td>{moment(element.created_at).format("DD-MM-YYYY")}</td>
      <td>
        <i
          className="fa fa-edit animation-scale"
          style={{
            fontSize: "14px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => setEdit(element._id)}
        />
        {element?.docs?.map((e) => (
          <i
            key={e._id}
            className="fa fa-file animation-scale"
            style={{
              fontSize: "14px",
              color: "black",
              cursor: "pointer",
              marginLeft: "10px",
              color: Colors.darkBlue,
            }}
            onClick={() => handleDownloadFile(e._id)}
          />
        ))}
      </td>
    </tr>
  ));
