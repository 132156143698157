import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const SentOrdersTable = ({ data }) => {
  return <Table className="styled-table" raws={data} headers={HEADERS} />;
};

export default SentOrdersTable;
