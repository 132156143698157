export const HEADERS_ARRAY = [
  [<th colSpan={7}>Claims to do (extended)</th>],
  [
    <th>Market</th>,
    <th>Payment Method</th>,
    <th>To pay</th>,
    <th>Priority</th>,
    <th>Overdue</th>,
    <th>To check</th>,
    <th>Check in progress</th>,
  ]
]