import { getValidClaisByStatusReport } from '@/API/repositories/reports';
import Table from '@/common/components/Table';
import { useRequestsContext } from '@/common/hooks/requestHook';
import React, { useEffect, useMemo, useState } from 'react'
import { HEADERS_ARRAY } from './constants/headersArray';
import { getRaws } from './helpers/getRaws';

const ValidClaimsTable = () => {
  const [raws, setRaws] = useState([]);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const shouldShowLoading = useMemo(
    () => !raws?.length && hasUnfilledRequest(getValidClaisByStatusReport),
    [raws, hasUnfilledRequest]
  );

  const loadData = async () => {
    const response = await makeRequest(getValidClaisByStatusReport)

    if (response?.data) {
      setRaws(getRaws(response.data));
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      style={{ maxHeight: "300px", overflow: "scroll"}}
    >
      <Table
        loading={shouldShowLoading} 
        stickyHeader 
        className="styled-table-dashboard"
        headersArray={HEADERS_ARRAY} 
        raws={raws} 
      />
    </div>
  )
}

export default ValidClaimsTable
