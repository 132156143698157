import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ActionIcon, Success, TableWrapper } from "./SmsSearchTable.styled";
import { Colors } from "@/common/colors/colors";

const SmsSearchTable = ({ data }) => {
  const navigate = useNavigate();
  const {
    commonData: { products },
  } = useCommonDataContext();

  return (
    <TableWrapper>
      <table className="styled-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>From number</th>
            <th>To number</th>
            <th>Success</th>
            <th>Type</th>
            <th>Message</th>
            <th>Product</th>
            <th>Sent at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data.map((sms, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{sms.fromNumber || "-----"}</td>
              <td>{sms.toNumber || "-----"}</td>
              <td>
                <Success color={sms.success ? Colors.green : Colors.red}>
                  {sms.success ? "Yes" : "No"}
                </Success>
              </td>
              <td>{sms.type || "-----"}</td>
              <td>{sms._template?.message || "-----"}</td>
              <td>
                {products.find(
                  (product) => product._id === sms._template.product
                ).name || "-----"}
              </td>
              <td>
                {moment(sms.created_at).format("YYYY-MM-DD HH:mm") || "-----"}
              </td>
              <td>
                <ActionIcon
                  className="fa fa-address-card animation-scale"
                  onClick={() => navigate("/dashboard/order/" + sms.order)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SmsSearchTable;
