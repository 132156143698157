import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const SumUpOpener = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 14px;
  color: ${Colors.darkGray2};
  cursor: pointer;
  z-index: 1;
`;
