import Table from "@/common/components/Table";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const GamificationConfigsTable = ({
  gamificationConfigs,
  setSelectedConfig,
}) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();

  const raws = getRaws(gamificationConfigs, markets, setSelectedConfig);

  return <Table className="styled-table" headers={HEADERS} raws={raws} />;
};

export default GamificationConfigsTable;
