import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { getCSVDataForMainInfo } from "../../../helpers/getCSVDataForMailInfo";
import { round } from "@/common/functions/round";
import CSV from "@/common/components/CSV";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelDataForMainInfo } from "../../../helpers/getExcleDataForMainInfo";
import { ActionsWrapper } from "../../ccPaymentKDRSearchBar/CCPaymentKDRSearchBar.styled";

export const getRaws = (mainReportData, selectedConsultant) =>
  mainReportData
    .sort((a, b) => a.contract_type.localeCompare(b.contract_type))
    .filter((e) => selectedConsultant.map((s) => s.value).includes(e._id))
    .map((data) => (
      <tr>
        <td>{data.contract_type}</td>
        <td>{data.name}</td>
        <td>{data.surname}</td>
        <td>{formatToDateTamplate(data.from)}</td>
        <td>{formatToDateTamplate(data.to)}</td>
        <td>{data.consultantHours || 0} h</td>
        <td>{data.managerHours || 0} h</td>
        <td>{data.hoursSum || 0} h</td>
        <td>
          {data.additional_bonus || 0} {data.currency}
        </td>
        <td>
          {data.gamification_bonus || 0} {data.currency}
        </td>
        <td>
          {round(data.pre_consultant) || 0} {data.currency}
        </td>
        <td>
          {round(data.final_consultant) || 0} {data.currency}
        </td>
        <td>
          {round(data.pre_manager) || 0} {data.currency}
        </td>
        <td>
          {round(data.final_manager) || 0} {data.currency}
        </td>
        <td>
          {round(data.sum) || 0} {data.currency}
        </td>
        <td>
          <ActionsWrapper>
            <CSV
              filename={`${
                data.name + "_" + data.surname + "_main_kdr_report"
              }.csv`}
              header={[
                "TYP",
                "IMIĘ",
                "NAZWISKO",
                "OD",
                "DO",
                "KONSULTANT",
                "MANAGER",
                "Suma",
                "DODATKOWY",
                "GRYWALIZACJA",
                "WSTĘPNE K",
                "WYRÓWNANIE K",
                "WSTĘPNE M",
                "WYRÓWNANIE M",
                "Suma",
              ]}
              data={getCSVDataForMainInfo(data)}
            />
            <ExportExcel
              fileName={`${
                data.name + "_" + data.surname + "_main_kdr_report"
              }`}
              excelData={getExcelDataForMainInfo(data)}
            />
          </ActionsWrapper>
        </td>
      </tr>
    ));
