import React, { useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import { useNavigate } from "react-router-dom";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import { getOrderWithContact } from "../../../../API/repositories/order";
import { useRequestsContext } from "../../../../common/hooks/requestHook";

const StyledInput = styled.input`
  width: 280px;
  border: 1px solid ${Colors.darkBlue};
  transition: width 0.3s;
  &:focus {
    width: 350px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border: 1px dashed black;
  border-radius: 20px;
  width: 500px;
`;

const Icon = styled.i`
  cursor: pointer;
  margin-left: -30px;
  color: ${Colors.darkBlue};
`;

const TruckIcon = styled.i`
  color: ${Colors.darkBlue};
  font-size: 32px;
  transition: transform 2s;
`;

const OrderSearch = () => {
  const navigate = useNavigate();
  const searchRef = useRef();
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { makeRequest } = useRequestsContext();

  const handleKeyUp = (e) => {
    if (e.key === "Enter") handleSearch(e);
  };

  const handleSearch = async (e) => {
    e && e.preventDefault();

    if (!searchRef.current?.value.trim()) {
      addMessage("Enter order id to search");
      return;
    }

    const orderResponse = await makeRequest(
      getOrderWithContact.bind(null, searchRef.current.value.trim())
    );

    if (!orderResponse.data) {
      addMessage("Order with this is doesn't exist", "error");
      return;
    }

    navigate(`/dashboard/order/${searchRef.current?.value.trim()}`);
  };

  return (
    <div>
      <Wrapper>
        <TruckIcon
          style={{ color: Colors.darkBlue, fontSize: "32px" }}
          className="fa-solid fa fa-truck-fast"
        />

        <div>
          <MessageQueue removeMessage={removeMessage} messages={messages} />
          <StyledInput
            ref={searchRef}
            className="offInputStyle"
            type="text"
            onKeyUp={(e) => handleKeyUp(e)}
            placeholder="Type order id"
          />
          <Icon
            className="fa fa-arrow-right animation-scale"
            onClick={(e) => handleSearch(e)}
          />
        </div>
      </Wrapper>
    </div>
  );
};

export default OrderSearch;
