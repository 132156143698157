import React from 'react'
import ContactInformation from './components/contactInformation/ContactInformation'
import Documents from './components/documents/Documents'
import ValidClaimChat from './components/validClaimChat/ValidClaimChat'
import { InfoSideWrapper } from './InfoSide.styled'

const InfoSide = ({
  order,
  contact,
  claimPriority,
  setClaimPriority,
  allMessages,
  setAllMessages,
  claim,
  handleValidClaimUpdate,
  claimStatus
}) => {
  return (
    <InfoSideWrapper>
      <ContactInformation
        order={order}
        contact={contact}
        claimPriority={claimPriority}
        setClaimPriority={setClaimPriority}
        handleValidClaimUpdate={handleValidClaimUpdate}
        claim={claim}
      />
      <Documents claim={claim} />
      <ValidClaimChat
        claimStatus={claimStatus}
        claim={claim}
        chatHeight={270}
        chatWidth={360}
        allMessages={allMessages}
        setAllMessages={setAllMessages}
        handleValidClaimUpdate={handleValidClaimUpdate}
      />
    </InfoSideWrapper>
  )
}

export default InfoSide
