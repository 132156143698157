import moment from "moment";
import { createDataSets } from "./createDataSets";

export const handleMapData = (selectedMarket, data, users, setChartData) => {
  const labels = data.map((d) => moment(d.from).format("YYYY-MM-DD"));

  setChartData(() => ({
    labels: labels,
    datasets: createDataSets(selectedMarket, data, users),
  }));
};
