import { 
	QueueGroupAction, 
	QueueGroupsActions, 
	QueueGroupsConsultants, 
	QueueGroupsTr 
} from "../QueueGroups.styled";

export const getRaws = ({
	queueGroups,
	users,
	setShowAddGroup,
	hadnleDeleteQueueGroup
}) => queueGroups
.sort((a, b) => a.point_from - b.point_from)
.map((data, index) => (
	<QueueGroupsTr key={index}>
		<td>{data.name}</td>
		<QueueGroupsConsultants>
			{users
				.filter((user) => data.consultants.includes(user.value))
				.map((userOption) => userOption.label)
				.join(", ")}
		</QueueGroupsConsultants>
		<td>{data.point_from}</td>
		<td>{data.point_to}</td>
		<td>{data.min_length}</td>
		<td>{data.max_length}</td>
		<QueueGroupsActions>
			<QueueGroupAction
				className="fa fa-edit"
				onClick={() => setShowAddGroup(data)}
			/>
			<QueueGroupAction
				className="fa fa-trash"
				color="red"
				onClick={(e) => hadnleDeleteQueueGroup(e, data._id)}
			/>
		</QueueGroupsActions>
	</QueueGroupsTr>
));
