import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_LIMIT, DEFAULT_PAGE } from './constants/searchParams';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { getValidClaimsFeedback } from '@/API/repositories/reports';
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue';
import Table from '@/common/components/Table';
import { getRaws } from './helpers/getRaws';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { ButtonsWrapper, Container, ContentWrapper, TableWrapper } from './ValidClaimsFeedbackReport.styled';
import Pagination from './components/pagination/Pagination';
import { getTotalPages } from './helpers/getTotalPages';
import { getHeadersArray } from './helpers/getHeadersArray';
import { filterReducer } from './helpers/reducer';
import { DEFAULT_FILTER, FILTER_ACTIONS } from './constants/filter';
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd';
import { getClaimTags } from '@/API/repositories/claim-tag';
import { Colors } from '@/common/colors/colors';
import CSV from '@/common/components/CSV';
import { HEADERS } from './constants/header';
import { getCSVBody } from './helpers/getCSVBody';
import { checkIsFilterApplied } from './helpers/checkIsFilterApplied';
import FilterSetter from './components/filterSetter/FilterSetter';

const ValidClaimsFeedbackReport = () => {
	const [filter, dispatchFilter] = useReducer(filterReducer, DEFAULT_FILTER);
	const [reportData, setReportData] = useState();
	const [count, setCount] = useState(0);
	const [inputPage, setInputPage] = useState();
	const [openAction, setOpenAction] = useState(null);
	const [tagsOptions, setTagsOptions] = useState([]);
	const [loadedPages, setLoadedPages] = useState([])

	const [searchParams, setSearchParams] = useSearchParams({ 
		page: DEFAULT_PAGE 
	});
	const { makeRequest, hasUnfilledRequest } = useRequestsContext();
	const { addMessage } = useNewMessageQueueContext();
	const { 
		commonData: { markets, products },
	} = useCommonDataContext();

	const page = useMemo(() => {
		const page = parseInt(searchParams.get('page'));
		
		if (isNaN(page)) {
			return DEFAULT_PAGE;
		}

		return page;
	}, [searchParams]);

	const checkAllowedToLoad = () => {
		if (DEFAULT_LIMIT < 1) {
			addMessage("Limit must be greater than 0", "error");
			return false;
		}

		if (page < 1) {
			addMessage("Page must be greater than 0", "error");
			return false;
		}

		return true;
	}

	const reloadFilterData = async (reloadFilter) => {
		setInputPage(DEFAULT_PAGE);

		const payload = {
			limit: DEFAULT_LIMIT,
			page: DEFAULT_PAGE,
			...Object.entries(reloadFilter).reduce((acc, [key, selected]) => {
				acc[key] = selected.map(element => element.value);
				return acc;
			}, {})
		};

		if (!checkAllowedToLoad()) {
			return;
		}

		const response = await makeRequest(getValidClaimsFeedback.bind(null, payload));

		setCount(response.data.count || 0);

		if (!response?.data?.claims) {
			return addMessage("Error loading data", "error");
		}

		setLoadedPages([DEFAULT_PAGE]);
		setReportData({
			[DEFAULT_PAGE]: response.data.claims
		});
	}

	const loadData = async () => {
		if (loadedPages.includes(page)) {
			return;
		}

		const payload = {
			limit: DEFAULT_LIMIT,
			page,
			...Object.entries(filter).reduce((acc, [key, selected]) => {
				acc[key] = selected.map(element => element.value);
				return acc;
			}, {})
		};

		if (!checkAllowedToLoad()) {
			return;
		}

		const response = await makeRequest(getValidClaimsFeedback.bind(null, payload));

		if (!response?.data?.claims) {
			return addMessage("Error loading data", "error");
		}

		const nextData = {
			...(reportData || {}),
			[page]: response.data.claims
		};

		setReportData(() => nextData);
		setLoadedPages(() => [...loadedPages, page]);	
		setCount(response.data.count);
	}

	const handleInputPageChange = (({ target: { value }}) => {
		setInputPage(value);
		validateInputPage(value);
	});

	const validateInputPage = (page) => {
		if (!page) {
			return;
		}

		if (page < 1) {
			return setInputPage(1);
		}

		const totalPages = getTotalPages(count, DEFAULT_LIMIT);

		if (page > totalPages) {
			return setInputPage(totalPages);
		}
	};

	const handleResetFilters = () => {
		setSearchParams({
			page: DEFAULT_PAGE
		})
		dispatchFilter({ type: FILTER_ACTIONS.RESET });
		reloadFilterData(DEFAULT_FILTER)
	}

	const loadTags = async () => {
    const response = await makeRequest(getClaimTags);

    if (!response?.data) {
      return addMessage("Error while loading tags", "error");
    }

    const mappedOptions = response.data
      .map(tag => ({ value: tag._id, label: <span style={{ color: Colors.darkGray2}}>{tag.label}</span>}));

    setTagsOptions(() => mappedOptions);
    return mappedOptions;
  };

	useEffect(() => {
		loadTags();
	}, [])

	useEffect(() => {
		loadData();
	}, [page])

	return (
		<ContentWrapper>
			{hasUnfilledRequest(getValidClaimsFeedback, getClaimTags) && <Loading />}
			<Container>
			<ButtonsWrapper>
				<CustomButtonAdd disabled={!checkIsFilterApplied(filter)} defaultText="Reset filters" onClick={() => handleResetFilters()} />
				{reportData?.[page] && (
					<CSV
					header={HEADERS}
					data={getCSVBody({
						data: reportData?.[page],
						markets,
						products,
						page,
					})}
					filename={`ValidClaimsFeedbackReport.csv`}	
				/>)}
			</ButtonsWrapper>
			<TableWrapper>
				{reportData?.[page] && (
					<Table 
						style={{ width: "100%" }}
						className="styled-table sticky-header" 
						headersArray={getHeadersArray(filter, setOpenAction)}
						raws={getRaws({data: reportData[page], markets, products, page })}
					/>
				)}
			</TableWrapper>
			</Container>
			<Pagination
				count={count}
				setSearchParams={setSearchParams}
				handleInputPageChange={handleInputPageChange}
				inputPage={inputPage}
				page={page}
			/>
			{openAction && (
				<FilterSetter
					openAction={openAction}
					tagsOptions={tagsOptions}
					setOpenAction={setOpenAction}
					filter={filter}
					dispatchFilter={dispatchFilter}
					reloadData={reloadFilterData}
					setSearchParams={setSearchParams}
				/>
			)}
		</ContentWrapper>
	)
}

export default ValidClaimsFeedbackReport
