import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TicketsListWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row-start: 2;
  grid-row-end: ${(props) => props.gridRawEnd};
  overflow: scroll;
  overflow-x: hidden;
  background-color: ${Colors.lightGray};
`;

export const MessageItem = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  min-width: 100%;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  color: ${(props) => props.color};
  gap: 10px;
  border-bottom: 1px solid ${Colors.newLightColor};
`;

export const MessageItemTitleInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MessageSenderNameWrapper = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

export const PriorityWrapper = styled.div`
  position: absolute;
  transform: translate(calc(-100% - 4px), 4px);
  background-color: ${Colors.red};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  padding: 6px 8px;
  padding-left: 20px;
  width: 45%;
  flex-shrink: 0;
`;

export const MessageStatus = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 4px 10px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  background-color: ${(props) => props.bgColor};
`;

export const MessageSenderName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MessageSenderMarket = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 100%;
  min-width: 100px;
  color: black;
  align-items: center;
  font-weight: bold;
  background-color: ${(props) => props.bakckgroundColor};
  border-right: 1px solid ${Colors.newLightColor};
`;

export const TicketTitle = styled.p`
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MessageText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
`;

export const OnlineRound = styled.span`
  background-color: ${(props) => props.backgroundColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const LastMessageTimeWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
