import moment from "moment";
import { createDataSets } from "./createDataSets";

export const handleMapData = (data, setChartData, markets) => {
  const labels = data.map((d) => moment(d.from).format("YYYY-MM-DD"));

  setChartData(() => ({
    labels: labels,
    datasets: createDataSets(data, markets),
  }));
};
