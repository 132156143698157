import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const NameWrapper = styled.h3`
  color: ${Colors.darkBlue};
  b {
    margin-left: 20px;
    font-style: oblique;
  }
`;

export const TopInfoWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const TopIcon = styled.i`
  font-size: 20px;
  color: ${Colors.darkBlue};
  cursor: pointer;
`;

export const AddDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const UploadFileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchBar = styled.div`
  padding: 10px;
  color: ${Colors.darkBlue};
  border: 1px dashed ${Colors.darkBlue};
  min-width: 1000px;
  border-radius: 15px;
  margin-bottom: 30px;
`;

export const SearchItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Flex = styled.div``;

export const Tbody = styled.tbody`
  td {
    padding: 10px;
    text-align: center;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
`;

export const TitleWrapper = styled.h4`
  margin: 20px 0;
  color: ${Colors.darkBlue};
`;

export const WorkingHoursWrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 30px;
  width: 600px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MultipleButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  margin-bottom: 60px;
`;

export const ConsultantMonthWrapper = styled.div``;
