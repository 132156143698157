import PopUp from "@/common/components/PopUp";
import React from "react";
import { Title } from "./ShippingTimeline.styled";
import Card from "@/common/components/Card";
import { getShippingTimeline } from "./helpers/getShippingTimeline";

const ShippingTimeline = ({ setShow, shipping }) => {
  return (
    <PopUp padding="20px" setShow={setShow}>
      <Title>Shipping history</Title>
      <Card>{getShippingTimeline(shipping)}</Card>
    </PopUp>
  );
};

export default ShippingTimeline;
