import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../../common/components/Input";
import styled from "styled-components";
import SelectInput from "../../../../../common/components/SelectInput";
import Form from "../../../../../common/components/Form";
import Card from "../../../../../common/components/Card";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { updateQueue } from "../../../../../API/repositories/queue";
import { Colors } from "@/common/colors/colors";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import PopUp from "@/common/components/PopUp";
import Loading from "@/common/components/Loading";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const EIGHT_DIGIT_COUNTRIES = ['lt']

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 900px;
`;

const Wrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const PhoneNUmbersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;

  h3 {
    color: ${Colors.darkBlue};
    font-weight: bold;
    font-size: 20px;
  }
`;

const ElementWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px dashed ${Colors.darkBlue};
  color: ${Colors.darkBlue};
  cursor: pointer;

  &:hover {
    border: 1px solid ${Colors.red};
    transform: scale(1.1);
  }
`;

const QUEUE_STATUSES = [
  { label: "NEW", value: "new" },
  { label: "NO_RESPONSE", value: "no_response" },
  { label: "CONFIRMED", value: "confirmed" },
  { label: "TRASH", value: "trash" },
  { label: "SHIFT", value: "shift" },
  { label: "FOREGIN", value: "foreign" },
  { label: "MERGED", value: "merged" },
  { label: "TEMP", value: "temp" },
  { label: "RESIGNED", value: "resigned" },
  { label: "INBOUND", value: "inbound" },
  { label: "RECALL", value: "recall" },
  { label: "TO_CONFIRM", value: "to_confirm" },
];

const PHONE_DIRECTIONS = {
  outgoing: "outgoing",
  incoming: "incoming",
};

const QueueForm = ({
  markets,
  products,
  users,
  queue,
  addMessage,
  loadData,
}) => {
  const nameRef = useRef();
  const t1Ref = useRef();
  const t2Ref = useRef();
  const phoneNumberRef = useRef();
  const minutesBetweenCallsRef = useRef();
  const increaseTimeBetweenCallsRef = useRef();
  const increaseTimeBetweenCallsAfterRef = useRef();
  const firstDequeueAfterRef = useRef();
  const maxCallTimesRef = useRef();
  const maxKeepTimeRef = useRef();
  const shiftForConsultantKeepTimeRef = useRef();
  const selectWeightRef = useRef();
  const callWeightRef = useRef();
  const isTestQeueRef = useRef();
  const dequeuNotificationLimitRef = useRef();

  const [phoneDirection, setPhoneDIrection] = useState();
  const [outgoing, setOutgoing] = useState(queue?.outbound_numbers || []);
  const [incoming, setIncomming] = useState(queue?.incoming_numbers || []);

  const [queueInForm, setQueueInFrom] = useState(queue);

  const [selectedProductOption, setSelectedProductOption] = useState(
    products.find((product) => product.value === queue.product)
  );
  const [selectedMarketOption, setSelectedMarketOption] = useState(
    markets.find((market) => market.value === queue.market)
  );
  const [selectedConsultantsOption, setSelectedConsultantsOption] = useState(
    users.filter((users) => queue.consultants.includes(users.value))
  );
  const [selectedQueueStatusesOption, setSelectedQueueStatusesOption] =
    useState(
      QUEUE_STATUSES.filter((status) =>
        queue.allowed_contact_statuses.includes(status.value)
      )
    );
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { commonData: { markets: allMarkets } } = useCommonDataContext();

  const handleAddPhoneNumber = (e) => {
    e && e.preventDefault();
    const phoneNumber = phoneNumberRef.current.value;
    const marketShort = allMarkets?.find(m => m._id === selectedMarketOption?.value)?.short;

    const digitsTargetAmount = EIGHT_DIGIT_COUNTRIES.includes(marketShort) ? 8 : 9;

    if (
      (phoneNumber.length > 0 && phoneNumber.length !== digitsTargetAmount) ||
      phoneNumber[0] === "+"
    ) {
      addMessage(`Type number with ${digitsTargetAmount} numbers without prefix`, "error");
      return;
    }

    if (phoneDirection === PHONE_DIRECTIONS.incoming) {
      setIncomming((prev) => [...prev, phoneNumber]);
    } else {
      setOutgoing((prev) => [...prev, phoneNumber]);
    }

    setPhoneDIrection(() => null);
  };

  const deleteIncoming = (incoming) => {
    setIncomming((prev) => prev.filter((p) => p !== incoming));
  };

  const deleteOutgoing = (outgoing) => {
    setOutgoing((prev) => prev.filter((p) => p !== outgoing));
  };

  const handleUpdateQueue = async (e) => {
    e.preventDefault();

    const queueToUpdate = {};

    queueToUpdate.name = nameRef.current ? nameRef.current.value : queue.name;
    queueToUpdate.t1_new = t1Ref.current ? t1Ref.current.value : queue.t1_new;
    queueToUpdate.t2_fresh = t2Ref.current
      ? t2Ref.current.value
      : queue.t2_fresh;
    queueToUpdate.minutes_between_calls = minutesBetweenCallsRef.current
      ? minutesBetweenCallsRef.current.value
      : queue.minutes_between_calls;
    queueToUpdate.increase_time_between_calls =
      increaseTimeBetweenCallsRef.current
        ? increaseTimeBetweenCallsRef.current.value
        : queue.increase_time_between_calls;
    queueToUpdate.increase_time_between_calls_after =
      increaseTimeBetweenCallsAfterRef.current
        ? increaseTimeBetweenCallsAfterRef.current.value
        : queue.increase_time_between_calls_after;
    queueToUpdate.first_dequeue_after = firstDequeueAfterRef.current
      ? firstDequeueAfterRef.current.value
      : queue.first_dequeue_after;
    queueToUpdate.maximum_call_times = maxCallTimesRef.current
      ? maxCallTimesRef.current.value
      : queue.maximum_call_times;
    queueToUpdate.max_keep_time = maxKeepTimeRef.current
      ? maxKeepTimeRef.current.value
      : queue.max_keep_time;
    queueToUpdate.shift_for_consultant_keep_time =
      shiftForConsultantKeepTimeRef.current
        ? shiftForConsultantKeepTimeRef.current.value
        : queue.shift_for_consultant_keep_time;

    queueToUpdate.allowed_contact_statuses = selectedQueueStatusesOption.map(
      (status) => status.value
    );
    queueToUpdate.consultants = selectedConsultantsOption.map(
      (consultant) => consultant.value
    );
    queueToUpdate.market = selectedMarketOption.value;
    queueToUpdate.product = selectedProductOption.value;
    queueToUpdate.outbound_numbers = outgoing;
    queueToUpdate.incoming_numbers = incoming;
    queueToUpdate.select_weight = selectWeightRef.current.value;
    queueToUpdate.call_weight = callWeightRef.current.value;
    queueToUpdate.dequeue_notification_limit = dequeuNotificationLimitRef.current?.value
    
    queueToUpdate.is_test_queue = isTestQeueRef?.current?.checked;

    const updatedQueue = await makeRequest(
      updateQueue.bind(null, queue._id, queueToUpdate)
    );

    if (updatedQueue.data) {
      addMessage("Saved", "success");
      await loadData();
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const handleSelectOptions = () => {
    setSelectedProductOption(() =>
      products.find((product) => product.value === queue.product)
    );
    setSelectedMarketOption(() =>
      markets.find((market) => market.value === queue.market)
    );
    setSelectedConsultantsOption(() =>
      users.filter((users) => queue.consultants.includes(users.value))
    );
  };

  useEffect(() => {
    handleSelectOptions();
  }, [products, markets, users]);

  return (
    <>
      {hasUnfilledRequest(updateQueue) && <Loading />}
      <Wrapper>
        <Card>
          <h3>
            <u style={{ color: Colors.darkBlue }}>Queue:</u>
          </h3>
          <Form onSubmit={(e) => handleUpdateQueue(e)}>
            <Flex>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    inputRef={t1Ref}
                    name="T1 - new "
                    value={queueInForm.t1_new}
                    required={true}
                    disabled={false}
                    inputWidth={60}
                    width={140}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={maxCallTimesRef}
                    name="Max Call Times"
                    value={queueInForm.maximum_call_times}
                    required={true}
                    disabled={false}
                    inputWidth={60}
                    width={140}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={t2Ref}
                    name="T2 - fresh "
                    value={queueInForm.t2_fresh}
                    required={true}
                    disabled={false}
                    inputWidth={60}
                    color={Colors.darkBlue}
                    width={140}
                  />
                  <Input
                    inputRef={maxKeepTimeRef}
                    name="Max Keep time"
                    value={queueInForm.max_keep_time}
                    required={true}
                    disabled={false}
                    color={Colors.darkBlue}
                    inputWidth={60}
                    width={140}
                  />
                  <SelectInput
                    name="Product"
                    options={products}
                    setSelected={setSelectedProductOption}
                    selected={selectedProductOption}
                    width={140}
                    selectWidth={160}
                    color={Colors.darkBlue}
                  />

                  <SelectInput
                    name="Market"
                    options={markets}
                    setSelected={setSelectedMarketOption}
                    selected={selectedMarketOption}
                    width={140}
                    selectWidth={160}
                    color={Colors.darkBlue}
                  />
                </div>

                <SelectInput
                  name="Consultants"
                  options={users}
                  setSelected={setSelectedConsultantsOption}
                  selected={selectedConsultantsOption}
                  color={Colors.darkBlue}
                  multiple={true}
                  width={100}
                  selectWidth={400}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    inputRef={minutesBetweenCallsRef}
                    name="Minutes between calls"
                    value={queueInForm.minutes_between_calls}
                    required={true}
                    color={Colors.darkBlue}
                    disabled={false}
                    inputWidth={100}
                  />
                  <Input
                    inputRef={increaseTimeBetweenCallsRef}
                    name="Increase Time Between Calls"
                    value={queueInForm.increase_time_between_calls}
                    required={true}
                    disabled={false}
                    inputWidth={100}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={increaseTimeBetweenCallsAfterRef}
                    name="Increase Time Between Calls After"
                    value={queueInForm.increase_time_between_calls_after}
                    required={true}
                    color={Colors.darkBlue}
                    disabled={false}
                    inputWidth={100}
                  />
                  <Input
                    inputRef={firstDequeueAfterRef}
                    name="First Dequeue After"
                    value={queueInForm.first_dequeue_after}
                    required={true}
                    color={Colors.darkBlue}
                    disabled={false}
                    inputWidth={100}
                  />
                  <ToggleSwitch
                    toggleRef={isTestQeueRef}
                    checked={queueInForm.is_test_queue}
                    text={"Test queue:"}
                  />
                </div>
                <div>
                  <Input
                    inputRef={nameRef}
                    name="Name"
                    value={queueInForm.name}
                    required={true}
                    color={Colors.darkBlue}
                    disabled={false}
                    width={140}
                  />
                  <SelectInput
                    name="Statuses"
                    options={QUEUE_STATUSES}
                    setSelected={setSelectedQueueStatusesOption}
                    selected={selectedQueueStatusesOption}
                    multiple={true}
                    color={Colors.darkBlue}
                    width={140}
                  />
                  <Input
                    inputRef={shiftForConsultantKeepTimeRef}
                    name="Shift for consultant keep time"
                    value={queueInForm.shift_for_consultant_keep_time}
                    required={true}
                    color={Colors.darkBlue}
                    disabled={false}
                    width={140}
                  />
                  <Input
                    inputRef={selectWeightRef}
                    name="Select weight"
                    value={queueInForm.select_weight}
                    required
                    color={Colors.darkBlue}
                    width={140}
                    type="number"
                  />
                  <Input
                    inputRef={callWeightRef}
                    name="Call weight"
                    value={queueInForm?.call_weight || 1}
                    required
                    color={Colors.darkBlue}
                    width={140}
                    type="number"
                  />
                  <Input
                    inputRef={dequeuNotificationLimitRef}
                    name="Dequeu notification limit"
                    value={queueInForm.dequeue_notification_limit}
                    color={Colors.darkBlue}
                    width={140}
                    type="number"
                  />
                </div>
              </div>
              <PhoneNUmbersWrapper>
                <h3>Outbounds:</h3>
                {outgoing.map((out) => (
                  <ElementWrapper onClick={() => deleteOutgoing(out)}>
                    {out}
                  </ElementWrapper>
                ))}
              </PhoneNUmbersWrapper>
              <PhoneNUmbersWrapper>
                <h3>Inbounds:</h3>
                {incoming.map((inc) => (
                  <ElementWrapper onClick={() => deleteIncoming(inc)}>
                    {inc}
                  </ElementWrapper>
                ))}
              </PhoneNUmbersWrapper>
              <ButtonWrapper>
                <CustomButtonAdd
                  text="Add outgoing"
                  onClick={() => setPhoneDIrection(PHONE_DIRECTIONS.outgoing)}
                />
                <CustomButtonAdd
                  text="Add incomming"
                  onClick={() => setPhoneDIrection(PHONE_DIRECTIONS.incoming)}
                />
                <CustomButtonSave text="Save" styles={{ marginBottom: 0 }} />
              </ButtonWrapper>
            </Flex>
          </Form>
        </Card>
      </Wrapper>
      {phoneDirection && (
        <PopUp setShow={setPhoneDIrection}>
          <h3 style={{ color: Colors.darkBlue, marginBottom: "40px" }}>
            Add {phoneDirection}:
          </h3>
          <form onSubmit={(e) => handleAddPhoneNumber(e)}>
            <Input
              inputRef={phoneNumberRef}
              name="Phone number"
              required={true}
              color={Colors.darkBlue}
              disabled={false}
              inputWidth={400}
              showLabel={false}
            />
            <ButtonWrapper style={{ marginTop: "40px" }}>
              <CustomButtonSave text="Save" />
            </ButtonWrapper>
          </form>
        </PopUp>
      )}
    </>
  );
};

export default QueueForm;
