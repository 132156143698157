import { round } from "./round";

export const caclManagerPreEffectivePaymentNew = (data, final) => {
  if (!filterDataByFinalStatusNew(data, final)?.length) {
    return 0;
  }

  const effectiveSalary = findEffectiveSalaryNew(data, final);

  const teamEffectivePayment = effectiveSalary?.team?.reduce((prev, next) => {
    const totalSalary = effectiveSalary?.data
      ? calcTotalSalary(next.data, next) * next.manager_percent / 100
      : 0;


    return prev + totalSalary;
  }, 0);

  return Math.round(teamEffectivePayment * 100) / 100 || 0;
}

export const caclManagerPreEffectivePayment = (data, final, forexRates) => {
  if (!filterDataByFinalStatus(data, final)?.length) {
    return 0;
  }

  const teamEffectivePayment = data._team.reduce((prev, next) => {
    const effectiveSalary = findEffectiveSalary(next, final);

    if (!effectiveSalary) {
      return prev;
    }

    const totalSalary = effectiveSalary?.data
      ? calcTotalSalary(effectiveSalary.data, next._month_working_info)
      : 0;

    const currencyRate =
      data.data[0].salary_currency !== effectiveSalary?.salary_currency
        ? findAverageForexRate(forexRates, data, effectiveSalary)
        : 1;

    const originalCurrencyValue = totalSalary * currencyRate;

    return prev + originalCurrencyValue;
  }, 0);

  return caluclateManagerPayment(teamEffectivePayment, data);
};

export const calcManagerHours = (data) => {
  return data.reduce((prev, next) => prev + (next.manager_hours || 0), 0);
};

const caluclateManagerPayment = (teamPayment, data) =>
  Math.round(teamPayment * data._month_working_info.manager_percent * 100) / 10000;

const findEffectiveSalaryNew = (data, final) => {
  return data?.data?.find(d => d.is_final === final);
}

const findEffectiveSalary = (dataItem, final) =>
  dataItem._effective_salary.find((e) => e.is_final === final);

const findAverageForexRate = (forexRates, data, effectiveSalary) =>
  forexRates.find(
    (forexRate) =>
      forexRate.to_currency.toLowerCase() === data.data[0].salary_currency &&
      forexRate.from_currency.toLowerCase() === effectiveSalary?.salary_currency
  )?.average_rate;

const filterDataByFinalStatusNew = (data, final) =>
  data?.data?.filter(d => d.is_final === final);

const filterDataByFinalStatus = (data, final) =>
  data._team.filter((d) =>
    d._effective_salary.find((e) => e.is_final === final)
  );

export const calcTotalSalary = (orders, payment) =>
  orders?.reduce(
    (current, next) =>
      current +
      calcOrderSalary(next.big_items_count, next.small_items_count, payment),
    0
  );

const calcOrderSalary = (bigItems, smallItems, payment) =>
  bigItems * payment.delivered_commision +
  smallItems * payment.additional_item_commision;

export const calcHoursPayment = (consultantResults) => {
  const result = round(
    consultantResults?.data[0]._consultant_hours.reduce(
      (prev, next) => prev + calcConsultantHours(next),
      0
    ) * consultantResults?._month_working_info.hourly_rate
  );

  return consultantResults._month_working_info.contract_type
    ?.split("_")[0]
    ?.toLowerCase() === "ro"
    ? Math.min(result, 4200)
    : result;
};

export const calcConsultantHours = (workingDay) => {
  return workingDay.attendance_at_work || 0;
};

export const calcPaymentResult = (consultantResults, final = false) => {
  const hoursPayment = calcHoursPayment(consultantResults);
  const bonus = calcTotalSalary(
    consultantResults.data.find((e) => e.is_final === final)?.data,
    consultantResults._month_working_info
  );

  return Math.max(bonus, hoursPayment);
};

export const calcBonus = (effectivePayment, hourPayment) => {
  const result = effectivePayment - hourPayment;

  return Math.max(result, 0);
};

export const calcEqualization = (data) => {
  if (
    data.data.length === 1 ||
    !data.data.find((e) => e.is_final === true)?.data
  ) {
    return 0;
  }

  const secondTotalCal = calcBonus(
    calcTotalSalary(
      data.data.find((e) => e.is_final === true).data,
      data._month_working_info
    ),
    calcHoursPayment(data)
  );

  const firstTotalCal = calcBonus(
    calcTotalSalary(
      data.data.find((e) => e.is_final === false).data,
      data._month_working_info
    ),
    calcHoursPayment(data)
  );

  return secondTotalCal - firstTotalCal;
};
