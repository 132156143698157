import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import {
  formatToDateTamplate,
  formatToDateTimeFormaterTemplate,
} from "../../../common/functions/dateFormater";
import { useFormik } from "formik";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import { getAdminRecordedCalls } from "../../../API/repositories/calling";
import Loading from "../../../common/components/Loading";
import { getTranscriptionById } from "../../../API/repositories/transcription";
import MediaPlayer from "../../../common/components/MediaPlayer";
import SelectInput from "../../../common/components/SelectInput";
import { CONSULTANT_ROLES } from "../../../components/loginPage/LoginForm/loginForm";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import Transcriptions from "../../../common/components/transcriptions/Transcriptions";
import { getRecordingById } from "@/API/repositories/storedDocument";

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const SearchBar = styled.div`
  border-radius: 15px;
  border: 1px solid ${Colors.darkBlue};
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 800px;
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
`;

const ListeningCalls = () => {
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [recording, setRecording] = useState(null);
  const [transcriptions, setTranscriptions] = useState();
  const [team, setTeam] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);

  const { options, filterUsersByRoles, commonData, queryValues } =
    useCommonDataContext();
  const { queuesOptions } = options;
  const { users } = commonData;
  const { isFetching } = queryValues;

  const handleGetRecording = async (e, element) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getRecordingById.bind(null, element.stored_data)
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(() => url);
    }
  };

  const handleGetTranscription = async (element) => {
    const response = await makeRequest(
      getTranscriptionById.bind(null, element.stored_data)
    );

    if (response?.data) {
      setTranscriptions(() => response.data);
    }
  };

  const handleSearch = async (values) => {
    values.fromDate = moment(values.fromDate).startOf("day").toISOString();
    values.toDate = moment(values.toDate).endOf("day").toISOString();

    const payload = {
      fromDate: moment(values.fromDate).startOf("day").toISOString(),
      toDate: moment(values.toDate).endOf("day").toISOString(),
      consultants: values?.consultants?.map((c) => c.value),
    };

    if (values.number) {
      payload.number = values.number;
    }

    const response = await makeRequest(
      getAdminRecordedCalls.bind(null, payload)
    );

    if (response.data) {
      setData(() =>
        response.data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: formatToDateTamplate(new Date()),
      toDate: formatToDateTamplate(new Date()),
      consultants: [],
    },
    onSubmit: (values) => handleSearch(values),
  });

  const laodData = async () => {
    setTeam(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  const handleSelectedQueues = (queue) => {
    let selectedUsersIds = [];

    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    formik.setFieldValue(
      "consultants",
      team.filter((c) => selectedUsersIds.includes(c.value))
    );

    setSelectedQueues(() => queue);
  };

  useEffect(() => {
    laodData();
  }, [users]);

  return (
    <>
      {(hasUnfilledRequest(
        getAdminRecordedCalls,
        getRecordingById,
        getTranscriptionById
      ) ||
        isFetching) && <Loading />}
      <Wrapper>
        <SearchBar>
          <InfoWrapper>
            <Input
              type="date"
              name={"From"}
              id="fromDate"
              value={formik.values.fromDate}
              onChange={formik.handleChange}
              width={80}
              inputWidth={130}
              color={Colors.darkBlue}
            />
            <Input
              type="date"
              name={"To"}
              id="toDate"
              value={formik.values.toDate}
              onChange={formik.handleChange}
              width={80}
              inputWidth={130}
              color={Colors.darkBlue}
            />
          </InfoWrapper>
          <InfoWrapper>
            <Input
              name={"Number"}
              id="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              width={100}
              inputWidth={260}
              color={Colors.darkBlue}
              placeholder="Type without country code"
            />
          </InfoWrapper>
          <InfoWrapper>
            <SelectInput
              name={"Consultants"}
              id="consultants"
              selected={formik.values.consultants}
              setSelected={(value) =>
                formik.setFieldValue("consultants", value)
              }
              options={team}
              width={100}
              multiple={true}
              inputWidth={220}
              color={Colors.darkBlue}
            />
            <SelectInput
              name={"Queue"}
              id="queue"
              selected={selectedQueues}
              setSelected={handleSelectedQueues}
              options={queuesOptions}
              width={100}
              multiple={true}
              inputWidth={220}
              color={Colors.darkBlue}
            />
          </InfoWrapper>
          <ButtonWrapper>
            <button className="btn btn-warning" onClick={formik.handleSubmit}>
              Search
            </button>
          </ButtonWrapper>
        </SearchBar>
        {data[0] && (
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Consultant</th>
                <th>Calling start at</th>
                <th>Number</th>
                <th>Client Full name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data
                ?.sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((elemnet, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{elemnet?.consultant?.username}</td>
                    <td>
                      {formatToDateTimeFormaterTemplate(elemnet.created_at)}
                    </td>
                    <td>
                      {elemnet.type === "incoming" ? elemnet.from : elemnet.to}
                    </td>
                    <td>{elemnet?.contact?.full_name || "------"}</td>
                    <td>
                      <ActionsWrapper>
                        <i
                          className="fa fa-ear-listen animation-scale"
                          style={{ cursor: "pointer", color: Colors.red }}
                          onClick={(e) => handleGetRecording(e, elemnet)}
                        />
                        <i
                          className="fa fa-scroll animation-scale"
                          style={{ cursor: "pointer", color: Colors.darkBlue }}
                          onClick={() => handleGetTranscription(elemnet)}
                        />
                      </ActionsWrapper>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Wrapper>
      {recording && (
        <MediaPlayer recording={recording} setClose={setRecording} />
      )}
      {transcriptions && (
        <Transcriptions
          transcriptions={transcriptions}
          setClose={setTranscriptions}
        />
      )}
    </>
  );
};

export default ListeningCalls;
