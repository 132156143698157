import moment from "moment";

export const LAST_YEAR_WEEKS_SUNDAY = [...Array(52).keys()].map(
  (howManySubstract) => {
    const element = {};

    element.value = moment()
      .subtract(howManySubstract, "weeks")
      .startOf("week");
    element.label = element.value.format("YYYY-MM-DD");

    return element;
  }
);
