import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getOutboundContact } from "@/API/repositories/queue";
import userManager from "@/API/userManager";
import Loading from "@/common/components/Loading";

import { useRequestsContext } from "@/common/hooks/requestHook";
import { Colors } from "@/common/colors/colors";

const Wrapper = styled.div`
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 20px;
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.color};
    color: white;
  }
`;

const Contact = ({
  textCurrent,
  textNew,
  currentContact,
  setCurrentContact,
}) => {
  const navigate = useNavigate();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleGetContact = async () => {
    if (currentContact[0]) {
      let orderId = currentContact[0].order;
      setCurrentContact(() => null);
      navigate(`/consultant/contact/outbound/${orderId}`);
    } else {
      const response = await makeRequest(
        getOutboundContact.bind(null, userManager.getUser().id)
      );

      if (response.data) {
        navigate(`/consultant/contact/outbound/${response.data._id}`);
      } else {
      }
    }
  };

  const getColor = (type) => {
    return type ? Colors.red : Colors.green;
  };

  return (
    <>
      <Wrapper
        color={getColor(
          currentContact && currentContact[0]?.type === "outbound"
        )}
        className="animation-scale"
        onClick={() => handleGetContact()}
        disabled={
          hasUnfilledRequest(getOutboundContact) ||
          (currentContact && currentContact[0]?.type === "inbound")
        }
      >
        <i className="fa fa-phone" style={{ fontSize: "30px" }} />{" "}
        <strong>
          {currentContact && currentContact[0]?.type === "outbound" ? (
            <>{textCurrent}</>
          ) : (
            <> {textNew}</>
          )}
        </strong>
      </Wrapper>
      {hasUnfilledRequest(getOutboundContact) ? <Loading /> : ""}
    </>
  );
};

export default Contact;
