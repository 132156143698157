import moment from "moment";
import React, { useEffect, useState } from "react";

const QueuePoints = ({ queuePoints }) => {
  const [points, setPoints] = useState(null);

  useEffect(() => {
    const pom = [{ point: 0, value: 0 }];
    const sortedPoints = queuePoints.points.sort((a, b) => a.point - b.point);

    for (let i = 0; i < sortedPoints.length; i++) {
      const number = pom[i].value + sortedPoints[i].value + 1;
      pom.push({ point: sortedPoints[i].point + 1, value: number });
    }

    setPoints(pom);
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <h3>
          <u>Queue points:</u>
        </h3>

        <h5>({moment(queuePoints.created_at).format("DD/MM HH:mm")})</h5>

        <h5>shifts: {queuePoints.shifts}</h5>
      </div>

      <table className="styled-table">
        <thead>
          <tr>
            <th>Point</th>

            {points &&
              points.map((point, i) => (
                <th key={i} style={{ textAlign: "center" }}>
                  {point.point}
                </th>
              ))}
          </tr>
        </thead>

        <tbody className="queue">
          <tr>
            <td>Value</td>

            {points && points.map((point, i) => <td key={i}>{point.value}</td>)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QueuePoints;
