export const calcWorkingHours = (workingInfo) => {
  const attendance = workingInfo.reduce(
    (prev, next) => prev + (next.attendance_at_work || 0),
    0
  );

  const holiday = workingInfo.reduce(
    (prev, next) => prev + (next.holiday_leave || 0),
    0
  );

  const specialLeave = workingInfo.reduce(
    (prev, next) => prev + (next.special_leave || 0),
    0
  );

  const sick = workingInfo.reduce(
    (prev, next) => prev + (next.sick_leave || 0),
    0
  );

  const request = workingInfo.reduce(
    (prev, next) => prev + (next.leave_on_reqests || 0),
    0
  );

  const managerHours = workingInfo.reduce(
    (prev, next) => prev + (next.manager_hours || 0),
    0
  );

  const excusesUnpaidLeave = workingInfo.reduce(
    (prev, next) => prev + (next.excused_unpaid_leave || 0),
    0
  );

  const absenceWithoutExcuse = workingInfo.reduce(
    (prev, next) => prev + (next.absence_without_excuse || 0),
    0
  );

  return (
    attendance +
    holiday +
    sick +
    request +
    managerHours +
    excusesUnpaidLeave +
    specialLeave +
    absenceWithoutExcuse
  );
};
