import { Colors } from "@/common/colors/colors";
import { VALID_CLAIM_PRIORITIES } from "@/common/constants/priorities";
import { checkOverdue } from "./checkOverdue";

export const checkHighPriority = (claim) => {
  const isOverdue = checkOverdue(claim);

  if (isOverdue) {
    return Colors.red;
  }

  if (claim.priority === VALID_CLAIM_PRIORITIES.HIGH.value) {
    return Colors.red2;
  }

  return null;
};
