import styled from "styled-components";
import { BORDER_RADIUS } from "../../../../constants/borderRadius";
import { Colors } from "@/common/colors/colors";

export const SumUpWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: gray;
  border-radius: ${BORDER_RADIUS};
  padding: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  transform: ${(props) =>
    props.isSumUpOpen ? "translateY(0%)" : "translateY(-100%)"};
  visibility: ${(props) => (props.isSumUpOpen ? "visible" : "hidden")};
  transition: all 0.2s;
`;

export const SumUpDataWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-grow: 1;
  align-items: center;
`;

export const SumUpInfo = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 22px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: ${(props) => props.color};
`;

export const SumUpItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SumUpItemElementsWrapper = styled.div`
  display: flex;
  gap: 64px;
`;

export const SumUpItemElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const SumUpItemElementInfo = styled.div`
  color: ${Colors.darkGray2};
  font-size: 14px;
`;

export const SumUpItemElementImageWrapper = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: relative;
`;

export const SumUpItemElementImage = styled.img`
  width: 100%;
`;
