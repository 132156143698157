import React from "react";
import { FilterWrapper, SearchBarWrapper } from "./SearchBar.styled";
import SelectInput from "@/common/components/SelectInput";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { Colors } from "@/common/colors/colors";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { COMPANIES_OPTIONS } from "@/common/constants/Accounting";

const SearchBar = ({
  handleSearch,
  setSelectedMonth,
  selectedMonth,
  selectedMarketsOptions,
  setSelectedMarketsOptions,
  marketsOptions,
}) => {
  return (
    <SearchBarWrapper>
      <FilterWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SelectInput
            name="Month"
            options={LAST_YEAR_MONTHS}
            color={Colors.darkBlue}
            width={50}
            setSelected={setSelectedMonth}
            selected={selectedMonth}
          />
          <CustomButtonAdd
            defaultText="Search"
            onClick={(e) => handleSearch(e)}
          />
        </div>
        <SelectInput
          name="Consultants"
          options={marketsOptions}
          color={Colors.darkBlue}
          showLabel={false}
          selectWidth={600}
          setSelected={setSelectedMarketsOptions}
          selected={selectedMarketsOptions}
          multiple={true}
        />
      </FilterWrapper>
    </SearchBarWrapper>
  );
};

export default SearchBar;
