import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
`;

export const SearchBar = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue};
  min-width: 50vw;
  border-radius: 15px;
`;

export const Flex = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: center;
`;
