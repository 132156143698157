import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../../../../common/components/Card";
import Table from "../../../../../common/components/Table";

const QueueCurrentConsultant = ({ currentConsultants }) => {
  const navigate = useNavigate();

  const headers = [
    "started at",
    "Consultant",
    "D.C.",
    "Status",
    "Created at Order",
    "Phone",
    "Actions",
  ];

  const raws = useMemo(() => {
    if (currentConsultants) {
      return currentConsultants.map((consultant, i) => (
        <tr>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {moment(consultant.updated_at).format("YYYY-MM-DD HH:mm:ss")}
          </td>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {consultant._user.name + " " + consultant._user.surname}
          </td>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {consultant.dequeued_count}
          </td>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {consultant._order.status}
          </td>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {moment(consultant._order.created_at).format("YYYY-MM-DD HH:mm:ss")}
          </td>
          <td style={{ padding: "10px 10px", textAlign: " center" }}>
            {consultant?._contact?.phone_number
              ?.match(/.{1,3}/g)
              .map((substring) => substring + " ")}
          </td>
          <td
            style={{
              padding: "10px 10px",
              textAlign: " center",
              gap: "20px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              onClick={() =>
                navigate(`/dashboard/contact/${consultant._contact._id}`)
              }
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-address-book" />
            </div>
            <div
              onClick={() =>
                navigate(`/settings/user/show/${consultant.consultant}`)
              }
            >
              <i className="fa fa-user" />
            </div>
          </td>
        </tr>
      ));
    }
  }, [currentConsultants]);

  return (
    currentConsultants &&
    currentConsultants.length > 0 && (
      <Card>
        <h3 style={{ marginBottom: "20px" }}>
          <u>Queue current workers:</u>
        </h3>

        <Table
          className="styled-table styled-table-2"
          raws={raws}
          headers={headers}
        />
      </Card>
    )
  );
};

export default QueueCurrentConsultant;
