import React from "react";

const NoResponseShiftTable = ({ data, consultants, name }) => {
  const calcByStatus = (data, status) => {
    return data.reduce(
      (prev, next) => prev + (next.status === status ? 1 : 0),
      0
    );
  };

  const calcResonForResigned = (data, status, reason) => {
    return data.reduce(
      (prev, next) =>
        prev +
        (next.status === status && next.no_response_reason === reason ? 1 : 0),
      0
    );
  };

  const calcResonForShift = (data, status, reason) => {
    return data.reduce(
      (prev, next) =>
        prev + (next.status === status && next.shift_reason === reason ? 1 : 0),
      0
    );
  };

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th colspan="9">NO RESPONSE AND SHIFT STATUSES ({name})</th>
        </tr>
        <tr>
          <th rowspan={2}>Consultant</th>
          <th colspan={5}>No Response</th>
          <th colspan={3}>Shift</th>
        </tr>
        <tr>
          <th>REJECTED_CALL</th>
          <th>VOICMAIL</th>
          <th>NO_ANSWER</th>
          <th>VOIP_ERROR</th>
          <th>OTHER</th>
          <th>NO_PRODUCT_INFO</th>
          <th>BAD_TIME</th>
          <th>OTHER</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data.length > 0 &&
          data.map(
            (element) =>
              element[0] && (
                <tr>
                  <td>
                    {element[0] &&
                      consultants.find((c) => c._id === element[0].consultant)
                        ?.username}
                  </td>
                  <td>
                    {calcResonForResigned(
                      element,
                      "no_response",
                      "REJECTED_CALL"
                    )}{" "}
                    (
                    {Math.round(
                      (calcResonForResigned(
                        element,
                        "no_response",
                        "REJECTED_CALL"
                      ) /
                        calcByStatus(element, "no_response")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForResigned(element, "no_response", "VOICMAIL")} (
                    {Math.round(
                      (calcResonForResigned(
                        element,
                        "no_response",
                        "VOICMAIL"
                      ) /
                        calcByStatus(element, "no_response")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForResigned(element, "no_response", "NO_ANSWER")}{" "}
                    (
                    {Math.round(
                      (calcResonForResigned(
                        element,
                        "no_response",
                        "NO_ANSWER"
                      ) /
                        calcByStatus(element, "no_response")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForResigned(element, "no_response", "VOIP_ERROR")}{" "}
                    (
                    {Math.round(
                      (calcResonForResigned(
                        element,
                        "no_response",
                        "VOIP_ERROR"
                      ) /
                        calcByStatus(element, "no_response")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForResigned(element, "no_response", "OTHER")} (
                    {Math.round(
                      (calcResonForResigned(element, "no_response", "OTHER") /
                        calcByStatus(element, "no_response")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForShift(element, "shift", "NO_PRODUCT_INFO")} (
                    {Math.round(
                      (calcResonForShift(element, "shift", "NO_PRODUCT_INFO") /
                        calcByStatus(element, "shift")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForShift(element, "shift", "BAD_TIME")} (
                    {Math.round(
                      (calcResonForShift(element, "shift", "BAD_TIME") /
                        calcByStatus(element, "shift")) *
                        100
                    )}
                    %)
                  </td>
                  <td>
                    {calcResonForShift(element, "shift", "OTHER")} (
                    {Math.round(
                      (calcResonForShift(element, "shift", "OTHER") /
                        calcByStatus(element, "shift")) *
                        100
                    )}
                    %)
                  </td>
                </tr>
              )
          )}
      </tbody>
    </table>
  );
};

export default NoResponseShiftTable;
