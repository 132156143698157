import React from 'react'
import { CLAIM_TYPES } from '@/common/constants/validClaims'
import { CheckBoxInputsWrapper, SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'

const ClaimType = ({ 
  claim, 
  claimType, 
  setClaimType, 
  maxClaimDate 
}) => {
  return (
    <SectionWrapper>
      <Subtitle>
        Claim type:
      </Subtitle>
      <CheckBoxInputsWrapper>
        <CheckBoxInput
          disabled={claim}
          checked={claimType === CLAIM_TYPES.REFUND}
          handleSelect={() => setClaimType(CLAIM_TYPES.REFUND)} 
          text={`Refund (due ${maxClaimDate})`} 
        />
        <CheckBoxInput
          disabled={claim}
          checked={claimType === CLAIM_TYPES.COMPLAINT}
          handleSelect={() => setClaimType(CLAIM_TYPES.COMPLAINT)} 
          text="Complaint" 
        />
      </CheckBoxInputsWrapper>
    </SectionWrapper>
  )
}

export default ClaimType
