import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants";
import { getRaws } from "./helpers/getRaws";

const SuspicionTable = ({ suspicions }) => {
  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(suspicions)}
    />
  );
};

export default SuspicionTable;
