import React from "react";
import { Colors } from "../colors/colors";
const ExcelJS = require("exceljs");

const ExportExcel = ({
  excelData,
  fileName,
  color = Colors.darkBlue,
  label,
}) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet 1");

    sheet.defaultRowHeight = 20;

    sheet.getRow(1).border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "6dbf9e" },
    };

    sheet.getRow(1).font = {
      size: 12,
      bold: true,
    };

    sheet.getRow(1).height = 20;
    sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

    sheet.columns = Object.entries(excelData[0]).map(([key, value]) => ({
      header: key,
      key: key,
      width: key.split("").length * 1.2,
      numFmt: "0.00",
    }));

    excelData?.map((data, i) => {
      sheet.addRow({
        ...data,
      });
      sheet.getRow(i + 2).height = 25;
      sheet.getRow(i + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div onClick={() => exportToExcel()}>
      {label && (
        <span style={{ color: "black", fontSize: "22px" }}>
          {label}
          &nbsp;&rarr;&nbsp;
        </span>
      )}
      <i
        className="animation-scale fa-solid fa-file-excel"
        style={{
          fontSize: `26px`,
          cursor: "pointer",
          color: color,
        }}
      />
    </div>
  );
};

export default ExportExcel;
