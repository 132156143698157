import MDEditor from "@uiw/react-md-editor";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: 20px;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
  padding: 30px;
  background-color: white;
`;

const ConsultantScript = ({ phoneScript }) => {
  return (
    <Wrapper>
      <MDEditor.Markdown
        source={phoneScript}
        style={{
          whiteSpace: "pre-wrap",
          backgroundColor: "white",
          color: "black",
        }}
      />
    </Wrapper>
  );
};

export default ConsultantScript;
