import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TodoTitle = styled.h3`
  background-color: ${Colors.purple};
  font-size: 22px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TodoItemsListWrapper = styled.div`
  position: relative;
  transform: translateY(${(props) => (props.hide ? "calc(50px - 100%)" : 0)});
  height: ${(props) => (props.hide ? "50px" : "fit-content")};
  z-index: ${(props) => (props.hide ? -1 : 1)};
  opacity: ${(props) => (props.hide ? 0.4 : 1)};
  overflow: hidden;
`;
