import { FilterOptionFinderBool } from "./components/filterOptionFinderBool/FilterOptionFinderBool";
import { FilterOptionFinderDate } from "./components/filterOptionFinderDate/FilterOptionFinderDate";
import { FilterOptionFinderNumber } from "./components/filterOptionFinderNumber/FilterOptionFinderNumber";
import { FilterOptionFinderString } from "./components/filterOptionFinderString/FilterOptionFinderString";
import { FilterOptionFinderObjectId } from "./components/filterOptoinFinderObjectId/FilterOptionFinderObjectId";

export const FilterOptionFinder = ({
  data,
  filters,
  dispatchFilters,
  markets,
  products,
  consultants,
}) => {
  return data.map((fieldData) => {
    if (fieldData.value.type === "String" || fieldData.value.type === "Enum")
      return (
        <FilterOptionFinderString
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "Date")
      return (
        <FilterOptionFinderDate
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "Number")
      return (
        <FilterOptionFinderNumber
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "Boolean")
      return (
        <FilterOptionFinderBool
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "ObjectID") {
      return (
        <FilterOptionFinderObjectId
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
          markets={markets}
          products={products}
          consultants={consultants}
        />
      );
    }

    return null;
  });
};