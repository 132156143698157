import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  height: 95vh;
  overflow-y: scroll;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const TitlePopUp = styled.h4`
  color: ${Colors.darkBlue};
  font-weight: bold;
  margin-bottom: 20px;
`;

export const MessagesBox = styled.div`
  padding: 20px 10px;
  width: 460px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid grey;
`;

export const UserWrapper = styled.p`
  font-size: 14px;
  margin-right: 10px;
  width: 120px;
  text-align: left;
`;
