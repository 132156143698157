import { Colors } from "@/common/colors/colors";
import Icon from "@/common/components/Icon";
import { formatDate } from "@/common/functions/dateFormater";

export const getRaws = ({
  callHistoryTimeline,
  checked,
  handleNavigateToOrder,
  handleListenSuspicious,
  handleTranscript,
  transcriptingRecording,
  loadingAudio,
  currentSuspicionRecording,
  handleChangeSuspiciousRecording,
}) =>
  callHistoryTimeline.map((callHistoryItem, i) => (
    <tr
      style={{
        backgroundColor:
          currentSuspicionRecording === callHistoryItem.stored_data?._id
            ? Colors.red
            : "",
      }}
      key={callHistoryItem.created_at}
    >
      <td>{i + 1}</td>
      <td>{callHistoryItem.type}</td>
      <td>{callHistoryItem.status}</td>
      <td>{callHistoryItem.consultant.username}</td>
      <td>{formatDate(callHistoryItem.created_at, "YYYY-MM-DD HH:mm")}</td>
      <td>{callHistoryItem.talking_time}</td>
      <td>
        <div style={{ display: "flex", gap: "12px" }}>
          {checked?.includes(callHistoryItem.stored_data?._id) &&
          currentSuspicionRecording !== callHistoryItem.stored_data?._id ? (
            <Icon
              onClick={() =>
                handleChangeSuspiciousRecording(
                  callHistoryItem.stored_data?._id,
                  callHistoryItem.consultant?._id
                )
              }
              cursor="pointer"
              color={Colors.red}
              name="fa fa-retweet"
            />
          ) : (
            "----"
          )}
          {callHistoryItem.order ? (
            <div>
              <Icon
                onClick={() =>
                  handleNavigateToOrder(callHistoryItem.order?._id)
                }
                cursor="pointer"
                color={Colors.darkGray2}
                name="fa fa-truck"
              />
            </div>
          ) : (
            "----"
          )}
          {callHistoryItem.stored_data ? (
            <>
              <div>
                {" "}
                <Icon
                  cursor="pointer"
                  onClick={() =>
                    handleListenSuspicious(callHistoryItem.stored_data?._id)
                  }
                  name={
                    callHistoryItem.stored_data._id === loadingAudio
                      ? "fa fa-spinner fa-spin"
                      : "fa fa-volume-low"
                  }
                />
              </div>
              <div>
                <Icon
                  onClick={() =>
                    handleTranscript(callHistoryItem.stored_data?._id)
                  }
                  cursor="pointer"
                  name={
                    callHistoryItem.stored_data?._id === transcriptingRecording
                      ? "fa fa-spinner fa-spin"
                      : "fa fa-scroll"
                  }
                />
              </div>
            </>
          ) : (
            <span>----&nbsp;&nbsp;----</span>
          )}
        </div>
      </td>
    </tr>
  ));
