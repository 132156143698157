import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;
const queryString = require("query-string");

export const getRealTimeData = () =>
  errorHandler(async () => {
    const data = await api.get(`/voip/get-realtime-data/`);
    return { data: data.data };
  });

export const getMarketStatistcs = () =>
  errorHandler(async () => {
    const data = await api.get(`/voip/get-market-statistics/`);
    return { data: data.data };
  });

export const getSpecialMarketStatistcs = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/voip/get-special-market-statistics?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getUserWorkingInformation = () =>
  errorHandler(async () => {
    const data = await api.get(`/voip/get-working-user`);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
