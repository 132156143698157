export const UNPAID_HEADERS = [
  "No.",
  "Market",
  "Full name",
  "Mail", 
  "Delivered at", 
  "Created at", 
  "Max payment date",
  "Amount", 
  "Payment method", 
  "Claim status", 
  "Actions"
];

export const PAID_HEADERS = [
  "No.", 
  "Market",
  "Full name",
  "Mail", 
  "Delivered at", 
  "Refund date",
  "Payment method",
  "Claim status",
  "Actions",
  "Załączniki"
];
