import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const createBonus = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/bonus/create`, payload);

    return { data: data.data };
  });

export const updateBonus = (payload, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/bonus/update/${id}`, payload);

    return { data: data.data };
  });

export const getBonuses = () =>
  errorHandler(async () => {
    const data = await api.get(`/bonus/get`);

    return { data: data.data };
  });

export const getBonusesByUserId = (userId) => 
  errorHandler(async () => {
    const data = await api.get(`/bonus/get/${userId}`);

    return { data: data.data };
  });
