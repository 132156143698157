import { Colors } from "@/common/colors/colors";
const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

export const SearchBar = styled.div`
  border-radius: 15px;
  border: 1px solid ${Colors.darkBlue};
  display: flex;
  justify-content: left;
  gap: 20px;
  min-width: 800px;
  padding: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  color: ${Colors.darkBlue};
`;
