import React, { useEffect, useState } from "react";
import {
  ChangeTitleWrapper,
  ConversationType,
  SuccessButton,
} from "../../ConversationGroup.styled";
import SelectInput from "@/common/components/SelectInput";
import { updateTicketTitle } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { Colors } from "@/common/colors/colors";
import { getTopicsOptions } from "../../../ticketView/helpers/getTopicsOptions";

function TicketChangeTitle({ selectedTicket, loadData, departments }) {
  const [titleLoading, setTitleLoading] = useState();
  const [isTitleSuccess, setIsTitleSuccess] = useState();
  const [selectedTitle, setSelectedTitle] = useState();

  const { makeRequest } = useRequestsContext();

  const handleUpdateTicketTitle = async () => {
    setTitleLoading(() => true);

    const response = await makeRequest(
      updateTicketTitle.bind(null, selectedTicket._id, selectedTitle.value)
    );

    if (response.data) {
      setIsTitleSuccess(() => true);
      await loadData();
    }

    setTitleLoading(() => false);
  };

  const handleChangeTitle = (title) => {
    if (title.value !== selectedTicket[selectedTicket.type].title) {
      setIsTitleSuccess(() => false);
    } else {
      setIsTitleSuccess(() => true);
    }

    setSelectedTitle(() => title);
  };

  const handleSelectTitle = () => {
    setIsTitleSuccess(() => true);
    setSelectedTitle(() =>
      getTopicsOptions(departments)?.find(
        (topic) => topic.value === selectedTicket.question?.title
      )
    );
  };

  useEffect(() => {
    handleSelectTitle();
  }, [selectedTicket, departments]);

  return selectedTicket.type === "question" &&
    !selectedTicket?.merged &&
    ["new", "open"].includes(selectedTicket.status) ? (
    <ChangeTitleWrapper>
      Topic:
      <SelectInput
        selectFontSize={14}
        showLabel={false}
        selectWidth={220}
        options={getTopicsOptions(departments)}
        selected={selectedTitle}
        setSelected={handleChangeTitle}
      />
      <SuccessButton
        onClick={() => handleUpdateTicketTitle()}
        backgroundColor={isTitleSuccess ? Colors.green : ""}
        borderColor={isTitleSuccess ? Colors.green : Colors.darkGray2}
        color={isTitleSuccess ? "white" : Colors.darkGray2}
        className="animation-scale"
      >
        <i
          className={
            titleLoading ? "fa-solid fa-spinner fa-spin" : "fa fa-check "
          }
        />
      </SuccessButton>
    </ChangeTitleWrapper>
  ) : (
    <ConversationType>
      {selectedTicket.type}, {selectedTicket.status}{" "}
      {selectedTicket.suspicion?.is_bad && ", suspicion was correct"}
    </ConversationType>
  );
}

export default TicketChangeTitle;
