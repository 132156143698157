import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import translationManager from "../../../API/translationManager";
import PopUp from "../../../common/components/PopUp";
import Buttons from "../calling-vonage/buttons/Buttons";
import Comments from "../calling-vonage/comments/Comments";
import ConsultantScript from "../calling-vonage/consultantScript/ConsultantScript";
import MoreInfo from "../calling/moreInfo/MoreInfo";
import Order from "../calling-vonage/order/Order";
import Orders from "../calling-vonage/orders/Orders";
import TicketsForCalling from "../calling-vonage/tickets/TicketsForCalling";
import ContactOutbound from "./outbound/ContactOutbound";
import ReCallInfo from "./ReCallInfo";
import ReCalling from "./ReCalling";
import WrapTimeCounter from "./timer/WrapTimeCounter";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import SupportMail from "@/common/components/supportMail/SupportMail";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MAX_WRAP_SECONDS = 60 * 4;

const OutboundView = ({
  handleEndCall,
  isTalking = true,
  setCountWrapTime,
  countWrapTime,
  handleWrappTimeClear,
}) => {
  const {
    currentContactOutbound,
    callOneceAgain,
    callingClientOnceAgain,
    setCallingClientOnceAgain,
    showResultReCall,
    setShowResultReCall,
  } = useContext(CallCRMApiContextVonage);

  const formatOrderVariantsToDisplay = () =>
    currentContactOutbound?.order_items?.map((orderItem, i) => ({
      _id: i + 1,
      name: currentContactOutbound._product.variants.find(
        (variant) => variant.short === orderItem.product_variant
      )?.name,
      price: orderItem.price,
      value: currentContactOutbound._product.variants.find(
        (variant) => variant.short === orderItem.product_variant
      )?.short,
    })) || [];

  const [chosenProductData, setChosenProductData] = useState(
    formatOrderVariantsToDisplay()
  );
  const [shouldDisableSuspicion, setShouldDisableSuspicion] = useState(
    currentContactOutbound?.shouldDisableSuspicion
  );
  const [showScript, setShowScript] = useState();
  const [isCallAgainDisabled, setIsCallAgainDisable] = useState(false);

  const [contact, setContact] = useState(
    currentContactOutbound._contact || null
  );
  const [comments, setComments] = useState(
    currentContactOutbound._comments || []
  );
  const [market, setMarket] = useState(currentContactOutbound._market || null);
  const [confirmation, setConfirmation] = useState(false);

  const [translation, setTranslation] = useState({
    reamainingTime: "Remaining time",
    minutes: "minutes",
    seconds: "seconds",
    fullName: "Full name",
    dequeuedCount: "Dequeued count",
    commentInfo: "type your comment",
    add: "Add",
    shift: "Shift",
    noResponse: "No response",
    resign: "Resign",
    confirm: "Confirm",
    contactOrders: "Contact Orders",
    no: "No.",
    status: "Status",
    currency: "Currency",
    product: "Product",
    market: "Market",
    createdAt: "Created at",
    sendMail: "Send Mail",
    save: "Save",
    street: "Street",
    postalCode: "Postal code",
    city: "City",
    additionalInfo: "Company name (if necessary)",
    country: "Country",
    email: "E-mail",
    street: "Street",
    city: "City",
    country: "Country",
    countryCode: "Country code",
    deliveryDate: "Delivery date",
    comment: "Comment",
    commentInfo: "Type comment...",
    basicInfo: "Basic info",
    product: "Product",
    confirm: "Confirm",
    items: "Items",
    send: "Send",
    phone: "phone",
    shippingInfo: "Shipping information",
    goToDashBoard: "Go to DashBoard",
    variant: "Variants",
    price: "Price",
    name: "Name",
    short: "Short",
    trash: "Trash",
    mailError: "There was an error while sending",
    mailSuccess: "Successfully sent an email",
    ticket: "Create ticket",
    createInvoice: "Create invoice",
    checkProm: "Check promotion",
    promNotFound: "A province with a postal code was not found",
    promNotAvaiable: "Error! Province:",
    promNotAvaiableTitle:
      "We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)",
    promAvaialbleTitle: "Postal code correct!  Province:",
    promAvaiable: "The customer can take advantage of the promotional offer",
    informed: "Informed",
    phone_number_for_courier: "Phone for courier",
    courier: "Courier",
    timeline: "Timeline",
    invoice_status: "Invoice status by color",
    mail_history: "Mail history",
    shipping_history: "Shipping history",
    suspicion: "Suspicion",
    suspect: "Suspect",
  });

  const translate = async () => {
    const translations = Object();
    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );
    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <>
      <PopUp closeIcon={false} canOffByClickingBackground={false}>
        <Wrapper>
          <div
            style={{
              padding: "30px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <MoreInfo
              product={currentContactOutbound._product.short}
              email={contact.email}
              sendMail={translation.sendMail}
              order={currentContactOutbound}
              mailError={translation.mailError}
              mailSuccess={translation.mailSuccess}
            />
            <SupportMail order={currentContactOutbound} />
            <TicketsForCalling
              market={market}
              orderId={currentContactOutbound._id.toString()}
            />
            <button
              className="btn btn-warning"
              onClick={() => setShowScript(() => true)}
            >
              Script
            </button>
            {isTalking && (
              <button
                className="btn btn-danger"
                type="button"
                onClick={(e) => {
                  setIsCallAgainDisable(() => true);
                  setTimeout(() => {
                    setIsCallAgainDisable(() => false);
                  }, 8000);
                  handleEndCall();
                }}
              >
                End Call
              </button>
            )}
            {!isTalking && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => {
                  setCountWrapTime(() => false);
                  setIsCallAgainDisable(() => true);
                  setTimeout(() => {
                    setIsCallAgainDisable(() => false);
                  }, 8000);
                  callOneceAgain();
                }}
                disabled={isCallAgainDisabled}
              >
                Call Again
              </button>
            )}

            {countWrapTime && (
              <WrapTimeCounter
                initTime={MAX_WRAP_SECONDS}
                onTimeEnd={handleWrappTimeClear}
              />
            )}
          </div>
          <ContactOutbound
            shouldDisableSuspicion={shouldDisableSuspicion}
            setShouldDisableSuspicion={setShouldDisableSuspicion}
            translation={translation}
            market={market}
            confirm={!confirmation}
            contact={contact}
            setContact={setContact}
          />
          <Order
            chosenProductData={chosenProductData}
            setChosenProductData={setChosenProductData}
            product={currentContactOutbound._product}
            contact={contact}
            order={currentContactOutbound}
          />
          <Buttons
            product={currentContactOutbound._product}
            contact={contact}
            chosenProductData={chosenProductData}
            data={currentContactOutbound}
            translation={translation}
          />
          <Orders
            orders={currentContactOutbound._orders || []}
            currentOrder={currentContactOutbound}
            translation={{
              contactOrders: translation.contactOrders,
              no: translation.no,
              status: translation.status,
              currency: translation.currency,
              product: translation.product,
              market: translation.market,
              createdAt: translation.createdAt,
              timeline: translation.timeline,
              invoice_status: translation.invoice_status,
              mail_history: translation.mail_history,
              shipping_history: translation.shipping_history,
              suspicion: translation.suspicion,
            }}
          />
          <Comments
            translation={{
              ...translation,
            }}
            comments={comments}
            contactId={currentContactOutbound._contact._id}
            setComments={setComments}
          />
        </Wrapper>
      </PopUp>
      {showScript && (
        <ConsultantScript
          setShowScript={setShowScript}
          phoneScript={currentContactOutbound._product.product_script}
        />
      )}
      {callingClientOnceAgain && (
        <ReCalling close={setCallingClientOnceAgain} />
      )}
      {showResultReCall && (
        <ReCallInfo
          showResultReCall={showResultReCall}
          setShowResultReCall={setShowResultReCall}
        />
      )}
    </>
  );
};

export default OutboundView;
