import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { ButtonWrapper } from "@/pages/admin/mailCategory/MailCategory.styled";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";

const AddNewProperty = ({ setShow, toggleProperty }) => {
  const propertyRef = useRef();
  const valueRef = useRef();

  const handleAddProperty = () => {
    setShow(() => false);
    toggleProperty({
      timestamp: new Date().getTime(),
      property: propertyRef.current.value,
      value: valueRef.current.value,
    });
  };

  return (
    <PopUp padding="10px 20px 18px" setShow={setShow}>
      <form onSubmit={() => handleAddProperty()}>
        <Input
          inputRef={propertyRef}
          color={Colors.darkBlue}
          width={100}
          name="Property"
          required
        />
        <Input
          inputRef={valueRef}
          color={Colors.darkBlue}
          width={100}
          name="Value"
          required
        />
        <ButtonWrapper>
          <CustomButtonSave
            onClick={() => handleAddProperty()}
            text="Save"
            styles={{ margin: 0 }}
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default AddNewProperty;
