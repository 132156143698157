import React, { useRef, useState } from "react";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { Bar } from "react-chartjs-2";
import { getUtmGampaingWPPKChart } from "@/API/repositories/reports";
import Input from "@/common/components/Input";

const WPPKDeliveredUTM = () => {
  const [chartData, setChartData] = useState();

  const utmCampaignRef = useRef();
  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);

    const payload = {};

    payload.from = moment().subtract("30", "days").startOf("day").toISOString();
    payload.to = moment().subtract("7", "days").endOf("day").toISOString();
    payload.utm_campaign = utmCampaignRef.current?.value;

    if (!payload.utm_campaign) return;

    const response = await makeRequest(getUtmGampaingWPPKChart.bind(null, payload));
    if (response?.data) {
      setChartData(response.data);
    }
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      handleLoadData();
    }
  };

  return (
    <Wrapper width={800}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa fa-arrow-trend-up" />
          {`WWPK UTM -30 -> -7 days`}
        </TopContentWrapper>
        <TopContentWrapper>
          <Input
            inputRef={utmCampaignRef}
            inputWidth={200}
            showLabel={false}
            placeholder="type utm"
            onKeyPress={handleSubmit}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <Bar data={chartData} />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default WPPKDeliveredUTM;
