import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const DynamicMailAttachmentTable = ({ dynamicMailAttachments, handleSetDynamicMailAttachmentEdit }) => {
  const { options: { marketsOptions } } = useCommonDataContext();

  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(dynamicMailAttachments, marketsOptions, handleSetDynamicMailAttachmentEdit)}
    />
  );
};

export default DynamicMailAttachmentTable;
