import { getCommon } from "../../API/query/common";
import { useQuery } from "react-query";
import { handleMapToOptions } from "../functions/handleMapToOptions";
import userManager from "../../API/userManager";

const COMMON_CACHE_TIME = 1000 * 60 * 30;

export const useCommonData = () => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ["common"],
    userManager.getUser()
      ? getCommon
      : () => ({ users: [], markets: [], products: [], queues: [] }),
    {
      onSuccess: (successData) => {
        handleSetOptions(successData);
        userManager.setCommonData(successData);
      },
      initialData: {
        users: userManager.getCommonData()?.users || [],
        markets: userManager.getCommonData()?.markets || [],
        products: userManager.getCommonData()?.products || [],
        queues: userManager.getCommonData()?.queues || [],
        usersOptions: userManager.getCommonData()?.usersOptions || [],
        productsOptions: userManager.getCommonData()?.productsOptions || [],
        marketsOptions: userManager.getCommonData()?.marketsOptions || [],
        queuesOptions: userManager.getCommonData()?.queuesOptions || [],
      },
      refetchInterval: COMMON_CACHE_TIME,
      refetchOnWindowFocus: false,
    }
  );

  const {
    users,
    products,
    markets,
    queues,
    usersOptions,
    productsOptions,
    marketsOptions,
    queuesOptions,
  } = data;

  const options = {
    usersOptions,
    marketsOptions,
    productsOptions,
    queuesOptions,
  };
  const commonData = { users, markets, queues, products };
  const queryFunctions = { refetch };
  const queryValues = { isFetching, isLoading };

  const handleSetOptions = (successData) => {
    successData.usersOptions = handleMapToOptions(
      successData.users,
      "username",
      "_id"
    );
    successData.marketsOptions = handleMapToOptions(
      successData.markets,
      "name",
      "_id"
    );
    successData.productsOptions = handleMapToOptions(
      successData.products,
      "name",
      "_id"
    );
    successData.queuesOptions = handleMapToOptions(
      successData.queues?.sort((qA, qB) => qB.select_weight - qA.select_weight),
      "name"
    );
  };

  const filterUsersByRoles = (roles, onlyActive = false) => {
    return handleMapToOptions(
      users.filter((user) => onlyActive ? roles.includes(user.role) && user.active : roles.includes(user.role)),
      "username",
      "_id"
    );
  };

  const getActiveUsersOptions = () => {
    return handleMapToOptions(users.filter((user) => user.active), "username", "_id");
  }

  return {
    commonData,
    options,
    filterUsersByRoles,
    getActiveUsersOptions,
    queryValues,
    queryFunctions,
  };
};
