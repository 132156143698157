import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createBlackBoardInfo,
  editBlackBoardInfo,
  getBlackBoardList,
} from "../../../../API/repositories/blackboardInfo";
import { formatDate } from "../../../../common/functions/dateFormater";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { CONSULTANT_ROLES } from "../../../loginPage/LoginForm/loginForm";
import BlackboardAction from "./BlackboardAction";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import Loading from "../../../../common/components/Loading";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  gap: 50px;
`;

export const MODE = {
  EDIT: "Edit",
  CREATE: "Create",
};

const BlackboardList = () => {
  const [mode, setMode] = useState(null);
  const [edit, setEdit] = useState(false);
  const [usersOptions, setUsersOptions] = useState();

  const [data, setData] = useState();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { filterUsersByRoles, commonData } = useCommonDataContext();
  const { users } = commonData;

  const handleLoadData = async (e) => {
    e && e.preventDefault();

    setUsersOptions(filterUsersByRoles(CONSULTANT_ROLES), "username", "_id");

    const dataResponse = await makeRequest(getBlackBoardList);

    if (dataResponse.data) {
      setData(() => dataResponse.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [edit, users]);

  return (
    <Wrapper>
      {(hasUnfilledRequest(createBlackBoardInfo) ||
        hasUnfilledRequest(editBlackBoardInfo)) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      <div style={{ width: "100%", textAlign: "left" }}>
        <button
          className="btn btn-warning"
          onClick={() => {
            setMode(() => MODE.CREATE);
          }}
        >
          Create
        </button>
      </div>
      {data?.length > 0 && (
        <table className="styled-table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Title</th>
              <th>From</th>
              <th>To</th>
              <th>Color</th>
              <th>Message</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((element, index) => (
                <tr>
                  <td>{index + 1}.</td>
                  <td>{element.title}</td>
                  <td>
                    {element.from && formatDate(element.from, "YYYY-MM-DD")}
                  </td>
                  <td>{element.to && formatDate(element.to, "YYYY-MM-DD")}</td>
                  <td>{element.color}</td>
                  <td style={{ maxWidth: "400px" }}>{element.message}</td>
                  <td>
                    <i
                      className="fa fa-edit animation-scale"
                      onClick={() => {
                        setEdit(element);
                        setMode(() => MODE.EDIT);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {mode && (
        <BlackboardAction
          data={edit || {}}
          mode={mode}
          reload={handleLoadData}
          setShow={setMode}
          usersOptions={usersOptions}
          addMessage={addMessage}
        />
      )}
    </Wrapper>
  );
};

export default BlackboardList;
