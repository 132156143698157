import { CLAIM_STATUSES } from "@/common/constants/claims";
import { CLAIM_REASONS } from "@/common/constants/support";
import { ClAIM_METHODS } from "@/common/constants/validClaims";

export const findClaimStatusConfig = (claimStatus) => 
  Object.values(CLAIM_STATUSES).find(s => s.value === claimStatus);

export const findClaimMethodConfig = (claimMethod) =>
  Object.values(ClAIM_METHODS).find(m => m.value === claimMethod);

export const findValidClaimReasonConfig = (claimReason) =>
  Object.values(CLAIM_REASONS).find(r => r.value === claimReason);
