import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

const PREFIX = 'claim-tag';

export const createClaimTag = (claimTag) =>
  errorHandler(async () => {
    const data = await api.post(`/${PREFIX}/create`, claimTag);

    return { data: data.data };
  });

export const updateClaimTag = (claimTag, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/update/${id}`, claimTag);

    return { data: data.data };
  });

export const getClaimTags = () =>
  errorHandler(async () => {
    const data = await api.get(`/${PREFIX}/get`);

    return { data: data.data };
  });
