import Api from "../api";
import { errorHandler } from "../common";

const { tokenManager, axios: api } = Api;

export const createConsultantDocuments = (body) =>
  errorHandler(async () => {
    const data = await api.post(
      "/consultant-documents/create-consultant-documents",
      body
    );
    return { data: data.data };
  });

export const updateConsultantDocuments = (body) =>
  errorHandler(async () => {
    const data = await api.patch(
      "/consultant-documents/update-consultant-documents",
      body
    );
    return { data: data.data };
  });

export const getAllConsultantDocuments = () =>
  errorHandler(async () => {
    const data = await api.get(
      `/consultant-documents/get-all-consultant-documents`
    );
    return { data: data.data };
  });

export const getConsultantDrafts = () =>
  errorHandler(async () => {
    const data = await api.get(
      `/consultant-documents/get-consultant-documents`
    );
    return { data: data.data };
  });

export const removeConsultantDraft = (id) =>
  errorHandler(async () => {
    const data = await api.delete(
      `/consultant-documents/delete-consultant-documents/${id}`
    );
    return { data: data.data };
  });
