import React from "react";
import PopUp from "@/common/components/PopUp";
import { ButtonWrapper, Title } from "./AddToQueue.styled";

const AddToQueue = ({
  setConfirmAddToQueue,
  selectedToQueue,
  handleAddOrdersToQueue,
}) => {
  return (
    <PopUp setShow={setConfirmAddToQueue}>
      <Title>
        Do you want do add to queue {selectedToQueue.length} Orders?
      </Title>
      <ButtonWrapper>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => setConfirmAddToQueue(false)}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-success"
          disabled={selectedToQueue.length === 0}
          onClick={(e) => handleAddOrdersToQueue(e)}
        >
          Yes
        </button>
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddToQueue;
