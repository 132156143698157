import React from "react";
import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { ButtonsWrapper, Title } from "./Warning.styled";

const Warning = ({ setShowWarning, handleSubmit }) => {
  return (
    <PopUp padding="10px 20px 14px" setShow={setShowWarning}>
      <Title>
        After accepting, it will be impossible to change invoice! Are you sure
        that you want to accept it?
      </Title>
      <ButtonsWrapper>
        <CustomButtonAdd
          onClick={() => setShowWarning(() => false)}
          color={Colors.red}
          defaultText="No"
        />
        <CustomButtonAdd
          onClick={() => handleSubmit()}
          color={Colors.green}
          defaultText="Yes"
        />
      </ButtonsWrapper>
    </PopUp>
  );
};

export default Warning;
