import Icon from "@/common/components/Icon";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const getRaws = (invoiceConfigs, setInvoiceConfig, handleTrigger) =>
  invoiceConfigs.map((config, i) => (
    <tr key={config._id}>
      <td>{i + 1}</td>
      <td>{config.name}</td>
      <td>{config.ip}</td>
      <td>{config.pathToRun}</td>
      <td>
        <Wrapper>
          <Icon onClick={() => setInvoiceConfig(config)} name="fa fa-edit" />
          <Icon
            onClick={() => handleTrigger(config._id)}
            name="fa fa-hourglass-start"
          />
        </Wrapper>
      </td>
    </tr>
  ));
