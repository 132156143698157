import { Colors } from '@/common/colors/colors';
import PopUp from '@/common/components/PopUp'
import React from 'react'
import styled from 'styled-components'

const ImportantText = styled.span`
  font-weight: 700;
  font-size: 20px;
`

const Text = styled.span`
  color: ${Colors.green};
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PaymentStatus = ({ status, setShow }) => {
  return (
    <PopUp setShow={setShow}>
     <Container>
      <Text>PayU status:</Text> <ImportantText>{status}</ImportantText>
     </Container>
    </PopUp>
  )
}

export default PaymentStatus
