import { InfoItem, InfoItemLabel, InfoItemsWrapper, InfoItemValue } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React from 'react'

const Cash = ({
	contact
}) => {
	return (
		<InfoItemsWrapper>
		<InfoItem>
			<InfoItemLabel>
				Postal code:
			</InfoItemLabel>
			<InfoItemValue>
				{contact?.postal_code}
			</InfoItemValue>
		</InfoItem>

		<InfoItem>
			<InfoItemLabel>
				Street:
			</InfoItemLabel>
			<InfoItemValue>
				{contact?.street}
			</InfoItemValue>
		</InfoItem>

		<InfoItem>
			<InfoItemLabel>
				City:
			</InfoItemLabel>
			<InfoItemValue>
				{contact?.city}
			</InfoItemValue>
		</InfoItem>
	</InfoItemsWrapper>
	)
}

export default Cash
