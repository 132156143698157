import { calcHoursPayment } from "./calcHoursPayment";
import { calcTotalSalary } from "../../../helpers/calcTotalSalary";

export const calcPaymentResult = (consultantResults, final = false) => {
  const hoursPayment = calcHoursPayment(consultantResults);
  const bonus = calcTotalSalary(
    consultantResults.data.find((e) => e.is_final === final).data,
    consultantResults._month_working_info
  );

  return Math.max(bonus, hoursPayment);
};
