import { Colors } from "@/common/colors/colors";

const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
  min-height: 100%;
`;

export const SearchBar = styled.div`
  z-index: 1;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  color: ${Colors.darkBlue};
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 15px;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
`;
