import React from "react";
import PopUp from "@/common/components/PopUp";
import { SubTitle, Textarea, SaveButtonWrapper } from "../../TicketView.styled";

const DocumentDecline = ({
  setIsToDeclineOpen,
  documentMessageRef,
  handleChangeDocumentTicketStatus,
}) => {
  return (
    <PopUp setShow={setIsToDeclineOpen}>
      <SubTitle>Add reason of decline:</SubTitle>

      <Textarea
        placeholder="decline..."
        showLabel={false}
        ref={documentMessageRef}
      />

      <SaveButtonWrapper>
        <button
          onClick={() => handleChangeDocumentTicketStatus("declined_pending")}
          className="btn btn-warning"
        >
          Save
        </button>
      </SaveButtonWrapper>
    </PopUp>
  );
};

export default DocumentDecline;
