import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { LAST_YEAR_WEEKS_MONDAY } from "@/common/constants/lastYearWeeksMonday";
import { LAST_YEAR_WEEKS_SUNDAY } from "@/common/constants/lastYearWeeksSunday";

export const PERIOD_OF_TIME_OPTIONS = [
  {
    label: "Week",
    value: "week",
    default_back: 11,
    period: LAST_YEAR_WEEKS_SUNDAY,
    period_from: LAST_YEAR_WEEKS_MONDAY,
  },
  {
    label: "Month",
    value: "month",
    default_back: 2,
    period: LAST_YEAR_MONTHS,
    period_from: LAST_YEAR_MONTHS,
  },
];
