export const getQuillDeltaPlain = (message, userId, showTranslation) => {
	let quillDelta = null;

	if (message.sender === userId)
    quillDelta = (showTranslation && message.quill_delta_translation) || message.quill_delta;
	else
		quillDelta = (!showTranslation && message.quill_delta_translation) || message.quill_delta;

	return JSON.parse(quillDelta).map((item) => item.insert).join('').trim();
}
