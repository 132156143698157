import {
  formatDate,
  formatToDateTamplate,
} from "@/common/functions/dateFormater";
import { getMarketById } from "../../../helpers/getMarketById";
import { getVatLabel } from "./getVatLabel";
import { countOrderItemsSum } from "../../../helpers/countOrderItemsSum";

export const getExcelData = (tickets, markets, currency) =>
  tickets.map((ticket, i) => ({
    No: i + 1,
    Kraj: getMarketById(ticket._order.market, markets)?.name,
    "Miesiąc wystawienia": formatDate(
      ticket.valid_invoice.issue_date,
      "YYYY-MM"
    ),
    "Data sprzedaży": formatToDateTamplate(ticket.valid_invoice.sold_date),
    "Data wystawienia faktury": formatToDateTamplate(
      ticket.valid_invoice.issue_date
    ),
    "Nr Faktury": ticket.valid_invoice.invoice_number || "----",
    "Korekta Faktury": ticket.valid_invoice.correction_number || "----",
    "Nazwa Firmy Klienta na wystawionej fakturze":
      ticket.valid_invoice.company_name || ticket.valid_invoice.full_name,
    "Nazwa Klienta (osoba fizyczna w specyfikacji excel od pośrednika)":
      ticket._contact?.full_name,
    [`Kwota (netto ${currency})`]: countOrderItemsSum(
      ticket.valid_invoice.order_items
    ),
    "Kwota VAT": getVatLabel(ticket.valid_invoice.vat_amount),
    [`Kwota (brutto ${currency})`]: countOrderItemsSum(
      ticket.valid_invoice.order_items
    ),
  }));
