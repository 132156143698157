import { getMarketById } from "./getMarketById";
import { formatDate } from "@/common/functions/dateFormater";
import { getVatLabel } from "./getVatLabel";
import { sortByInvoiceNumbers } from "../components/invoicesReportTable/helpers/sortByInvoiceNumber";

export const getCSVData = (orders, markets, correctionNumber) =>
  sortByInvoiceNumbers(orders)
    .filter((order) =>
      correctionNumber ? !!order._ticket.invoice.correction_number : true
    )
    .map((order, i) => [
      i + 1,
      getMarketById(order.market, markets)?.name,
      formatDate(order._ticket.invoice.issue_date, "YYYY-MM"),
      formatDate(
        order.shipping.status_delivered_at ||
          order.shipping.status_delivered_no_payment_at,
        "YYYY-MM-DD"
      ),
      formatDate(order._ticket.invoice.issue_date, "YYYY-MM-DD"),
      order._ticket.invoice.invoice_number || "----",
      order._ticket.invoice.correction_number || "----",
      order._ticket.invoice.comapny_name,
      order._contact?.full_name,
      order.order_items.reduce((prev, next) => prev + next.price, 0),
      getVatLabel(order._ticket.invoice.vat_amount),
      order.order_items.reduce((prev, next) => prev + next.price, 0),
    ]);
