import { getAllPosibleFiledsToFilter } from "@/API/repositories/order";
import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useState } from "react";
import { ButtonWrapper } from "../../MailCategory.styled";
import AddNewProperty from "./components/addNewProperty/AddNewProperty";
import { PropertiesWrapper, Property } from "./NewConditionalField.styled";

const CONDITIONAL_FIELDS_OPTIONS = [
  {
    label: "coursename",
    value: "coursename",
  },
  {
    label: "paylink",
    value: "paylink",
  },
];

const NewConditionalField = ({
  setShow,
  setConditionalFields,
  fieldData,
  addMessage,
}) => {
  const [data, setData] = useState();
  const [selectedConditionalField, setSelectedConditionalField] = useState(
    fieldData?.value
  );
  const [selectedFieldBy, setSelectedFieldBy] = useState(fieldData?.fieldBy);
  const [properties, setProperties] = useState(fieldData?.properties);

  const [showAddProperty, setShowAddProperty] = useState();

  const { makeRequest } = useRequestsContext();

  const getData = async () => {
    const response = await makeRequest(getAllPosibleFiledsToFilter);

    if (response.data) {
      setData(() => [
        ...Object.entries(response.data.orderFields).map(([key, value]) => {
          return { label: "order." + key, value: value };
        }),
      ]);
    }
  };

  const handleSave = () => {
    if (!selectedConditionalField || !selectedFieldBy || !properties?.length) {
      return addMessage("Select all data", "error");
    }
    setConditionalFields((prev) => {
      const filtered =
        prev?.filter(
          (field) => field.value !== selectedConditionalField.value
        ) || [];
      filtered.push({
        value: selectedConditionalField.value,
        fieldBy: selectedFieldBy.label,
        properties,
      });

      return filtered;
    });
    setShow(() => false);
  };

  const toggleProperty = (property) => {
    const hasProperty = properties?.find(
      (p) => property.timestamp === p.timestamp
    );

    if (hasProperty) {
      return setProperties((prev) =>
        prev?.filter((p) => p.timestamp !== property.timestamp)
      );
    }

    setProperties((prev) => [...(prev || []), property]);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <PopUp padding="10px 20px 14px" setShow={setShow}>
      <div style={{ marginBottom: "100px" }}>
        <CustomButtonAdd
          onClick={() => setShowAddProperty(true)}
          text="add properties"
        />
        <SelectInput
          name="Conditional field"
          color={Colors.darkBlue}
          width={150}
          selectWidth={350}
          options={CONDITIONAL_FIELDS_OPTIONS}
          setSelected={setSelectedConditionalField}
          selected={selectedConditionalField}
        />
        <SelectInput
          setSelected={setSelectedFieldBy}
          selected={selectedFieldBy}
          name="Field by"
          color={Colors.darkBlue}
          options={data}
          width={150}
          selectWidth={350}
        />
        <PropertiesWrapper>
          {properties?.map((property) => (
            <Property
              onClick={() => toggleProperty(property)}
              key={property.timestamp}
            >
              {property.property} - {property.value}
            </Property>
          ))}
        </PropertiesWrapper>
      </div>
      <ButtonWrapper>
        <CustomButtonAdd onClick={() => handleSave()} defaultText="Save" />
      </ButtonWrapper>
      {showAddProperty && (
        <AddNewProperty
          toggleProperty={toggleProperty}
          setShow={setShowAddProperty}
        />
      )}
    </PopUp>
  );
};

export default NewConditionalField;
