import Table from '@/common/components/Table'
import React, { useMemo } from 'react'
import { getPaidRaws } from '../../helpers/getPaidRaws';
import { getNotPaidRaws } from '../../helpers/getNotPaidRaws';
import { PAID_HEADERS, UNPAID_HEADERS } from '../../constants/headers';
import { INTERNAL_STATUSES } from '@/common/constants/claims';

const ClaimTable = ({
  validClaims,
  setSelectedClaim,
  setAddFile,
  selectedStatus
}) => {
  const headers = useMemo(() => {
    return selectedStatus.value === INTERNAL_STATUSES.DONE ? PAID_HEADERS : UNPAID_HEADERS;
  }, [selectedStatus]);

  const raws = useMemo(() => {
    if (!validClaims?.length) return [];

    return selectedStatus.value === INTERNAL_STATUSES.DONE
      ? getPaidRaws(validClaims, setSelectedClaim, setAddFile)
      : getNotPaidRaws(validClaims, setSelectedClaim, setAddFile)
  },
    [validClaims, selectedStatus]);

  return (
    <Table className="styled-table" headers={headers} raws={raws} />
  )
}

export default ClaimTable
