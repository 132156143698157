import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  getMarketWppkReportForConsultant,
  getMarketWppkReportForMarket,
} from "../../API/repositories/reports";
import { Colors } from "../../common/colors/colors";
import Card from "../../common/components/Card";
import CSV from "../../common/components/CSV";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import EffectiveReportConsultant from "../../components/admin/reports/EffectiveReportConsultant";
import EffectiveReportMarket from "../../components/admin/reports/EffectiveReportMarket";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

export const CALL_TYPE_OPTIONS = [
  { label: "Inbound", value: "inbound" },
  { label: "Outbound", value: "outbound" },
];

const EffectiveReport = () => {
  const [weight, setWeights] = useState(true);
  const [forConfirmedDate, setForConfirmedDate] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedCallTypes, setSelectedCallTypes] = useState(CALL_TYPE_OPTIONS);
  const [data, setData] = useState();

  const downloadStartDateRef = useRef();
  const downloadEndDateRef = useRef();
  const createdStartDateRef = useRef();
  const createdEndDateRef = useRef();

  const downloadStartDate = moment().format("YYYY-MM-DD");
  const createdStartDate = moment()
    .subtract("6", "months")
    .format("YYYY-MM-DD");

  const { options, queryValues, commonData } = useCommonDataContext();
  const { marketsOptions, usersOptions, queuesOptions, productsOptions } =
    options;
  const { isLoading } = queryValues;
  const { queues } = commonData;

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const round = (number) => {
    return Math.round(number * 100) / 100;
  };

  const getCsvRowsConsultant = (data) => {
    const result = [];
    Object.entries(data)
      .sort((a, b) => {
        if (weight)
          return (
            parseInt(a[1]._id.split("-")[1]) - parseInt(b[1]._id.split("-")[1])
          );
        return 1;
      })
      .sort((a, b) => {
        if (weight)
          return a[1]._id.split("-")[0].localeCompare(b[1]._id.split("-")[0]);
        return a[1]._id.localeCompare(b[1]._id);
      })
      .map(([key, value]) => {
        const temp = [];

        key = value._id;

        temp.push(
          usersOptions.find(
            (a) => a.value === (!weight ? key.split("-")[0] : key)
          ).label
        );
        temp.push(marketsOptions.find((a) => a.value === value.market).label);
        temp.push(productsOptions.find((a) => a.value === value.product).label);
        !weight && temp.push(key.split("-")[1]);
        temp.push(round(value.cod_count));
        temp.push(Math.round(value.cod_sum));
        temp.push(round(value.cod_delivered_count));
        temp.push(round(value.cod_delivered_sum));
        temp.push(
          round(
            value.weight_all_sum > 0 ? value.cod_sum / value.weight_all_sum : 0
          )
        );
        temp.push(
          round(
            value.weight_all_sum > 0
              ? value.cod_delivered_sum / value.weight_all_sum
              : 0
          )
        );
        temp.push(round(value.dequeue_count));
        temp.push(round(value.shift));
        temp.push(round(value.confirmed));
        temp.push(round(value.no_response));
        temp.push(round(value.trash));
        temp.push(round(value.informed));
        temp.push(round(value.resigned));
        temp.push(round(value.weight_all_sum));

        result.push(temp);
      });

    return result;
  };

  const getCsvRowsMarket = (data) => {
    const result = [];

    Object.entries(data).map(([key, value]) => {
      const temp = [];

      key = value._id;

      if (!marketsOptions.find((a) => a.value === value.market)) return;

      temp.push(marketsOptions.find((a) => a.value === value.market)?.label);
      temp.push(productsOptions.find((a) => a.value === value.product)?.label);
      !weight && temp.push(key.split("-")[1]);
      temp.push(round(value.cod_count));
      temp.push(Math.round(value.cod_sum));
      temp.push(round(value.cod_delivered_count));
      temp.push(round(value.cod_delivered_sum));
      temp.push(round(value.cod_sum / value.weight_all_sum));
      temp.push(round(value.cod_delivered_sum / value.weight_all_sum));
      temp.push(value.inbounds);
      temp.push(value.outbounds);
      temp.push(value.contacts);
      temp.push(round(value.weight_all_sum));

      result.push(temp);
    });

    return result;
  };

  const handleGetReport = async () => {
    setData(() => null);
    const payload = Object();
    payload.from = downloadStartDateRef.current
      ? moment(downloadStartDateRef.current.value).startOf("day")
      : moment(downloadStartDate).startOf("day");
    payload.to = downloadEndDateRef.current
      ? moment(downloadEndDateRef.current.value).endOf("day")
      : moment(downloadStartDate).endOf("day");
    payload.fromContact = createdStartDateRef.current
      ? moment(createdStartDateRef.current.value).startOf("day")
      : moment(createdStartDate).startOf("day");
    payload.toContact = createdEndDateRef.current
      ? moment(createdEndDateRef.current.value).endOf("day")
      : moment(downloadStartDate).endOf("day");
    payload.weights = !weight;
    payload.type = selectedCallTypes.map((callType) => callType.value);

    if (productsOptions && forConfirmedDate) {
      payload.ids = selectedMarkets.map((data) => data.value);
      if (payload.type.length === 0) return;
      if (payload.ids.length === 0) {
        addMessage("Select markets", "error");

        return;
      }

      const response = await makeRequest(
        getMarketWppkReportForMarket.bind(null, payload)
      );

      if (response.data && !Object.keys(response.data).length) {
        addMessage("Not found");
        setData(() => null);

        return;
      }

      if (response.data) {
        setData(() =>
          Object.entries(response.data).map(([key, value]) => {
            value._id = key;
            return value;
          })
        );
      }
    } else {
      payload.ids = selectedConsultants.map((data) => data.value);
      if (payload.ids.length === 0) {
        addMessage("Select consultants", "error");

        return;
      }

      const response = await makeRequest(
        getMarketWppkReportForConsultant.bind(null, payload)
      );

      if (response.data && !Object.keys(response.data).length) {
        addMessage("Not found");
        setData(() => null);

        return;
      }

      if (response.data) {
        setData(() =>
          Object.entries(response.data).map(([key, value]) => {
            value._id = key;
            return value;
          })
        );
      }
    }
  };

  const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

  return (
    <Wrapper>
      {(hasUnfilledRequest(getMarketWppkReportForConsultant) ||
        hasUnfilledRequest(getMarketWppkReportForMarket)) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      {!isLoading ? (
        <Card>
          <div style={{ maxWidth: "80vw" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  inputRef={downloadStartDateRef}
                  name="Download date from"
                  type="date"
                  width={200}
                  value={downloadStartDate}
                  inputWidth={140}
                />
                <Input
                  inputRef={downloadEndDateRef}
                  name="Download date to"
                  type="date"
                  width={200}
                  value={downloadStartDate}
                  inputWidth={140}
                />
                <Input
                  inputRef={createdStartDateRef}
                  name="Created date from"
                  type="date"
                  width={200}
                  value={createdStartDate}
                  inputWidth={140}
                />
                <Input
                  inputRef={createdEndDateRef}
                  name="Created date to"
                  type="date"
                  width={200}
                  value={downloadStartDate}
                  inputWidth={140}
                />
              </div>
              <div>
                <SelectInput
                  multiple={true}
                  name="Products"
                  options={productsOptions}
                  width={200}
                  setSelected={setSelectedMarkets}
                  selected={selectedMarkets}
                />
                <SelectInput
                  multiple={true}
                  name="Consultants"
                  options={usersOptions}
                  width={200}
                  setSelected={setSelectedConsultants}
                  selected={selectedConsultants}
                />
                <SelectInput
                  multiple={true}
                  name="Queue"
                  options={queuesOptions}
                  width={200}
                  setSelected={setSelectedQueue}
                  selected={selectedQueues}
                />
                <SelectInput
                  multiple={true}
                  name="Call types"
                  options={CALL_TYPE_OPTIONS}
                  width={200}
                  setSelected={setSelectedCallTypes}
                  selected={selectedCallTypes}
                />
              </div>
            </div>
            <div>Options:</div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span
                className="animation-scale"
                style={{
                  background: weight ? Colors.green : "none",
                  padding: "5px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  setData(() => null);
                  setWeights(true);
                }}
              >
                Bez podzialu na wagi
              </span>
              <span
                className="animation-scale"
                style={{
                  background: weight ? "none" : Colors.green,
                  padding: "5px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  setData(() => null);
                  setWeights(false);
                }}
              >
                Z podzialem na wagi
              </span>
              <span
                className="animation-scale"
                style={{
                  background: forConfirmedDate ? Colors.green : "none",
                  padding: "5px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  setData(() => null);
                  setForConfirmedDate(true);
                }}
              >
                Po Dacie utworzenia (market)
              </span>
              <span
                className="animation-scale"
                style={{
                  background: forConfirmedDate ? "none" : Colors.green,
                  padding: "5px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  setData(() => null);
                  setForConfirmedDate(false);
                }}
              >
                Po Dacie pobrania (konsultanci)
              </span>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              float: "right",
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {data && !forConfirmedDate && (
              <CSV
                filename={"effective-consultant.csv"}
                header={
                  !weight
                    ? [
                        "Consultant",
                        "Market",
                        "Product",
                        "Download no",
                        "COD number",
                        "COD sum",
                        "COD delivered number",
                        "COD delivered sum",
                        "WPPK",
                        "WPPK delivered",
                        "Number of downloaded orders",
                        "shifts",
                        "confirmed",
                        "no_response",
                        "informed",
                        "trash",
                        "resigned",
                        "Weight sum",
                      ]
                    : [
                        "Consultant",
                        "Market",
                        "Product",
                        "COD number",
                        "COD sum",
                        "COD delivered number",
                        "COD delivered sum",
                        "WPPK",
                        "WPPK delivered",
                        "Number of downloaded orders",
                        "shifts",
                        "confirmed",
                        "no_response",
                        "informed",
                        "trash",
                        "resigned",
                        "Weight sum",
                      ]
                }
                data={getCsvRowsConsultant(data)}
              />
            )}
            {data && forConfirmedDate && (
              <CSV
                filename={"effective-market.csv"}
                header={
                  !weight
                    ? [
                        "Market",
                        "Product",
                        "Download no",
                        "COD number",
                        "COD sum",
                        "COD delivered number",
                        "COD delivered sum",
                        "WPPK",
                        "WPPK delivered",
                        "Inbounds",
                        "Outbounds",
                        "Contacts",
                        "Weight sum",
                      ]
                    : [
                        "Market",
                        "Product",
                        "COD number",
                        "COD sum",
                        "COD delivered number",
                        "COD delivered sum",
                        "WPPK",
                        "WPPK delivered",
                        "Inbounds",
                        "Outbounds",
                        "Contacts",
                        "Weight sum",
                      ]
                }
                data={getCsvRowsMarket(data)}
              />
            )}
            <button
              onClick={(e) => handleGetReport(e)}
              className="btn btn-warning"
            >
              Search
            </button>
          </div>
        </Card>
      ) : (
        <Loading />
      )}
      {data && !forConfirmedDate && (
        <EffectiveReportConsultant
          weights={!weight}
          markets={marketsOptions}
          products={productsOptions}
          users={usersOptions}
          data={data}
        />
      )}
      {data && forConfirmedDate && (
        <EffectiveReportMarket
          weights={!weight}
          markets={marketsOptions}
          products={productsOptions}
          users={usersOptions}
          data={data}
        />
      )}
    </Wrapper>
  );
};

export default EffectiveReport;
