import { formatToDateTamplate } from "@/common/functions/dateFormater";

export const getCSVDataForShortInfo = (data) => {
  const result = [];

  data.forEach((data) => {
    const element = [];

    element.push(data.contract_type);
    element.push(data.name + " " + data.surname);
    element.push(formatToDateTamplate(data.from));
    element.push(formatToDateTamplate(data.to));
    element.push(data.hoursSum);
    element.push(data.sum);

    result.push(element);
  });

  return result;
};
