/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import userManager from "../../../../API/userManager";
import { Colors } from "../../../../common/colors/colors";
import UserSettings from "../../../../common/components/UserSettings";
import { adminNavigationConfig } from "../../../../common/constants/navigationConfigs";
import PopUp from "@/common/components/PopUp";

const Wrapper = styled.div`
  background-color: #035072;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 15px 15px 0px;
  height: 100vh;
  padding: 10px;
  width: 60px;
`;

const NavigationSection = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  gap: 15px;
  color: white;
  padding: 6px 15px;
  border-radius: 15px;

  ${({ link }) =>
    link && `margin-bottom: 10px; &:hover {  transform: scale(1.1);}`}
  ${({ selected }) => selected && ` background-color: ${Colors.lightBlue};  `} 
    
    
    &:hover .hover {
    display: block;
  }
`;

const Icon = styled.i`
  font-size: 20px;
  color: white;
`;

const IconWrapper = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80vh;
`;

const WrapperPopUp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: -20px;
  max-width: 510px;
`;

const NavigateElement = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 15px;
  gap: 20px;
  width: 250px;
  color: ${Colors.darkBlue};

  &:hover {
    transform: scale(1.05);
    color: ${Colors.lightBlue};
  }
`;

const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 30px;
  margin-top: -30px;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0 0 0;
  width: 600px;
`;

const Hover = styled.div`
  display: none;
  position: absolute;
  background-color: lightgrey;
  color: ${Colors.darkBlue};
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  margin-top: 50px;
  width: 80px;
  text-align: center;
  margin-left: -15px;
`;

const Navbar = () => {
  const [lastSelected, setLastSelected] = useState();
  const { pathname } = useLocation();
  const [sectionToShow, setSectionToShow] = useState("");

  const handleShowClick = (sectionName) => {
    setSectionToShow((prev) => {
      const newSectionName = prev === sectionName ? "" : sectionName;

      return newSectionName;
    });
    setLastSelected(() => sectionName);
  };

  const generateSettings = (setShow, list, name) => {
    return (
      <PopUp setShow={setShow} minWidth="900px">
        <Title>{name}:</Title>
        <CenterWrapper>
          <WrapperPopUp>
            {list
              .filter((e) => userManager.checkAccess(e.access))
              .map((element) => (
                <NavigateElement
                  key={element.name}
                  to={element.link}
                  onClick={() => setShow(() => null)}
                >
                  <div style={{ width: "20px", textAlign: "center" }}>
                    <i className={element.icon} />
                  </div>
                  {element.name}
                </NavigateElement>
              ))}
          </WrapperPopUp>
        </CenterWrapper>
      </PopUp>
    );
  };
  return (
    <Wrapper>
      <Box>
        {adminNavigationConfig
          .filter((d) => userManager.checkAccess(d.access))
          .map((navigationSection) => (
            <React.Fragment key={navigationSection.name}>
              <NavigationSection
                to={navigationSection.link ? navigationSection.link : pathname}
                selected={navigationSection.name === lastSelected}
                link={navigationSection.link}
                onClick={() => handleShowClick(navigationSection.name)}
              >
                <IconWrapper>
                  {navigationSection.path ? (
                    <img
                      className={navigationSection.icon}
                      src={navigationSection.path}
                      width={20}
                      height={20}
                    />
                  ) : (
                    <Icon className={navigationSection.icon} />
                  )}
                </IconWrapper>
                <Hover className="hover">{navigationSection.name}</Hover>
              </NavigationSection>
              {sectionToShow === navigationSection.name &&
                navigationSection.list &&
                generateSettings(
                  setSectionToShow,
                  navigationSection.list,
                  navigationSection.name
                )}
            </React.Fragment>
          ))}
      </Box>
      {sectionToShow === "Settings" && (
        <UserSettings setShowSettings={setSectionToShow} />
      )}
    </Wrapper>
  );
};

export default Navbar;
