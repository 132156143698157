import { findUserById } from "@/common/functions/findUserById";

export const getRaws = (tableData, users) =>
  tableData.map(([id, el], i) => (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>{findUserById(id, users)?.username}</td>
      <td>{el.cod_count}</td>
      <td>{el.dequeue_count}</td>
    </tr>
  ));
