import React from "react";

export const COLOR_CONFIG = [
  {
    base: "#F0BD5A",
    light: "#FFFAF0",
    dark: "#FFEFD0",
  },
  {
    base: "#8CD4C8",
    light: "#E1FCF8",
    dark: "#ACEFE4",
  },
  {
    base: "#BDB8DB",
    light: "#DAD7ED",
    dark: "#AAA2E0",
  },
];

const MarketsStatistics = ({ data = null }) => {
  const countByStatus = (data, status) => {
    return data.reduce(
      (prev, next) => prev + (next?._call_history?.status === status ? 1 : 0),
      0
    );
  };

  const calcRowSpanCountry = (value) => {
    return value.reduce((prev, next) => prev + next.data.length, 0);
  };

  return (
    <table className="styled-table-cutsom styled-table">
      <thead>
        <tr>
          <th colSpan={10}>Market Stats Outbound</th>
        </tr>
        <tr>
          <th>County</th>
          <th>Call Center Number</th>
          <th>Consultant</th>
          <th>Triggered calls</th>
          <th>No Response</th>
          <th>Voice mail</th>
          <th>Answerd</th>
          <th>Confirmed</th>
          <th>Shift</th>
          <th>Resign</th>
        </tr>
      </thead>
      <tbody className="queue">
        {Object.entries(data)?.length > 0 ? (
          Object.entries(data).map(([key, value], index) => (
            <>
              <tr key={2}>
                <td
                  rowSpan={calcRowSpanCountry(value)}
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.base,
                    writingMode: "vertical-rl",
                  }}
                >
                  {key}
                </td>
                <td
                  rowSpan={value[0]?.data?.length}
                  style={{ backgroundColor: COLOR_CONFIG[index]?.light }}
                >
                  {value[0]?.phone_number}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data && value[0]?.data[0]?.name}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data && value[0]?.data[0]?.value?.length}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {countByStatus(value[0]?.data[0].value, "no_response")}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data &&
                    value[0]?.data[0]?.value?.reduce(
                      (prev, next) =>
                        prev +
                        (next.answered_at &&
                        ["no_response"].includes(next._call_history.status)
                          ? 1
                          : 0),
                      0
                    )}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data &&
                    value[0]?.data[0].value.reduce(
                      (prev, next) =>
                        prev +
                        (next.answered_at &&
                        ["confirmed", "shift", "resigned"].includes(
                          next._call_history.status
                        )
                          ? 1
                          : 0),
                      0
                    )}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data &&
                    countByStatus(value[0]?.data[0].value, "confirmed")}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data &&
                    countByStatus(value[0]?.data[0].value, "shift")}
                </td>
                <td style={{ backgroundColor: COLOR_CONFIG[index]?.light }}>
                  {value[0]?.data &&
                    countByStatus(value[0]?.data[0].value, "resigned")}
                </td>
              </tr>
              {value[0].data?.map((consultantData, i) => {
                if (i > 0) {
                  return (
                    <tr
                      style={{
                        backgroundColor:
                          COLOR_CONFIG[index] &&
                          COLOR_CONFIG[index][
                            (i + 1) % 2 == 0 ? "dark" : "light"
                          ],
                      }}
                    >
                      <td>{consultantData?.name}</td>
                      <td>{consultantData?.value.length}</td>
                      <td>
                        {countByStatus(consultantData?.value, "no_response")}
                      </td>
                      <td>
                        {consultantData?.value.reduce(
                          (prev, next) =>
                            prev +
                            (next.answered_at &&
                            ["no_response"].includes(next._call_history.status)
                              ? 1
                              : 0),
                          0
                        )}
                      </td>
                      <td>
                        {consultantData?.value?.reduce(
                          (prev, next) =>
                            prev +
                            (next.answered_at &&
                            ["confirmed", "shift", "resigned"].includes(
                              next._call_history.status
                            )
                              ? 1
                              : 0),
                          0
                        )}
                      </td>
                      <td>
                        {consultantData?.value &&
                          countByStatus(consultantData?.value, "confirmed")}
                      </td>
                      <td>
                        {consultantData?.value &&
                          countByStatus(consultantData?.value, "shift")}
                      </td>
                      <td>
                        {consultantData?.value &&
                          countByStatus(consultantData?.value, "resigned")}
                      </td>
                    </tr>
                  );
                }
              })}
              {value?.map((data, i) => {
                if (i > 0) {
                  return (
                    <>
                      <tr
                        style={{ backgroundColor: COLOR_CONFIG[index]?.base }}
                      >
                        <td rowSpan={data.data.length}>{data.phone_number}</td>
                        <td>{data?.data && data?.data[0].name}</td>
                        <td>{data?.data && data?.data[0].value.length}</td>
                        <td>
                          {data?.data &&
                            countByStatus(data?.data[0].value, "no_response")}
                        </td>
                        <td>
                          {data?.data &&
                            data?.data[0].value.reduce(
                              (prev, next) =>
                                prev +
                                (next.answered_at &&
                                ["no_response"].includes(
                                  next._call_history.status
                                )
                                  ? 1
                                  : 0),
                              0
                            )}
                        </td>
                        <td>
                          {data?.data &&
                            data?.data[0].value.reduce(
                              (prev, next) =>
                                prev +
                                (next.answered_at &&
                                ["confirmed", "shift", "resigned"].includes(
                                  next._call_history.status
                                )
                                  ? 1
                                  : 0),
                              0
                            )}
                        </td>
                        <td>
                          {data?.data &&
                            countByStatus(data?.data[0].value, "confirmed")}
                        </td>
                        <td>
                          {data?.data &&
                            countByStatus(data?.data[0].value, "shift")}
                        </td>
                        <td>
                          {data?.data &&
                            countByStatus(data?.data[0].value, "resigned")}
                        </td>
                      </tr>
                      {data?.data?.map(
                        (consultantData, i) =>
                          i > 0 && (
                            <tr
                              style={{
                                backgroundColor: COLOR_CONFIG[index]?.base,
                              }}
                            >
                              <td>{consultantData?.name}</td>
                              <td>{consultantData?.value.length}</td>
                              <td>
                                {countByStatus(
                                  consultantData?.value,
                                  "no_response"
                                )}
                              </td>
                              <td>
                                {consultantData?.value.reduce(
                                  (prev, next) =>
                                    prev +
                                    (next.answered_at &&
                                    ["no_response"].includes(
                                      next._call_history.status
                                    )
                                      ? 1
                                      : 0),
                                  0
                                )}
                              </td>
                              <td>
                                {consultantData?.value.reduce(
                                  (prev, next) =>
                                    prev +
                                    (next.answered_at &&
                                    ["confirmed", "shift", "resigned"].includes(
                                      next._call_history.status
                                    )
                                      ? 1
                                      : 0),
                                  0
                                )}
                              </td>
                              <td>
                                {consultantData?.value &&
                                  countByStatus(
                                    consultantData?.value,
                                    "confirmed"
                                  )}
                              </td>
                              <td>
                                {consultantData?.value &&
                                  countByStatus(consultantData?.value, "shift")}
                              </td>
                              <td>
                                {consultantData?.value &&
                                  countByStatus(
                                    consultantData?.value,
                                    "resigned"
                                  )}
                              </td>
                            </tr>
                          )
                      )}
                    </>
                  );
                }
              })}
            </>
          ))
        ) : (
          <tr>
            <td colSpan={10}>There is no data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default MarketsStatistics;
