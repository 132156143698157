import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 700px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const Paragraph = styled.p`
  width: 100%;
`;

export const CommentsWrapper = styled.div`
  width: 100%;
  min-width: 460px;
  border: 1px solid ${Colors.newGray};
  border-radius: 4px;
  height:  ${(props) => `${props.chatHeight || 460}px`};
  overflow-y: scroll;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  margin-bottom: 16px;
`;

export const SendCommentWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Textarea = styled.textarea`
  border: 1px solid ${Colors.newDarkGray};
  outline: none;
  resize: none;
  border-radius: 4px;
  opacity: 0.7;
  flex-grow: 1;
  height: 60px;
  color: ${Colors.newGray};
  transition: box-shadow 0.2s;

  &:focus {
    opacity: 1;
    box-shadow: 0 0 4px 1px ${Colors.purple};
  }
`;

export const EmptyCommentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  color: ${Colors.newGray};
`;

export const CommentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px 12px;
  color: #fff;
  border-radius: 20px;
  width: 200px;
  overflow-wrap: anywhere;
  align-self: ${(props) => props.align};
  background-color: ${(props) => props.backgroundColor};
`;

export const MessageSendingWrapper = styled.span`
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

export const MessageSenderWrapper = styled.strong`
  display: flex;
  justify-content: space-between;
`;

export const MessageInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const TranslationIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  right: 20px;
  position: sticky;
  top: 0;
`;

export const TranslateIcon = styled.i`
  right: 10px;
  top: 8px;
  font-size: 32px;
  color: ${Colors.newLightColor};
  cursor: pointer;
`;
