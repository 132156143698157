import React, { useState } from "react";
import {
  NameWrapper,
  TopIcon,
  TopInfoWrapper,
} from "../../../../CCHours.styled";
import AddDocument from "./components/addDocument/AddDocument";

const TopInfo = ({
  consultants,
  showConsultantMonth,
  selectedMonth,
  hanldeSearch,
}) => {
  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState();

  return (
    <TopInfoWrapper>
      <NameWrapper>
        {selectedMonth?.label}:
        <b>
          <u>
            {
              consultants.find((c) => c.value === showConsultantMonth._id)
                ?.label
            }
          </u>
        </b>
      </NameWrapper>
      <TopIcon
        onClick={() => setIsAddDocumentOpen(true)}
        className="fa fa-file-circle-plus animation-scale"
      />
      {isAddDocumentOpen && (
        <AddDocument
          setIsAddDocumentOpen={setIsAddDocumentOpen}
          hanldeSearch={hanldeSearch}
          showConsultantMonth={showConsultantMonth}
        />
      )}
    </TopInfoWrapper>
  );
};

export default TopInfo;
