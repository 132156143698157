export const HEADERS_ARRAY = [
  [<th colSpan={6}>Pre Manager result</th>],
  [
    <th>Consultant</th>,
    <th>Price</th>,
    <th>For one</th>,
    <th>Confirmed orders</th>,
    <th>Delivered orders</th>,
    <th>Effecitve payment</th>,
  ],
];
