import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const saveConsultantPdfToAws = (query, body) =>
  errorHandler(async () => {
    const data = await api.post(
      "/stored-document/save-consultant-pdf?" +
        queryString.stringify(cleanObject(query), { arrayFormat: "bracket" }),
      body
    );

    return { data: data.data };
  });

export const savePdfsavePdfForConsultantDocumentsToAws = (body) =>
  errorHandler(async () => {
    const data = await api.post(
      `/stored-document/save-pdf-consultant-drafts/`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data: data.data };
  });

export const savePdfToAws = (claimId, body) =>
  errorHandler(async () => {
    const data = await api.post(`/stored-document/save-pdf/${claimId}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { data: data.data };
  });

export const getPdfsByIds = (ids) =>
  errorHandler(async () => {
    const data = await api.get(
      "/stored-document/pdf-files-by-ids?" +
        queryString.stringify(cleanObject(ids), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getRecordingById = (_id) =>
  errorHandler(async () => {
    const data = await api.get("/stored-document/file-by-id/" + _id, {
      responseType: "Buffer",
    });
    return { data: data.data };
  });

export const getFileById = (_id, shoudlAddSeen = false) =>
  errorHandler(async () => {
    const data = await api.get(
      `/stored-document/pdf-file-by-id/${_id}/${shoudlAddSeen}`,
      {
        responseType: "Buffer",
      }
    );
    return { data: data.data };
  });

export const deleteFileById = (_id) =>
  errorHandler(async () => {
    const data = await api.delete("/stored-document/pdf-file-by-id/" + _id);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
