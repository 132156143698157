import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";

export const SearchBar = styled.div`
  border: 1px solid ${Colors.darkBlue};
  padding: 20px;
  border-radius: 15px;
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
`;

export const TableWrapper = styled.div`
  overflow: scroll;
  max-height: 80vh;
  max-width: 80vw;
`;

export const FlexRightItem = styled.div`
  align-self: flex-end;
`;

export const Flex = styled.div`
  display: flex;
`;

export const GapFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SelectsFlex = styled.div`
  display: flex;
`;
