import PopUp from '@/common/components/PopUp'
import { Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React, { useEffect } from 'react'
import { ButtonWrapper, InputsWrapper, Textarea } from './AddMessage.styled'
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'
import Input from '@/common/components/Input'
import { Colors } from '@/common/colors/colors'
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue'
import { formatToDateTamplate } from '@/common/functions/dateFormater'

const AddMessage = ({ messageRef, status, setShow, send, failDateRef, requireFailDate = false }) => {
  const { addMessage } = useNewMessageQueueContext();

  const handleSend = () => {
    if (requireFailDate && !failDateRef.current?.value) {
      return addMessage("Transfer fail date is required", "info", 1000);
    }

    setShow(false);
    send(status, messageRef.current?.value);
  }

  useEffect(() => {
    messageRef?.current.focus();
  }, [messageRef?.current])

  return (
    <PopUp padding='10px 20px 16px' setShow={setShow}>
      <Subtitle>Add message to change status to "{status}":</Subtitle>
      <InputsWrapper>
        {requireFailDate && (
          <Input
            inputRef={failDateRef}
            color={Colors.darkBlue}
            name="Transfer fail date"
            width={123}
            fontSize='14px'
            inputFontSize='14px'
            height={30}
            inputWidth={350}
            type='datetime-local'
            margin='0px'
          />
        )}
        <Textarea placeholder={"Type..."} ref={messageRef} />
      </InputsWrapper>
      <ButtonWrapper>
        <CustomButtonAdd
          onClick={handleSend}
          defaultText="Send"
        />
      </ButtonWrapper>
    </PopUp>
  )
}

export default AddMessage
