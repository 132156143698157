import Input from "@/common/components/Input";
import React, { useEffect, useRef } from "react";
import { Form, Title } from "../../InvoiceTicket.styled";
import { Colors } from "@/common/colors/colors";
import { ButtonWrapper, Textarea } from "../addComment/AddComment.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createValidInvoiceTicket } from "@/API/repositories/tickets";
import { getOrderWithContact } from "@/API/repositories/order";
import Loading from "@/common/components/Loading";

const PhysicalPerson = ({
  orderId,
  addMessage,
  setShowInvoiceTicket,
  handleSearch,
}) => {
  const fullNameRef = useRef();
  const streeNumberRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const commentRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getOrderWithContact.bind(null, orderId));

    if (response?.data) {
      const { _contact: contact } = response.data;
      fullNameRef.current.value = contact?.full_name || "";
      streeNumberRef.current.value = contact?.street || "";
      postalCodeRef.current.value = contact?.postal_code || "";
      cityRef.current.value = contact?.city || "";
    }
  }

  const handleCreatePhysicalPersonInvoiceTicket = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.full_name = fullNameRef.current.value.trim();
    payload.city = cityRef.current.value.trim();
    payload.street = streeNumberRef.current.value.trim();
    payload.postal_code = postalCodeRef.current.value.trim();
    payload.additional_info = commentRef.current?.value;

    const response = await makeRequest(
      createValidInvoiceTicket.bind(null, orderId, payload)
    );

    if (!response.data) {
      return addMessage("Couldn't create invoice ticket", "error");
    }

    setShowInvoiceTicket(() => false);
    addMessage("Succesfully created invoice ticket", "success");
    handleSearch();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getOrderWithContact) && <Loading />}
      <Title>Physical person invoice:</Title>
      <Form onSubmit={(e) => handleCreatePhysicalPersonInvoiceTicket(e)}>
        <Input
          required
          inputWidth={720}
          width={170}
          color={Colors.darkBlue}
          name="Full name"
          inputRef={fullNameRef}
        />
        <Input
          required
          inputWidth={720}
          width={170}
          color={Colors.darkBlue}
          name="Street and number"
          inputRef={streeNumberRef}
        />
        <Input
          required
          inputWidth={720}
          width={170}
          color={Colors.darkBlue}
          name="Postal code"
          inputRef={postalCodeRef}
        />
        <Input
          required
          inputWidth={720}
          width={170}
          color={Colors.darkBlue}
          name="City"
          inputRef={cityRef}
        />
        <Textarea
          placeholder="Add comment"
          rows={8}
          cols={105}
          ref={commentRef}
        />
        <ButtonWrapper>
          <CustomButtonSave text="Save" styles={{ margin: 0 }} />
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default PhysicalPerson;
