import React from "react";
import DwItem from "./components/dwItem/DwItem";
import {
  DwListWrapper,
  DwWrapper,
} from "../../../conversationGroup/ConversationGroup.styled";

const DwUsers = ({ dwSavedUsers, selectedTicket, loadData }) => {
  return (
    <DwWrapper>
      DW:{" "}
      <DwListWrapper>
        {dwSavedUsers.map((user) => (
          <DwItem
            loadData={loadData}
            selectedTicket={selectedTicket}
            user={user}
          />
        ))}
      </DwListWrapper>
    </DwWrapper>
  );
};

export default DwUsers;
