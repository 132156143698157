import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import React, { useMemo } from 'react'
import { FILTER_ACTIONS } from '../../constants/filter';

const ShowAction = ({ setAction, filters, action, dispatch }) => {
	const { options: { marketsOptions }, commonData: { markets } } = useCommonDataContext();

	const currenciesOptions = useMemo(() => {
		const currenciesSet = new Set(markets.map(({ currency }) => currency));
		const currenciesOptions = Array.from(currenciesSet).map(currency => ({ value: currency.toUpperCase(), label: currency.toUpperCase() }));
		return currenciesOptions;
	}, [markets]);

	const getActionOptions = () => {
		switch (action) {
			case FILTER_ACTIONS.SET_MARKETS:
				return marketsOptions;
			case FILTER_ACTIONS.SET_CURRENCIES:
				return currenciesOptions;
			default: return [];
		}
	}

	return (
		<PopUp setShow={setAction}>
			<div style={{ marginBottom: "120px" }}>
				<SelectInput
					selectWidth={500}
					showLabel={false}
					options={getActionOptions()}
					selected={filters[action]}
					multiple
					setSelected={(value) => dispatch({ type: action, payload: value })}
				/>
			</div>
		</PopUp>
	)
}

export default ShowAction
