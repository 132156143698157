import { errorHandler } from "../common";
import Api from "../api";

const { tokenManager, axios: api } = Api;

export const getBlackBoardList = () =>
  errorHandler(async () => {
    const data = await api.get(`/blackboard-info`);
    return { data: data.data };
  });

export const createBlackBoardInfo = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/blackboard-info`, payload);
    return { data: data.data };
  });
export const editBlackBoardInfo = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/blackboard-info`, payload);
    return { data: data.data };
  });

export const getConsultantBlackboardInfo = (payload) =>
  errorHandler(async () => {
    const data = await api.get(`/blackboard-info/consultant`, payload);
    return { data: data.data };
  });
