import { USERNAME_ROLES } from "../constants/usernameRoles";

export const getUserName = (user) => {
  if (!user) {
    return;
  }

  return USERNAME_ROLES.includes(user.role.toLowerCase())
    ? user.username
    : user.role;
};
