import React, { useState } from "react";
import DwUsers from "./components/dwUsers/DwUsers";
import DwSelect from "./components/dwSelect/DwSelect";
import { ConversationDW } from "../conversationGroup/ConversationGroup.styled";

const ConversationDwUsers = ({ selectedTicket, loadData, departments }) => {
  const [dwSavedUsers, setDwSavedUsers] = useState([]);

  return (
    <ConversationDW>
      {!!dwSavedUsers?.length && (
        <DwUsers
          dwSavedUsers={dwSavedUsers}
          selectedTicket={selectedTicket}
          loadData={loadData}
        />
      )}
      <DwSelect
        departments={departments}
        dwSavedUsers={dwSavedUsers}
        setDwSavedUsers={setDwSavedUsers}
        selectedTicket={selectedTicket}
        loadData={loadData}
      />
    </ConversationDW>
  );
};

export default ConversationDwUsers;
