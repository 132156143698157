import React from "react";
import {
  CommonInfo,
  ContentWrapepr,
  Message,
  MessageHistoryBox,
  UserWrapper,
} from "../claimEditHrView.styles";
import { formatToDateTimeFormaterTemplate } from "@/common/functions/dateFormater";

const MessageBox = ({ data, users }) => {
  return (
    <MessageHistoryBox>
      {data.claim.messages
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .map((message) => (
          <div key={message.created_at}>
            <ContentWrapepr>
              <UserWrapper>
                {users.find((user) => message.sender === user._id)?.username}
              </UserWrapper>
              <Message>{message.original}</Message>
            </ContentWrapepr>
            <CommonInfo>
              <b>{message.type}</b>{" "}
              {formatToDateTimeFormaterTemplate(message.created_at).replace(
                "T",
                " "
              )}
            </CommonInfo>
          </div>
        ))}
    </MessageHistoryBox>
  );
};

export default MessageBox;
