import styled from 'styled-components'

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: fit-content;
  gap: 30px;
`;

export const EmailText = styled.span`
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
`;
