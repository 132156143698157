import { findMarketById } from "./findMarketById";

export const getRaws = (gamificationConfigs, markets, setSelectedConfig) =>
  gamificationConfigs.map((gamificationConfig) => (
    <tr key={gamificationConfig._id}>
      <td>{findMarketById(gamificationConfig.market, markets)?.name}</td>
      <td>{gamificationConfig.calling_mode_constant}</td>
      <td>{gamificationConfig.normal_mode_constant}</td>
      <td>{gamificationConfig.listening_score_target}</td>
      <td>
        <i
          onClick={() => setSelectedConfig(() => gamificationConfig)}
          style={{ cursor: "pointer" }}
          className="fa animation-scale fa-edit"
        />
      </td>
    </tr>
  ));
