import PopUp from "@/common/components/PopUp";
import React from "react";
import { Form } from "../payoutSlip.styles";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { Colors } from "chart.js";

const AddPayoutSlip = ({ setAddPayoutSlip, fileRef, hanldeAddFile }) => {
  return (
    <PopUp setShow={setAddPayoutSlip}>
      <Form onSubmit={(e) => hanldeAddFile(e)}>
        <input
          style={{ width: "350px", marginLeft: "30px" }}
          color={Colors.darkBlue}
          ref={fileRef}
          type="file"
          accept="application/pdf,image/jpg,image/png,image/jpeg"
          required
        />
        <CustomButtonSave text={"Save"} width={80} height={30} />
      </Form>
    </PopUp>
  );
};

export default AddPayoutSlip;
