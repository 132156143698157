import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'
import React, { useEffect, useState } from 'react'
import BonusForm from './components/bonusForm/BonusForm'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper, InfoIconWrapper } from './Bonus.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import MessageQueue, { useMessageQueue } from '@/common/messageProvider';
import { getBonuses } from '@/API/repositories/bonus';
import BonusesTable from './components/bonusesTable/BonusesTable';
import moment from 'moment';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import Icon from '@/common/components/Icon';

const Bonus = () => {
  const [show, setShow] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState();
  const [bonuses, setBonuses] = useState([]);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { commonData: { users } } = useCommonDataContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const loadBonuses = async () => {
    setBonuses([]);
    const response = await makeRequest(getBonuses);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    response.data.sort((a, b) => {
      const usernameA = users.find(u => u._id === a.consultant)?.username;
      const usernameB = users.find(u => u._id === b.consultant)?.username;
      return usernameA?.localeCompare(usernameB);
    }).sort((a, b) => moment(b.valid_to).format('YYYY-MM-DDHH:mm').localeCompare(moment(a.valid_to).format('YYYY-MM-DDHH:mm')));

    setBonuses(response.data);;
  };

  useEffect(() => {
    loadBonuses();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getBonuses) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <ContentWrapper>
        <CustomButtonAdd 
          defaultText="Add bonus" 
          onClick={() => {
            setSelectedBonus(null)
            setShow(true)
          }} 
        />
        <InfoIconWrapper label={`GREEN colors means active bonus for current date, ORANGE color means active bonus, which not includes current date, RED color is for not currently active bonus`}>
          <Icon color='#fff' size={20} name="fa fa-info" />
        </InfoIconWrapper>
      </ContentWrapper>
      {show && <BonusForm setSelectedBonus={setSelectedBonus} reload={loadBonuses} selectedBonus={selectedBonus} addMessage={addMessage} setShow={setShow} />}
      {!!bonuses?.length && (
        <BonusesTable setSelectedBonus={setSelectedBonus} setShow={setShow} bonuses={bonuses} />
      )}
    </PageWrapper>
  )
}

export default Bonus
