import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const StyledText = styled.p`
  color: ${Colors.darkGray2};
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 30px);
`;

export const StyledTextImportant = styled(StyledText)`
  font-size: 14px;
  font-weight: bold;
`;

export const DocumentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const DocumentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
