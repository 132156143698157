import React from "react";
import { TableWrapper, Tbody } from "../hoursReport.styles";
import moment from "moment";
import { calcWorkingHours } from "@/common/functions/calcWorkingHours";
import { HEADERS_UOP_CZ } from "../constants/headers";
import ExportExcelAdvance from "@/common/components/ExportExcelAdvance";
import { generateConsultantData } from "../helpers/generateUopReportExelc";
import { reduceData } from "../helpers/reduceData";

const HoursReportTable = ({
  data,
  filterRegex,
  selectedConsultant,
  consultants,
  workingFromDate,
  workingToDate,
}) => {
  return (
    <TableWrapper>
      <table className="styled-table-2 tableWrap">
        <thead>
          <tr>
            <th>No.</th>
            <th>Imię i Nazwisko</th>
            <th>Etat</th>
            <th colSpan={2}>Okres rozliczeniowy</th>
            <th>Full-time /miesiąc</th>
            <th>Ilość godzin przepracowanych</th>
            <th>Urlop</th>
            <th>Urlop okolicznościowy</th>
            <th>L4 - zwolnie lekarskie</th>
            <th>Urlop na żądanie</th>
            <th>Urlop bezpłatny</th>
            <th>Nieusprawiedliwone</th>
            <th>Dodatkowe (UOP CZ)</th>
          </tr>
        </thead>
        <Tbody>
          {data
            .sort((a, b) => a._id.localeCompare(b._id))
            .filter((e) =>
              filterRegex
                ? (
                    e?._month_working_info?.contract_type ||
                    e?._user?.contract_type
                  )
                    ?.toLowerCase()
                    .includes(filterRegex.toLowerCase())
                : true
            )
            .filter((c) =>
              selectedConsultant.map((c1) => c1.value).includes(c._id)
            )
            .map((consultantData, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}.</td>
                  <td>
                    {consultants.find((c) => c.value === consultantData._id)
                      ?.label || "------"}
                  </td>
                  <td>
                    {consultantData._month_working_info?.month_hours ||
                      "------"}
                  </td>
                  <td>
                    {(consultantData._month_working_info?.working_from &&
                      moment(
                        consultantData._month_working_info?.working_from
                      ).format("YYYY-MM-DD")) ||
                      workingFromDate}
                  </td>
                  <td>
                    {(consultantData._month_working_info?.working_to &&
                      moment(
                        consultantData._month_working_info?.working_to
                      ).format("YYYY-MM-DD")) ||
                      workingToDate}
                  </td>
                  <td>{calcWorkingHours(consultantData.data)}</td>
                  <td>
                    {reduceData(consultantData.data, [
                      "attendance_at_work",
                      "manager_hours",
                    ])}
                  </td>
                  <td>{reduceData(consultantData.data, ["holiday_leave"])}</td>
                  <td>{reduceData(consultantData.data, ["special_leave"])}</td>
                  <td>{reduceData(consultantData.data, ["sick_leave"])}</td>
                  <td>
                    {reduceData(consultantData.data, ["leave_on_reqests"])}
                  </td>
                  <td>
                    {reduceData(consultantData.data, ["excused_unpaid_leave"])}
                  </td>
                  <td>
                    {reduceData(consultantData.data, [
                      "absence_without_excuse",
                    ])}
                  </td>
                  <td>
                    {consultantData?._month_working_info?.contract_type
                      ?.toLowerCase()
                      ?.includes("cz_uop") && (
                      <div>
                        <ExportExcelAdvance
                          label="pl"
                          data={{
                            headers: HEADERS_UOP_CZ(
                              consultantData._month_working_info.for_month
                            ),
                            excelData: generateConsultantData(consultantData),
                            fileName: `Dochazku_${consultantData._month_working_info.for_month}_${consultantData._user.name}_${consultantData._user.surname}`,
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </Tbody>
      </table>
    </TableWrapper>
  );
};

export default HoursReportTable;
