const { Colors } = require("@/common/colors/colors");
const { default: styled } = require("styled-components");

export const FAQType = styled.h4`
  margin: 20px;
  color: ${Colors.darkGray};
  font-style: italic;
`;
export const FAQQuestion = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
`;

export const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
`;

export const FAQWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  overflow-y: scroll;
  max-height: 95vh;
`;

export const FAQInfo = styled.div`
  border-radius: 10px;
  background-color: ${Colors.lightGray};
  margin: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${Colors.darkBlue};
`;

export const TitleWrapper = styled.h2`
  color: ${Colors.darkBlue};
`;

export const SubtitleWrapper = styled.h5`
  font-style: italic;
  max-width: 600px;
  text-align: center;
  margin: 20px 0;
  color: ${Colors.darkBlue};
`;
