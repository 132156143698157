import React, { useEffect, useState } from "react";
import {
  ConversatinDWInputWrapper,
  SuccessButton,
} from "../../../../ConversationGroup.styled";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { addDwUsers } from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";

const DwSelect = ({
  selectedTicket,
  loadData,
  setDwSavedUsers,
  dwSavedUsers,
}) => {
  const [dwUsers, setDwUsers] = useState([]);
  const [dwLoading, setDwLoading] = useState();
  const [isDwSuccess, setIsDwSuccess] = useState(true);
  const {
    getActiveUsersOptions
  } = useCommonDataContext();
  const usersOptions = getActiveUsersOptions();

  const { makeRequest } = useRequestsContext();

  const handleAddDw = async () => {
    setDwLoading(() => true);

    const response = await makeRequest(
      addDwUsers.bind(
        null,
        selectedTicket._id,
        dwUsers.map((user) => user.value)
      )
    );

    if (response.data) {
      setDwUsers(() => []);
      await loadData();
      setIsDwSuccess(() => true);
    }

    setDwLoading(() => false);
  };

  const handleChangeDwUsers = (updatedDwUsers) => {
    if (!updatedDwUsers?.length) {
      setIsDwSuccess(() => true);
    } else {
      setIsDwSuccess(() => false);
    }
    setDwUsers(() => updatedDwUsers);
  };

  const handleSetDwUsers = () => {
    setDwSavedUsers(
      () =>
        usersOptions.filter((userOption) =>
          selectedTicket?.dw?.includes(userOption.value)
        ) || []
    );

    setIsDwSuccess(() => true);
    setDwUsers(() => []);
  };

  useEffect(() => {
    handleSetDwUsers();
  }, [selectedTicket]);
  return (
    <ConversatinDWInputWrapper>
      <SelectInput
        multiple
        options={usersOptions.filter(
          (user) => !dwSavedUsers.some((dw) => dw.value === user.value)
        )}
        color="gray"
        width={20}
        selectWidth={500}
        selectFontSize={14}
        setSelected={handleChangeDwUsers}
        selected={dwUsers}
        showLabel={false}
      />
      <SuccessButton
        onClick={() => handleAddDw()}
        backgroundColor={isDwSuccess ? Colors.green : ""}
        borderColor={isDwSuccess ? Colors.green : Colors.darkGray2}
        color={isDwSuccess ? "white" : Colors.darkGray2}
        className="animation-scale"
      >
        <i
          className={dwLoading ? "fa-solid fa-spinner fa-spin" : "fa fa-check "}
        />
      </SuccessButton>
    </ConversatinDWInputWrapper>
  );
};

export default DwSelect;
