import styled from "styled-components";

export const WarningWrapper = styled.div`
  color: red;
  font-weight: bold;
  padding: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
