import Table from "@/common/components/Table";
import React, { useState } from "react";
import { HEADERS } from "./constants";
import { getRaws } from "./helpers/getRaws";
import { TableWrapper } from "./TemplateTable.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import TemplateForm from "../templateForm/TemplateForm";

const TemplateTable = ({ templates, addMessage, loadData }) => {
  const [selectedTemplate, setSelectedTemplate] = useState();

  const {
    commonData: { markets, products },
  } = useCommonDataContext();

  return (
    <TableWrapper>
      {markets?.length && (
        <Table
          className="styled-table sticky-header"
          headers={HEADERS}
          raws={getRaws(templates, markets, products, setSelectedTemplate)}
        />
      )}
      {selectedTemplate && (
        <TemplateForm
          setShow={setSelectedTemplate}
          template={selectedTemplate}
          addMessage={addMessage}
          loadData={loadData}
        />
      )}
    </TableWrapper>
  );
};

export default TemplateTable;
