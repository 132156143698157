export const KEY_TYPES = {
  SENDGRID: "sendgrid",
  FAKTUROWNIA: "fakturownia",
  META: "meta",
};

export const KEY_TYPES_OPTIONS = [
  {
    label: "Sendgrid",
    value: KEY_TYPES.SENDGRID,
  },
  {
    label: "Fakturownia",
    value: KEY_TYPES.FAKTUROWNIA,
  },
  {
    label: "Meta",
    value: KEY_TYPES.META,
  }
];
