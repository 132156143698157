import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

export const SearchBarView = styled.div`
  max-width: 80vw;
  display: flex;
`;

export const ButtonWrapper = styled.div`
  float: right;
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const TableWrapper = styled.div`
  max-height: 55vh;
  max-width: 1000px;
  overflow-x: auto;
  overflow-y: auto;
`;
