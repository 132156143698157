import { ActionIcon } from "../../ConversationGroup.styled";

const EmailClipboard = ({ email, onClick }) => {
  return (
    <div>
      <span style={{ fontSize: "12px" }}>{email}</span>
      <ActionIcon
        style={{ margin: "0 8px", fontSize: "15px" }}
        className="fa fa-copy"
        color="grey"
        onClick={onClick}
      />
    </div>
  );
};

export default EmailClipboard;
