export const getRaws = (accountsSpend) =>
  accountsSpend
    .sort((accA, accB) => accA.productName.localeCompare(accB.productName))
    .map((accountSpend) => (
      <tr key={accountSpend.productName}>
        <td>{accountSpend.productName}</td>
        <td>
          {accountSpend.totalSpend} {accountSpend.currency}
        </td>
        <td>{accountSpend.totalLeads}</td>
        <td>
          {(accountSpend.totalSpend / accountSpend.totalLeads).toFixed(2)}{" "}
          {accountSpend.currency}
        </td>
      </tr>
    ));
