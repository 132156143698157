import { formatToDateTamplate } from "@/common/functions/dateFormater";
import moment from "moment";
import * as MomentRange from "moment-range";
const momentRange = MomentRange.extendMoment(moment);

export const getWorkingDaysForMonth = (market, month = new Date()) => {
  const startDate = moment(month).startOf("month");
  const endDate = moment(month).endOf("month");

  const daysRange = Array.from(momentRange.range(startDate, endDate).by("day"));

  const freeMarketDates = market?.free_days?.map((freeDay) =>
    formatToDateTamplate(freeDay.date)
  );

  const workingDays = daysRange.reduce((acc, momentDate) => {
    const date = new Date(momentDate.toDate());

    if (
      date.getDay() === 6 ||
      date.getDay() === 0 ||
      freeMarketDates?.includes(formatToDateTamplate(date))
    ) {
      return acc;
    }

    acc++;

    return acc;
  }, 0);

  return workingDays;
};
