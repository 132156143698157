import { useRef } from "react";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import CustomButtonError from "@/common/components/buttons/CustomButtonError";

const DeleteReportPopUp = ({ onClose, onDelete }) => {
  const emailRef = useRef();

  return (
    <PopUp setShow={onClose}>
      <form>
        <p>Are you sure you want to delete this mailing report?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 10px -20px 0",
          }}
        >
          <CustomButtonError
            onClick={onDelete}
            color={Colors.orange}
            defaultText={`Delete`}
          />
        </div>
      </form>
    </PopUp>
  );
};

export default DeleteReportPopUp;
