import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getDrawnContact } from "../../API/repositories/queue";
import translationManager from "../../API/translationManager";
import { Colors } from "../../common/colors/colors";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import Buttons from "../../components/consultant/contact/buttons/Buttons";
import Comments from "../../components/consultant/contact/comments/Comments";
import ConsultantScript from "../../components/consultant/contact/consultantScript/ConsultantScript";
import EndContact from "../../components/consultant/contact/endContact/EndContact";
import MoreInfo from "../../components/consultant/contact/moreInfo/MoreInfo";
import Order from "../../components/consultant/contact/order/Order";
import Orders from "../../components/consultant/contact/orders/Orders";
import PrimaryInfo from "../../components/consultant/contact/primaryInfo/PrimaryInfo";
import TicketsForCalling from "../../components/consultant/contact/tickets/TicketsForCalling";
import { Timer } from "../../components/consultant/contact/timer/Timer";
import Ping from "@/common/components/Ping";
import SupportMail from "@/common/components/supportMail/SupportMail";

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: 36fr 64fr;
  width: 100%;
  gap: 30px;
  padding: 30px;
`;

const ContactWrapper = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
`;

const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: scroll;
`;

const OUTBOUND_NOT_ALLOWED_STATUSES = [
  "trash",
  "resigned",
  "shift",
  "no_response",
];

const ConsultantContactOutbound = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [time, setTime] = useState(null);
  const [contact, setContact] = useState(null);
  const [comments, setComments] = useState([]);
  const [market, setMarket] = useState(null);

  const [shouldOffContact, setShouldOffContact] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();

  const [chosenProductData, setChosenProductData] = useState([]);

  const handleGetDawnContact = async () => {
    const response = await makeRequest(getDrawnContact.bind(null, id));
    if (response.data) {
      if (
        !response.data._call_queue_stack &&
        OUTBOUND_NOT_ALLOWED_STATUSES.includes(response.data.status)
      )
        navigate("/consultant/dashboard");
      else {
        const now = moment();
        const to = moment(response.data._call_history.created_at);
        const minutes =
          parseInt(response.data._queue?.max_keep_time) -
          now.diff(to, "seconds") / 60;
        setTime(() => (minutes > 0 ? minutes : 0));
        setMarket(() => response.data._market);
        setContact(() => response.data._contact);
        setChosenProductData(() =>
          response.data.order_items
            ? response.data.order_items.map((data, index) => {
                return {
                  _id: index,
                  name: response.data._product.variants.find(
                    (variant) => variant.short === data.product_variant
                  ).name,
                  value: data.product_variant,
                  price: data.price,
                };
              })
            : []
        );

        setData(() => response.data);

        setComments(() => response.data._comments);
      }
    } else {
      addMessage(response.error, "error");
    }
  };

  useLayoutEffect(() => {
    handleGetDawnContact();
  }, []);

  const [translation, setTranslation] = useState({
    reamainingTime: "Remaining time",
    minutes: "minutes",
    seconds: "seconds",
    fullName: "Full name",
    dequeuedCount: "Dequeued count",
    commentInfo: "type your comment",
    add: "Add",
    shift: "Shift",
    noResponse: "No response",
    resign: "Resign",
    confirm: "Confirm",
    contactOrders: "Contact Orders",
    no: "No.",
    status: "Status",
    currency: "Currency",
    product: "Product",
    market: "Market",
    createdAt: "Created at",
    sendMail: "Send Mail",
    save: "Save",
    street: "Street",
    postalCode: "Postal code",
    city: "City",
    additionalInfo: "Company name (if necessary)",
    country: "Country",
    email: "E-mail",
    street: "Street",
    city: "City",
    country: "Country",
    countryCode: "Country code",
    deliveryDate: "Delivery date",
    comment: "Comment",
    commentInfo: "Type comment...",
    basicInfo: "Basic info",
    product: "Product",
    confirm: "Confirm",
    items: "Items",
    send: "Send",
    phone: "phone",
    shippingInfo: "Shipping information",
    goToDashBoard: "Go to DashBoard",
    variant: "Variants",
    price: "Price",
    name: "Name",
    short: "Short",
    trash: "Trash",
    mailError: "There was an error while sending",
    mailSuccess: "Successfully sent an email",
    ticket: "Create ticket",
    createInvoice: "Create invoice",
    checkProm: "Check promotion",
    promNotFound: "A province with a postal code was not found",
    promNotAvaiable: "Error! Province:",
    promNotAvaiableTitle:
      "We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)",
    promAvaialbleTitle: "Postal code correct!  Province:",
    promAvaiable: "The customer can take advantage of the promotional offer",
    phone_number_for_courier: "Phone number for courier",
    courier: "Courier",
    timeline: "Timeline",
    invoice_status: "Invoice status by color",
    mail_history: "Mail history",
    shipping_history: "Shipping history",
    suspicion: "Suspicion",
    suspect: "Suspect",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <div
      style={{
        backgroundColor: Colors.lightlightBlue,
        width: "100vw",
        paddingTop: "30px",
      }}
    >
      <Ping display={false} />
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {!hasUnfilledRequest(getDrawnContact) && data != null ? (
        <Wrapper>
          <Timer
            setShouldOffContact={setShouldOffContact}
            time={time}
            reamainingTime={translation.reamainingTime}
            minutes={translation.minutes}
            tSeconds={translation.seconds}
          />
          <Flex>
            <ContactWrapper>
              <PrimaryInfo
                translation={translation}
                addMessage={addMessage}
                market={market}
                confirm={!confirmation}
                contact={contact}
                shouldDisableSuspicion={data.shouldDisableSuspicion}
                setContact={setContact}
                handleGetDawnContact={handleGetDawnContact}
                dequeuedCount={
                  data._call_queue_stack
                    ? data._call_queue_stack.dequeued_count
                    : data.contact_attempts_queue_count
                }
              />
              <Buttons
                comments={comments}
                product={data._product}
                contact={contact}
                chosenProductData={chosenProductData}
                data={data}
                handleGetDawnContact={handleGetDawnContact}
                translation={translation}
              />
              <Comments
                handleGetDawnContact={handleGetDawnContact}
                translation={{
                  ...translation,
                }}
                comments={comments}
                contactId={data._contact._id}
                setComments={setComments}
              />
            </ContactWrapper>
            <OrderWrapper>
              <Order
                chosenProductData={chosenProductData}
                setChosenProductData={setChosenProductData}
                addMessage={addMessage}
                product={data._product}
                contact={contact}
                order={data}
              />
              <ConsultantScript phoneScript={data._product.product_script} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <div>
                  <Orders
                    orders={data._orders || []}
                    translation={{
                      contactOrders: translation.contactOrders,
                      no: translation.no,
                      status: translation.status,
                      currency: translation.currency,
                      product: translation.product,
                      market: translation.market,
                      createdAt: translation.createdAt,
                      timeline: translation.timeline,
                      invoice_status: translation.invoice_status,
                      mail_history: translation.mail_history,
                      shipping_history: translation.shipping_history,
                      suspicion: translation.suspicion,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <MoreInfo
                    addMessage={addMessage}
                    product={data._product.short}
                    email={contact.email}
                    sendMail={translation.sendMail}
                    order={data}
                    mailError={translation.mailError}
                    mailSuccess={translation.mailSuccess}
                  />
                  <SupportMail order={data} />
                  <TicketsForCalling
                    market={market}
                    orderId={data._id.toString()}
                  />
                </div>
              </div>
            </OrderWrapper>
          </Flex>
        </Wrapper>
      ) : (
        <Loading />
      )}
      {shouldOffContact && <EndContact />}
    </div>
  );
};

export default ConsultantContactOutbound;
