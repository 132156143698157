import React from "react";
import PopUp from "./PopUp";

const ErrorLang = ({ setShow, fromLang, toLang, sendFunc }) => {
  return (
    <PopUp canOffByClickingBackground={false} setShow={setShow}>
      <div style={{ color: "red", fontWeight: "bold", padding: "20px" }}>
        Error! Your messsage lang is {fromLang}, but your system language is{" "}
        {toLang}. Are you sure that you want to send this message? If your
        language is {fromLang}, please click close icon and change it in
        settings! If it's error please click send!
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <button className="btn btn-danger" onClick={(e) => sendFunc(e)}>
          Send
        </button>
      </div>
    </PopUp>
  );
};

export default ErrorLang;
