import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div`
  align-self: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

export const RoleWrapper = styled.div`
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export const OnlineWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-self: center;
`;

export const VersionWrapper = styled.div`
  color: ${(props) => props.color};
`;

export const TableTitle = styled.h3`
  width: 100%;
  color: ${Colors.darkBlue};
  margin: 20px 4px 10px;
`;

export const ShowArrow = styled.i`
  transform: ${(props) => (props.show ? `rotate(0)` : `rotate(180deg)`)};
  transition: transform 0.3s;
`;

export const UsersTableWrapper = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0.1)};
  overflow: hidden;
  height: ${(props) => (props.show ? "fit-content" : "100px")};
  transition: all 0.3s;
`;
