import Api from "../api";

import { errorHandler } from "../common";

const { axios: api } = Api;

export const createTodo = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/todo/create`, payload);

    return { data: data.data };
  });

export const updateTodo = (payload, todoId) =>
  errorHandler(async () => {
    const data = await api.patch(`/todo/update/${todoId}`, payload);

    return { data: data.data };
  });

export const updateTodoStatus = (todoId, status) =>
  errorHandler(async () => {
    const data = await api.patch(`/todo/update-status/${todoId}`, { status });

    return { data: data.data };
  });

export const getAllTodos = () =>
  errorHandler(async () => {
    const data = await api.get("todo/get-admin");

    return { data: data.data };
  });

export const getConsultantManagerTodos = () =>
  errorHandler(async () => {
    const data = await api.get("todo/get-consultant-manager");

    return { data: data.data };
  });

export const markSeenByUser = () =>
  errorHandler(async () => {
    const data = await api.patch(`todo/mark-seen-by-user`);

    return { data: data.data };
  });

export const addTodoComment = (todoId, comment) =>
  errorHandler(async () => {
    const data = await api.post(`todo/add-comment/${todoId}`, { comment });

    return { data: data.data };
  });

export const markTodoMessagesSeen = (todoId) =>
  errorHandler(async () => {
    const data = await api.post(`todo/mark-messages-seen/${todoId}`);

    return { data: data.data };
  });
