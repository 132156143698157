import React, { useEffect, useRef, useState } from "react";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import { getDonutMarketStats } from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import CustomDoughnut from "@/common/charts/Doughnut";

const DonuteMarketStats = () => {
  const [chartData, setChartData] = useState();
  const [selectedMarket, setSelectedMarket] = useState();

  const {
    options: { queuesOptions },
  } = useCommonDataContext();
  const timeRangeRef = useRef();

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);
    const payload = {};
    payload.consultants = selectedMarket.value.consultants;
    payload.timeFrom = timeRangeRef.current.value;

    const response = await makeRequest(
      getDonutMarketStats.bind(null, payload)
    );

    if (response?.data) {
      setChartData(() => response.data);
    }
  };

  useEffect(() => {
    if (queuesOptions[0] && selectedMarket) handleLoadData();
  }, [queuesOptions, selectedMarket]);

  useEffect(() => {
    if (queuesOptions[0]) setSelectedMarket(() => queuesOptions[0]);
  }, [queuesOptions]);

  return (
    <Wrapper width={800}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa-brands fa-usps" />
          Daily market doughtnut ({chartData?.downloaded})
        </TopContentWrapper>
        <TopContentWrapper>
          <Input
            showLabel={false}
            inputWidth={50}
            value={0}
            inputRef={timeRangeRef}
          />
          <SelectInput
            options={queuesOptions}
            selected={selectedMarket}
            setSelected={setSelectedMarket}
            selectWidth={200}
            showLabel={false}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper style={{ justifyContent: "center", height: "420px" }}>
        {chartData ? (
          <CustomDoughnut data={chartData} />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default DonuteMarketStats;
