import styled from "styled-components";

export const PaginationElementsWrapper = styled.div`
	display: flex;
	margin: 0 auto;
	gap: 32px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

export const Flex = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`;

export const Info = styled.p`
	font-size: 14px;
`;

export const ElementsContainer = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`;
