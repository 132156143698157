import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const InvoiceConfigTable = ({
  serverConfig,
  setServerConfig,
  handleTrigger,
}) => {
  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(serverConfig, setServerConfig, handleTrigger)}
    />
  );
};

export default InvoiceConfigTable;
