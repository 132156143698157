import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./Companies.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import CompanyForm from "./components/companyForm/CompanyForm";
import CompanyTable from "./components/companyTable/CompanyTable";
import { getCompanies } from "@/API/repositories/company";
import { getKeysByType } from "@/API/repositories/key";
import { KEY_TYPES } from "@/common/constants/keyTypes";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";

const Companies = () => {
  const [companies, setCompanies] = useState();
  const [keysOptions, setKeysOptions] = useState();
  const [isCompanyFormOpen, setIsCompanyFormOpen] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const { messages, removeMessage, addMessage } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setCompanies(() => response.data);
  };

  const loadKeys = async () => {
    const response = await makeRequest(
      getKeysByType.bind(null, KEY_TYPES.FAKTUROWNIA)
    );

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setKeysOptions(() => handleMapToOptions(response.data, "label", "_id"));
  };

  const handleSetCopmanyFormInEditMode = (key) => {
    setSelectedCompany(() => key);
    setIsCompanyFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsCompanyFormOpen(() => false);
    setSelectedCompany(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadKeys();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getCompanies, getKeysByType) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <ContentWrapper>
        <CustomButtonAdd
          style={{ height: "fit-content" }}
          defaultText="Add company"
          onClick={() => setIsCompanyFormOpen(true)}
        />
      </ContentWrapper>
      {isCompanyFormOpen && (
        <CompanyForm
          addMessage={addMessage}
          setShow={handleCloseForm}
          loadData={loadData}
          selectedCompany={selectedCompany}
          keysOptions={keysOptions}
        />
      )}
      {!!companies?.length && (
        <CompanyTable
          setCompany={handleSetCopmanyFormInEditMode}
          companies={companies}
        />
      )}
    </PageWrapper>
  );
};

export default Companies;
