export const getRaws = (data) =>
  [...data.map((el) => (
    <tr key={`${el[0]}_${el[1]}_${el[2]}_${el?.[3]}`}>
      <td>{el[0]}</td>
      <td>{el[1]}</td>
      <td>{el[2]}</td>
      {el[3] && <td>{el[3]}</td>}
    </tr>
  )),
  <tr>
    {data[0]?.length && (
      <td colSpan={data[0]?.length}>
        <b>
        Languages sum: {data.reduce((acc, el) => acc + el[2], 0)}
        </b>
      </td>
    )}
  </tr>];
