export const HEADERS = [
  "Time period",
  "Start date",
  "End date",
  "Product",
  "Leads",
  "CC Leads",
  "Paid orders",
  "Paid orders value CZK",
  "Paid orders value PLN",
  "Total spend (Netto)",
  "ROAS",
];
