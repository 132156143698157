import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import CreateButton from "../../../../common/components/CreateButton";
import ListTable from "../../../../common/components/ListTable";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import ProductNew from "./ProductNew";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const ProductList = () => {
  const [row, setRow] = useState([]);
  const column = ["no", "name", "short"];

  const [showNew, setShowNew] = useState(false);
  const { commonData } = useCommonDataContext();
  const { products } = commonData;

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { hasUnfilledRequest } = useRequestsContext();

  const handleGetProducts = async () => {
    setRow(() =>
      products.map((element) => {
        let rowToDisplay = [];

        rowToDisplay.push(element.name);
        rowToDisplay.push(element.short);

        return {
          data: rowToDisplay,
          _id: element._id,
        };
      })
    );
  };

  useLayoutEffect(() => {
    handleGetProducts();
  }, [showNew, products]);

  return (
    <>
      {hasUnfilledRequest(handleGetProducts) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      {row && (
        <Wrapper>
          <CreateButton onClick={() => setShowNew(true)} name={"product"} />
          <ListTable column={column} row={row} entityName={"product"} />
        </Wrapper>
      )}
      {showNew && (
        <PopUp setShow={setShowNew}>
          <ProductNew close={() => setShowNew(false)} addMessage={addMessage} />
        </PopUp>
      )}
    </>
  );
};

export default ProductList;
