export const HEADERS = [
  "No.",
  "Order sent at",
  "Full Name",
  "Market",
  "Product",
  "Waybill",
  "Actions",
];

export const HEADERS_CSV = [
  "Order sent at",
  "Full Name",
  "Market",
  "Product",
  "Waybill",
];
