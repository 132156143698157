import userManager from "@/API/userManager";
import { Colors } from "@/common/colors/colors";
import moment from "moment";

export const getRaws = (dataToDisplay, navigate, setMessages) =>
  dataToDisplay
    .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    .sort((a, b) => a.type.localeCompare(b.type))
    .map((ticket, index) => (
      <tr>
        <td>{index + 1}.</td>
        <td>{ticket.status}</td>
        <td>{ticket.type}</td>
        <td>{ticket.question?.title || "----"}</td>
        <td>{moment(ticket.updated_at).format("YYYY-MM-DD HH:mm")}</td>
        <td>
          {ticket.type === "correction" &&
          ticket.last_status_by !== userManager.getUser().id
            ? "Admin"
            : ticket._last_status_by?.username}
        </td>
        <td>
          <div
            style={{
              width: "150px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {
              ticket[ticket.type].message?.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0].original
            }
          </div>
        </td>
        <td>{ticket._contact?.email || "----"}</td>
        <td>
          <div style={{ display: "flex", gap: "10px" }}>
            <i
              className="fa fa-comments animation-scale"
              style={{
                color: Colors.darkBlue,
                cursor: "pointer",
              }}
              onClick={() => setMessages(ticket)}
            />
            {ticket.contact && (
              <i
                className="fa fa-address-book animation-scale"
                style={{ color: "grey", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard/contact/${ticket.contact}`);
                }}
              />
            )}
          </div>
        </td>
      </tr>
    ));
