import { Delta } from "quill/core";

export const getQuillDelta = (message, userId, showTranslation) => {
  let res = null;
  if (message.sender === userId)
    res = (showTranslation && message.quill_delta_translation) || message.quill_delta;

  res = (!showTranslation && message.quill_delta_translation) || message.quill_delta;

  return new Delta(JSON.parse(res));
}