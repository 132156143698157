import { Colors } from "@/common/colors/colors";
import React from "react";
import { FlexRight, MarginBottomContainter } from "../claimEditHrView.styles";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import TextArea from "@/common/components/TextArea";

const AddMessageComponent = ({
  setIsInputOpen,
  handleStatusChange,
  messageType,
  inputRef,
}) => {
  return (
    <PopUp setShow={setIsInputOpen}>
      <MarginBottomContainter>
        <h3 style={{ color: Colors.darkBlue }}>
          Add what {messageType.split("_").join(" ")}
        </h3>
      </MarginBottomContainter>
      <MarginBottomContainter>
        <TextArea
          textAreaRef={inputRef}
          width="400"
          placeholder={`type to mark as ${messageType}...`}
        />
      </MarginBottomContainter>
      <FlexRight>
        <button
          className="btn btn-success"
          type="button"
          onClick={handleStatusChange}
          style={{ marginBottom: "-25px" }}
        >
          Save
        </button>
      </FlexRight>
    </PopUp>
  );
};

export default AddMessageComponent;
