import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React, { useEffect, useRef, useState } from "react";
import { VALID_INVOICES_OPTIONS } from "../../constants/validInvoices";
import { Colors } from "@/common/colors/colors";
import { Wrapper } from "./ValidInvoicesSearchBar.styled";
import { SearchBar } from "../../ValidInvoices.styled";
import Card from "@/common/components/Card";
import { sortTickets } from "../../helpers/sortTickets";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { getCompanies } from "@/API/repositories/company";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { findDefaultMarketFromOptions } from "@/common/functions/findDefaultMarketFromOptions";
import { filterEntitiesByIds } from "@/common/functions/filterEntitiesByIds";
import { findPossibleProductFieldByMarket } from "@/common/functions/findPossibleProductFieldByMarket";
import Loading from "@/common/components/Loading";
import ToggleSwitch from "@/common/components/ToggleSwitch";

const ValidInvoicesSearchBar = ({
  selectedStatus,
  setSelectedStatus,
  selectedMarket,
  setSelectedMarket,
  handleSearch,
  setVisibleTickets,
  validInvoicesTickets,
  addMessage,
  handleSearchCorrectionsByStatus,
  setSelectedCompany,
  selectedCompany
}) => {
  const [companies, setCompanies] = useState();
  const [companiesOptions, setCompaniesOptions] = useState();
  const [showAllMarkets, setShowAllMarkets] = useState(false);

  const salesRef = useRef();
  const correctionRef = useRef();
  const allMarketsRef = useRef();

  const {
    commonData: { products },
    options: { marketsOptions, queuesOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setCompanies(() => response.data);

    setSelectedStatus(VALID_INVOICES_OPTIONS[0]);
  };

  const hansleSetVisibleTickets = () => {
    if (correctionRef.current?.checked && !salesRef.current?.checked) {
      setShowAllMarkets(true);
    } else {
      if (allMarketsRef.current?.checked) {
        allMarketsRef.current.checked = false;
      }
      setShowAllMarkets(false);
    }

    const corrections =
      validInvoicesTickets?.filter(
        (ticket) => ticket.valid_invoice.invoice_type === "correction"
      ) || [];
    const sales =
      validInvoicesTickets?.filter(
        (ticket) => ticket.valid_invoice.invoice_type === "sales"
      ) || [];

    setVisibleTickets(() => {
      const visible = [];

      if (salesRef.current?.checked) {
        visible.push(...sales);
      }

      if (correctionRef.current?.checked) {
        visible.push(...corrections);
      }

      return sortTickets(visible);
    });
  };

  const handleSetMarket = (market) => {
    const currentMarket =
      market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

    setSelectedMarket(currentMarket);
    handleMarketChange(currentMarket);
  };

  const handleMarketChange = (market) => {
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);

    if (market && companies) {
      const possibleCompanies = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products,
        "company"
      );

      const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

      const filteredCompanies = filterEntitiesByIds(
        companies,
        uniqueCompaniesIds
      );

      const newCompaniesOptions = handleMapToOptions(
        filteredCompanies,
        "name",
        "_id"
      );

      setCompaniesOptions(newCompaniesOptions);
      const company = newCompaniesOptions[0];

      setSelectedCompany(company);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    handleSetMarket();
  }, [queuesOptions, marketsOptions, products, companies]);

  useEffect(() => {
    hansleSetVisibleTickets();
  }, [validInvoicesTickets]);

  return (
    <Card>
      {!marketsOptions?.length && <Loading />}
      <SearchBar>
        <Wrapper onSubmit={(e) => allMarketsRef.current?.checked ? handleSearchCorrectionsByStatus(e) : handleSearch(e)}>
          <div>
            <SelectInput
              color={Colors.darkBlue}
              options={marketsOptions}
              selected={selectedMarket}
              setSelected={handleSetMarket}
              name="Market"
              width={80}
              required
              hideStar
              disabled={allMarketsRef.current?.checked}
            />
          </div>
          <div>
            <SelectInput
              width={80}
              name="Company"
              options={companiesOptions}
              setSelected={setSelectedCompany}
              selected={selectedCompany}
              color={Colors.darkBlue}
              disabled={!companiesOptions || allMarketsRef.current?.checked}
              required
              hideStar
            />
            <SelectInput
              color={Colors.darkBlue}
              selected={selectedStatus}
              setSelected={setSelectedStatus}
              options={VALID_INVOICES_OPTIONS}
              name="Status"
              width={80}
              required
              hideStar
            />
          </div>
          <CustomButtonSave
            text="Search"
            color={Colors.orange}
            styles={{ height: "fit-content", margin: 0 }}
          />
        </Wrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {showAllMarkets && (
            <ToggleSwitch
              onChange={() => hansleSetVisibleTickets()}
              toggleRef={allMarketsRef}
              text="All markets"
              width={110}
              gap="12px"
            />
          )}
          <ToggleSwitch
            onChange={() => hansleSetVisibleTickets()}
            toggleRef={salesRef}
            text="Sales"
            width={110}
            gap="12px"
            checked
            disabled={allMarketsRef.current?.checked}
          />
          <ToggleSwitch
            onChange={() => hansleSetVisibleTickets()}
            toggleRef={correctionRef}
            text="Correction"
            width={140}
            gap="12px"
            checked
          />
        </div>
      </SearchBar>
    </Card>
  );
};

export default ValidInvoicesSearchBar;
