import React from "react";
import userManager from "@/API/userManager";
import {
  TicketsListWrapper,
  MessageItem,
  MessageSender,
  MessageText,
  OnlineRound,
  MessageStatus,
  MessageInfoWrapper,
  PriorityWrapper,
} from "./TicketsList.styled";
import { handleOnline } from "../../helpers/handleOnline";
import { getTicketBackgroundColor } from "./helpers/getTicketBackgroundColor";
import { getUserName } from "../../helpers/getUsername";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getTicketMessage } from "../../helpers/getTicketMessage";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { Colors } from "@/common/colors/colors";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import Icon from "@/common/components/Icon";

const TicketsList = ({
  tickets,
  selectedTicket,
  handleSelectTicket,
  newTicketCreating,
}) => {
  const {
    commonData: { users },
  } = useCommonDataContext();
  const currentUserId = userManager.getUser().id;

  return (
    <TicketsListWrapper gridRawEnd={4}>
      {newTicketCreating && (
        <MessageItem backgroundColor={Colors.darkGray}>
          <CustomSkeleton count={2} height="10px" width="100%" />
        </MessageItem>
      )}
      {tickets?.map((ticket) => (
        <MessageItem
          onClick={() => handleSelectTicket(ticket)}
          backgroundColor={getTicketBackgroundColor(
            ticket,
            selectedTicket,
            currentUserId
          )}
          key={ticket._id}
          color={"black"}
        >
          <MessageInfoWrapper>
            {ticket.prioritized && (
              <PriorityWrapper>
                <Icon color="#fff" size={10} name="fa fa-exclamation" />
              </PriorityWrapper>
            )}
            <MessageSender>
              <span>{getUserName(ticket._last_status_by) || "System"}</span>
              <OnlineRound
                backgroundColor={handleOnline(
                  ticket._last_status_by?.last_ping
                )}
              />
            </MessageSender>
            <MessageText>{getTicketMessage(ticket, users)}</MessageText>
          </MessageInfoWrapper>
          <MessageStatus
            bgColor={
              QUESTION_TICKET_STATUS_OPTIONS.find(
                (o) => o.value === ticket.status
              )?.color
            }
          >
            {
              QUESTION_TICKET_STATUS_OPTIONS.find(
                (o) => o.value === ticket.status
              )?.label
            }
          </MessageStatus>
        </MessageItem>
      ))}
    </TicketsListWrapper>
  );
};

export default TicketsList;
