import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const PropertiesWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 700px;
`;

export const Property = styled.div`
  background-color: ${Colors.green};
  padding: 8px;
  color: white;
  border-radius: 10px;
  border: 1px solid ${Colors.green};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: ${Colors.green};
  }
`;
