export const groupFaqs = (faqs) => {
  const map = new Map();

  faqs.forEach((faq) => {
    if (map.has(faq.type)) {
      return map.get(faq.type).push(faq);
    }

    map.set(faq.type, [faq]);
  });

  return Array.from(map, ([name, value]) => ({ name, value }));
};
