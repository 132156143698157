import { compareDates } from "@/common/functions/compareDates";

export const calcBreak = (elements, status, queue) => {
  elements = elements.sort((a, b) =>
    compareDates(new Date(a.created_at), new Date(b.created_at))
  );

  let seconds = 0;
  let foundBreak;

  elements.forEach((act) => {
    if (foundBreak && act.status !== status) {
      seconds += compareDates(new Date(act.created_at), new Date(foundBreak));
      foundBreak = null;
    }

    if (act.status === status && !foundBreak && (queue === act.queue || queue === 'any')) {
      foundBreak = act.created_at;
    }
  });

  return Math.floor(seconds / 1000 / 60);
};
