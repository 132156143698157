import { getValidClaims } from '@/API/repositories/validClaim';
import Loading from '@/common/components/Loading';
import { useRequestsContext } from '@/common/hooks/requestHook'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { Colors } from '@/common/colors/colors';
import ValidClaimForm from '@/common/components/validClaimForm/ValidClaimForm';
import { getClaimTags } from '@/API/repositories/claim-tag';
import { sortValidClaims } from './helpers/sortValidClaims';
import ClaimTable from './components/claimTable/ClaimTable';
import ClaimSearchBar from './components/claimSearchBar/ClaimSearchBar';
import AddFile from './components/addFile/AddFile';
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { CLAIM_ROLE_STATUSES } from '@/common/constants/claims';
import userManager from '@/API/userManager';

const ValidClaims = () => {
  const { options: { marketsOptions }} = useCommonDataContext();

  const [validClaims, setValidClaims] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState(marketsOptions);
  const [selectedStatus, setSelectedStatus] = useState(CLAIM_ROLE_STATUSES[userManager.getUser().role].TO_DO);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [addFile, setAddFile] = useState(null);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useNewMessageQueueContext();

  const loadTags = async () => {
    const response = await makeRequest(getClaimTags);

    if (!response?.data) {
      return addMessage("Error while loading tags", "error");
    }

    const mappedOptions = response.data
      .map(tag => ({ value: tag._id, label: <span style={{ color: Colors.darkGray2}}>{tag.label}</span>}));

    setTagsOptions(() => mappedOptions);
    return mappedOptions;
  };

  const handleSearch = async () => {
    if (!selectedMarkets?.length || !selectedStatus?.value) {
      return addMessage("Please select markets and statuses", "error");
    }
    
    const payload = {
      markets: selectedMarkets.map(market => market.value),
      statuses: Object.values(selectedStatus.claim_statuses).map(status => status.value),
    }
    const response = await makeRequest(getValidClaims.bind(null, payload));

    if (!response?.data) {
      return addMessage("Error while loading valid claims", "error");
    }

    setValidClaims(() => sortValidClaims(response.data));
  }

  const handleSelectStatus = (value) => {
    setValidClaims(() => []);
    setSelectedStatus(() => value);
  }

  useEffect(() => {
    loadTags();
    handleSearch();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getValidClaims) && <Loading />}
      <ClaimSearchBar
        selectedMarkets={selectedMarkets}
        setSelectedMarkets={setSelectedMarkets}
        selectedStatus={selectedStatus}
        setSelectedStatus={handleSelectStatus}
        handleSearch={handleSearch}
      />
      {!!validClaims.length && (
        <ClaimTable
          selectedStatus={selectedStatus}
          setAddFile={setAddFile}
          validClaims={validClaims}
          setSelectedClaim={setSelectedClaim}
        />
      )}
      {selectedClaim && (
        <ValidClaimForm 
          savedTags={tagsOptions}
          savedContact={selectedClaim._contact}
          reload={handleSearch}
          claim={selectedClaim}
          setShow={setSelectedClaim}
          order={selectedClaim._order}
        />
      )}
      {addFile && (
        <AddFile
          claimId={addFile}
          addMessageToParent={addMessage}
          reload={handleSearch}
          setAddFile={setAddFile} 
        />
      )}
    </PageWrapper>
  )
}

export default ValidClaims
