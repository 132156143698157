import { formatToDateTamplate } from "@/common/functions/dateFormater";

export const getRefundDate = (ticket) => {
	const isRefund = ticket._valid_claim
		|| ticket._order.claim
		|| ticket._order.payment?.some(p => p.status_refunded_date);

	if (!isRefund) {
		return "----";
	}

	return formatToDateTamplate(
		ticket._valid_claim?.created_at
		|| ticket._order.claim?.created_at
		|| ticket._order.payment.find(p => p.status_refunded_date).status_refunded_date
	);
}