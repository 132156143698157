import { getPercent } from "@/common/functions/getPercent";
import { findUserById } from "./findUserById";
import { getStars } from "@/common/functions/getStars";
import { sortMarketStats } from "@/components/consultant/dashboard/panel/gamification/components/market/helpers/sortMarketStats";

export const getRaws = ({ gamificationStats, users, markets, queues }) => {
  return sortMarketStats({
    marketGamification: gamificationStats,
    users,
    markets,
    queues,
  }).map((gamificationUserStats, i) => (
    <tr key={gamificationUserStats.consultant}>
      <td>{i + 1}.</td>
      <td>{findUserById(gamificationUserStats.consultant, users)?.username}</td>
      <td>{gamificationUserStats.total_amount}</td>
      <td>{gamificationUserStats.delivered_amount}</td>
      <td>
        {getPercent(
          gamificationUserStats.delivered_amount,
          gamificationUserStats.total_amount
        ).toFixed(2) || 0}
        %
      </td>
      <td>
        {getStars({
          user: findUserById(gamificationUserStats.consultant, users),
          gamificationUserStats,
          markets,
          queues,
        })}
      </td>
      <td>{gamificationUserStats.average_listening_score || "----"}</td>
    </tr>
  ));
};
