import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ErrorMessage = styled.div`
  position: absolute;
  color: ${Colors.red};
  background-color: white;
  font-weight: bold;
  font-size: 24px;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  padding: 12px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
