import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import React, { useMemo, useState } from "react";
import { Title } from "../calendar.styles";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";
import { Colors } from "@/common/colors/colors";
import { createDateForBackend } from "@/common/functions/createDateForBackend";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import Icon from "@/common/components/Icon";
import SelectInput from "@/common/components/SelectInput";
import { HOURS_OPTIONS } from "../consts/hoursOptionsConst";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getValidBonuses } from "@/common/functions/getValidBonuses";
import { checkIsManager } from "@/common/functions/checkIsManager";
import userManager from "@/API/userManager";
import { WORKING_OPTIONS } from "@/common/constants/workingOptions";

const NewAddWorkedHours = ({
  bonuses,
  handleNewSaveWorkedHours,
  setAddWorkingHoursNew,
  translation,
  addWorkingHoursNew
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [selectedType, setSelectedType] = useState();
  const [addType, setAddType] = useState();
  const [types, setTypes] = useState([]);

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { options: { productsOptions }, commonData: { users } } = useCommonDataContext();

  const userId = userManager.getUser().id;

  const availableBonuses = useMemo(() => {
    return getValidBonuses(addWorkingHoursNew, bonuses, addMessage);
  }, [bonuses, addWorkingHoursNew]);

  const handleAddType = (e) => {
    e && e.preventDefault();

    const payload = {
      id: types.length + 1,
      from: selectedFrom.label,
      to: selectedTo.label,
      fromValue: selectedFrom.value,
      toValue: selectedTo.value,
      type: selectedType.value,
      bonusId: availableBonuses.find(b => b.product === selectedProduct.value)?._id,
    };

    setTypes((prev) => [...prev, payload]);
    setSelectedFrom(null);
    setSelectedTo(null);
    setAddType(null);
    setSelectedType(null);
    setSelectedProduct(null);
  };

  const handleDeleteHourType = (id) => {
    setTypes((prev) =>
      prev
        .filter((typeId) => typeId.id !== id)
        .map((type, i) => ({ ...type, id: i + 1 }))
    );
  };

  const getProductLabelByBonusId = (bonusId) => {
    const option = productsOptions.find(o => {
      const bonus = availableBonuses.find(b => b._id === bonusId);

      return o.value === bonus?.product;
    });

    if (!option) {
      addMessage("Product not found, check bonuses and try again", "error");
      setTypes([]);
      setSelectedFrom(null);
      setSelectedTo(null);
    }

    return option?.label;
  }

  const handleSave = (e) => {
    e && e.preventDefault();

    if (types.length === 0) return;

    const payload = {};

    const resultTypes = {};

    types.forEach((type) => {
      const amount = type.toValue - type.fromValue;

      [...Array(amount).keys()].forEach((i) => {
        const time = 510 + (i + type.fromValue) * 30;
        const seconds =
          (time % 60).toString().length === 1 ? (time % 60) + "0" : time % 60;

        const resultHour = `${Math.floor(time / 60)}:${seconds}`;

        resultTypes[resultHour] = {
          sign: type.type,
          bonusId: type.bonusId,
        }
      });
    });

    payload.from = createDateForBackend(addWorkingHoursNew);
    payload.to = createDateForBackend(addWorkingHoursNew);
    payload.type = Object.entries(resultTypes).map(([hour_time, hourData]) => ({
      hour_time,
      sign: hourData.sign,
      bonusId: hourData.bonusId,
    }));

    handleNewSaveWorkedHours(payload);
  };

  return (
    <>
      <PopUp setShow={setAddWorkingHoursNew}>
        <MessageQueue messages={messages} removeMessage={removeMessage} />
        <Title>Add Hours</Title>
        <form
          onSubmit={(e) => handleSave(e)}
          style={{ display: "grid", placeItems: "center" }}
        >
        <Input
          controlled
          value={addWorkingHoursNew}
          disabled
          width={50}
          name="Date"
          required={true}
          color={Colors.darkBlue}
          inputWidth={140}
          type="date"
        />
          <table
            className="styled-table-dashboard"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>{translation.From}</th>
                <th>{translation.To}</th>
                <th>{translation.Type}</th>
                <th>{translation.Product}</th>
                <th>{translation.Action}</th>
              </tr>
            </thead>
            <tbody className="queue">
              {types.map((type) => (
                <tr key={type.id}>
                  <td>{type.id}</td>
                  <td>{type.from}</td>
                  <td>{type.to}</td>
                  <td>{type.type}</td>
                  <td>{getProductLabelByBonusId(type?.bonusId)}</td>
                  <td>
                    <Icon
                      name="fa fa-trash"
                      color={Colors.red}
                      onClick={() => handleDeleteHourType(type.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ButtonWrapper style={{ marginTop: "40px", gap: "20px" }}>
            <CustomButtonAdd
              defaultText={<Icon name={"fa fa-plus"} />}
              style={{ marginBottom: "-30px", width: "30px" }}
              onClick={() => {
                setSelectedProduct(null);
                setAddType(true);
              }}
            />
            <CustomButtonSave text={"Save"} width={100} />
          </ButtonWrapper>
        </form>
      </PopUp>
      {addType && (
        <PopUp setShow={setAddType}>
          <Title>Add type</Title>
          <form
            onSubmit={(e) => handleAddType(e)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "600px",
              height: "300px",
            }}
          >
            <SelectInput
              name="Product"
              required
              color={Colors.darkBlue}
              width={90}
              options={productsOptions?.filter((option) => availableBonuses?.some(b => b.product === option.value))}
              setSelected={setSelectedProduct}
              selected={selectedProduct}
            />
            <SelectInput
              name={"Type"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={WORKING_OPTIONS?.filter(o => {
                if (!checkIsManager(userId, users, addWorkingHoursNew) && o.value === "m") {
                  return false;
                }
              
                return true;
              })}
              setSelected={setSelectedType}
              selected={selectedType}
            />
            <SelectInput
              name={"From"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={HOURS_OPTIONS}
              setSelected={setSelectedFrom}
              selected={selectedFrom}
            />
            <SelectInput
              name={"To"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={HOURS_OPTIONS}
              setSelected={setSelectedTo}
              selected={selectedTo}
            />
            <ButtonWrapper>
              <CustomButtonSave text={"Save"} />
            </ButtonWrapper>
          </form>
        </PopUp>
      )}
    </>
  );
};

export default NewAddWorkedHours;
