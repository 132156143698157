import React, { useEffect, useLayoutEffect, useState } from "react";
import ListTable from "../../../../common/components/ListTable";
import Loading from "../../../../common/components/Loading";
import { formatUsers, getRaws } from "./helpers";
import {
  ButtonWrapper,
  ShowArrow,
  TableTitle,
  UsersTableWrapper,
  Wrapper,
} from "./UserList.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { HEADERS } from "./constants";
import { useSearchParams } from "react-router-dom";
import UserForm from "./components/userForm/UserForm";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getUsers } from "@/API/repositories/user";
import { getLanguages } from "@/API/repositories/language";

const UserList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayEdit, setDisplayEdit] = useState(false);
  const [activeRaws, setActiveRaws] = useState(null);
  const [notActiveRaws, setNotActiveRaws] = useState(null);
  const [showActive, setShowActive] = useState(false);
  const [showNotActive, setShowNotActive] = useState(false);
  const [users, setUsers] = useState();
  const [languageOptions, setLanguageOptions] = useState();

  const { removeMessage, addMessage, messages } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleGetUsers = async () => {
    const usersResponse = await makeRequest(getUsers);

    if (!usersResponse?.data) {
      return addMessage("Couldn't load users", "error");
    }

    setUsers(() => usersResponse.data);

    const languageResponse = await makeRequest(getLanguages);

    if (!languageResponse?.data) {
      return addMessage("Couldn't load languages", "error");
    }

    setLanguageOptions(
      languageResponse.data.map((language) => ({
        label: language.label,
        value: language._id,
      }))
    );

    setActiveRaws(getRaws(formatUsers(usersResponse.data), usersResponse.data));
    setNotActiveRaws(
      getRaws(formatUsers(usersResponse.data, false), usersResponse.data)
    );
  };

  useLayoutEffect(() => {
    if (!displayEdit) {
      handleGetUsers();
    }
  }, [displayEdit]);

  useEffect(() => {
    const mode = searchParams.get("mode");

    if (mode) {
      setDisplayEdit(true);
    } else {
      setDisplayEdit(false);
    }
  }, [searchParams]);

  return (
    <>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(getUsers, getLanguages) && <Loading />}
      {activeRaws && (
        <Wrapper>
          <ButtonWrapper>
            <CustomButtonAdd
              defaultText="Create user"
              onClick={() =>
                setSearchParams({
                  mode: "create",
                })
              }
            />
          </ButtonWrapper>
          <TableTitle onClick={() => setShowActive((prev) => !prev)}>
            Active users{" "}
            <ShowArrow show={showActive} className="fa fa-arrow-down" />
          </TableTitle>
          <UsersTableWrapper show={showActive}>
            <ListTable column={HEADERS} row={activeRaws} entityName={"user"} />
          </UsersTableWrapper>
          <TableTitle onClick={() => setShowNotActive((prev) => !prev)}>
            Not active users{" "}
            <ShowArrow show={showNotActive} className="fa fa-arrow-down" />
          </TableTitle>
          <UsersTableWrapper show={showNotActive}>
            <ListTable
              column={HEADERS}
              row={notActiveRaws}
              entityName={"user"}
            />
          </UsersTableWrapper>
          {displayEdit && (
            <UserForm
              languageOptions={languageOptions}
              users={users}
              addMessage={addMessage}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default UserList;
