import moment from "moment";
import React from "react";

const CurrentGeneratedCallsTable = ({ toScore, users, handleGetRecording }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th colSpan={7}>Current genereted calls</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>Consultant</th>
          <th>Direction</th>
          <th>Phone Number</th>
          <th>Status After Call</th>
          <th>Call At</th>
          <th>Rate It</th>
        </tr>
      </thead>
      <tbody className="queue">
        {toScore?.generated && users[0] ? (
          toScore.generated?.map((element, i) => (
            <tr key={element._id + i}>
              <td>{i + 1}.</td>
              <td>
                {users.find((u) => u._id === element.consultant)?.username}
              </td>
              <td>{element.type}</td>
              <td>{element.type === "outgoing" ? element.to : element.from}</td>
              <td>{element.call_history.status}</td>
              <td style={{ minWidth: "160px" }}>
                {moment(element.created_at).format("YYYY-MM-DD HH:mm")}
              </td>
              <td>
                <i
                  className="fa-solid fa-list-check animation-scale"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleGetRecording(e, element)}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={7}>There is no data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CurrentGeneratedCallsTable;
