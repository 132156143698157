import React from "react";
import userManager from "@/API/userManager";
import {
  OldTicketsWrapper,
  OldMessageSender,
  OldMessageText,
  OldMessageItem,
  OnlineRound,
  OldMessageStatus,
  OldMessageInfoWrapper,
  PriorityWrapper,
} from "./OldTicketsList.styled";
import { handleOnline } from "../../helpers/handleOnline";
import { getOutdatedTicketBackgroundColor } from "./helpers/getOutdatedTicketBackgound";
import { getUserName } from "../../helpers/getUsername";
import { getTicketMessage } from "../../helpers/getTicketMessage";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import Icon from "@/common/components/Icon";

const OldTicketsList = ({ oldTickets, selectedTicket, handleSelectTicket }) => {
  const {
    commonData: { users },
  } = useCommonDataContext();
  const currentUserId = userManager.getUser().id;

  return (
    <OldTicketsWrapper>
      <div>
        {oldTickets?.map((ticket) => (
          <OldMessageItem
            onClick={() => handleSelectTicket(ticket)}
            backgroundColor={getOutdatedTicketBackgroundColor(
              ticket,
              selectedTicket,
              currentUserId
            )}
            color={
              ticket._id === selectedTicket?._id || ticket.type === "correction"
                ? "white"
                : "black"
            }
            key={ticket._id}
          >
            <OldMessageInfoWrapper>
              {ticket.prioritized && (
                <PriorityWrapper>
                  <Icon color="#fff" size={10} name="fa fa-exclamation" />
                </PriorityWrapper>
              )}
              <OldMessageSender>
                <span>{getUserName(ticket._last_status_by) || "System"}</span>
                <OnlineRound
                  backgroundColor={handleOnline(
                    ticket._last_status_by?.last_ping
                  )}
                />
              </OldMessageSender>
              <OldMessageText>{getTicketMessage(ticket, users)}</OldMessageText>
            </OldMessageInfoWrapper>
            <OldMessageStatus
              bgColor={
                QUESTION_TICKET_STATUS_OPTIONS.find(
                  (o) => o.value === ticket.status
                )?.color
              }
            >
              {
                QUESTION_TICKET_STATUS_OPTIONS.find(
                  (o) => o.value === ticket.status
                )?.label
              }
            </OldMessageStatus>
          </OldMessageItem>
        ))}
      </div>
    </OldTicketsWrapper>
  );
};

export default OldTicketsList;
