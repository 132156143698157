import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;
export const createMarket = (market) =>
  errorHandler(async () => {
    const data = await api.post("/market", {
      name: market.name,
      currency: market.currency,
      short: market.short,
    });
    return { data: data.data };
  });

export const getMarkets = () =>
  errorHandler(async () => {
    const data = await api.get("/market");
    return { data: data.data };
  });

export const updateMarket = (id, market) =>
  errorHandler(async () => {
    const data = await api.patch(`/market/${id}`, {
      ...market,
    });
    return { data: data.data };
  });

export const getOneMarket = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/market/${_id}`);
    return { data: data.data };
  });
