export const FILTER_ACTIONS = {
	SET_MARKETS: 'markets',
	SET_CURRENCIES: 'currencies',
	RESET: "RESET",
};

export const DEFAULT_FILTER = {
	markets: [],
	currencies: [],
};
