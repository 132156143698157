import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TodoItemElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 25%;
`;

export const TodoItem = styled.div`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  padding: 24px 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border: 1px solid ${Colors.darkGray};
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
