import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createCompany = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/company/create", payload);

    return { data: data.data };
  });

export const updateCompany = (payload) =>
  errorHandler(async () => {
    const data = await api.patch("/company/update", payload);

    return { data: data.data };
  });

export const getCompanies = () =>
  errorHandler(async () => {
    const data = await api.get("/company/get-all");

    return { data: data.data };
  });
