import React, { useState, useRef, useEffect } from "react";
import ConversationGroup from "../conversationGroup/ConversationGroup";
import TicketsList from "../ticketsList/TicketsList";
import Chat from "../chat/Chat";
import Communication from "../communication/Communication";
import { ListeningObjections } from "@/components/consultant/dashboard/panel/ticket/listeningObjections/ListeningObjections";
import {
  Container,
  TitleWrapper,
  Title,
  AddIcon,
  TicketsWrapper,
  TicketsCloser,
  TicketCreateWrapper,
} from "./TicketView.styled";
import userManager from "@/API/userManager";
import { getLastTicketNotification } from "../../helpers/getLastTicketNotification";
import { markTicketAsSeen } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { ACCEPTABLE_FILE_TYPES } from "@/common/constants/availableFileTypes";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import CreateNewTicket from "@/components/consultant/dashboard/panel/ticket/createNewTicket/CreateNewTicket";
import OldTickets from "./components/oldTickets/OldTickets";
import { detectTicketChanged } from "./helpers/detectTicketChanged";
import { getDepartments } from "@/API/repositories/department";
import Loading from "@/common/components/Loading";

const TicketView = ({
  setIsTicketViewOpen,
  tickets = [],
  oldTickets,
  loadData,
  translation,
  isCallingTicket,
  handleEndCall,
}) => {
  const [departments, setDepartments] = useState();
  const [showCreateTicket, setShowCreateTicket] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [showlisteningObjections, setShowlisteningObjections] = useState(null);
  const [recording, setRecording] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messageLoading, setMessageLoading] = useState(null);
  const [newTicketCreating, setNewTicketCreating] = useState(false);

  const quillRef = useRef();
  const fileRef = useRef();
  const currentUserId = userManager.getUser().id;

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleFileChanges = () => {
    quillRef.current?.setContents?.(null);

    if (fileRef.current?.files.length) {
      if (
        !ACCEPTABLE_FILE_TYPES.includes(
          fileRef.current?.files[0].name.split(".").at(-1)
        )
      ) {
        fileRef.current.value = "";
        setIsFileSelected(() => false);

        return addMessage("Wrong file type", "error");
      }

      return setIsFileSelected(() => true);
    }

    setIsFileSelected(() => false);
  };

  const handleSelectTicket = async (ticket) => {
    setSelectedTicket(() => ticket);
    quillRef.current?.setContents?.(null);
    fileRef.current.value = "";
    handleFileChanges();

    const lastNotification = getLastTicketNotification(ticket);

    if (!lastNotification.seen?.includes(currentUserId)) {
      await makeRequest(markTicketAsSeen.bind(null, ticket._id));
      await loadData();
    }
  };

  const handleDataLoaded = () => {
    if (selectedTicket && tickets) {
      const updatedTicket = [...(tickets || []), ...(oldTickets || [])].find(
        (ticket) => ticket._id === selectedTicket._id
      );

      if (!updatedTicket) return setSelectedTicket(tickets[0]);

      if (detectTicketChanged(selectedTicket, updatedTicket))
        return setSelectedTicket(() => updatedTicket);
    }
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return;
    }

    setDepartments(() => response.data);
  };

  useEffect(() => {
    handleDataLoaded();
  }, [tickets]);

  useEffect(() => {
    loadDepartments();
  }, []);

  return (
    <>
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      {hasUnfilledRequest(getDepartments) && <Loading />}
      <TicketsWrapper>
        <TicketsCloser onClick={() => setIsTicketViewOpen((prev) => !prev)} />
        <Container>
          <TitleWrapper>
            <Title>{translation["tickets"]}</Title>
            <TicketCreateWrapper
              onClick={() => setShowCreateTicket(() => true)}
            >
              <AddIcon className="fa fa-plus" />
            </TicketCreateWrapper>
          </TitleWrapper>
          <ConversationGroup
            departments={departments}
            setIsTicketViewOpen={setIsTicketViewOpen}
            handleEndCall={handleEndCall}
            isCallingTicket={isCallingTicket}
            handleCloseTicketView={setIsTicketViewOpen}
            selectedTicket={selectedTicket}
            loadData={loadData}
            setShowlisteningObjections={setShowlisteningObjections}
            setRecording={setRecording}
            translation={translation}
          />
          <TicketsList
            handleSelectTicket={handleSelectTicket}
            tickets={tickets}
            selectedTicket={selectedTicket}
            newTicketCreating={newTicketCreating}
          />
          <Chat
            messageLoading={messageLoading}
            selectedTicket={selectedTicket}
          />
          <OldTickets
            translation={translation}
            selectedTicket={selectedTicket}
            handleSelectTicket={handleSelectTicket}
            oldTickets={oldTickets}
          />
          <Communication
            setMessageLoading={setMessageLoading}
            messageLoading={messageLoading}
            loadData={loadData}
            selectedTicket={selectedTicket}
            quillRef={quillRef}
            fileRef={fileRef}
            isFileSelected={isFileSelected}
            handleFileChanges={handleFileChanges}
          />
        </Container>
      </TicketsWrapper>
      {showlisteningObjections && recording && (
        <ListeningObjections
          ticket={showlisteningObjections}
          setShow={setShowlisteningObjections}
          recording={recording}
        />
      )}
      {showCreateTicket && (
        <CreateNewTicket
          setNewTicketCreating={setNewTicketCreating}
          addMessage={addMessage}
          translation={translation}
          handleGetConsultantTicketsToDo={loadData}
          setShowQuestionTicket={setShowCreateTicket}
        />
      )}
    </>
  );
};

export default TicketView;
