import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createGamificationConfig = (payload) =>
  errorHandler(async () => {
    const data = await api.post("gamification-config", payload);

    return { data: data.data };
  });

export const updateGamificationConfig = (payload) =>
  errorHandler(async () => {
    const data = await api.patch("gamification-config", payload);

    return { data: data.data };
  });

export const getGamificationConfigs = () =>
  errorHandler(async () => {
    const data = await api.get("gamification-config");

    return { data: data.data };
  });

export const getGamificationConfigForUser = () =>
  errorHandler(async () => {
    const data = await api.get("gamification-config/user");

    return { data: data.data };
  });
