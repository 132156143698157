export const OPTIONS = [
  {
    label: "Confirmation Date",
    value: "confirmation_date",
  },
  {
    label: "Lms sent at",
    value: "shipping.status_lms_sent_at",
  },
];
