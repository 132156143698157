import React from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import workingTime from "../../../images/icons/icons8-work-time-64.png";
import breakTime from "../../../images/icons/icons8-coffee-break-96.png";
import TimeInfo from "./timeInfo/TimeInfo";

const DateStyle = styled.h4`
  color: ${Colors.darkBlue};
  display: flex;
  gap: 20px;
`;

const DayInfomration = ({ queuesWorkingSeconds, breakSeconds }) => {
  return (
    <DateStyle>
      {queuesWorkingSeconds ? (
        queuesWorkingSeconds.map((item) => (
          <TimeInfo
            image={workingTime}
            seconds={item.seconds}
            name={item.queue}
          />
        ))
      ) : (
        <TimeInfo image={workingTime} seconds={0} name={""} />
      )}
      <TimeInfo image={breakTime} seconds={breakSeconds} name={"Break time"} />
    </DateStyle>
  );
};

export default DayInfomration;
