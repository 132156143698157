import moment from "moment";
import React, { useLayoutEffect, useRef, useState } from "react";
import { getConsultantsDailyReport } from "../../API/repositories/reports";
import { Colors } from "../../common/colors/colors";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { CONSULTANT_ROLES } from "../../components/loginPage/LoginForm/loginForm";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import DownloadTable from "@/common/components/downloadTable/DownloadTable";
import NoResponseShiftTable from "@/common/components/noResponseShiftTable/NoResponseShiftTable";
import { WorkingByTake } from "@/common/components/workingByTake/WorkingByTake";

const DailyReport = () => {
  const [consultantsFilterOption, setConsultantFilterOption] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [data, setData] = useState();

  const { commonData, filterUsersByRoles } = useCommonDataContext();
  const { users } = commonData;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const dateFromRef = useRef();
  const dateToref = useRef();

  const handleGetConsultants = async () => {
    setConsultantFilterOption(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedConsultant.length) {
      addMessage("Select consultants", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.consultants = selectedConsultant.map(
      (consultant) => consultant.value
    );

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getConsultantsDailyReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (
      response.data &&
      response.data.length &&
      !response.data.some((element) => {
        return element._call_history.length || element._inbound_history.length;
      })
    ) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  useLayoutEffect(() => {
    handleGetConsultants();
  }, [users]);

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getConsultantsDailyReport) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      <SearchBarCenter>
        <div>
          <Input
            name="Download from"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="date"
            inputWidth={140}
          />
          <Input
            name="Download to"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateToref}
            type="date"
            inputWidth={140}
          />
        </div>
        <SelectInput
          name="Consultants"
          options={consultantsFilterOption}
          color={Colors.darkBlue}
          width={100}
          setSelected={setSelectedConsultant}
          selected={selectedConsultant}
          multiple={true}
        />
        <button className="btn btn-warning" onClick={(e) => hanldeSearch(e)}>
          Search
        </button>
        <button
          className="btn btn-warning"
          onClick={(e) => setSelectedConsultant(consultantsFilterOption)}
        >
          Add All
        </button>
      </SearchBarCenter>
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._inbound_history)}
          consultants={users}
          name={"Inbound"}
        />
      )}
      {data && data.length > 0 && (
        <NoResponseShiftTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}

      {data && data.length > 0 && (
        <WorkingByTake
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
    </WrapperWithScroll>
  );
};

export default DailyReport;
