import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getGradeReport } from "../../API/repositories/reports";
import Card from "../../common/components/Card";
import CSV from "../../common/components/CSV";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import StatisticsGradeTable from "../../components/consultant/statistics/StatisticsGradeTable";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import { CONSULTANT_ROLES } from "../../components/loginPage/LoginForm/loginForm";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const Flex = styled.div`
  display: flex;
`;

const GradeReport = () => {
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [data, setData] = useState();

  const downloadStartDateRef = useRef();
  const downloadEndDateRef = useRef();
  const createdStartDateRef = useRef();
  const createdEndDateRef = useRef();

  const downloadStartDate = moment()
    .subtract("35", "days")
    .format("YYYY-MM-DD");
  const downloadEndDate = moment().subtract("7", "days").format("YYYY-MM-DD");

  const createdStartDate = moment()
    .subtract("6", "months")
    .format("YYYY-MM-DD");
  const createdEndDate = moment().format("YYYY-MM-DD");

  const [consultants, setConsultants] = useState(null);
  const [selectedQueues, setSelectedQueues] = useState([]);

  const { commonData, options } = useCommonDataContext();
  const { queuesOptions } = options;
  const { queues, users } = commonData;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleGetReport = async () => {
    setData(() => null);
    const payload = Object();

    if (!selectedConsultants.length) {
      addMessage("Select consultants", "error");

      return;
    }

    payload.from = downloadStartDateRef.current
      ? moment(downloadStartDateRef.current.value).startOf("day")
      : moment(downloadStartDate).startOf("day");
    payload.to = downloadEndDateRef.current
      ? moment(downloadEndDateRef.current.value).endOf("day")
      : moment(downloadEndDate).endOf("day");
    payload.fromContact = createdStartDateRef.current
      ? moment(createdStartDateRef.current.value).startOf("day")
      : moment(createdStartDate).startOf("day");
    payload.toContact = createdEndDateRef.current
      ? moment(createdEndDateRef.current.value).endOf("day")
      : moment(createdEndDate).endOf("day");
    payload.weights = true;
    payload.ids = selectedConsultants.map((data) => data.value);

    const response = await makeRequest(getGradeReport.bind(null, payload));

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleSetConsultants = () => {
    setConsultants(
      users
        .filter((user) => CONSULTANT_ROLES.includes(user.role))
        .map((consultant) => {
          return {
            label: consultant.name + " " + consultant.surname,
            value: consultant._id,
          };
        })
    );
  };

  const getCSVdata = (toCsv) => {
    const result = [];

    toCsv.map((consultantRow) => {
      consultantRow.wppk
        .sort((a, b) => parseInt(a.dequeueCount) - parseInt(b.dequeueCount))
        .map((row) => {
          const temp = [];

          temp.push(
            consultants.find(
              (consultant) => consultant.value === consultantRow.index
            ).label
          );
          temp.push(row.dequeueCount);
          temp.push(row.downloaded);
          temp.push(row.costs);
          temp.push(row.deliverd);
          temp.push(row.deliveredSum);
          temp.push(row.wppk);
          temp.push(row.grade);

          result.push(temp);
        });

      const temp = [];

      temp.push(
        consultants.find(
          (consultant) => consultant.value === consultantRow.index
        ).label
      );
      temp.push("Total");
      temp.push(consultantRow.wppkSum.downloadedSum);
      temp.push(consultantRow.wppkSum.costsSum);
      temp.push(consultantRow.wppkSum.deliverdSum);
      temp.push(consultantRow.wppkSum.deliveredValueSum);
      temp.push(consultantRow.wppkSum.wppk);
      temp.push(consultantRow.wppkSum.grade);

      result.push(temp);
    });

    return result;
  };

  const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      consultants.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

  useEffect(() => {
    handleSetConsultants();
  }, [users]);

  return (
    <Wrapper>
      {hasUnfilledRequest(getGradeReport) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      {consultants ? (
        <Card>
          <div style={{ maxWidth: "80vw" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  inputRef={downloadStartDateRef}
                  name="Download date from"
                  type="date"
                  width={240}
                  value={downloadStartDate}
                  inputWidth={220}
                />
                <Input
                  inputRef={downloadEndDateRef}
                  name="Download date to"
                  type="date"
                  width={240}
                  value={downloadEndDate}
                  inputWidth={220}
                />
              </div>
              <div>
                <Input
                  inputRef={createdStartDateRef}
                  name="Created date from"
                  type="date"
                  width={240}
                  value={createdStartDate}
                  inputWidth={220}
                />
                <Input
                  inputRef={createdEndDateRef}
                  name="Created date to"
                  type="date"
                  width={240}
                  value={createdEndDate}
                  inputWidth={220}
                />
              </div>
            </div>

            <Flex>
              <SelectInput
                multiple={true}
                name="Consultants"
                options={consultants}
                width={220}
                setSelected={setSelectedConsultants}
                selected={selectedConsultants}
                selectWidth={240}
              />{" "}
              <SelectInput
                multiple={true}
                name="Consultants from queue"
                options={queuesOptions}
                width={220}
                selectWidth={240}
                setSelected={setSelectedQueue}
                selected={selectedQueues}
              />{" "}
            </Flex>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {data && (
              <CSV
                filename={"report-grade-all.csv"}
                data={getCSVdata(data)}
                header={[
                  "Consultant",
                  "Dequeue number",
                  "Downloaded",
                  "Cost",
                  "Delivered",
                  "Delivered sum",
                  "Grade",
                ]}
              />
            )}
            <button
              onClick={(e) => handleGetReport(e)}
              className="btn btn-warning"
            >
              Search
            </button>
          </div>
        </Card>
      ) : (
        <Loading />
      )}
      {data && (
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "30px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {data.map((data) => (
            <StatisticsGradeTable
              csv={true}
              grade={data}
              dontShowDescription={true}
              name={() =>
                consultants.find(
                  (consultant) => consultant.value === data.index
                ).label
              }
            />
          ))}{" "}
        </div>
      )}
    </Wrapper>
  );
};

export default GradeReport;
