export const CLAIM_PAYMENT_STATUSES = [
  {
    label: "To pay",
    value: "to_pay",
  },
  {
    label: "Paid",
    value: "paid",
  },
];

export const CLAIM_STATUS_OPTIONS_NOT_ENDED_CLAIMS = [
  {
    label: "Created",
    value: "created",
  },
  {
    label: "To correct",
    value: "to_correct",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "To check",
    value: "to_check",
  },
  {
    label: "Checked",
    value: "checked",
  },
  {
    label: "Done",
    value: "done",
  },
];

export const CLAIM_STATUS_OPTIONS = [
  {
    label: "Created",
    value: "created",
  },
  {
    label: "To correct",
    value: "to_correct",
  },
  {
    label: "Done",
    value: "done",
  },
  {
    label: "Paid",
    value: "paid",
  },
];

export const COMPANIES_OPTIONS = [
  {
    label: "Eou",
    value: "EOU",
  },
  {
    label: "Lingua",
    value: "LINGUA",
  },
  {
    label: "Europa",
    value: "EUROPA",
  },
];
