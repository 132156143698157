import styled from "styled-components";

export const AccessTokenWrapper = styled.td`
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Icon = styled.i`
  cursor: pointer;
`;
