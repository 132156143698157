import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
`;

export const SearchBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;
