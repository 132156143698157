import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const ENTITY_NAME = "test-result";

export const createTestResult = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}/`, payload);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
