import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  gap: 40px;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end
`;

export const TableWrapper = styled.div`
  max-height: calc(100vh - 250px);
  overflow: scroll;
`;
