import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const SelectUserWrapepr = styled.div`
  display: flex;
`;

export const AnswerWrapper = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const AnswerWrapperOptions = styled.div`
  display: flex;
  justify-content: left;
  gap: 30px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const QuestionWrapper = styled.div`
  display: grid;
  gap: 30px;
`;

export const Form = styled.form`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    margin-top: 40px;
  }

  button {
    margin-bottom: -40px;
  }
`;
