import React from "react";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headersArray";
import { getRaws } from "./helpers/getRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getGamificationBonus } from "./helpers/getGamificationBonus";
import { findUserMarket } from "@/common/functions/findUserMarket";

const PaymentSummarizeTable = ({ consultantResults, paymentData }) => {
  const {
    commonData: { markets, queues, users },
  } = useCommonDataContext();
  const userMarket = findUserMarket(markets, queues, consultantResults._id);

  const gamificationBonus = consultantResults._gamification_results
    ? getGamificationBonus({
        consultantResults,
        paymentData,
        userMarket,
        markets,
        queues,
        users,
      })
    : 0;

  return (
    <Table
      style={{ marginTop: "30px" }}
      className="styled-table"
      headersArray={HEADERS_ARRAY}
      raws={getRaws(consultantResults, gamificationBonus)}
    />
  );
};

export default PaymentSummarizeTable;
