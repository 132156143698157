import SelectInput from "@/common/components/SelectInput";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Flex, RightFlex } from "../../ValidInvoicesReport.styled";
import Input from "@/common/components/Input";
import ExportExcel from "@/common/components/ExportExcel";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { Colors } from "@/common/colors/colors";
import {
  formatDate,
  formatToDateTamplate,
} from "@/common/functions/dateFormater";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { getMarketById } from "../../helpers/getMarketById";
import Card from "@/common/components/Card";
import { getValidInvoicesReport } from "@/API/repositories/reports";
import { sortTicketsByInvoiceNumber } from "@/common/functions/sortTicketsByInvoiceNumber";
import { getExcelData } from "./helpers/getExcelData";
import { saveAs } from "@/common/functions/saveAs";
import { Icon } from "./ValidInvoicesReportSearchBar.styled";
import { getCompanies } from "@/API/repositories/company";
import { findCompanyNameById } from "../../../../../common/functions/findCompanyNameById";
import { findDefaultMarketFromOptions } from "@/common/functions/findDefaultMarketFromOptions";
import { findPossibleProductFieldByMarket } from "@/common/functions/findPossibleProductFieldByMarket";
import { filterEntitiesByIds } from "@/common/functions/filterEntitiesByIds";
import { filterProdluctIdsByCompanyId } from "@/common/functions/filterProductIdsByCompanyId";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import { getPdfsByIds } from "@/API/repositories/storedDocument";
const JSZip = require("jszip");
const zip = new JSZip();

const ValidInvoicesReportSearchBar = ({
  tickets,
  setTickets,
  visibleTickets,
  setVisibleTickets,
}) => {
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [companies, setCompanies] = useState();
  const [productsOptions, setProductsOptions] = useState();
  const [companiesOptions, setCompaniesOptions] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const {
    options: { marketsOptions, queuesOptions },
    commonData: { markets, products },
  } = useCommonDataContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const fromDateRef = useRef();
  const toDateRef = useRef();
  const salesRef = useRef();
  const correctionRef = useRef();

  const handleSearch = async () => {
    if (!selectedMarket?.value) {
      return addMessage("Select market", "error");
    }

    if (!selectedProduct) {
      return addMessage("Select product", "error");
    }

    if (!fromDateRef.current?.value || !toDateRef.current?.value) {
      return addMessage("Select all dates", "error");
    }

    const payload = {};

    payload.market = selectedMarket.value;
    payload.products = [selectedProduct.value];
    payload.fromDate = new Date(fromDateRef.current.value);
    payload.toDate = new Date(toDateRef.current.value);

    const response = await makeRequest(
      getValidInvoicesReport.bind(null, payload)
    );

    if (!response.data) {
      return addMessage("Something went wrong", "error");
    }

    if (response.data) {
      setTickets(() => response.data);
    }
  };

  const handleGetAllMonthFiles = async () => {
    const ids = visibleTickets.map(
      (ticket) => ticket.valid_invoice.stored_document
    );

    const query = {};

    query.ids = ids;

    const response = await makeRequest(getPdfsByIds.bind(null, query));
    if (response.data) {
      const files = response.data;
      files.forEach((file) => {
        file.name = file.name.split("_")[1] || file.name;
      });

      files.forEach((file) => {
        zip.file(file.name, file.data.data);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(
          content,
          `${findCompanyNameById(
            companies,
            selectedCompany.value
          )}_invoices_${getMarketById(
            selectedMarket.value,
            markets
          )?.short.toUpperCase()}_${formatToDateTamplate(
            fromDateRef.current?.value
          )}_${formatToDateTamplate(toDateRef.current?.value)}.zip`
        );
      });

      files.forEach((file) => {
        zip.remove(file.name);
      });
    }
  };

  const hansleSetVisibleTickets = () => {
    const corrections =
      tickets?.filter(
        (ticket) => ticket.valid_invoice.invoice_type === "correction"
      ) || [];
    const sales =
      tickets?.filter(
        (ticket) => ticket.valid_invoice.invoice_type === "sales"
      ) || [];

    setVisibleTickets(() => {
      const visible = [];

      if (salesRef.current?.checked) {
        visible.push(...sales);
      }

      if (correctionRef.current?.checked) {
        visible.push(...corrections);
      }

      return sortTicketsByInvoiceNumber(visible);
    });
  };

  const excelData = useMemo(() => {
    if (!!visibleTickets?.length) {
      const currency = visibleTickets[0]._order.currency;

      return getExcelData(visibleTickets, markets, currency);
    }
  }, [visibleTickets]);

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (response?.data) {
      setCompanies(() => response.data);
    }
  };

  const handleSetMarket = (market) => {
    const currentMarket =
      market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

    setSelectedMarket(currentMarket);
    handleMarketChange(currentMarket);
  };

  const handleMarketChange = (market) => {
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (market && companies) {
      const possibleCompanies = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products,
        "company"
      );

      const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

      const filteredCompanies = filterEntitiesByIds(
        companies,
        uniqueCompaniesIds
      );

      const newCompaniesOptions = handleMapToOptions(
        filteredCompanies,
        "name",
        "_id"
      );

      setCompaniesOptions(newCompaniesOptions);
      const company = newCompaniesOptions[0];

      handleSetCompany(company, market);
    }
  };

  const handleSetCompany = (company, market = selectedMarket) => {
    setSelectedCompany(company);
    handleCompanyChange(company, market);
  };

  const handleCompanyChange = (company, market) => {
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (company) {
      const possibleProductsIds = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products
      );

      const productIdsFilteredByCompanies = filterProdluctIdsByCompanyId(
        products,
        possibleProductsIds,
        company.value
      );

      const filteredProducts = filterEntitiesByIds(
        products,
        productIdsFilteredByCompanies
      );

      const productsOptions = handleMapToOptions(
        filteredProducts,
        "name",
        "_id"
      );

      setProductsOptions(() => productsOptions);
      setSelectedProduct(() => productsOptions[0]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    handleSetMarket();
  }, [queuesOptions, marketsOptions, products, companies]);

  useEffect(() => {
    hansleSetVisibleTickets();
  }, [tickets]);

  return (
    <Card>
      {(hasUnfilledRequest(
        getValidInvoicesReport,
        getPdfsByIds,
        getCompanies
      ) ||
        !marketsOptions?.length) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <Flex>
        <div>
          <SelectInput
            name="Market"
            placeholder="Select market..."
            selectWidth={250}
            width={100}
            options={marketsOptions}
            setSelected={handleSetMarket}
            selected={selectedMarket}
            color={Colors.darkBlue}
          />
          <SelectInput
            name="Company"
            placeholder="Select company..."
            selectWidth={250}
            width={100}
            options={companiesOptions}
            setSelected={handleSetCompany}
            selected={selectedCompany}
            color={Colors.darkBlue}
          />
          <SelectInput
            name="Product"
            placeholder="Select product..."
            selectWidth={250}
            width={100}
            options={productsOptions}
            selected={selectedProduct}
            setSelected={setSelectedProduct}
            color={Colors.darkBlue}
          />
        </div>
        <div>
          <Input
            inputWidth={250}
            width={100}
            inputRef={fromDateRef}
            name="From"
            type="date"
            color={Colors.darkBlue}
          />
          <Input
            inputWidth={250}
            inputRef={toDateRef}
            width={100}
            name="To"
            type="date"
            color={Colors.darkBlue}
          />
          <RightFlex>
            <ToggleSwitch
              onChange={() => hansleSetVisibleTickets()}
              toggleRef={salesRef}
              text="Sales"
              width={130}
              gap="12px"
              checked
            />
            <ToggleSwitch
              onChange={() => hansleSetVisibleTickets()}
              toggleRef={correctionRef}
              text="Correction"
              width={110}
              gap="12px"
              checked
            />
          </RightFlex>
        </div>
      </Flex>
      <RightFlex>
        {!!visibleTickets?.length && (
          <>
            <div>
              <Icon
                onClick={() => handleGetAllMonthFiles()}
                className="fa fa-file-zipper animation-scale"
              />
            </div>
            <ExportExcel
              excelData={excelData}
              fileName={`${findCompanyNameById(
                companies,
                selectedCompany.value
              )}_${getMarketById(
                selectedMarket.value,
                markets
              ).short.toUpperCase()}_Faktury_${formatDate(
                fromDateRef.current?.value,
                "DD.MM.YYYY"
              )}-${formatDate(toDateRef.current?.value, "DD.MM.YYYY")}.csv`}
            />
          </>
        )}
        <CustomButtonAdd onClick={() => handleSearch()} defaultText="Search" />
      </RightFlex>
    </Card>
  );
};

export default ValidInvoicesReportSearchBar;
