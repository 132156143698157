import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const PaymentWrapper = styled.div`
  padding: 30px;
  border-radius: 15px;
  border: 1px solid ${Colors.darkBlue};
  display: flex;
  justify-content: right;
  flex-direction: column;
`;

export const Title = styled.h4`
  color: ${Colors.darkBlue};
  text-align: center;
  margin: 0 0 20px;
`;
