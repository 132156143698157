import Table from "@/common/components/Table";
import React, { useMemo } from "react";
import { getHeaders } from "./helpers/getHeaders";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getRaws } from "./helpers/getRaws";

const ValidInvoicesReportTable = ({ tickets }) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();

  const headers = useMemo(() => {
    return getHeaders(tickets);
  }, [tickets]);

  const raws = useMemo(() => {
    return getRaws(tickets, markets);
  }, [tickets]);

  return (
    <div style={{ maxWidth: "calc(90vw - 20px)", overflow: "scroll" }}>
      <Table headers={headers} raws={raws} className="styled-table" />
    </div>
  );
};

export default ValidInvoicesReportTable;
