import PopUp from "@/common/components/PopUp";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const Details = ({ setShow, show, handleRemoveConsultantFromContact }) => {
  const navigate = useNavigate();

  return (
    <PopUp setShow={setShow}>
      <table className="styled-table-dashboard">
        <thead>
          <tr>
            <th>Type</th>
            <th>Queue</th>
            <th>Phone Number</th>
            <th>Started at</th>
            <th>D.C.</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr key={show._id}>
            <td>{show.type}</td>
            <td>{show._queue?.name || "----"}</td>
            <td>{show._contact.phone_number}</td>
            <td>
              {show.created_at && moment(show.created_at).format("HH:mm")}
            </td>
            <td>{show._order.contact_attempts_queue_count}</td>
            <td>{show._order.status}</td>
            <td style={{ minWidth: "100px" }}>
              <i
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                className="fa fa-user animation-scale"
                onClick={() =>
                  navigate("/settings/user/show/" + show._consultant._id)
                }
              />
              <i
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
                className="fa fa-tasks animation-scale"
                onClick={() => {
                  if (show.type !== "inbound")
                    navigate("/settings/queue/show/" + show._queue._id);
                }}
              />
              <i
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
                className="fa fa-address-book animation-scale"
                onClick={() => navigate("/dashboard/contact/" + show.contact)}
              ></i>
              <i
                style={{
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                className="fa fa-address-card animation-scale"
                onClick={() => navigate("/dashboard/order/" + show.order)}
              ></i>
              <i
                style={{
                  color: "red",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                className="fa fa-remove animation-scale"
                onClick={(e) => handleRemoveConsultantFromContact(e, show)}
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </PopUp>
  );
};

export default Details;
