import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getContactWithOrders,
  saveConsultantContact,
} from "../../API/repositories/contact";
import Card from "../../common/components/Card";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { COUNTRIES } from "../../common/constants/countries";
import SelectInput from "../../common/components/SelectInput";
import EditItem from "../../common/components/EditItem";
import OrderEdit from "../../components/consultant/order/OrderEdit";
import { getProductByUserQueue } from "../../API/repositories/product";
import userManager from "../../API/userManager";
import { Colors } from "../../common/colors/colors";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { DISABLED_TOOLTIP } from "@/common/constants/suspicion";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useNewMessageQueueContext } from "@/common/hooks/useNewMessageQueue";

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
`;

const CardWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

const ConsultantContact = () => {
  const { id } = useParams();
  const [dataToDisplay, setDataToDisplay] = useState(null);
  const [country, setCountry] = useState();
  const [create, setCreate] = useState(false);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState({});

  const nameRef = useRef();
  const emailRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const phoneForCourierRef = useRef();

  const { commonData: { markets } } = useCommonDataContext();
  const { addMessage } = useNewMessageQueueContext();

  const market = useMemo(() => {
    return markets.find(m => m.short === dataToDisplay?.country)
  }, [dataToDisplay])

  const handleGetContactWithOrders = async () => {
    const response = await makeRequest(getContactWithOrders.bind(null, id));
    const responseProduct = await makeRequest(
      getProductByUserQueue.bind(null, userManager.getUser().id)
    );

    if (response.data && responseProduct.data) {
      setCountry(
        COUNTRIES.find((country) => country.value === response.data.country)
      );

      response.data.orders.forEach((order) => {
        order.showEdit = false;

        let varaints = "";

        order.order_items?.forEach((item, i) => {
          if (i === 0) {
            varaints += item.product_variant;
          } else {
            varaints += " ," + item.product_variant;
          }
        });

        order.varaintsName = varaints !== "" ? varaints : "-------";

        if (order.shipping) {
          if (order.shipping.status && order.shipping.status !== "to_send") {
            order.showEdit = true;
          }
        }
      });

      setDataToDisplay(() => response.data);
    } else {
      setDataToDisplay(() => null);
    }
  };

  const handleShowOrderEdit = (e, data, create) => {
    e.preventDefault();
    setCreate(() => create);
    setOrder(() => data);
    setShow(() => true);
  };

  useEffect(() => {
    handleGetContactWithOrders();
  }, [show]);

  const handleSave = async () => {

    if (phoneForCourierRef?.current?.value 
      && !validatePhoneForCourier(phoneForCourierRef.current.value)) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    const payload = Object();
    payload.full_name = nameRef.current
      ? nameRef.current.value
      : dataToDisplay.full_name;
    payload.email = emailRef.current
      ? emailRef.current.value
      : dataToDisplay.email;
    payload.country = country.value;
    payload.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : dataToDisplay.postal_code;
    payload.city = cityRef.current ? cityRef.current.value : dataToDisplay.city;
    payload.street = streetRef.current
      ? streetRef.current.value
      : dataToDisplay.street;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;

    const response = await makeRequest(
      saveConsultantContact.bind(null, id, payload)
    );

    await handleGetContactWithOrders();

    if (response.data) {
    } else {
      console.error(response.error);
    }
  };

  const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, dataToDisplay._id)
    );

    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    await handleGetContactWithOrders();

    return addMessage("Success", "success");
  };

  const getOrderItems = (order) => {
    return order.shipping?.order_items?.length
      ? order.shipping?.order_items
      : order.order_items;
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(
        saveConsultantContact,
        createSuspicionTicket,
        getContactWithOrders
      ) && <Loading />}
      {dataToDisplay && (
        <CardWrapper>
          <Card>
            <div
              style={{
                width: "1000px",
                display: "grid",
                gap: "40px",
                padding: "10px",
              }}
            >
              <h3 style={{ color: Colors.darkBlue }}>Contact:</h3>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    inputRef={nameRef}
                    name="Full Name"
                    value={dataToDisplay.full_name}
                    width={140}
                  />
                  <Input
                    inputRef={emailRef}
                    name="Email"
                    value={dataToDisplay.email}
                    width={140}
                  />
                  <SelectInput
                    options={COUNTRIES}
                    name="Country"
                    selected={country}
                    setSelected={setCountry}
                    width={140}
                  />
                  <Input
                    name="Phone for courier"
                    value={dataToDisplay.phone_number_for_courier}
                    width={140}
                    inputRef={phoneForCourierRef}
                  />
                  <Input
                    name="Phone Number"
                    disabled={true}
                    value={dataToDisplay.phone_number}
                    width={140}
                  />
                </div>
                <div>
                  <Input
                    inputRef={postalCodeRef}
                    name="Postal Code"
                    value={dataToDisplay.postal_code}
                    width={140}
                  />
                  <Input
                    inputRef={cityRef}
                    name="City"
                    value={dataToDisplay.city}
                    width={140}
                  />
                  <Input
                    inputRef={streetRef}
                    name="Street"
                    value={dataToDisplay.street}
                    width={140}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <CustomButtonAdd
                  tooltip={
                    dataToDisplay.shouldDisableSuspicion
                      ? DISABLED_TOOLTIP
                      : null
                  }
                  disabled={dataToDisplay.shouldDisableSuspicion}
                  defaultText="Suspect"
                  color={Colors.red}
                  onClick={handleCreateSuspicionTicket}
                />
                <CustomButtonAdd defaultText="Save" onClick={handleSave} />
              </div>
            </div>
          </Card>
          <div style={{ width: "1000px" }}>
            <h3 style={{ marginBottom: "30px", color: Colors.darkBlue }}>
              Orders:
            </h3>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      <h5>status</h5>
                    </th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      <h5>currency</h5>
                    </th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      <h5>product</h5>
                    </th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      <h5>variants</h5>
                    </th>
                    <th style={{ width: "150px", textAlign: "center" }}>
                      <h5>value</h5>
                    </th>
                    <th style={{ width: "150px", textAlign: "center" }}>
                      <h5>shipping status</h5>
                    </th>
                    <th style={{ width: "200px", textAlign: "center" }}>
                      <h5>edit shipping</h5>
                    </th>
                  </tr>
                </thead>
                <tbody className="queue">
                  {dataToDisplay.orders.map((data) => (
                    <tr>
                      <td style={{ width: "100px", textAlign: "center" }}>
                        {data.status}
                      </td>
                      <td style={{ width: "100px", textAlign: "center" }}>
                        {" "}
                        {data.shipping?.currency || data.currency}
                      </td>
                      <td style={{ width: "100px", textAlign: "center" }}>
                        {data._product.name}
                      </td>
                      <td style={{ width: "100px", textAlign: "center" }}>
                        {getOrderItems(data).length
                          ? getOrderItems(data)
                              .map((item) => item.product_variant)
                              .join(", ")
                          : "--------"}
                      </td>
                      <td style={{ width: "150px", textAlign: "center" }}>
                        {getOrderItems(data).length
                          ? getOrderItems(data)
                              .reduce((prev, next) => prev + next.price, 0)
                              ?.toFixed(2)
                          : "------"}
                      </td>
                      <td style={{ width: "150px", textAlign: "center" }}>
                        {data.shipping?.status === "delivered_no_payment"
                          ? "delivered"
                          : data.shipping?.status || "------"}
                      </td>
                      <td style={{ width: "200px", textAlign: "center" }}>
                        {!data.showEdit ? (
                          <div
                            onClick={(e) => handleShowOrderEdit(e, data, false)}
                          >
                            <EditItem />
                          </div>
                        ) : (
                          "Sent"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardWrapper>
      )}
      {show && (
        <OrderEdit
          order={order}
          setShow={setShow}
          setOrder={setOrder}
          contact={dataToDisplay}
          country={country}
          market={market}
          create={create}
          addMessage={addMessage}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantContact;
