import { getConsultantInfo } from "@/API/repositories/user";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useState } from "react";
import { TitleSection, TopContentWrapper, Wrapper } from "../shared.styles";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { removeConsultantFromContact } from "@/API/repositories/queue";
import {
  CounterWrapper,
  NobodyWrapper,
  WorkingSummaryWrapper,
} from "./consultantInfo.styles";
import Details from "./components/Details";
import WorkingUsers from "./components/WorkingUsers";
import { Colors } from "@/common/colors/colors";
import { groupData } from "./helpers/groupData";

const ConsultantInfo = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadData = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(getConsultantInfo);

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleRemoveConsultantFromContact = async (e, boundInfo) => {
    e && e.preventDefault();
    const response = await makeRequest(
      removeConsultantFromContact.bind(null, boundInfo)
    );

    if (response.data) {
      setShow(() => null);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [show]);

  useEffect(() => {
    const interval = setInterval((e) => {
      handleLoadData(e);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Wrapper width={480}>
        <TitleSection>
          <TopContentWrapper>
            <i className="fa fa-people-group" /> Consultant information
          </TopContentWrapper>
          <WorkingSummaryWrapper>
            <CounterWrapper color="green">
              {data.reduce(
                (prev, next) => prev + (next.bound_order_contact ? 1 : 0),
                0
              )}
              <i className="fa fa-phone" style={{ color: "green" }} />
            </CounterWrapper>
            <CounterWrapper color="red">
              {data.reduce(
                (prev, next) => prev + (next.bound_order_contact ? 0 : 1),
                0
              )}
              <i className="fa fa-phone-slash" style={{ color: "red" }} />
            </CounterWrapper>
            <CounterWrapper color={Colors.darkBlue}>
              <i
                class="fa-solid fa-arrows-rotate animation-scale"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setData(() => []);
                  handleLoadData();
                }}
              />
            </CounterWrapper>
          </WorkingSummaryWrapper>
        </TitleSection>
        {data[0] ? (
          <WorkingUsers data={groupData(data)} setShow={setShow} />
        ) : hasUnfilledRequest(getConsultantInfo) ? (
          <CustomSkeleton width={440} count={17} height={4} />
        ) : (
          <NobodyWrapper>Nobody is working... :(</NobodyWrapper>
        )}
      </Wrapper>
      {show && (
        <Details
          show={show}
          setShow={setShow}
          handleRemoveConsultantFromContact={handleRemoveConsultantFromContact}
        />
      )}
    </>
  );
};

export default ConsultantInfo;
