import React, { useMemo } from "react";
import Table from "@/common/components/Table";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getHeaders } from "../../helpers/getHeaders";
import { getRaws } from "./helpers/getRaws";

const InvoicesReportTable = ({ orders, correctionNumber }) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();

  const headers = useMemo(() => {
    if (!!orders?.length) {
      return getHeaders(orders);
    }
  }, [orders]);

  const raws = useMemo(() => {
    if (orders && !!orders.length) {
      return getRaws(orders, markets, correctionNumber);
    }
  }, [orders]);

  return (
    <Table
      headers={headers}
      raws={raws}
      className="styled-table styled-table-2"
    />
  );
};

export default InvoicesReportTable;
