export const OLD_STATUSES = [
  "closed",
  "accepted_pending",
  "accepted",
  "declined_pending",
  "declined",
];
export const CALLING_TICKETS = ["listening_objections", "calling_improvement"];
export const NOT_ABLE_TO_CLOSE = ["invoice", "document", "question"];
export const ACCEPTED_STATUSES = ["accepted_pending", "accepted"];
