import React, { useEffect, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { generateListeningToScoring } from "../../../API/repositories/listeningGenerator";
import Loading from "../../../common/components/Loading";
import {
  createListeningScoring,
  currentWeekConsultantManager,
  updateListeningScoring,
} from "../../../API/repositories/listeningScoring";
import MessageQueue, { useMessageQueue } from "../../../common/messageProvider";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { Wrapper, ButtonWrapper } from "./consultantManagerScoring.styles";
import ImporvementTicket from "../../admin/listeningScores/components/ImporvementTicket";
import Scoring from "./components/Scoring";
import ScoringCreate from "./components/ScoringCreate";
import CurrentWeekScoreTable from "./components/CurrentWeekScoreTable";
import CurrentGeneratedCallsTable from "./components/CurrentGeneratedCallsTable";
import Scores from "@/pages/admin/listeningScores/components/Scores";
import { getRecordingById } from "@/API/repositories/storedDocument";
import Blackboard from "@/components/consultant/dashboard/panel/blackboard-info/Blackboard";

const ConsultantManagerScoringListening = () => {
  const [scores, setScores] = useState(null);
  const [toScore, setToScore] = useState();
  const [showEdit, setShowEdit] = useState();
  const [showEditCurrent, setShowEditCurrent] = useState();
  const [currentScore, setCurrentScore] = useState();

  const [showTicketTalkingImprovement, setShowTicketTalkingImprovement] =
    useState();
  const {
    commonData: { users },
  } = useCommonDataContext();

  const { messages, addMessage } = useMessageQueue();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleGenerateListening = async () => {
    const response = await makeRequest(generateListeningToScoring);
    if (response.data) {
      const { generated, table } = response.data;
      setToScore({ generated, table });
    }
  };

  const handleLoadData = async () => {
    const scoreResponse = await makeRequest(currentWeekConsultantManager);
    if (scoreResponse.data[0]) {
      const scoreResults = scoreResponse.data.reduce((prev, next) => {
        prev[next._user.username] = [
          ...(prev[next?._user.username] || []),
          next,
        ];
        return prev;
      }, {});

      setScores(() => scoreResults);
    }
  };

  const handleGetRecording = async (e, element) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getRecordingById.bind(null, element.stored_data)
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setCurrentScore(() => ({ ...element, url }));
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  useEffect(() => {}, [users]);

  return (
    <>
      {hasUnfilledRequest(
        generateListeningToScoring,
        getRecordingById,
        createListeningScoring,
        updateListeningScoring
      ) && <Loading />}
      <MessageQueue messages={messages} />
      <Wrapper>
        <ButtonWrapper>
          <button
            className="btn btn-warning"
            onClick={() => handleGenerateListening()}
          >
            Generate
          </button>
        </ButtonWrapper>
        <CurrentWeekScoreTable
          scores={scores}
          users={users}
          setShowEdit={setShowEdit}
        />
        <Blackboard type="listening" />
        <CurrentGeneratedCallsTable
          handleGetRecording={handleGetRecording}
          toScore={toScore}
          users={users}
        />
        {currentScore && (
          <ScoringCreate
            setCurrentScore={setCurrentScore}
            currentScore={currentScore}
            handleLoadData={handleLoadData}
            setToScore={setToScore}
            showEditCurrent={showEditCurrent}
            toScore={toScore}
          />
        )}
      </Wrapper>
      {showEdit && (
        <Scores
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          setShowEditCurrent={setShowEditCurrent}
          setShowTicketTalkingImprovement={setShowTicketTalkingImprovement}
        />
      )}
      {showEditCurrent && (
        <Scoring
          setShowEditCurrent={setShowEditCurrent}
          showEditCurrent={showEditCurrent}
          handleLoadData={handleLoadData}
          setToScore={setToScore}
          setShowEdit={setShowEdit}
        />
      )}
      {showTicketTalkingImprovement && (
        <ImporvementTicket
          showTicketTalkingImprovement={showTicketTalkingImprovement}
          setShowTicketTalkingImprovement={setShowTicketTalkingImprovement}
          addMessage={addMessage}
        />
      )}
    </>
  );
};

export default ConsultantManagerScoringListening;
