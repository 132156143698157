import React, { useEffect, useState } from "react";
import {
  ConversationItem,
  SuccessButton,
} from "../../ConversationGroup.styled";
import { getUserById } from "../../../../helpers/getUserById";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SelectInput from "@/common/components/SelectInput";
import { getUserDeaprtment } from "../../../ticketsHeader/helpers/getUserDepartment";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { updateTicketReceiver } from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";

const ConversationUsers = ({ selectedTicket, departments, loadData }) => {
  const [isReciverUpdating, setIsReciverUpdating] = useState();
  const [isReciverSuccess, setIsReciverSuccess] = useState(true);
  const [selectedReciver, setSelectedReciver] = useState();

  const {
    commonData: { users },
    getActiveUsersOptions
  } = useCommonDataContext();
  const usersOptions = getActiveUsersOptions();

  const { makeRequest } = useRequestsContext();

  const filterReciverOptions = () => {
    const reciverDepartment = getUserDeaprtment(
      departments,
      selectedTicket.reciver
    );

    if (reciverDepartment) {
      return usersOptions.filter(
        (option) =>
          reciverDepartment.head_of_department === option.value ||
          reciverDepartment.users.includes(option.value)
      );
    }

    return usersOptions.filter(
      (option) =>
        !departments.some(
          (d) =>
            d.head_of_department === option.value ||
            d.users.includes(option.value)
        )
    );
  };

  const handleUpdateReceiver = async () => {
    setIsReciverUpdating(() => true);
    const response = await makeRequest(
      updateTicketReceiver.bind(
        null,
        selectedTicket._id,
        selectedReciver?.value
      )
    );

    if (response?.data) {
      setIsReciverSuccess(true);
      await loadData();
    }

    setIsReciverUpdating(() => false);
  };

  useEffect(() => {
    setSelectedReciver({
      label: getUserById(selectedTicket.reciver, users)?.username,
      value: getUserById(selectedTicket.reciver, users)?._id,
    });
    setIsReciverSuccess(true);
  }, [selectedTicket]);

  return (
    <div>
      <ConversationItem fontSize="16px">
        <strong>
          From:{" "}
          {selectedTicket.created_by
            ? getUserById(selectedTicket.created_by, users)?.username
            : "System"}
        </strong>
      </ConversationItem>
      <ConversationItem fontSize="12px">
        <span>TO: </span>
        {selectedTicket.reciver ? (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SelectInput
              showLabel={false}
              selectWidth={160}
              selectFontSize={12}
              margin="10px 0"
              setSelected={(option) => {
                setSelectedReciver(option);
                setIsReciverSuccess(false);
              }}
              selected={
                selectedReciver || {
                  label: getUserById(selectedTicket.reciver, users)?.username,
                  value: getUserById(selectedTicket.reciver, users)?._id,
                }
              }
              options={filterReciverOptions() || []}
            />
            <SuccessButton
              onClick={() => handleUpdateReceiver()}
              backgroundColor={isReciverSuccess ? Colors.green : ""}
              borderColor={isReciverSuccess ? Colors.green : Colors.darkGray2}
              color={isReciverSuccess ? "white" : Colors.darkGray2}
              className="animation-scale"
            >
              <i
                className={
                  isReciverUpdating
                    ? "fa-solid fa-spinner fa-spin"
                    : "fa fa-check "
                }
              />
            </SuccessButton>
          </div>
        ) : selectedTicket.department ? (
          departments.find((d) => d._id === selectedTicket.department)?.name
        ) : (
          "System"
        )}
      </ConversationItem>
    </div>
  );
};

export default ConversationUsers;
