import styled from "styled-components";

export const StarsWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.gap};
  margin-bottom: ${(props) => `${props.marginBottom}px`};
`;

export const StarItemWrapper = styled.div`
  position: relative;
`;

export const StarWrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StarCoverWrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  position: absolute;
  top: 0;
  height: 100%;
`;

export const StarImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StarImageCover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
`;
