export const getHeaders = (tickets) => [
  "No",
  "Kraj",
  "Miesiąc wystawienia",
  "Data sprzedaży",
  "Data wystawienia faktury",
  "Nr Faktury",
  "Korekta Faktury",
  "Nazwa Firmy Klienta na wystawionej fakturze",
  "Nazwa Klienta (osoba fizyczna w specyfikacji excel od pośrednika)",
  `Kwota (netto ${tickets[0]._order.currency})`,
  "Kwota VAT",
  `Kwota (brutto ${tickets[0]._order.currency})`,
];
