import React, { useEffect, useState } from "react";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import CustomBar from "@/common/charts/Bar";
import { mapArchivesToChart } from "./helpers/mapArchivesToChart";

const Archives = () => {
  const [data, setData] = useState();

  const {
    data: { archives },
  } = useAdminDashboardContext();

  const handleSetData = () => {
    const toSetData = {
      labels: ["markets"],
      datasets: mapArchivesToChart(archives),
    };

    setData(() => toSetData);
  };

  useEffect(() => {
    archives && handleSetData();
  }, [archives]);

  return (
    <Wrapper width="800">
      <TitleSection>
        <TopContentWrapper>
          <i className="fa fa-trash" />
          Archives
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {!!data ? <CustomBar data={data} /> : <CustomSkeleton />}
      </ChartWrapper>
    </Wrapper>
  );
};

export default Archives;
