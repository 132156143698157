import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h3`
  margin-bottom: 40px;
  color: ${Colors.red};
  max-width: 600px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;
