import React from "react";
import styled from "styled-components";
import { Colors } from "../../colors/colors";

const Button = styled.button`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  position: relative;

  font-size: 14px;
  font-weight: bold;
  padding: 5px;

  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  background-color: transparent;
  border-radius: 10px;
  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    color: white;
    border: 1px solid ${(props) => props.color};
    background-color: ${(props) => props.color};
    transform: scale(1.1);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid gray;
    background-color: ${Colors.lightGray};
    color: #666666;
    cursor: not-allowed;
    transform: scale(1);
  }

  &::after {
    content: "${(props) => props.tooltip}";
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    padding: 5px;
    background-color: ${Colors.darkGray2};
    color: #fff;
    border-radius: 5px;
    z-index: 99999;
  }

  &:hover::after {
    display: ${(props) => (props.tooltip ? "block" : "none")};
  }
`;

const CustomButtonAdd = ({
  text = "",
  onClick,
  defaultText,
  disabled = false,
  style,
  color = Colors.orange,
  tooltip = null,
}) => {
  return (
    <Button
      tooltip={tooltip}
      type="button"
      color={color}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      style={style}
    >
      {text.toLowerCase().replaceAll("add", "")}
      {text.toLowerCase().includes("add") && <i className="fa fa-plus" />}
      {defaultText}
    </Button>
  );
};

export default CustomButtonAdd;
