import React from "react";
import { roundResult } from "../helpers/roundResult";

const MailTable = ({ data }) => {
  return (
    <table className="styled-table-dashboard">
      <thead>
        <tr>
          <th>Category</th>
          <th>Requested</th>
          <th>Delivered</th>
          <th>Open</th>
          <th>Clicked</th>
          <th>Bounces</th>
          <th>Spam</th>
        </tr>
      </thead>
      <tbody>
        {data.map((d) => (
          <tr key={d.cateogry}>
            <td>{d.category}</td>
            <td>{d.requested} </td>
            <td>
              {d.delivered} (
              {roundResult(d.delivered / Math.max(d.requested, 1))}%)
            </td>
            <td>
              {d.open} ({roundResult(d.open / Math.max(d.delivered, 1))}
              %)
            </td>
            <td>
              {d.click} ({roundResult(d.click / Math.max(d.delivered, 1))}%)
            </td>
            <td>
              {d.bounces} ({roundResult(d.bounces / Math.max(d.delivered, 1))}%)
            </td>
            <td>
              {d.spam} ({roundResult(d.spam / Math.max(d.delivered, 1))}
              %)
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MailTable;
