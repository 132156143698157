export const TEMPLATES_OPTIONS = [
  {
    value: "sales_0%",
    label: "Sales 0%",
  },
  {
    value: "correction_0%",
    label: "Correction 0%",
  },
  {
    value: "sales_zw",
    label: "Sales zw",
  },
  {
    value: "correction_zw",
    label: "Correction zw",
  },
];
