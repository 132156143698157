import React from "react";
import { useNavigate } from "react-router-dom";

const EditItem = ({ link ,width =200}) => {
    const navigate = useNavigate()

  return (
      <i className="fa fa-edit" style={{ fontSize: "14px", color: "black", cursor: "pointer" , width: `${width}px`}} onClick={() => navigate(link)}></i>
  );
};

export default EditItem;
