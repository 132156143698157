import Api from "../api";
import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const getCompanyDataByVat = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/invoices/company-data-by-vat?${queryString.stringify(
        cleanObject(payload),
        { arrayFormat: "bracket" }
      )}`
    );
    return { data: data.data };
  });

export const getCompanyDataByNumber = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/invoices/company-data-by-number?${queryString.stringify(
        cleanObject(payload),
        { arrayFormat: "bracket" }
      )}`
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
