import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 12px;
	justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	gap: 16px;
	width: 98%;
`;

export const TableWrapper = styled.div`
	height: calc(100vh - 215px);
	overflow: auto;
	display: flex;
`;

export const TableCell = styled.td`
	padding: 4px !important;
`;

export const FilterItem = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
`;
