import React, { useEffect, useRef, useState } from "react";
import { ChartWrapper, TitleSection, Wrapper } from "../shared.styles";
import { getMarketWppkReportForConsultant } from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import DailyTable from "./components/DailyTable";
import moment from "moment";
import styled from "styled-components";
import CustomSkeleton from "@/common/components/CustomSkeleton";

const WrapperCustom = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 450px;
  overflow: scroll;
`;

const CallingStatsWPPK = () => {
  const [data, setData] = useState();

  const {
    commonData: { queues, users, markets },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setData(null);

    const allConsultants = queues.reduce(
      (prev, next) => [...prev, ...next.consultants],
      []
    );

    const payload = {};
    payload.ids = allConsultants;
    payload.from = moment().startOf("day");
    payload.to = moment().endOf("day");
    payload.weights = false;
    payload.type = ["inbound", "outbound"];

    const { data } = await makeRequest(
      getMarketWppkReportForConsultant.bind(null, payload)
    );

    const result = {};

    Object.entries(data).forEach(([key, value]) => {
      value._id = key;

      result[value.market] = result[value.market]
        ? [...result[value.market], value]
        : [value];
    });

    setData(result);
  };

  useEffect(() => {
    if (queues?.length > 0 && !data) handleLoadData();
  }, [queues]);

  return (
    <Wrapper width={600}>
      <TitleSection>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <i className="fa-brands fa-usps" />
          <div style={{ minWidth: "100px" }}>Daily </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />
        </div>
      </TitleSection>
      <WrapperCustom>
        {data ? (
          <DailyTable data={data} consultants={users} markets={markets} />
        ) : (
          <CustomSkeleton width={520} count={18} height={4} />
        )}
      </WrapperCustom>
    </Wrapper>
  );
};

export default CallingStatsWPPK;
