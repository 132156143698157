import React, { useRef, useState } from "react";
import Input from "../../../../../common/components/Input";
import PopUp from "../../../../../common/components/PopUp";

const AddRegion = ({
  setAddRegion,
  regionLabelRef,
  handleAddRegion,
  selectedRegionEdit,
  codes,
  setCodes,
}) => {
  if (!codes && selectedRegionEdit?.codes) {
    setCodes(() => selectedRegionEdit?.codes);
  }
  const from = useRef();
  const to = useRef();

  const addCode = (e) => {
    e && e.preventDefault();

    if (!from.current?.value || !to.current?.value) {
      return;
    }

    const ids = {
      _id: (codes?.length || 0) + 1,
      from: parseInt(from.current.value),
      to: parseInt(to.current.value),
    };

    if (codes) {
      setCodes(() => [...codes, ids]);
    } else {
      setCodes(() => [ids]);
    }

    from.current.value = "";
    to.current.value = "";
  };

  const removeCode = (_id) => {
    const filtredRegions = codes.filter((value) => value._id !== _id);

    const validatedRegions = filtredRegions.map((value, i) => {
      value._id = i + 1;
      return value;
    });

    setCodes(() => validatedRegions);
  };

  return (
    <PopUp setShow={setAddRegion}>
      <form onSubmit={(e) => handleAddRegion(e)}>
        <Input
          inputRef={regionLabelRef}
          name="Region name"
          width={160}
          required
          value={selectedRegionEdit?.label}
        ></Input>
        <div>
          <Input
            name="Code from"
            inputRef={from}
            width={160}
            inputWidth={180}
            placeholder="First 3 chars"
            type="number"
          ></Input>
          <Input
            inputRef={to}
            name="Code to"
            width={160}
            inputWidth={180}
            placeholder="First 3 chars"
            type="number"
          ></Input>
        </div>
        {codes?.length > 0 && (
          <table className="styled-table" style={{ margin: "40px auto" }}>
            <thead>
              <tr>
                <th>Id</th>
                <th>From</th>
                <th>To</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="queue">
              {codes.map((value) => (
                <tr key={value._id}>
                  <td>{value._id}</td>
                  <td>{value.from}</td>
                  <td>{value.to}</td>
                  <td>
                    <i
                      className="fa fa-trash animation-scale"
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => removeCode(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "-20px",
            gap: "30px",
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={(e) => addCode(e)}
          >
            Add Code
          </button>
          <button className="btn btn-warning" type="submit">
            Save
          </button>
        </div>
      </form>
    </PopUp>
  );
};

export default AddRegion;
