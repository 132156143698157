import React, { useState } from "react";
import { Wrapper } from "./ValidInvoicesReport.styled";
import ValidInvoicesReportSearchBar from "./components/validInvoicesSearchBar/ValidInvoicesReportSearchBar";
import ValidInvoicesReportTable from "./components/validInvoicesReportTable/ValidInvoicesReportTable";

const ValidInvoicesReport = () => {
  const [tickets, setTickets] = useState();
  const [visibleTickets, setVisibleTickets] = useState();

  return (
    <Wrapper>
      <ValidInvoicesReportSearchBar
        visibleTickets={visibleTickets}
        setVisibleTickets={setVisibleTickets}
        tickets={tickets}
        setTickets={setTickets}
      />
      {!!visibleTickets?.length && (
        <ValidInvoicesReportTable tickets={visibleTickets} />
      )}
    </Wrapper>
  );
};

export default ValidInvoicesReport;
