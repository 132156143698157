import { findMarketByContractType } from "./findMarketByContractType";
import { getWorkingDaysForMonth } from "./getWorkingDaysForMonth";

export const getUserGoal = ({
  gamificationUserStats,
  user,
  markets,
  queues,
}) => {
  const userMarket = findMarketByContractType(markets, user);
  const workingDays = getWorkingDaysForMonth(
    userMarket,
    gamificationUserStats.month
  );
  const constantMultiplier = user.calling_system
    ? gamificationUserStats.calling_mode_constant
    : gamificationUserStats.normal_mode_constant;

  const contractHours = user.contract_hours;

  return workingDays * constantMultiplier * contractHours;
};
