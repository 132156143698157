export const MAIL_TEMPLATE_TYPES = [
  {
    label: "ORDER_SENT_CONFIRIMATION_SK",
    value: "d-f63f53c418f74dd79c08e096b30e0ff9",
  },
  {
    label: "ORDER_CONFIRMATION_SK",
    value: "d-ae14417366be41cda9716db94f6c9f36",
  },
  {
    label: "MORE_INFO_SK",
    value: "d-5586e7dba8a3469897160e173a37fafc",
  },
  {
    label: "LANGUO_ORDER_SENT_CONFIRIMATION_CZ",
    value: "d-abd99dd912b74e41805ec73dbcf28917",
  },
  {
    label: "LANGUO_ORDER_CONFIRMATION_CZ",
    value: "d-8717838c7a054950ada05012087c4cc6",
  },
  {
    label: "LANGUO_MORE_INFO_CZ",
    value: "d-d3b7ba213bb34c9c9de2f4b710524741",
  },
  {
    label: "LANGUO_MORE_INFO_CZ_TEST_1",
    value: "d-2777ed30fd1148ceb6e92e4d39bb7b75",
  },
  {
    label: "LANGUO_MORE_INFO_CZ_TEST_2",
    value: "d-a6461c93458a48fcaedda803d1139ef2",
  },
  {
    label: "POLYGLOT_MORE_INFO_SK_TEST_1",
    value: "d-a61be90e7cd74d66be799ba93d35b896",
  },
  {
    label: "POLYGLOT_MORE_INFO_SK_TEST_2",
    value: "d-d26d3051fd7745fb9ea8c7aa889918cd",
  },
  {
    label: "SMARTADO_ORDER_SENT_CONFIRIMATION_RO",
    value: "d-f78c016c7dfd4bdd80e041afc9481479",
  },
  {
    label: "SMARTADO_ORDER_CONFIRMATION_RO",
    value: "d-fd56ad7ca9f3483db0bafeb3a4ce430c",
  },
  {
    label: "SMARTADO_MORE_INFO",
    value: "d-54bfd979d2884da29ec8fdefe5a77fc5",
  },
  {
    label: "SMARTADO_MORE_INFO_RO_TEST_1",
    value: "d-9695c8ae60b547eaa14bc80f18e8ed9f",
  },
  {
    label: "SMARTADO_MORE_INFO_RO_TEST_2",
    value: "d-6f896fe25ca045e79b24a5e7a560b056",
  },
  {
    label: "POLYGLOT_SK_MAIL_UP_SALE",
    value: "d-d65563526b554289873e5ce68e699841",
  },
  {
    label: "LANGUO_CZ_MAIL_UP_SALE",
    value: "d-a2fc73cc52704898b26a4085c38a785b",
  },
  {
    label: "SMARTADO_RO_MAIL_UP_SALE",
    value: "d-93627daa42d2445e832d01e1b1203f60",
  },
  {
    label: "POLYGLOT_SK_MAIL_RESTURN",
    value: "d-92bb08db855748239a33e584b4d25adf",
  },
  {
    label: "LANGUO_CZ_MAIL_RETURN",
    value: "d-5a804c75a66347cea1ee627f0dab0950",
  },
  {
    label: "SMARTADO_RO_MAIL_RETURN",
    value: "d-b136922bd4c24a169d4d8d61b63cca92",
  },
  {
    label: "LANGUO_CZ_MORE_INFO_PROMO",
    value: "d-fb3e5bc5ff7e497290e718eb3302cd47",
  },
  {
    label: "LANGUO_MORE_INFO_NO_PROMO",
    value: "d-4e2aa9db555542aba335258f8f3d8a64",
  },
  {
    label: "POLYGLOT_OUT_DATED",
    value: "d-45d230a5f80c44eebeede7fd8f174e54",
  },
];

export const MAIL_TEMPLATE_BOUNCE_TYPES = [
  {
    label: "Email Bounced",
    value: "bounce",
  },
  {
    label: "Email Blocked",
    value: "blocked",
  },
];
