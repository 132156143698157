import { getPercent } from "@/common/functions/getPercent";
import { findUserById } from "../../../helpers/findUserById";
import { getStars } from "@/common/functions/getStars";
import { getUserGoal } from "@/common/functions/getUserGoal";

export const sortMarketStats = ({
  marketGamification,
  users,
  markets,
  queues,
}) =>
  marketGamification
    .filter((e) => e !== null)
    .sort((consultantDataA, consultantDataB) => {
      const userA = findUserById(consultantDataA.consultant, users);
      const userB = findUserById(consultantDataB.consultant, users);

      if (!userA || !userB) return false;

      const starsA = getStars({
        user: userA,
        gamificationUserStats: consultantDataA,
        markets,
        queues,
      });
      const starsB = getStars({
        user: userB,
        gamificationUserStats: consultantDataB,
        markets,
        queues,
      });

      if (starsA === starsB) {
        const goalA = getUserGoal({
          gamificationUserStats: consultantDataA,
          user: userA,
          markets,
          queues,
        });
        const goalB = getUserGoal({
          gamificationUserStats: consultantDataB,
          user: userB,
          markets,
          queues,
        });

        const percentOfGoalA = getPercent(consultantDataA.total_amount, goalA);
        const percentOfGoalB = getPercent(consultantDataB.total_amount, goalB);

        return percentOfGoalB - percentOfGoalA;
      }

      return starsB - starsA;
    });
