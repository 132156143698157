import moment from "moment";

export const sortByInvoiceNumbers = (orders) =>
  orders
    .sort((orderA, orderB) => {
      const invoiceNumberA = orderA._ticket.invoice.invoice_number.trim();
      const invoiceNumberB = orderB._ticket.invoice.invoice_number.trim();

      return invoiceNumberA.localeCompare(invoiceNumberB);
    })
    .sort((orderA, orderB) => {
      const invoiceNumberA = orderA._ticket.invoice.invoice_number
        .trim()
        .slice(2, -2);
      const invoiceNumberB = orderB._ticket.invoice.invoice_number
        .trim()
        .slice(2, -2);

      const monthA = invoiceNumberA.slice(2, 4);
      const yearA = invoiceNumberA.slice(-4);
      const monthB = invoiceNumberB.slice(2, 4);
      const yearB = invoiceNumberB.slice(-4);

      const dateA = moment(monthA + "-" + yearA, "MM-YYYY");
      const dateB = moment(monthB + "-" + yearB, "MM-YYYY");

      return moment(dateA).diff(dateB);
    });
