import { findUserById } from "@/common/functions/findUserById";
import { calcSalaryForOne } from "../../../helpers/calcSalaryForOne";
import { calcSalary } from "../../../helpers/calcSalary";

export const getTeamRaws = (consultantResults, users) =>
  consultantResults?.data
  ?.find(d => d.is_final)?.team?.map((teamItem) => {
    const user = findUserById(teamItem.consultant, users);

    if (!user) return null;

    return teamItem.data?.map((res, i) => (
      <tr key={`${user._id}_${i}`}>
        <td>{user.username}</td>
        <td>
          {res.price} {teamItem.currency}
        </td>
        <td>
          {Math.round(calcSalaryForOne(res, teamItem) * 100) / 100} {teamItem.currency}
        </td>
        <td>{res.orders_count}</td>
        <td>{res.delivered_count}</td>
        <td>
          {Math.round(calcSalary(
            res.big_items_count,
            res.small_items_count,
            teamItem
          ) * 100) / 100} {teamItem.currency}
        </td>
      </tr>
    )
    )
  }
  );
  