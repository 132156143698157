import React, { useEffect, useRef, useState } from "react";
import PopUp from "./PopUp";
import SaveButton from "./SaveButton";
import SelectInput from "./SelectInput";
import { useRequestsContext } from "../hooks/requestHook";
import { chnageUserSettings } from "../../API/repositories/user";
import userManager from "../../API/userManager";
import Input from "./Input";
import translationManager from "../../API/translationManager";
import { LANGUAGES } from "../constants/currency";

const UserSettings = ({ setShowSettings }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const passwordRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSaveButton = async (e) => {
    e.preventDefault();

    const payload = new Object();

    payload.password = passwordRef.current ? passwordRef.current.value : null;
    payload.language = selectedLanguage ? selectedLanguage.value : null;

    const response = await makeRequest(
      chnageUserSettings.bind(null, userManager.getUser().id, payload)
    );

    if (response.data) {
      if (payload.language) {
        await translationManager.getLanguage(payload.language);
        window.location.reload();
      }
    }

    setSelectedLanguage(() => null);
    passwordRef.current.value = "";
  };

  const [translation, setTranslation] = useState({
    text1: "Language",
    text2: "Password",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <PopUp setShow={setShowSettings}>
      <div>
        <div>
          <SelectInput
            name={translation["text1"]}
            width={100}
            options={LANGUAGES}
            selected={selectedLanguage}
            setSelected={setSelectedLanguage}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Input
            inputRef={passwordRef}
            name={translation["text2"]}
            type="password"
            width={100}
          />
        </div>
        <div style={{ marginTop: "100px" }}>
          <SaveButton onClick={handleSaveButton} />
        </div>
      </div>
    </PopUp>
  );
};

export default UserSettings;
