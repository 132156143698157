import { Colors } from "../../../../../common/colors/colors";
import { IconsWrapper } from "../styles/Test.styles";

export const handleReturnOptions = (ans, removeFunk, editFunk) => {
  return (
    <IconsWrapper>
      <i
        className="fa fa-remove animation-scale"
        style={{ color: Colors.red, cursor: "pointer" }}
        onClick={() => removeFunk(ans)}
      />
      <i
        className="fa fa-edit animation-scale"
        style={{ color: Colors.yellow, cursor: "pointer" }}
        onClick={() => editFunk(ans)}
      />
    </IconsWrapper>
  );
};

export const handleReturnCorrectIcon = (bool) => {
  if (bool) {
    return <i className="fa fa-check" style={{ color: Colors.green }} />;
  }

  return <i className="fa fa-remove" style={{ color: Colors.red }} />;
};
