import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../../common/colors/colors";
import styled from "styled-components";
import userManager from "@/API/userManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findUserById } from "@/common/functions/findUserById";

const Wrapper = styled.div`
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 20px;
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.color};
    color: white;
  }
`;

const VONAGE_CALLERS = ['lt'];

const StartCalling = ({ translation }) => {
  const navigate = useNavigate();
  const userId = userManager.getUser().id;

  const { 
    commonData: { queues, markets, users }, 
    queryFunctions: { refetch } 
  } = useCommonDataContext();

  const callingLink = useMemo(() => {
    if (!queues?.length || !markets?.length || !users?.length) {
      refetch();
    }

    const user = findUserById(userId, users);
  
    let userQueue = queues.find((queue) => queue._id === user?.preferred_queue) || queues.filter(queue => queue.consultants.includes(userId))[0];

    if (!userQueue) {
      return null;
    }

    const userMarketShort = markets.find((market) => market._id === userQueue?.market);

    if (VONAGE_CALLERS.includes(userMarketShort?.short)) {
      return "/consultant/calling/vonage";
    }

    return "/consultant/calling";
  }, [queues, markets, users, userId, userManager.getUser()])

  return (
    <Wrapper
      color={Colors.green}
      className="animation-scale"
      onClick={() => navigate(callingLink)}
    >
      <i className="fa fa-phone-volume" style={{ fontSize: "30px" }} />
      <strong>{translation["start_calling"]}</strong>
    </Wrapper>
  );
};

export default StartCalling;
