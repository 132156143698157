import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const TicketsWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 80, 114, 0.78);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const TicketsCloser = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  z-index: 2;
  margin: 10px;
  display: grid;
  grid-template-columns: 35% 1fr;
  grid-template-rows: max-content minmax(10%, 1fr) minmax(10%, 1fr) 40px 5% 150px;
  height: 95vh;
  width: 95vw;
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid ${Colors.lightGray};
`;

export const Title = styled.h3`
  color: white;
`;

export const AddIcon = styled.i`
  cursor: pointer;
  font-size: 18px;
  color: white;
`;

export const Textarea = styled.textarea`
  width: 70%;
`;

export const OldTicketsTitleWrapper = styled.div`
  grid-row: 4 / 5;
`;

export const OldTicketsTitle = styled.div`
  position: sticky;
  padding: 10px;
  top: 0;
  padding-left: 20px;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background-color: ${Colors.darkBlue};
  color: white;
  font-weight: bold;
`;

export const SubTitle = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 12px;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
