import React, { useMemo, useRef, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import { getOrderLangPairClaimReport } from "../../../API/repositories/reports";
import Loading from "../../../common/components/Loading";
import MessageQueue, { useMessageQueue } from "../../../common/messageProvider";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SentOrdersSearchBar from "./components/sentOrdersSearchBar/SentOrdersSearchBar";
import SentOrdersTable from "./components/sentOrdersTable/SentOrdersTable";
import { generateRaws, generateRawsCSV } from "./helpers/generateRaws";

const LangPairClaimReport = () => {
  const dateFromRef = useRef();
  const dateToRef = useRef();
  const onlyRef = useRef();
  const monthlyRef = useRef();

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedLangPair, setSelectedLangPair] = useState([]);
  const [selectedType, setSelectedType] = useState();

  const [data, setData] = useState();

  const results = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateRaws(
      data,
      selectedType,
      onlyRef.current.checked,
      selectedLangPair,
      monthlyRef.current.checked
    );
  }, [data]);

  const csvResults = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateRawsCSV(
      data,
      selectedType,
      onlyRef.current.checked,
      selectedLangPair,
      monthlyRef.current.checked
    );
  }, [data]);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleSearch = async () => {
    const payload = Object();

    if (!selectedProduct || !selectedType) {
      addMessage("Please pick all required fileds", "error");

      return;
    }

    payload.from =
      dateFromRef.current?.value || moment().startOf("day").toISOString();
    payload.to =
      dateToRef.current?.value || moment().endOf("day").toISOString();
    payload.product = selectedProduct.value._id;
    payload.langPairs = selectedLangPair?.map((data) => data.value);

    payload.from = moment(payload.from).startOf("day").toISOString();
    payload.to = moment(payload.to).endOf("day").toISOString();

    const response = await makeRequest(
      getOrderLangPairClaimReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getOrderLangPairClaimReport) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <SentOrdersSearchBar
        dateFromRef={dateFromRef}
        dateToRef={dateToRef}
        handleSearch={handleSearch}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedLangPair={selectedLangPair}
        setSelectedLangPair={setSelectedLangPair}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        results={csvResults}
        onlyRef={onlyRef}
        monthlyRef={monthlyRef}
      />
      {!!data?.length && (
        <SentOrdersTable data={results} monthly={monthlyRef.current.checked} />
      )}
    </WrapperWithScroll>
  );
};

export default LangPairClaimReport;
