import Api from "../api";
import { errorHandler } from "../common";

const { tokenManager, axios: api } = Api;
const queryString = require("query-string");

export const getContactWithFilters = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/contact/get_contact_with_filters/?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

export const markContactAsPostalCodePromotion = (contactId, decision) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/contact/mark-contact-as-postal-code-promotion/${contactId}`,
      { decision }
    );
    return { data: data.data };
  });

export const getSearchedContactWithOrders = (serachData) =>
  errorHandler(async () => {
    const data = await api.get(`/contact/search/${serachData}`);
    return { data: data.data };
  });

export const getContactWithOrders = (id) =>
  errorHandler(async () => {
    const data = await api.get(`/contact/with_order/${id}`);
    return { data: data.data };
  });

export const markAsRodoDecision = (id) =>
  errorHandler(async () => {
    const data = await api.patch(`/contact/mark-as-rodo-decision/${id}`);
    return { data: data.data };
  });

export const updateContactFromOutbound = (id, contact) =>
  errorHandler(async () => {
    const data = await api.patch(`/contact/outbound/${id}`, contact);
    return { data: data.data };
  });

export const createContact = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/contact`, payload);
    return { data: data.data };
  });

export const saveConsultantContact = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/contact/consultant/${id}`, payload);
    return { data: data.data };
  });

export const updateContact = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/contact/${id}`, payload);
    return { data: data.data };
  });

export const getCallHistory = (contactId) =>
  errorHandler(async () => {
    const data = await api.get(`/contact/history/${contactId}`);

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
