export const DEFAULT_LANGUAGE = "en";
export const LANGUAGES = {
  RO: "ro",
  PL: "pl",
  CS: "cs",
  HU: "hu",
  SK: "sk",
  EN: "en",
  LT: "lt",
  HR: "hr",
  BG: "bg",
  LV: "lv",
};
