import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  gap: 30px;
`;

export const SettingsIcon = styled.i`
  cursor: pointer;
  color: ${Colors.darkBlue};
  font-size: 36px;
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const CircleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px;
  position: absolute;
  right: 0;
  top: 40px;
`;

export const SpaceBetween = styled.div`
  margin: 0 30px;
  display: flex;
  flex-direction: column;
`;
