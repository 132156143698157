import React from "react";
import { Colors } from "../colors/colors";
const ExcelJS = require("exceljs");

const ExportExcelAdvance = ({ data = {}, color = Colors.darkBlue }) => {
  const createExcelWithHeaders = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet 1");

    sheet.defaultRowHeight = 20;

    const addHeaders = (sheet, headers) => {
      headers.forEach((rowConfig) => {
        const { startRow, endRow, startCol, endCol, name, width } = rowConfig;
        if (startRow !== endRow || startCol !== endCol) {
          sheet.mergeCells(startRow, startCol, endRow, endCol);
        }

        const cell = sheet.getCell(startRow, startCol);
        cell.value = name;
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFE0" },
        };
        cell.font = {
          size: 12,
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };

        if (width) {
          sheet.getColumn(startCol).width = width;
        }
      });
    };

    addHeaders(sheet, data.headers);

    data?.excelData?.forEach((data, i) => {
      const row = sheet.addRow(data);
      row.height = 25;

      row.alignment = { vertical: "middle", horizontal: "center" };
    });

    workbook.xlsx.writeBuffer().then((currentData) => {
      const blob = new Blob([currentData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${data.fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div onClick={() => createExcelWithHeaders()}>
      <i
        className="animation-scale fa-solid fa-file-excel"
        style={{
          fontSize: `26px`,
          cursor: "pointer",
          color: color,
        }}
      />
    </div>
  );
};

export default ExportExcelAdvance;
