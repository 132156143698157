import { createDataSets } from "./createDataSets";

export const handleMapData = (selectedMarket, data, setChartData, users) => {
  setChartData(() => null);

  const labels = selectedMarket.value.consultants.map(
    (c) => users.find((u) => u._id === c)?.username
  );

  setChartData(() => ({
    labels: labels,
    datasets: createDataSets(selectedMarket, data),
  }));
};
