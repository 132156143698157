import React, { useEffect, useRef, useState } from "react";
import { getVoipUserWorktime } from "@/API/repositories/reports";
import { Colors } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { CONSULTANT_ROLES } from "@/components/loginPage/LoginForm/loginForm";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import WorkTimeTable from "./components/workTimeTable/WorkTimeTable";
import { getCSVDATA } from "./helpers/getCsvData";
import { calcData } from "./helpers/calcData";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { getTodayName } from "@/common/functions/getTodayName";
import { PageWrapper } from "@/common/styles/Wrappers";
import { SearchBarRaw } from "@/common/styles/SearchBars";
import { getToday } from "@/common/functions/getToday";
import { formatDateRef } from "@/common/functions/formatDateRef";

const VoipWorkTime = () => {
  const [selctedUsers, setSelectedUsers] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [usersOptions, setUsersOptions] = useState();
  const [data, setData] = useState();
  const [showCSV, setShowCSV] = useState(false);
  const fromRef = useRef();
  const toRef = useRef();
  const today = getToday();

  const {
    options: { queuesOptions },
    filterUsersByRoles,
    commonData: { users, queues },
  } = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e && e.preventDefault();
    setShowCSV(() => false);

    if (selctedUsers.length === 0) {
      return;
    }

    const payload = {
      to: formatDateRef(toRef, today, "start"),
      from: formatDateRef(fromRef, today, "end"),
      consultants: selctedUsers.map((s) => s.value),
    };

    const response = await makeRequest(getVoipUserWorktime.bind(null, payload));

    if (response.data) {
      setData(() => calcData(response.data, queues));
    }

    setShowCSV(() => true);
  };

  const handleSelectedQueues = (queue) => {
    let selectedUsersIds = [];

    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedUsers(() =>
      usersOptions.filter((c) => selectedUsersIds.includes(c.value))
    );

    setSelectedQueues(() => queue);
  };

  const loadData = async (e) => {
    e && e.preventDefault();

    setUsersOptions(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  useEffect(() => {
    loadData();
  }, [users]);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getVoipUserWorktime) && <Loading />}
      <SearchBarRaw>
        <div style={{ minWidth: "400px" }}>
          <SelectInput
            showLabel={false}
            selected={selctedUsers}
            setSelected={setSelectedUsers}
            options={usersOptions}
            width={80}
            selectWidth={360}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select user..."
          />
        </div>
        <SelectInput
          showLabel={false}
          selected={selectedQueues}
          setSelected={handleSelectedQueues}
          options={queuesOptions}
          width={80}
          selectWidth={180}
          color={Colors.darkBlue}
          multiple={true}
          placeholder="Select market..."
        />
        <div>
          <Input
            name="From"
            type="date"
            inputWidth={160}
            color={Colors.darkBlue}
            width={80}
            inputRef={fromRef}
            value={today}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="date"
            width={80}
            inputWidth={160}
            color={Colors.darkBlue}
            value={today}
          />
        </div>
        <div>
          <CustomButtonAdd
            className="btn btn-warning"
            defaultText="Search"
            onClick={(e) => handleSearch(e)}
          />
        </div>
        {showCSV && (
          <div>
            <CSV
              header={["date", "queue", "consultant", "status", "from", "to"]}
              data={getCSVDATA(data)}
              filename={`WORKING_VOIP_CSV_${getTodayName(fromRef, toRef)}.csv`}
            />
          </div>
        )}
      </SearchBarRaw>
      {data && <WorkTimeTable data={data} />}
    </PageWrapper>
  );
};

export default VoipWorkTime;
