import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 30px;
`;

export const SearchBar = styled.div`
  padding: 10px;
  color: ${Colors.darkBlue};
  border: 1px dashed ${Colors.darkBlue};
  min-width: 1000px;
  border-radius: 15px;
  margin-bottom: 30px;
`;

export const SearchItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const SearchButtons = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
`;

export const Tbody = styled.tbody`
  td {
    padding: 10px;
    text-align: center;
  }
`;

export const Flex = styled.div`
  display: flex;
`;
