import React from "react";
import Table from "@/common/components/Table";
import { HEADERS } from "../../constants/headers";
import { getRaws } from "./helpers/getRaws";

const CostPerLeadTable = ({ accountsSpend }) => {
  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(accountsSpend)}
    />
  );
};

export default CostPerLeadTable;
