import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 40px 0;
`;
