import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import Ping from "@/common/components/Ping";
import SetPreferredQueue from "@/common/components/setPreferredQueue/SetPreferredQueue";
import Icon from "@/common/components/Icon";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const NumberOfTickets = styled.p`
  font-weight: bold;
  color: ${Colors.red};
  margin-top: -10px;
  font-size: 16px;
  margin-left: -25px;
`;

const SECONDS_BLOCK = 15;
const ConsultantStatusButtons = ({
  handleEnd,
  handleBreak,
  handleStart,
  handleTickets,
  ticketsNumber,
  onQueueChange,
}) => {
  const [disableStartButton, setDisableButtonStart] = useState(false);
  const [showSetPreferredQueue, setShowSetPreferredQueue] = useState(false);

  const { commonData: { queues }} = useCommonDataContext();

  const handleOffButtonForFewSeconds = (e) => {
    e.preventDefault();
    if (disableStartButton) return;

    setDisableButtonStart(() => true);

    setTimeout(() => {
      setDisableButtonStart(() => false);
    }, SECONDS_BLOCK * 1000);

    handleStart(e);
  };

  const hasMultipleQueues = queues?.filter(queue => queue.consultants?.includes(userManager.getUser().id))?.length > 1;

  return (
    <Wrapper>
      {hasMultipleQueues && <Icon size={36} onClick={() => setShowSetPreferredQueue(true)} name="fa fa-arrow-right-arrow-left" />}
      <i
        className="fa-solid fa-bell animation-scale"
        style={{
          cursor: "pointer",
          fontSize: "34px",
          color: ticketsNumber > 0 ? Colors.red : Colors.darkBlue,
        }}
        onClick={handleTickets}
      ></i>
      {ticketsNumber > 0 && <NumberOfTickets>{ticketsNumber}</NumberOfTickets>}
      <i
        className="fa-solid fa-house animation-scale"
        style={{ cursor: "pointer", fontSize: "34px", color: Colors.darkBlue }}
        onClick={handleEnd}
      ></i>
      <i
        className="fa-solid fa-play animation-scale"
        style={{
          cursor: disableStartButton ? "not-allowed" : "pointer",
          fontSize: "36px",
          color: disableStartButton ? "grey" : Colors.darkBlue,
        }}
        onClick={handleOffButtonForFewSeconds}
      ></i>
      <Ping display={false} />
      {showSetPreferredQueue && (
        <SetPreferredQueue 
          setShow={setShowSetPreferredQueue} 
          onQueueChange={onQueueChange} 
        />
      )}
    </Wrapper>
  );
};

export default ConsultantStatusButtons;
