import { CONVERSION_NAMES } from "../constants/accountsConversion";

export const accountsDataReducer = (state, action) => {
  switch (action.type) {
		case CONVERSION_NAMES.PREFIX:
			return {
				...state,
				[CONVERSION_NAMES.PREFIX]: action.payload
			}
		case CONVERSION_NAMES.ACCOUNT:
			return {
				...state,
				[CONVERSION_NAMES.ACCOUNT]: action.payload
			}
		case CONVERSION_NAMES.BANK_CODE:
			return {
				...state,
				[CONVERSION_NAMES.BANK_CODE]: action.payload
			}
		default:
			return state;
	}
};