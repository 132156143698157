import Icon from "@/common/components/Icon";

export const getRaws = (companies, setCompanies) =>
  companies.map((company, i) => (
    <tr key={company._id}>
      <td>{i + 1}</td>
      <td>{company.name}</td>
      <td>{company.vat}</td>
      <td>{company.fakturownia_key.label}</td>
      <td>
        <Icon onClick={() => setCompanies(company)} name="fa fa-edit" />
      </td>
    </tr>
  ));
