import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import userManager from "../../../API/userManager";
import Ping from "../../../common/components/Ping";
import SearchContact from "../../../common/components/SearchContact";
import { Colors } from "../../../common/colors/colors";
import translationManager from "../../../API/translationManager";
import Loguout from "./Loguout";
import Tickets from "./ticket/Tickets";
import { useNavigate } from "react-router-dom";
import TodoView from "./todoView/TodoView";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import Icon from "@/common/components/Icon";
import SetPreferredQueue from "@/common/components/setPreferredQueue/SetPreferredQueue";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 30px;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
`;

const FullNameWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const UserName = styled.p`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
`;

const InfoBar = () => {
  const navigate = useNavigate();

  const [showSetPreferredQueue, setShowSetPreferredQueue] = useState(false);
  const [translation, setTranslation] = useState({
    sayHiToUser: "Hello",
    ImportNewContact: "Import new contact",
    CurrentContact: "Import current contacts",
    Grade: "Grade",
    CurrentMonth: moment().startOf("month").format("MMMM"),
    LastMonth: moment().subtract(1, "month").startOf("month").format("MMMM"),
    CreateContact: "Create contact",
    ImportInboundContact: "Import incomming contact",
    PlaceholderInbound: "type phone number without '+' ",
    Your_shifts: "Your shifts",
    Your_emails: "Your emails",
    shift_date: "shift date",
    full_name: "full name",
    email: "email",
    number: "number",
    should_call: "should call",
    actions: "actions",
    mails: "Mails",
    shifts: "Shifts",
    sent_date: "sent date",
    downloaded_contact_text: "Please end your contact",
    inboundCallText: "Import current inbound contact",
    blackboard: "Informations",
    yourTickets: "Your Tickets",
    tickets: "Tickets",
    file: "File",
    topic: "Topic",
    ticket_question: "Ticket question",
    receiver: "Receiver",
    message: "Message",
    create_ticket: "Create ticket",
    type_message: "Type message",
    old_tickets: "Old tickets",
    phone_number_for_courier: "Phone for courier",
  });

  const { commonData: { queues }} = useCommonDataContext();

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const userRole = userManager.getUser().role;

  const hasMultipleQueues = queues?.filter(queue => queue.consultants?.includes(userManager.getUser().id))?.length > 1;

  return (
    <Wrapper>
      <div>
        <Title>
          {translation.sayHiToUser} {userManager.getUser()?.name}!
        </Title>
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          <b style={{ color: Colors.darkBlue }}>{moment().format("dddd")}</b>
          {moment().format(", MMMM YYYY")}
        </span>
      </div>
      <SearchContact />
      <FullNameWrapper>
        <UserName
          onClick={() => navigate("/consultant/profile")}
          style={{ cursor: "pointer" }}
        >
          {userManager.getUser()?.username}
        </UserName>
        <Ping />
        {hasMultipleQueues && <Icon onClick={() => setShowSetPreferredQueue(true)} name="fa fa-arrow-right-arrow-left" />}
        <i
          className="fa fa-user-circle"
          style={{
            color: Colors.darkBlue,
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/consultant/profile")}
        />
        <Tickets translation={translation} />
        {CONSULTANT_MANAGER_ROLES.includes(userRole) && <TodoView />}
        {showSetPreferredQueue && (<SetPreferredQueue setShow={setShowSetPreferredQueue} />)}
        <Loguout />
      </FullNameWrapper>
    </Wrapper>
  );
};

export default InfoBar;
