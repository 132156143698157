import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import React, { useEffect, useState } from "react";
import { ButtonCreateWrapper, Wrapper } from "./promotion.styles";
import { Colors } from "chart.js";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getMailTemplates } from "@/API/repositories/mailTemplate";
import Input from "@/common/components/Input";
import PromotionElement from "./components/PromotionElement";
import { getPromotions } from "@/API/repositories/promotion";
import Loading from "@/common/components/Loading";

const Promotion = () => {
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [filterRegex, setFilterRegex] = useState();
  const [element, setElement] = useState();
  const { addMessage, messages } = useMessageQueue();

  const handleLoadData = async () => {
    const mailTemplateResponse = await makeRequest(getMailTemplates);

    if (mailTemplateResponse.data) {
      setMailTemplateOptions(() =>
        mailTemplateResponse.data.map((template) => ({
          label: template.template_sengrid_name,
          value: template._id,
        }))
      );
    }

    const promotionReposnse = await makeRequest(getPromotions);

    if (promotionReposnse.data) {
      setData(() => promotionReposnse.data);
    }
  };

  const getIconByBool = (bool) => {
    if (!!bool) {
      return (
        <i
          className="fa fa-check"
          style={{ color: "green", fontSize: "18px" }}
        />
      );
    }

    return (
      <i className="fa fa-remove" style={{ color: "red", fontSize: "18px" }} />
    );
  };

  useEffect(() => {
    if (!element) {
      handleLoadData();
    }
  }, [element]);

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 3) {
      return setFilterRegex(() => e.target.value);
    }
    return setFilterRegex(() => null);
  };

  return (
    <>
    {hasUnfilledRequest(getMailTemplates, getPromotions) && <Loading />}
      <MessageQueue messages={messages} />
      <Wrapper>
        <ButtonCreateWrapper>
          <button
            className="btn btn-warning animation-scale"
            onClick={() => setElement({})}
          >
            Create
          </button>
          <Input
            width={100}
            inputWidth={200}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
        </ButtonCreateWrapper>
        {element && (
          <PromotionElement
            element={element}
            setElement={setElement}
            addMessage={addMessage}
            reload={handleLoadData}
            templates={mailTemplateOptions}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={6}>Promotion</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Cron</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] && mailTemplateOptions[0] ? (
              data
                .filter((e) =>
                  filterRegex ? e.name.includes(filterRegex) : true
                )
                .map((t, i) => (
                  <tr key={t._id}>
                    <td>{i + 1}.</td>
                    <td>{t.name}</td>
                    <td>{t.cron}</td>
                    <td>{getIconByBool(t.active)}</td>
                    <td>
                      <i
                        className="fa fa-edit animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => setElement(t)}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default Promotion;
