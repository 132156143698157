import { compareDates } from "@/common/functions/compareDates";

export const sortTodosByDateAndPriority = (todos) =>
  todos
    .sort((todoA, todoB) =>
      compareDates(new Date(todoA.deadline), new Date(todoB.deadline))
    )
    .sort(
      (todoA, todoB) =>
        Number(todoB.high_priority) - Number(todoA.high_priority)
    );
