import { TableHeaderItem } from "../CCPaymentReportMainTable.styled";

export const HEADERS_ARRAY = [
  [<th colSpan="16">MAIN REPORT</th>],
  [
    <th rowSpan="3">TYP</th>,
    <th colSpan="2" rowSpan="2">
      IMITE I NAZWISKO
    </th>,
    <th colSpan="2" rowSpan="2">
      OKRES ROZLICZENIA
    </th>,
    <th colSpan="3" rowSpan="2">
      ILOSC PRZEPRACOWANYCH GODZIN
    </th>,
    <th colSpan="7">BONUS BRUTTO</th>,
    <th rowSpan="3">CSV</th>,
  ],
  [
    <th rowSpan="2">DODATKOWY</th>,
    <th rowSpan="2">GRYWALIZACJA</th>,
    <th colSpan="4">EFEKTYWNOŚCIOWY</th>,
    <th rowSpan="2">Suma</th>,
  ],
  [
    <th>IMIĘ</th>,
    <th>NAZWISKO</th>,
    <th>OD</th>,
    <th>DO</th>,
    <th>
      <TableHeaderItem minWidth="120px">a) KONSULTANT</TableHeaderItem>
    </th>,
    <th>
      <TableHeaderItem minWidth="120px">b) MANAGER</TableHeaderItem>
    </th>,
    <th>Suma</th>,
    <th>
      <TableHeaderItem minWidth="120px">a) WSTĘPNE K</TableHeaderItem>
    </th>,
    <th>
      <TableHeaderItem minWidth="180px">b) WYRÓWNANIE K</TableHeaderItem>
    </th>,
    <th>
      <TableHeaderItem minWidth="120px">c) WSTĘPNE M</TableHeaderItem>
    </th>,
    <th>
      <TableHeaderItem minWidth="140px">d) WYRÓWNANIE M</TableHeaderItem>
    </th>,
  ],
];
