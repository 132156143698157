import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import {
  getConsultantEffectiveSalary,
  getEffectiveSummarizeConsultant,
} from "../../API/repositories/reports";
import translationManager from "../../API/translationManager";
import userManager from "../../API/userManager";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import EffectivePaymentTable from "../../components/consultant/salary/EffectivePaymentTable";
import ManagerEffectivePaymentTable from "@/components/consultant/salary/managerEffectivePaymentTable/ManagerEffectivePaymentTable";
import ManagerSummarize from "@/components/consultant/salary/managerSummarize/ManagerSummarize";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import EffectivePaymentSummarize from "@/components/consultant/salary/effectivePaymentSummarize/EffectivePaymentSummarize";
import { findUserMarket } from "@/common/functions/findUserMarket";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

const InfoWrapper = styled.div`
  max-width: 800px;
  border: 1px dashed red;
  padding: 20px;
  border-radius: 10px;
  color: red;
  text-decoration: underline;
`;

const ConsultantSalary = () => {
  const [summarizeData, setSummarizeData] = useState();
  const [currentMonthEffectiveSalary, setCurrentMonthEffectiveSalary] =
    useState(null);
  const [lastMonthEffectiveSalary, setLastMonthEffectiveSalary] =
    useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const {
    commonData: { markets, queues, users },
  } = useCommonDataContext();

  const currentUser = userManager.getUser();

  const handleGetConsultantEffectiveSalary = async () => {
    const currnetMonthFrom = moment().startOf("month").toDate();
    const currnetMonthTo = moment().endOf("month").toDate();

    let response = await makeRequest(
      getConsultantEffectiveSalary.bind(
        null,
        userManager.getUser().id,
        currnetMonthFrom,
        currnetMonthTo
      )
    );

    if (response.data) {
      setCurrentMonthEffectiveSalary(() => response.data);
    }

    const lastMonthFrom = moment()
      .subtract("1", "month")
      .startOf("month")
      .toDate();
    const lastMonthTo = moment().subtract("1", "month").endOf("month").toDate();

    response = await makeRequest(
      getConsultantEffectiveSalary.bind(
        null,
        userManager.getUser().id,
        lastMonthFrom,
        lastMonthTo
      )
    );

    if (response.data) {
      setLastMonthEffectiveSalary(() => response.data);
    }

    const months = [
      moment().subtract(1, "month").format("MMMM_YYYY").toUpperCase(),
      moment().subtract(2, "month").format("MMMM_YYYY").toUpperCase(),
      moment().subtract(3, "month").format("MMMM_YYYY").toUpperCase(),
    ];
    const userMarket = findUserMarket(markets, queues, currentUser.id);

    const marketUsers = users
      .filter((user) => {
        const market = findUserMarket(markets, queues, user._id);

        return market?._id === userMarket?._id;
      })
      .map((u) => u._id);

    const effectiveSummarizeConsultantResponse = await makeRequest(
      getEffectiveSummarizeConsultant.bind(
        null,
        currentUser.id,
        marketUsers,
        months
      )
    );

    if (effectiveSummarizeConsultantResponse?.data) {
      setSummarizeData(() => effectiveSummarizeConsultantResponse.data);
    }
  };

  useLayoutEffect(() => {
    handleGetConsultantEffectiveSalary();
  }, []);

  const [translation, setTranslation] = useState({
    noData: "There is no data",
    currnetMonthFrom: moment().startOf("month").format("DD MMMM YYYY"),
    currnetMonthTo: moment().endOf("month").format("DD MMMM YYYY"),
    lastMonthFrom: moment()
      .subtract(1, "month")
      .startOf("month")
      .format("DD MMMM YYYY"),
    lastMonthTo: moment()
      .subtract(1, "month")
      .endOf("month")
      .format("DD MMMM YYYY"),
    month: "Month",
    hours: "Hours",
    hours_payment: "Hours payment",
    effective_salary: "Effective salary",
    payment: "Payment",
    bonus: "Bonus",
    gamification_bonus: "Gamification bonus",
    equalization: "Equalization",
    payout_slip: "Payout Slip",
    special_klaudia_info:
      "CC Managers check the hours for the previous month up to the first 3 - 4 working days at the beginning of the following month. Only after this time will you see the final hours for the previous month in your bonus statement.",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);


  return (
    <Wrapper>
      {hasUnfilledRequest(
        getConsultantEffectiveSalary,
        getEffectiveSummarizeConsultant
      ) && <Loading />}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InfoWrapper>{translation["special_klaudia_info"]}</InfoWrapper>
      </div>
      {!!currentMonthEffectiveSalary?.data?.length && (
          <EffectivePaymentTable
            data={currentMonthEffectiveSalary}
            from={translation["currnetMonthFrom"]}
            to={translation["currnetMonthTo"]}
          />
        )}
      {!!currentMonthEffectiveSalary?.team?.length && (
        <ManagerEffectivePaymentTable
          team={currentMonthEffectiveSalary.team}
          from={translation["currnetMonthFrom"]}
          to={translation["currnetMonthTo"]}
        />
      )}
      {lastMonthEffectiveSalary?.data?.length && (
        <EffectivePaymentTable
          data={lastMonthEffectiveSalary}
          from={translation["lastMonthFrom"]}
          to={translation["lastMonthTo"]}
        />
      )}
      {!!lastMonthEffectiveSalary?.team?.length && (
        <ManagerEffectivePaymentTable
          team={lastMonthEffectiveSalary.team}
          from={translation["lastMonthFrom"]}
          to={translation["lastMonthTo"]}
        />
      )}
      {!lastMonthEffectiveSalary?.data?.length &&
        !currentMonthEffectiveSalary?.data?.length &&
        !lastMonthEffectiveSalary?.team?.length &&
        !currentMonthEffectiveSalary?.team?.length && (
          <h4>{translation["noData"]}</h4>
        )}
      {summarizeData && (
        <EffectivePaymentSummarize
          summarizeData={summarizeData}
          translation={translation}
        />
      )}
      {CONSULTANT_MANAGER_ROLES.includes(currentUser.role) && summarizeData && (
        <ManagerSummarize summarizeData={summarizeData} />
      )}
    </Wrapper>
  );
};

export default ConsultantSalary;
