import React, { useState } from "react";
import PopUp from "../../../../../common/components/PopUp";
import SelectInput from "../../../../../common/components/SelectInput";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { addProductToOrder } from "../../../../../API/repositories/order";

const Title = styled.div`
  display: flex;
  color: ${Colors.darkBlue};
  gap: 10px;
  margin-top: -30px;
  align-items: center;

  p {
    font-size: 28px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: -25px;
`;

const Form = styled.form`
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 80px;
`;

const AddVariant = ({ product, setAddVariant, order, handleLoadContact }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [error, setError] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSave = async (e) => {
    e.preventDefault();
    if (!selectedVariant || !selectedPrice) {
      setError(() => true);
      setTimeout(() => {
        setError(() => false);
      }, 2000);
      return;
    }

    const orderItems = [
      ...(order.order_items?.map((e, i) => ({
        product: e.product,
        value: e.product_variant,
        price: e.price,
      })) || []),
      {
        product: product._id,
        price: selectedPrice.value,
        value: selectedVariant.value,
      },
    ];

    const response = await makeRequest(
      addProductToOrder.bind(null, order._id, orderItems)
    );

    if (response.data) {
      await handleLoadContact();
      setAddVariant(() => false);
    } else {
      setError(() => true);
      setTimeout(() => {
        setError(() => false);
      }, 2000);
    }
  };

  return (
    <PopUp setShow={setAddVariant}>
      <Title>
        <p>Product:</p> <h3>{product.name}</h3>
      </Title>
      <Form onSubmit={(e) => handleSave(e)}>
        <div>
          <SelectInput
            required
            name={"Variant"}
            width={80}
            selected={selectedVariant}
            setSelected={setSelectedVariant}
            color={Colors.darkBlue}
            options={product.variants.map((varaint) => {
              return { label: varaint.name, value: varaint.short };
            })}
          />
          <SelectInput
            name={"Price"}
            width={80}
            required
            selected={selectedPrice}
            setSelected={setSelectedPrice}
            color={Colors.darkBlue}
            options={product.prices.map((price) => {
              return { label: price.value, value: price.value };
            })}
          />
        </div>
        <ButtonWrapper>
          <button
            type="submit"
            className={`btn btn-${error ? "danger" : "warning"}`}
          >
            Add
          </button>
        </ButtonWrapper>
      </Form>
    </PopUp>
  );
};

export default AddVariant;
