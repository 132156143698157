import { Colors } from "@/common/colors/colors";
import { CLAIM_STATUSES } from "@/common/constants/claims";
import { checkHighPriority } from "./checkHighPriority";

export const getClaimColor = (claim) => {

  if (claim.status === CLAIM_STATUSES.PAID.value) {
    return Colors.lightBlueGreen;
  }

  const highPriorityColor = checkHighPriority(claim);

  if (highPriorityColor) {
    return highPriorityColor;
  }

  if (claim.status === CLAIM_STATUSES.TO_PAY.value) {
    return Colors.lightGray;
  }

  if ([CLAIM_STATUSES.CHECK_IN_PROGRESS.value, CLAIM_STATUSES.TO_CHECK.value].includes(claim.status)) {
    return Colors.lightBlue2;
  }

  if ([CLAIM_STATUSES.CORRECT_IN_PROGRESS.value, CLAIM_STATUSES.TO_CORRECT.value].includes(claim.status)) {
    return Colors.brightOrange;
  }

  if (claim.status === CLAIM_STATUSES.PAID_IN_PROGRESS.value) {
    return Colors.lightPurple;
  }

  return;
}