import Table from '@/common/components/Table';
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import React from 'react'
import { TableWrapper } from '../../RecruitmentPage.styled';
import Icon from '@/common/components/Icon';

const RecruitmentPageTable = ({ pages, setShow, setSelectedPage }) => {
  const { commonData: { markets }} = useCommonDataContext();

  const headers = ["No.", "Title", "Market", "Actions"]
  const raws = pages.sort((a, b) => a.market.localeCompare(b.market)).map((page, index) => {
    return (
      <tr 
        key={`${page._id}`}
      >
        <td>{index + 1}</td>
        <td>{page.title}</td>
        <td>{markets.find(p => p._id === page.market)?.name}</td>
        <td>
          <Icon 
            onClick={() => {
              setShow(true);
              setSelectedPage(page);
            }}
            name="fa fa-edit"
          />
        </td>
      </tr>
    )
  })
  
  return (
    <TableWrapper>
      <Table stickyHeader className="styled-table" headers={headers} raws={raws} />
    </TableWrapper>
  )
}

export default RecruitmentPageTable
