import { handleGetLastOutgoingCall } from "./handleGetLastOutgoingCall";

export const getRaws = (data, navigate) =>
  data.map((element, i) => (
    <tr>
      <td>{i + 1}.</td>
      <td>{element.name}</td>
      <td>{element._contact.phone_number}</td>
      <td>{element._contact.full_name}</td>
      <td>{element._calling_information.length}</td>
      <td>
        {
          element._calling_information.filter(
            (calling) => calling.type === "incoming"
          ).length
        }
      </td>
      <td>
        {
          element._calling_information.filter(
            (calling) => calling.type === "incoming" && !calling.recording_link
          ).length
        }
      </td>
      <td>
        {handleGetLastOutgoingCall(element._calling_information) ? "Yes" : "No"}
      </td>
      <td>
        {(handleGetLastOutgoingCall(element._calling_information)
          ?.recording_link &&
          "Yes") ||
          "No"}
      </td>
      <td>
        <i
          className="fa fa-edit"
          onClick={() => navigate(`/dashboard/contact/${element._contact._id}`)}
        />
      </td>
    </tr>
  ));
