import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${Colors.darkBlue};
  font-size: 24px;
  font-weight: 700;
`;

export const SearchBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;
