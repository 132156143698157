import React, { useMemo } from "react";
import Table from "../../../../common/components/Table";
import { getLabel } from "../helpers/getLabel";
import { getHeaders } from "../helpers/getHeaders";

const CallHistoryCSVTable = ({
  callHistoryData,
  selectedCallHistoryFields,
}) => {
  const raws = useMemo(
    () =>
      callHistoryData?.map((callHistory, i) => (
        <tr key={callHistory.created_at}>
          {selectedCallHistoryFields.map((field) => (
            <td key={`${field.label} - ${callHistory.created_at}`}>
              {getLabel(callHistory[field.value.label], field.value.type, i)}
            </td>
          ))}
        </tr>
      )),
    [callHistoryData, selectedCallHistoryFields]
  );

  return (
    <Table
      style={{ width: "100%" }}
      headers={getHeaders(selectedCallHistoryFields)}
      className="styled-table"
      raws={raws}
    />
  );
};

export default CallHistoryCSVTable;
