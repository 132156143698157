import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const ChatWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 6;
  background-color: #fff;
  overflow: scroll;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TranslationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  position: sticky;
  top: 10px;
`;

export const TranslationIcon = styled.i`
  font-size: 32px;
  cursor: pointer;
  color: ${Colors.newGray};
`;

export const NoChatData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: bold;
`;

export const ChatList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageBoxSenderWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const MessageBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;

export const CopyWrapper = styled.div`
  margin-top: 10px;
`;

export const FileBox = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gray;
  padding: 8px;
  border-radius: 10px;
  background-color: rgba(211, 211, 211, 0.3);
  cursor: pointer;
  position: relative;
  max-width: 100%;
`;

export const FileTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StatusChangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
`;

export const MessageBoxMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  gap: 4px;
  max-width: 500px;
  min-width: 200;
  padding: 8px;
  overflow-wrap: anywhere;
  border-radius: 10px;
  margin: 10px;
  font-size: 18px;
`;

export const MessageBoxMessageSender = styled.div`
  display: flex;
  font-size: 12px;
  color: black;
  font-weight: bold;
  padding: 2px;
`;

export const MessageBoxOriginal = styled.div`
  padding: 6px;
  font-size: 16px;
  text-shadow: ${({ isCopied }) => isCopied ? `2px 2px 8px ${Colors.darkBlue}` : 'none'};
`;

export const MessageInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const MessageBoxTime = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  font-size: 12px;
`;

export const StatusChange = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  border-top: 1px solid ${Colors.darkGray};
  font-weight: bold;
  font-size: 14px;
  padding: 12px 24px 0;
  color: ${Colors.darkBlue};
  text-align: center;
`;

export const ExclamationIconWrapper = styled.div`
  background-color: ${Colors.darkBlue};
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const SeenWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  opacity: 0.4;
  margin: 0 12px;
`;

export const StatusChangeTime = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  opacity: 0.8;
  color: ${Colors.darkBlue};
  margin-top: -10px;
`;

export const MessageSendingWrapper = styled.span`
  margin-right: 4px;
`;
