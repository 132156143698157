import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FlexButtons = styled.div`
  display: flex;
  justify-content: right;
  gap: 30px;
  margin-top: 100px;
`;

export const H5 = styled.strong`
  color: ${Colors.darkBlue};
  width: 150px;
  text-align: right;
  font-size: 18px;
  margin-top: 10px;
`;

export const DataStrong = styled.h5`
  color: ${Colors.darkBlue};
  margin-left: 30px;
  margin-top: 10px;
`;

export const MarginBottomContainter = styled.div`
  margin-bottom: 40px;
`;

export const MessageHistoryBox = styled.div`
  margin: 40px auto;
  padding: 20px 10px;
  width: 580px;
  max-height: 200px;
  overflow: auto;
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 15px;
  color: ${Colors.darkBlue};
  scroll-behavior: smooth;
`;

export const UserWrapper = styled.p`
  font-size: 14px;
`;

export const TopContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const CommonInfo = styled.p`
  font-size: 12px;
  text-align: right;
  color: black;
`;

export const Message = styled.div`
  font-size: 20px;
  text-align: right;
  width: 100%;
`;

export const ContentWrapepr = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
`;

export const FileUploadWrapper = styled.div`
  margin: 20px 10px 0px 0px;
  display: flex;
  justify-content: right;
  gap: 10px;
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
  margin-left: 40px;
  color: ${Colors.darkBlue};
`;

export const DocumentElementWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
`;

export const FileNameWrapper = styled.div`
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
