import React, { useEffect, useReducer, useRef, useState } from 'react'
import PopUp from '../PopUp'
import { ButtonWrapper, ConfirmButton, FormSidesWrapper, Header } from './ValidClaimForm.styled'
import { getContactWithOrders } from '@/API/repositories/contact'
import { useRequestsContext } from '@/common/hooks/requestHook'
import Loading from '../Loading'
import ClaimSide from './components/claimSide/ClaimSide'
import InfoSide from './components/infoSide/InfoSide'
import { VALID_CLAIM_PRIORITIES } from '@/common/constants/priorities'
import { getClaimTags } from '@/API/repositories/claim-tag'
import { Colors } from '@/common/colors/colors'
import { cancelValidClaim, createValidClaim, payValidClaim, updateValidClaim } from '@/API/repositories/validClaim'
import { ClAIM_METHODS, CLAIM_TYPES } from '@/common/constants/validClaims'
import userManager from '@/API/userManager'
import { CLAIM_REASONS } from '@/common/constants/support'
import { validateIBAN } from '@/common/functions/validateIban'
import { findMarketById } from '@/common/functions/findMarketById'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { CLAIM_STATUSES } from '@/common/constants/claims'
import ShowPayDate from './components/common/showPayDate/ShowPayDate'
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue'
import { chekcSubmitDisabled } from './helpers/checkSubmitDisabled'
import moment from 'moment'
import { CONVERSION_NAMES } from './constants/accountsConversion'
import { accountsDataReducer } from './helpers/accountsDataReducer'
import { genItemsIds } from './helpers/getItemsIds'
import AreYouSure from './components/areYouSure/AreYouSure'

const ValidClaimForm = ({
  claim,
  setShow,
  order,
  savedContact,
  savedTags,
  fromSearch,
  reload = () => { }
}) => {
  const { commonData: { markets } } = useCommonDataContext();

  const country = findMarketById(claim?.iban_market || order.market, markets);

  const [accoutsDataState, dispatchAccountsData] = useReducer(accountsDataReducer, {
    [CONVERSION_NAMES.PREFIX]: claim?.prefix || "",
    [CONVERSION_NAMES.ACCOUNT]: claim?.account || "",
    [CONVERSION_NAMES.BANK_CODE]: claim?.bank_code || "",
  });
  const [claimType, setClaimType] = useState(claim?.type || CLAIM_TYPES.REFUND);
  const [selectedOrderItems, setSelectedOrderItems] = useState(genItemsIds(claim || order));
  const [claimPriority, setClaimPriority] = useState(claim?.priority || VALID_CLAIM_PRIORITIES.NONE.value)
  const [contact, setContact] = useState(savedContact);
  const [tagsOptions, setTagsOptions] = useState(savedTags || []);
  const [selectedTags, setSelectedTags] = useState(tagsOptions?.filter(o => claim?.tags.includes(o.value)) || []);
  const [claimReason, setClaimReason] = useState(CLAIM_REASONS.find(r => r.value === claim?.reason) || null);
  const [allMessages, setAllMessages] = useState(claim?.messages || []);
  const [claimStatus, setClaimStatus] = useState(claim?.status || "none");
  const [claimMethod, setClaimMethod] = useState(claim?.payment_method || ClAIM_METHODS.BANK_TRANSFER.value);
  const [showPayDate, setShowPayDate] = useState(false);
  const [ibanCountry, setIbanCountry] = useState({
    value: country._id,
    label: country.short.toUpperCase()
  });
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const feedbackRef = useRef({ value: claim?.feedback || "" });
  const ibanRef = useRef({ value: claim?.iban || "" });
  const payDateRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useNewMessageQueueContext();

  const { id } = userManager.getUser();

  const disabled = chekcSubmitDisabled(claimStatus, claim, fromSearch);

  const loadContact = async () => {
    if (contact) return;

    const response = await makeRequest(getContactWithOrders.bind(null, order.contact));

    if (!response?.data) {
      return addMessage("Error while loading contact", "error");
    }

    setContact(() => response.data);
  };

  const loadTags = async () => {
    if (!!savedTags?.length) return;
    const response = await makeRequest(getClaimTags);

    if (!response?.data) {
      return addMessage("Error while loading tags", "error");
    }

    const mappedOptions = response.data
      .map(tag => ({ value: tag._id, label: <span style={{ color: Colors.darkGray2 }}>{tag.label}</span> }));

    setTagsOptions(() => mappedOptions);
    setSelectedTags(() => mappedOptions.filter(tag => claim?.tags?.includes(tag.value)));
    return mappedOptions;
  };

  const handleValidatePaymentMethod = () => {
    if (ibanRef.current?.value) {
      ibanRef.current.value = ibanRef.current.value.replace(/ /g, '')?.toUpperCase();
    }

    if (claimMethod === ClAIM_METHODS.BANK_TRANSFER.value) {
      if (!ibanRef.current?.value) {
        addMessage("You need to provide IBAN for bank transfer", "error");
        return false;
      }

      if (ibanCountry.label?.toLowerCase() !== ibanRef.current.value.slice(0, 2)?.toLowerCase()) {
        addMessage("IBAN country is not valid", "error");
        return false;
      }

      const isValid = validateIBAN(ibanRef.current.value);

      if (!isValid) {
        addMessage("Invalid IBAN", "error");
        return false;
      }
    }

    return true;
  }

  const checkIsValidSubmission = () => {
    if (!selectedOrderItems.length) {
      addMessage("You need to select at least one item", "error");
      return false;
    }

    if (!claimReason) {
      addMessage("You need to select a reason", "error");
      return false;
    }

    if (!selectedTags.length) {
      addMessage("You need to select at least one tag", "error");
      return false;
    }

    if (!feedbackRef.current.value) {
      addMessage("You need to provide feedback", "error");
      return false;
    }

    if (!handleValidatePaymentMethod()) {
      return false;
    }

    return true;
  }

  const handleValidClaimUpdate = async ({ message, status, failed_at, priority }) => {
    if (!handleValidatePaymentMethod()) {
      return;
    }

    if (failed_at) {
      claim.transfers.at(-1).failed_at = new Date(failed_at).toISOString();
      claim.transfers.at(-1).state = 'failed';
    }

    const payload = {
      message,
      status: status || claimStatus,
      payment_method: claimMethod,
      iban: ibanRef.current?.value,
      iban_market: ibanCountry.value,
      transfers: claim?.transfers || [],
      priority: priority || claimPriority,
      ...accoutsDataState,
    }

    const response = await makeRequest(
      updateValidClaim.bind(null, claim._id, payload)
    );

    if (!response?.data) {
      return addMessage("Error while updating claim", "error");
    }

    message && setAllMessages((prev) => [...prev, message]);
    status && setClaimStatus(payload.status);
    priority && setClaimPriority(payload.priority);
    addMessage("Claim updated", "success");
  }

  const handleClaimFormSubmit = () => {
    if (!claim) {
      return handleCreateValidClaim();
    }

    if (claimStatus === CLAIM_STATUSES.PAID_IN_PROGRESS.value) {
      return handleCancelClaim();
    }

    if (claimStatus === CLAIM_STATUSES.TO_PAY.value) {
      if (claimMethod === ClAIM_METHODS.CASH.value) {
        return handlePayClaim(true);
      }

      return handlePayClaim();
    }
  }

  const handlePayClaim = async (forced = false) => {
    if (!forced) {
      setShowPayDate(true);
      return addMessage("Last step before payment", "info");
    }

    if (payDateRef?.current?.value &&
      moment(payDateRef.current.value).isBefore(moment().add(50, 'minutes'))) {
      return addMessage("Please select date in the future", "error");
    }

    const date = payDateRef?.current?.value ? new Date(payDateRef.current.value) : new Date();

    setShowPayDate(false);

    const payload = {
      processDate: date.toISOString(),
    };

    const response = await makeRequest(payValidClaim.bind(null, claim._id, payload));

    if (!response?.data) {
      return addMessage("Error while paying claim", "error");
    }

    addMessage("Claim paid", "success");
    setClaimStatus(CLAIM_STATUSES.PAID_IN_PROGRESS.value);
  }

  const handleCancelClaim = async (forced = false) => {
    if (!forced) {
      return setShowAreYouSure(true);
    }

    setShowAreYouSure(false);

    const response = await makeRequest(cancelValidClaim.bind(null, claim._id));

    if (!response?.data) {
      return addMessage("Error while cancelling claim", "error");
    }

    addMessage("Claim cancelled", "success");
    setClaimStatus(CLAIM_STATUSES.TO_PAY.value);
  }

  const handleCreateValidClaim = async () => {
    if (claim) {
      return;
    }

    if (!checkIsValidSubmission()) {
      return;
    }

    const payload = {
      order: order._id,
      order_items: selectedOrderItems.map(item => delete item.id && item),
      tags: selectedTags.map(tag => tag.value),
      priority: claimPriority,
      reason: claimReason.value,
      type: claimType,
      feedback: feedbackRef.current.value,
      messages: allMessages,
      created_by: id,
      payment_method: claimMethod,
      iban: ibanRef.current?.value,
      currency: order.shipping?.currency || order.currency,
      iban_market: ibanCountry.value,
      ...accoutsDataState,
    };

    const response = await makeRequest(createValidClaim.bind(null, payload));

    if (!response?.data) {
      return addMessage("Error while creating claim", "error");
    }

    addMessage("Claim created", "success");
    handleClose();
  }

  const handleClose = () => {
    setShow(false);
    reload();
  }

  useEffect(() => {
    loadContact();
    loadTags();
  }, [])

  return (
    <PopUp crossHeight="20px" maxHeight='98vh' padding='8px 20px 10px' setShow={handleClose}>
      {hasUnfilledRequest(
        getContactWithOrders,
        createValidClaim,
        updateValidClaim,
        cancelValidClaim,
        payValidClaim
      ) && <Loading />}
      <Header>
        Claim
      </Header>
      <FormSidesWrapper>
        <ClaimSide
          claimMethod={claimMethod}
          setClaimMethod={setClaimMethod}
          claim={claim}
          feedbackRef={feedbackRef}
          loadTags={loadTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tagsOptions={tagsOptions}
          claimType={claimType}
          setClaimType={setClaimType}
          selectedOrderItems={selectedOrderItems}
          setSelectedOrderItems={setSelectedOrderItems}
          order={order}
          contact={contact}
          claimReason={claimReason}
          setClaimReason={setClaimReason}
          claimStatus={claimStatus}
          handleValidClaimUpdate={handleValidClaimUpdate}
          ibanRef={ibanRef}
          ibanCountry={ibanCountry}
          setIbanCountry={setIbanCountry}
          accountsDataState={accoutsDataState}
          dispatchAccountsData={dispatchAccountsData}
        />
        <InfoSide
          claimStatus={claimStatus}
          claim={claim}
          order={order}
          contact={contact}
          claimPriority={claimPriority}
          setClaimPriority={setClaimPriority}
          allMessages={allMessages}
          setAllMessages={setAllMessages}
          handleValidClaimUpdate={handleValidClaimUpdate}
        />
      </FormSidesWrapper>
      <ButtonWrapper>
        <ConfirmButton
          disabled={disabled}
          background={claimStatus === CLAIM_STATUSES.PAID_IN_PROGRESS.value
            ? Colors.red
            : Colors.purple
          }
          onClick={() => handleClaimFormSubmit()}
        >
          {claim
            ? claimStatus === CLAIM_STATUSES.PAID_IN_PROGRESS.value
              ? "Cancel"
              : "Pay"
            : "Confirm"
          }
        </ConfirmButton>
      </ButtonWrapper>
      {showPayDate && (
        <ShowPayDate
          claimMethod={claimMethod}
          contact={contact}
          claim={claim}
          setShow={setShowPayDate}
          payDateRef={payDateRef}
          handleSubmit={handlePayClaim}
        />
      )}
      {showAreYouSure && (
        <AreYouSure setShow={setShowAreYouSure} submit={handleCancelClaim} />
      )}
    </PopUp>
  )
}

export default ValidClaimForm
