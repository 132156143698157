import { getTopicsOptions } from "./getTopicsOptions";

export const findLabelByTitle = (ticket, departments) => {
  const topics = getTopicsOptions(departments);

  return (
    topics?.find((t) => t.value === ticket[ticket.type].title)?.label ||
    ticket.type
  );
};
