import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
`;

export const Element = styled.div`
  width: 300px;
  padding: 20px;
  background-color: ${Colors.lightGray};
  border-radius: 10px;
  height: fit-content;

  h5 {
    color: ${Colors.darkBlue};
    font-size: 20px;
  }

  i {
    font-size: 20px;
    color: ${Colors.darkBlue};
    float: right;
    margin: 10px;
    cursor: pointer;
  }
`;

export const Text = styled.h5`
  color: ${Colors.red};
  text-align: center;
  width: 100%;
`;

export const FilesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin: 20px;
`;
