import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { getToday } from "@/common/functions/getToday";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import React from "react";

const SentOrdersSearchBar = ({
  dateFromRef,
  dateToRef,
  handleSearch,
  selectedMarkets,
  setSelectedMarkets,
}) => {
  const {
    options: { marketsOptions },
  } = useCommonDataContext();
  const today = getToday();

  return (
    <SearchBarCenter>
      <div>
        <Input
          name="LMS sent date from"
          width={180}
          color={Colors.darkBlue}
          inputRef={dateFromRef}
          type="date"
          value={today}
          inputWidth={140}
        />
        <Input
          name="LMS sent date to"
          width={180}
          color={Colors.darkBlue}
          inputRef={dateToRef}
          type="date"
          value={today}
          inputWidth={140}
        />
      </div>
      <SelectInput
        multiple={true}
        color={Colors.darkBlue}
        name="Markets"
        options={marketsOptions}
        width={200}
        setSelected={setSelectedMarkets}
        selected={selectedMarkets}
      />
      <button className="btn btn-warning" onClick={(e) => handleSearch(e)}>
        Search
      </button>
    </SearchBarCenter>
  );
};

export default SentOrdersSearchBar;
