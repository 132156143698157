import { compareDates } from "@/common/functions/compareDates";
import moment from "moment";

const MAX_MINUTES_PASSED = 20;

export const checkIfCommentAdded = (userId, comments) => {
  const userComments = comments.filter((comment) => comment.user === userId);

  const lastUserCommentDate = userComments
    .sort((commA, commB) =>
      compareDates(new Date(commB.created_at), new Date(commA.created_at))
    )
    .at(0)?.created_at;

  if (!lastUserCommentDate) {
    return false;
  }

  const minutesPassed = moment().diff(moment(lastUserCommentDate), "minutes");

  return minutesPassed <= MAX_MINUTES_PASSED;
};
