import { Colors } from "../colors/colors";

export const CALENDAR_ELEMENTS_CONFIG = [
  {
    background: Colors.green,
    text: "Atten: ",
    field: "attendance_at_work",
  },
  {
    background: Colors.orange,
    text: "Break: ",
    field: "break_during_the_day",
  },
  {
    background: Colors.red,
    text: "AbsenceWE: ",
    field: "absence_without_excuse",
  },
  {
    background: Colors.red,
    text: "ExcusedUL: ",
    field: "excused_unpaid_leave",
  },
  {
    background: Colors.red,
    text: "leaveOR: ",
    field: "leave_on_reqests",
  },
  {
    background: Colors.darkBlue,
    text: "M hours: ",
    field: "manager_hours",
  },
  {
    background: Colors.red,
    text: "Sick: ",
    field: "sick_leave",
  },
  {
    background: 'purple',
    text: 'Holi leave: ',
    field: 'holiday_leave'
  },
  {
    background: Colors.darkGray2,
    text: 'Spec leave: ',
    field: 'special_leave'
  }
];
