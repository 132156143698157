import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useState } from "react";
import SpeechToTextSearchBar from "./components/speechToTextSearchBar/SpeechToTextSearchBar";
import { TextWrapper } from "./SpeechToText.styled";

const SpeechToText = () => {
  const [text, setText] = useState();

  return (
    <PageWrapper>
      <SpeechToTextSearchBar setText={setText} />
      {text && <TextWrapper>{text}</TextWrapper>}
    </PageWrapper>
  );
};

export default SpeechToText;
