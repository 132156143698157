import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import React, { useEffect, useRef, useState } from "react";
import { ButtonWrapper } from "../../Companies.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { preventFn } from "@/common/functions/preventEvent";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { createCompany, updateCompany } from "@/API/repositories/company";

const CompanyForm = ({
  setShow,
  addMessage,
  loadData,
  selectedCompany,
  keysOptions,
}) => {
  const [selectedKey, setSelectedKey] = useState();

  const vatRef = useRef();
  const nameRef = useRef();
  const domainRef = useRef();
  const colorRef = useRef();
  const weightRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleCreateKey = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.vat = vatRef.current.value;
    payload.name = nameRef.current.value;
    payload.fakturownia_key =
      selectedKey?.value || selectedCompany?.fakturownia_key._id;
    payload._id = selectedCompany?._id;
    payload.fakturownia_domain = domainRef.current.value;
    payload.invoice_number_color = colorRef.current.value;
    payload.select_weight = weightRef?.current?.value || 0;

    const response = selectedCompany
      ? await makeRequest(updateCompany.bind(null, payload))
      : await makeRequest(createCompany.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setShow(() => false);
    addMessage("Success", "success");
    loadData();
  };

  const handleSetColor = () => {
    if (selectedCompany) {
      colorRef.current.value = selectedCompany.invoice_number_color;
    }
  };

  useEffect(() => {
    handleSetColor();
  }, []);

  return (
    <PopUp padding="10px 20px 20px" setShow={setShow}>
      {hasUnfilledRequest(updateCompany, createCompany) && <Loading />}
      <form onSubmit={(e) => handleCreateKey(e)}>
        <SelectInput
          setSelected={setSelectedKey}
          selected={
            selectedKey ||
            keysOptions.find(
              (option) => option.value === selectedCompany?.fakturownia_key._id
            )
          }
          options={keysOptions}
          required
          color={Colors.darkBlue}
          width={180}
          selectWidth={340}
          hideStar
          name="Fakturownia key"
          placeholder="Select one option"
        />
        <Input
          inputRef={nameRef}
          required
          color={Colors.darkBlue}
          width={180}
          inputWidth={340}
          name="Name"
          value={selectedCompany?.name}
          placeholder="e.g Europa"
        />
        <Input
          inputRef={vatRef}
          required
          color={Colors.darkBlue}
          width={180}
          inputWidth={340}
          name="Vat"
          placeholder="e.g 5252909818"
          value={selectedCompany?.vat}
        />
        <Input
          inputRef={domainRef}
          required
          name="Fakturownia domain"
          width={180}
          inputWidth={340}
          placeholder="e.g europa-edu"
          color={Colors.darkBlue}
          value={selectedCompany?.fakturownia_domain}
        />
        <Input
          color={Colors.darkBlue}
          width={180}
          inputWidth={340}
          name="Invoice number color"
          type="color"
          inputRef={colorRef}
          required
        />
        <Input
          width={180}
          color={Colors.darkBlue}
          inputWidth={340}
          name="Weight"
          type="number"
          inputRef={weightRef}
          required
          value={selectedCompany?.select_weight || 0}
        />
        <ButtonWrapper>
          <CustomButtonSave
            styles={{ margin: 0 }}
            color={Colors.orange}
            text={`${selectedCompany ? "Update" : "Create"} key`}
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default CompanyForm;
