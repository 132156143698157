import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants";
import { getRaws } from "./helpers/getRaws";

const FastPayOrderTable = ({
  displayedContact,
  handleShowSearchedOrders,
  handleSendRefund,
  setInvoice
}) => {
  const fastPayOrders = displayedContact.orders?.filter(
    (order) => order.payment?.length
  );

  const raws = getRaws(
    fastPayOrders,
    handleShowSearchedOrders,
    handleSendRefund,
    setInvoice
  );

  return (
    !!fastPayOrders?.length && (
      <Table className="styled-table" headers={HEADERS} raws={raws} />
    )
  );
};

export default FastPayOrderTable;
