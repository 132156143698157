import Icon from "@/common/components/Icon"
import { findUserById } from "@/common/functions/findUserById"
import moment from "moment"

export const getRaws = (managersHistory, setEdit, users) => managersHistory?.sort((a, b) => {
  return new Date(b.active_to) - new Date(a.active_to)
})?.map(manager => (
  <tr key={manager._id}>
    <td>{findUserById(manager.manager, users)?.username}</td>
    <td>{moment(manager.active_from).format('YYYY-MM-DD')}</td>
    <td>{moment(manager.active_to).format('YYYY-MM-DD')}</td>
    <td><Icon name="fa fa-edit" onClick={() => setEdit(manager)} /></td>
  </tr>
))