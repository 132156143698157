import { round } from "@/common/functions/round";
import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  overflow-x: scroll;
  border-collapse: collapse;
  font-size: 0.7em;
  font-family: sans-serif;
  min-width: 400px;
  width: 100%;
`;

const StyledThead = styled.thead`
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: center; /* Wyśrodkowanie tekstu w nagłówkach */
  border-bottom: 1px solid white;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledTh = styled.th`
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 8px 5px;
  &:first-child {
    border-top-left-radius: 10px !important;
  }
  &:last-child {
    border-top-right-radius: 10px !important;
  }
`;

const StyledThNoRadius = styled.th`
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-top: 1px solid white;

  padding: 8px 5px;
`;

const StyledTbody = styled.tbody`
  tr {
    border-bottom: 1px solid #dddddd;
  }
  tr:last-child {
    border-bottom: none;
  }
  tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
`;

const StyledTd = styled.td`
  text-align: center;
  padding: 8px 5px;
  &:first-child {
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-bottom-right-radius: 10px;
  }
`;

const DailyTable = ({ data, consultants, markets }) => {
  return (
    <StyledTable>
      <StyledThead>
        <tr>
          <StyledTh rowSpan={2}>Market</StyledTh>
          <StyledTh rowSpan={2}>No.</StyledTh>
          <StyledTh rowSpan={2}>Consultant</StyledTh>
          <StyledTh colSpan={2}>COD</StyledTh>
          <StyledTh rowSpan={2}>WPPK</StyledTh>
          <StyledTh rowSpan={2}>Downloaded</StyledTh>
          <StyledTh rowSpan={2}>Weight</StyledTh>
        </tr>
        <tr>
          <StyledThNoRadius>Number</StyledThNoRadius>
          <StyledThNoRadius>Sum</StyledThNoRadius>
        </tr>
      </StyledThead>
      <StyledTbody>
        {Object.entries(data).map(([key, value], i) => (
          <React.Fragment key={i}>
            <tr>
              <StyledTd rowSpan={value.length + 1}>
                {markets.find((c) => c._id === key)?.name}
              </StyledTd>
            </tr>
            {value
              .sort((a, b) => b.cod_count - a.cod_count)
              .map((v, j) => (
                <tr key={v._id}>
                  <StyledTd>{j + 1}.</StyledTd>
                  <StyledTd>
                    {consultants.find((c) => c._id === v._id)?.username}
                  </StyledTd>
                  <StyledTd>{round(v.cod_count)}</StyledTd>
                  <StyledTd>{round(v.cod_sum)}</StyledTd>
                  <StyledTd>
                    {round(
                      v.weight_all_sum > 0 ? v.cod_sum / v.weight_all_sum : 0
                    )}
                  </StyledTd>
                  <StyledTd>{round(v.dequeue_count)}</StyledTd>
                  <StyledTd>{round(v.weight_all_sum)}</StyledTd>
                </tr>
              ))}
          </React.Fragment>
        ))}
      </StyledTbody>
    </StyledTable>
  );
};

export default DailyTable;
