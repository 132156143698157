import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const OldTicketsWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 5 / 7;
  background-color: ${Colors.lightGray};
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
`;

export const OldMessageWrapper = styled.p`
  display: flex;
  gap: 10px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

export const OldMessageStatus = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 4px;
  width: 70px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  background-color: ${(props) => props.bgColor};
`;

export const OldMessageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  padding: 6px 8px;
  overflow: hidden;
  width: 45%;
  flex-shrink: 0;
  padding-left: 20px;
`;

export const OldMessageSenderNameWrapper = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

export const OldMessageSenderMarket = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100%;
  width: 80px;
  color: black;
  align-items: center;
  font-weight: bold;
  background-color: ${(props) => props.bakckgroundColor};
  border-right: 1px solid ${Colors.newLightColor};
`;

export const OldMessageText = styled.p`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const OldMessageItem = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  color: ${(props) => props.color};
  gap: 10px;
  border-bottom: 1px solid ${Colors.newLightColor};
`;

export const OnlineRound = styled.span`
  background-color: ${(props) => props.backgroundColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const PriorityWrapper = styled.div`
  position: absolute;
  transform: translate(calc(-100% - 4px), 4px);
  background-color: ${Colors.red};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LastMessageTimeWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
