import React, { useEffect, useMemo, useState } from "react";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Table from "@/common/components/Table";
import { getQueueStackAndContacts } from "@/API/repositories/queue";
import { getDailyUsage } from "@/API/repositories/reports";

const DailyLeadUsage = () => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const [data, setData] = useState();

  const headersArray = [
    [<th colSpan={5}>Today Queue Usage</th>],
    [
      <th>Queue</th>,
      <th>All leads </th>,
      <th>Queued leads </th>,
      <th>Confirmed leads </th>,
      <th>Queue left leads </th>,
    ],
  ];

  const raws = useMemo(() => {
    if (data && data[0]) {
      return data.map((add) => (
        <tr key={add.queueName}>
          <td>{add.queueName}</td>
          <td>{add.newLeads || 0}</td>
          <td>{add.leadsInQueue}</td>
          <td>{add.confirmed_leads}</td>
          <td>{add.left_leads}</td>
        </tr>
      ));
    }
  }, [data]);

  const shouldShowLoading = useMemo(
    () => !data && hasUnfilledRequest(getDailyUsage),
    [data, hasUnfilledRequest]
  );

  const loadData = async () => {
    const response = await makeRequest(getDailyUsage);

    if (response.data) {
      setData(response.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      style={{
        height: "fit-content",
        width: "810px",
        maxHeight: "400px",
        overflow: "scroll",
      }}
    >
      <Table
        style={{ width: "800px" }}
        className="styled-table"
        headersArray={headersArray}
        raws={raws}
        noDataCols={4}
        loading={shouldShowLoading}
      />
    </div>
  );
};
export default DailyLeadUsage;
