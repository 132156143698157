import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import { TableItemHover } from "@/common/styles/Hover";
import Icon from "@/common/components/Icon";
import { getOrderInvoiceColor } from "@/common/functions/getOrderInvoiceColor";
import MailsTimeline from "@/components/mailsTimeline/MailsTimeline";
import ShippingTimeline from "@/components/shippingTimeline/ShippingTimeline";
import { addMailConditionalStyles } from "@/common/functions/addMailConditionalStyles";
import { addShippingConditionalStyles } from "@/common/functions/addShippingConditionalStyles";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
`;
const Orders = ({ orders, translation, currentOrder }) => {
  const [mailsTimeline, setMailsTimeline] = useState();
  const [shippingTimeline, setShippingTimeline] = useState();

  return (
    <Wrapper>
      <h5 style={{ color: Colors.darkBlue }}>Client orders:</h5> <br></br>
      <table className="styled-table" style={{ minWidth: "100%" }}>
        <thead>
          <tr>
            <th>{translation.no}</th>
            <th>{translation.status}</th>
            <th>{translation.currency}</th>
            <th>{translation.product}</th>
            <th>{translation.market}</th>
            <th>{translation.createdAt}</th>
            <th>{translation.timeline}</th>
          </tr>
        </thead>
        <tbody className="queue">
          {orders.map((order, i) => {
            return (
              <tr
                style={{
                  color: order._id === currentOrder._id ? "green" : "black",
                }}
              >
                <td>{i + 1}</td>
                <td>{order.status}</td>
                <td>{order.currency}</td>
                <td>{order._product.name}</td>
                <td>{order._market.name}</td>
                <td>{moment(order.created_at).format("YYYY-MM-DD")}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <TableItemHover label={translation.invoice_status}>
                      <Icon
                        color={getOrderInvoiceColor(order?._invoice)}
                        cursor="default"
                        name="fa fa-file-invoice"
                      />
                    </TableItemHover>
                    <TableItemHover label={translation.mail_history}>
                      <Icon
                        onClick={() => setMailsTimeline(order?._mails)}
                        name="fa fa-envelope"
                        {...addMailConditionalStyles(order)}
                      />
                    </TableItemHover>
                    <TableItemHover label={translation.shipping_history}>
                      <Icon
                        onClick={() => setShippingTimeline(order?.shipping)}
                        name="fa fa-truck"
                        {...addShippingConditionalStyles(order)}
                      />
                    </TableItemHover>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {mailsTimeline?.length && (
        <MailsTimeline setShow={setMailsTimeline} mails={mailsTimeline} />
      )}
      {shippingTimeline && (
        <ShippingTimeline
          setShow={setShippingTimeline}
          shipping={shippingTimeline}
        />
      )}
    </Wrapper>
  );
};

export default Orders;
