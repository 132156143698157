import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

const PREFIX = "/file";

export const getAllFiles = () =>
  errorHandler(async () => {
    const data = await api.get(`${PREFIX}/get`);

    return { data: data.data };
  });

export const createFile = (file) =>
  errorHandler(async () => {
    const data = await api.post(`${PREFIX}/create`, file);

    return { data: data.data };
  });

export const updateFile = (file, _id) =>
  errorHandler(async () => {
    const data = await api.patch(`${PREFIX}/update/${_id}`, file);

    return { data: data.data };
  });  
