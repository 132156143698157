import React from "react";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headersArray";
import { getTeamRaws } from "./helpers/getTeamRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const PreManagerResultTable = ({ consultantResults }) => {
  const {
    commonData: { users },
  } = useCommonDataContext();

  return (
    <Table
      style={{ marginTop: "30px" }}
      className="styled-table"
      headersArray={HEADERS_ARRAY}
      raws={getTeamRaws(consultantResults, users)}
    />
  );
}
  
export default PreManagerResultTable;
