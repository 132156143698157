export const CALLING_STATUSES = [
  {
    value: "outgoing",
    label: "Outbound",
  },
  {
    value: "incoming",
    label: "Inbound",
  },
];

export const SMS_TEMPLATE_TYPES = [
  {
    label: "After shipping return",
    value: "after_shipping_return",
  },
  {
    label: "After shipping awizo",
    value: "after_shipping_awizo",
  },
];

export const CONTRACTS_OPTIONS = [
  {
    label: "SK_UoP",
    value: "SK_UoP",
  },
  {
    label: "RO_UoP",
    value: "RO_UoP",
  },
  {
    label: "CZ_UoP",
    value: "CZ_UoP",
  },
  {
    label: "CZ_DPP",
    value: "CZ_DPP",
  },
];

export const WORKING_HOURS = [
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
];

export const HOURS_POSIBLES = ["h", "1", "/", "u", "n", "m", "s", "r", "sl"];
