import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const NotSeenWrapper = styled.div`
  color: ${Colors.red};
  font-size: 18px;
  position: absolute;
  right: -6px;
  top: -12px;
`;
