import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../../common/components/Input";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { useCommonDataContext } from "../../../../../common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import moment from "moment";
import { getOrderSentChart } from "@/API/repositories/reports";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import CustomLine from "@/common/charts/Line";

const SentOrders = () => {
  const timeRangeRef = useRef();
  const [chartData, setChartData] = useState();

  const {
    commonData: { products, markets },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);

    const payload = {};
    payload.from = moment()
      .subtract(timeRangeRef.current.value, "d")
      .startOf("day")
      .toISOString();
    payload.to = moment().endOf("day").toISOString();
    payload.markets = markets.map((market) => market._id);

    const response = await makeRequest(getOrderSentChart.bind(null, payload));

    if (response?.data) {
      setChartData(response.data);
    }
  };

  useEffect(() => {
    if (products) handleLoadData();
  }, [products]);

  return (
    <Wrapper width="800">
      <TitleSection>
        <TopContentWrapper>
          <i className="fa-brands fa-usps" />
          Sent orders
        </TopContentWrapper>{" "}
        <TopContentWrapper>
          <Input
            showLabel={false}
            inputWidth={50}
            value={2}
            inputRef={timeRangeRef}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {!!chartData ? (
          <CustomLine data={chartData} />
        ) : (
          <CustomSkeleton width={760} count={17} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default SentOrders;
