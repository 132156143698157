export const CLAIM_TYPES = {
  REFUND: 'refund',
  COMPLAINT: 'complaint'
}

export const ClAIM_METHODS = {
  BANK_TRANSFER: {
    value: 'bank_transfer',
    label: "Bank transfer"
  },
  CASH: {
    value: 'cash',
    label: "Cash"
  }
}
