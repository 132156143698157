import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CountConsultantSum from "./CountConsultantSum";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";

const Wrapper = styled.div`
  display: flex;
  margin: 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TBody = styled.tbody`
  td {
    text-align: center;
    padding: 10px;
    font-size: 15px;
  }
`;

const ConsultantHoursTable = ({ data, month }) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();
  const [workingHours, setWorkingHours] = useState(data.data);
  const freeDays =
    findMarketByContractType(markets, data._user)?.free_days || [];

  const firstDayOfTheMonth = moment(month.toDate()).startOf("month");

  const workingDays = Array.from(Array(firstDayOfTheMonth.daysInMonth()).keys())
    .map(
      (number, i) =>
        !["Sunday", "Saturday"].includes(
          firstDayOfTheMonth.clone().add(number, "day").format("dddd")
        ) &&
        !freeDays
          .map((d) => d.date)
          .includes(
            firstDayOfTheMonth.clone().add(number, "day").format("YYYY-MM-DD")
          )
    )
    .filter((result) => result !== false).length;

  useEffect(() => {
    setWorkingHours(() => data.data);
  }, [data]);

  return (
    <Wrapper>
      <table className="styled-table-2 tableWrap">
        <thead>
          <tr>
            <th>Attendance</th>
            <th>Holiday</th>
            <th>Special leave</th>
            <th>Sick leave</th>
            <th>Leave on request</th>
            <th>Break during the day</th>
            <th>Excused unpaid leave</th>
            <th>Absence</th>
            <th>Manager hours</th>
            <th>Hours a day</th>
          </tr>
        </thead>
        <TBody>
          <CountConsultantSum
            data={workingHours}
            monthWorkingData={data._month_working_info || {}}
            workingDays={workingDays}
          />
        </TBody>
      </table>
    </Wrapper>
  );
};

export default ConsultantHoursTable;
