import moment from "moment";

export const generateRaws = (data, minusDays) => {
  const result = [];

  data
    .sort((orderA, orderB) => {
      const a = new Date(orderA?.shipping.status_lms_sent_at).getTime();
      const b = new Date(orderB?.shipping.status_lms_sent_at).getTime();

      return a - b;
    })
    .filter(({ shipping }) => {
      const date = moment().startOf("day");
      const treshold = moment(shipping.status_lms_sent_at)
        .add(minusDays, "day")
        .startOf("day");

      if (date.isBefore(treshold)) {
        return false;
      }

      return true;
    })
    .forEach((order, i) => {
      const element = (
        <tr>
          <td>{i + 1}.</td>
          <td>
            {moment(order.shipping.status_lms_sent_at).format(
              "DD/MM/YYYY HH:mm"
            )}
          </td>
          <td>{order.contact[0].full_name}</td>
          <td>{order.market[0].name}</td>
          <td>{order.product[0].name}</td>
          <td>{order.shipping.waybill_number}</td>
          <td>
            <i
              className="fa fa-edit"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(`/dashboard/order/${order._id}`)}
            />
          </td>
        </tr>
      );
      result.push(element);
    });

  return result;
};

export const generateRawsCSV = (data, minusDays) => {
  const result = [];

  data
    .sort((orderA, orderB) => {
      const a = new Date(orderA?.shipping.status_lms_sent_at).getTime();
      const b = new Date(orderB?.shipping.status_lms_sent_at).getTime();

      return a - b;
    })
    .filter(({ shipping }) => {
      const date = moment().startOf("day");
      const treshold = moment(shipping.status_lms_sent_at)
        .add(minusDays, "day")
        .startOf("day");

      if (date.isBefore(treshold)) {
        return false;
      }

      return true;
    })
    .forEach((order, i) => {
      const element = [
        moment(order.shipping.status_lms_sent_at).format("DD/MM/YYYY HH:mm"),
        order.contact[0].full_name,
        order.market[0].name,
        order.product[0].name,
        order.shipping.waybill_number,
      ];

      result.push(element);
    });

  return result;
};
