import React from 'react'
import { Label, StyledInput, StyledText } from './CheckBoxInput.styled';
import { Colors } from '@/common/colors/colors';

const CheckBoxInput = ({
  checked = false,
  text,
  disabled = false,
  accentColor = Colors.darkBlue,
  mainColor = Colors.checkBoxSelected,
  color = Colors.darkBlue,
  hideCheckMark = false,
  handleSelect = () => {}
}) => {

  return (
    <Label 
      mainColor={mainColor} 
      disabled={disabled} 
      selected={checked}
    >
      <StyledInput
        accentColor={accentColor}
        disabled={disabled}
        checked={checked}
        onClick={(e) => {
          handleSelect(e);
        }}
        type='checkbox'
        hidden={hideCheckMark}
      />
      <StyledText color={color} selected={checked}>
        {text}
      </StyledText>
    </Label>
  )
}

export default CheckBoxInput
