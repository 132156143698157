import React from "react";
import { countByStatus } from "../../helpers/countByStatus";

const IncomingTable = ({ data }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Consultant</th>
          <th>Answerd</th>
          <th>Confirmed</th>
          <th>Shift</th>
          <th>Resign</th>
          <th>No action</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data.map((d) => (
          <tr key={d._id}>
            <td>{d._id}</td>
            <td>{d.orders.length}</td>
            <td>{countByStatus(d.orders, "confirmed")}</td>
            <td>{countByStatus(d.orders, "shift")}</td>
            <td>{countByStatus(d.orders, "resigned")}</td>
            <td>{countByStatus(d.orders)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default IncomingTable;
