import moment from "moment";
import React, { useRef, useState } from "react";
import { getMoreInfoTestReport } from "../../API/repositories/reports";
import { Colors } from "../../common/colors/colors";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import { getToday } from "@/common/functions/getToday";

const OPTIONS = [
  {
    label: "PS_MORE_INFO_SK",
    value: "d-d26d3051fd7745fb9ea8c7aa889918cd",
  },
  {
    label: "SR_MORE_INFO_RO",
    value: "d-54bfd979d2884da29ec8fdefe5a77fc5",
  },
  {
    label: "LC_MORE_INFO_CZ",
    value: "d-1b26dd1a954a46f880b24cac7b66f120",
  },
  {
    label: "VC_MORE_INFO_CZ",
    value: "d-dc71bc33fc314870ba2ed5353fd3fd8a",
  },
];

const MoreInfoReport = () => {
  const dateFromRef = useRef();
  const dateToRef = useRef();

  const [selectedMails, setSelectedMails] = useState(OPTIONS);
  const [data, setData] = useState();
  const today = getToday();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async () => {
    const payload = Object();

    payload.from =
      dateFromRef.current?.value || moment().startOf("day").toISOString();
    payload.to =
      dateToRef.current?.value || moment().endOf("day").toISOString();
    payload.mails_types = selectedMails.map((data) => data.value);

    payload.from = moment(payload.from).startOf("day").toISOString();
    payload.to = moment(payload.to).endOf("day").toISOString();

    const response = await makeRequest(
      getMoreInfoTestReport.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getMoreInfoTestReport) && <Loading />}
      <SearchBarCenter>
        <div>
          <Input
            name="Mail sent date from"
            width={180}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="date"
            value={today}
            inputWidth={140}
          />
          <Input
            name="Mail sent date to"
            width={180}
            color={Colors.darkBlue}
            inputRef={dateToRef}
            type="date"
            value={today}
            inputWidth={140}
          />
        </div>
        <SelectInput
          multiple={true}
          color={Colors.darkBlue}
          name="Mails Types"
          options={OPTIONS}
          width={120}
          selectWidth={400}
          setSelected={setSelectedMails}
          selected={selectedMails}
        />
        <button
          className="btn btn-warning"
          onClick={(e) => handleSearch(e)}
          style={{ marginLeft: "120px" }}
        >
          Search
        </button>
      </SearchBarCenter>
      {data && data.length > 0 && (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Template</th>
              <th>Unique sent</th>
              <th>Opened SUM</th>
              <th>Clicked SUM</th>
              <th>Bounced SUM</th>
              <th>Confirmed</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data.map((element) => (
              <tr>
                <td>
                  {
                    OPTIONS.find((option) => option.value === element.name)
                      .label
                  }
                </td>
                <td>{new Set(element.value.map((e) => e.order)).size}</td>
                <td>
                  {element.value.reduce(
                    (prev, next) => prev + (next.open_countrer || 0),
                    0
                  )}
                </td>
                <td>
                  {element.value.reduce(
                    (prev, next) => prev + (next.clicked_countrer || 0),
                    0
                  )}
                </td>
                <td>
                  {element.value.reduce(
                    (prev, next) => prev + (next.bounced_at ? 1 : 0),
                    0
                  )}
                </td>
                <td>
                  {
                    new Set(
                      element.value
                        .filter((mail) => mail._order.status === "confirmed")
                        .map((mail) => mail._order._id)
                    ).size
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </WrapperWithScroll>
  );
};

export default MoreInfoReport;
