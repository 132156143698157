import Table from "@/common/components/Table";
import React, { useState } from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import MetaAccountForm from "../metaAccountForm/MetaAccountForm";

const MetaAccountsTable = ({ accounts, loadData, addMessage }) => {
  const [selectedAccount, setSelectedAccount] = useState();

  return (
    <>
      <Table
        className="styled-table"
        headers={HEADERS}
        raws={getRaws(accounts, setSelectedAccount)}
      />
      {selectedAccount && (
        <MetaAccountForm
          setShow={setSelectedAccount}
          loadData={loadData}
          addMessage={addMessage}
          account={selectedAccount}
        />
      )}
    </>
  );
};

export default MetaAccountsTable;
