import moment from "moment";
import React, { useRef, useState } from "react";
import { getMarketsDailyReport } from "../../API/repositories/reports";
import { Colors } from "../../common/colors/colors";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { formatDate } from "../../common/functions/formatDate";
import { useRequestsContext } from "../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";

const TYPE_OPTIONS = [
  {
    label: "Inbound",
    value: "incoming",
  },
  {
    label: "Outbound",
    value: "outgoing",
  },
];

const DailyMarket = () => {
  const [callingType, setCallingType] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [data, setData] = useState();

  const { options } = useCommonDataContext();
  const { marketsOptions } = options;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const dateFromRef = useRef();
  const dateToref = useRef();

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedMarkets.length) {
      addMessage("Select markets", "error");

      return;
    }

    if (!callingType.length) {
      addMessage("Select calling types", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.markets = selectedMarkets.map((market) => market.value);
    payload.types = callingType.map((type) => type.value);

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getMarketsDailyReport.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleCountNotPickUp = (data) => {
    return data.filter((element) => !element.consultant)?.length || 0;
  };

  const countRealTimeCalling = (data) => {
    const seconds = data.reduce(
      (prev, next) => prev + (next.talking_time || 0),
      0
    );

    const result = `${Math.floor(seconds / 60)} minutes ${
      seconds % 60
    } seconds`;

    return result;
  };

  const countMinutes = (seconds) => {
    return Math.ceil(seconds / 60);
  };

  const countClouTalkCalling = (data) => {
    return `${data.reduce(
      (prev, next) => prev + countMinutes(next.talking_time || 0),
      0
    )} minutes`;
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getMarketsDailyReport) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      <SearchBarCenter>
        <div>
          <Input
            name="Download from"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="date"
            inputWidth={140}
            value={formatDate(moment())}
          />
          <Input
            name="Download to"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateToref}
            type="date"
            inputWidth={140}
            value={formatDate(moment())}
          />
        </div>
        <div>
          <SelectInput
            name="Marktes"
            options={marketsOptions}
            color={Colors.darkBlue}
            width={100}
            setSelected={setSelectedMarkets}
            selected={selectedMarkets}
            multiple={true}
            selectWidth={180}
          />
          <SelectInput
            name="Type"
            options={TYPE_OPTIONS}
            color={Colors.darkBlue}
            width={100}
            setSelected={setCallingType}
            selected={callingType}
            multiple={true}
            selectWidth={180}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <button className="btn btn-warning" onClick={(e) => hanldeSearch(e)}>
            Search
          </button>
          <button
            className="btn btn-warning"
            onClick={(e) => setSelectedMarkets(marketsOptions)}
          >
            Add All
          </button>
        </div>
      </SearchBarCenter>
      {data && (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Number of callings</th>
              <th>Number not pick up</th>
              <th>Real time calling</th>
              <th>CaloudTalk time calling</th>
            </tr>
          </thead>
          <tbody className="queue">
            <tr>
              <td>{data.length}</td>
              <td>{handleCountNotPickUp(data)}</td>
              <td>{countRealTimeCalling(data)}</td>
              <td>{countClouTalkCalling(data)}</td>
            </tr>
          </tbody>
        </table>
      )}
    </WrapperWithScroll>
  );
};

export default DailyMarket;
