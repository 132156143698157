import styled from "styled-components";
import { Colors } from "@/common/colors/colors";
import { BORDER_RADIUS } from "../../constants/borderRadius";
import { ROW_GAP, ROW_HEIGHT } from "./constants/layout";

export const ParticipentsWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 40px;
  gap: ${`${ROW_GAP}px`};
  max-height: ${(props) => props.maxHeight};
`;

export const ParticipentWrapper = styled.div`
  display: flex;
  height: ${`${ROW_HEIGHT}px`};
  width: 360px;
  gap: 8px;
  align-items: center;
  padding: 0px 8px;
  border-radius: ${BORDER_RADIUS};
  background-color: ${(props) =>
    props.winner ? Colors.lightlightBlue : "white"};
`;

export const ParticipentName = styled.p`
  width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${Colors.darkBlue};
`;
