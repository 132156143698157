import { getLastTicketNotification } from "./getLastTicketNotification";
import { getStatusChangeMessage } from "./getStatusChangeMessage";

export const getTicketMessage = (ticket, users) => {
  const notification = getLastTicketNotification(ticket);

  if (notification.quill_delta) {
    return "[rich text message]";
  }

  if (!notification.original) {
    return "[file message]";
  }

  return notification.type === "status_change"
    ? getStatusChangeMessage(notification.original, users)
    : notification.original;
};
