import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
`;

export const ButtonCreateWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h3`
  margin-bottom: 50px;
  color: ${Colors.darkBlue};
`;

export const SubTitle = styled.h4`
  color: ${Colors.darkBlue};
  text-decoration: underline;
  margin-bottom: 25px;
`;

export const LineWrapper = styled.p`
  color: ${Colors.darkBlue};
  margin-bottom: 15px;
`;

export const WrapperCategory = styled.div`
  min-width: 800px;
  padding: 20px;
`;

export const ButtonSaveWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 50px;
`;

export const TestingWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 50px;
  width: 100%;
  align-items: center;

  div {
    button {
      float: right;
      margin-right: 10px;
    }
  }
`;
