import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import InvoiceTicket from "../../../../common/components/invoiceTicket/InvoiceTicket";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";

import CreateNewTicket from "../../dashboard/panel/ticket/createNewTicket/CreateNewTicket";
import translationManager from "@/API/translationManager";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const H5 = styled.strong`
  color: ${Colors.darkBlue};
  width: 110px;
  text-align: right;
  font-size: 24px;
  margin-top: 10px;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 80px;

  input::file-selector-button {
    border: 2px solid ${Colors.darkBlue};
    border-radius: 15px;
    background-color: transparent;
    font-style: italic;
    color: ${Colors.darkBlue};
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const TicketsForCalling = ({ orderId, market }) => {
  const [showQuestionTicket, setShowQuestionTicket] = useState();
  const [showInvoiceTicket, setShowInvoiceTicket] = useState();
  const [success, setSuccess] = useState();

  const [translation, setTranslation] = useState({
    topic: "Topic",
    ticket_question: "Ticket question",
    receiver: "Receiver",
    file: "File",
    create_ticket: "Create ticket",
    type_message: "Type message",
  });

  const { addMessage, messages, removeMessage } = useMessageQueue();

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <>
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      {showQuestionTicket && (
        <CreateNewTicket
          orderId={orderId}
          translation={translation}
          addMessage={addMessage}
          setShowQuestionTicket={setShowQuestionTicket}
        />
      )}
      <Wrapper>
        <div>
          <button
            className={`btn btn-${
              success === "question" ? "success" : "warning"
            } animation-scale`}
            onClick={() => setShowQuestionTicket(true)}
          >
            <i className="fa fa-question" style={{ marginRight: "10px" }}></i>
            {success === "question" ? "Success" : "Question"}
          </button>
        </div>
        <div>
          <button
            className={`btn btn-${
              success === "invoice" ? "success" : "warning"
            } animation-scale`}
            onClick={() => setShowInvoiceTicket(true)}
          >
            <i
              className="fa fa-file-invoice"
              style={{ marginRight: "10px" }}
            ></i>
            {success === "invoice" ? "Success" : "Invoice"}
          </button>
        </div>
        {showInvoiceTicket && (
          <InvoiceTicket
            market={market}
            addMessage={addMessage}
            orderId={orderId}
            setShowInvoiceTicket={setShowInvoiceTicket}
          />
        )}
      </Wrapper>
    </>
  );
};

export default TicketsForCalling;
