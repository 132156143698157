import {
  caclManagerPreEffectivePaymentNew,
  calcManagerHours,
} from "@/common/functions/consultantPaymentsCalculations";
import { round } from "@/common/functions/round";

export const getRaws = (summarizeData) =>
  summarizeData
    .map(({ consultantResults }) => {
      if (!consultantResults) {
        return null;
      }

      const managerHours = calcManagerHours(
        consultantResults.data[0]._consultant_hours
      );

      const effectivePaymentNotFinal = caclManagerPreEffectivePaymentNew(
        consultantResults,
        false,
      );

      const effectivePaymentFinal = caclManagerPreEffectivePaymentNew(
        consultantResults,
        true,
      );

      const originalCurrency = consultantResults.data[0].salary_currency;

      return (
        <>
          <tr>
            <td rowSpan={2}>
              {consultantResults._month_working_info.for_month}
            </td>
            <td rowSpan={2}>{managerHours} h</td>
            <td rowSpan={2}>
              {managerHours *
                consultantResults._month_working_info.manager_hourly_rate}{" "}
              {originalCurrency}
            </td>
            <td>
              {effectivePaymentNotFinal} {originalCurrency}
            </td>
            <td>
              {effectivePaymentNotFinal +
                managerHours *
                  consultantResults._month_working_info
                    .manager_hourly_rate}{" "}
              {originalCurrency}
            </td>
            <td rowSpan={2}>
              {round(
                Math.max(effectivePaymentFinal - effectivePaymentNotFinal, 0)
              )}{" "}
              {originalCurrency}
            </td>
          </tr>
          <tr>
            <td>
              {effectivePaymentFinal} {originalCurrency}
            </td>
            <td>
              {" "}
              {(effectivePaymentFinal > 0 &&
                round(
                  effectivePaymentFinal +
                    managerHours *
                      consultantResults._month_working_info.manager_hourly_rate
                )) ||
                0}{" "}
              {consultantResults.data[0].salary_currency}
            </td>
          </tr>
        </>
      );
    })
    ?.filter((raw) => raw);
