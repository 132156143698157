import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChooseProductView from "./ChoseProductView";
import translationManager from "../../../../API/translationManager";

const Wrapper = styled.div`
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
`;

const Order = ({
  addMessage,
  product,
  order,
  chosenProductData,
  setChosenProductData,
  contact,
}) => {
  const [translation, setTranslation] = useState({
    chooseOrderItem: "Choose order item",
    variants: "Variants",
    price: "Price",
    name: "Name",
    add: "Add",
    chosenOrderItems: "Chosen order items",
    nothingToSee: "There is no products in this order",
    shipping: "Shipping",
    variant: "Variant",
    short: "Short",
    surname: "Surname",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <Wrapper>
      <ChooseProductView
        contact={contact}
        addMessage={addMessage}
        product={product}
        setChosenProductData={setChosenProductData}
        chosenProductData={chosenProductData}
        currency={order.currency}
        orderId={order._id}
        items={order.order_items}
        translation={{
          chooseOrderItem: translation.chooseOrderItem,
          name: translation.name,
          variants: translation.variants,
          price: translation.price,
          short: translation.short,
          variant: translation.variant,
          add: translation.add,
          chosenOrderItems: translation.chosenOrderItems,
          nothingToSee: translation.nothingToSee,
          shipping: translation.shipping,
        }}
      />
    </Wrapper>
  );
};

export default Order;
