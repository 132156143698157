import React, { useState } from "react";
import styled from "styled-components";
import Ping from "@/common/components/Ping";
import userManager from "@/API/userManager";
import { Colors } from "@/common/colors/colors";
import AdminSearchContact from "@/common/components/adminSearchContact/AdminSearchContact";
import Tickets from "./components/ticket/Tickets";
import Logout from "@/common/components/Logout";
import moment from "moment";
import CreateForm from "../dashboard/create/CreateForm";
import PopUp from "@/common/components/PopUp";
import OrderSearch from "../dashboard/orderSearch/OrderSearch";
import LanguagesChanger from "@/common/components/languagesChanger/LanguagesChanger";
import Icon from "@/common/components/Icon";
import TodoView from "./components/todoView/TodoView";
import TextToSpeach from "./components/textToSpeach/TextToSpeach";

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0px 10px 0 30px;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
`;

const FullNameWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
  margin-right: 10px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const WELCOME_OPTIONS = [
  "Hi! How are you?",
  "Good morning! Have a great day!",
  "Hey there! What's up?",
  "Hello! Nice to meet you.",
  "Good evening! How was your day?",
  "Bonjour ! Comment ça va ?",
  "Salut ! Quoi de neuf ?",
  "Coucou ! Enchanté de te rencontrer.",
  "Bonne journée ! À plus tard !",
  "¡Hola! ¿Cómo estás?",
  "¡Buenos días! Que tengas un buen día.",
  "¡Hola! ¿Qué tal?",
  "¡Hola! Mucho gusto en conocerte.",
  "¡Buenas tardes! ¿Cómo ha sido tu día?",
  "Hallo! Wie geht es dir?",
  "Hey! Wie läuft's?",
  "Grüß Gott! Schön, dich kennenzulernen.",
  "Guten Abend! Wie war dein Tag?",
  "Ciao! Come stai?",
  "Buongiorno! Buona giornata!",
  "Hey! Come va?",
  "Salve! Piacere di conoscerti.",
  "Buonasera! Come è andata la tua giornata?",
  "Сайн байна уу?",
  "Манай талаар их маш ойлгохоор байна уу?",
  "Сайн байцгаана уу?",
  "Сайн уу?",
  "Оройн мэнд хүргэе!",
  "你好！",
  "早上好！祝你有美好的一天！",
  "嘿！最近如何？",
  "你好！很高兴认识你。",
  "晚上好！你今天过得怎么样？",
  "Dobar dan! Kako ste?",
  "Dobro jutro! Ugodan dan!",
  "Bok! Kaj ima?",
  "Zdravo! Drago mi je što smo se upoznali.",
  "Dobra večer! Kako je protekao tvoj dan?",
];

const InfoBar = () => {
  const [showNewContact, setShowNewContact] = useState();
  const [showOrderSearch, setShowOrderSearch] = useState();
  const [showTextToSpeach, setShowTextToSpeach] = useState();

  const getRandomNumber = () =>
    Math.floor(Math.random() * WELCOME_OPTIONS.length);
  const user = userManager.getUser();

  const canAccessTodos =
    user.access.includes("GET_ADMIN_TODOS") || user.role === "ADMIN";

  return (
    <>
      <Wrapper>
        <div style={{ width: "600px" }}>
          <Title>{WELCOME_OPTIONS[getRandomNumber()]}</Title>
          <span style={{ fontStyle: "italic", fontSize: "14px" }}>
            <b style={{ color: Colors.darkBlue }}>{moment().format("dddd")}</b>
            {moment().format(", MMMM YYYY")}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <AdminSearchContact />
          <div
            className="animation-scale"
            style={{
              border: `1px solid ${Colors.darkBlue}`,
              borderRadius: "10px",
              width: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Colors.darkBlue,
              cursor: "pointer",
            }}
          >
            <i className="fa fa-add" onClick={() => setShowNewContact(true)} />
          </div>
          <div
            className="animation-scale"
            style={{
              border: `1px solid ${Colors.darkBlue}`,
              borderRadius: "10px",
              width: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Colors.darkBlue,
              cursor: "pointer",
              marginLeft: "-15px",
            }}
          >
            <i
              className="fa-solid fa fa-truck-fast"
              onClick={() => setShowOrderSearch(true)}
            />
          </div>
        </div>
        <ActionsWrapper>
          <FullNameWrapper>
            {userManager.getUser()?.username}
            <Ping />
          </FullNameWrapper>
          {canAccessTodos && <TodoView />}
          <Tickets />
          <LanguagesChanger />
          <i
            className="fa-solid fa fa-headphones"
            style={{
              color: Colors.darkBlue,
              cursor: "pointer",
            }}
            onClick={() => setShowTextToSpeach(true)}
          />
          <Logout />
        </ActionsWrapper>
      </Wrapper>
      {showNewContact && (
        <PopUp setShow={setShowNewContact}>
          <CreateForm />
        </PopUp>
      )}
      {showTextToSpeach && <TextToSpeach setShow={setShowTextToSpeach} />}
      {showOrderSearch && (
        <PopUp setShow={setShowOrderSearch}>
          <OrderSearch />
        </PopUp>
      )}
    </>
  );
};

export default InfoBar;
