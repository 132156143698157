import React, { useEffect, useRef, useState } from "react";
import PopUp from "../PopUp";
import moment from "moment";
import { getFileById } from "@/API/repositories/storedDocument";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import Icon from "../Icon";
import {
  CommentsListWrapper,
  CommentsWrapper,
  DataWrapper,
  EmptyCommentsWrapper,
  MessageInfoWrapper,
  MessageSenderWrapper,
  MessageSendingWrapper,
  Paragraph,
  SendCommentWrapper,
  Textarea,
  Title,
  TranslateIcon,
  TranslationIconWrapper,
} from "./TodoPreview.styled";
import { Colors } from "@/common/colors/colors";
import { addTodoComment, markTodoMessagesSeen } from "@/API/repositories/todo";
import userManager from "@/API/userManager";
import { findUserById } from "@/common/functions/findUserById";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { formatDate } from "@/common/functions/dateFormater";
import { KEYS_CODES } from "../contactSearch/contstants/keysCodes";

const TodoPreview = ({ setShow, todo, loadData }) => {
  const [isFileLoading, setIsFileLoading] = useState();
  const [commentsList, setCommentsList] = useState(todo.messages || []);
  const [comment, setComment] = useState();
  const [isOriginalText, setIsOriginalText] = useState(true);

  const bottomRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const {
    commonData: { users },
  } = useCommonDataContext();
  const { messages, removeMessage, addMessage } = useMessageQueue();

  const currentUserId = userManager.getUser().id;

  const handleGetStoredDocument = async () => {
    if (!todo?._stored_document) {
      return addMessage("No file to get", "error");
    }
    setIsFileLoading(() => true);

    const response = await makeRequest(
      getFileById.bind(null, todo._stored_document._id)
    );

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
    setIsFileLoading(() => false);
  };

  const handleAddComment = async () => {
    setComment(() => "");
    setCommentsList((prev) => [
      ...prev,
      {
        original: comment,
        created_at: new Date(),
        sender: currentUserId,
      },
    ]);
    const response = await makeRequest(
      addTodoComment.bind(null, todo._id, comment)
    );
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });

    if (!response?.data) {
      setCommentsList(todo?.messages || []);
      return addMessage("Something went wrong", "error");
    }
    await loadData();
  };

  const handleEnterSubmit = (e, fn) => {
    if (
      e.keyCode === KEYS_CODES.enter &&
      e.shiftKey === false &&
      comment?.trim()
    ) {
      e.preventDefault();
      return fn();
    }
  };

  const handleMarkMessagesSeen = async () => {
    const response = await makeRequest(
      markTodoMessagesSeen.bind(null, todo._id)
    );

    if (response?.data) {
      loadData();
    }
  };

  useEffect(() => {
    handleMarkMessagesSeen();
  }, []);

  return (
    <PopUp setShow={setShow}>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <DataWrapper>
        <Title>{todo.title}</Title>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: todo.description
              .replaceAll("  ", "&nbsp&nbsp;")
              .replaceAll("\n", "<br />"),
          }}
        />
        <p>Deadline - {moment(todo.deadline).format("YYYY-MM-DD HH:mm")}</p>
        {todo._stored_document && (
          <p>
            {todo._stored_document?.id_in_bucket} -{" "}
            <Icon
              name={isFileLoading ? "fa fa-spinner fa-spin" : "fa fa-file"}
              size={20}
              onClick={() => handleGetStoredDocument()}
            />
          </p>
        )}
        {todo.user && (
          <>
            <CommentsWrapper>
              <TranslationIconWrapper>
                <TranslateIcon
                  onClick={() => setIsOriginalText((prev) => !prev)}
                  className="fa fa-language"
                />
              </TranslationIconWrapper>
              {!commentsList?.length && (
                <EmptyCommentsWrapper>No comments yet</EmptyCommentsWrapper>
              )}
              {commentsList.map((message, idx) => (
                <CommentsListWrapper
                  align={
                    message.sender === currentUserId ? "flex-end" : "flex-start"
                  }
                  backgroundColor={
                    message.sender === currentUserId
                      ? Colors.purple
                      : Colors.newGray
                  }
                >
                  <MessageInfoWrapper>
                    <span>
                      {isOriginalText
                        ? message.original
                        : message.translation?.trim() || message.original}
                    </span>
                    {hasUnfilledRequest(addTodoComment) &&
                      idx === commentsList?.length - 1 && (
                        <div>
                          <Icon color="#fff" name="fa fa-spinner fa-spin" />
                        </div>
                      )}
                  </MessageInfoWrapper>
                  <MessageSendingWrapper>
                    <MessageSenderWrapper>
                      {findUserById(message.sender, users)?.username ||
                        "System"}
                    </MessageSenderWrapper>
                    {formatDate(message.created_at, "YYYY-MM-DD HH:mm")}
                  </MessageSendingWrapper>
                </CommentsListWrapper>
              ))}
              <div ref={bottomRef} />
            </CommentsWrapper>
            <SendCommentWrapper>
              <Textarea
                value={comment}
                onChange={(e) => setComment(() => e.target.value)}
                onKeyDown={(e) => handleEnterSubmit(e, handleAddComment)}
                placeholder="Add comment..."
              />
              <Icon
                onClick={() => {
                  if (comment) {
                    handleAddComment();
                  }
                }}
                cursor={comment?.trim() ? "pointer" : ""}
                size={22}
                color={comment?.trim() ? Colors.purple : Colors.newDarkGray}
                name="fa fa-send"
              />
            </SendCommentWrapper>
          </>
        )}
      </DataWrapper>
    </PopUp>
  );
};

export default TodoPreview;
