import React, { useState } from "react";
import {
  ContactListItem,
  ContactListItemElement,
  ContactsList,
  ContactsWrapper,
} from "./Contacts.styled";
import { Icon } from "../../ContactSearch.styled";
import { Colors } from "@/common/colors/colors";
import ContactOrders from "../contactOrders/ContactOrders";

const Contacts = ({
  contacts,
  setCheckedContact,
  setCheckedOrder,
  setContacts,
  checkedContact,
  checkedOrder,
}) => {
  const [selectedContact, setSelectedContact] = useState();

  const handleOrderCheck = (newOrderId) => {
    setSelectedContact(() => null);
    setCheckedContact(() => null);
    setCheckedOrder(() => newOrderId);
    setContacts(() => null);
  };

  const handleContactCheck = (contactId) => {
    setSelectedContact(() => null);
    setCheckedOrder(() => null);
    setCheckedContact(() => contactId);
    setContacts(() => null);
  };

  return (
    <>
      <ContactsWrapper>
        <ContactsList>
          {contacts.map((contact) => (
            <React.Fragment key={contact._id}>
              <ContactListItem>
                <ContactListItemElement width="150px">
                  {contact.full_name}
                </ContactListItemElement>
                <ContactListItemElement width="90px">
                  {contact.phone_number}
                </ContactListItemElement>
                <ContactListItemElement width="180px">
                  {contact.email}
                </ContactListItemElement>
                <Icon
                  className={
                    checkedContact === contact._id
                      ? "fa fa-check"
                      : "fa fa-plus"
                  }
                  color={checkedContact === contact._id ? Colors.green : ""}
                  onClick={() => handleContactCheck(contact._id)}
                />
                <Icon
                  onClick={() => {
                    setSelectedContact((prev) => {
                      return prev?._id === contact._id ? null : contact;
                    });
                  }}
                  rotate={selectedContact?._id === contact._id ? "180deg" : "0"}
                  className="fa fa-arrow-down"
                />
              </ContactListItem>
              {selectedContact?._id === contact._id && (
                <ContactOrders
                  selectedContact={selectedContact}
                  checkedOrder={checkedOrder}
                  handleOrderCheck={handleOrderCheck}
                />
              )}
            </React.Fragment>
          ))}
        </ContactsList>
      </ContactsWrapper>
    </>
  );
};

export default Contacts;
