import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { getUserCurrentContact } from "../../API/repositories/queue";
import translationManager from "../../API/translationManager";
import userManager from "../../API/userManager";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import Blackboard from "../../components/consultant/dashboard/panel/blackboard-info/Blackboard";
import Contact from "../../components/consultant/dashboard/panel/contact/Contact";
import Create from "../../components/consultant/dashboard/panel/create/Create";
import Inbound from "../../components/consultant/dashboard/panel/inbound/Inbound";
import ConsultantQueueActions from "../../components/consultant/dashboard/panel/queueActons/ConsultantQueueActions";
import StartCalling from "../../components/consultant/dashboard/panel/start-calling/StartCalling";
import CallingStatisticsChart from "../../components/consultant/dashboard/panel/callingStats/CallingStatisticsChart";
import GradeChart from "../../components/consultant/dashboard/panel/GradeChart/GradeChart";
import ConfirmedToHoursChart from "../../components/consultant/dashboard/panel/confirmedToHoursChart/ConfirmedToHoursChart";
import Gamification from "@/components/consultant/dashboard/panel/gamification/Gamification";
import ManagerStatsTable from "@/components/consultant/dashboard/panel/manager-stats-table/ManagerStatsTable";
import { MANAGER_ROLE } from "@/components/admin/settings/user/components/userForm/constants";
import { deleteFileById } from "@/API/repositories/storedDocument";

const TopViewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 30px;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

const Wrappepr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  gap: 50px;
  padding: 20px 50px 50px 50px;
  overflow: auto;
`;

const PLIVO_CALLERS = [
  "65e17c717b70329cee175bc3",
  "637bc207a973ab8bab9e6ab6",
  "638f3377004cd1bc3ffac14d",
  "637ce3f8a973ab8bab9e6b33",
  "64789ca99788a94ef095d67f",
  "647730b4b63b993a2c914ef3",
  "638075e5e139e14dc432e91b",
  "641475a20c7236ac0c7fe0ae",
  "65d328d3c0c5b42de26b5e9e",
  "641060c0f7450ad7f1f81e16",
  "63919e95afbaa60b6be86bba",
  "63ce361140a4feb1b5e67c47",
  "638f3377004cd1bc3ffac14e",
  "64916c3c63f9e7400bca6d69",
  "646e119c2853b8b8d901b3e8",
  "64e36bf78f02ae23dbc01182",
  "64f1ec3a2c710a77de0d1acb",
  "63d9321781c215dd1bd7ea23",
  "649e9a557d8f4c2b41efcd14",
  "63d92df481c215dd1bd7e3fa",
  "643fe96a3eb9aeddc8aac3df",
  "6401d04111e19fc513eaef06",
  "646b7bda26937db1e5c2d1e7",
  "64fefb268f5e5e0d700943bc",
  "659cebc616920685b9ecbba2",
  "65a6889f8078f1dd7df80375",
  "65cf4824c0c5b42de267b627",
  "66010a4afb80871f66cc056c",
  "66010ab5fb80871f66cc05f8",
  "65e09c3c7b70329cee16a9fa",
  "65d84f003e149b67b07e766d",
  "65e8785f2d63dd9de944a3ef",
  "65e878d02d63dd9de944a7ab",
  "66010331fb80871f66cbfb95",
  "660103d5fb80871f66cbfc64",
  "66010469fb80871f66cbfd11",
  "660104effb80871f66cbfe31",
  "6656fd6d756def5ae28b40c1",
];

const ConsultantDashboard = () => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [currentContact, setCurrentContact] = useState(null);

  const userRole = userManager.getUser().role;

  const payload = Object();
  payload.from = moment().subtract(35, "days").startOf("day").toDate();
  payload.to = moment().subtract(7, "days").startOf("day").toDate();
  payload.weights = true;
  payload.ids = [userManager.getUser().id];

  const [translation, setTranslation] = useState({
    ImportNewContact: "Import new contact",
    CurrentContact: "Import current contacts",
    Grade: "Grade",
    CurrentMonth: moment().startOf("month").format("MMMM"),
    LastMonth: moment().subtract(1, "month").startOf("month").format("MMMM"),
    CreateContact: "Create contact",
    ImportInboundContact: "Import incomming contact",
    PlaceholderInbound: "type phone number without '+' ",
    Your_shifts: "Your shifts",
    Your_emails: "Your emails",
    shift_date: "shift date",
    full_name: "full name",
    email: "email",
    number: "number",
    should_call: "should call",
    actions: "actions",
    mails: "Mails",
    shifts: "Shifts",
    sent_date: "sent date",
    downloaded_contact_text: "Please end your contact",
    inboundCallText: "Import current inbound contact",
    blackboard: "Informations",
    yourTickets: "Your Tickets",
    topic: "Topic",
    ticket_question: "Ticket question",
    receiver: "Receiver",
    message: "Message",
    create_ticket: "Create ticket",
    type_message: "Type message",
    "Pick up contacts": "Pick up contacts",
    Avg: "Avg",
    today: "today",
    "last 7 days grade": "last 7 days grade",
    "last 7 days results": "last 7 days results",
    Downloaded: "Downloaded",
    start_calling: "Start calling",
    team_stats: "Team stats",
    username: "Username",
    confirmed_orders: "Confirmed orders",
    calls: "Calls",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const handleGetUserCurrentContact = async () => {
    const response = await makeRequest(
      getUserCurrentContact.bind(null, userManager.getUser().id)
    );
    if (response.data) {
      setCurrentContact(() => response.data);
    }
  };

  useLayoutEffect(() => {
    handleGetUserCurrentContact();
  }, []);

  return (
    <Wrappepr>
      {(hasUnfilledRequest(getUserCurrentContact) ||
        hasUnfilledRequest(deleteFileById)) && <Loading />}
      <Gamification />
      <ButtonsContainer>
        {userManager?.getUser()?.calling_system && (
          <StartCalling translation={translation} />
        )}
        <Contact
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          textCurrent={translation["CurrentContact"]}
          textNew={translation["ImportNewContact"]}
        />
        <Inbound
          inboundCallText={translation["inboundCallText"]}
          downloadedContactText={translation["downloaded_contact_text"]}
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          importText={translation["ImportInboundContact"]}
          placeHolderText={translation["PlaceholderInbound"]}
        />
        <Create text={translation["CreateContact"]} />
      </ButtonsContainer>
      <TopViewContainer>
        <CallingStatisticsChart translations={translation} />
        <GradeChart translations={translation} />
        <ConfirmedToHoursChart translations={translation} />
      </TopViewContainer>
      <MiddleContainer>
        <Blackboard translation={translation} />
        <ConsultantQueueActions text={translation} />
        {MANAGER_ROLE === userRole && (
          <ManagerStatsTable translations={translation} />
        )}
      </MiddleContainer>
    </Wrappepr>
  );
};

export default ConsultantDashboard;
