import React from "react";
import Table from "@/common/components/Table";
import { getHeadersArray } from "./helpers/getHeadersArray";
import { getRaws } from "./helpers/getRaws";

const ShortInfoDataTable = ({ data }) => {
  return (
    <Table
      className="styled-table"
      headersArray={getHeadersArray(data)}
      raws={getRaws(data)}
    />
  );
};

export default ShortInfoDataTable;
