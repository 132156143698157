import Table from '@/common/components/Table';
import React from 'react'
import { TableWrapper } from '../../File.styled';
import Icon from '@/common/components/Icon';
import { Colors } from '@/common/colors/colors';

const FileTable = ({ files, setShow, setSelectedFile, handleDownloadFile, loadingFiles }) => {

  const headers = ["No.", "Filename", "Extension", "Mimetype", "Actions"]
  const raws = files.sort((a, b) => a.filename.localeCompare(b.filename)).map((file, index) => {
    return (
      <tr 
        key={`${file._id}`}
      >
        <td>{index + 1}</td>
        <td>{file.filename}</td>
        <td>{file.extension}</td>
        <td>{file.mimetype}</td>
        <td style={{ display: "flex", gap: "12px", justifyContent: "center" }}>
          <Icon 
            onClick={() => {
              setShow(true);
              setSelectedFile(file);
            }}
            name="fa fa-edit"
          />
           {loadingFiles.includes(file._id) 
            ? <Icon onClick={() => false} name="fa fa-spinner fa-spin" /> 
            : <Icon color={Colors.green} onClick={() => handleDownloadFile(file)} name="fa fa-download" />}
        </td>
      </tr>
    )
  })
  
  return (
    <TableWrapper>
      <Table stickyHeader className="styled-table" headers={headers} raws={raws} />
    </TableWrapper>
  )
}

export default FileTable
