import React from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import PopUp from "../../../common/components/PopUp";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.darkBlue};
  justify-content: center;
  margin-bottom: -20px;
`;

const LoadingAnimation = styled.div`
  margin-top: 8px;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px 1px;
    background: ${Colors.darkBlue};
    animation: wave 1.2s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
`;

const WaitingPopup = () => {
  return (
    <PopUp canOffByClickingBackground={false}>
      <Wrapper>
        <h3>Waiting for server</h3>
        <LoadingAnimation>
          <span></span>
          <span></span>
          <span></span>
        </LoadingAnimation>
      </Wrapper>
    </PopUp>
  );
};

export default WaitingPopup;
