import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const CCViewWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap;
`;

export const FLexColumne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: ${(props) => props.width}px;
  height: 500px;
  border-radius: 15px;
  padding: 15px;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
`;

export const TitleSection = styled.p`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.darkBlue};
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TopContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
