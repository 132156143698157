export const HEADERS = [
  "Time period",
  "Start date",
  "End date",
  "Product",
  "Leads",
  "CPL (Netto)",
  "Total spend (Netto)",
  "WPPK confirmed",
  "ROAS confirmed",
  "WPPK delivered",
  "ROAS delivered",
];
