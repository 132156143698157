import React from "react";
import styled from "styled-components";
import { Colors } from "../colors/colors";

const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const SaveButton = ({ onClick }) => {
  return (
    <ButtonWrapper>
      <button
        onClick={(e) => onClick(e)}
        type="submit"
        className="btn btn-warning animation-scale"
        style={{
          background: Colors.yellow,
          float: "right",
          marginRight: "20px",
        }}
      >
        Save
      </button>
    </ButtonWrapper>
  );
};

export default SaveButton;
