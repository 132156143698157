import React, { useEffect, useState } from "react";
import { getConsultantDrafts } from "../../../API/repositories/consultantDocuments";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Loading from "../../../common/components/Loading";
import translationManager from "../../../API/translationManager";
import {
  Element,
  FilesWrapper,
  Text,
  Wrapper,
} from "./consultantDrafts.styles";
import {
  SubtitleWrapper,
  TitleWrapper,
} from "../consultantFaq/consultantFaq.styles";
import { handleDownloadFile } from "./helper/downloadFile";
import { getFileById } from "@/API/repositories/storedDocument";

const ConsultantDrafts = () => {
  const [data, setData] = useState();
  const [translation, setTranslation] = useState({
    thereIsNoDraftForYou: "There is no draft for you :(((",
    title: "Employee Resources",
    description: `Explore our Employee Resources for essential HR documents, including vacation requests and HR policies, to simplify employee management and improve HR efficiency.`,
  });

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  const handleLoadData = async () => {
    const response = await makeRequest(getConsultantDrafts);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
    translate();
  }, []);

  return (
    <>
      {(hasUnfilledRequest(getConsultantDrafts) ||
        hasUnfilledRequest(getFileById)) && <Loading />}
      {data && (
        <Wrapper>
          <TitleWrapper className="fade__in__animation">
            {translation["title"]}
          </TitleWrapper>
          <SubtitleWrapper className="fade__in__animation">
            {translation["description"]}
          </SubtitleWrapper>
          <FilesWrapper>
            {data.map((d, i) => (
              <Element
                key={d._id}
                className="fade__in__animation"
                style={{
                  animationDelay: `${i * 100}ms`,
                }}
              >
                <h5>
                  {i + 1}. {d._stored_docs.description}
                </h5>
                <i
                  className="fa fa-file animation-scale "
                  onClick={() =>
                    handleDownloadFile(d._stored_docs._id, makeRequest)
                  }
                />
              </Element>
            ))}
          </FilesWrapper>
          {data.length === 0 && <Text>{translation.thereIsNoDraftForYou}</Text>}
        </Wrapper>
      )}
    </>
  );
};

export default ConsultantDrafts;
