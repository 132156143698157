import React, { useState, useEffect, useMemo } from "react";
import { Colors } from "@/common/colors/colors";
import SelectInput from "@/common/components/SelectInput";
import moment from "moment";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { CONSULTANT_ROLES } from "@/components/loginPage/LoginForm/loginForm";
import { getHoursForCCManager } from "@/API/repositories/consultantHours";
import Loading from "@/common/components/Loading";
import CSV from "@/common/components/CSV";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import Input from "@/common/components/Input";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import {
  Wrapper,
  SearchBar,
  SearchItem,
  SearchButtons,
} from "./hoursReport.styles";
import { HEADERS } from "./constants/headers";
import HoursReportTable from "./components/HoursReportTable";
import { getCSVBodyForHoursReport } from "./helpers/getCSVBodyForHoursReport";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelBodyForHoursReport } from "./helpers/getExcelBodyForHoursRepor";
import { COMPANIES_OPTIONS } from "@/common/constants/Accounting";

export default function HoursReport() {
  const workingFromDate = moment().startOf("month").format("YYYY-MM-DD");
  const workingToDate = moment().endOf("month").format("YYYY-MM-DD");
  const [selectedCompany, setSelectedCompany] = useState(COMPANIES_OPTIONS[0]);

  const {
    options: { queuesOptions },
    filterUsersByRoles,
    queryValues: { isLoading },
    commonData: { users },
  } = useCommonDataContext();

  const [selectedQueues, setSelectedQueues] = useState(queuesOptions);
  const [filterRegex, setFilterRegex] = useState();
  const [allData, setAllData] = useState([]);
  const [consultants, setConsultants] = useState();
  const [selectedConsultant, setSelectedConsultants] = useState();
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[0]);

  const data = useMemo(() => {
    if (selectedCompany && allData) {
      return [...allData].filter(
        (ad) => ad._user.company === selectedCompany.value
      );
    }

    return allData;
  }, [selectedCompany, allData]);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleGetConsultants = async () => {
    const consultantResponseValidated = filterUsersByRoles(CONSULTANT_ROLES);

    setConsultants(() => consultantResponseValidated);
    setSelectedConsultants(() => consultantResponseValidated);
  };

  const handleSearch = async (e) => {
    e && e.preventDefault();

    setAllData(() => null);

    const payload = {};

    payload.month = selectedMonth.value;
    payload.monthFormated = moment(selectedMonth.value)
      .format("MMMM_YYYY")
      .toUpperCase();
    payload.accepted = true;

    const response = await makeRequest(
      getHoursForCCManager.bind(null, payload)
    );

    if (response.data) {
      setAllData(() => response.data);

      response.data.forEach((hours, i) => {
        const foundTicket = hours.data.some((hour) => hour.ticket);

        response.data[i].foundTicket = foundTicket;
      });
    }
  };

  const handleSelectQueue = (queue) => {
    let selectedQueueUsers = [];

    queue.forEach((q) => {
      selectedQueueUsers = [...selectedQueueUsers, ...q.value.consultants];
    });

    const users = consultants.filter((c) =>
      selectedQueueUsers.includes(c.value)
    );

    setSelectedConsultants(() => users);
    setSelectedQueues(() => queue);
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 1) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  useEffect(() => {
    handleGetConsultants();
    if (queuesOptions) {
      setSelectedQueues(() => queuesOptions);
    }
  }, [users, queuesOptions]);

  return (
    <div>
      <Wrapper>
        {(isLoading || hasUnfilledRequest(getHoursForCCManager)) && <Loading />}
        <SearchBar>
          <SearchItem>
            <SelectInput
              name="Comapny"
              options={COMPANIES_OPTIONS}
              color={Colors.darkBlue}
              width={80}
              selectWidth={120}
              setSelected={setSelectedCompany}
              selected={selectedCompany}
            />
            <SelectInput
              name="Month"
              options={LAST_YEAR_MONTHS}
              color={Colors.darkBlue}
              width={50}
              setSelected={setSelectedMonth}
              selected={selectedMonth}
            />
            <Input
              width={80}
              inputWidth={120}
              onChange={(e) => handleChangeRegex(e)}
              name="Includer"
              color={Colors.darkBlue}
            />
          </SearchItem>
          <SelectInput
            showLabel={false}
            selectWidth={1000}
            options={consultants}
            selected={selectedConsultant}
            setSelected={setSelectedConsultants}
            multiple={true}
            placeholder={"Select consultants..."}
          />
          <SelectInput
            showLabel={false}
            placeholder={"Select queues..."}
            selectWidth={1000}
            options={queuesOptions}
            setSelected={handleSelectQueue}
            selected={selectedQueues}
            multiple={true}
          />
          <SearchButtons>
            {data && data.length > 0 && (
              <>
                <ExportExcel
                  label="pl"
                  excelData={getExcelBodyForHoursReport(
                    data,
                    selectedConsultant,
                    consultants,
                    workingFromDate,
                    workingToDate,
                    filterRegex,
                    HEADERS.PL
                  )}
                  fileName={`hours_report_pl_${selectedMonth.label}_${selectedCompany.value}`}
                />
                <ExportExcel
                  label="en"
                  excelData={getExcelBodyForHoursReport(
                    data,
                    selectedConsultant,
                    consultants,
                    workingFromDate,
                    workingToDate,
                    filterRegex,
                    HEADERS.EN
                  )}
                  fileName={`hours_report_en_${selectedMonth.label}_${selectedCompany.value}`}
                />
              </>
            )}

            {data && data.length > 0 && (
              <CSV
                label="pl"
                header={HEADERS.PL}
                data={getCSVBodyForHoursReport(
                  data,
                  selectedConsultant,
                  consultants,
                  workingFromDate,
                  workingToDate,
                  filterRegex
                )}
                filename={`Hours report PL ${selectedMonth.label}`}
              />
            )}
            {data && data.length > 0 && (
              <CSV
                label="en"
                header={HEADERS.EN}
                data={getCSVBodyForHoursReport(
                  data,
                  selectedConsultant,
                  consultants,
                  workingFromDate,
                  workingToDate,
                  filterRegex
                )}
                filename={`Hours report EN ${selectedMonth.label}`}
              />
            )}
            <CustomButtonAdd
              defaultText={"Add all"}
              onClick={() => setSelectedConsultants(consultants)}
            />
            <CustomButtonAdd
              defaultText="Search"
              onClick={(e) => handleSearch(e)}
            />
          </SearchButtons>
        </SearchBar>
        {!!data?.length && (
          <HoursReportTable
            data={data}
            filterRegex={filterRegex}
            selectedConsultant={selectedConsultant}
            consultants={consultants}
            workingFromDate={workingFromDate}
            workingToDate={workingToDate}
          />
        )}
      </Wrapper>
    </div>
  );
}
