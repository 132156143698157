export const ARITHMETIC_OPTIONS = [
  {
    label: "equal",
    value: "$eq",
  },
  {
    label: "lesser",
    value: "$lt",
  },
  {
    label: "lesser or equal",
    value: "$lte",
  },
  {
    label: "grater",
    value: "$gt",
  },
  {
    label: "grater or equal",
    value: "$gte",
  },
];

export const DIRECTION_OPTIONS = [
  {
    label: "Ascending",
    value: "asc",
  },
  {
    label: "Descending",
    value: "desc",
  },
];

export const TIME_UNIT_OPTIONS = [
  {
    label: "Hours",
    value: "hours",
  },
  {
    label: "Days",
    value: "days",
  },
];