import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { Title } from "./LanguageForm.styled";
import { ButtonWrapper } from "../../Languages.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createLanguage, updateLanguage } from "@/API/repositories/language";
import Loading from "@/common/components/Loading";
import { preventFn } from "@/common/functions/preventEvent";

const LanguageForm = ({ setShow, addMessage, loadData, selectedLanguage }) => {
  const labelRef = useRef();
  const shortRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleSubmit = (e) => {
    e && preventFn(e);

    if (selectedLanguage) {
      return handleUpdateLanguage();
    }

    return handleCreateLanguage();
  };

  const handleUpdateLanguage = async () => {
    const payload = {};

    payload.label = labelRef.current.value;
    payload.short = shortRef.current.value;

    const response = await makeRequest(
      updateLanguage.bind(null, selectedLanguage?._id, payload)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Updated", "success");
    setShow(() => false);
    loadData();
  };

  const handleCreateLanguage = async () => {
    const payload = {};

    payload.label = labelRef.current.value;
    payload.short = shortRef.current.value;

    const response = await makeRequest(createLanguage.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Created", "success");
    setShow(() => false);
    loadData();
  };

  return (
    <PopUp padding="4px 20px 50px" setShow={setShow}>
      {hasUnfilledRequest(createLanguage) && <Loading />}
      <Title>Create language</Title>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Input
          inputRef={labelRef}
          value={selectedLanguage?.label}
          required
          name="Label"
          color={Colors.darkBlue}
          width={40}
        />
        <Input
          inputRef={shortRef}
          value={selectedLanguage?.short}
          required
          name="Short"
          color={Colors.darkBlue}
          width={40}
        />
        <ButtonWrapper>
          <CustomButtonSave text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default LanguageForm;
