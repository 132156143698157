import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRequestsContext } from "../hooks/requestHook";
import { countPing } from "../../API/repositories/core";
import { Colors } from "../colors/colors";
import { VERSION } from "../constants/version";

const Circle = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: -20px;
  margin-left: -5px;
`;

const Ping = ({ display = true }) => {
  const [ping, setPing] = useState(35);

  const { makeRequest } = useRequestsContext();

  const handleCountPing = () => {
    return setInterval(async () => {
      await countPing();
    }, [25000]);
  };

  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().map((entry) => {
        if (
          entry.name === `${process.env.REACT_APP_CRM_URL}/core/ping/${VERSION}`
        )
          setPing(() => Math.floor(entry.duration));
      });
    });

    observer.observe({ entryTypes: ["resource"] });

    const interval = handleCountPing();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {ping && display && (
        <Circle
          style={{
            background: `${
              ping < 120 ? "green" : ping < 240 ? Colors.yellow : "red"
            }`,
          }}
        ></Circle>
      )}
    </>
  );
};

export default Ping;
