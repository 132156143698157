import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { round } from "@/common/functions/round";

export const getCSVDataForMainInfo = (data) => {
  const result = [];

  const element = [];

  element.push(data.contract_type);
  element.push(data.name);
  element.push(data.surname);
  element.push(formatToDateTamplate(data.from));
  element.push(formatToDateTamplate(data.to));
  element.push(data.consultantHours);
  element.push(data.managerHours);
  element.push(data.hoursSum);
  element.push(data.additional_bonus);
  element.push(data.gamification_bonus);
  element.push(data.pre_consultant);
  element.push(data.final_consultant);
  element.push(data.pre_manager);
  element.push(round(data.final_manager));
  element.push(data.sum);

  result.push(element);

  return result;
};
