import React, { useEffect, useState } from 'react'
import { HtmlEditorPreview, HtmlEditorTextArea, HtmlEditorWrapper } from './HtmlEditor.styled';
import { isValidHtml } from './helpers/isValidHtml';

const HtmlEditor = ({ setShouldBlockSubmit, setFieldValue, field, defaultValue }) => {
  const [htmlContent, setHtmlContent] = useState(defaultValue);
  const [htmlError, setHtmlError] = useState();

  const validateHtml = (html) => {
    const isValid = isValidHtml(html);

    setHtmlError(() => !isValid);
    setShouldBlockSubmit(() => !isValid);
  }

  useEffect(() => {
    validateHtml(htmlContent);
  }, []);

  return (
    <HtmlEditorWrapper>
      <HtmlEditorTextArea 
        name="html_content"
        htmlError={htmlError}
        placeholder='Enter HTML content here'
        value={htmlContent}
        rows={10}
        onChange={(e) => {
          setHtmlContent(e.target.value);
          validateHtml(e.target.value);
          setFieldValue(field, e.target.value);
        }} 
      />
      <HtmlEditorPreview
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </HtmlEditorWrapper>
  )
}

export default HtmlEditor
