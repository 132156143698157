import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const LanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: ${Colors.gray};
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
`;

export const LanugageImage = styled.img`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  object-fit: cover;
  cursor: pointer;
`;

export const LanguagesSelection = styled.div`
  position: absolute;
  top: 60px;
  right: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 106px;
  gap: 8px;
  z-index: 3;
  padding: 5px;
  padding-bottom: 12px;
  border-radius: 10px;
  background-color: ${Colors.gray};
`;
