import React from "react";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headersArray";
import { getSummarizeRaws } from "./helpers/getSummarizeRaws";

const ManagerPaymentSummarizeTable = ({ consultantResults, forexRates }) => {
  return (
    <Table
      className="styled-table"
      style={{ marginTop: "30px" }}
      headersArray={HEADERS_ARRAY}
      raws={getSummarizeRaws(consultantResults, forexRates)}
    />
  );
};

export default ManagerPaymentSummarizeTable;
