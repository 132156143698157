export const CLAIM_REASONS = [
  {
    label: "Product Quality",
    value: "product_quality",
  },
  {
    label: "Product Expectation",
    value: "product_expectation",
  },
  {
    label: "Logistics",
    value: "logistics",
  },
  {
    label: "Call Center",
    value: "call_center",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "None",
    value: "none",
  },
];
