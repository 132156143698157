export function isValidHtml(html) {
  if (!html?.trim()) {
    return false;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const serializedDoc = new XMLSerializer().serializeToString(doc);
  
  const sanitizedOriginalHtml = html.trim();
  const sanitizedSerializedDoc = serializedDoc.trim();

  const sanitizedInside = sanitizedSerializedDoc.split('<body>')[1].split('</body>')[0];

  return sanitizedInside === sanitizedOriginalHtml;
}