import React from "react";
import PopUp from "@/common/components/PopUp";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import { PercentsFormDataWrapper } from "./PercentsForm.styled";
import { ButtonWrapper } from "../../../../Gamification.styled";

const PercentsForm = ({
  percentsRef,
  setAddPercentsMode,
  handleAddPercents,
  name,
  forPercentsRef,
}) => {
  return (
    <PopUp setShow={setAddPercentsMode}>
      <form onSubmit={(e) => handleAddPercents(e)}>
        <PercentsFormDataWrapper>
          <Input
            color={Colors.darkBlue}
            inputRef={forPercentsRef}
            width={90}
            min={1}
            inputWidth={100}
            name={name}
            type="number"
            required
          />
          <Input
            color={Colors.darkBlue}
            inputRef={percentsRef}
            width={90}
            max={100}
            min={1}
            inputWidth={100}
            name="Percents"
            type="number"
            required
          />
        </PercentsFormDataWrapper>
        <ButtonWrapper>
          <CustomButtonSave text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default PercentsForm;
