export const genItemsIds = (entity) => {
  const items = entity.shipping?.order_items?.legnth 
    ? entity.shipping.order_items 
    : entity.order_items;

  items.forEach((item, i) => {
    item.id = i
  });

  return items;
}