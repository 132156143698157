export const CALL_HISTORY_FIELDS = [
  {
    label: "no",
    value: { label: "i", type: "iterator" },
  },
  {
    label: "Consultant",
    value: { label: "consultant", type: "string" },
  },
  {
    label: "Started at",
    value: { label: "started_at", type: "date" },
  },
  {
    label: "Queue",
    value: { label: "queue", type: "string" },
  },
  {
    label: "Dequeue count",
    value: { label: "dequeue_count", type: "number" },
  },
  {
    label: "Created at",
    value: { label: "created_at", type: "date" },
  },
  {
    label: "Updated at",
    value: { label: "updated_at", type: "date" },
  },
  {
    label: "Previous Call Hisotry",
    value: { label: "previousCallHisotry", type: "string" },
  },
  {
    label: "Call status",
    value: { label: "call_status", type: "string" },
  },
  {
    label: "Order status",
    value: { label: "order_status", type: "string" },
  },
  {
    label: "Shipping status",
    value: { label: "shipping_status", type: "string" },
  },
  {
    label: "Lms sent at",
    value: { label: "lms_sent_at", type: "date" },
  },
  {
    label: "Lms delivery date",
    value: { label: "lms_delivery_date", type: "date" },
  },
  {
    label: "Order created at",
    value: { label: "order_created_at", type: "date" },
  },
  {
    label: "Email",
    value: { label: "email", type: "string" },
  },
  {
    label: "Phone number",
    value: { label: "phone_number", type: "string" },
  },
  {
    label: "Order value",
    value: { label: "value", type: "number" },
  },
  {
    label: "No resposne reason",
    value: { label: "no_response_reason", type: "string" },
  },
  {
    label: "Waiting time",
    value: { label: "waiting_time", type: "number" },
  },
  {
    label: "Talking time",
    value: { label: "talking_time", type: "number" },
  },
];
