import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  checkLanguage,
  createGeneralQuestionTicket,
} from "../../../../../../API/repositories/tickets";
import { Colors } from "../../../../../../common/colors/colors";
import ErrorLang from "../../../../../../common/components/ErrorLang";
import PopUp from "../../../../../../common/components/PopUp";
import SelectInput from "../../../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../../../common/hooks/requestHook";
import { Flex, H5 } from "../../../../contact/tickets/TicketsForCalling";
import ContactSearch from "@/common/components/contactSearch/ContactSearch";
import { getSearchedContactWithOrders } from "@/API/repositories/contact";
import { CheckedDataWrapper, ContactsCloser } from "./CreateNewTicket.styled";
import Loading from "@/common/components/Loading";
import { getDepartments } from "@/API/repositories/department";
import userManager from "@/API/userManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findSuperior } from "@/common/functions/findSuperior";
import RichTextEditor from "@/common/components/richTextEditor/RichTextEditor";
import { handleExtractDelta } from "@/common/functions/handleExtractDelta";

const RedSspan = styled.span`
  color: red;
`;

const Form = styled.form``;

const TEAM_LEADER_OPTIONS = [
  {
    label: "Order problem",
    value: "order_problem",
    order_required: true,
    file_required: true,
  },
  {
    label: "Order question",
    value: "order_question",
    order_required: true,
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Bonus information",
    value: "bonus_information",
  },
];

const CreateNewTicket = ({
  setShowQuestionTicket,
  translation,
  handleGetConsultantTicketsToDo = () => {},
  addMessage,
  orderId,
  contactId,
  setNewTicketCreating = () => {},
}) => {
  const [departments, setDepartments] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [selectedRevicer, setSelectedRevicer] = useState();
  const [userErrorLangInfo, setUserErrorLangInfo] = useState();
  const [contacts, setContacts] = useState(contactId);
  const [checkedOrder, setCheckedOrder] = useState(orderId);
  const [checkedContact, setCheckedContact] = useState();

  const fileRef = useRef();
  const textareaRef = useRef();

  const { makeRequest } = useRequestsContext();
  const {
    commonData: { users },
    getActiveUsersOptions
  } = useCommonDataContext();
  const usersOptions = getActiveUsersOptions();

  const quillRef = useRef();

  const handleSearch = async () => {
    if (!textareaRef.current?.value)
      return addMessage("Type something before search", "error");

    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, textareaRef.current.value)
    );

    if (!response.data?.length > 0) return addMessage("Not found anyone");

    setContacts(() => response.data);

    textareaRef.current.value = "";
  };

  const checkMessageLanguage = async (e) => {
    e && e.preventDefault();

    const checkLanguageResponse = await makeRequest(
      checkLanguage.bind(null, quillRef.current.getText())
    );

    if (!checkLanguageResponse.data)
      return addMessage("Something went wrong!", "error");

    if (
      checkLanguageResponse.data.lang.toLowerCase() !==
      checkLanguageResponse.data.userLang
    ) {
      setUserErrorLangInfo(checkLanguageResponse.data);
      return false;
    }

    return true;
  };

  const handleTicketQuestionCreate = async (e) => {
    e && e.preventDefault();

    if (!selectedRevicer || !selectedTitle || !quillRef.current.getText()?.trim()) {
      addMessage("Fill in all required fields", "error");

      return;
    }

    if (selectedTitle.file_required && !fileRef.current?.files[0]) {
      addMessage("Add file", "error");

      return;
    }

    const shouldHaveOrder =
      selectedTitle.order_required && !checkedOrder && !checkedContact;

    if (shouldHaveOrder) {
      return addMessage(
        "Cannot create ticket without order or contact",
        "error"
      );
    }

    const isValid = await checkMessageLanguage(e);

    if (isValid) {
      await sendTicketQuestionCreate(e);
    }
  };

  const sendTicketQuestionCreate = async (e) => {
    e && e.preventDefault();

    setNewTicketCreating(() => true);

    const formData = new FormData();

    formData.append("reciver", selectedRevicer.value);
    formData.append("topic", selectedTitle.value);
    formData.append("quill_delta", JSON.stringify(handleExtractDelta(quillRef)));

    if (checkedContact) {
      formData.append("contact", checkedContact);
    }

    if (checkedOrder) {
      formData.append("order", checkedOrder);
    }

    if (fileRef.current?.files[0])
      formData.append("file", fileRef.current.files[0]);

    const response = await makeRequest(
      createGeneralQuestionTicket.bind(null, formData)
    );

    if (!response.data) addMessage("Couldn't create ticket", "error");

    if (response.data) {
      setShowQuestionTicket(() => false);
      addMessage("Success", "success");

      await handleGetConsultantTicketsToDo();
    }

    setNewTicketCreating(() => false);
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDepartments(() => response.data);
  };

  const handleSetReceiver = (reciver) => {
    setSelectedRevicer(reciver);
    setSelectedTitle(() => null);
  };

  const userId = userManager.getUser().id;
  const superior = usersOptions.find(u => u.value === findSuperior(userId, users)?._id);

  const getReceiverOptions = () => {
    const reciverOptions = [];

    if (superior) {
      reciverOptions.push(superior);
    }

    if (departments) {
      reciverOptions.push(
        ...(departments?.map((d) => ({
          label: d.name,
          value: d._id,
        })) || [])
      );
    }

    return reciverOptions;
  };

  const getTopicsOptions = () => {
    if (selectedRevicer?.value === superior?.value) {
      return TEAM_LEADER_OPTIONS;
    }

    return departments?.find((d) => d._id === selectedRevicer?.value)?.topics;
  };

  const resetCheckedData = () => {
    setCheckedContact(() => null);
    setCheckedOrder(() => null);
  };

  useEffect(() => {
    loadDepartments();
  }, []);

  return (
    <>
      {!departments?.length && <Loading />}
      <PopUp setShow={setShowQuestionTicket}>
        {!!contacts?.length && (
          <ContactsCloser onClick={() => setContacts(null)} />
        )}
        <h3 style={{ color: Colors.darkBlue, marginBottom: "30px" }}>
          {translation["ticket_question"]}
        </h3>
        {(checkedContact || checkedOrder) && (
          <CheckedDataWrapper onClick={() => resetCheckedData()}>
            Added one {checkedContact ? "contact" : "order"}
            <i className="fa fa-check" />
          </CheckedDataWrapper>
        )}
        <ContactSearch
          contacts={contacts}
          textareaRef={textareaRef}
          handleSearch={handleSearch}
          setCheckedContact={setCheckedContact}
          setCheckedOrder={setCheckedOrder}
          setContacts={setContacts}
          checkedContact={checkedContact}
          checkedOrder={checkedOrder}
        />
        <Form
          style={{ minWidth: "600px" }}
          onSubmit={(e) => handleTicketQuestionCreate(e)}
        >
          <SelectInput
            required
            width={140}
            color={Colors.darkBlue}
            name={translation["receiver"]}
            selected={selectedRevicer}
            setSelected={handleSetReceiver}
            options={getReceiverOptions()}
            selectWidth={350}
            fontSize={24}
          />
          <SelectInput
            required
            width={140}
            color={Colors.darkBlue}
            name={translation["topic"]}
            selected={selectedTitle}
            setSelected={setSelectedTitle}
            options={getTopicsOptions()}
            selectWidth={350}
            fontSize={24}
            disabled={!selectedRevicer}
          />
          <div style={{ marginTop: "40px" }}>
            <RichTextEditor ref={quillRef} />
          </div>
          <Flex>
            <H5>
              {translation["file"]}:{" "}
              {selectedTitle?.file_required && <RedSspan>*</RedSspan>}
            </H5>
            <input
              style={{ width: "350px", marginLeft: "30px" }}
              color={Colors.darkBlue}
              ref={fileRef}
              type="file"
              accept="application/pdf,image/jpg,image/png,image/jpeg"
            />
          </Flex>
          <button
            className="btn btn-warning"
            type="submit"
            style={{ float: "right", margin: "20px 0 -20px 0" }}
          >
            {translation["create_ticket"]}
          </button>
        </Form>
      </PopUp>
      {userErrorLangInfo && (
        <ErrorLang
          setShow={setUserErrorLangInfo}
          fromLang={userErrorLangInfo.lang}
          toLang={userErrorLangInfo.userLang}
          sendFunc={sendTicketQuestionCreate}
        />
      )}
    </>
  );
};

export default CreateNewTicket;
