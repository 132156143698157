import React, { useEffect, useState } from "react";
import { Colors } from "../../../../common/colors/colors";

const WrapTimeCounter = ({ initTime, onTimeEnd }) => {
  const [restTime, setRestTime] = useState(initTime);

  const minutes = parseInt(restTime / 60);
  const seconds = restTime % 60;

  const startWrapTime = () => {
    return setInterval(() => {
      setRestTime((prev) => {
        if (prev - 1 > 0) return prev - 1;
        onTimeEnd();
        return 0;
      });
    }, 1000);
  };

  useEffect(() => {
    const interval = startWrapTime();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <h3 style={{ color: restTime > 60 ? Colors.darkBlue : Colors.red }}>
      <span style={{ marginRight: "10px" }}>Time:</span>
      {minutes < 10 ? "0" + minutes : minutes}:
      {seconds < 10 ? "0" + seconds : seconds}
    </h3>
  );
};

export default WrapTimeCounter;
