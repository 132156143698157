import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const CustomIcon = styled.i`
  margin-right: 10px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  resize: none;
  outline: none;
  border: 1px solid #ccc;
  margin-top: 20px;

  &:focus {
    border: 1px solid ${Colors.darkBlue};
    box-shadow: 0 0 4px ${Colors.darkBlue};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
