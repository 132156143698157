import { GOOD_CALLING_THRESHOLD } from "@/common/constants/companies";
import { average } from "@/common/functions/average";
import { round } from "@/common/functions/round";
import React from "react";

const TableManagerScores = ({ managerData, setShowEdit }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th colSpan={7}>Manager Scores</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>Manger</th>
          <th>Scores</th>
          <th>Min Score</th>
          <th>Max Score</th>
          <th>Average Score</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="queue">
        {managerData ? (
          Object.entries(managerData).map(([key, value], i) => (
            <tr key={i}>
              <td>{i + 1}.</td>
              <td>{key}</td>
              <td>{value.length}</td>
              <td>
                {round(
                  Math.min.apply(
                    Math,
                    value.map((v) => v.score)
                  )
                )}{" "}
                %
              </td>
              <td>
                {round(
                  Math.max.apply(
                    Math,
                    value.map((v) => v.score)
                  )
                )}{" "}
                %
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  color:
                    average(value.map((v) => v.score)) < GOOD_CALLING_THRESHOLD
                      ? "red"
                      : "green",
                }}
              >
                {average(value.map((v) => v.score))} %
              </td>
              <td>
                <i
                  className="fa fa-eye animation-scale"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowEdit(value)}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={7}>There is no data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableManagerScores;
