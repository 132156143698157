import { sortByInvoiceNumbers } from "./sortByInvoiceNumber";
import { getMarketById } from "../../../helpers/getMarketById";
import { formatDate } from "@/common/functions/dateFormater";
import { getVatLabel } from "../../../helpers/getVatLabel";

export const getRaws = (orders, markets, correctionNumber) =>
  sortByInvoiceNumbers(orders)
    .filter((order) =>
      correctionNumber ? !!order._ticket.invoice.correction_number : true
    )
    .map((order, i) => (
      <tr key={order._id}>
        <td>{i + 1}</td>
        <td>{getMarketById(order.market, markets)?.name}</td>
        <td>{formatDate(order._ticket.invoice.issue_date, "YYYY-MM")}</td>
        <td>
          {formatDate(
            order.shipping.status_delivered_at ||
              order.shipping.status_delivered_no_payment_at,
            "YYYY-MM-DD"
          )}
        </td>
        <td>{formatDate(order._ticket.invoice.issue_date, "YYYY-MM-DD")}</td>
        <td>{order._ticket.invoice.invoice_number || "----"}</td>
        <td>{order._ticket.invoice.correction_number || "----"}</td>
        <td>{order._ticket.invoice.comapny_name}</td>
        <td>{order._contact?.full_name}</td>
        <td>
          {order.order_items.reduce((prev, next) => prev + next.price, 0)}{" "}
          {order.currency}
        </td>
        <td>{getVatLabel(order._ticket.invoice.vat_amount)}</td>
        <td>
          {order.order_items.reduce((prev, next) => prev + next.price, 0)}{" "}
          {order.currency}
        </td>
      </tr>
    ));
