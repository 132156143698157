import React from "react";
import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import Warning from "@/pages/admin/validInvoices/common/components/warning/Warning";
import { ButtonsWrapper, Embed } from "./InvoicePreview.styled";

const InvoicePreview = ({
  pdfSource,
  setPdfSource,
  handleAccept,
  showWarning,
  setShowWarning,
}) => {
  return (
    <PopUp setShow={setPdfSource}>
      <Embed src={pdfSource + "#toolbar=0"} height={700} width={495} />
      <ButtonsWrapper>
        <CustomButtonAdd
          defaultText="Edit"
          color={Colors.red}
          onClick={() => setPdfSource(() => null)}
        />
        <CustomButtonAdd
          defaultText="Accept"
          color={Colors.green}
          onClick={() => setShowWarning(() => true)}
        />
      </ButtonsWrapper>
      {showWarning && (
        <Warning setShowWarning={setShowWarning} handleSubmit={handleAccept} />
      )}
    </PopUp>
  );
};

export default InvoicePreview;
