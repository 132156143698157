export const HEADERS = [
	"No.", 
	"Market", 
	"Product", 
	"Sell date", 
	"Client name", 
	"Claim type", 
	"Claim entry date", 
	"Claim method", 
	"After delivery days", 
	"Reason", 
	"Feedback", 
	"Tags"
]