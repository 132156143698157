import React from "react";
import styled from "styled-components";
import { Colors } from "../colors/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: -20px;
    color: ${Colors.darkBlue};
    font-size: 20px;
  }
`;

const TextArea = ({
  name,
  label = null,
  textAreaRef,
  width = "900px",
  fontSize = "16px",
  marginTop = "20px",
  minHeight = "100px",
  defaultValue,
  placeholder = "type sth special...",
  resize = "none",
  required = false,
  onChange = () => {},
}) => {
  return (
    <Wrapper>
      {label && (
        <label>
          {label}:{required && <span style={{ color: Colors.red }}>*</span>}
        </label>
      )}
      <textarea
        name={name}
        ref={textAreaRef}
        style={{
          width: width,
          fontSize: fontSize,
          marginTop: marginTop,
          minHeight: minHeight,
          resize: resize,
        }}
        defaultValue={defaultValue || ""}
        required
        placeholder={placeholder}
        aria-required={required}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default TextArea;
