import { compareDates } from "@/common/functions/compareDates";
import { findMarketById } from "./findMarketById";
import { formatDate } from "@/common/functions/dateFormater";
import { countOrderItemsPrice } from "./countOrderItemsPrice";
import { CorrectionIcon } from "../ClaimsReport.styled";
import { Colors } from "@/common/colors/colors";
import { countOriginalSum } from "./countOriginalSum";
import { countPolishCurrencySum } from "./countPolishCurrencySum";
import Icon from "@/common/components/Icon";
import { checkCorrection } from "./checkCorrection";
import { checkInvoice } from "./checkInvoice";
import { checkInvoiceCorrection } from "./checkInvoiceCorrection";
import { checkValidInvoiceCorrection } from "./checkValidInvoiceCorrection";

export const getRaws = ({
  orders,
  correctionNumberFilterRef,
  markets,
  setOrderWithInvoice,
}) => {
  const dataRaws = orders
    .sort((orderA, orderB) =>
      compareDates(
        new Date(orderA.claim.paid_date),
        new Date(orderB.claim.paid_date)
      )
    )
    .filter((order) =>
      correctionNumberFilterRef.current?.checked ? checkCorrection(order) : true
    )
    .map((order, i) => (
      <tr>
        <td>{`${i + 1}.`}</td>
        <td>{findMarketById(order.market, markets)?.name}</td>
        <td>{formatDate(order.claim.paid_date, "MM/YYYY")}</td>
        <td>
          {formatDate(
            order.shipping.status_delivered_at ||
              order.shipping.status_delivered_no_payment_at,
            "YYYY-MM-DD"
          )}
        </td>
        <td>{formatDate(order.claim.paid_date, "YYYY-MM-DD")}</td>
        <td>{order._contact.full_name}</td>
        <td>
          {countOrderItemsPrice(order)} {order.currency}{" "}
        </td>
        <td>{order.claim.polish_currency || 0} pln</td>
        <td>
          {checkInvoice(order) ? (
            checkInvoiceCorrection(order) ? (
              <CorrectionIcon
                onClick={() => setOrderWithInvoice(order)}
                className="fa fa-check"
                color={Colors.green}
              />
            ) : (
              <CorrectionIcon
                onClick={() => setOrderWithInvoice(order)}
                className="fa fa-circle-xmark"
                color={Colors.red}
              />
            )
          ) : checkValidInvoiceCorrection(order) ? (
            <Icon
              cursor="default"
              color={Colors.darkGray2}
              name="fa fa-check"
            />
          ) : (
            "-----"
          )}
        </td>
      </tr>
    ));

  const sumRaw = (
    <tr>
      <td></td>
      <td>Sum</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        {countOriginalSum(orders, correctionNumberFilterRef)}{" "}
        {orders[0]?.currency}
      </td>
      <td>{countPolishCurrencySum(orders, correctionNumberFilterRef)} pln</td>
      <td></td>
    </tr>
  );

  dataRaws.push(sumRaw);

  return dataRaws;
};
