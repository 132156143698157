export const CALL_CENTER_POSITION_OPTIONS = [
  {
    label: "Junior Specialist",
    value: "junior",
  },
  {
    label: "Regular Specialist",
    value: "regular",
  },
  {
    label: "Senior Specialist",
    value: "senior",
  },
  {
    label: "Team Leader",
    value: "team_leader",
  },
]