import { updateCallCenterCandidate } from '@/API/repositories/callCenterCandidate';
import { Colors } from '@/common/colors/colors';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput';
import { CONSULTANT_MANAGER_ROLES } from '@/common/constants/consultantRoles';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import CustomButtonSave from '@/common/components/buttons/CustomButtonSave';
import Input from '@/common/components/Input';
import ToggleSwitch from '@/common/components/ToggleSwitch';
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd';
import StatusChange from './components/statusChange/StatusChange';
import { CANDIDATE_INTERACTION_STATUSES, MONTH_HOURS, STATUSES_LABELS } from '@/common/constants/recruitmentStatuses';
import RecruitmentChat from '@/common/components/recruitmentChat/RecruitmentChat';
import AlertMessage from './components/alertMessage/AlertMessage';
import { ButtonWrapper, FlexForm, SwitchesWrapper, Title } from '../../RecruitmentManager.styled';
import { formatDate, formatToDateTamplate } from '@/common/functions/dateFormater';
import formatToGMT from '@/common/functions/formatToGMT';

const EDITABLE_STATUSES = [CANDIDATE_INTERACTION_STATUSES.NEW, CANDIDATE_INTERACTION_STATUSES.PENDING, CANDIDATE_INTERACTION_STATUSES.NEXT_NEW, CANDIDATE_INTERACTION_STATUSES.NEXT_PENDING, CANDIDATE_INTERACTION_STATUSES.REJECT];
const BUTTONS_BLOCKED_STATUSES = [CANDIDATE_INTERACTION_STATUSES.SECOND_STAGE_ACCEPT, CANDIDATE_INTERACTION_STATUSES.TO_HIRE];

const CandidateForm = ({ 
  setShow, 
  selectedCandidate, 
  addMessage,
  callCenterPositionsOptions,
  recruitmentPagesOptions,
  reload = () => {},
}) => {
  const [experienceSet, setExperienceSet] = useState();
  const [chatHeight, setChatHeight] = useState();
  const [showStatusChange, setShowStatusChange] = useState();
  const [showAlertMessage, setShowAlertMessage] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { options: { marketsOptions }, filterUsersByRoles } = useCommonDataContext();

  const inputsRef = useRef();


  const formik = useFormik({
    initialValues: {
      market: marketsOptions.find(o => o.value === selectedCandidate.market),
      call_center_manager: filterUsersByRoles(CONSULTANT_MANAGER_ROLES).find(o => o.value === selectedCandidate.call_center_manager),
      recruitment_page: recruitmentPagesOptions.find(page => selectedCandidate.recruitment_page === page.value),
      call_center_position: callCenterPositionsOptions.find(position => selectedCandidate.call_center_position === position.value),
      first_name: selectedCandidate.first_name,
      last_name: selectedCandidate.last_name,
      email: selectedCandidate.email,
      phone: selectedCandidate.phone,
      status: selectedCandidate.status,
      has_headset: !!selectedCandidate.has_headset,
      has_experience: !!selectedCandidate.has_experience,
      month_hours: MONTH_HOURS.find(h => h.value === selectedCandidate.month_hours),
      possible_start_date: selectedCandidate.possible_start_date && formatToDateTamplate(selectedCandidate.possible_start_date),
      stage_two_date: selectedCandidate.stage_two_date && formatDate(selectedCandidate.stage_two_date, 'YYYY-MM-DD HH:mm'),
      recall_date: selectedCandidate.recall_date && formatDate(selectedCandidate.recall_date, 'YYYY-MM-DD HH:mm'),
      decision_to_start_cooperation: selectedCandidate.decision_to_start_cooperation && formatDate(selectedCandidate.decision_to_start_cooperation, 'YYYY-MM-DD HH:mm'),
      headset_number: selectedCandidate.headset_number,
    }, 
    onSubmit: (values) => handleSubmit(foramtBeforeSubmit(values)),
  });

  const foramtBeforeSubmit = (values) => {
    return {
      ...values,
      market: values.market.value,
      call_center_manager: values.call_center_manager.value,
      recruitment_page: values.recruitment_page.value,
      call_center_position: values.call_center_position.value,
      month_hours: values.month_hours?.value,
      possible_start_date: values.possible_start_date && formatToGMT(values.possible_start_date),
      stage_two_date: values.stage_two_date && formatToGMT(values.stage_two_date),
      recall_date: values.recall_date && formatToGMT(values.recall_date),
      decision_to_start_cooperation: values.decision_to_start_cooperation && formatToGMT(values.decision_to_start_cooperation),
    };
  }

  const handleSubmit = async (payload) => {
    if (experienceSet) {
      return setShowAlertMessage("Please, check if you have added details about experience before saving");
    }

    const response = await makeRequest(updateCallCenterCandidate.bind(null, payload, selectedCandidate._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  useEffect(() => {
    setChatHeight(inputsRef.current?.offsetHeight);
  }, [inputsRef.current?.offsetHeight]);

  return (
    <PopUp padding='10px 20px 15px' setShow={setShow}>
      {hasUnfilledRequest(updateCallCenterCandidate) && <Loading />}
      {showStatusChange && <StatusChange setShow={setShowStatusChange} status={showStatusChange} formik={formik} />}
      <FlexForm onSubmit={formik.handleSubmit}>
        <div ref={inputsRef}>
          <Title>
            {STATUSES_LABELS.find(s => s.value === formik.values.status)?.label}
          </Title>
          <SelectInput
            color={Colors.darkBlue}
            name="Call center position"
            disabled={!formik.values.market?.value || !EDITABLE_STATUSES.includes(selectedCandidate.status)}
            options={callCenterPositionsOptions.filter(position => position.market === formik.values.market.value)}
            width={200}
            required
            value={formik.values.call_center_position}
            selected={formik.values.call_center_position}
            setSelected={(value) => formik.setFieldValue('call_center_position', value)}
          />
          <SelectInput
            options={MONTH_HOURS}
            color={Colors.darkBlue}
            name="Month hours"
            value={formik.values.month_hours}
            setSelected={value => formik.setFieldValue('month_hours', value)}
            selected={formik.values.month_hours}
            required
            type='number'
            width={200}
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          <Input
            id={"first_name"}
            color={Colors.darkBlue}
            name="First name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          <Input
            id={"last_name"}
            color={Colors.darkBlue}
            name="Last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          <Input
            id={"email"}
            color={Colors.darkBlue}
            name="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
            type='email'
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          <Input
            id={"phone"}
            color={Colors.darkBlue}
            name="Phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          <Input
            id={"possible_start_date"}
            color={Colors.darkBlue}
            name="Possible start date"
            value={formik.values.possible_start_date}
            onChange={formik.handleChange}
            required
            requiredSign
            type='date'
            width={200}
            disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
          />
          {selectedCandidate.stage_two_date && (
            <>
              <Input
                id={"stage_two_date"}
                color={Colors.darkBlue}
                name="Stage two call date"
                value={formik.values.stage_two_date}
                onChange={formik.handleChange}
                required
                requiredSign
                type='datetime-local'
                width={200}
                disabled
              />
              {selectedCandidate?.decision_to_start_cooperation && (
                <Input
                  id={"decision_to_start_cooperation"}
                  color={Colors.darkBlue}
                  name="Decision to start cooperation"
                  value={formik.values.decision_to_start_cooperation}
                  onChange={formik.handleChange}
                  required
                  requiredSign
                  type='datetime-local'
                  width={200}
                  disabled
                />
              )}
              {selectedCandidate?.headset_number && (
                <Input
                  id={"headset_number"}
                  color={Colors.darkBlue}
                  name="Headset id"
                  value={formik.values.headset_number}
                  onChange={formik.handleChange}
                  width={200}
                  disabled
                />
              )}
            </>
          )}
          {formik.values.recall_date && (
              <Input
              id={"recall_date"}
              color={Colors.darkBlue}
              name="Recall date"
              value={formik.values.recall_date}
              onChange={formik.handleChange}
              required
              requiredSign
              type='datetime-local'
              width={200}
              disabled
            />
          )}
          <SwitchesWrapper>
            <ToggleSwitch
              name="has_headset"
              onChange={formik.handleChange}
              text="Has headset:"
              width={210}
              checked={formik.values.has_headset}
              disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
            />
            <ToggleSwitch
              name="has_experience"
              onChange={(e) => {
                formik.handleChange(e);
                setExperienceSet(e.target.checked);
              }}
              text="Has expericence:"
              width={210}
              checked={formik.values.has_experience}
              disabled={!EDITABLE_STATUSES.includes(selectedCandidate.status)}
            />
          </SwitchesWrapper>
        </div>
        <div>
          {selectedCandidate && <RecruitmentChat chatHeight={chatHeight} candidate={selectedCandidate} reload={reload} />}
          <ButtonWrapper>
            <CustomButtonAdd
              disabled={BUTTONS_BLOCKED_STATUSES.includes(selectedCandidate.status) || CANDIDATE_INTERACTION_STATUSES.REJECT === selectedCandidate.status}
              onClick={() => {
                formik.setFieldValue('status', CANDIDATE_INTERACTION_STATUSES.REJECT);
                formik.handleSubmit();
              }}
              defaultText={"Reject"}
              color={Colors.red}
            />
            <CustomButtonAdd
              disabled={BUTTONS_BLOCKED_STATUSES.includes(selectedCandidate.status)}
              onClick={() => {
                if (!selectedCandidate.stage_two_date) {
                  if (!formik.values.month_hours || !formik.values.possible_start_date) {
                    return addMessage("Please fill all fields before accept", "error");
                  }
                  setShowStatusChange(CANDIDATE_INTERACTION_STATUSES.ACCEPT);
                } else {
                  formik.setFieldValue('status', CANDIDATE_INTERACTION_STATUSES.SECOND_STAGE_ACCEPT);
                  formik.handleSubmit();
                }
              }}
              color={Colors.green}
              defaultText={"Accept"}
            />
            <CustomButtonAdd
              disabled={BUTTONS_BLOCKED_STATUSES.includes(selectedCandidate.status)}
              onClick={() => setShowStatusChange(CANDIDATE_INTERACTION_STATUSES.NO_RESPONSE)}
              defaultText={"No response"}
            />
            <CustomButtonAdd
              disabled={BUTTONS_BLOCKED_STATUSES.includes(selectedCandidate.status)}
              onClick={() => setShowStatusChange(CANDIDATE_INTERACTION_STATUSES.SHIFT_CALL)}
              defaultText={"Shift call"}
            />
            <CustomButtonSave disabled={BUTTONS_BLOCKED_STATUSES.includes(selectedCandidate.status)} styles={{ margin: 0 }} text="Save" />
          </ButtonWrapper>
        </div>
        {showAlertMessage && <AlertMessage setExperienceSet={setExperienceSet} message={showAlertMessage} setShow={setShowAlertMessage} />}
      </FlexForm>
  </PopUp>
  )
}

export default CandidateForm
