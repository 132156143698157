import {
  formatDate,
  formatToDateTamplate,
} from "@/common/functions/dateFormater";
import { getMarketById } from "../../../helpers/getMarketById";
import { LimitedWidthCell } from "../ValidInvoicesReportTable.styled";
import { getVatLabel } from "../../validInvoicesSearchBar/helpers/getVatLabel";
import { countOrderItemsSum } from "../../../helpers/countOrderItemsSum";

export const getRaws = (tickets, markets) =>
  tickets.map((ticket, i) => (
    <tr key={ticket._id}>
      <td>{i + 1}</td>
      <td>{getMarketById(ticket._order.market, markets)?.name}</td>
      <td>{formatDate(ticket.valid_invoice.issue_date, "YYYY-MM")}</td>
      <td>{formatToDateTamplate(ticket.valid_invoice.sold_date)}</td>
      <td>{formatToDateTamplate(ticket.valid_invoice.issue_date)}</td>
      <td>{ticket.valid_invoice.invoice_number || "----"}</td>
      <td>{ticket.valid_invoice.correction_number || "----"}</td>
      <LimitedWidthCell>
        {ticket.valid_invoice.company_name || ticket.valid_invoice.full_name}
      </LimitedWidthCell>
      <LimitedWidthCell>{ticket._contact.full_name}</LimitedWidthCell>
      <td>
        {countOrderItemsSum(ticket.valid_invoice.order_items)}{" "}
        {ticket._order.currency}
      </td>
      <td>{getVatLabel(ticket.valid_invoice.vat_amount)}</td>
      <td>
        {countOrderItemsSum(ticket.valid_invoice.order_items)}{" "}
        {ticket._order.currency}
      </td>
    </tr>
  ));
