import { findSuperior } from "@/common/functions/findSuperior";

export const filterQueueOptionsForManager = (queuesOptions, users, mangerId) =>
  queuesOptions.filter((queue) => {
    const managerTeamIds = users
      .filter((user) => findSuperior(user._id, users)?._id === mangerId)
      .map((user) => user._id);

    return queue.value.consultants.some((id) => managerTeamIds.includes(id));
  });
