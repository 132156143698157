import React, { useEffect, useState } from "react";
import {
  getAllSMSTemplate,
  getNumbersFromCloudTalk,
} from "@/API/repositories/calling";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { Wrapper } from "./SMSCreate.styled";
import SMSTemplateTable from "./components/smsTemplateTable/SMSTemplateTable";
import SMSForm from "./components/smsForm/SMSForm";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";

const SMSCreate = () => {
  const [showCreate, setShowCreate] = useState();
  const [showEdit, setShowEdit] = useState();
  const [data, setData] = useState();
  const [cloudTalkNumbersOptions, setCloudTalkNumbersOptions] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const { makeRequest } = useRequestsContext();

  const handleGetCommonData = async () => {
    const cloudTalkNumbers = await makeRequest(getNumbersFromCloudTalk);

    if (cloudTalkNumbers.data) {
      setCloudTalkNumbersOptions(() => cloudTalkNumbers.data);
    }
  };

  const handleSelectTemplate = async (e, element) => {
    e.preventDefault();

    setSelectedTemplate(() => element);
    setShowEdit(() => true);
  };

  const handleGetSmsTemplates = async () => {
    const smsResponse = await makeRequest(getAllSMSTemplate);

    if (smsResponse.data) {
      setData(() => smsResponse.data);
    }
  };

  useEffect(() => {
    handleGetCommonData();
    handleGetSmsTemplates();
  }, []);

  return (
    <Wrapper>
      <div>
        <CustomButtonAdd
          onClick={() => setShowCreate(() => true)}
          defaultText="Create SMS template"
        />
      </div>
      {data && data.length > 0 && (
        <SMSTemplateTable
          handleSelectTemplate={handleSelectTemplate}
          data={data}
        />
      )}
      {showEdit && (
        <SMSForm
          mode="edit"
          setShow={setShowEdit}
          show={showEdit}
          handleGetSmsTemplates={handleGetSmsTemplates}
          cloudTalkNumbersOptions={cloudTalkNumbersOptions}
          selectedTemplate={selectedTemplate}
        />
      )}
      {showCreate && (
        <SMSForm
          mode="create"
          setShow={setShowCreate}
          handleGetSmsTemplates={handleGetSmsTemplates}
          cloudTalkNumbersOptions={cloudTalkNumbersOptions}
        />
      )}
    </Wrapper>
  );
};

export default SMSCreate;
