export const calculateHoursPayment = ({ userId, hours, rate, users }) => {
  const user = users.find((u) => u._id === userId);
  if (!user) {
    return;
  }

  const result = hours * rate;
  return user.contract_type.split("_")[0]?.toLowerCase() === "ro"
    ? Math.min(result, 4200)
    : result;
};
