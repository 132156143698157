import Quill from 'quill';
import React, { forwardRef, useEffect, useRef } from 'react'
import 'quill/dist/quill.bubble.css'
import './style.css'

const RichTextViewer = forwardRef(
  ({ defaultValue }, _) => {
    const ref = useRef(null);
    const containerRef = useRef(null);


    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
    
      const quill = new Quill(editorContainer, {
        modules: {
          toolbar: [
          ],
        },
        readOnly: true,
        theme: 'bubble',
      });

      ref.current = quill;

      const editor = editorContainer.querySelector('.ql-editor');

      editor?.classList?.add('ql-readonly');

      quill.setContents(defaultValue);

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref, defaultValue]);

    return <div ref={containerRef}></div>;
  },
);

export default RichTextViewer
