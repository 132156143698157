import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createFaq,
  getAllFaqs,
  updateFaq,
} from "../../../../API/repositories/faq";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import FaqAction from "./FaqAction";
import MessageQueue, { useMessageQueue } from "../../../../common/messageProvider";
import Loading from "../../../../common/components/Loading";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin: 30px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 1200px;
`;

const FaqList = () => {
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const [create, setCreate] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const createNewFaq = async (e, faq) => {
    e.preventDefault();
    const response = await makeRequest(createFaq.bind(null, faq));

    if (response.data) {
      setCreate(() => null);
      addMessage("Created", "success");
    } else {
      addMessage("Couldn't create", "error");
    }
  };

  const updateFaqFunc = async (e, id, faq) => {
    e.preventDefault();
    const response = await makeRequest(updateFaq.bind(null, id, faq));

    if (response.data) {
      setEdit(() => null);
      addMessage("Saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const loadData = async () => {
    const response = await makeRequest(getAllFaqs);

    if (response.data) {
      setData(() =>
        response.data
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .sort((a, b) => a.type.localeCompare(b.type))
      );
    }
  };

  useEffect(() => {
    loadData();
  }, [edit, create]);

  return (
    <Wrapper>
      {(hasUnfilledRequest(createFaq) || hasUnfilledRequest(updateFaq)) && <Loading />}

      <MessageQueue messages={messages} removeMessage={removeMessage} />

      <ButtonWrapper>
        <button
          className="btn btn-warning"
          onClick={() => setCreate(() => true)}
        >
          New <i className="fa fa-question"></i>
        </button>
      </ButtonWrapper>

      {data && (
        <TableWrapper>
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Type</th>
                <th>Question</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data.map((d, i) => (
                <tr>
                  <td>{i + 1}.</td>
                  <td>{d.type}</td>
                  <td>{d.original_question}</td>
                  <td>
                    <i
                      className="fa fa-edit animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() => setEdit(() => d)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}
      {(create || edit) && (
        <FaqAction
          onClose={create ? setCreate : setEdit}
          data={create || edit}
          onAction={create ? createNewFaq : updateFaqFunc}
        />
      )}
    </Wrapper>
  );
};

export default FaqList;
