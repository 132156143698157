import React, { useEffect, useState } from "react";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SearchBar from "./components/SearchBar/SearchBar";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getCCEffectiveSalaryPaymentKDR } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { generateMainData } from "../ccPaymentKDR/helpers/generateMainData";
import Table from "@/common/components/Table";
import { getConsultantRows, getMarketRows } from "./helpers/getRows";
import {
  CONSULTANT_HEADERS_ARRAY,
  MARKET_HEADERS_ARRAY,
} from "./constants/headers";
import { getDataByMarket } from "./helpers/getDataByMarket";

const EOU_COMPANY = "EOU";

export default function CCPaymentShort() {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const {
    commonData: { users, markets, queues },
    options: { marketsOptions },
  } = useCommonDataContext();

  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);
  const [selectedMarketsOptions, setSelectedMarketsOptions] =
    useState(marketsOptions);

  const [consultantsData, setConsultantsData] = useState();
  const [marketsData, setMarketsData] = useState();

  const handleSearch = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getCCEffectiveSalaryPaymentKDR.bind(
        null,
        selectedMonth.value.toISOString(),
        EOU_COMPANY
      )
    );

    if (!response.data || !users[0]) return;

    const mainData = generateMainData(
      {
        data: response.data,
        markets,
        queues,
        users,
      },
      selectedMarketsOptions
    );

    mainData.sort((a, b) => a.market.name.localeCompare(b.market.name));

    const marketsData = getDataByMarket(
      mainData,
      selectedMarketsOptions,
      markets
    );

    setMarketsData(marketsData);
    setConsultantsData(mainData);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getCCEffectiveSalaryPaymentKDR) && <Loading />}
      <SearchBar
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedMarketsOptions={selectedMarketsOptions}
        setSelectedMarketsOptions={setSelectedMarketsOptions}
        marketsOptions={marketsOptions}
        handleSearch={handleSearch}
      />
      {marketsData && (
        <Table
          headersArray={MARKET_HEADERS_ARRAY}
          raws={getMarketRows(marketsData)}
          className="styled-table"
        />
      )}
      {consultantsData && (
        <Table
          headersArray={CONSULTANT_HEADERS_ARRAY}
          raws={getConsultantRows(consultantsData)}
          className="styled-table"
        />
      )}
    </WrapperWithScroll>
  );
}
