import * as moment from "moment";

export const getOrdersToDisplay = (orders, orderFields) =>   orders.map((contact) => {
  let rowToDisplay = [];

  orderFields.forEach((selected) => {
    let value;

    if (
      contact[selected.value.label] &&
      (selected.value.label === "created_at" ||
        selected.value.label === "suggested_delivery_date" ||
        selected.value.label === "confirmation_date")
    ) {
      if (selected.value.label === "suggested_delivery_date")
        value = moment(contact[selected.value.label]).format("YYYY-MM-DD");
      else
        value = moment(contact[selected.value.label]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
    } else {
      value = contact[selected.value.label];
    }

    rowToDisplay.push(value);
  });

  return { data: rowToDisplay, _id: contact._id };
});