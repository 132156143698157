import React from "react";
import { Colors } from "@/common/colors/colors";
import { ActionWrapper } from "./ArchiveTable.styled";

const ArchiveTable = ({ data, selectedToQueue, setSelectedToQueue }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>No</th>
          <th>Full Name</th>
          <th>Number</th>
          <th>Adress</th>
          <th>Postal Code</th>
          <th>Archive by</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data?.map((d, i) => (
          <tr>
            <td key={i}>{i + 1}.</td>
            <td>{d._contact.full_name}</td>
            <td>{d._contact.phone_number}</td>
            <td>
              {d._contact.city || d._contact.street
                ? `${d._contact.city} ${d._contact.street}`
                : "------"}
            </td>
            <td>{d._contact.postal_code || "-----"}</td>
            <td>{d._user?.username || "-----"}</td>
            <td>
              <ActionWrapper>
                <input
                  type="checkbox"
                  onClick={() =>
                    setSelectedToQueue((prev) => {
                      if (prev.includes(d._id)) {
                        return prev.filter((orderId) => orderId !== d._id);
                      }
                      return [...prev, d._id];
                    })
                  }
                  checked={selectedToQueue.includes(d._id)}
                />
                <i
                  className="fa fa-address-book animation-scale"
                  style={{ cursor: "pointer", color: Colors.darkBlue }}
                  onClick={() => {
                    window.open(`/dashboard/order/${d._id}`, "_blank").focus();
                  }}
                />
              </ActionWrapper>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ArchiveTable;
