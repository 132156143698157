import React from "react";
import Icon from "@/common/components/Icon";
import { TodoItemsListWrapper, TodoTitle } from "./TodoStatus.styled";
import TodoListItem from "./components/todoItem/TodoListItem";
import { TODO_STATUSES } from "@/common/constants/todoStatuses";

const TodoStatus = ({
  status,
  todos,
  hiddenStatuses,
  handleHiddenStatusesChange,
  handleEditTodo,
  updateStatus,
  loadingTodoId,
  loadData,
  notRead,
}) => {
  return (
    <div>
      <TodoTitle>
        <span>{TODO_STATUSES[status].title}</span>
        <Icon
          rotate={hiddenStatuses.includes(TODO_STATUSES[status].value)}
          color="white"
          name="fa fa-arrow-down"
          size={20}
          onClick={() =>
            handleHiddenStatusesChange(TODO_STATUSES[status].value)
          }
        />
      </TodoTitle>
      <TodoItemsListWrapper
        hide={hiddenStatuses.includes(TODO_STATUSES[status].value)}
      >
        {todos
          .find((group) => group._id === TODO_STATUSES[status].value)
          ?.data.map((todo) => (
            <TodoListItem
              notRead={notRead}
              loadData={loadData}
              handleEditTodo={handleEditTodo}
              todo={todo}
              key={todo._id}
              updateStatus={updateStatus}
              loadingTodoId={loadingTodoId}
              status={status}
            />
          ))}{" "}
      </TodoItemsListWrapper>
    </div>
  );
};

export default TodoStatus;
