import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #d7edf6;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginWrapper = styled.div`
  padding-top: 150px;
  width: 400px;
  height: 600px;
  position: absolute;
`;
