import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;
export const createProduct = (product) =>
  errorHandler(async () => {
    const data = await api.post("/product", product);
    return { data: data.data };
  });

export const getProducts = () =>
  errorHandler(async () => {
    const data = await api.get("/product");
    return { data: data.data };
  });

export const updateProduct = (_id, product) =>
  errorHandler(async () => {
    const data = await api.patch(`/product/${_id}`, product);
    return { data: data.data };
  });

export const getOneProduct = (id) =>
  errorHandler(async () => {
    const data = await api.get(`/product/${id}`);
    return { data: data.data };
  });

export const getProductByUserQueue = (id) =>
  errorHandler(async () => {
    const data = await api.get(`/product/by_user_queue/${id}`);
    return { data: data.data };
  });
