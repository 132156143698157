import Icon from "@/common/components/Icon";

export const getRaws = (keys, setKey) =>
  keys.map((key, i) => (
    <tr key={key._id}>
      <td>{i + 1}</td>
      <td>{key.label}</td>
      <td>{key.type}</td>
      <td>
        <Icon onClick={() => setKey(key)} name="fa fa-edit" />
      </td>
    </tr>
  ));
