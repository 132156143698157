import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const SearchBar = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: ${Colors.darkBlue};
  border: 1px dashed ${Colors.darkBlue};
  gap: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  width: 100%;
`;
