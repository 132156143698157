import React, { useState } from "react";
import moment from "moment";
import Icon from "@/common/components/Icon";
import { TodoItem, TodoItemElement } from "./TodoListItem.styled";
import { Colors } from "@/common/colors/colors";
import userManager from "@/API/userManager";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import TodoPreview from "@/common/components/todoPreview/TodoPreview";
import { createPortal } from "react-dom";
import { TODO_STATUSES } from "@/common/constants/todoStatuses";

const TodoListItem = ({
  todo,
  handleEditTodo,
  loadingTodoId,
  updateStatus,
  loadData,
  status,
  notRead,
}) => {
  const currentUserId = userManager.getUser().id;
  const [isTodoPreviewOpen, setIsTodoPreviewOpen] = useState();

  return (
    <TodoItem
      backgroundColor={
        todo.high_priority ? Colors.lightBlue2 : Colors.lightlightBlue2
      }
    >
      {loadingTodoId === todo._id ? (
        <CustomSkeleton height={10} count={3} width="800px" />
      ) : (
        <>
          <TodoItemElement>
            {notRead?.includes(todo._id) && (
              <Icon name="fa-brands fa-readme" cursor="" color={Colors.red} />
            )}
            {todo.title}
          </TodoItemElement>
          <TodoItemElement>{todo._user?.username || "myself"}</TodoItemElement>
          <TodoItemElement>
            {moment(todo.deadline).format("YYYY-MM-DD HH:mm")}
          </TodoItemElement>
          <TodoItemElement>
            {todo.created_by === currentUserId &&
              todo.status !== TODO_STATUSES.DONE.value && (
                <Icon
                  size={20}
                  name="fa fa-edit"
                  onClick={() => handleEditTodo(todo)}
                />
              )}
            {TODO_STATUSES[status]?.next && (
              <Icon
                size={20}
                name="fa fa-forward-step"
                color={Colors.green}
                onClick={() =>
                  updateStatus(todo._id, TODO_STATUSES[status]?.next)
                }
              />
            )}
            {TODO_STATUSES[status]?.previous && (
              <Icon
                size={20}
                name="fa fa-rotate-left"
                color={Colors.red}
                onClick={() =>
                  updateStatus(todo._id, TODO_STATUSES[status]?.previous)
                }
              />
            )}
            <Icon
              size={20}
              name="fa fa-eye"
              color={Colors.darkPurple}
              onClick={() => setIsTodoPreviewOpen(true)}
            />
          </TodoItemElement>
        </>
      )}
      {isTodoPreviewOpen &&
        createPortal(
          <TodoPreview
            loadData={loadData}
            setShow={setIsTodoPreviewOpen}
            todo={todo}
          />,
          document.body
        )}
    </TodoItem>
  );
};

export default TodoListItem;
