import React from "react";
import PopUp from "../../../../../common/components/PopUp";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  h3 {
    color: red;
    text-align: center;
  }
`;

const NoAswer = ({ translation, setClose }) => {
  return (
    <PopUp setShow={setClose}>
      <Wrapper>
        <h3>{translation.no_answer}</h3>
      </Wrapper>
    </PopUp>
  );
};

export default NoAswer;
