export const restartAnimations = (animationsToRestart) => {
  const animations = document.getAnimations();

  animations.forEach(animation => {
    if (animationsToRestart.includes(animation.animationName)) {
      animation.cancel();
      animation.play();
    }
  })
};
