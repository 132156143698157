import styled from "styled-components";

export const TableWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  max-height: 80vh;
  overflow: scroll;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const LimitedSizeCell = styled.td`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
