export const HEADERS_ARRAY = [
  [<th colSpan={7}>Consultant payment summarize</th>],
  [
    <th>Hours</th>,
    <th>Hours payment</th>,
    <th>Effective salary</th>,
    <th>Payment</th>,
    <th>Bonus</th>,
    <th>Gamification bonus</th>,
    <th>Equalization</th>,
  ],
];
