import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(2, 80, 114, 0.78);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 20px;
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Flex = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Confirm = ({ text, onConfirm, onDeceline }) => {
  return (
    <Wrapper>
      <Card>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => onDeceline()}
        >
          <strong>X</strong>
        </div>
        <div>Czy na pewno chcesz zaznaczyc {text} ?</div>
        <Flex>
          <button
            className="btn btn-danger"
            style={{ width: "100px" }}
            onClick={() => onDeceline()}
          >
            Nie
          </button>
          <button
            className="btn btn-success"
            style={{ width: "100px" }}
            onClick={() => onConfirm()}
          >
            Tak
          </button>
        </Flex>
      </Card>
    </Wrapper>
  );
};

export default Confirm;
