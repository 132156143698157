import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  getContactWithOrders,
  markContactAsPostalCodePromotion,
  saveConsultantContact,
} from "../../../../API/repositories/contact";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { COUNTRIES } from "../../../../common/constants/countries";
import PopUp from "../../../../common/components/PopUp";
import Comments from "../../contact/comments/Comments";
import { Colors } from "../../../../common/colors/colors";
import { saveInboundCall } from "../../../../API/repositories/queue";
import ContactInboundForm from "./ContactInboundForm";
import OrdersInBound from "./OrdersInBound";
import { MAX_WRAP_SECONDS } from "../OutboundView";
import ContactInboundOrderEdit from "./ContactInboundOrderEdit";
import translationManager from "../../../../API/translationManager";
import ReCallInfo from "../ReCallInfo";
import ReCalling from "../ReCalling";
import WrapTimeCounter from "../timer/WrapTimeCounter";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useNewMessageQueueContext } from "@/common/hooks/useNewMessageQueue";

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  gap: 20px;
`;
const ContactInbound = ({
  incoming,
  isTalking = true,
  handleEndCall,
  setCountWrapTime,
  countWrapTime,
  handleWrappTimeClear,
}) => {
  const [dataToDisplay, setDataToDisplay] = useState(null);
  const [country, setCountry] = useState();
  const [create, setCreate] = useState(false);
  const [isCallAgainDisabled, setIsCallAgainDisable] = useState(false);
  const [shouldDisableSuspicion, setShouldDisableSuspicion] = useState();

  const { makeRequest } = useRequestsContext();
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState({});
  const [promotionResult, setPromotionResult] = useState();

  const { commonData: { markets, queues, products }} = useCommonDataContext();
  const { addMessage } = useNewMessageQueueContext();

  const detectedProduct = useMemo(() => {
    const lastPhoneNumber = dataToDisplay?._calling_information?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })?.[0]?.call_center_phone_number;


    const queue = queues.find(q => q.incoming_numbers?.includes(lastPhoneNumber));

    const product = products.find(p => p._id === queue?.product);

    if (product) {
      addMessage(`You are talking about ${product?.name}!!!`, "error", 3000)
    }
  }, [
    dataToDisplay, products, queues
  ])

  const market = useMemo(() => {
    return markets.find(m => m.short === dataToDisplay?.country)
  }, [dataToDisplay])

  const handleGetContact = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getContactWithOrders.bind(null, incoming)
    );

    if (response.data) {
      setCountry(COUNTRIES.find((country) => country.value === response.data.country));
      setDataToDisplay(() => null);
      setDataToDisplay(() => response.data);
      setShouldDisableSuspicion(response.data.shouldDisableSuspicion);
    }
  };

  const handleShowOrderEdit = (e, data, create) => {
    e.preventDefault();
    setCreate(() => create);
    setOrder(() => data);
    setShow(() => true);
  };

  useEffect(() => {
    handleGetContact();
  }, [show]);

  const handleSaveContact = async (payload) => {
    if (payload.phone_number_for_courier && !validatePhoneForCourier(payload.phone_number_for_courier)) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    const payloadToSend = { ...payload, country: payload.country?.value };

    const response = await makeRequest(
      saveConsultantContact.bind(null, incoming, payloadToSend)
    );

    if (response.data) {
      await handleGetContact();
    } else {
      console.error(response.error);
    }
  };

  const handleEndInboundContact = async (e) => {
    e.preventDefault();

    const response = await makeRequest(saveInboundCall);

    if (response.data) {
      setCountWrapTime(() => false);
      setCallingClientCredentials(() => null);
      setCurrentContactOutbound(() => null);
      setShowCallingDescription(() => true);
      setIncoming(() => false);
    }
  };

  const handleCheckPromotion = async (e) => {
    e && e.preventDefault();

    let hasPromotion = {
      region: null,
      message:
        "A province with a postal code was not found " +
        dataToDisplay.postal_code,
      isGood: false,
    };

    try {
      market.regions.forEach((m) => {
        const postalCode = parseInt(dataToDisplay.postal_code.substring(0, 3));

        const foundCode = m.codes.find(
          (c) => c.from <= postalCode && c.to >= postalCode
        );

        if (foundCode) {
          hasPromotion.region = m;
          hasPromotion.message =
            "Error! Province:" +
            m.label +
            "We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)";
        }
      });

      market.promotion.forEach((prom) => {
        if (
          prom.regions.includes(hasPromotion.region._id) &&
          new Date(prom.from) - new Date(dataToDisplay.created_at) <= 0 &&
          new Date(prom.to) - new Date(dataToDisplay.created_at) >= 0
        ) {
          hasPromotion.isGood = true;
          hasPromotion.message =
            "Postal code correct!  Province: " +
            hasPromotion.region.label.toUpperCase() +
            " The customer can take advantage of the promotional offer.";
        }
      });
    } catch (e) {
      console.log(e.message);
    }

    const response = await makeRequest(
      markContactAsPostalCodePromotion.bind(
        null,
        dataToDisplay._id,
        hasPromotion.isGood
      )
    );

    await handleGetContact();

    setPromotionResult(() => hasPromotion);
  };

  const [translation, setTranslation] = useState({
    reamainingTime: "Remaining time",
    minutes: "minutes",
    seconds: "seconds",
    fullName: "Full name",
    dequeuedCount: "Dequeued count",
    commentInfo: "type your comment",
    add: "Add",
    shift: "Shift",
    noResponse: "No response",
    resign: "Resign",
    confirm: "Confirm",
    contactOrders: "Contact Orders",
    no: "No.",
    status: "Status",
    currency: "Currency",
    product: "Product",
    market: "Market",
    createdAt: "Created at",
    sendMail: "Send Mail",
    save: "Save",
    street: "Street",
    postalCode: "Postal code",
    city: "City",
    additionalInfo: "Company name (if necessary)",
    country: "Country",
    email: "E-mail",
    street: "Street",
    city: "City",
    addOrder: "Add Order",
    orders: "Orders",
    country: "Country",
    variants: "Variants",
    price: "Price",
    shippingStatus: "Shipping status",
    actions: "Actions",
    countryCode: "Country code",
    deliveryDate: "Delivery date",
    comment: "Comment",
    commentInfo: "Type comment...",
    basicInfo: "Basic info",
    product: "Product",
    confirm: "Confirm",
    items: "Items",
    send: "Send",
    phone: "phone",
    shippingInfo: "Shipping information",
    goToDashBoard: "Go to DashBoard",
    variant: "Variants",
    price: "Price",
    name: "Name",
    short: "Short",
    trash: "Trash",
    mailError: "There was an error while sending",
    mailSuccess: "Successfully sent an email",
    ticket: "Create ticket",
    createInvoice: "Create invoice",
    checkProm: "Check promotion",
    promNotFound: "A province with a postal code was not found",
    promNotAvaiable: "Error! Province:",
    promNotAvaiableTitle:
      "We are currently not running a promotional offer for this region. (Sale at a price of 9800 Kč)",
    promAvaialbleTitle: "Postal code correct!  Province:",
    promAvaiable: "The customer can take advantage of the promotional offer",
    takeNewContact: " Take new Contact",
    phone_number_for_courier: "Phone for courier",
    timeline: "Timeline",
    invoice_status: "Invoice status by color",
    mail_history: "Mail history",
    shipping_history: "Shipping history",
    suspicion: "Suspicion",
    suspect: "Suspect",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const {
    callOneceAgain,
    callingClientOnceAgain,
    setCallingClientOnceAgain,
    showResultReCall,
    setShowResultReCall,
    setShowCallingDescription,
    setCallingClientCredentials,
    setCurrentContactOutbound,
    setIncoming,
  } = useContext(CallCRMApiContextVonage);

  return (
    <>
      {dataToDisplay && (
        <PopUp
          closeIcon={false}
          setShow={() => {}}
          canOffByClickingBackground={false}
        >
          <Wrapper>
            <CardWrapper>
              <Flex>
                {!isTalking && (
                  <button
                    className="btn btn-warning animation-scale"
                    onClick={(e) => handleEndInboundContact(e)}
                  >
                    {translation.takeNewContact}
                  </button>
                )}

                {isTalking && (
                  <button
                    className="btn btn-danger animation-scale"
                    type="button"
                    onClick={(e) => {
                      setIsCallAgainDisable(() => true);
                      setTimeout(() => {
                        setIsCallAgainDisable(() => false);
                      }, 8000);
                      handleEndCall();
                    }}
                  >
                    End Call
                  </button>
                )}
                {!isTalking && (
                  <button
                    className="btn btn-danger animation-scale"
                    type="button"
                    onClick={(e) => {
                      setCountWrapTime(() => false);
                      setIsCallAgainDisable(() => true);
                      setTimeout(() => {
                        setIsCallAgainDisable(() => false);
                      }, 8000);
                      callOneceAgain();
                    }}
                    disabled={isCallAgainDisabled}
                  >
                    Call Again
                  </button>
                )}
                {countWrapTime && (
                  <WrapTimeCounter
                    initTime={MAX_WRAP_SECONDS}
                    onTimeEnd={handleWrappTimeClear}
                  />
                )}
              </Flex>
              <Flex>
                <ContactInboundForm
                  shouldDisableSuspicion={shouldDisableSuspicion}
                  setShouldDisableSuspicion={setShouldDisableSuspicion}
                  contact={dataToDisplay}
                  handleSave={handleSaveContact}
                  handleCheckPromotion={handleCheckPromotion}
                  country={country}
                  translation={translation}
                />
                <div>
                  <OrdersInBound
                    dataToDisplay={dataToDisplay}
                    handleShowOrderEdit={handleShowOrderEdit}
                    translation={translation}
                    reloadFn={handleGetContact}
                  />
                  <Comments
                    comments={dataToDisplay._comments}
                    contactId={dataToDisplay._id}
                    translation={translation}
                  />
                </div>
              </Flex>
            </CardWrapper>
            {promotionResult && (
              <PopUp setShow={setPromotionResult}>
                <h3
                  style={{
                    color: promotionResult.isGood ? Colors.green : Colors.red,
                    maxWidth: "400px",
                    textAlign: "center",
                  }}
                >
                  {promotionResult.message}
                </h3>
              </PopUp>
            )}
          </Wrapper>
        </PopUp>
      )}

      {show && (
        <ContactInboundOrderEdit
          order={order}
          inbound={true}
          setShow={setShow}
          setOrder={setOrder}
          contact={dataToDisplay}
          country={country}
          market={market}
          detectedProduct={detectedProduct}
          create={create}
        />
      )}
      {callingClientOnceAgain && (
        <ReCalling close={setCallingClientOnceAgain} />
      )}
      {showResultReCall && (
        <ReCallInfo
          showResultReCall={showResultReCall}
          setShowResultReCall={setShowResultReCall}
        />
      )}
    </>
  );
};

export default ContactInbound;
