import MDEditor from "@uiw/react-md-editor";
import React from "react";
import styled from "styled-components";
import PopUp from "../../../../common/components/PopUp";

const Wrapper = styled.div`
  border-radius: 20px;
  overflow-y: scroll;
  width: 80vw;
  padding: 30px;
  background-color: white;
`;

const ConsultantScript = ({ phoneScript, setShowScript }) => {
  return (
    <PopUp setShow={setShowScript}>
      <Wrapper>
        <MDEditor.Markdown
          source={phoneScript}
          style={{
            whiteSpace: "pre-wrap",
            backgroundColor: "white",
            color: "black",
          }}
        />
      </Wrapper>
    </PopUp>
  );
};

export default ConsultantScript;
