import React from "react";
import { DataWrapper, Title } from "../../Gamification.styled";
import {
  ParticipentWrapper,
  ParticipentName,
  ParticipentsWrapper,
} from "./Market.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";
import Stars from "../../common/components/stars/Stars";
import { getPercent } from "@/common/functions/getPercent";
import {
  COLS_AMOUNT,
  ORDER_WIDTH_SMALL,
  ROW_GAP,
  ROW_HEIGHT,
} from "./constants/layout";
import { findUserById } from "../../helpers/findUserById";
import { sortMarketStats } from "./helpers/sortMarketStats";
import { TABS } from "../../constants/tabs";
import { getWorkingDaysForMonth } from "@/common/functions/getWorkingDaysForMonth";
import { getDeliveredRange } from "@/common/functions/getDeliveredRange";
import { getOrdersRange } from "@/common/functions/getOrdersRange";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";

const Market = ({ marketGamification, selectedTab }) => {
  const currentUserId = userManager.getUser().id;

  const {
    commonData: { users, markets, queues },
  } = useCommonDataContext();

  const market = findMarketByContractType(
    markets,
    findUserById(currentUserId, users)
  );
  const workingDays = getWorkingDaysForMonth(market);
  return (
    <DataWrapper
      visibilityHidden={selectedTab !== TABS.market}
      selectedTop={true}
    >
      <Title>Market ranking</Title>
      {!!marketGamification?.length && (
        <ParticipentsWrapper
          maxHeight={`${
            Math.ceil(marketGamification?.length / COLS_AMOUNT) *
            (ROW_HEIGHT + ROW_GAP)
          }px`}
        >
          {sortMarketStats({ marketGamification, users, markets, queues }).map(
            (consultantData, i) => (
              <ParticipentWrapper winner={i < market?.winners}>
                <ParticipentName>
                  {currentUserId === consultantData.consultant ? (
                    <b>
                      {i + 1}.{" "}
                      {findUserById(consultantData.consultant, users).username}
                    </b>
                  ) : (
                    <span>
                      {i + 1}.{" "}
                      {findUserById(consultantData.consultant, users).username}
                    </span>
                  )}
                </ParticipentName>
                <Stars
                  extraStar={
                    consultantData.listening_target <=
                    consultantData.average_listening_score
                  }
                  potentialExtraStar={true}
                  showNotCompleted={false}
                  ordersRange={getOrdersRange({
                    stars_percents: consultantData.stars_percents,
                    workingDays,
                    dayHours: findUserById(consultantData.consultant, users)
                      ?.contract_hours,
                    constantMultiplier: findUserById(
                      consultantData.consultant,
                      users
                    )?.calling_system
                      ? consultantData.calling_mode_constant
                      : consultantData.normal_mode_constant,
                  })}
                  deliveredPercent={getPercent(
                    consultantData.delivered_amount,
                    consultantData.total_amount
                  )}
                  deliveredRange={getDeliveredRange(
                    consultantData.package_percents
                  )}
                  totalAmount={consultantData.total_amount}
                  orderWidth={ORDER_WIDTH_SMALL}
                  marginBottom={0}
                />
              </ParticipentWrapper>
            )
          )}
        </ParticipentsWrapper>
      )}
    </DataWrapper>
  );
};

export default Market;
