import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "../colors/colors";
import { restartAnimations } from "../functions/restartAnimations";

const CIRCLE_CIRCUMREFERENCE = 100 * Math.PI;
const INITIAL_DASHOFFSET = 0;
const FINAL_DASHOFFSET = CIRCLE_CIRCUMREFERENCE;

const CountdownWrapper = styled.div`
  width: 62px;
  height: 62px;
  overflow: hidden;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 1px 50px 1px;
`;

const CountdownCircle = styled.svg`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

const CountdownPath = styled.circle`
  fill: white;
  stroke: ${Colors.darkBlue};
  stroke-width: 3;
  stroke-dasharray: ${CIRCLE_CIRCUMREFERENCE};
  stroke-dashoffset: ${INITIAL_DASHOFFSET};
  animation: countdown ${(props) => props.animationDuration}s linear infinite;

  @keyframes countdown {
    0% {
      stroke-dashoffset: ${INITIAL_DASHOFFSET};
    }

    100% {
      stroke-dashoffset: ${FINAL_DASHOFFSET};
    }
  }
`;

const CountdownText = styled.text`
  font-size: 32px;
  dominant-baseline: middle;
  text-anchor: middle;
  font-weight: bold;
  fill: ${Colors.darkBlue};
`;

const Circle = ({ getData, refreshTime }) => {
  const [timeToRefesh, setTimeToRefresh] = useState(refreshTime || 15);
  const countDownRef = useRef();

  const refresh = async (e) => {
    e && e.preventDefault();

    restartAnimations(["countdown"]);

    getData();
    setTimeToRefresh(() => refreshTime || 15);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRefresh((prev) => {
        if (prev > 1) {
          return prev - 1;
        }

        return refreshTime || 15;
      });
    }, 1000);

    if (timeToRefesh === 1) {
      setTimeout(() => {
        getData();
        restartAnimations(["countdown"]);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeToRefesh]);

  return (
    <CountdownWrapper>
      <CountdownCircle viewBox="0 0 100 100" onClick={(e) => refresh(e)}>
        <CountdownPath
          ref={countDownRef}
          animationDuration={refreshTime || 15}
          cx="50"
          cy="50"
          r="48"
        />

        <CountdownText x="50" y="50">
          {timeToRefesh}
        </CountdownText>
      </CountdownCircle>
    </CountdownWrapper>
  );
};

export default Circle;
