import React from "react";
import { SearchBar, TextArea, Icon } from "./ContactSearch.styled";
import { KEYS_CODES } from "./contstants/keysCodes";
import Contacts from "./components/contacts/Contacts";

const ContactSearch = ({
  contacts,
  textareaRef,
  handleSearch,
  setCheckedContact,
  setCheckedOrder,
  setContacts,
  checkedContact,
  checkedOrder,
}) => {
  const onEnterPress = (e) => {
    if (e.keyCode === KEYS_CODES.enter && e.shiftKey === false) {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <>
      <SearchBar>
        <TextArea
          onKeyDown={(e) => onEnterPress(e)}
          ref={textareaRef}
          placeholder="Type contact name, email, phone number"
          rows={1}
        />
        <Icon onClick={() => handleSearch()} className="fa fa-search" />
      </SearchBar>
      {contacts?.length > 0 && (
        <Contacts
          contacts={contacts}
          setCheckedContact={setCheckedContact}
          setContacts={setContacts}
          setCheckedOrder={setCheckedOrder}
          checkedContact={checkedContact}
          checkedOrder={checkedOrder}
        />
      )}
    </>
  );
};

export default ContactSearch;
