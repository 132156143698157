import React, { useState } from "react";
import styled from "styled-components";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { sendMailByMailType } from "../../../API/repositories/mail";
import Loading from "../../../common/components/Loading";
import { confirmOrder, resendOrder } from "../../../API/repositories/order";
import CreateQuestionAdmin from "../ticket/components/createQuestionAdmin/CreateQuestionAdmin";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { getTrackingLink } from "@/common/functions/getTrackingLink";
import SendAnyMail from "./SendAnyMail";

const Flex = styled.div`
  display: flex;
  gap: 18px;
  max-width: 400px;
  align-items: start;
  flex-flow: wrap;
  height: fit-content;

  button {
    height: fit-content;
  }
`;

const OrderButtons = ({ addMessage, order, handleGetOrderWithContact }) => {
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [showSendAnyMail, setShowSendAnyMail] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSendMailMoreInfo = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "MORE_INFO_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendMailOrderConfirmation = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "ORDER_CONFIRMATION_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendInvoiceMail = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(null, "INVOICE_" + order._market.short, order._id)
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  // const handleSendIBLActivationCode = async () => {
  //   const response = await makeRequest(
  //     sendMailByMailType.bind(
  //       null,
  //       "IBL_ACTIVATION_CODE_" + order._market.short,
  //       order._id
  //     )
  //   );

  //   if (response.data) {
  //     await handleGetOrderWithContact();
  //     addMessage("Mail sent", "success");
  //   } else {
  //     addMessage("Unable to send", "error");
  //   }
  // };

  const handleSendActivationCode = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "ACTIVATION_CODE_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendCorrectionInvoiceMail = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "CORRECTION_INVOICE_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendAnyMailTemplate = async (type) => {
    const response = await makeRequest(
      sendMailByMailType.bind(null, type + order._market.short, order._id)
    );

    setShowSendAnyMail(() => null);

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleReSend = async () => {
    const response = await makeRequest(resendOrder.bind(null, order._id));

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Done", "success");
    } else {
      addMessage("Unable to resend", "error");
    }
  };

  const handleConfirm = async () => {
    const response = await makeRequest(confirmOrder.bind(null, order._id));

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Done", "success");
    } else {
      addMessage("Unable to resend", "error");
    }
  };

  return (
    <>
      {hasUnfilledRequest(confirmOrder, resendOrder) && <Loading />}
      <Flex>
        <CustomButtonAdd
          onClick={() => handleSendMailMoreInfo()}
          defaultText="Send More Info Mail"
        />
        <CustomButtonAdd
          onClick={() => handleSendMailOrderConfirmation()}
          defaultText="Send Confirmation Mail"
        />
        <CustomButtonAdd
          onClick={() => handleSendInvoiceMail()}
          defaultText="Send Invoice Mail"
          disabled={!order.sales_invoice}
        />
        {/* <CustomButtonAdd
          onClick={() => handleSendIBLActivationCode()}
          defaultText="Send IBL Activation code"
  />*/}
        <CustomButtonAdd
          onClick={() => handleSendActivationCode()}
          defaultText="Send Activation code"
        />
        <CustomButtonAdd
          onClick={() => handleSendCorrectionInvoiceMail()}
          defaultText="Send correction invoice"
          disabled={!order.correction_invoice}
        />
        <CustomButtonAdd
          disabled={!order._contact?.country || !order.shipping?.waybill_number}
          onClick={() => {
            window.open(
              getTrackingLink(order._contact?.country, order.shipping),
              {
                traget: "_blank",
              }
            );
          }}
          defaultText="Check Post"
        />
        <CustomButtonAdd
          disabled={!order?.shipping?.is_order_sent}
          onClick={() => handleReSend()}
          defaultText="ReSend"
        />
        <CustomButtonAdd
          className="btn btn-warning"
          disabled={order.status === "confirmed"}
          onClick={() => handleConfirm()}
          defaultText="Confirm"
        />
        <CustomButtonAdd
          onClick={() => setShowCreateQuestion(true)}
          defaultText="Ask a question"
        />
        <CustomButtonAdd
          onClick={() => setShowSendAnyMail(order)}
          defaultText="Send any Mail"
        />
      </Flex>
      {showCreateQuestion && (
        <CreateQuestionAdmin
          order={order}
          addMessage={addMessage}
          setShowQuestionTicket={setShowCreateQuestion}
        />
      )}
      {showSendAnyMail && (
        <SendAnyMail
          setShowSendAnyMail={setShowSendAnyMail}
          handleSendAnyMailTemplate={handleSendAnyMailTemplate}
          showSendAnyMail={showSendAnyMail}
        />
      )}
    </>
  );
};

export default OrderButtons;
