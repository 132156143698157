import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const createListeningScoring = (body) =>
  errorHandler(async () => {
    const data = await api.post(`/listening-scoring`, body);
    return { data: data.data };
  });
export const getScoringReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/listening-scoring/report?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const updateListeningScoring = (body) =>
  errorHandler(async () => {
    const data = await api.patch(`/listening-scoring`, body);
    return { data: data.data };
  });

export const currentWeekConsultantManager = () =>
  errorHandler(async () => {
    const data = await api.get(
      `/listening-scoring/current-week-manager-consultant`
    );
    return { data: data.data };
  });

export const consultantScoreResult = () =>
  errorHandler(async () => {
    const data = await api.get(`/listening-scoring/consultant-score-result`);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
