import moment from "moment";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getEffectivePaymentReport } from "../../API/repositories/reports";
import Card from "../../common/components/Card";
import CSV from "../../common/components/CSV";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import EffectivePaymentTable from "../../components/consultant/salary/EffectivePaymentTable";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";
import { findUserById } from "@/common/functions/findUserById";

const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const PaymentReport = () => {
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [data, setData] = useState();

  const { commonData, options, queryValues } = useCommonDataContext();
  const { queues, users } = commonData;
  const { queuesOptions, usersOptions } = options;
  const { isLoading } = queryValues;

  const startDateRef = useRef();
  const endDateRef = useRef();

  const startDate = moment().subtract("1", "months").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const getDataCoCsvForAll = (dataToChange) => {
    const result = [];

    const calcSalary = (bigItems, smallItems, user) => {
      return (
        bigItems * user.delivered_commision +
        smallItems * user.additional_item_commision
      );
    };

    const calcSalaryForOne = (data, user) => {
      return (
        calcSalary(data.big_items_count, data.small_items_count, user) /
        data.delivered_count
      );
    };

    dataToChange.forEach((currentUser) => {
      const consultant = findUserById(currentUser.consultant, users);
      currentUser.data
        .sort((a, b) => a.price - b.price)
        .forEach((currentData) => {
          const temp = [];

          temp.push(
            consultant?.name + "_" + consultant?.surname
          );
          temp.push(currentData.price + " " + currentUser.salary_currency);
          temp.push(
            calcSalaryForOne(currentData, currentUser.month_working_info)
              ? calcSalaryForOne(currentData, currentUser.month_working_info) + " " + currentUser.salary_currency
              : 0 + " " + currentUser.salary_currency
          );
          temp.push(currentData.orders_count);
          temp.push(currentData.delivered_count);
          temp.push(
            calcSalary(
              currentData.big_items_count,
              currentData.small_items_count,
              currentUser.month_working_info
            ) +
              " " +
              currentUser.salary_currency
          );

          result.push(temp);
        });
    });

    return result;
  };

  const handleGetReport = async () => {
    const payload = {};

    if (!selectedConsultants.length) {
      addMessage("Select consultants", "error");
      setData(() => null);

      return;
    }

    payload.from = startDateRef.current
      ? moment(startDateRef.current.value).startOf("day").toISOString()
      : moment(startDate).startOf("day").toISOString();
    payload.to = endDateRef.current
      ? moment(endDateRef.current.value).endOf("day").toISOString()
      : moment(endDate).endOf("day").toISOString();
    payload.ids = selectedConsultants.map((data) => data.value);

    const response = await makeRequest(
      getEffectivePaymentReport.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(getEffectivePaymentReport) && <Loading />}

      <MessageQueue removeMessage={removeMessage} messages={messages} />

      {!isLoading ? (
        <Card>
          <div
            style={{
              maxWidth: "80vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Input
                inputRef={startDateRef}
                name="confirmation date from"
                type="date"
                width={250}
                value={startDate}
                inputWidth={140}
              />
              <Input
                inputRef={endDateRef}
                name="confirmation date to"
                type="date"
                width={250}
                value={endDate}
                inputWidth={140}
              />
            </div>
            <div>
              <SelectInput
                multiple={true}
                name="Consultants"
                options={usersOptions}
                width={250}
                setSelected={setSelectedConsultants}
                selected={selectedConsultants}
              />
              <SelectInput
                multiple={true}
                name="Consultants from queue"
                options={queuesOptions}
                width={250}
                setSelected={setSelectedQueue}
                selected={selectedQueues}
              />{" "}
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              float: "right",
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {data && (
              <CSV
                filename={"payment-report-all.csv"}
                header={[
                  "Consultant",
                  "Price",
                  "Salaty for on",
                  "Confirmed orders",
                  "Delivered orders",
                  "Effective salary",
                ]}
                data={getDataCoCsvForAll(data)}
              />
            )}
            <button
              onClick={(e) => handleGetReport(e)}
              className="btn btn-warning"
            >
              Search
            </button>
          </div>
        </Card>
      ) : (
        <Loading />
      )}
      {data && (
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "30px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {data.map((data) => (
            <EffectivePaymentTable data={data} csv={true} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default PaymentReport;
