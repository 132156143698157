import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { markOrdertAsConsultantDecision } from "../../../../API/repositories/order";
import userManager from "../../../../API/userManager";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import ConfirmationView from "../order/ConfirmationView";
import { checkIfCommentAdded } from "@/common/functions/checkIfCommentAdded";
import Confirm from "./Confirm";
import ConfirmPayment from "../ConfirmPayment";
import { MIN_SHIFT_OFFSET } from "@/common/constants/minShiftOffset";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { MAX_SHIFT_DAYS } from "../../calling/buttons/Buttons";

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  height: fit-content;
  display: grid;
`;

const StatusButton = styled.span`
  width: fit-content;
  font-size: 12px;
  padding: 5px;
  border-radius: 10px;
  height: fit-content;
`;

const SHIFT_STATUSES = [
  { label: "No Product Info", staus: "NO_PRODUCT_INFO" },
  { label: "Wrong time", staus: "BAD_TIME" },
  { label: "other", staus: "OTHER" },
];

const NO_RESPONSE_STATUSES = [
  { label: "rejected_call", staus: "REJECTED_CALL" },
  { label: "voicmail", staus: "VOICMAIL" },
  { label: "no answer", staus: "NO_ANSWER" },
  { label: "VOIP error", staus: "VOIP_ERROR" },
  { label: "other", staus: "OTHER" },
];

const Buttons = ({
  product,
  translation,
  contact,
  chosenProductData,
  comments = [],
  data,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const minDate = moment().format("YYYY-MM-DDTHH:mm");
  const maxDate = moment()
    .add(MAX_SHIFT_DAYS, "days")
    .format("YYYY-MM-DDTHH:mm");
  const defaultShiftDate = moment().add(1, "days").format("YYYY-MM-DDTHH:mm");

  const [shiftReason, setshiftReason] = useState("BAD_TIME");
  const [noResponseReason, setNoResponseReason] = useState("REJECTED_CALL");
  const [isConfirm, setIsConfirm] = useState(false);

  const [confirmDecision, setConfirmDecision] = useState(false);
  const [text, setText] = useState();
  const [decision, setDecision] = useState();

  const [isConfirmNotAllowed, setIsConfirmNotAllowed] = useState(false);
  const [isShift, setIsShift] = useState(false);
  const [isNoResponse, setIsNoResponse] = useState(false);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [confirmPayment, setConfirmPyment] = useState();
  const [shiftDate, setShiftDate] = useState(defaultShiftDate);

  const currentUserId = userManager.getUser().id;

  const wasCommentAdded = useMemo(
    () => checkIfCommentAdded(currentUserId, comments),
    [currentUserId, comments]
  );
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleConfirmOrder = (e) => {
    e.preventDefault();

    if (data?.payment?.length > 0) {
      setConfirmPyment(() => true);
      return;
    }

    if (
      chosenProductData.length === 0 ||
      contact.postal_code === undefined ||
      contact.city === undefined ||
      contact.street === undefined
    ) {
      setIsConfirmNotAllowed((prev) => !prev);
    } else {
      const price = chosenProductData.reduce(
        (prev, next) => prev + next.price,
        0
      );

      if (
        contact.country === "cz" &&
        !contact.hasPromotion &&
        price < 1900 * 5
      ) {
        setIsConfirmNotAllowed((prev) => !prev);
      } else {
        setIsConfirm((prev) => !prev);
      }
    }
  };

  const handleClick = async (e, type) => {
    e.preventDefault();

    if (["SHIFT", "NO_RESPONSE"].includes(type)) {
      setIsShift(() => false);
      setIsNoResponse(() => false);
    }

    setConfirmDecision(true);
    setText(type);
    setDecision(type);
  };

  const goDashboard = () => {
    navigate("/consultant/dashboard");
  };

  const handleDecision = async (type, additionalInformation) => {
    const responseDecision = await makeRequest(
      markOrdertAsConsultantDecision.bind(this, userManager.getUser().id, id, {
        type: type,
        additionalInformation: additionalInformation,
      })
    );

    if (responseDecision.data && !(type === "CONFIRM")) {
      goDashboard();
    }
  };

  const onDeceline = () => {
    setConfirmDecision(false);
  };

  const onConfirm = async () => {
    switch (decision) {
      case "SHIFT":
        if (MIN_SHIFT_OFFSET > new Date(shiftDate) - new Date()) {
          addMessage(
            "Shift date must be at least 10 minutes from now",
            "error"
          );
          return;
        }
        handleDecision("SHIFT", {
          shiftReason,
          shiftDate: new Date(shiftDate).toISOString(),
        });
        break;
      case "RESIGN":
        handleDecision("RESIGN", {});
        break;
      case "TRASH":
        handleDecision("TRASH", {});
        break;
      case "NO_RESPONSE":
        handleDecision("NO_RESPONSE", { noResponseReason: noResponseReason });
        break;
      default:
    }
  };

  return (
    <Wrapper>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(markOrdertAsConsultantDecision) && <Loading />}
      <ButtonWrapper>
        <Flex>
          <button
            disabled={!wasCommentAdded}
            style={{ fontSize: "14px", padding: "8px" }}
            className="btn btn-warning"
            onClick={(e) => setIsShift((prev) => !prev)}
          >
            {translation.shift}
          </button>
          <button
            disabled={!wasCommentAdded}
            style={{ fontSize: "14px", padding: "8px" }}
            className="btn btn-warning"
            onClick={(e) => setIsNoResponse((prev) => !prev)}
          >
            {translation.noResponse}
          </button>
          <button
            disabled={!wasCommentAdded}
            style={{ fontSize: "14px", padding: "8px" }}
            className="btn btn-warning"
            onClick={(e) => handleClick(e, "RESIGN")}
          >
            {translation.resign}
          </button>
          <button
            disabled={!wasCommentAdded}
            style={{ fontSize: "14px", padding: "8px" }}
            className="btn btn-danger"
            onClick={(e) => handleClick(e, "TRASH")}
          >
            {translation.trash}
          </button>
        </Flex>
        <button
          disabled={!wasCommentAdded}
          style={{ fontSize: "14px", padding: "8px" }}
          className="btn btn-success"
          onClick={(e) => handleConfirmOrder(e)}
        >
          {translation.confirm}
        </button>
        {confirmDecision ? (
          <Confirm
            shiftDate={shiftDate}
            text={text}
            onDeceline={onDeceline}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
      </ButtonWrapper>
      {confirmPayment && (
        <ConfirmPayment
          data={data}
          setClose={setConfirmPyment}
          translation={translation}
          inbound={false}
        />
      )}
      {isConfirm && (
        <PopUp setShow={setIsConfirm}>
          <ConfirmationView
            setIsConfirm={setIsConfirm}
            product={product}
            shippingData={contact}
            translation={translation}
            chosenProductData={chosenProductData}
          />
        </PopUp>
      )}
      {isConfirmNotAllowed && (
        <PopUp setShow={setIsConfirmNotAllowed}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <h4>Please fill all required fields</h4>
            <button
              className="btn btn-success"
              onClick={() => setIsConfirmNotAllowed((prev) => !prev)}
            >
              OK
            </button>
          </div>
        </PopUp>
      )}
      {isShift && (
        <PopUp setShow={setIsShift}>
          <h3>Decision: SHIFT</h3>
          <Flex
            style={{ margin: "20px 20px 0px 20px", flexDirection: "column" }}
          >
            <Flex style={{ margin: "20px 20px 20px 20px", gap: "40px" }}>
              {SHIFT_STATUSES.map((status) => (
                <StatusButton
                  style={{ cursor: "pointer" }}
                  className={
                    shiftReason === status.staus
                      ? "check animation-scale"
                      : "notCheck animation-scale"
                  }
                  key={status.staus}
                  onClick={() => setshiftReason(() => status.staus)}
                >
                  {status.label}
                </StatusButton>
              ))}
            </Flex>
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                type="datetime-local"
                min={minDate}
                max={maxDate}
                value={shiftDate}
                onChange={(e) => setShiftDate(e.target.value)}
              />
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  handleClick(e, "SHIFT");
                }}
              >
                Make Shift
              </button>
            </div>
          </Flex>
        </PopUp>
      )}
      {isNoResponse && (
        <PopUp setShow={setIsNoResponse}>
          <h3>Decision: NO RESPONSE</h3>
          <Flex
            style={{ margin: "20px 20px 0px 20px", flexDirection: "column" }}
          >
            <Flex style={{ margin: "20px 20px 20px 20px", gap: "40px" }}>
              {NO_RESPONSE_STATUSES.map((status) => (
                <StatusButton
                  className={
                    noResponseReason === status.staus
                      ? "check animation-scale"
                      : "notCheck animation-scale"
                  }
                  key={status.staus}
                  onClick={() => setNoResponseReason(() => status.staus)}
                  style={{ cursor: "pointer" }}
                >
                  {status.label}
                </StatusButton>
              ))}
            </Flex>
            <button
              className="btn btn-warning"
              onClick={(e) => handleClick(e, "NO_RESPONSE")}
            >
              Save
            </button>
          </Flex>
        </PopUp>
      )}
    </Wrapper>
  );
};

export default Buttons;
