import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getMailsByFilter } from "../../API/repositories/mail";
import { Colors } from "../../common/colors/colors";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import PopUp from "../../common/components/PopUp";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { copyLinkToClipboard } from "../../common/functions/copyLinkToClipboard";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import {
  MAIL_TEMPLATE_BOUNCE_TYPES,
} from "../../common/constants/mails";
import { useNavigate } from "react-router-dom";
import { CONSULTANT_ROLES } from "../../components/loginPage/LoginForm/loginForm";
import { getMailTemplates } from "../../API/repositories/mailTemplate";
import { useCommonDataContext } from "../../common/hooks/commonDataContext";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
`;

const SearchBar = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue};
  min-width: 50vw;
  gap: 30px;
  border-radius: 15px;
`;

const Flex = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: center;
`;

const BorderLine = styled.div`
  width: 100%;
  background-color: ${Colors.lightlightBlue};
  height: 1px;
`;

const ColorTitle = styled.h4`
  color: ${Colors.darkBlue};
`;
const OptionWrapper = styled.div`
  margin: 20px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const MailOptionPopUp = ({
  setShow,
  mailTemplate,
  setMailTemplate,
  customer,
  setCustomer,
  consultant,
  setConsultant,
  openCountFrom,
  setOpenCountFrom,
  openCountTo,
  setOpenCountTo,
  clickCountFrom,
  setClickCountFrom,
  clickCountTo,
  setClickCountTo,
  spamReportsFrom,
  setSpamReportsFrom,
  spamReportsTo,
  setSpamReportsTo,
  createdDateFrom,
  setCreatedDateFrom,
  createdDateTo,
  setCreatedDateTo,
  processedDateFrom,
  setProcessedDateFrom,
  processedDateTo,
  setProcessedDateTo,
  deliveredDateFrom,
  setDeliveredDateFrom,
  deliveredDateTo,
  setDeliveredDateTo,
  droppedDateFrom,
  setDroppedDateFrom,
  droppedDateTo,
  setDroppedDateTo,
  bouncedType,
  setBouncedType,
  firstOpenDateFrom,
  setFirstOpenDateFrom,
  firstOpenDateTo,
  setFirstOpenDateTo,
  firstClickDateFrom,
  setFirstClickDateFrom,
  firstClickDateTo,
  setFirstClickDateTo,
  firstSpamReportDateFrom,
  setFirstSpamReportDateFrom,
  firstSpamReportDateTo,
  setFirstSpamReportnDateTo,
  consultants,
  bouncedDateFrom,
  setBouncedDateFrom,
  bouncedDateTo,
  setBouncedDateTo,
  templateOptions,
}) => {
  return (
    <PopUp setShow={setShow}>
      <h3 style={{ color: Colors.darkBlue, marginBottom: "40px" }}>
        Mail Filter
      </h3>

      <ColorTitle>Type</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <SelectInput
          name="Mail Template"
          options={templateOptions}
          selected={mailTemplate}
          setSelected={setMailTemplate}
          multiple={true}
          selectWidth={500}
        />
      </OptionWrapper>
      <ColorTitle>Customer</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          name="Email"
          value={customer}
          onChange={(e) => setCustomer(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Sender</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <SelectInput
          name="Consultants"
          multiple={true}
          options={consultants}
          selected={consultant}
          setSelected={setConsultant}
        />
      </OptionWrapper>

      <ColorTitle>Open Times</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={openCountFrom}
          onChange={(e) => setOpenCountFrom(e.target.value)}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={openCountTo}
          onChange={(e) => setOpenCountTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Click Times</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={clickCountFrom}
          onChange={(e) => setClickCountFrom(e.target.value)}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={clickCountTo}
          onChange={(e) => setClickCountTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Spam Report Times</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={spamReportsFrom}
          onChange={(e) => setSpamReportsFrom(e.target.value)}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={spamReportsTo}
          onChange={(e) => setSpamReportsTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Created Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={createdDateFrom}
          onChange={(e) => setCreatedDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={createdDateTo}
          onChange={(e) => setCreatedDateTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Processed Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={processedDateFrom}
          onChange={(e) => setProcessedDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={processedDateTo}
          onChange={(e) => setProcessedDateTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Delivered Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={deliveredDateFrom}
          onChange={(e) => setDeliveredDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={deliveredDateTo}
          onChange={(e) => setDeliveredDateTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>Dropped Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={droppedDateFrom}
          onChange={(e) => setDroppedDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={droppedDateTo}
          onChange={(e) => setDroppedDateTo(e.target.value)}
        />
      </OptionWrapper>
      <ColorTitle>Bounced Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={bouncedDateFrom}
          onChange={(e) => setBouncedDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={bouncedDateTo}
          onChange={(e) => setBouncedDateTo(e.target.value)}
        />
      </OptionWrapper>
      <ColorTitle>Bounced Type</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <SelectInput
          name="Types"
          width={140}
          options={MAIL_TEMPLATE_BOUNCE_TYPES}
          selected={bouncedType}
          setSelected={setBouncedType}
        />
      </OptionWrapper>

      <ColorTitle>First Open Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstOpenDateFrom}
          onChange={(e) => setFirstOpenDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstOpenDateTo}
          onChange={(e) => setFirstOpenDateTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>First click Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstClickDateFrom}
          onChange={(e) => setFirstClickDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstClickDateTo}
          onChange={(e) => setFirstClickDateTo(e.target.value)}
        />
      </OptionWrapper>

      <ColorTitle>First Spam Report Date</ColorTitle>
      <BorderLine></BorderLine>

      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstSpamReportDateFrom}
          onChange={(e) => setFirstSpamReportDateFrom(e.target.value)}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstSpamReportDateTo}
          onChange={(e) => setFirstSpamReportnDateTo(e.target.value)}
        />
      </OptionWrapper>
    </PopUp>
  );
};

const MailSearch = () => {
  const [showFilters, setShowFilters] = useState();
  const [data, setData] = useState();
  const createdFromRef = useRef();
  const createdToRef = useRef();

  const navigate = useNavigate();

  const [mailTemplate, setMailTemplate] = useState();
  const [customer, setCustomer] = useState();

  const [consultant, setConsultant] = useState();
  const [consultants, setConsultants] = useState();

  const [openCountFrom, setOpenCountFrom] = useState();
  const [openCountTo, setOpenCountTo] = useState();

  const [clickCountFrom, setClickCountFrom] = useState();
  const [clickCountTo, setClickCountTo] = useState();

  const [spamReportsFrom, setSpamReportsFrom] = useState();
  const [spamReportsTo, setSpamReportsTo] = useState();

  const [createdDateFrom, setCreatedDateFrom] = useState();
  const [createdDateTo, setCreatedDateTo] = useState();

  const [processedDateFrom, setProcessedDateFrom] = useState();
  const [processedDateTo, setProcessedDateTo] = useState();

  const [deliveredDateFrom, setDeliveredDateFrom] = useState();
  const [deliveredDateTo, setDeliveredDateTo] = useState();

  const [droppedDateFrom, setDroppedDateFrom] = useState();
  const [droppedDateTo, setDroppedDateTo] = useState();

  const [bouncedDateFrom, setBouncedDateFrom] = useState();
  const [bouncedDateTo, setBouncedDateTo] = useState();

  const [bouncedType, setBouncedType] = useState();

  const [firstOpenDateFrom, setFirstOpenDateFrom] = useState();
  const [firstOpenDateTo, setFirstOpenDateTo] = useState();

  const [firstClickDateFrom, setFirstClickDateFrom] = useState();
  const [firstClickDateTo, setFirstClickDateTo] = useState();

  const [firstSpamReportDateFrom, setFirstSpamReportDateFrom] = useState();
  const [firstSpamReportDateTo, setFirstSpamReportnDateTo] = useState();

  const { commonData, filterUsersByRoles } = useCommonDataContext();
  const { users } = commonData;
  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const [templateOptions, setTemplateOptions] = useState([]);

  const handleCopyIDToClipboard = (e, id) => {
    e.preventDefault();
    copyLinkToClipboard(id);
    addMessage("copied", "success");
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    const payload = new Object();
    payload.createdFrom =
      createdFromRef.current?.value || moment().startOf("day");
    payload.createdTo = createdToRef.current?.value || moment().endOf("day");

    payload.mailTemplate = mailTemplate?.map((template) => template.value);
    payload.customer = customer;
    payload.consultant = consultant?.map((consultant) => consultant.value);
    payload.openCountFrom = openCountFrom;
    payload.openCountTo = openCountTo;
    payload.clickCountFrom = clickCountFrom;
    payload.clickCountTo = clickCountTo;
    payload.spamReportsFrom = spamReportsFrom;
    payload.spamReportsTo = spamReportsTo;
    payload.createdDateFrom = createdDateFrom;
    payload.createdDateTo = createdDateTo;
    payload.processedDateFrom = processedDateFrom;
    payload.processedDateTo = processedDateTo;
    payload.deliveredDateFrom = deliveredDateFrom;
    payload.deliveredDateTo = deliveredDateTo;
    payload.droppedDateFrom = droppedDateFrom;
    payload.droppedDateTo = droppedDateTo;
    payload.bouncedDateFrom = bouncedDateFrom;
    payload.bouncedDateTo = bouncedDateTo;
    payload.bouncedType = bouncedType?.map((bounce) => bounce.value);
    payload.firstOpenDateFrom = firstOpenDateFrom;
    payload.firstOpenDateTo = firstOpenDateTo;
    payload.firstClickDateFrom = firstClickDateFrom;
    payload.firstClickDateTo = firstClickDateTo;
    payload.firstSpamReportDateFrom = firstSpamReportDateFrom;
    payload.firstSpamReportDateTo = firstSpamReportDateTo;

    const response = await makeRequest(getMailsByFilter.bind(null, payload));

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleLoadData = async () => {
    setConsultants(() => filterUsersByRoles(CONSULTANT_ROLES));

    const responseTemplates = await makeRequest(getMailTemplates);

    if (responseTemplates.data) {
      setTemplateOptions(() =>
        responseTemplates.data.map((e) => ({
          label: e.template_sengrid_name,
          value: e.template_sengrid_id,
        }))
      );
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [users]);

  return (
    <Wrapper>
      {hasUnfilledRequest(getMailsByFilter) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <SearchBar>
        <h3>Mail Search:</h3>
        <Input
          inputRef={createdFromRef}
          name="Created from"
          value={new Date().toISOString().split("T")[0]}
          type="date"
          width={140}
          inputWidth={140}
        />
        <Input
          inputRef={createdToRef}
          name="Created to"
          type="date"
          value={new Date().toISOString().split("T")[0]}
          width={140}
          inputWidth={140}
        />
        <div>
          <button
            className="btn btn-warning"
            onClick={() => setShowFilters(true)}
          >
            Options
          </button>
        </div>
        <div>
          <button className="btn btn-warning" onClick={(e) => handleSearch(e)}>
            Search
          </button>
        </div>
      </SearchBar>
      <div style={{ maxWidth: "80vw", overflow: "auto", maxHeight: "80vh" }}>
        {data && data.length > 0 ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Sender</th>
                <th>Customer</th>
                <th>Type</th>
                <th>Opened Times</th>
                <th>Clicked Times </th>
                <th>Spam Reported Times</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Processed At</th>
                <th>Delivered At</th>
                <th>Dropped At</th>
                <th>Bounced At</th>
                <th>Bounced Type</th>
                <th>First Open At</th>
                <th>First Click At</th>
                <th>Fisrt Spam Report At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data.map((element, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{element.sender ? element._user.username : "System"}</td>
                  <td>{element.customer}</td>
                  <td>{element.mailType}</td>
                  <td>{element.open_countrer || 0}</td>
                  <td>{element.clicked_countrer || 0}</td>
                  <td>{element.spam_report_countrer || 0}</td>
                  <td>
                    {element.created_at
                      ? moment(element.created_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.updatedAt
                      ? moment(element.updatedAt).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.processed_at
                      ? moment(element.processed_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.delivered_at
                      ? moment(element.delivered_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.dropped_at
                      ? moment(element.dropped_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.bounced_at
                      ? moment(element.bounced_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>{element.bounced_type || "-------"}</td>
                  <td>
                    {element.first_open_at
                      ? moment(element.first_open_at).format("YYYY-MM-DD HH:mm")
                      : "-------"}
                  </td>
                  <td>
                    {element.first_click_at
                      ? moment(element.first_click_at).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-------"}
                  </td>
                  <td>
                    {element.fisrt_spam_report_at
                      ? moment(element.fisrt_spam_report_at).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-------"}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        flexWrap: "wraps",
                      }}
                    >
                      <div>
                        {element.order && (
                          <CustomButtonAdd
                            className="btn btn-warning"
                            onClick={() =>
                              navigate("/dashboard/order/" + element.order)
                            }
                            defaultText="Order"
                          />
                          )
                        }
                        {element.call_center_candidate && (
                          <CustomButtonAdd
                            className="btn btn-warning"
                            onClick={() =>
                              navigate(`/cc/recruitment?candidate=${element.call_center_candidate}`)
                            }
                            defaultText="Candidate"
                          />)
                        }
                      </div>
                      {element.sengird_id && (
                        <div>
                          <button
                            onClick={(e) =>
                              handleCopyIDToClipboard(
                                e,
                                element.sengird_id.toString()
                              )
                            }
                            className="btn btn-warning"
                          >
                            Sengrid
                          </button>
                        </div>
                      )}
                      {element.template_id && (
                        <div>
                          <button
                            onClick={(e) =>
                              handleCopyIDToClipboard(
                                e,
                                element.template_id.toString()
                              )
                            }
                            className="btn btn-warning"
                          >
                            Template
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : data ? (
          <Flex>
            <h3 style={{ color: "red" }}>No results</h3>
          </Flex>
        ) : (
          ""
        )}
        {showFilters && (
          <MailOptionPopUp
            templateOptions={templateOptions}
            consultants={consultants}
            setShow={setShowFilters}
            mailTemplate={mailTemplate}
            setMailTemplate={setMailTemplate}
            customer={customer}
            setCustomer={setCustomer}
            consultant={consultant}
            setConsultant={setConsultant}
            openCountFrom={openCountFrom}
            setOpenCountFrom={setOpenCountFrom}
            openCountTo={openCountTo}
            setOpenCountTo={setOpenCountTo}
            clickCountFrom={clickCountFrom}
            setClickCountFrom={setClickCountFrom}
            clickCountTo={clickCountTo}
            setClickCountTo={setClickCountTo}
            spamReportsFrom={spamReportsFrom}
            setSpamReportsFrom={setSpamReportsFrom}
            spamReportsTo={spamReportsTo}
            setSpamReportsTo={setSpamReportsTo}
            createdDateFrom={createdDateFrom}
            setCreatedDateFrom={setCreatedDateFrom}
            createdDateTo={createdDateTo}
            setCreatedDateTo={setCreatedDateTo}
            processedDateFrom={processedDateFrom}
            setProcessedDateFrom={setProcessedDateFrom}
            processedDateTo={processedDateTo}
            setProcessedDateTo={setProcessedDateTo}
            deliveredDateFrom={deliveredDateFrom}
            setDeliveredDateFrom={setDeliveredDateFrom}
            deliveredDateTo={deliveredDateTo}
            setDeliveredDateTo={setDeliveredDateTo}
            droppedDateFrom={droppedDateFrom}
            setDroppedDateFrom={setDroppedDateFrom}
            droppedDateTo={droppedDateTo}
            setDroppedDateTo={setDroppedDateTo}
            bouncedType={bouncedType}
            setBouncedType={setBouncedType}
            firstOpenDateFrom={firstOpenDateFrom}
            setFirstOpenDateFrom={setFirstOpenDateFrom}
            firstOpenDateTo={firstOpenDateTo}
            setFirstOpenDateTo={setFirstOpenDateTo}
            firstClickDateFrom={firstClickDateFrom}
            setFirstClickDateFrom={setFirstClickDateFrom}
            firstClickDateTo={firstClickDateTo}
            setFirstClickDateTo={setFirstClickDateTo}
            firstSpamReportDateFrom={firstSpamReportDateFrom}
            setFirstSpamReportDateFrom={setFirstSpamReportDateFrom}
            firstSpamReportDateTo={firstSpamReportDateTo}
            setFirstSpamReportnDateTo={setFirstSpamReportnDateTo}
            bouncedDateFrom={bouncedDateFrom}
            setBouncedDateFrom={setBouncedDateFrom}
            bouncedDateTo={bouncedDateTo}
            setBouncedDateTo={setBouncedDateTo}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default MailSearch;
