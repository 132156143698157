import { getMailOptionsCategories } from "@/API/repositories/mailCategory";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import CustomButtonError from "@/common/components/buttons/CustomButtonError";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;
  align-items: center;
`;

const SendAnyMail = ({
  setShowSendAnyMail,
  handleSendAnyMailTemplate,
  showSendAnyMail,
}) => {
  const [mailCategories, setMailCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [accepted, setAccepted] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadMailCategories = async () => {
    const payload = {
      product: showSendAnyMail.product,
    };
    const res = await makeRequest(getMailOptionsCategories.bind(null, payload));

    if (res.data) {
      const options = res.data.map((d) => ({ label: d.name, value: d._id }));

      setMailCategories(() => options);
      setSelectedCategory(() => options[0]);
    }
  };

  const handleSend = () => {
    handleSendAnyMailTemplate(
      selectedCategory.label.split("_").slice(1, -1).join("_") + "_"
    );
  };

  useEffect(() => {
    loadMailCategories();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getMailOptionsCategories) && <Loading />}
      <PopUp setShow={setShowSendAnyMail}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setAccepted(true);
          }}
        >
          <SelectInput
            showLabel={false}
            options={mailCategories}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
          />
          <CustomButtonSave text={"Send Mail"} />
        </Form>
      </PopUp>
      {accepted && (
        <PopUp setShow={setAccepted}>
          <div>
            <div>
              Are you sure that you want to send{" "}
              {selectedCategory.label.split("_").slice(1, -1).join("_")} mail.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                marginTop: "50px",
              }}
            >
              <CustomButtonError
                defaultText="No i dont"
                onClick={() => setAccepted(false)}
              />

              <CustomButtonAdd
                text="Yes i want"
                onClick={() => handleSend(false)}
              />
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};

export default SendAnyMail;
