import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

export const Flex = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
