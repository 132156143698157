import PopUp from "@/common/components/PopUp";
import React, { useEffect, useState } from "react";
import { ConsultantMonthWrapper } from "../../CCHours.styled";
import ConsultantHoursTable from "@/components/admin/cc/ConsultantHoursTable";
import MonthInfoForm from "./components/MonthInfoForm";
import Calendar from "./components/calendar/Calendar";
import TopInfo from "./components/topInfo/TopInfo";
import { getBonusesByUserId } from "@/API/repositories/bonus";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";

const ConsultantMonth = ({
  selectedMonth,
  showConsultantMonth,
  setShowConsultantMonth,
  consultants,
  consultantsFull,
  hanldeSearch,
}) => {
  const [bonuses, setBonuses] = useState([]); 

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadBonuses = async () => {
    const response = await makeRequest(getBonusesByUserId.bind(null, showConsultantMonth._user._id));

    if (response?.data) {
      return setBonuses(() => response.data);
    }
  }

  useEffect(() => { 
    loadBonuses();
  }, []);

  return (
    <PopUp setShow={setShowConsultantMonth}>
      {hasUnfilledRequest(getBonusesByUserId) && <Loading />}
      <TopInfo
        consultants={consultants}
        showConsultantMonth={showConsultantMonth}
        selectedMonth={selectedMonth}
        hanldeSearch={hanldeSearch}
      />
      <ConsultantMonthWrapper>
        <ConsultantHoursTable
          month={selectedMonth.value}
          data={showConsultantMonth}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "60px",
            justifyContent: "center",
            marginTop: "60px",
          }}
        >
          <MonthInfoForm
            selectedMonth={selectedMonth}
            showConsultantMonth={showConsultantMonth}
            setShowConsultantMonth={setShowConsultantMonth}
            consultantsFull={consultantsFull}
            hanldeSearch={hanldeSearch}
          />
          <Calendar
            bonuses={bonuses}
            month={selectedMonth.value}
            data={showConsultantMonth}
            hanldeSearch={hanldeSearch}
          />
        </div>
      </ConsultantMonthWrapper>
    </PopUp>
  );
};

export default ConsultantMonth;
