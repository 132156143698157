import React from "react";
import { getHeadersArray } from "./helpers/getHeadersArray";
import { getRaws } from "./helpers/getRaws";
import Table from "@/common/components/Table";

const PreResultTable = ({ payment, consultantResults }) => (
  <Table
    style={{ marginTop: "30px" }}
    raws={getRaws(payment, consultantResults)}
    headersArray={getHeadersArray(payment.is_final)}
    className="styled-table"
  />
);
export default PreResultTable;
