import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 1000px;
`;

const MarketRegionTable = ({ region, setSelectedRegionEdit }) => {
  return (
    <Wrapper>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th colSpan={4}>Regions</th>
          </tr>
          <tr>
            <th>No.</th>
            <th>Label</th>
            <th>Postal codes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="queue">
          {region[0] ? (
            region.map((reg, i) => (
              <tr>
                <td>{i + 1}.</td>
                <td>{reg.label}</td>
                <td>
                  {reg.codes
                    ?.map((c) => "[" + c.from + ", " + c.to + "]")
                    .join(" , ")}
                </td>
                <td>
                  <i
                    className="fa fa-edit animation-scale"
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedRegionEdit(reg)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>There is no data</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default MarketRegionTable;
