const getDepth = (data) => {
  if (!data[1]?.length) {
    return 1;
  }
  
  let depth = 0;

  data[1].forEach((item) => {
    const itemDepth = getDepth(item);
    if (itemDepth > depth) {
      depth = itemDepth;
    }
  });

  return depth + 1;
}

const getSpan = (dataset) => {
  if (!dataset[1]?.length) {
    return 1;
  }
  
  let span = 0;

 
  dataset[1].forEach((item) => {
    span += getSpan(item);
  });

  return span;
}

const generateRawsRecursively = (item, maxDepth = { value: 0 }, res = [[]]) => {
  const [title, group] = item;

  const span = getSpan(item);
  const depth = getDepth(item);

  if (maxDepth.value) {
    res.at(-1).push({
      title, 
      span
    });
  } else {
    maxDepth.value = depth;
    res.push(
      [{
        title, 
        span
      }]
    );
  }

  if (!group?.length) {
    return res;
  }

  group.forEach((item) => {
    maxDepth.value--;
    generateRawsRecursively(item, maxDepth, res);
  });

  return res;
}

export const generateTableRaws = (data) => {
  return data.map(item => {
    return generateRawsRecursively(item, { value: getDepth(item) }).map((item, index) => {
      return (
        <tr key={index}>
          {item.map((cell, index) => {
            return (
              <td rowSpan={cell.span} key={index}>
                {cell.title}
              </td>
            )
          })}
        </tr>
      )
    });
  })
};
