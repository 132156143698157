export const ANSWER_OPTIONS = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Input",
    value: "input",
  },
  {
    label: "Image",
    value: "image",
  },
];

export const QUESTION_OPTIONS = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Input",
    value: "input",
  },
  {
    label: "Image",
    value: "image",
  },
];
