export const getTrackingLink = (country, shipping) => {
  const { tracking_link, waybill_number, curier_name } = shipping;

  if (tracking_link) {
    return `${tracking_link}${waybill_number}`;
  }

  if (country === "cz") {
    return `https://www.postaonline.cz/en/trackandtrace/-/zasilka/cislo?parcelNumbers=${waybill_number}`;
  }

  if (country === "sk") {
    return curier_name === "sp"
      ? `https://tandt.posta.sk/en/items/${waybill_number}`
      : `https://123kurier.jpsoftware.sk/sledovanie-zasielky?number=${waybill_number}`;
  }

  if (country === "ro") {
    return `https://www.fancourier.ro/en/awb-tracking/?metoda=tracking&bar_size=x&limba=romana&awb=${waybill_number}`;
  }

  if (country === "hu") {
    return `https://posta.hu/nyomkovetes/nyitooldal?searchvalue=${waybill_number}`;
  }
};
