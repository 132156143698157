import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Property = styled.div`
  background-color: ${Colors.green};
  color: white;
  padding: 4px;
  border-radius: 10px;
  cursor: pointer;
`;

export const PropertiesWrapper = styled.div`
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  gap: 14px;
`;

export const GroupTableWrapper = styled.div`
  max-width: 600px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  margin: 20px 0;
`;

export const LimitedCell = styled.td`
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
