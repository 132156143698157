import { getActiveTickets } from "@/API/repositories/tickets";
import Table from "@/common/components/Table";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useMemo } from "react";
import { HEADERS_ARRAY } from "./constants/headersArray";

const ActiveTickets = () => {
  const {
    data: { active_tickets },
  } = useAdminDashboardContext();
  const { hasUnfilledRequest } = useRequestsContext();

  const activeTicketsGrouppedByMarket = active_tickets?.reduce((acc, next) => {
    if (acc[next.contact.country]) {
      acc[next.contact.country].push(next);
    } else {
      acc[next.contact.country] = [next];
    }

    return acc;
  }, {});

  const getMarket = (item) => item[0].contact.country;

  const raws = useMemo(() => {
    if (activeTicketsGrouppedByMarket) {
      return Object.values(activeTicketsGrouppedByMarket)
        ?.sort((groupA, groupB) =>
          getMarket(groupA).localeCompare(getMarket(groupB))
        )
        .map((item) => (
          <tr key={getMarket(item)}>
            <td>{getMarket(item)}</td>
            <td>
              {item.reduce((acc, next) => {
                return next.status === "new" ? ++acc : acc;
              }, 0)}
            </td>
            <td>
              {item.reduce((acc, next) => {
                return next.status === "pending" ? ++acc : acc;
              }, 0)}
            </td>
            <td>
              {item.reduce((acc, next) => {
                return next.prioritized ? ++acc : acc;
              }, 0)}
            </td>
          </tr>
        ));
    }
  }, [activeTicketsGrouppedByMarket]);

  const shouldShowLoading = useMemo(
    () => !raws && hasUnfilledRequest(getActiveTickets),
    [raws, hasUnfilledRequest]
  );

  return (
    <div>
      <Table
        raws={raws}
        headersArray={HEADERS_ARRAY}
        className="styled-table-dashboard"
        loading={shouldShowLoading}
      />
    </div>
  );
};

export default ActiveTickets;
