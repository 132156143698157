import React, { useEffect, useRef, useState } from "react";
import { createOrderWithShipping } from "../../../API/repositories/order";
import Input from "../../../common/components/Input";
import PopUp from "../../../common/components/PopUp";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { COUNTRIES } from "../../../common/constants/countries";
import Loading from "../../../common/components/Loading";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { getProductsWithCouriers } from "@/API/repositories/lms";
import { findProductById } from "@/common/functions/findProductById";

const OrderCreate = ({ contact, setShow }) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRIES.find((country) => contact.country === country.value)
  );
  const [chosenVariants, setChosenVariants] = useState([]);
  const [product, setProduct] = useState(null);
  const [market, setMarket] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionPrice, setSelectedOptionPrice] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const { commonData, options } = useCommonDataContext();
  const { markets, products } = commonData;
  const { marketsOptions, productsOptions } = options;

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const phoneForCourierRef = useRef();
  const suggestedDeliveryDateRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();

  const handleSaveOrder = async (e) => {
    e.preventDefault();
    const order = Object();

    order.currency = markets.find(
      (_market) => market.value === _market._id
    ).currency;
    order.market = market.value;
    order.product = product.value;
    order.items = chosenVariants;

    const shipping = Object();

    shipping.full_name = nameRef.current
      ? nameRef.current.value
      : contact.full_name;

    shipping.email = emailRef.current ? emailRef.current.value : contact.email;

    shipping.phone_number = phoneRef.current
      ? phoneRef.current.value
      : contact.phone_number;

    shipping.suggested_delivery_date = suggestedDeliveryDateRef.current.value;
    shipping.street = streetRef.current.value;
    shipping.postal_code = postalCodeRef.current.value;
    shipping.city = cityRef.current.value;
    shipping.country = selectedCountry.value;
    shipping.curier_lms_id = selectedCourier?.value;

    order.shipping = shipping;

    const response = await makeRequest(
      createOrderWithShipping.bind(null, contact._id, order)
    );

    if (response.data) {
      setShow(() => false);
    } else {
    }
  };

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!product || !productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (productWithCourier) =>
        findProductById(product.value, products)?.short ===
        productWithCourier.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(options[0]);
  };

  const hadnleAddProduct = (e) => {
    e.preventDefault();

    if (selectedOption && selectedOptionPrice)
      setChosenVariants((prev) => [
        ...chosenVariants,
        {
          _id: chosenVariants.length + 1,
          name: selectedOption.label,
          value: selectedOption.value,
          price: selectedOptionPrice.value,
        },
      ]);
  };

  const hadnleDelteProductItem = (_id) => {
    let data = chosenVariants.filter((item) => item._id !== _id);
    let id = 1;
    data = data.map((data) => {
      data._id = id;
      id++;
      return data;
    });
    setChosenVariants(data);
  };

  useEffect(() => {
    loadCouriers();
  }, []);

  useEffect(() => {
    handleSetCouriersData();
  }, [product, productsWithCouriers]);

  return (
    <PopUp setShow={setShow} top={30}>
      {hasUnfilledRequest(createOrderWithShipping) && <Loading />}
      <h2 style={{ marginBottom: "20px" }}>Order</h2>
      <form onSubmit={(e) => handleSaveOrder(e)}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <h4 style={{ margin: "20px" }}>
              <u>Contact Info:</u>
            </h4>
            <Input
              inputRef={nameRef}
              name="Name"
              value={contact.full_name}
              required={true}
              width={100}
            />
            <Input
              name="Email"
              type="email"
              inputRef={emailRef}
              value={contact.email}
              required={true}
              width={100}
            />
            <Input
              name="Phone"
              inputRef={phoneRef}
              value={contact.phone_number}
              required={true}
              width={100}
            />
            <Input
              name="Phone for courier"
              inputRef={phoneForCourierRef}
              value={contact.phone_number_for_courier}
              width={100}
              disabled
            />
          </div>
          <div>
            <h4 style={{ margin: "20px" }}>
              <u>Shipping Info:</u>
            </h4>
            <Input
              inputRef={suggestedDeliveryDateRef}
              type="date"
              name="Suggested delivery date"
              required={true}
              width={250}
            />
            <Input
              inputRef={streetRef}
              name="Street"
              required={true}
              width={250}
              value={contact.street}
            />
            <Input
              name="Postal code"
              inputRef={postalCodeRef}
              required={true}
              width={250}
              value={contact.postal_code}
            />
            <Input
              name="City"
              inputRef={cityRef}
              required={true}
              width={250}
              value={contact.city}
            />
            <SelectInput
              name="Country"
              selected={selectedCountry}
              setSelected={setSelectedCountry}
              width={250}
              options={COUNTRIES}
            />
            <Input
              name="Country code"
              value={selectedCountry?.value}
              width={250}
              disabled={true}
            />
          </div>
        </div>
        {products && markets && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SelectInput
                name="Product"
                selected={product}
                setSelected={setProduct}
                width={80}
                selectWidth={195}
                options={productsOptions}
              />
              <SelectInput
                name="Market"
                selected={market}
                setSelected={setMarket}
                width={80}
                selectWidth={195}
                options={marketsOptions}
              />
              <SelectInput
                disabled={!product}
                name="Courier"
                width={80}
                selectWidth={195}
                options={courierOptions}
                selected={selectedCourier}
                setSelected={setSelectedCourier}
              />
            </div>

            <div>
              <h4 style={{ margin: "20px" }}>
                <u>Items:</u>
              </h4>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: "20px",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <label
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    padding: "0px 30px",
                  }}
                >
                  <strong style={{ marginRight: "20px" }}>Name:</strong>{" "}
                  {product
                    ? products.find(
                        (_product) => _product._id === product.value
                      ).name
                    : ""}
                </label>
                <SelectInput
                  name={"Variants"}
                  width={80}
                  selected={selectedOption}
                  setSelected={setSelectedOption}
                  options={
                    product
                      ? products
                          .find((_product) => _product._id === product.value)
                          .variants.map((varaint) => {
                            return {
                              label: varaint.name,
                              value: varaint.short,
                            };
                          })
                      : null
                  }
                />
                <SelectInput
                  name={"Price"}
                  width={40}
                  selected={selectedOptionPrice}
                  setSelected={setSelectedOptionPrice}
                  options={
                    product
                      ? products
                          .find((_product) => _product._id === product.value)
                          .prices.map((price) => {
                            return { label: price.value, value: price.value };
                          })
                      : null
                  }
                />
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button
                    type="submit"
                    className="btn btn-warning"
                    onClick={(e) => hadnleAddProduct(e)}
                  >
                    Add
                  </button>
                </div>
              </div>

              {chosenVariants.length > 0 && (
                <div
                  style={{
                    marginTop: "30px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th style={{ fontSize: "20px", textAlign: "left" }}>
                          Variant
                        </th>
                        <th style={{ fontSize: "20px", textAlign: "center" }}>
                          Short
                        </th>
                        <th style={{ fontSize: "20px", textAlign: "center" }}>
                          Price
                        </th>
                        <th
                          style={{ fontSize: "20px", textAlign: "center" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {chosenVariants.map((data) => (
                        <tr style={{}}>
                          <td
                            style={{
                              width: "100px",
                              fontSize: "18px",
                              borderTop: "1px solid grey",
                              textAlign: "left",
                              padding: "11px 0px",
                            }}
                          >
                            {data.name}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              fontSize: "18px",
                              borderTop: "1px solid grey",
                              textAlign: "center",
                            }}
                          >
                            {data.value}
                          </td>
                          <td
                            style={{
                              width: "200px",
                              fontSize: "18px",
                              borderTop: "1px solid grey",
                              textAlign: "center",
                            }}
                          >
                            {data.price}{" "}
                            {
                              markets.find(
                                (_market) => market.value === _market._id
                              ).currency
                            }
                          </td>
                          <td
                            style={{
                              width: "80px",
                              fontSize: "18px",
                              borderTop: "1px solid grey",
                              textAlign: "center",
                            }}
                          >
                            <button
                              className="btn btn-danger"
                              onClick={() => hadnleDelteProductItem(data._id)}
                              style={{ background: "none", border: "none" }}
                            >
                              <i
                                class="fa fa-remove"
                                style={{ color: "red", fontSize: "20px" }}
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        )}
        <button
          style={{ float: "right" }}
          className="btn btn-warning"
          type="submit"
        >
          Save
        </button>
      </form>
    </PopUp>
  );
};

export default OrderCreate;
