import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
  margin-left: 12px;
  flex-direction: column;
`;
