import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
  max-height: 90vh;
  overflow: scroll;
`;

export const SearchBar = styled.div`
  border-radius: 15px;
  border: 1px dashed ${Colors.darkBlue};
  display: flex;
  min-width: 800px;
  padding: 20px;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
`;
