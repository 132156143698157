import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Title = styled.h3`
  margin-bottom: 40px;
  font-weight: bold;
  color: ${Colors.darkBlue};
`;

export const PercentsAddWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const PercentsTitle = styled.p`
  color: ${Colors.darkBlue};
  padding-left: 16px;
  font-weight: bold;
  font-size: 18px;
`;

export const PercentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin: 0 0 20px 16px;
`;

export const Percents = styled.div`
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  background-color: ${Colors.green};
  color: white;
`;

export const RegulationsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 20px;
  position: relative;
`;

export const HoverWrapper = styled.div`
  position: relative;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 200px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 20px;
      top: -70px;
      right: 50%;
      transform: translateX(50%);
      border-radius: 10px;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;

export const Textarea = styled.textarea`
  display: block;
  resize: none;
`;
