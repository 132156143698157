import Table from '@/common/components/Table'
import React from 'react'
import { HEADERS } from './constants/headers'
import { getRaws } from './helpers/getRaws'

const CandidatesTable = ({ candidates, setShowCandidateForm }) => {

  return (
    <Table className="styled-table" headers={HEADERS} raws={getRaws(candidates, setShowCandidateForm)} />
  )
}

export default CandidatesTable
