import CustomButtonSave from '@/common/components/buttons/CustomButtonSave'
import PopUp from '@/common/components/PopUp'
import { useFormik } from 'formik'
import React from 'react'
import { ButtonWrapper } from '../../File.styled'
import { useRequestsContext } from '@/common/hooks/requestHook'
import Loading from '@/common/components/Loading'
import { createFile, updateFile } from '@/API/repositories/file'
import Input from '@/common/components/Input'
import { Colors } from '@/common/colors/colors'
import { getBase64 } from '@/common/functions/getBase64'

const FileForm = ({ setShow, addMessage, selectedFile, reload = () => {} }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const formik = useFormik({
    initialValues: {
      filename: selectedFile?.filename || '',
      base64: null,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleFileSelect = async (e) => {
    if (!e.target.files[0]) {
      return formik.setFieldValue("base64", "");
    }

    const base64 = await getBase64(e.target.files[0]);
    formik.setFieldValue("base64", base64);
  };

  const handleSubmit = async (values) => {
    const requestFn = selectedFile ? updateFile : createFile;

    const response = await makeRequest(requestFn.bind(null, values, selectedFile?._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(createFile, updateFile) && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <Input
          width={100}
          inputWidth={300}
          name="Filename"
          color={Colors.darkBlue}
          value={formik.values.filename}
          onChange={formik.handleChange}
          id="filename"
          required
        />
        <Input
          required={!selectedFile}
          type="file"
          name="File"
          color={Colors.darkBlue}
          width={100}
          inputWidth={300}
          onChange={handleFileSelect}
        />
        <ButtonWrapper>
          <CustomButtonSave text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default FileForm
