export const CANDIDATE_INTERACTION_STATUSES = {
  NO_RESPONSE: 'button_status',
  SHIFT_CALL: 'button_status',
  REJECT: 'rejected',
  ACCEPT: 'accept',
  TO_HIRE: 'to_hire',
  NEXT_PENDING: 'next_pending',
  SECOND_STAGE_ACCEPT: 'accepted_second_stage',
  PENDING: 'pending',
  NEW: 'new',
  NEXT_NEW: 'next_new',
}

export const STATUSES_LABELS = [
  {
    weight: 0,
    label: "First stage new",
    value: CANDIDATE_INTERACTION_STATUSES.NEW
  },
  {
    weight: 1,
    label: "First stage pending",
    value: CANDIDATE_INTERACTION_STATUSES.PENDING
  },
  {
    weight: 2,
    label: "Second stage new",
    value: CANDIDATE_INTERACTION_STATUSES.NEXT_NEW
  },
  {
    weight: 3,
    label: "Second stage pending",
    value: CANDIDATE_INTERACTION_STATUSES.NEXT_PENDING
  },
  {
    weight: 4,
    label:  "Accept hiring",
    value: CANDIDATE_INTERACTION_STATUSES.SECOND_STAGE_ACCEPT
  },
  {
    weight: 5,
    label: 'To hire',
    value: CANDIDATE_INTERACTION_STATUSES.TO_HIRE
  },
  {
    weight: 6,
    label: "Rejected",
    value: CANDIDATE_INTERACTION_STATUSES.REJECT
  },
]

export const MONTH_HOURS = [
  {
    label: 'Full time (160 hours)',
    value: 160
  }, 
  {
    label: 'Part time (120 hours)',
    value: 120
  },
  {
    label: 'Part time (80 hours)',
    value: 80
  }
]