export const getCSVDATA = (data) => {
  let result = [];

  if (!data) return;

  data.forEach((d) => {
    result = [...result, ...d.csv];
  });

  return result;
};
