export const getRaws = (cplData) =>
  cplData.map((item) => (
    <tr key={`${item.dateFrom}_${item.productName}`}>
      <td>{item.idx}</td>
      <td>{item.dateFrom}</td>
      <td>{item.dateTo}</td>
      <td>{item.productName}</td>
      <td>{item.leads}</td>
      <td>{item.toCallLeads}</td>
      <td>{item.paidOrders}</td>
      <td>{item.paidOrderValue}</td>
      <td>{item.paidOrderValuePLN}</td>
      <td>{item.totalSpend}</td>
      <td>{item.roas}</td>
    </tr>
  ));
