import React, { useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import {
  Title,
  Percents,
  PercentsAddWrapper,
  PercentsTitle,
  PercentsWrapper,
  Textarea,
  RegulationsWrapper,
  HoverWrapper,
} from "./GamificationForm.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import PercentsForm from "./components/percentsForm/PercentsForm";
import { ButtonWrapper } from "../../Gamification.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { PERCENTS_MODES } from "./constants/percentsModes";

const GamificatinForm = ({
  setIsGamificationFormOpen,
  handleSubmitConfig,
  gamificationConfig,
  addMessage,
}) => {
  const [addPercentsMode, setAddPercentsMode] = useState();
  const [packagesPercentsRange, setPackagesPercentsRange] = useState(
    gamificationConfig?.package_percents || []
  );
  const [starsPercentsRange, setStarsPercentsRange] = useState(
    gamificationConfig?.stars_percents || []
  );
  const [winnersPercentsRange, setWinnersPercentsRange] = useState(
    gamificationConfig?.winners_percents || []
  );
  const [selectedMarket, setSelectedMarket] = useState({
    value: gamificationConfig?.market,
  });

  const percentsRef = useRef();
  const starsForPercentsRef = useRef();
  const placeForPercentsRef = useRef();
  const listeningTargetRef = useRef();
  const normalModeRef = useRef();
  const callingModeRef = useRef();
  const gamificationRegulationRef = useRef();
  const ordersRegulationRef = useRef();
  const listeningnRegulationRef = useRef();
  const deliveryRegulationRef = useRef();
  const sumUpRegulationRef = useRef();

  const {
    options: { marketsOptions },
  } = useCommonDataContext();

  const handleDeletePercents = (percents, mode) => {
    switch (mode) {
      case PERCENTS_MODES.PACKAGES:
        return setPackagesPercentsRange((prev) =>
          prev.filter(
            (percentWithStar) => percentWithStar.percents !== percents
          )
        );
      case PERCENTS_MODES.STARS:
        return setStarsPercentsRange((prev) =>
          prev.filter(
            (percentWithStar) => percentWithStar.percents !== percents
          )
        );
      default:
        return setWinnersPercentsRange((prev) =>
          prev.filter(
            (percentWithStar) => percentWithStar.percents !== percents
          )
        );
    }
  };

  const handleAddPercents = (e) => {
    e && e.preventDefault();
    if (
      !percentsRef.current?.value ||
      (!starsForPercentsRef.current?.value &&
        !placeForPercentsRef.current?.value)
    )
      return;

    const newPercent =
      addPercentsMode === PERCENTS_MODES.WINNERS
        ? {
            percents: percentsRef.current?.value,
            place: placeForPercentsRef.current?.value,
          }
        : {
            percents: percentsRef.current?.value,
            stars: starsForPercentsRef.current?.value,
          };

    setAddPercentsMode(() => null);

    switch (addPercentsMode) {
      case PERCENTS_MODES.PACKAGES:
        return setPackagesPercentsRange((prev) => [...prev, newPercent]);
      case PERCENTS_MODES.STARS:
        return setStarsPercentsRange((prev) => [...prev, newPercent]);
      default:
        return setWinnersPercentsRange((prev) => [...prev, newPercent]);
    }
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();

    if (
      !selectedMarket?.value ||
      !packagesPercentsRange?.length ||
      !starsPercentsRange?.length ||
      !winnersPercentsRange?.length ||
      packagesPercentsRange.length > starsPercentsRange.length
    ) {
      return addMessage("Add all data correctly");
    }

    const payload = {};

    payload.listening_score_target = listeningTargetRef.current?.value;
    payload.normal_mode_constant = normalModeRef.current?.value;
    payload.calling_mode_constant = callingModeRef.current?.value;
    payload.stars_percents = starsPercentsRange;
    payload.package_percents = packagesPercentsRange;
    payload.winners_percents = winnersPercentsRange;
    payload.market = selectedMarket.value;
    payload.gamification_regulation = gamificationRegulationRef.current?.value;
    payload.orders_regulation = ordersRegulationRef.current?.value;
    payload.listening_regulation = listeningnRegulationRef.current?.value;
    payload.delivery_regulation = deliveryRegulationRef.current?.value;
    payload.sum_up_regulation = sumUpRegulationRef.current?.value;
    payload._id = gamificationConfig?._id;

    return handleSubmitConfig(payload);
  };

  return (
    <PopUp setShow={setIsGamificationFormOpen}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Title>Stars achievement configuration</Title>
        <PercentsAddWrapper>
          <PercentsTitle>Package delivery percents range</PercentsTitle>
          <CustomButtonAdd
            onClick={() => setAddPercentsMode(() => PERCENTS_MODES.PACKAGES)}
            text="add"
          />
        </PercentsAddWrapper>
        <PercentsWrapper>
          {!!packagesPercentsRange?.length &&
            packagesPercentsRange.map((percentWithStart) => (
              <Percents
                key={percentWithStart.percents}
                onClick={() =>
                  handleDeletePercents(
                    percentWithStart.percents,
                    PERCENTS_MODES.PACKAGES
                  )
                }
              >
                {percentWithStart.stars} - {percentWithStart.percents}%
              </Percents>
            ))}
        </PercentsWrapper>
        <PercentsAddWrapper>
          <PercentsTitle>Stars percents range</PercentsTitle>
          <CustomButtonAdd
            onClick={() => setAddPercentsMode(() => PERCENTS_MODES.STARS)}
            text="add"
          />
        </PercentsAddWrapper>
        <PercentsWrapper>
          {!!starsPercentsRange?.length &&
            starsPercentsRange.map((percentWithStart) => (
              <Percents
                key={percentWithStart.percents}
                onClick={() =>
                  handleDeletePercents(
                    percentWithStart.percents,
                    PERCENTS_MODES.STARS
                  )
                }
              >
                {percentWithStart.stars} - {percentWithStart.percents}%
              </Percents>
            ))}
        </PercentsWrapper>
        <PercentsAddWrapper>
          <PercentsTitle>Winners percents range</PercentsTitle>
          <CustomButtonAdd
            onClick={() => setAddPercentsMode(() => PERCENTS_MODES.WINNERS)}
            text="add"
          />
        </PercentsAddWrapper>
        <PercentsWrapper>
          {!!winnersPercentsRange?.length &&
            winnersPercentsRange.map((percentWithStart) => (
              <Percents
                key={percentWithStart.percents}
                onClick={() =>
                  handleDeletePercents(
                    percentWithStart.percents,
                    PERCENTS_MODES.WINNERS
                  )
                }
              >
                {percentWithStart.place} - {percentWithStart.percents}%
              </Percents>
            ))}
        </PercentsWrapper>
        <SelectInput
          options={marketsOptions}
          color={Colors.darkBlue}
          width={72}
          selectWidth={210}
          setSelected={setSelectedMarket}
          selected={marketsOptions.find(
            (option) => option.value === selectedMarket.value
          )}
          name="Market"
        />
        <RegulationsWrapper>
          <HoverWrapper label="Gamification regulation">
            <Textarea
              placeholder="Type gamification regulation..."
              cols={40}
              rows={5}
              required
              ref={gamificationRegulationRef}
              defaultValue={gamificationConfig?.gamification_regulation}
            />
          </HoverWrapper>
          <HoverWrapper label="Orders regulation">
            <Textarea
              placeholder="Type orders regulation..."
              cols={40}
              rows={5}
              required
              ref={ordersRegulationRef}
              defaultValue={gamificationConfig?.orders_regulation}
            />
          </HoverWrapper>
          <HoverWrapper label="Listening regulation">
            <Textarea
              placeholder="Type listening regulation..."
              cols={40}
              rows={5}
              required
              ref={listeningnRegulationRef}
              defaultValue={gamificationConfig?.listening_regulation}
            />
          </HoverWrapper>
          <HoverWrapper label="Devliery regulation">
            <Textarea
              placeholder="Type delivery regulation..."
              cols={40}
              rows={5}
              ref={deliveryRegulationRef}
              defaultValue={gamificationConfig?.delivery_regulation}
              required
            />
          </HoverWrapper>
          <HoverWrapper label="Sum up regulation">
            <Textarea
              placeholder="Type sum up regulation..."
              cols={40}
              rows={5}
              defaultValue={gamificationConfig?.sum_up_regulation}
              ref={sumUpRegulationRef}
              required
            />
          </HoverWrapper>
        </RegulationsWrapper>
        <Input
          color={Colors.darkBlue}
          width={202}
          inputWidth={80}
          type="number"
          name="Listening score target"
          inputRef={listeningTargetRef}
          value={gamificationConfig?.listening_score_target}
          required
        />
        <Input
          color={Colors.darkBlue}
          width={202}
          inputWidth={80}
          type="number"
          name="Normal mode constant"
          inputRef={normalModeRef}
          value={gamificationConfig?.normal_mode_constant}
          required
        />
        <Input
          color={Colors.darkBlue}
          width={202}
          inputWidth={80}
          type="number"
          name="Calling mode constant"
          inputRef={callingModeRef}
          value={gamificationConfig?.calling_mode_constant}
          required
        />
        <ButtonWrapper>
          <CustomButtonSave text="Save" />
        </ButtonWrapper>
      </form>
      {addPercentsMode && (
        <PercentsForm
          percentsRef={percentsRef}
          name={addPercentsMode === PERCENTS_MODES.WINNERS ? "Place" : "Stars"}
          forPercentsRef={
            addPercentsMode === PERCENTS_MODES.WINNERS
              ? placeForPercentsRef
              : starsForPercentsRef
          }
          setAddPercentsMode={setAddPercentsMode}
          handleAddPercents={handleAddPercents}
        />
      )}
    </PopUp>
  );
};

export default GamificatinForm;
