import React, { useState } from "react";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import SelectInput from "../../../../common/components/SelectInput";
import { createListeningGenerator } from "../../../../API/repositories/listeningGenerator";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
  margin-top: 100px;
`;

const ListeningGeneratorCreate = ({ setShow, managers, addMessage }) => {
  const [selectedManager, setSelectedManager] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSave = async () => {
    if (!selectedManager) {
      addMessage("Set Manager", "error");
      return;
    }

    const payload = {};
    payload.manager = selectedManager.value;

    const response = await makeRequest(
      createListeningGenerator.bind(null, payload)
    );

    if (response.data) {
      setShow(() => null);
    }
  };

  return (
    <PopUp setShow={setShow}>
      <Title>Create Li Gen:</Title>
      <SelectInput
        width={100}
        selectWidth={200}
        name="Manager"
        options={managers}
        selected={selectedManager}
        setSelected={setSelectedManager}
        color={Colors.darkBlue}
      />
      <ButtonWrapper>
        <button className="btn btn-warning" onClick={() => handleSave()}>
          Save
        </button>
      </ButtonWrapper>
    </PopUp>
  );
};

export default ListeningGeneratorCreate;
