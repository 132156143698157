import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./DynamicMailAttachment.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import DynamicMailAttachmentForm from "./components/dynamicMailAttachmentForm/DynamicMailAttachmentForm";
import DynamicMailAttachmentTable from "./components/dynamicMailAttachmentTable/DynamicMailAttachmentTable";
import { createDynamicMailAttachment, getAllDynamicMailAttachments, updateDynamicMailAttachment } from "@/API/repositories/dynamicMailAttachment";
import { getAllFiles } from "@/API/repositories/file";

const DynamicMailAttachment = () => {
  const [dynamicMailAttachments, setDynamicMailAttachments] = useState();
  const [isDynamicMailAttachmentFormOpen, setIsDynamicMailAttachmentFormOpen] = useState();
  const [selectedDynamicContent, setSelectedDynamicContent] = useState();
  const [filesOptions, setFilesOptions] = useState();

  const { messages, removeMessage, addMessage } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    await Promise.all([loadDynamicMailAttachments(), loadFiles()]);
  };

  const loadDynamicMailAttachments = async () => {
    const response = await makeRequest(getAllDynamicMailAttachments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDynamicMailAttachments(() => response.data);
  }

  const loadFiles = async () => {
    const response = await makeRequest(getAllFiles);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setFilesOptions(() => response.data.map(file => ({ value: file._id, label: file.filename })));
  }

  const handleSetDynamicMailAttachmentEdit = (content) => {
    setSelectedDynamicContent(() => content);
    setIsDynamicMailAttachmentFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsDynamicMailAttachmentFormOpen(() => false);
    setSelectedDynamicContent(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getAllDynamicMailAttachments,
        createDynamicMailAttachment,
        updateDynamicMailAttachment
      ) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <ContentWrapper>
        <CustomButtonAdd
          style={{ height: "fit-content" }}
          defaultText="Add dynamic mail attachment"
          onClick={() => setIsDynamicMailAttachmentFormOpen(true)}
        />
      </ContentWrapper>
      {isDynamicMailAttachmentFormOpen && (
        <DynamicMailAttachmentForm
          selectedDynamicContent={selectedDynamicContent}
          setShow={handleCloseForm}
          addMessage={addMessage}
          reload={loadData}
          filesOptions={filesOptions}
        />
      )}
      {!!dynamicMailAttachments?.length && (
        <DynamicMailAttachmentTable
          handleSetDynamicMailAttachmentEdit={handleSetDynamicMailAttachmentEdit}
          dynamicMailAttachments={dynamicMailAttachments}
        />
      )}
    </PageWrapper>
  );
};

export default DynamicMailAttachment;
