import { getMarkets } from "@/API/repositories/market";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import PromotionsFilters from "../filters/PromotionsFilters";
import { getUsers } from "@/API/repositories/user";
import {
  ButtonSaveWrapper,
  SubTitle,
  TestingWrapper,
} from "../promotion.styles";
import TextArea from "@/common/components/TextArea";
import { Colors } from "@/common/colors/colors";
import {
  createPromotion,
  handleCheckQueryPromotion,
  handleTestPromotionMail,
  updatePromotion,
} from "@/API/repositories/promotion";
import Table from "@/common/components/Table";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import PromoTemplateEdit from "./PromoTemplateEdit";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import Loading from "@/common/components/Loading";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";

const TAMPLATES_HEADER = [
  "No",
  "Name",
  "SenderName",
  "SenderEamil",
  "Weight",
  "Actions",
];

const FormWrapper = styled.form`
  width: 1200px;
  height: 800px;
`;

const MAIL_PAYLOAD = {
  promoUrl: "https://www.superpromocja.pl/",
};

function reducer(state, action) {
  if (action.type === "add")
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === "Number"
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.from_days && { from_days: parseInt(action.from_days) }),
        ...(action.from_hours && { from_hours: parseInt(action.from_hours) }),
        ...(action.to_days && { to_days: parseInt(action.to_days) }),
        ...(action.to_hours && { to_hours: parseInt(action.to_hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };
  if (action.type === "validate") {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === "remove") {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === "init") {
    return {
      ...action.data,
    };
  }
}

const PromotionElement = ({
  element,
  setElement,
  addMessage,
  reload,
  templates,
}) => {
  const [filters, dispatchFilters] = useReducer(reducer, {});
  const [selectedProduct, setSelectedProduct] = useState();
  const [productsOptions, setProductsOptions] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState();
  const [selected, setSelected] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [queryResults, setQueryResults] = useState();
  const [promotionTemplates, setPromotionTemplates] = useState(
    element.templateConfig || []
  );
  const [editPromotionTemplate, setEditPromotionTemplate] = useState();

  const nameRef = useRef();
  const cronRef = useRef();
  const activeRef = useRef();
  const emailTestRef = useRef();
  const textAreaRef = useRef();

  const {
    commonData: { products, markets, queues },
  } = useCommonDataContext();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleSave = async (e) => {
    e?.preventDefault();

    const payload = {
      ...element,
    };

    payload.name = nameRef.current?.value;
    payload.cron = cronRef.current?.value;
    payload.active = activeRef.current?.checked || false;
    payload.filters = JSON.stringify(filters);
    payload.templateConfig = promotionTemplates;
    payload.price = parseInt(selectedPrice?.value);
    payload.product = selectedProduct?.value?._id;

    if (payload._id) {
      const response = await makeRequest(updatePromotion.bind(null, payload));

      if (response?.data) {
        addMessage("Promotion updated", "success");
        setElement(() => null);
      }
      return;
    }

    const response = await makeRequest(createPromotion.bind(null, payload));

    if (response?.data) {
      addMessage("Promotion created", "success");
      setElement(() => null);
    }
  };

  const handleLoad = async () => {
    const responseConsultant = await makeRequest(getUsers);

    if (responseConsultant.data) {
      setConsultants(() =>
        responseConsultant.data.map((data) => {
          return { label: data.username, value: data._id };
        })
      );
    }

    dispatchFilters({
      type: "init",
      data: JSON.parse(element.filters),
    });

    setSelected(() =>
      Object.entries(JSON.parse(element.filters)).map(([key, value]) => {
        return {
          label: key,
          value: { ...value, type: value.type_field },
        };
      })
    );
  };

  const handleCheckQuery = async () => {
    const payload = {
      query: filters,
      data: {
        mailConfigId: element?._id,
      },
    };

    const responseQueryPromotion = await makeRequest(
      handleCheckQueryPromotion.bind(null, payload)
    );

    if (responseQueryPromotion.data) {
      setQueryResults(() => responseQueryPromotion.data);
    }
  };

  const handleTest = async (e) => {
    e?.preventDefault();

    const payload = {
      email: emailTestRef?.current?.value,
      category_id: element?._id,
      mail_payload: textAreaRef.current.value,
    };

    const responseSend = await makeRequest(
      handleTestPromotionMail.bind(null, payload)
    );

    if (responseSend.data) {
      addMessage("Promotion mail send", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  useEffect(() => {
    handleLoad();
    setProductsOptions(() =>
      products.map((d) => ({ label: d.name, value: d }))
    );
  }, [products]);

  useEffect(() => {
    if (element.product && productsOptions?.length > 0) {
      const currentProduct = productsOptions.find(
        (p) => p.value._id === element.product
      );

      setSelectedProduct(() => currentProduct);

      const price = currentProduct.value.prices.find(
        (p) => p.value == element.price
      );

      if (!price) {
        return;
      }

      setSelectedPrice(() => ({ label: price.value, value: price.value }));
    }
  }, [productsOptions]);

  return (
    <>
      <PopUp setShow={setElement}>
        {hasUnfilledRequest(
          updatePromotion, 
          createPromotion, 
          getUsers, 
          handleCheckQueryPromotion,
          handleTestPromotionMail,
        ) && <Loading />}
        <FormWrapper onSubmit={handleSave}>
          <h3>Promotion {element._id ? "Edit" : "Create"}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Input
                name="Name"
                inputRef={nameRef}
                width={80}
                inputWidth={250}
                value={element.name}
              />
              <Input
                inputRef={cronRef}
                name="Cron"
                width={80}
                inputWidth={250}
                value={element.cron || "* * * * * *"}
              />
              <SelectInput
                name="Price"
                options={selectedProduct?.value?.prices?.map((p) => ({
                  label: p.value,
                  value: p.value,
                }))}
                selected={selectedPrice}
                setSelected={setSelectedPrice}
                width={80}
                selectWidth={250}
              />
              <ToggleSwitch
                width={100}
                text="Active"
                toggleRef={activeRef}
                checked={element.active}
              />
            </div>
            <div>
              <SelectInput
                name="Product"
                options={productsOptions}
                selected={selectedProduct}
                setSelected={setSelectedProduct}
                width={120}
                selectWidth={400}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SubTitle>Templates:</SubTitle>
              <CustomButtonAdd
                defaultText="Add Template"
                onClick={() => setEditPromotionTemplate({})}
              />
            </div>
            <Table
              headers={TAMPLATES_HEADER}
              raws={
                promotionTemplates
                  ?.sort((a, b) => a.id - b.id)
                  .map((t) => (
                    <tr>
                      <td>{t.id || "-----"}</td>
                      <td>{t.name || "-----"}</td>
                      <td>{t.senderName || "-----"}</td>
                      <td>{t.senderEmail || "-----"}</td>
                      <td>{t.weight || "-----"}</td>
                      <td>
                        <i
                          className="fa fa-edit animation-scale"
                          style={{ cursor: "pointer" }}
                          onClick={() => setEditPromotionTemplate(t)}
                        />
                      </td>
                    </tr>
                  )) || []
              }
              className="styled-table-2"
            />
          </div>
          {consultants[0] && markets[0] && products[0] && queues[0] && (
            <PromotionsFilters
              filters={filters}
              dispatchFilters={dispatchFilters}
              selectedData={selected}
              setSelectedData={setSelected}
              markets={handleMapToOptions(markets, "name", "_id")}
              products={handleMapToOptions(products, "name", "_id")}
              queues={handleMapToOptions(queues, "name", "_id")}
              consultants={consultants}
            />
          )}
          <div style={{ display: "flex", justifyContent: "right" }}>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => handleCheckQuery()}
              disabled={!element._id}
            >
              Check query result
            </button>
          </div>
          <SubTitle>Testing: </SubTitle>
          <TestingWrapper>
            <TextArea
              textAreaRef={textAreaRef}
              label="Sending object"
              placeholder="object"
              defaultValue={JSON.stringify(MAIL_PAYLOAD, null, "\t")}
              fontSize={"12px"}
              width="500px"
              minHeight="210px"
            />
            <div>
              <Input
                showLabel={false}
                inputRef={emailTestRef}
                inputWidth={350}
                name="Mail"
                type="email"
                placeholder="Type email..."
                color={Colors.darkBlue}
              />
              <button
                type="button"
                className="btn btn-warning"
                disabled={!element._id}
                onClick={() => handleTest()}
              >
                Test
              </button>
            </div>
          </TestingWrapper>
          <ButtonSaveWrapper>
            <button className="btn btn-warning" type="submit">
              Save
            </button>
          </ButtonSaveWrapper>
        </FormWrapper>
      </PopUp>
      {editPromotionTemplate && (
        <PromoTemplateEdit
          edit={editPromotionTemplate}
          setEdit={setEditPromotionTemplate}
          templates={templates}
          setTemplateConfig={setPromotionTemplates}
        />
      )}
      {queryResults && (
        <PopUp setShow={setQueryResults}>
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Full name</th>
                <th>Email</th>
                <th>Market</th>
                <th>Product</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {queryResults[0] ? (
                queryResults.map((result, i) => (
                  <tr key={result._id}>
                    <td>{i + 1}.</td>
                    <td>{result.contact.full_name}</td>
                    <td>{result.contact.email}</td>
                    <td>{result.market.name}</td>
                    <td>{result.product.name}</td>
                    <td>
                      <i
                        className="fa fa-address-book animation-scale"
                        style={{ color: Colors.darkBlue, cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `/dashboard/order/${result._id}`,
                            "_blank"
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>There is no data</td>
                </tr>
              )}
            </tbody>
          </table>
        </PopUp>
      )}
    </>
  );
};

export default PromotionElement;
