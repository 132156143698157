import styled from "styled-components";
import { Colors } from "../colors/colors";

export const SearchBarRaw = styled.div`
  border: 1px solid ${Colors.darkBlue};
  padding: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SearchBarColumn = styled.div`
  border: 1px solid ${Colors.darkBlue};
  padding: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const SearchBarCenter = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue};
  min-width: 50vw;
  gap: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
`;
