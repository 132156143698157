import React, { useRef, useState } from "react";
import PopUp from "../PopUp";
import SelectInput from "../SelectInput";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "../buttons/CustomButtonSave";
import { ButtonWrapper, Form } from "./Resign.styled";
import { RESIGN_OPTIONS } from "@/common/constants/resignOptions";
import Input from "../Input";

const Resign = ({ setShow, handleResign }) => {
  const [selectedReason, setSelectedReason] = useState();
  const commentRef = useRef();

  return (
    <PopUp padding="12px 20px" setShow={setShow}>
      <Form
        onSubmit={(e) =>
          handleResign(e, selectedReason.value, commentRef.current.value)
        }
      >
        <SelectInput
          setSelected={setSelectedReason}
          selected={selectedReason}
          name="Resign reason"
          color={Colors.darkBlue}
          width="150"
          options={RESIGN_OPTIONS}
          required
        />
        <Input
          requiredSign
          color={Colors.darkBlue}
          required
          inputRef={commentRef}
          width={150}
          name="Comment"
        />
        <ButtonWrapper>
          <CustomButtonSave styles={{ margin: 0 }} text="Resign" />
        </ButtonWrapper>
      </Form>
    </PopUp>
  );
};

export default Resign;
