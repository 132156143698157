import React from "react";
import {
  SubTitle,
  TitleIcon,
  Info,
  HoverRegulation,
} from "../../../../Gamification.styled";
import {
  StarItemWrapper,
  StarWrapper,
  StarImage,
  StarCoverWrapper,
  StarImageCover,
} from "../../../../common/components/stars/Stars.styled";
import Phone from "@/images/icons/gamification-phone.svg";
import GrayStar from "@/images/icons/gamification-gray-star.svg";
import FilledStar from "@/images/icons/gamification-filled-star.svg";
import {
  ScoringWrapper,
  ScoringStarWrapper,
  AverageScoreWrapper,
} from "./CallReview.styled";

const CallReview = ({ userData, regulation }) => {
  const { listening_target, average_listening_score } = userData;

  return (
    <>
      <SubTitle>
        Call Review
        <HoverRegulation regulation={regulation}>
          <TitleIcon className="fa fa-thin fa-circle-info" />
        </HoverRegulation>
      </SubTitle>
      <ScoringWrapper>
        <ScoringStarWrapper>
          <StarItemWrapper>
            <StarWrapper width="40px">
              <StarImage alt="gray-star" src={GrayStar} />
            </StarWrapper>
            <StarCoverWrapper
              width={`${
                average_listening_score < listening_target
                  ? (average_listening_score / listening_target) * 100
                  : 100
              }%`}
            >
              <StarImageCover alt="filled-star" src={FilledStar} />
            </StarCoverWrapper>
          </StarItemWrapper>
        </ScoringStarWrapper>
        <AverageScoreWrapper>
          <img src={Phone} alt="phone" />
          <Info>
            <b>{average_listening_score}</b> / {listening_target}
          </Info>
        </AverageScoreWrapper>
      </ScoringWrapper>
    </>
  );
};

export default CallReview;
