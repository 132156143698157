import { formatDate } from "@/common/functions/dateFormater";

export const getRaws = (suspicions) =>
  suspicions.map((el, i) => (
    <tr key={el._id}>
      <td>{i + 1}</td>
      <td>{el._consultant.username}</td>
      <td>{el._maker.username}</td>
      <td>{el._contact.full_name}</td>
      <td>{el._contact.phone_number}</td>
      <td>{el._contact.email}</td>
      <td>
        {el.suspicion.is_bad ? "Correct suspicion" : "Incorrect suspicion"}
      </td>
      <td>{formatDate(el.created_at, "YYYY-MM-DD HH:mm")}</td>
    </tr>
  ));
