import moment from "moment";

export const LAST_YEAR_MONTHS = [...Array(12).keys()].map(
  (howManySubstract) => {
    const element = {};

    element.value = moment()
      .subtract(howManySubstract, "months")
      .startOf("month");
    element.label = moment()
      .subtract(howManySubstract, "months")
      .format("MMMM YYYY");

    return element;
  }
);
