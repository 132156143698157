import { Colors } from "@/common/colors/colors";

export const getColor = (priority) => {
  switch (priority) {
    case "low_priority":
      return { background: Colors.yellow, color: "black" };
    case "high_priority":
      return { background: Colors.red, color: "black" };
    default:
      return {};
  }
};
