import { NOT_FINISHED_QUEUE_STATUSES } from "../constants/queue";
import { SHIPPING_NOT_SEND_STATUSES } from "../constants/shipping";
import { NOT_EDITABLE_STATUSES } from "../constants/statuses";

export const checkIsOrderEditable  = (order) => {
  if (NOT_FINISHED_QUEUE_STATUSES.includes(order?.status)) {
    return true;
  }

  if (!order.shipping) {
    return true;
  }

  return (
    SHIPPING_NOT_SEND_STATUSES.includes(order?.shipping?.status) &&
    !NOT_EDITABLE_STATUSES.includes(order?.status)
  );
}