import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getConsultantOrders } from "../../API/repositories/order";
import translationManager from "../../API/translationManager";
import userManager from "../../API/userManager";
import Card from "../../common/components/Card";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { ALLOWED_CONSULTANT_ORDER_STATUSES } from "../../common/constants/statuses";
import { useRequestsContext } from "../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import OrdersTable from "../../components/consultant/orders/OrdersTable";
import { getToday } from "@/common/functions/getToday";
import SearchWrapper from "@/common/components/newDesign/SearchWrapper";
import { Colors } from "@/common/colors/colors";

const Wrapper = styled.div`
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
const ConsultantOrders = () => {
  const [selected, setSelected] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState();
  const navigate = useNavigate();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const fromRef = useRef();
  const toRef = useRef();
  const today = getToday();

  const hadnleGetConsultantOrders = async (e) => {
    e.preventDefault();
    setDataToDisplay(() => null);

    const payload = Object();

    payload.statuses = selected.map((data) => data.value);
    if (payload.statuses.length === 0) return;

    payload.from = fromRef.current
      ? moment(fromRef.current.value).startOf("day")
      : null;
    payload.to = toRef.current
      ? moment(toRef.current.value).endOf("day")
      : null;

    const response = await makeRequest(
      getConsultantOrders.bind(null, userManager.getUser().id, payload)
    );

    if (response.data && response.data.length > 0) {
      setDataToDisplay(() =>
        response.data.sort((a, b) => a.status.localeCompare(b.status))
      );
    }
  };

  const [translation, setTranslation] = useState({
    status: "Status",
    start: "From",
    end: "To",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getConsultantOrders) && <Loading />}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <SearchWrapper onSubmit={hadnleGetConsultantOrders}>
          <SelectInput
            options={ALLOWED_CONSULTANT_ORDER_STATUSES}
            selected={selected}
            setSelected={setSelected}
            multiple={true}
            name={translation["status"]}
            width={80}
            selectWidth={200}
            required
            color={Colors.darkBlue}
          />
          <Input
            inputRef={fromRef}
            width={100}
            name={translation["start"]}
            type="date"
            inputWidth={150}
            value={today}
            color={Colors.darkBlue}
            required
            requiredSign
          />
          <Input
            inputRef={toRef}
            width={90}
            name={translation["end"]}
            type="date"
            inputWidth={150}
            value={today}
            color={Colors.darkBlue}
            required
            requiredSign
          />
        </SearchWrapper>
      </div>
      <div style={{ width: "100%" }}>
        {dataToDisplay && <OrdersTable data={dataToDisplay} />}
      </div>
    </Wrapper>
  );
};

export default ConsultantOrders;
