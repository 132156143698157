import React, { useEffect, useRef, useState } from "react";
import {
  ChartWrapper,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import {
  getLastConsultantWPPK,
  getLastMarketWPPK,
} from "@/API/repositories/reports";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import CustomLine from "@/common/charts/Line";
import { handleMapData } from "./helpers/handleMapData";

const WPPKDeliveredMarket = () => {
  const [chartData, setChartData] = useState();
  const timeRangeRef = useRef();

  const {
    commonData: { products },
  } = useCommonDataContext();

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setChartData(() => null);

    const payload = {};
    payload.timeFrom = timeRangeRef.current.value;
    payload.timeTo = 2;

    const response = await makeRequest(getLastMarketWPPK.bind(null, payload));
    if (response.data) {
      response.data.sort(
        (a, b) => new Date(a.from).getTime() - new Date(b.from).getTime()
      );

      handleMapData(response.data, setChartData, products);
    }
  };

  useEffect(() => {
    if (products) handleLoadData();
  }, [products]);

  return (
    <Wrapper width={800}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa-brands fa-usps" />
          Weekly Product WPPK delivered
        </TopContentWrapper>
        <TopContentWrapper>
          <Input
            showLabel={false}
            inputWidth={50}
            value={2}
            inputRef={timeRangeRef}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {chartData ? (
          <CustomLine
            data={chartData}
            options={{
              scales: {
                y: {
                  type: "linear",
                  display: true,
                  position: "left",
                },
              },
            }}
          />
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default WPPKDeliveredMarket;
