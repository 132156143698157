import React, { useEffect, useReducer, useRef, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { getMailTemplates } from "../../../API/repositories/mailTemplate";
import {
  ButtonCreateWrapper,
  ButtonSaveWrapper,
  Title,
  SubTitle,
  Wrapper,
  TestingWrapper,
  WrapperCategory,
  LineWrapper,
} from "../../../common/styles/Mails";
import PopUp from "../../../common/components/PopUp";
import Loading from "../../../common/components/Loading";
import MessageQueue, { useMessageQueue } from "../../../common/messageProvider";
import Input from "../../../common/components/Input";
import { Colors } from "../../../common/colors/colors";
import { getMailTests } from "../../../API/repositories/mailTest";
import SelectInput from "../../../common/components/SelectInput";
import {
  createMailCategory,
  getMailCategories,
  testCategoryEmail,
  updateMailCategory,
} from "../../../API/repositories/mailCategory";
import TextArea from "../../../common/components/TextArea";
import CategoryFilter from "../../../components/admin/mails/CategoryFilter";
import { getUsers } from "../../../API/repositories/user";
import { getMarkets } from "../../../API/repositories/market";
import { getProducts } from "../../../API/repositories/product";
import { getOrdersToMailByQuery } from "../../../API/repositories/mail";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import NewConditionalField from "./components/newConditionalField/NewConditionalField";
import { PropertiesWrapper, Property } from "./MailCategory.styled";
import userManager from "@/API/userManager";
import { getQueues } from "@/API/repositories/queue";
import GroupConfig from "./components/groupConfig/GroupConfig";
import GroupConfigTable from "./components/groupConfigTable/GroupConfigTable";

const RESTRICTED_ROLES = ["SUPPORT"];

const MAIL_PAYLOAD = {
  order_id: "2023",
  invoice_number: "FS01092023SK",
  tracking_link: "https://tandt.posta.sk/en/items/EB667251449SK",
  paymentLink:
    "https://ustav-pro-lingvisticky-vyzkum.cz/anglictina50pluslp2/typ/?utm_source=mail&utm_medium=pay&price_version=v1",
  paymentLink3Days:
    "https://ustav-pro-lingvisticky-vyzkum.cz/anglictina50pluslp2/typ/?utm_source=mail&utm_medium=pay_3days&price_version=v1",
  totalPrice: 1960,
  waybill: "123456789",
  region: "Praha",
  hasPromotion: true,
  coursename: "Angielski w 30 dni dla dorosłych",
  paylink: "https://cj-fasttrack.pl/typ/?utm_campaign=mail&utm_medium=pay",
  name: "Test Name",
  paymentOrder: [
    {
      course: "Trójpoziomowy kurs angielskiego",
      voucher: "PL22222",
    },
    {
      course: "Trójpoziomowy kurs niemieckiego",
      voucher: "PL22222",
    },
  ],
  orders: [
    {
      index: 1,
      product: "Tříúrovňový kurz angličtiny",
      price: 1960,
    },
  ],
};

const ATTACHMENT_OPTIONS = [
  {
    label: "Sales invoice",
    value: "sales",
  },
  {
    label: "Correction invoice",
    value: "correction",
  },
  {
    label: "None",
    value: "",
  },
];

function reducer(state, action) {
  if (action.type === "add")
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === "Number"
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.days && { days: parseInt(action.days) }),
        ...(action.hours && { hours: parseInt(action.hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };
  if (action.type === "validate") {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === "remove") {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === "init") {
    return {
      ...action.data,
    };
  }
}

const MailTemplateElement = ({
  element,
  setElement,
  addMessage,
  reload,
  templates,
  mailCategories,
}) => {
  const [filters, dispatchFilters] = useReducer(reducer, {});
  const [showGroupConfig, setShowGroupConfig] = useState(false);
  const [groupConfigs, setGroupConfigs] = useState(element.group_configs || []);
  const [selected, setSelected] = useState([]);
  const [queryResult, setQueryResult] = useState();
  const [isNewConditionaFieldOpen, setIsNewConditionaFieldOpen] = useState();
  const [conditionalFields, setConditionalFields] = useState(
    element.conditional_fields ? JSON.parse(element.conditional_fields) : null
  );

  const { makeRequest } = useRequestsContext();
  const [showInfo, setShowInfo] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [products, setProducts] = useState([]);
  const [queues, setQueues] = useState([]);

  const [consultants, setConsultants] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedAttachment, setSelectedAttachment] = useState(
    ATTACHMENT_OPTIONS.find(
      (option) => option.value === element.attachment
    ) || { label: "None", value: "" }
  );
  const categoryNameRef = useRef();
  const cronTimeRef = useRef();
  const activeRef = useRef();
  const shouldSendOnceRef = useRef();
  const shouldSendOnWeekendRef = useRef();
  const shouldSendCmsPaymentUrlRef = useRef();
  const emailTestRef = useRef();
  const textAreaRef = useRef();
  const fileRef = useRef();
  const typRef = useRef();

  const handleFindOrdersByQuery = async () => {
    filters.should_send_mail_once = element.should_send_mail_once;
    filters.mailCategoryId = element._id;

    const response = await makeRequest(
      getOrdersToMailByQuery.bind(null, filters)
    );

    if (response.data) {
      setQueryResult(() => response.data);
    }
  };

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (cronTimeRef?.current?.value?.split(" ").length != 6) {
      addMessage("CRON TIME SHOULD HAVE 6 spaces check info", "error");
      return;
    }

    if (cronTimeRef?.current?.value) {
      const hasError = cronTimeRef?.current?.value
        ?.split(" ")
        .filter((element) => element.includes("/*"));

      if (hasError.length > 0) {
        addMessage("Not valid Cron, check info");
        return;
      }
    }

    if (selectedProduct.length === 0 || !groupConfigs?.length) {
      addMessage("Please add product and  configs", "error");
      return;
    }

    const payload = {};

    payload._id = element._id || null;
    payload.name = categoryNameRef.current?.value || element.name;
    payload.group_configs = groupConfigs;
    payload.product = selectedProduct.value;
    payload.cron_time = cronTimeRef.current.value || element.cron_time;
    payload.active = activeRef.current?.checked || false;
    payload.filters = JSON.stringify(filters);
    payload.should_send_mail_once = shouldSendOnceRef.current?.checked || false;
    payload.should_send_on_weekend =
      shouldSendOnWeekendRef.current?.checked || false;
    payload.attachment = selectedAttachment.value;
    payload.conditional_fields = conditionalFields?.length
      ? JSON.stringify(conditionalFields)
      : null;
    payload.typ_url_part = typRef.current?.value;
    payload.should_include_cms_payment_link = shouldSendCmsPaymentUrlRef.current?.checked || false;

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailCategory.bind(null, payload));
    } else {
      response = await makeRequest(createMailCategory.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  const handleLoadData = async () => {
    const responseProducts = await makeRequest(getProducts);
    if (responseProducts.data) {
      const productOptions = responseProducts.data.map((data) => {
        return { label: data.name, value: data._id };
      });

      setProducts(() => productOptions);

      if (element.product) {
        setSelectedProduct(() =>
          productOptions.find((p) => element.product === p.value)
        );
      }
    }

    const responseQueues = await makeRequest(getQueues);

    if (responseQueues.data) {
      const queuesOptions = responseQueues.data.map((data) => {
        return { label: data.name, value: data._id };
      });

      setQueues(() => [...queuesOptions, { label: "Null", value: null }]);
    }

    const responseMarket = await makeRequest(getMarkets);
    if (responseMarket.data) {
      setMarkets(() =>
        responseMarket.data.map((data) => {
          return { label: data.name, value: data._id };
        })
      );
    }

    const responseConsultant = await makeRequest(getUsers);
    if (responseConsultant.data) {
      setConsultants(() =>
        responseConsultant.data.map((data) => {
          return { label: data.name + " " + data.surname, value: data._id };
        })
      );
    }

    dispatchFilters({
      type: "init",
      data: JSON.parse(element.filters),
    });

    setSelected(() =>
      Object.entries(JSON.parse(element.filters)).map(([key, value]) => {
        return {
          label: key,
          value: { ...value, type: value.type_field },
        };
      })
    );
  };

  const handleRemoveConditioalField = (timestamp) => {
    setConditionalFields((prev) => {
      prev.forEach((field) => {
        field.properties = field.properties?.filter(
          (prop) => prop.timestamp !== timestamp
        );
      });
      return prev.filter((el) => el.properties?.length);
    });
  };

  const handleTest = async () => {
    if (!!selectedAttachment?.value?.length && !fileRef.current?.files[0]) {
      return addMessage("Select file", "error");
    }

    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);
    formData.append("category_id", element._id);
    formData.append("email", emailTestRef.current.value);
    formData.set("mail_payload", textAreaRef.current?.value || MAIL_PAYLOAD);

    const response = await makeRequest(testCategoryEmail.bind(null, formData));

    if (response.data) {
      addMessage("Sent", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <PopUp setShow={setElement}>
        <WrapperCategory>
          <Title>
            Mail Category{" "}
            <i
              className="fa fa-circle-info animation-scale"
              style={{ cursor: "pointer" }}
              onClick={() => setShowInfo(() => true)}
            />
          </Title>
          <form onSubmit={(e) => handleSave(e)}>
            <SubTitle>Settings: </SubTitle>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <Input
                  inputRef={categoryNameRef}
                  width={150}
                  inputWidth={350}
                  name="Name"
                  value={element.name}
                  color={Colors.darkBlue}
                  requiredSign
                  required
                />
                <Input
                  inputRef={typRef}
                  width={150}
                  inputWidth={350}
                  name="TYP url part"
                  value={element.typ_url_part}
                  color={Colors.darkBlue}
                />
                <Input
                  inputRef={activeRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  disabled={!element._id}
                  name="Active"
                  checked={element.active}
                  color={Colors.darkBlue}
                />
                <CustomButtonAdd
                  text="add conditional"
                  onClick={() => setIsNewConditionaFieldOpen(true)}
                />
                <div
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                  }}
                >
                  {conditionalFields?.map((field) => (
                    <div key={field.value}>
                      {field.value} by {field.fieldBy}
                        <PropertiesWrapper>
                          {field.properties.map((prop) => (
                            <Property
                              onClick={() =>
                                handleRemoveConditioalField(prop.timestamp)
                              }
                              key={prop.timestamp}
                            >
                              {prop.property} - {prop.value}
                            </Property>
                          ))}
                        </PropertiesWrapper>
                      </div>
                    ))}
                  </div>
                  <CustomButtonAdd 
                    text="add group" 
                    onClick={() => setShowGroupConfig(true)} 
                  />
                  {!!groupConfigs?.length && (
                    <GroupConfigTable
                      templates={templates}
                      groupConfigs={groupConfigs}
                      setShowGroupConfig={setShowGroupConfig}
                    />
                  )}
              </div>
              <div>
                <Input
                  link={"https://crontab.guru/"}
                  value={element.cron_time}
                  inputRef={cronTimeRef}
                  width={150}
                  inputWidth={150}
                  name="Cron time"
                  color={Colors.darkBlue}
                  requiredSign
                  required
                />
                <SelectInput
                  name={"Product"}
                  width={150}
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={products}
                  selected={selectedProduct}
                  setSelected={setSelectedProduct}
                  required
                />
                <SelectInput
                  name="Attachment"
                  width={150}
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={ATTACHMENT_OPTIONS}
                  selected={selectedAttachment}
                  setSelected={setSelectedAttachment}
                />
                <Input
                  inputRef={shouldSendOnceRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  name="Send mail once"
                  checked={
                    element.should_send_mail_once === undefined
                      ? true
                      : element.should_send_mail_once
                  }
                  color={Colors.darkBlue}
                />
                <Input
                  inputRef={shouldSendOnWeekendRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  name="On weekend"
                  checked={element.should_send_on_weekend}
                  color={Colors.darkBlue}
                />
                <Input
                  inputRef={shouldSendCmsPaymentUrlRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  name="Payment url"
                  checked={element.should_include_cms_payment_link}
                  color={Colors.darkBlue}
                />
              </div>
            </div>
            {consultants[0] && markets[0] && products[0] && queues[0] && (
              <CategoryFilter
                filters={filters}
                dispatchFilters={dispatchFilters}
                selectedData={selected}
                setSelectedData={setSelected}
                markets={markets}
                products={products}
                queues={queues}
                consultants={consultants}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => handleFindOrdersByQuery()}
                disabled={!element._id}
              >
                Check query result
              </button>
            </div>
            <SubTitle>Testing: </SubTitle>
            <TestingWrapper>
              <TextArea
                textAreaRef={textAreaRef}
                label="Sending object"
                placeholder="object"
                defaultValue={JSON.stringify(MAIL_PAYLOAD, null, "\t")}
                fontSize={"12px"}
                width="500px"
                minHeight="210px"
              />
              <div>
                <Input
                  showLabel={false}
                  inputRef={emailTestRef}
                  inputWidth={350}
                  name="Mail"
                  type="email"
                  placeholder="Type email..."
                  color={Colors.darkBlue}
                />
                {!!selectedAttachment?.value?.length && (
                  <Input
                    type="file"
                    inputRef={fileRef}
                    showLabel={false}
                    accept="application/pdf"
                    inputWidth={350}
                  />
                )}
                <button
                  type="button"
                  className="btn btn-warning"
                  disabled={!element._id}
                  onClick={() => handleTest()}
                >
                  Test
                </button>
              </div>
            </TestingWrapper>
            <ButtonSaveWrapper>
              <button className="btn btn-warning" type="submit">
                Save
              </button>
            </ButtonSaveWrapper>
          </form>
        </WrapperCategory>
        {isNewConditionaFieldOpen && (
          <NewConditionalField
            addMessage={addMessage}
            setConditionalFields={setConditionalFields}
            setShow={setIsNewConditionaFieldOpen}
          />
        )}
      </PopUp>
      {queryResult && (
        <PopUp setShow={setQueryResult}>
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Full name</th>
                <th>Email</th>
                <th>Market</th>
                <th>Product</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {queryResult[0] ? (
                queryResult.map((result, i) => (
                  <tr key={result._id}>
                    <td>{i + 1}.</td>
                    <td>{result.contact.full_name}</td>
                    <td>{result.contact.email}</td>
                    <td>{result.market.name}</td>
                    <td>{result.product.name}</td>
                    <td>
                      <i
                        className="fa fa-address-book animation-scale"
                        style={{ color: Colors.darkBlue, cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `/dashboard/order/${result._id}`,
                            "_blank"
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>There is no data</td>
                </tr>
              )}
            </tbody>
          </table>
        </PopUp>
      )}
      {showGroupConfig && (
        <GroupConfig 
          setGroupConfigs={setGroupConfigs}
          groupConfigs={groupConfigs}
          templatesOptions={templates}
          setShow={setShowGroupConfig} 
          config={showGroupConfig} 
        />
      )}
      {showInfo && (
        <PopUp setShow={setShowInfo}>
          <Title>Wskazówki do CronTime:</Title>
          <LineWrapper>1. Musi byc 6 spacji nie mniej nie wiecej.</LineWrapper>
          <LineWrapper>
            2. Default to * * * * * * - wtedy beda sie wykonywac co sekunde
          </LineWrapper>
          <LineWrapper>
            3. 1(wsza) giwazdka to sekundy (1-60) , 2(ga) gwiazdka to
            minuty(1-60), 3(cia) to godziny (1-24), 4 to dni w miesiacu , 5 to
            miesiace, 6 to dni w tygodniu (1-7 gdzie 1 to poniedzialek)
          </LineWrapper>
          <LineWrapper>Przyklady:</LineWrapper>
          <LineWrapper>45 * * * * * - co minute w 45 sekundzie</LineWrapper>
          <LineWrapper>0 10 * * * * - co godzine w 10 minucie</LineWrapper>
          <LineWrapper>
            0 */30 9-17 * * * - co 30 minut w godzinach 9 - 17{" "}
          </LineWrapper>
          <LineWrapper>
            0 30 11 * * 1-5 - od poniedzialku do piatku o 11:30
          </LineWrapper>
          W razie czego to tu mozna sie czego dowiedziec{" "}
          <a
            href="https://docs.nestjs.com/techniques/task-scheduling"
            target="_blank"
            rel="noreferrer"
          >
            https://docs.nestjs.com/techniques/task-scheduling
          </a>
          . Klikajac na cron time mozna wejsc w testera.
        </PopUp>
      )}
    </>
  );
};

const MailCategory = () => {
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [testData, setTestData] = useState();
  const [element, setElement] = useState();
  const [filterRegex, setFilterRegex] = useState();

  const { addMessage, messages } = useMessageQueue();

  const isRestricted = RESTRICTED_ROLES.includes(userManager.getUser().role);

  const handleLoadData = async () => {
    const mailTemplateResponse = await makeRequest(getMailTemplates);

    if (mailTemplateResponse.data) {
      setMailTemplateOptions(() =>
        mailTemplateResponse.data.map((template) => ({
          label: template.template_sengrid_name,
          value: template._id,
        }))
      );
    }

    const testsResponse = await makeRequest(getMailTests);

    if (testsResponse.data) {
      setTestData(() => testsResponse.data.map((test) => test.data[0]));
    }

    const response = await makeRequest(getMailCategories);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  const getIconByBool = (bool) => {
    if (!!bool) {
      return (
        <i
          className="fa fa-check"
          style={{ color: "green", fontSize: "18px" }}
        />
      );
    }

    return (
      <i className="fa fa-remove" style={{ color: "red", fontSize: "18px" }} />
    );
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 3) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  return (
    <>
      {hasUnfilledRequest(
        createMailCategory,
        updateMailCategory,
        getMailCategories,
        getProducts,
        getMarkets,
        getOrdersToMailByQuery,
        getMailTemplates
      ) && <Loading />}
      <MessageQueue messages={messages} />
      <Wrapper>
        <ButtonCreateWrapper>
          {!isRestricted && (
            <button
              className="btn btn-warning animation-scale"
              onClick={() => setElement({})}
            >
              Create
            </button>
          )}
          <Input
            width={100}
            inputWidth={200}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            addMessage={addMessage}
            reload={handleLoadData}
            templates={mailTemplateOptions}
            mailCategories={data.map((element) => ({
              label: element.name,
              value: element._id,
            }))}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={6}>Mail Categories</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Cron</th>
              <th>Active</th>
              <th>Send once</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] && mailTemplateOptions[0] ? (
              data
                .filter((e) =>
                  filterRegex ? e.name.includes(filterRegex) : true
                )
                .map((element, i) => (
                  <tr key={element._id}>
                    <td>{i + 1}.</td>
                    <td>{element.name}</td>
                    <td>{element.cron_time}</td>
                    <td>{getIconByBool(element.active)}</td>
                    <td>{getIconByBool(element.should_send_mail_once)}</td>
                    <td>
                      {!isRestricted && (
                        <i
                          className="fa fa-edit animation-scale"
                          style={{ cursor: "pointer" }}
                          onClick={() => setElement(element)}
                        />
                      )}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default MailCategory;
