import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const NobodyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 420px;
  color: ${Colors.red};
`;

export const Line = styled.div`
  border-bottom: 1px dashed ${Colors.darkBlue};
  color: ${Colors.darkBlue};
  font-style: italic;
  margin: 10px 0;
`;

export const GroupWrapper = styled.div``;

export const ConsultantViewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;
  overflow: auto;
  gap: 10px;
  margin-top: 10px;
  overflow: auto;
  height: 95%;
`;

export const RowWrapper = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 200px;
  height: fit-content;
`;

export const Circle = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
  background-color: ${(props) => (props.isMnager ? "lightcoral" : "aliceblue")};
`;

export const WorkingSummaryWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const FullName = styled.p`
  display: flex;
  justify-content: left;
  color: ${Colors.darkBlue};
  font-size: 12px;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const EmailWrapper = styled.p`
  display: flex;
  color: lightgrey;
  font-style: italic;
  font-size: 10px;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Point = styled.div`
  background-color: ${(prev) => prev.color};
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 2px 0 0 3px;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CounterWrapper = styled.div`
  display: flex;
  gap: 5px;
  color: ${(prev) => prev.color};
  align-items: center;
  font-weight: bold;
`;
