import { formatMonthName, formatToDateTamplate } from '@/common/functions/dateFormater';
import { generateExcelSheetBuffer } from '@/common/functions/getExcelBuffer';
import { getExcelClaimsHeaderMap } from '../constants/headers';

export const getClaimReportExcel = async (marketName, data, month) => {
	const sheetName = `EOU_${formatMonthName(month)}_zwroty_${marketName.toLowerCase()}`;
	const EXCEL_CLAIMS_HEADERS_MAP = getExcelClaimsHeaderMap(data[0].transfers.at(-1).currency);

	const excelData = data.map((item, i) => ({
		[EXCEL_CLAIMS_HEADERS_MAP.idx]: i + 1,
		[EXCEL_CLAIMS_HEADERS_MAP.market]: marketName,
		[EXCEL_CLAIMS_HEADERS_MAP.month]: formatMonthName(month, 'YYYY-MM'),
		[EXCEL_CLAIMS_HEADERS_MAP.form]: item.transfers.at(-1).provider,
		[EXCEL_CLAIMS_HEADERS_MAP.date]: formatToDateTamplate(item.order.shipping.status_delivered_at
			|| item.order.shipping.status_delivered_no_payment_at),
		[EXCEL_CLAIMS_HEADERS_MAP.refundDate]: formatToDateTamplate(item.transfers.at(-1).initiated_at),
		[EXCEL_CLAIMS_HEADERS_MAP.fullName]: item.transfers.at(-1).full_name,
		[EXCEL_CLAIMS_HEADERS_MAP.amount]: item.transfers.at(-1).amount,
		[EXCEL_CLAIMS_HEADERS_MAP.invoiceCorrection]: item.correction_invoice?.valid_invoice.correction_number || "----"
	}));

	excelData.push({
		[EXCEL_CLAIMS_HEADERS_MAP.amount]: data.reduce((acc, item) => acc + item.transfers.at(-1).amount, 0)
	})

	const buffer = await generateExcelSheetBuffer(excelData, sheetName);

	return buffer;
}