import React from "react";
import styled from "styled-components";
import EditItem from "./EditItem";

const Wrapper = styled.div`
  margin: 0px auto;
`;

const ListTable = ({ column, row, entityName }) => {
  return (
    <Wrapper>
      <table className=" styled-table">
        <thead>
          <tr>
            {column.map((item, i) => (
              <th key={item} style={{ textAlign: "center" }}>
                {item}
              </th>
            ))}
            <th style={{ textAlign: "center" }}>Actions</th>
          </tr>
        </thead>
        <tbody className="list">
          {row[0] ? (
            row.map((item, i) => (
              <tr key={i}>
                <td style={{ width: "50px", textAlign: "center" }}>{i + 1}</td>
                {item.data.map((value, i) => (
                  <td key={i} style={{ width: "200px", textAlign: "center" }}>
                    {value instanceof Function ? value(item) : value}
                  </td>
                ))}
                <td style={{ textAlign: "center" }}>
                  <EditItem
                    link={
                      entityName === "user"
                        ? `/settings/${entityName}/list?mode=show&userId=${item._id}`
                        : `/settings/${entityName}/show/${item._id}`
                    }
                    width={100}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={column.length + 1}>There is no data</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default ListTable;
