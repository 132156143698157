import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");
const { axios: api } = Api;

export const getTranscriptionById = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`transcription/transcription-by-id/${_id}`);

    return { data: data.data };
  });

export const generateTextToSpeach = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `transcription/text-to-speach?${queryString.stringify(
        cleanObject(payload),
        {
          arrayFormat: "bracket",
        }
      )}`
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
