import { getLastTicketMessage } from "./getLastTicketMessage";
import { getLastTicketDocument } from "./getLastTicketDocument";

export const getLastTicketNotification = (ticket) => {
  const lastMessage = getLastTicketMessage(ticket);
  const lastDocument = getLastTicketDocument(ticket);

  if (
    lastDocument &&
    new Date(lastDocument.created_at).getTime() >
      new Date(lastMessage.created_at).getTime()
  )
    return lastDocument;

  return lastMessage;
};
