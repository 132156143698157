import moment from "moment";

export const formatDateRef = (dateRef, time, dayPart) => {
  const date =
    dayPart === "end"
      ? moment(time).endOf("day").toISOString()
      : moment(time).startOf("day").toISOString();

  return dateRef?.current?.value || date;
};
