import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "../../constants/headers";
import { getRaws } from "./helpers/getRaws";
import { TableWrapper } from "../../PaymentProductCPLReport.styled";

const ProductCPLTable = ({ cplData, values }) => {
  HEADERS[0] = values?.time_period?.label || HEADERS[0];

  return (
    <TableWrapper>
      <Table
        stickyHeader
        className="styled-table"
        raws={getRaws(cplData)}
        headers={HEADERS}
      />
    </TableWrapper>
  );
};

export default ProductCPLTable;
