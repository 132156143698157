export const validate = (values) => {
  if (!values.products?.length) {
    return "Select some products";
  }

  if (!values.time_period) {
    return "Select time period";
  }

  if (!values.period_from || !values.period_to) {
    return "Enter periods";
  } else if (values.period_from.value.isAfter(values.period_to.value)) {
    return "You are trying to select wrong period, check it";
  }

  return null;
};
