import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  max-width: 600px;
`;

export const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 600px;
`;

export const Title = styled.h4`
  margin: 0 16px 16px 0;
  color: ${Colors.darkBlue};
`;

export const DataWrapper = styled.div`
  border-left: 1px solid black;
  font-size: 14px;
  padding-left: 10px;
  overflow: hidden;
`;

export const SubTitle = styled.p`
  margin-bottom: 8px;
  padding-left: 10px;
  color: black;
`;

export const BoldSubTitle = styled.p`
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const DateContainer = styled.div`
  min-width: 70px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const DateWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  color: ${Colors.darkBlue};
`;

export const Point = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  border: 1px solid red;
  top: 5px;
  left: 73px;
  border-radius: 50%;
`;

export const SettingsIcon = styled.i`
  font-size: 18px;
  cursor: pointer;
  color: ${Colors.darkBlue};
`;
