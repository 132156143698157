import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TodoListContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 80, 114, 0.78);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const TodoListCloser = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const TodoItemsContainer = styled.div`
  height: calc(85vh - 60px);
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
`;

export const TodoListWrapper = styled.div`
  position: relative;
  height: 85vh;
  width: 85vw;
  z-index: 2;
  border-radius: 10px;
  background-color: ${Colors.lightGray};
`;

export const TodoItemsWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 18px;
  margin-top: 20px;
`;

export const AddTodoButtonWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  bottom: 0;
  height: 0;
  z-index: 3;
`;

export const AddTodoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(-110%);

  background-color: ${Colors.darkBlue};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.9;
    transform: scale(0.9) translateY(-120%);
  }
`;

export const CustomSkeletonWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 20px;
  left: 20px;
  overflow: hidden;
`;

export const TodoStatusWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const TodoListTabsWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  height: 60px;
  z-index: 2;
`;

export const TodoListTab = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid ${Colors.darkPurple};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${Colors.darkPurple};
  opacity: ${(props) => (props.active ? 0.8 : 1)};

  &:hover {
    color: ${Colors.darkPurple};
    background-color: ${Colors.lightBlue2};
  }

  &:active {
    opacity: 0.5;
  }
`;
