import userManager from "@/API/userManager"
import { CLAIM_STATUSES } from "@/common/constants/claims";
import { ROLE_VALUES } from "@/components/admin/settings/user/components/userForm/constants"

export const checkDisabled = (claimStatus) => {
	if (userManager.getUser().role === ROLE_VALUES.FINANCE_ANALYST) {
		return true;
	}

	if (![CLAIM_STATUSES.CORRECT_IN_PROGRESS.value, CLAIM_STATUSES.NONE.value].includes(claimStatus)) {
		return true;
	}

	return false;
}
