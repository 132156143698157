import { 
	CheckBoxInputsWrapper, 
	SectionText, 
	SectionWrapper, 
	Subtitle 
} from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React from 'react'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'
import { ClAIM_METHODS } from '@/common/constants/validClaims'
import { PaymentContentWrapper, PaymentMethodWrapper } from './ClaimPayment.styled'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { findMarketById } from '@/common/functions/findMarketById'
import BankTransfer from './components/bankTransfer/BankTransfer'
import Cash from './components/cash/Cash'
import { checkDisabled } from './helpers/checkDisabled'

const ClaimPayment = ({
  contact,
  claimMethod,
  setClaimMethod,
  claim,
  order,
  ibanRef,
  claimStatus,
	ibanCountry,
	setIbanCountry,
	accountsDataState,
	dispatchAccountsData,
}) => {
  const { commonData: { markets } } = useCommonDataContext();
  const disabled = checkDisabled(claimStatus);

  return (
    <SectionWrapper>
      <Subtitle>Claim method</Subtitle>
      <SectionText>
        Market: {findMarketById(order?.market, markets)?.name}
      </SectionText>
      <div>
        <CheckBoxInputsWrapper>
          <CheckBoxInput 
            disabled={disabled} 
            text="Bank transfer"
            checked={claimMethod === ClAIM_METHODS.BANK_TRANSFER.value}
            handleSelect={() => setClaimMethod(ClAIM_METHODS.BANK_TRANSFER.value)}
          />
          <CheckBoxInput 
            disabled={disabled} 
            text="Cash" 
            checked={claimMethod === ClAIM_METHODS.CASH.value}
            handleSelect={() => setClaimMethod(ClAIM_METHODS.CASH.value)}
          />
        </CheckBoxInputsWrapper>
        <PaymentMethodWrapper>
          {claimMethod === ClAIM_METHODS.CASH.value && (
						<Cash contact={contact} />
          )}
          {claimMethod === ClAIM_METHODS.BANK_TRANSFER.value && (
            <PaymentContentWrapper>
						<BankTransfer
							claim={claim}
							ibanRef={ibanRef}
							ibanCountry={ibanCountry}
							setIbanCountry={setIbanCountry}
							accountsDataState={accountsDataState}
							dispatchAccountsData={dispatchAccountsData}
							claimStatus={claimStatus}
							contact={contact}
							order={order}
						/>
            </PaymentContentWrapper>
          )}
        </PaymentMethodWrapper>
      </div>
    </SectionWrapper>
  )
}

export default ClaimPayment
