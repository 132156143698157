import { LANGUAGES } from "../constants/languages";
import CS from "@/images/languages-flags/CS.jpg";
import EN from "@/images/languages-flags/EN.jpg";
import SK from "@/images/languages-flags/SK.jpg";
import HU from "@/images/languages-flags/HU.jpg";
import RO from "@/images/languages-flags/RO.jpg";
import PL from "@/images/languages-flags/PL.jpg";
import LT from "@/images/languages-flags/LT.jpg";
import HR from "@/images/languages-flags/HR.jpg";
import LV from "@/images/languages-flags/LV.png";
import BG from "@/images/languages-flags/BG.png";

export const getFlagSource = (langShort) => {
  switch (langShort) {
    case LANGUAGES.RO:
      return RO;
    case LANGUAGES.PL:
      return PL;
    case LANGUAGES.CS:
      return CS;
    case LANGUAGES.HU:
      return HU;
    case LANGUAGES.SK:
      return SK;
    case LANGUAGES.LT:
      return LT;
    case LANGUAGES.HR:
      return HR;
    case LANGUAGES.LV:
      return LV;
    case LANGUAGES.BG:
      return BG;
    default:
      return EN;
  }
};
