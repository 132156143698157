import { Colors } from '@/common/colors/colors';
import styled from 'styled-components'

export const Label = styled.label`
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};  
  display: flex;
  align-items: center;
  background-color: ${({ selected, mainColor }) => selected ? mainColor : 'transparent'};
  width: fit-content;
  gap: 8px;
  padding: 6px;
  border-radius: 8px;
  border: ${({ selected, mainColor }) => selected ? `1px solid ${mainColor}` : `1px solid ${Colors.darkGray2}`};
`;

export const StyledInput = styled.input`
  width: 16px;
  height: 16px;
  accent-color: ${({ accentColor }) => accentColor};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};  
  opacity: ${({ checked }) => checked ? 1 : 0.7};
`;

export const StyledText = styled.span`
  font-weight: bold;
  user-select: none;
  font-size: 12px;
  color: ${({ selected, color }) => selected ? color : Colors.darkGray2};
`;
