import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const TableWrapper = styled.div`
  max-height: calc(100vh - 420px);
  overflow: scroll;
`;
