export const HOURS_OPTIONS = [
  {
    label: "8:30",
    value: 1,
  },
  {
    label: "9:00",
    value: 2,
  },
  {
    label: "9:30",
    value: 3,
  },
  {
    label: "10:00",
    value: 4,
  },
  {
    label: "10:30",
    value: 5,
  },
  {
    label: "11:00",
    value: 6,
  },
  {
    label: "11:30",
    value: 7,
  },
  {
    label: "12:00",
    value: 8,
  },
  {
    label: "12:30",
    value: 9,
  },
  {
    label: "13:00",
    value: 10,
  },
  {
    label: "13:30",
    value: 11,
  },
  {
    label: "14:00",
    value: 12,
  },
  {
    label: "14:30",

    value: 13,
  },
  {
    label: "15:00",

    value: 14,
  },
  {
    label: "15:30",
    value: 15,
  },
  {
    label: "16:00",
    value: 16,
  },

  {
    label: "16:30",
    value: 17,
  },
  {
    label: "17:00",
    value: 18,
  },
  {
    label: "17:30",
    value: 19,
  },
  {
    label: "18:00",
    value: 20,
  },
  {
    label: "18:30",
    value: 21,
  },

  {
    label: "19:00",
    value: 22,
  },
  {
    label: "19:30",
    value: 23,
  },
  {
    label: "20:00",
    value: 24,
  },

  {
    label: "20:30",
    value: 25,
  },
];
