import moment from "moment";
import { CONSULTANT_MANAGER_ROLES } from "../constants/consultantRoles"
import { findUserById } from "./findUserById";

export const checkIsManager = (userId, users, date = new Date()) => {
  const user = findUserById(userId, users);

  if (CONSULTANT_MANAGER_ROLES.includes(user.role)) {
    return true
  }

  return users.some(u => {
    return u.managers.some(m => {
      return m.manager === user._id && moment(m.active_from).isSameOrBefore(date) && moment(m.active_to).isSameOrAfter(date)  
    })
  });
}
