import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  addHoursByDateRange,
  saveConsultantHoursByManager,
} from "@/API/repositories/consultantHours";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import CalendarElement from "./components/CalendarElement";
import {
  CalendarElementEmptyWrapper,
  CalendarWrapper,
} from "./calendar.styles";
import TopDates from "./components/TopDates";
import AddWorkedHours from "./components/AddWorkedHours";
import NewAddWorkedHours from "./components/NewAddWorkedHours";
import Loading from "@/common/components/Loading";
import { createDateForBackend } from "@/common/functions/createDateForBackend";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import { updateDocumentTicket } from "@/API/repositories/tickets";
import { getFileById } from "@/API/repositories/storedDocument";
import { HOURS_POSIBLES } from "@/common/constants/CC";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";

const Calendar = ({ data, month, hanldeSearch, bonuses }) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [workingHoursOneSave, setWorkingHoursOneSave] = useState({});
  const [generatedArray, setGeneratedArray] = useState([]);
  const [addWorkingHoursNew, setAddWorkingHoursNew] = useState();
  const [addWorkedHours, setAddWorkedHours] = useState();
  const { commonData } = useCommonDataContext();
  const [freeDays, setFreeDays] = useState(
    findMarketByContractType(commonData?.markets, data._user)?.free_days || []
  );
  const firstDayOfTheMonth = moment(month.toDate()).startOf("month");
  const { addMessage, messages, removeMessage } = useMessageQueue();

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDeclineFile = async (ticketId) => {
    const payload = {};

    payload.ticket_status = "declined_pending";

    const response = await makeRequest(
      updateDocumentTicket.bind(null, ticketId, payload)
    );

    if (response?.data) {
      await hanldeSearch(null, true);
    }
  };

  const hanldeOnChange = (hour, sign, bonusId) => {
    if (HOURS_POSIBLES.includes(sign)) {
      workingHoursOneSave[hour] = {
        sign,
        bonusId,
      };
      setWorkingHoursOneSave(() => workingHoursOneSave);
      return;
    }
  };

  const handleRemoveConsultantHours = async (hour) => {
    setWorkingHoursOneSave((prev) => {
      delete prev[hour];
      return { ...prev };
    });
  }

  const handleSaveConsultantHours = async (e) => {
    e && e.preventDefault();

    const date = createDateForBackend(addWorkedHours);

    const response = await makeRequest(
      saveConsultantHoursByManager.bind(
        null,
        workingHoursOneSave,
        date,
        data._id
      )
    );
    if (response.data) {
      setWorkingHoursOneSave(() => null);
      setAddWorkedHours(() => null);
      await hanldeSearch(e, true);
    }
  };

  const generateArray = () => {
    const weekId = firstDayOfTheMonth.isoWeekday();

    const result = [...Array(weekId - 1).keys()].map(() => ({
      element: <CalendarElementEmptyWrapper />,
    }));
    result.push(
      ...[...Array(firstDayOfTheMonth.daysInMonth()).keys()]
        .map((number) => {
          const currentDay = firstDayOfTheMonth.clone().add(number, "days");

          const freeDay = freeDays.find((checkedFreeDay) =>
            checkedFreeDay.date.includes(currentDay.format("YYYY-MM-DD"))
          );
          const currentData = data.data?.find(
            (d) =>
              moment(d.for_day).format("YYYY-MM-DD") ===
              currentDay.format("YYYY-MM-DD")
          );
          return currentDay.isoWeekday() !== 7
            ? {
                element: (
                  <CalendarElement
                    number={number}
                    day={currentDay}
                    freeDay={freeDay}
                    data={currentData}
                    setWorkingHoursOneSave={setWorkingHoursOneSave}
                    handleDeclineFile={handleDeclineFile}
                    setAddWorkedHours={setAddWorkedHours}
                    handleDownloadFile={handleDownloadFile}
                    setAddWorkingHoursNew={setAddWorkingHoursNew}
                  />
                ),
              }
            : {};
        })
        .filter((e) => e.element)
    );
    return setGeneratedArray(() => result);
  };

  const handleNewSaveWorkedHours = async (payload) => {
    payload.consultant = data._id;
    payload.dailyHours = parseInt(
      (
        data._month_working_info?.contract_hours || data._user.contract_hours
      ).toString()
    );
    payload.dont_include = freeDays.map((f) => f.date);

    const response = await makeRequest(addHoursByDateRange.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    await hanldeSearch(null, true);
    setAddWorkingHoursNew(() => null);
  };

  useEffect(() => {
    generateArray();
  }, [data]);

  return (
    <>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(getFileById) && <Loading />}
      {addWorkedHours && (
        <AddWorkedHours
          handleRemoveConsultantHours={handleRemoveConsultantHours}
          bonuses={bonuses}
          workingHoursOneSave={workingHoursOneSave}
          hanldeOnChange={hanldeOnChange}
          handleSaveConsultantHours={handleSaveConsultantHours}
          setAddWorkedHours={setAddWorkedHours}
          addWorkedHours={addWorkedHours}
        />
      )}
      {addWorkingHoursNew && (
        <NewAddWorkedHours
          bonuses={bonuses}
          selectedMonth={month}
          handleNewSaveWorkedHours={handleNewSaveWorkedHours}
          setAddWorkingHoursNew={setAddWorkingHoursNew}
        />
      )}
      <CalendarWrapper>
        {data?.data && <TopDates />}
        {generatedArray.map((element) => element.element)}
      </CalendarWrapper>
    </>
  );
};

export default Calendar;
