export const getHeadersArray = (isFinal) => [
  [<th colSpan={5}>{isFinal ? "Final result" : "Pre result"}</th>],
  [
    <th>Price</th>,
    <th>For one</th>,
    <th>Confirmed orders</th>,
    <th> Delivered orders</th>,
    <th>Effective payment</th>,
  ],
];
