import styled from "styled-components";

export const Textarea = styled.textarea`
  resize: none;
  outline: none;
  margin-bottom: 10px;
  visibility: ${(props) => props.visibility};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
