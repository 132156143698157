import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import {
  getConsultantGrade,
  getConsultantGradeForNewConsultant,
} from "../../API/repositories/reports";
import translationManager from "../../API/translationManager";
import userManager from "../../API/userManager";
import Loading from "../../common/components/Loading";
import { useRequestsContext } from "../../common/hooks/requestHook";
import StatisticsGradeTable from "../../components/consultant/statistics/StatisticsGradeTable";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  padding: 20px;
  overflow-y: auto;
  max-height: 100vh;
  padding-bottom: 100px;
`;

const ConsultantStatistics = () => {
  const [grade, setGrade] = useState();
  const [gradeForNewConsultant, setGradeForNewConsultant] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleGetConsultantGrade = async () => {
    const payload = Object();
    payload.from = moment().subtract(35, "days").startOf("day").toDate();
    payload.to = moment().subtract(7, "days").endOf("day").toDate();
    payload.weights = true;
    payload.ids = [userManager.getUser().id];

    let response = await makeRequest(getConsultantGrade.bind(null, payload));

    if (response.data) {
      setGrade(() => response.data[0]);
    }

    payload.from = moment().subtract(28, "days").startOf("day").toDate();
    payload.to = moment().endOf("day").toDate();

    response = await makeRequest(
      getConsultantGradeForNewConsultant.bind(null, payload)
    );

    if (response.data) {
      setGradeForNewConsultant(() => response.data[0]);
    }
  };

  const [translation, setTranslation] = useState({
    noData: "There is no data",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  useLayoutEffect(() => {
    handleGetConsultantGrade();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getConsultantGrade) && <Loading />}
      {grade && <StatisticsGradeTable grade={grade} />}
      {gradeForNewConsultant && (
        <StatisticsGradeTable grade={gradeForNewConsultant} newConsultant />
      )}
      {!grade && !gradeForNewConsultant && <h4>{translation.noData}</h4>}
    </Wrapper>
  );
};

export default ConsultantStatistics;
