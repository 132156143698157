import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const Label = styled.p`
  color: ${Colors.darkBlue};
  padding: 8px 0;
  font-weight: bold;
`;
