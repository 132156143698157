import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { CenteredTable, ContentWrapper } from "./Languages.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import LanguageForm from "./components/languageForm/LanguageForm";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getLanguages } from "@/API/repositories/language";
import Loading from "@/common/components/Loading";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import LanguageTable from "./components/languageTable/LanguageTable";

const Languages = () => {
  const [isLanguageFormOpen, setIsLanguageFormOpen] = useState();
  const [languages, setLanguages] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, messages, removeMessage } = useMessageQueue();

  const loadData = async () => {
    const response = await makeRequest(getLanguages);

    if (response.data) {
      setLanguages(() => response.data);
    }
  };

  useEffect(() => {
    if (!isLanguageFormOpen) {
      setSelectedLanguage(() => null);
    }
  }, [isLanguageFormOpen]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getLanguages) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <ContentWrapper>
        <CustomButtonAdd
          onClick={() => setIsLanguageFormOpen(() => true)}
          style={{ height: "fit-content" }}
          defaultText="Add language"
        />
        <CenteredTable>
          {languages && (
            <LanguageTable
              setSelectedLanguage={setSelectedLanguage}
              setIsLanguageFormOpen={setIsLanguageFormOpen}
              languages={languages}
            />
          )}
        </CenteredTable>
      </ContentWrapper>
      {isLanguageFormOpen && (
        <LanguageForm
          selectedLanguage={selectedLanguage}
          loadData={loadData}
          addMessage={addMessage}
          setShow={setIsLanguageFormOpen}
        />
      )}
    </PageWrapper>
  );
};

export default Languages;
