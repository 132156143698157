import moment from "moment";
import React from "react";

const OrderWithDeliveryTable = ({
  displayedContact,
  handleShowSearchedOrders,
  getClaimMAX,
  getColor,
  setClaim,
  setResigned,
  getTrackingLink,
  setEditData,
  setEdit,
  setInvoice,
}) => {
  const ordersWithDelivery = displayedContact.orders.filter(
    (order) => !order.payment?.length
  );

  return (
    !!ordersWithDelivery?.length && (
      <table className="styled-table">
        <thead>
          <tr style={{ width: "fit-content" }}>
            <th>No. (shipping)</th>
            <th>Status</th>
            <th>Shipping status</th>
            <th>Items</th>
            <th>LMS Sent</th>
            <th>LMS Delivered NOP</th>
            <th>LMS Delivered</th>
            <th>MAX claim date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ordersWithDelivery.map((data, i) => (
            <tr key={i}>
              <td
                style={{
                  minWidth: "40px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {data?.shipping?.order_number}
              </td>
              <td>{data.status}</td>
              <td style={{ minWidth: "140px", textAlign: "center" }}>
                {data.shipping?.status ? data.shipping.status : "---------"}
              </td>
              <td style={{ minWidth: "100px", textAlign: "center" }}>
                {data.order_items.length > 0
                  ? data.order_items
                      .map((data) => " " + data.product_variant)
                      .toString()
                  : "---------"}
              </td>
              <td style={{ minWidth: "100px", textAlign: "center" }}>
                {data.shipping?.status_lms_sent_at
                  ? moment(data.shipping?.status_lms_sent_at).format(
                      "YYYY-MM-DD"
                    )
                  : "---------"}
              </td>
              <td style={{ minWidth: "170px", textAlign: "center" }}>
                {data.shipping?.status_delivered_no_payment_at
                  ? moment(
                      data.shipping?.status_delivered_no_payment_at
                    ).format("YYYY-MM-DD")
                  : "---------"}
              </td>
              <td style={{ minWidth: "140px", textAlign: "center" }}>
                {data.shipping?.status_delivered_at
                  ? moment(data.shipping?.status_delivered_at).format(
                      "YYYY-MM-DD"
                    )
                  : "---------"}
              </td>
              <td
                style={{
                  minWidth: "150px",
                  textAlign: "center",
                  color: getColor(
                    data.shipping?.status_delivered_at ||
                      data.shipping?.status_delivered_no_payment_at
                  ),
                }}
              >
                {data.shipping?.status_delivered_at ||
                data.shipping?.status_delivered_no_payment_at
                  ? getClaimMAX(
                      data.shipping?.status_delivered_at ||
                        data.shipping?.status_delivered_no_payment_at
                    )
                  : "---------"}
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                    margin: "10px",
                    minWidth: "300px",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    style={{ fontSize: "12px" }}
                    onClick={(e) => handleShowSearchedOrders(e, data._id)}
                  >
                    <b>Show Order</b>
                  </button>
                  <button
                    disabled={
                      (!data.shipping?.status_delivered_at &&
                        !data.shipping?.status_delivered_no_payment_at)
                    }
                    className="btn btn-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={(e) => setClaim(data)}
                  >
                    <b>Claim</b>
                  </button>
                  <button
                    disabled={data.status === "resigned"}
                    className="btn btn-success"
                    style={{ fontSize: "12px" }}
                    onClick={(e) => {
                      setResigned(data);
                    }}
                  >
                    <b>Resign</b>
                  </button>
                  <button
                    disabled={!data.shipping?.waybill_number}
                    className="btn btn-dark"
                    style={{ fontSize: "12px" }}
                    onClick={(e) =>
                      window.open(
                        getTrackingLink(
                          displayedContact.country,
                          data.shipping
                        ),
                        "_blank"
                      )
                    }
                  >
                    <b>Check Post</b>
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "12px" }}
                    disabled={!data.shipping}
                    onClick={(e) => {
                      setEditData(data);
                      setEdit(true);
                    }}
                  >
                    <b>Set Delivered</b>
                  </button>
                  <button
                    className={`btn btn-warning`}
                    style={{ fontSize: "12px" }}
                    onClick={(e) => {
                      setInvoice(data);
                    }}
                    disabled={data._invoices.length > 0}
                  >
                    <b>Invoice</b>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  );
};

export default OrderWithDeliveryTable;
