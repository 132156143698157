import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { getConsultantsDailyReport } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import SelectInput from "@/common/components/SelectInput";
import userManager from "@/API/userManager";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { WorkingByTake } from "@/common/components/workingByTake/WorkingByTake";
import NoResponseShiftTable from "@/common/components/noResponseShiftTable/NoResponseShiftTable";
import DownloadTable from "@/common/components/downloadTable/DownloadTable";
import { Wrapper } from "./DailyReportManager.styled";

const DailyReportManager = () => {
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [data, setData] = useState();

  const { commonData } = useCommonDataContext();
  const { users } = commonData;
  
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const dateFromRef = useRef();
  const dateToref = useRef();

  const currentUserId = userManager.getUser().id;

  const consultants = useMemo(() => {
    return handleMapToOptions(users?.filter(user => user.managers?.some(m => m.manager === currentUserId)) || [], 'username', '_id');
  }, [users, currentUserId]);

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedConsultant.length) {
      addMessage("Select consultants", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.consultants = selectedConsultant.map(
      (consultant) => consultant.value
    );

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getConsultantsDailyReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (
      response.data &&
      response.data.length &&
      !response.data.some((element) => {
        return element._call_history.length || element._inbound_history.length;
      })
    ) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(getConsultantsDailyReport) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <SearchBarCenter>
        <div>
          <Input
            name="Download from"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="date"
            inputWidth={140}
          />
          <Input
            name="Download to"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateToref}
            type="date"
            inputWidth={140}
          />
        </div>
        <SelectInput
          name="Consultants"
          options={consultants}
          color={Colors.darkBlue}
          width={100}
          setSelected={setSelectedConsultant}
          selected={selectedConsultant}
          multiple={true}
        />
        <button className="btn btn-warning" onClick={(e) => hanldeSearch(e)}>
          Search
        </button>
        <button
          className="btn btn-warning"
          onClick={() => setSelectedConsultant(consultants)}
        >
          Add All
        </button>
      </SearchBarCenter>
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._inbound_history)}
          consultants={users}
          name={"Inbound"}
        />
      )}
      {data && data.length > 0 && (
        <NoResponseShiftTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
      {data && data.length > 0 && (
        <WorkingByTake
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
    </Wrapper>
  );
};

export default DailyReportManager;
