import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  gap: 40px;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end
`;

export const CustomRaw = styled.tr`
  color: ${Colors.newLightColor};
  font-weight: bold;
`;

export const TableWrapper = styled.div`
  max-height: calc(100vh - 250px);
  overflow: scroll;
`;

export const InfoIconWrapper = styled.div`
  padding: 10px;
  border-radius: 50%;
  background-color: ${Colors.darkBlue};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      display: flex;
      width: 400px;
      z-index: 2;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 12px;
      top: 30px;
      left: 10px;
      border-radius: 10px;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;
