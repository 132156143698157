import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../common/colors/colors";
import { getGamificationPointsForUserByQuery } from "../../API/repositories/gamificationPoints";
import { useRequestsContext } from "../../common/hooks/requestHook";
import Loading from "../../common/components/Loading";
import anime from "animejs";
import userManager from "../../API/userManager";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  overflow-y: scroll;
  max-height: 95vh;
  gap: 50px;
`;

const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

const ResultElement = styled.div`
  padding: 15px 20px;
  width: 600px;
  border: 2px solid ${(props) => (props.isCurrent ? Colors.red : props.color)};
  border-radius: 10px;
  background-color: ${(props) => props.color};
  display: flex;
  gap: 20px;
  justify-content: space-between;
  cursor: ${(props) => (props.isCurrent ? "pointer" : "")};
`;

const ResultCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ResultElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const UsernameWrapper = styled.h3`
  color: white;
  font-style: italic;
  font-size: 22px;
  width: 220px;
`;

const NumberWrapper = styled.h3`
  color: white;
  font-size: 22px;
  align-self: flex-end;
`;

const getColorByPosition = (postion) => {
  if (postion === 1) {
    return Colors.green;
  }
  if (postion === 2) {
    return Colors.yellow;
  }
  if (postion === 3) {
    return Colors.violet;
  }

  return Colors.darkBlue;
};

const CurrentUserWraper = styled.h3`
  font-size: 30px;
  color: red;
  position: absolute;
  right: -40px;
  top: -30px;
  transform: rotate(45deg);
`;

const PointTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 60px;
`;

const PointWrapper = styled.div`
  width: 500px;
  border-left: 1px solid grey;
  padding-left: 10px;
  display: flex;
  gap: 10px;
`;

const ConsultantGamificationPoints = ({ points }) => {
  return (
    <PointTableWrapper>
      {points?.map((d) => (
        <PointWrapper>
          <i style={{ fontSize: "12px" }}>
            {moment(d.created_at).format("DD/MM/YYYY")}
          </i>
          <i>For: {d.points_for_type}</i>
          <b>Grade: {d.grade}</b>
          <b>Points: {d.point_number}</b>
        </PointWrapper>
      ))}
    </PointTableWrapper>
  );
};

const ConsultantGamification = () => {
  const [data, setData] = useState();
  const [showUserStats, setShowUserStats] = useState();
  const today = moment();
  const animationRef = useRef();

  useEffect(() => {
    animationRef.current = anime({
      targets: ".el",
      translateY: 200,
      backgroundColor: ["#FFA07A"],
      zIndex: [1000, 1],
      size: [10, 1],
      direction: "reverse",
      easing: "easeOutQuad",
    });

    animationRef.current.play();
  }, []);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadGamificationPoints = async () => {
    const payload = {};

    payload.from_last = today
      .clone()
      .subtract("1", "months")
      .startOf("month")
      .toISOString();
    payload.to_last = today
      .clone()
      .subtract("1", "months")
      .endOf("month")
      .toISOString();
    payload.from_current = today.clone().startOf("month").toISOString();
    payload.to_current = today.clone().endOf("month").toISOString();

    const response = await makeRequest(
      getGamificationPointsForUserByQuery.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadGamificationPoints();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getGamificationPointsForUserByQuery) && <Loading />}
      <table className="styled-table">
        <thead>
          <tr>
            <th colSpan={3}>
              Last Month Result (
              {today
                .clone()
                .subtract("1", "months")
                .startOf("month")
                .format("MMMM DD")}
              {" --> "}
              {today
                .clone()
                .subtract("1", "months")
                .endOf("month")
                .format("MMMM DD")}
              )
            </th>
          </tr>
          <tr>
            <th>Position</th>
            <th>Score</th>
            <th>Bonus %</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data && data?.last_month ? (
            <tr>
              <td>{data?.last_month.position}.</td>
              <td>{data?.last_month.score}</td>
              <td>{data?.last_month.bonus}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={3}>Thre is no results yet!</td>
            </tr>
          )}
        </tbody>
      </table>
      {data && data.current_month && data.current_month[0] && (
        <ResultWrapper>
          {data.current_month.map((element) => (
            <>
              <ResultElementWrapper
                onClick={() =>
                  userManager.getUser().username === element.username &&
                  setShowUserStats((prev) => !prev)
                }
              >
                <ResultCircle color={getColorByPosition(element.position)}>
                  {element.position}.
                </ResultCircle>
                <ResultElement
                  key={element.username}
                  color={getColorByPosition(element.position)}
                  isCurrent={
                    userManager.getUser().username === element.username
                  }
                  className={`${
                    userManager.getUser().username === element.username
                      ? "el"
                      : ""
                  }`}
                >
                  {userManager.getUser().username === element.username && (
                    <CurrentUserWraper>You!</CurrentUserWraper>
                  )}
                  <UsernameWrapper>{element.username}</UsernameWrapper>
                  <NumberWrapper>
                    {Math.round(element.score * 100) / 100} points
                  </NumberWrapper>
                  <NumberWrapper>{element.bonus} %</NumberWrapper>
                </ResultElement>
              </ResultElementWrapper>
              {showUserStats &&
                userManager.getUser().username === element.username && (
                  <ConsultantGamificationPoints points={element.data} />
                )}
            </>
          ))}
        </ResultWrapper>
      )}
    </Wrapper>
  );
};

export default ConsultantGamification;
