import { Colors } from "@/common/colors/colors";

export const getPointColor = (date) => {
  const now = new Date();
  const lastPing = new Date(date);
  const diffMinutes = (now.getTime() - lastPing.getTime()) / 1000 / 60;

  if (diffMinutes < 10) {
    return "green";
  }
  if (diffMinutes < 20) {
    return Colors.yellow;
  }
  return "red";
};
