import React from "react";
import CustomButtonAdd from "../../../../../common/components/buttons/CustomButtonAdd";

const Buttons = ({ setPromotion, setAddRegion, onSave }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        gap: "10px",
        padding: "30px",
        width: "100%",
      }}
    >
      <div>
        <button
          className="btn btn-warning"
          type="submit"
          onClick={() => onSave()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Buttons;
