import React from "react";
import styled from "styled-components";
import { Colors } from "../../colors/colors";

const Button = styled.button`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  white-space: nowrap;

  font-size: 14px;
  font-weight: bold;
  padding: 5px;

  color: ${Colors.red};
  border: 1px solid ${Colors.red};
  background-color: transparent;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    color: white;
    border: 1px solid ${Colors.red};
    background-color: ${Colors.red};
    transform: scale(1.1);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid gray;
    background-color: ${Colors.lightGray};
    color: #666666;
    cursor: not-allowed;
    transform: scale(1);
  }
`;

const CustomButtonError = ({
  type = "button",
  onClick,
  defaultText,
  disabled = false,
}) => {
  return (
    <Button type={type} onClick={(e) => onClick(e)} disabled={disabled}>
      {defaultText}
    </Button>
  );
};

export default CustomButtonError;
