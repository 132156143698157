import styled from "styled-components";

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Icon = styled.i`
  cursor: pointer;
`;
