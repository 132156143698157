import React from "react";
import { IconWrapper, Title } from "../listeningScores.styles";
import { round } from "@/common/functions/round";
import PopUp from "@/common/components/PopUp";
import moment from "moment";
import { GOOD_CALLING_THRESHOLD } from "@/common/constants/companies";

const Scores = ({
  showEdit,
  setShowEdit,
  setShowTicketTalkingImprovement,
  setShowEditCurrent,
  setEditScoring = () => {},
}) => {
  const getTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}m ${seconds}s`;
  };

  return (
    <PopUp setShow={setShowEdit}>
      <Title>Scores: </Title>
      <table className="styled-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Created at</th>
            <th>Call status</th>
            <th>For consultant</th>
            <th>Score</th>
            <th>Number</th>
            <th>Direction</th>
            <th>Duration</th>
            <th>Talking time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="queue">
          {showEdit
            .sort((a, b) =>
              a.call_history?.status?.localeCompare(b.call_history?.status)
            )
            .sort((a, b) =>
              a.edit?._user.username.username(b.edit._user?.username)
            )
            .map((edit, i) => (
              <tr key={i}>
                <td>{i + 1}.</td>
                <td>{moment(edit.created_at).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>{edit?.call_history?.status}</td>
                <td>{edit._user.username}</td>
                <td
                  style={{
                    color:
                      edit.score < GOOD_CALLING_THRESHOLD ? "red" : "green",
                  }}
                >
                  {round(edit.score)}%
                </td>
                <td>
                  {edit._calling_information.type === "outgoing"
                    ? edit._calling_information.to
                    : edit._calling_information.from}
                </td>
                <td>{edit._calling_information.type}</td>
                <td>{getTime(edit._calling_information.duration)} </td>
                <td>{getTime(edit._calling_information.talking_time)}</td>
                <td>
                  <IconWrapper>
                    <i
                      className="fa fa-eye animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditScoring(() => edit.scores);
                        setShowEditCurrent(edit);
                      }}
                    />
                    <i
                      className="fa fa-plus animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowTicketTalkingImprovement(edit)}
                    />
                  </IconWrapper>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </PopUp>
  );
};

export default Scores;
