import React from "react";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const CCPaymentReportMainTable = ({ mainReportData, selectedConsultant }) => {
  return (
    <Table
      headersArray={HEADERS_ARRAY}
      raws={getRaws(mainReportData, selectedConsultant)}
      className="styled-table"
    />
  );
};

export default CCPaymentReportMainTable;
