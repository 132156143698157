import { formatMonthName } from "@/common/functions/dateFormater";
import { getClaimReportExcel } from "./getClaimReportExcel";
import { groupOrdersByMarket } from "./groupOrdersByMarket";
import { getFailedTransfersReportExcel } from "./getFailedTransfersReportExcel";
import { saveAs } from "@/common/functions/saveAs";

const JSZip = require("jszip");
let zip = new JSZip();

export const generateZip = async (data, month) => {
	const grouppedByMarket = groupOrdersByMarket(data);

	for (const [marketName, claim] of Object.entries(grouppedByMarket)) {
		const buffer = await getClaimReportExcel(marketName, claim, month);
		zip.file(`EOU_${formatMonthName(month)}_zwroty_${marketName.toLowerCase()}.xlsx`, buffer);
	}


	const claimsWithSomeFailedTransfer = [...data]
		.filter(item => item.transfers.some(t => t.failed_at));

	const failedTransfersFileName = `EOU_${formatMonthName(month)}_nieudane_transakcje.xlsx`;

	if (claimsWithSomeFailedTransfer.length) {
		const faileTransfersBuffer = await getFailedTransfersReportExcel(claimsWithSomeFailedTransfer, month);
		zip.file(failedTransfersFileName, faileTransfersBuffer);
	}

	zip.generateAsync({ type: "blob" }).then((content) => {
		saveAs(content, `EOU_${formatMonthName(month)}_zwroty.zip`);
	});

	zip = new JSZip();
};
