export const HEADERS_ARRAY = [
  [<th colSpan={6}>Manager payment summarize</th>],
  [
    <th>Hours</th>,
    <th>Hours payment</th>,
    <th>Effective payment</th>,
    <th>Payment</th>,
    <th>Equalization</th>,
  ],
];
