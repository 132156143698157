import translationManager from "@/API/translationManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React, { useEffect, useState } from "react";
import Table from "@/common/components/Table";
import { getHeaders } from "./helpers/getHeaders";
import { getRaws } from "./helpers/getRaws";

const ManagerEffectivePaymentTable = ({ from, to, team }) => {
  const [translation, setTranslation] = useState({
    text0: "Consultant",
    text1: "Price",
    text2: "Salary for one",
    text3: "Confirmed orders",
    text4: "Delivered orders",
    text5: "Effective salary",
    text6: "Total",
  });

  const {
    commonData: { users },
  } = useCommonDataContext();

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <div>
      <Table
        className="styled-table"
        raws={getRaws(team, users)}
        headersArray={getHeaders(from, to, translation)}
      />
    </div>
  );
};

export default ManagerEffectivePaymentTable;
