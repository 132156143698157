import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import {
  createConsultantDocuments,
  getAllConsultantDocuments,
  removeConsultantDraft,
  updateConsultantDocuments,
} from "../../../../API/repositories/consultantDocuments";
import PopUp from "../../../../common/components/PopUp";
import SelectInput from "../../../../common/components/SelectInput";
import { Colors } from "../../../../common/colors/colors";
import Input from "../../../../common/components/Input";
import moment from "moment";
import Loading from "../../../../common/components/Loading";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";
import {
  deleteFileById,
  getFileById,
  savePdfsavePdfForConsultantDocumentsToAws,
} from "@/API/repositories/storedDocument";
import Icon from "@/common/components/Icon";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  gap: 50px;
`;

export const MODE = {
  EDIT: "Edit",
  CREATE: "Create",
  ADD: "Add",
};

const DocumentsList = () => {
  const [mode, setMode] = useState(null);
  const [edit, setEdit] = useState(false);

  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);

  const fileRef = useRef();
  const nameRef = useRef();
  const textareaRef = useRef();

  const { addMessage, messages } = useMessageQueue();

  const [data, setData] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { options } = useCommonDataContext();
  const { queuesOptions, usersOptions } = options;

  const handleLoadData = async (e) => {
    e && e.preventDefault();

    const dataResponse = await makeRequest(getAllConsultantDocuments);

    if (dataResponse.data) {
      setData(() => dataResponse.data);

      if (!dataResponse.data[0]) {
        addMessage("There is no items", "error");
      }

      if (edit) {
        setEdit(() => dataResponse.data.find((d) => d._id === edit._id));
      }
    }
  };

  const handleSaveFile = async (e) => {
    e.preventDefault();

    if (!fileRef.current?.files[0]) return;

    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);
    formData.append("description", textareaRef.current.value);
    formData.append("_id", edit._id);

    const response = await makeRequest(
      savePdfsavePdfForConsultantDocumentsToAws.bind(null, formData)
    );

    if (response.data) {
      await handleLoadData();
      addMessage("File saved", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const payload = {};
    payload.consultants = selectedConsultants.map((v) => v.value);
    payload.name = nameRef.current.value || edit.name;
    payload._id = edit._id;

    const response = await makeRequest(
      updateConsultantDocuments.bind(null, payload)
    );

    if (response.data) {
      await handleLoadData();
      addMessage("Worspace updated", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleDeleteDraft = async (id) => {
    const response = await makeRequest(removeConsultantDraft.bind(null, id));

    if (!response?.data) {
      return addMessage("Error", "error");
    }

    await handleLoadData();
    addMessage("Draft deleted", "success"); 
  };

  const handleCreateConsultantWorkspace = async (e) => {
    e.preventDefault();

    if (selectedConsultants.length === 0 || !nameRef.current) {
      addMessage("Pick consultant || add name", "error");
      return;
    }

    const payload = {};
    payload.consultants = selectedConsultants.map((v) => v.value);
    payload.name = nameRef.current.value;

    const response = await makeRequest(
      createConsultantDocuments.bind(null, payload)
    );

    if (response.data) {
      await handleLoadData();
      setMode(() => null);
      addMessage("Worspace created", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleDeleteDownloadFile = async (storedDataId) => {
    const response = await makeRequest(deleteFileById.bind(null, storedDataId));

    if (response.data) {
      await handleLoadData();
      addMessage("Deleted", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleSelectQueues = (queue) => {
    let selectedUsersIds = [];

    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((c) => selectedUsersIds?.includes(c.value))
    );

    setSelectedQueues(() => queue);
  };
  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <Wrapper>
        <div style={{ width: "100%", textAlign: "left" }}>
          <button
            className="btn btn-warning"
            onClick={() => {
              setMode(() => MODE.CREATE);
            }}
          >
            Create
          </button>
        </div>
        {data?.length > 0 && (
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Documents</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((element, index) => (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>{element.name || "----"}</td>
                    <td>
                      <div
                        style={{
                          color: Colors.darkBlue,
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {element._stored_docs?.map((sd) => (
                          <i
                            className="fa fa-file animation-scale"
                            style={{ cursor: "pointer" }}
                          />
                        ))}
                      </div>
                      {!element._stored_docs[0] && "--------"}
                    </td>
                    <td>
                      <div style={{ display: "flex", gap:"8px", justifyContent: "center", alignItems: "center" }}>
                        <i
                          className="fa fa-edit animation-scale"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEdit(element);
                            setMode(MODE.EDIT);
                            setSelectedQueues(() =>
                              queuesOptions.filter((q) =>
                                q.value.consultants.some((c) =>
                                  element?.consultants?.includes(c)
                                )
                              )
                            );
                            setSelectedConsultants(() =>
                              usersOptions.filter((c) =>
                                element?.consultants?.includes(c.value)
                              )
                            );
                          }}
                        />
                        <Icon onClick={() => handleDeleteDraft(element._id)} name="fa fa-trash" color={Colors.red} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {mode && mode === MODE.CREATE && (
          <PopUp setShow={setMode}>
            <div style={{ width: "1000px" }}>
              <h4 style={{ marginBottom: "40px", color: Colors.darkBlue }}>
                Create DOCS workspace
              </h4>
              <Input
                inputRef={nameRef}
                width={80}
                selectWidth={300}
                name="Name"
                multiple={true}
                color={Colors.darkBlue}
                required={true}
              />
              <div style={{ display: "flex" }}>
                <div>
                  <SelectInput
                    width={100}
                    selectWidth={700}
                    name="Consultants"
                    options={usersOptions}
                    selected={selectedConsultants}
                    setSelected={setSelectedConsultants}
                    multiple={true}
                    color={Colors.darkBlue}
                    labelUp={true}
                  />
                </div>
                <SelectInput
                  width={80}
                  selectWidth={200}
                  name="Queues"
                  options={queuesOptions}
                  selected={selectedQueues}
                  setSelected={handleSelectQueues}
                  multiple={true}
                  color={Colors.darkBlue}
                  labelUp={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "100px 0 -20px 0",
                }}
              >
                <button
                  className="btn btn-warning"
                  onClick={(e) => handleCreateConsultantWorkspace(e)}
                >
                  Create
                </button>
              </div>
            </div>
          </PopUp>
        )}
        {mode && mode === MODE.EDIT && (
          <PopUp setShow={setMode}>
            <h4 style={{ marginBottom: "40px", color: Colors.darkBlue }}>
              Edit DOCS in workspace
            </h4>
            <form onSubmit={(e) => handleEdit(e)}>
              <Input
                inputRef={nameRef}
                width={80}
                selectWidth={300}
                name="Name"
                value={edit.name}
                multiple={true}
                color={Colors.darkBlue}
                required={true}
              />
              <div style={{ display: "flex" }}>
                <div>
                  <SelectInput
                    width={100}
                    selectWidth={700}
                    name="Consultants"
                    options={usersOptions}
                    selected={selectedConsultants}
                    setSelected={setSelectedConsultants}
                    multiple={true}
                    color={Colors.darkBlue}
                    labelUp={true}
                  />
                </div>
                <SelectInput
                  width={80}
                  selectWidth={200}
                  name="Queues"
                  options={queuesOptions}
                  selected={selectedQueues}
                  setSelected={handleSelectQueues}
                  multiple={true}
                  color={Colors.darkBlue}
                  labelUp={true}
                />
              </div>
              <button className="btn btn-warning" style={{ float: "right" }}>
                Save
              </button>
            </form>
            <form>
              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                <textarea
                  ref={textareaRef}
                  style={{
                    width: "350px",
                    resize: "none",
                    fontSize: "16px",
                  }}
                  required
                  placeholder="type description... in consultant languge"
                />
                <input
                  ref={fileRef}
                  type="file"
                  accept="application/pdf"
                  style={{ width: "240px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "20px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={(e) => handleSaveFile(e)}
                >
                  Add New
                </button>
              </div>
            </form>
            {edit._stored_docs[0] && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Created at</th>
                      <th>Description</th>
                      <th>File</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {edit._stored_docs.map((sd, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {moment(sd.created_at).format("YYYY/MM/DD HH:mm")}
                        </td>
                        <td>{sd.description}</td>
                        <td>
                          <i
                            className="fa fa-file animation-scale"
                            style={{
                              color: Colors.darkBlue,
                              cursor: "pointer",
                            }}
                            onClick={() => handleDownloadFile(sd._id)}
                          />
                        </td>
                        <td>
                          <i
                            className="fa fa-trash animation-scale"
                            style={{ color: Colors.red, cursor: "pointer" }}
                            onClick={() => handleDeleteDownloadFile(sd._id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </PopUp>
        )}
      </Wrapper>
      {hasUnfilledRequest(
        getFileById,
        createConsultantDocuments,
        getAllConsultantDocuments,
        savePdfsavePdfForConsultantDocumentsToAws,
        removeConsultantDraft,
      ) && <Loading />}
      <MessageQueue messages={messages} />
    </>
  );
};

export default DocumentsList;
