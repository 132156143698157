export const WORKING_OPTIONS = [
  {
    label: "Attendance at work",
    value: "1",
  },
  {
    label: "holiday leave ",
    value: "h",
  },
  {
    label: "sick leave",
    value: "s",
  },
  {
    label: "leave on request",
    value: "r",
  },
  {
    label: "special leave",
    value: "sl",
  },
  {
    label: "Break during the day",
    value: "/",
  },
  {
    label: "Excused unpaid leave",
    value: "u",
  },
  {
    label: "Absence without excuse ",
    value: "n",
  },
  {
    label: "Managerial time",
    value: "m",
  },
];
