import React, { useContext } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import PopUp from "../../../common/components/PopUp";
import { CallCRMApiContext } from "../../../common/contexts/callingApiContext";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";

const Wrapper = styled.div`
  position: absolute;
  background: rgba(2, 80, 114, 0.78);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const Card = styled.div`
  background: white;
  width: 500px;
  height: fit-content;
  color: black;
  border-radius: 15px;
  padding: 20px;
`;

const CallingTo = styled.h2`
  color: ${Colors.darkBlue};
  display: flex;
`;

const ElementWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const LoadingAnimation = styled.div`
  margin-top: 2px;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px 1px;
    background: ${Colors.darkBlue};
    animation: wave 1.2s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
`;

const TextInfoWrapper = styled.h5`
  color: ${Colors.darkBlue};
  margin-top: 20px;
  margin-left: 10px;
`;

const ReCalling = ({ close }) => {
  const { callingClientOnceAgain } = useContext(CallCRMApiContextVonage);

  return (
    <PopUp setShow={close}>
      <Card>
        <CallingTo>
          Recalling: {callingClientOnceAgain?.full_name}
          <LoadingAnimation>
            <span></span>
            <span></span>
            <span></span>
          </LoadingAnimation>
        </CallingTo>
      </Card>
    </PopUp>
  );
};

export default ReCalling;
