import React from "react";
import { Colors } from "../colors/colors";

const CreateButton = ({ onClick, name, type = "button" }) => {
  return (
    <button
      className="btn btn-warning animation-scale"
      onClick={onClick}
      type={type}
      style={{ background: Colors.yellow, width: "fit-content" }}
    >
      Create {name}
    </button>
  );
};
export default CreateButton;
