export const HEADERS = [
  "Market",
  "Product",
  "UTM",
  "Downloaded",
  "Confirmed",
  "WPPK Confirmed",
  "% Confirmed",
  "Delivered",
  "WPPK Delivered",
  "% Delivered",
];
