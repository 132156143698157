import { calcTotalSalary } from "../../../helpers/calcTotalSalary";
import { calcHoursPayment } from "./calcHoursPayment";
import { calcBonus } from "./calcBonus";

export const calcEqualization = (data) => {
  if (
    data.data.length === 1 ||
    !data.data.find((e) => e.is_final === true)?.data
  ) {
    return 0;
  }

  const secondTotalCal = calcBonus(
    calcTotalSalary(
      data.data.find((e) => e.is_final === true).data,
      data._month_working_info
    ),
    calcHoursPayment(data)
  );

  const firstTotalCal = calcBonus(
    calcTotalSalary(
      data.data.find((e) => e.is_final === false).data,
      data._month_working_info
    ),
    calcHoursPayment(data)
  );

  return secondTotalCal - firstTotalCal;
};
