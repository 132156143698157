import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
  min-height: 50vh;
`;

export const SearchBar = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 15px;
  max-height: 60px;
`;
