import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const createCallCenterPosition = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/call-center-position/create`, payload);

    return { data: data.data };
  });

export const updateCallCenterPosition = (payload, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/call-center-position/update/${id}`, payload);

    return { data: data.data };
  });

export const getCallCenterPositions = () =>
  errorHandler(async () => {
    const data = await api.get(`/call-center-position/get`);

    return { data: data.data };
  });
