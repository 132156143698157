import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRequestsContext } from "../../common/hooks/requestHook";
import {
  createMailTemplate,
  getMailTemplates,
  updateMailTemplate,
} from "../../API/repositories/mailTemplate";
import {
  ButtonCreateWrapper,
  ButtonSaveWrapper,
  Title,
  Wrapper,
} from "../../common/styles/Mails";
import PopUp from "../../common/components/PopUp";
import Loading from "../../common/components/Loading";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import Input from "../../common/components/Input";
import { Colors } from "../../common/colors/colors";
import {
  createMailTest,
  getMailTests,
  updateMailTest,
} from "../../API/repositories/mailTest";
import SelectInput from "../../common/components/SelectInput";

const MailTemplateElement = ({
  element,
  setElement,
  addMessage,
  reload,
  templates,
}) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const [selectedTemplates, setSelectedTemplates] = useState(
    templates.filter((template) =>
      element.data
        ?.map((d) => d.mail_template_ids._id)
        ?.includes(template.value)
    )
  );
  const templateNameRef = useRef();

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (selectedTemplates.length == 0) {
      addMessage("Please add mail template", "error");
      return;
    }
    const payload = {};

    payload._id = element._id || null;
    payload.name = templateNameRef.current?.value || element.name;
    payload.mail_template_ids = selectedTemplates.map(
      (template) => template.value
    );

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailTest.bind(null, payload));
    } else {
      response = await makeRequest(createMailTest.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  return (
    <PopUp setShow={setElement}>
      <Title>Mail Test</Title>
      <form onSubmit={(e) => handleSave(e)}>
        <Input
          inputRef={templateNameRef}
          width={230}
          inputWidth={400}
          name="Name"
          color={Colors.darkBlue}
          value={element?.data && element?.data[0]?.name}
          requiredSign
          required
        />
        <SelectInput
          name={"Mail templates"}
          width={230}
          selectWidth={400}
          color={Colors.darkBlue}
          options={templates}
          selected={selectedTemplates}
          setSelected={setSelectedTemplates}
          multiple
        />
        <ButtonSaveWrapper>
          <button className="btn btn-warning" type="submit">
            Save
          </button>
        </ButtonSaveWrapper>
      </form>
    </PopUp>
  );
};

const MailTest = () => {
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [element, setElement] = useState();
  const { addMessage, messages } = useMessageQueue();

  const handleLoadData = async () => {
    const mailTemplateResponse = await makeRequest(getMailTemplates);

    if (mailTemplateResponse.data) {
      setMailTemplateOptions(() =>
        mailTemplateResponse.data.map((template) => ({
          label: template.template_sengrid_name,
          value: template._id,
        }))
      );
    }

    const response = await makeRequest(getMailTests);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getMailTests, createMailTest, updateMailTest) && (
        <Loading />
      )}
      <MessageQueue messages={messages} />
      <Wrapper>
        <ButtonCreateWrapper>
          <button
            className="btn btn-warning animation-scale"
            onClick={() => setElement({})}
          >
            Create
          </button>
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            addMessage={addMessage}
            reload={handleLoadData}
            templates={mailTemplateOptions}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={4}>Mail Tests</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Templates</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] && mailTemplateOptions[0] ? (
              data.map((element, i) => (
                <tr key={element._id}>
                  <td>{i + 1}.</td>
                  <td>{element.data[0].name}</td>
                  <td>
                    {element.data
                      ?.map(
                        (template) =>
                          template.mail_template_ids.template_sengrid_name
                      )
                      .join(", ") || "-----"}
                  </td>
                  <td>
                    <i
                      className="fa fa-edit animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() => setElement(element)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default MailTest;
