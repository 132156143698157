import CSV from "@/common/components/CSV";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React from "react";
import { HEADERS } from "./constants/headers";
import { generateData } from "../helpers/generateData";

const UTMReportTable = ({ data }) => {
  const {
    commonData: { markets, products },
  } = useCommonDataContext();

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Market</th>
          <th>Product</th>
          <th>UTM</th>
          <th>Downloaded</th>
          <th>Confirmed</th>
          <th>WPPK Confirmed</th>
          <th>% Confirmed</th>
          <th>Delivered</th>
          <th>WPPK Delivered</th>
          <th>% Delivered</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="queue">
        {Object.entries(data).map(([key, value]) => (
          <tr>
            <td>{markets.find((m) => m._id === value.market)?.name}</td>
            <td>{products.find((m) => m._id === value.product)?.name}</td>
            <td>{key.split("-")[1]}</td>
            <td>{value.contacts}</td>
            <td>{value.cod_count}</td>
            <td>
              {value.contacts > 0
                ? Math.round(parseFloat(value.cod_sum / value.contacts) * 100) /
                  100
                : 0}
            </td>
            <td>
              {Math.round((value.cod_count / value.contacts) * 10000) / 100} %
            </td>
            <td>{value.cod_delivered_count}</td>
            <td>
              {value.contacts > 0
                ? Math.round(
                    parseFloat(value.cod_delivered_sum / value.contacts) * 100
                  ) / 100
                : 0}
            </td>
            <td>
              {Math.round(
                (value.cod_delivered_count / value.cod_count) * 10000
              ) / 100}{" "}
              %
            </td>
            <td>
              <CSV
                filename="utm-report.csv"
                header={HEADERS}
                data={generateData({ key, value, markets, products })}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UTMReportTable;
