import { validate } from "./validate";

export const formatBeforeSubmit = (values, addMessage) => {
  const error = validate(values);

  if (error) {
    return addMessage(error, "error");
  }
  const formattedValues = { ...values };

  formattedValues.time_period = values.time_period.value;
  formattedValues.period_from =
    values.time_period.value !== "day"
      ? values.period_from.value.format("YYYY-MM-DD")
      : values.period_from;

  formattedValues.period_to =
    values.time_period.value !== "day"
      ? values.period_to.value.format("YYYY-MM-DD")
      : values.period_to;
  formattedValues.products = values.products.map((el) => el.value);

  return formattedValues;
};
