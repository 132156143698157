import Api from "../api";
import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const getGamificationStatsByMarket = (marketId) =>
  errorHandler(async () => {
    const data = await api.get(`/gamification-stats/market-stats/${marketId}`);

    return { data: data.data };
  });

export const findGamificationStatsForConsultantsAndMonth = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/gamification-stats/month-and-consultants?${queryString.stringify(
        cleanObject(payload),
        { arrayFormat: "bracket" }
      )}`
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
