export const getValidBonuses = (date, bonuses, addMessage) => {
  const bonusesProductMap = new Map();

    const selectedDate = new Date(date);

    bonuses.forEach((bonus) => {
      const validFrom = new Date(bonus.valid_from);
      const validTo = new Date(bonus.valid_to);      
      if (validFrom <= selectedDate) {
        const currentBonus = bonusesProductMap.get(bonus.product);

        const currentBonusValidTo = new Date(currentBonus?.valid_to);

        if (currentBonus && (currentBonusValidTo >= validTo)) {
          return;
        }

        bonusesProductMap.set(bonus.product, bonus);
      }
    });

    const values = Array.from(bonusesProductMap.values());

    if (!values?.length) {
      addMessage("Check bonuses", "error")
      return;
    }

    return values;
}