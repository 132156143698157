import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import {
  deleteInvoiceById,
  searchInvoices,
} from "../../API/repositories/tickets";
import { Colors } from "../../common/colors/colors";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { useRequestsContext } from "../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import InvoiceEdit from "../../components/admin/hr/InvoiceEdit";
import Table from "../../common/components/Table";
import { formatToDateTamplate } from "../../common/functions/dateFormater";
import { PRIORITY_SORT } from "../../common/constants/priorities";
import { IconWrapper } from "../consultant/consultantManagerScoringListening/consultantManagerScoring.styles";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findQueuesByMarketId } from "@/common/functions/findQueuesByMarketId";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
  min-height: 100%;
`;

const SearchBar = styled.div`
  z-index: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue};
  min-width: 50vw;
  gap: 30px;
  border-radius: 15px;
  height: 220px;
  max-width: 75vw;
`;

export const INVOICE_STATUSES_OPTIONS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "To correct",
    value: "to_correct",
  },
  {
    label: "Issued",
    value: "issued",
  },
  {
    label: "Done",
    value: "done",
  },
];

const getColor = (priority) => {
  switch (priority) {
    case "low_priority":
      return Colors.yellow;
    case "high_priority":
      return Colors.red;
    default:
      return "";
  }
};

const Invoices = () => {
  const [edit, setEdit] = useState();
  const [data, setData] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedStatus, setSelectedStatus] = useState(
    INVOICE_STATUSES_OPTIONS[0]
  );

  const {
    options: { productsOptions, marketsOptions },
    commonData: { queues },
  } = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e?.preventDefault();

    if (!selectedProduct || !selectedStatus)
      return addMessage("Select all required data");

    const payload = {};
    payload.product = selectedProduct.value;
    payload.status = selectedStatus.value;

    const response = await makeRequest(searchInvoices.bind(null, payload));

    if (response.data) {
      if (edit) {
        setEdit(() => response.data.find((res) => res._id === edit._id));
      }

      const visibleData = response.data
        .sort(
          (elementA, elementB) =>
            new Date(elementA._order.shipping?.status_delivered_at).getTime() -
            new Date(elementB._order.shipping?.status_delivered_at).getTime()
        )
        .sort(
          (a, b) =>
            PRIORITY_SORT[a.invoice.priority] -
            PRIORITY_SORT[b.invoice.priority]
        );

      setData(() => visibleData);
    }
  };

  const { removeMessage, messages, addMessage } = useMessageQueue();

  const handleChangeMarket = (marketOption) => {
    const filteredQueues = findQueuesByMarketId(marketOption.value, queues);

    setSelectedProduct(() =>
      productsOptions.find((productOption) =>
        filteredQueues.some((queue) => queue.product === productOption.value)
      )
    );

    setSelectedMarket(() => marketOption);
  };

  const handleDeleteInvoice = async (invoice) => {
    const response = await makeRequest(
      deleteInvoiceById.bind(null, invoice._id)
    );

    if (response.data) {
      addMessage("Deleted", "success");
      handleSearch();
    } else {
      addMessage("Error", "error");
    }
  };

  const filterProductsOptions = () => {
    if (!selectedMarket) {
      return [];
    }

    const filteredQueues = findQueuesByMarketId(selectedMarket.value, queues);

    return productsOptions.filter((productOption) =>
      filteredQueues.some((queue) => queue.product === productOption.value)
    );
  };

  const headers = [
    "No.",
    "Full name",
    "Email",
    "Company name",
    "Product",
    "Delivery at NO",
    "Delivery at",
    "Invoice to",
    "Invoice status",
    "Actions",
  ];

  const raws = useMemo(() => {
    if (data && data[0]) {
      return data.map((element, i) => (
        <tr
          key={element.created_at}
          style={{ backgroundColor: getColor(element.invoice.priority) }}
        >
          <td>{i + 1}.</td>
          <td>{element._contact.full_name}</td>
          <td>{element._contact.email}</td>
          <td>{element.invoice.comapny_name}</td>
          <td>{element._product?.name || "-----"}</td>
          <td>
            {element._order.shipping?.status_delivered_no_payment_at
              ? formatToDateTamplate(
                  element._order.shipping.status_delivered_no_payment_at
                )
              : "-----"}
          </td>
          <td>
            {element._order.shipping?.status_delivered_at
              ? formatToDateTamplate(
                  element._order.shipping.status_delivered_at
                )
              : "-----"}
          </td>
          <td>
            {element._order.shipping?.status_delivered_at
              ? moment(element._order.shipping.status_delivered_at)
                  .add("30", "days")
                  .format("YYYY-MM-DD")
              : element._order.shipping?.status_delivered_no_payment_at
              ? moment(element._order.shipping.status_delivered_at)
                  .add("30", "days")
                  .format("YYYY-MM-DD")
              : "-----"}
          </td>
          <td>{element.status}</td>
          <td>
            <IconWrapper>
              <i
                className="fa fa-edit animation-scale"
                style={{
                  fontSize: "14px",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => setEdit(() => element)}
              />
              <i
                className="fa fa-trash animation-scale"
                style={{
                  fontSize: "14px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteInvoice(element)}
              />
            </IconWrapper>
          </td>
        </tr>
      ));
    }
  }, [data]);

  const headersDone = [
    "No.",
    "Full name",
    "Email",
    "Company name",
    "Product",
    "Delivery at",
    "Date of payment",
    "Invoice number",
    "Actions",
  ];

  const rawsDone = useMemo(() => {
    if (data && data[0]) {
      return data.map((element, i) => (
        <tr
          key={element.created_at}
          style={{ backgroundColor: getColor(element.invoice.priority) }}
        >
          <td>{i + 1}.</td>
          <td>{element._contact.full_name}</td>
          <td>{element._contact.email}</td>
          <td>{element.invoice.comapny_name}</td>
          <td>{element._product?.name || "-----"}</td>
          <td>
            {element._order.shipping?.status_delivered_at
              ? formatToDateTamplate(
                  element._order.shipping.status_delivered_at
                )
              : "-----"}
          </td>
          <td>{formatToDateTamplate(element.invoice.sold_date)}</td>
          <td>{element.invoice.invoice_number}</td>
          <td>
            <i
              className="fa fa-edit animation-scale"
              style={{
                fontSize: "14px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setEdit(() => element)}
            />
          </td>
        </tr>
      ));
    }
  }, [data]);

  useEffect(() => {
    if (!!marketsOptions?.length) {
      handleChangeMarket(marketsOptions[0]);
    }
  }, [marketsOptions]);

  return (
    <Wrapper>
      <MessageQueue
        messages={messages}
        removeMessage={removeMessage}
        addMessage={addMessage}
      />
      {hasUnfilledRequest(searchInvoices) && <Loading />}
      <SearchBar>
        <h3>Invoices:</h3>
        <div>
          <SelectInput
            color={Colors.darkBlue}
            name="Market"
            width={140}
            inputWidth={140}
            setSelected={handleChangeMarket}
            options={marketsOptions}
            selected={selectedMarket}
          />
          <SelectInput
            color={Colors.darkBlue}
            name="Product"
            width={140}
            inputWidth={140}
            selected={selectedProduct}
            setSelected={setSelectedProduct}
            options={filterProductsOptions()}
          />
          <SelectInput
            color={Colors.darkBlue}
            name="Status"
            width={140}
            inputWidth={140}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            options={INVOICE_STATUSES_OPTIONS}
          />
        </div>
        <div>
          <button className="btn btn-warning" onClick={(e) => handleSearch(e)}>
            Search
          </button>
        </div>
      </SearchBar>
      {data && data[0] && selectedStatus.value !== "done" && (
        <Table
          headers={headers}
          raws={raws}
          className="styled-table styled-table-2"
        />
      )}
      {data && data[0] && selectedStatus.value === "done" && (
        <Table
          style={{ overflow: "scroll", minWidth: "50vh", maxWidth: "75vw" }}
          headers={headersDone}
          raws={rawsDone}
          className="styled-table styled-table-2"
        />
      )}
      {edit && (
        <InvoiceEdit
          setShow={setEdit}
          data={edit}
          handleSearch={handleSearch}
        />
      )}
    </Wrapper>
  );
};

export default Invoices;
