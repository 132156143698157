import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TranscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  :not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const TranscirptionTitle = styled.h3`
  color: ${Colors.darkBlue};
  font-size: 32px;
  text-transform: uppercase;
`;

export const TranscriptionText = styled.p`
  max-width: 900px;
  padding: 0 12px;
  white-space: pre-wrap;
`;
