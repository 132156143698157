import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const KeyTable = ({ keys, setKey }) => {
  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(keys, setKey)}
    />
  );
};

export default KeyTable;
