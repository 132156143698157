import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const ProgressBarItem = styled.div`
  position: relative;
  height: 12px;
  width: ${(props) => props.width};
  background-color: ${Colors.lightGray};
  border: 1px solid ${Colors.darkGray};

  :first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

    ::before {
      content: "0";
      position: absolute;
      bottom: -20px;
      font-size: 12px;
      color: gray;
    }
  }

  :last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    ::after {
      content: "${(props) => props.step}";
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 12px;
      color: gray;
    }
  }

  ::after {
    content: "${(props) => props.step}";
    position: absolute;
    right: -8px;
    bottom: -20px;
    font-size: 12px;
    color: gray;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
`;

export const ProgressBarItemFiller = styled.div`
  background-color: ${Colors.brightOrange};
  border-radius: inherit;
  height: 10px;
  width: ${(props) => props.width};
`;
