import { getCallCenterPositions } from '@/API/repositories/callCenterPosition';
import { getPages } from '@/API/repositories/recruitmentPage';
import Loading from '@/common/components/Loading';
import { useRequestsContext } from '@/common/hooks/requestHook';
import MessageQueue, { useMessageQueue } from '@/common/messageProvider';
import { CALL_CENTER_POSITION_OPTIONS } from '@/pages/admin/callCenterPosition/constants/callCenterPositions';
import React, { useEffect, useState } from 'react'
import CandidatesTable from './components/candidatesTable/CandidatesTable';
import { TableContainer, TableWrapper } from './RecruitmentManager.styled';
import { getCallCenterCandidatesForManager } from '@/API/repositories/callCenterCandidate';
import CandidateForm from './components/candidateForm/CandidateForm';
import RecruitmentManagerSearchBar from './components/recruitmentManagerSearchBar/RecruitmentManagerSearchBar';
import { useSearchParams } from 'react-router-dom';

const RecruitmentManager = () => {
  const [showCandidateForm, setShowCandidateForm] = useState(false);
  const [callCenterPositionsOptions, setCallCenterPositionsOptions] = useState([]);
  const [recruitmentPagesOptions, setRecruitmentPagesOptions] = useState([]);
  const [callCenterCandidates, setCallCenterCandidates] = useState([]);
  const [visibleCandidates, setVisibleCandidates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const loadBaseData = async () => {
    await Promise.all([
      loadCallCenterPositions(),
      loadRecruitmentPages(),
      loadCandidates()]);
  };

  const loadCandidates = async () => {
    const response = await makeRequest(getCallCenterCandidatesForManager);

    if (!response?.data) {
      return addMessage("Couldn't load call center candidates", "error");
    }

    setCallCenterCandidates(response.data);
  };

  const selectBySearchParams = () => {
    if (searchParams.has("candidate")) {
      const candidate = callCenterCandidates.find(c => c._id === searchParams.get("candidate"));
  
      if (candidate) {
        setShowCandidateForm(candidate);
        setSearchParams("");
      }
    }
  }

  const loadCallCenterPositions = async () => {
    const response = await makeRequest(getCallCenterPositions);

    if (!response?.data) {
      return addMessage("Couldn't load call center positions", "error");
    }

    setCallCenterPositionsOptions(response.data.map(({ title, _id, market }) => ({ label: CALL_CENTER_POSITION_OPTIONS.find(o => o.value === title)?.label, value: _id, market: market })));
  };

  const loadRecruitmentPages = async () => {
    const response = await makeRequest(getPages);

    if (!response?.data) {
      return addMessage("Couldn't load recruitment pages", "error");
    }

    setRecruitmentPagesOptions(response.data.map(({ title, _id, market }) => ({ label: title, value: _id, market: market })));
  };

  useEffect(() => {
    loadBaseData();
  }, []);

  useEffect(() => {
    selectBySearchParams();
  }, [searchParams, callCenterCandidates]);

  return (
    <div>
      {hasUnfilledRequest(getCallCenterCandidatesForManager, getCallCenterPositions, getPages) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <RecruitmentManagerSearchBar candidates={callCenterCandidates} setVisibleCandidates={setVisibleCandidates} />
      <TableContainer>
        <TableWrapper>
          <CandidatesTable setShowCandidateForm={setShowCandidateForm} candidates={visibleCandidates}  />
        </TableWrapper>
      </TableContainer>
      {showCandidateForm && (
        <CandidateForm
          callCenterPositionsOptions={callCenterPositionsOptions}
          recruitmentPagesOptions={recruitmentPagesOptions}
          addMessage={addMessage} 
          setShow={setShowCandidateForm}
          selectedCandidate={showCandidateForm}
          reload={loadCandidates}
        />
      )}
    </div>
  )
}

export default RecruitmentManager
