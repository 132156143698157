export const FILTER_ACTIONS = {
	SET_MARKETS: 'markets',
	SET_PRODUCTS: 'products',
	SET_CLAIM_TYPES: 'claimTypes',
	SET_REASONS: 'reasons',
	SET_TAGS: 'tags',
	RESET: 'reset',
};

export const DEFAULT_FILTER = {
	markets: [],
	products: [],
	claimTypes: [],
	reasons: [],
	tags: [],
};
