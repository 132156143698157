export const getVatLabel = (vatAmount) => {
  switch (vatAmount) {
    case "0%":
      return "0% VAT / Reverse charge";
    case "zw":
      return "zw";
    default:
      return "----";
  }
};
