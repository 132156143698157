import { Colors } from "@/common/colors/colors";

export const getOutdatedTicketBackgroundColor = (
  ticket,
  selectedTicket,
  currentUserId
) => {
  return selectedTicket?._id === ticket?._id
    ? Colors.newGray
    : Colors.newDarkGray;
};
