import { Colors } from "@/common/colors/colors";
import React, { useContext } from "react";
import styled from "styled-components";
import { RealtimeContext } from "../context/RealtimeContext";
import WraptimeNow from "@/components/admin/voip/realtime/WraptimeNow";
import TalkingNow from "@/components/admin/voip/realtime/TalkingNow";
import CallingNow from "@/components/admin/voip/realtime/CallingNow";
import IncomingCalls from "@/components/admin/voip/realtime/IncomingCalls";
import BreakNow from "@/components/admin/voip/realtime/BreakNow";
import LoginNow from "@/components/admin/voip/realtime/LoginNow";
import WorkingData from "@/components/admin/voip/realtime/WorkingData";

const UserWrapper = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${Colors.darkBlue};
  margin-top: -10px;
  border-radius: 5px;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
`;

const Users = () => {
  const { data, incomingCalls, workingData } = useContext(RealtimeContext);

  return (
    <>
      <UserWrapper />
      <ActionWrapper>
        <ElementsWrapper>
          <WraptimeNow data={data.wraptime} />
          <TalkingNow data={data.talking} />
          <CallingNow data={data.calling} />
          <IncomingCalls data={incomingCalls} />
        </ElementsWrapper>
        <ElementsWrapper>
          <WorkingData workingData={workingData} />
          <BreakNow data={data.break} />
          <LoginNow data={data.login} />
        </ElementsWrapper>
      </ActionWrapper>
    </>
  );
};

export default Users;
