import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import PopUp from "../../../common/components/PopUp";
import { CallCRMApiContext } from "../../../common/contexts/callingApiContext";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 150px;
  margin-bottom: -20px;
`;

const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

const Subtittle = styled.div`
  color: ${Colors.darkBlue};
`;

const ConsultantDecisionCalling = ({ setShowCalling, handleBreak }) => {
  const [callingTime, setCallingTime] = useState(20);

  const { handleCallNext, takeABreak } = useContext(CallCRMApiContextVonage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCallingTime((time) => {
        if (time > 0) {
          return time - 1;
        }

        handleCallNext();
        setShowCalling(() => false);
        return time;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getColor = () => {
    if (callingTime > 10) {
      return Colors.green;
    }

    if (callingTime > 5) {
      return Colors.orange;
    }

    return Colors.red;
  };

  return (
    <PopUp>
      <div>
        <Title>Do you want to call next person?</Title>
        <Subtittle>
          automatily calling next person in{" "}
          <span style={{ color: getColor(), fontWeight: "bold" }}>
            {callingTime}
          </span>
        </Subtittle>
        <Wrapper>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              setShowCalling(() => false);
              handleBreak(e);
            }}
          >
            Break
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => {
              handleCallNext();
              setShowCalling(() => false);
            }}
          >
            Call Next
          </button>
        </Wrapper>
      </div>
    </PopUp>
  );
};

export default ConsultantDecisionCalling;
