import React from "react";
import { CSVLink } from "react-csv";
import { Colors } from "../colors/colors";

const CSV = ({
  header,
  data,
  fontSize = 25,
  filename,
  label = "",
  color = Colors.darkBlue,
}) => {
  return (
    <CSVLink
      style={{ textDecoration: "none" }}
      filename={filename}
      data={[header, ...data]}
    >
      <div>
        {label && (
          <span style={{ color: "black", fontSize: "22px" }}>
            {label}
            &nbsp;&rarr;&nbsp;
          </span>
        )}
        <i
          className="animation-scale fa fa-file-csv"
          style={{
            fontSize: `${fontSize}px`,
            cursor: "pointer",
            color: color,
          }}
        />
      </div>
    </CSVLink>
  );
};

export default CSV;
