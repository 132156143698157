export const HEADERS = [
  "No.",
  "Market",
  "Number",
  "Full name",
  "All calling times",
  "Inbound calling times",
  "Inbound not answered",
  "Has outbound after",
  "Pick up answered",
  "Actions",
];
