import Quill from 'quill';
import React, { forwardRef, useEffect, useRef } from 'react';
import 'quill/dist/quill.snow.css';

const RichTextEditor = forwardRef(
  ({ readOnly = false, placeholder = 'Type message...' }, ref) => {
    const containerRef = useRef(null);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        readOnly,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
          
            ['clean']                                         
          ],
        },
        placeholder,
        theme: 'snow',
      });

      ref.current = quill;

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref, readOnly, placeholder]);

    return <div ref={containerRef}></div>;
  },
);

RichTextEditor.displayName = 'Editor';

export default RichTextEditor;