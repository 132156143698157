import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ManualViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const BrowserIcon = styled.i`
  font-size: 24px;
  cursor: pointer;
  color: ${(props) =>
    props.notActive
      ? Colors.darkGray2
      : props.hasVisited
      ? Colors.green
      : Colors.red};
  pointer-events: ${(props) => (props.notActive ? "none" : "default")};
`;

export const BrowserIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  margin-top: 17px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InputsWrapper = styled.div`
  visibility: ${(props) => props.visibility};
`;
