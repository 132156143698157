import React from "react";
import { countByStatus } from "../../helpers/countByStatus";
import { countAnsweredType } from "./helpers/countAnsweredType";

const OutgoingTable = ({ data }) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Consultant</th>
          <th>Triggered calls</th>
          <th>No Response</th>
          <th>Voice mail</th>
          <th>Answerd</th>
          <th>Confirmed</th>
          <th>Shift</th>
          <th>Resign</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data.map((d) => (
          <tr>
            <td>{d._id}</td>
            <td>{d.data.length}</td>
            <td>{countByStatus(d.data, "no_response")}</td>
            <td>{countAnsweredType(d.data, ["no_response"])}</td>
            <td>
              {countAnsweredType(d.data, ["confirmed", "shift", "resigned"])}
            </td>
            <td>{countByStatus(d.data, "confirmed")}</td>
            <td>{countByStatus(d.data, "shift")}</td>
            <td>{countByStatus(d.data, "resigned")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OutgoingTable;
