import styled from "styled-components";

export const ScoringWrapper = styled.div`
  margin-top: -16px;
`;

export const ScoringStarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
`;

export const AverageScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;
