import { getValidClaimsToCorrect } from '@/API/repositories/reports';
import Table from '@/common/components/Table';
import { useRequestsContext } from '@/common/hooks/requestHook';
import React, { useEffect, useMemo, useState } from 'react'
import { HEADERS_ARRAY } from './constants/headersArray';
import Icon from '@/common/components/Icon';
import { EmailText, EmailWrapper, TableWrapper } from './ToCorrectClaimsTable.styled';

const ToCorrectClaimsTable = () => {
  const [raws, setRaws] = useState([]);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const shouldShowLoading = useMemo(
    () => !raws?.length && hasUnfilledRequest(getValidClaimsToCorrect),
    [raws, hasUnfilledRequest]
  ); 

  const handleOpenContact = (contactId) => {
    window.open(`${window.location.origin}/dashboard/contact/${contactId}`, "_blank")
  }

  const loadData = async () => {
    const response = await makeRequest(getValidClaimsToCorrect);

    if (!response?.data) {
      return;
    }

    const currentRaws = response.data.map((claim) => (
      <tr key={claim._id}>
        <td>{claim.product.name}</td>
        <td>
          <EmailWrapper>
            <EmailText>
              {claim.contact.email} 
            </EmailText>
            <Icon 
              onClick={() => handleOpenContact(claim.contact._id)} 
              name="fa fa-address-book"
            />
          </EmailWrapper>
        </td>
      </tr>
    ))

    setRaws(() => currentRaws);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <TableWrapper>
      <Table
        loading={shouldShowLoading}
        stickyHeader
        className="styled-table-dashboard"
        headersArray={HEADERS_ARRAY}
        raws={raws}
      />
    </TableWrapper>
  )
}

export default ToCorrectClaimsTable
