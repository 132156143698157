import React from "react";
import PopUp from "@/common/components/PopUp";
import { SubTitle, Textarea, SaveButtonWrapper } from "../../TicketView.styled";

const DocumentToCorrect = ({
  setIsToCorrectOpen,
  handleChangeDocumentTicketStatus,
  documentMessageRef,
}) => {
  return (
    <PopUp setShow={setIsToCorrectOpen}>
      <SubTitle>Add what to correct:</SubTitle>
      <Textarea
        showLabel={false}
        placeholder="correct..."
        ref={documentMessageRef}
      />
      <SaveButtonWrapper>
        <button
          onClick={() => handleChangeDocumentTicketStatus("to_correct", true)}
          className="btn btn-warning"
        >
          Save
        </button>
      </SaveButtonWrapper>
    </PopUp>
  );
};

export default DocumentToCorrect;
