export const errorHandler = async (fn) => {
  try {
    return await fn();
  } catch (error) {
    console.error(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return { message: message, data: null };
  }
};
