import React, { useEffect, useMemo, useState } from "react";
import PopUp from "../PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createAdditionalOrder, getOrderById } from "@/API/repositories/order";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SelectInput from "../SelectInput";
import Loading from "../Loading";
import CustomButtonAdd from "../buttons/CustomButtonAdd";
import { Colors } from "@/common/colors/colors";
import Table from "../Table";
import Icon from "../Icon";
import {
  ButtonWrapper,
  InputsWrapper,
  ProductNameLabel,
  ProductNameWrapper,
  Title,
} from "./AdditionalOrderCreate.styled";
import CustomButtonSave from "../buttons/CustomButtonSave";
import { preventFn } from "@/common/functions/preventEvent";
import userManager from "@/API/userManager";

const AdditionalOrderCreate = ({ setShow, orderById, reloadFn }) => {
  const [orderBy, setOrderBy] = useState();
  const [chosenVariants, setChosenVariants] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionPrice, setSelectedOptionPrice] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { messages, removeMessage, addMessage } = useMessageQueue();
  const {
    commonData: { products, markets },
  } = useCommonDataContext();

  const loadData = async () => {
    const response = await makeRequest(getOrderById.bind(null, orderById));

    if (!response?.data) {
      return addMessage("Error loading data", "error");
    }

    setOrderBy(() => response.data);
  };

  const handleDelteProductItem = (_id) => {
    let data = chosenVariants.filter((item) => item._id !== _id);
    let id = 1;
    data = data.map((data) => {
      data._id = id;
      id++;
      return data;
    });
    setChosenVariants(() => data);
  };

  const hadnleAddProduct = () => {
    if (selectedOption && selectedOptionPrice)
      setChosenVariants((prev) => [
        ...(prev || []),
        {
          _id: (prev?.length || 0) + 1,
          name: selectedOption.label,
          value: selectedOption.value,
          price: selectedOptionPrice.value,
        },
      ]);
  };

  const handleSubmit = async (e) => {
    e && preventFn(e);

    const additionalOrderPayload = {};

    additionalOrderPayload.contact = orderBy.contact;
    additionalOrderPayload.status = "confirmed";
    additionalOrderPayload.confirmation_date = new Date();
    additionalOrderPayload.confirmed_by = userManager.getUser().id;
    additionalOrderPayload.currency = orderBy.currency;
    additionalOrderPayload.chosen_variants = chosenVariants;
    additionalOrderPayload.product = orderBy.product;
    additionalOrderPayload.market = orderBy.market;

    const shipping = {};

    shipping.status = "stand_by";
    shipping.created_at = new Date();
    shipping.curier_lms_id = orderBy.shipping.curier_lms_id;

    orderBy.shipping = shipping;

    additionalOrderPayload.shipping = shipping;

    const response = await makeRequest(
      createAdditionalOrder.bind(null, additionalOrderPayload)
    );

    if (!response?.data) {
      return addMessage("Couldn't create additional order", "error");
    }

    setShow(() => false);
    reloadFn();
  };

  const orderProduct = useMemo(
    () => products.find((product) => product._id === orderBy?.product),
    [products, orderBy]
  );

  const orderMarket = useMemo(
    () => markets.find((market) => market._id === orderBy?.market),
    [markets, orderBy]
  );

  const headers = ["Variant", "Short", "Price", "Actions"];
  const raws = chosenVariants?.map((data) => (
    <tr key={data._id}>
      <td>{data.name}</td>
      <td>{data.value}</td>
      <td>
        {data.price} {orderMarket.currency}
      </td>
      <td>
        <Icon
          onClick={() => handleDelteProductItem(data._id)}
          name="fa fa-remove"
          color={Colors.red}
        />
      </td>
    </tr>
  ));

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(getOrderById) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {orderMarket && orderProduct && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <Title>Order items:</Title>
          <ProductNameWrapper>
            <ProductNameLabel>Name:</ProductNameLabel>
            {orderProduct.name}
          </ProductNameWrapper>
          <InputsWrapper>
            <SelectInput
              name="Variant"
              color={Colors.darkBlue}
              width={80}
              selectWidth={200}
              selected={selectedOption}
              setSelected={setSelectedOption}
              options={orderProduct.variants.map((varaint) => {
                return { label: varaint.name, value: varaint.short };
              })}
            />
            <SelectInput
              name="Price"
              color={Colors.darkBlue}
              width={40}
              selectWidth={130}
              selected={selectedOptionPrice}
              setSelected={setSelectedOptionPrice}
              options={orderProduct.prices.map((price) => {
                return { label: price.value, value: price.value };
              })}
            />
            <CustomButtonAdd
              onClick={(e) => hadnleAddProduct(e)}
              text="add"
              style={{ height: "fit-content" }}
            />
          </InputsWrapper>
          {!!chosenVariants?.length && (
            <>
              <Table
                style={{ margin: "0 auto" }}
                className="styled-table"
                headers={headers}
                raws={raws}
              />
              <ButtonWrapper>
                <CustomButtonSave text="Save order" />
              </ButtonWrapper>
            </>
          )}
        </form>
      )}
    </PopUp>
  );
};

export default AdditionalOrderCreate;
