import Icon from "@/common/components/Icon";

export const getRaws = (invoiceConfigs, setInvoiceConfig) =>
  invoiceConfigs.map((config, i) => (
    <tr key={config._id}>
      <td>{i + 1}</td>
      <td>{config.name}</td>
      <td>{config.buyer_note}</td>
      <td>{config.correction_reason}</td>
      <td>{config.description}</td>
      <td>
        <Icon onClick={() => setInvoiceConfig(config)} name="fa fa-edit" />
      </td>
    </tr>
  ));
