import { getPercent } from "@/common/functions/getPercent";

export const getProgressBarVerticalFillerHeight = (
  deliveredPercent,
  percent,
  prev = 0
) =>
  deliveredPercent >= percent
    ? "100%"
    : deliveredPercent > prev
    ? `${getPercent(deliveredPercent, percent)}%`
    : 0;
