import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Colors } from "../colors/colors";

ChartJS.register(ArcElement, Tooltip, Legend);

const DEFAULT_DOUGHNUT_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        font: {
          color: Colors.darkBlue,
        },
        color: Colors.darkBlue,
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
  cutout: 100,
  percentageInnerCutout: 40,
};

const CustomDoughnut = ({ data, options }) => {
  return (
    <Doughnut
      options={{ ...DEFAULT_DOUGHNUT_OPTIONS, ...options }}
      data={data}
    />
  );
};

export default CustomDoughnut;
