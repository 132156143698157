import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import tokenManager from "./API/tokenManager";
import userManager from "./API/userManager";

const AccessMap = {
  consultant: "CONSULTANT_COMMON",
};

const AccesValidator = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    tokenManager.removeToken();
    userManager.removeUser();
    navigate("/login");
  };

  useEffect(() => {
    if (pathname.split("/")[1] === "consultant") {
      if (!userManager.checkAccess(AccessMap["consultant"])) {
        handleLogout();
      }
      // wyjatki dla managera
    }
  }, [pathname]);

  return <></>;
};

export default AccesValidator;
