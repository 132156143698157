import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const InputsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const TextareaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  margin-left: 28px;
  margin-bottom: 20px;
`;

export const TextareaTitle = styled.span`
  color: ${Colors.darkBlue};
  font-weight: bold;
  font-size: 18px;
`;
