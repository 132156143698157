import Table from "@/common/components/Table";
import React from "react";
import { Icon } from "./LanguageTable.styled";

const LanguageTable = ({
  languages,
  setIsLanguageFormOpen,
  setSelectedLanguage,
}) => {
  const handleSetEditForm = (language) => {
    setIsLanguageFormOpen(() => true);
    setSelectedLanguage(() => language);
  };

  const headers = ["No", "Label", "Short", "Actions"];
  const raws = languages.map((language, i) => (
    <tr key={language._id}>
      <td>{i + 1}</td>
      <td>{language.label}</td>
      <td>{language.short}</td>
      <td>
        <Icon
          onClick={() => handleSetEditForm(language)}
          className="fa fa-edit animation-scale"
        />
      </td>
    </tr>
  ));
  return <Table className="styled-table" headers={headers} raws={raws} />;
};

export default LanguageTable;
