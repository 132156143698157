import { getFileById } from "@/API/repositories/storedDocument";

export const handleDownloadFile = async (storedDataId, makeRequest) => {
  const response = await makeRequest(getFileById.bind(null, storedDataId));

  if (response.data) {
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(response.data.data.data).buffer])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.data.name);
    document.body.appendChild(link);
    link.click();
  }
};
