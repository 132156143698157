import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Flex = styled.div`
  display: flex;
`;

export const RightFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
`;

export const MarginBottomContainter = styled.div`
  margin-bottom: 40px;
`;

export const CorrectionIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const Icon = styled.i`
  font-size: 26px;
  cursor: pointer;
  color: ${Colors.darkBlue};
`;
