import moment from "moment";

export const sortTicketsByInvoiceNumber = (tickets) =>
  tickets
    .sort((ticketA, ticketB) => {
      if (ticketA.status !== "done" || ticketB.status !== "done") {
        return 0;
      }

      let invoiceNumberA = ticketA.valid_invoice.invoice_number;

      if (!invoiceNumberA) {
        const correctionNumber = ticketA.valid_invoice.correction_number;
        const idxLength = parseInt(correctionNumber.slice(1)).toString().length;

        invoiceNumberA = correctionNumber.slice(1 + idxLength);
      }

      let invoiceNumberB = ticketB.valid_invoice.invoice_number;

      if (!invoiceNumberB) {
        const correctionNumber = ticketB.valid_invoice.correction_number;
        const idxLength = parseInt(correctionNumber.slice(1)).toString().length;

        invoiceNumberB = correctionNumber.slice(1 + idxLength);
      }

      const idxA = parseInt(invoiceNumberA.slice(2));
      const idxB = parseInt(invoiceNumberB.slice(2));

      return idxA - idxB;
    })
    .sort((ticketA, ticketB) => {
      if (ticketA.status !== "done" || ticketB.status !== "done") {
        return 0;
      }

      let invoiceNumberA = ticketA.valid_invoice.invoice_number;

      if (!invoiceNumberA) {
        const correctionNumber = ticketA.valid_invoice.correction_number;
        const idxLength = parseInt(correctionNumber.slice(1)).toString().length;

        invoiceNumberA = correctionNumber.slice(1 + idxLength);
      }

      let invoiceNumberB = ticketB.valid_invoice.invoice_number;

      if (!invoiceNumberB) {
        const correctionNumber = ticketB.valid_invoice.correction_number;
        const idxLength = parseInt(correctionNumber.slice(1)).toString().length;

        invoiceNumberB = correctionNumber.slice(1 + idxLength);
      }

      const datePartA = invoiceNumberA.slice(-8, -2);
      const datePartB = invoiceNumberB.slice(-8, -2);

      const monthA = datePartA.slice(0, 2);
      const yearA = datePartA.slice(-4);
      const monthB = datePartB.slice(0, 2);
      const yearB = datePartB.slice(-4);

      const dateA = moment(monthA + "-" + yearA, "MM-YYYY");
      const dateB = moment(monthB + "-" + yearB, "MM-YYYY");

      return moment(dateA).diff(dateB);
    });
