import React from "react";
import PopUp from "@/common/components/PopUp";
import userManager from "@/API/userManager";
import { ButtonWrapper, WarningWrapper } from "./MessageWarning.styled";

const MessageWarning = ({
  userErrorLangInfo,
  setUserErrorLangInfo,
  sendTicketQuestionCreate,
}) => {
  return (
    <PopUp canOffByClickingBackground={false} setShow={setUserErrorLangInfo}>
      <WarningWrapper>
        Error! Your messsage lang is {userErrorLangInfo}, but your system
        language is {userManager.getUser().lang}. Are you sure that you want to
        send this message? If your language is {userErrorLangInfo}, please click
        close icon and change it in settings! If it's error please click send!
      </WarningWrapper>

      <ButtonWrapper>
        <button
          className="btn btn-danger"
          onClick={(e) => sendTicketQuestionCreate(e)}
        >
          Send
        </button>
      </ButtonWrapper>
    </PopUp>
  );
};

export default MessageWarning;
