import { calcConsultantHours } from "./calcConsultantHours";
import { calcHoursPayment } from "./calcHoursPayment";
import { calcTotalSalary } from "../../../helpers/calcTotalSalary";
import { calcPaymentResult } from "./calcPaymentResult";
import { round } from "../../../helpers/round";
import { calcBonus } from "./calcBonus";
import { calcEqualization } from "./calcEqualization";

export const getRaws = (consultantResults, gamificationBonus) => [
  <tr>
    <td rowSpan="2">
      {consultantResults.data[0]._consultant_hours.reduce(
        (prev, next) => prev + calcConsultantHours(next),
        0
      )}{" "}
      h
    </td>
    <td rowSpan="2">
      {calcHoursPayment(consultantResults)}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {calcTotalSalary(
        consultantResults.data.find((e) => e.is_final === false).data,
        consultantResults._month_working_info
      )}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {" "}
      {calcPaymentResult(consultantResults)}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {round(
        calcBonus(
          calcTotalSalary(
            consultantResults.data.find((e) => e.is_final === false).data,
            consultantResults._month_working_info
          ),
          calcHoursPayment(consultantResults)
        )
      )}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td rowSpan="2">
      {gamificationBonus || 0} {consultantResults.data[0].salary_currency}
    </td>
    <td rowSpan="2">
      {calcEqualization(consultantResults)}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
  </tr>,
  <tr>
    <td>
      {(consultantResults.data.find((e) => e.is_final === true) &&
        calcTotalSalary(
          consultantResults.data.find((e) => e.is_final === true).data,
          consultantResults._month_working_info
        )) ||
        0}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {(consultantResults.data.find((e) => e.is_final === true) &&
        calcPaymentResult(consultantResults, true)) ||
        0}{" "}
      {consultantResults.data[0].salary_currency}
    </td>
    <td>
      {consultantResults.data.find((e) => e.is_final === true) &&
        round(
          calcBonus(
            calcTotalSalary(
              consultantResults.data.find((e) => e.is_final === true).data,
              consultantResults._month_working_info
            ),
            calcHoursPayment(consultantResults)
          )
        )}
      {consultantResults.data[0].salary_currency}
    </td>
  </tr>,
];
