import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

const PREFIX = "department";

export const createDepartment = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`${PREFIX}/create`, payload);

    return { data: data.data };
  });

export const updateDepartment = (payload, id) =>
  errorHandler(async () => {
    const data = await api.patch(`${PREFIX}/update/${id}`, payload);

    return { data: data.data };
  });

export const getDepartments = () =>
  errorHandler(async () => {
    const data = await api.get(PREFIX);

    return { data: data.data };
  });
