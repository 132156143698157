import { Colors } from '@/common/colors/colors';
import Table from '@/common/components/Table';
import { formatDate } from '@/common/functions/dateFormater';
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import moment from 'moment';
import React from 'react'
import { CustomRaw, TableWrapper } from '../../Bonus.styled';
import Icon from '@/common/components/Icon';

const BonusesTable = ({ bonuses, setShow, setSelectedBonus }) => {
  const { commonData: { users, products }} = useCommonDataContext();

  const headers = ["No.", "Consultant", "Product", "Valid from", "Valid to", "Actions"]
  const raws = bonuses.map((bonus, index) => {
    const { consultant, product, valid_from, valid_to } = bonus;
    const isLastConsultantBonus = bonuses.findIndex(b => b.consultant === consultant && b.product?.toString() === product?.toString()) === index;

    let bgColor = moment().isBetween(moment(valid_from), moment(valid_to)) 
      ? Colors.green 
      : isLastConsultantBonus 
        ? Colors.orange 
        : Colors.red;

    return (
      <CustomRaw 
        style={{ 
          backgroundColor: bgColor,
        }} 
        key={`${valid_from}${valid_to}${product}${consultant}`}
      >
        <td>{index + 1}</td>
        <td>{users.find(user => user._id === consultant)?.username}</td>
        <td>{products.find(p => p._id === product)?.name}</td>
        <td>{formatDate(valid_from, 'YYYY-MM-DD')}</td>
        <td>{formatDate(valid_to, 'YYYY-MM-DD')}</td>
        <td>
          <Icon 
            onClick={() => {
              setShow(true);
              setSelectedBonus(bonus);
            }}
            name="fa fa-edit" color={Colors.newLightColor}
          />
        </td>
      </CustomRaw>
    )
  })
  
  return (
    <TableWrapper>
      <Table stickyHeader className="styled-table" headers={headers} raws={raws} />
    </TableWrapper>
  )
}

export default BonusesTable
