export const filterProdluctIdsByCompanyId = (
  products = [],
  productIds = [],
  companyId
) =>
  productIds.filter((productId) =>
    products.find(
      (product) => product._id === productId && product.company === companyId
    )
  );
