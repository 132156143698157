import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  AdvancedSettings,
  AdvancedSettingsCloser,
  Connector,
  FiltersWrapper,
  GearButton,
  SettingsWrapper,
  TicketCreateWrapper,
  TitleInfoContainer,
  TitleInfoWrapper,
  TitleWrapper,
} from "./TicketsHeader.styled";
import { AddIcon, Title } from "../ticketView/TicketView.styled";
import SelectInput from "@/common/components/SelectInput";
import Icon from "@/common/components/Icon";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import { getTopicsOptions } from "../ticketView/helpers/getTopicsOptions";
import { filterTickets } from "./helpers/fitlerTickets";
import { Colors } from "@/common/colors/colors";
import {
  DIRECTIONS_OPTIONS,
  FILTERS_ACTIONS,
  NULL_OPTION,
  OTHER_SYSTEMS_OPTION,
  WIDTH_PERCENT_LARGE,
  WIDTH_PERCENT_SMALL,
} from "./constants/filtersConstants";

function reducer(state, action) {
  switch (action.type) {
    case FILTERS_ACTIONS.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case FILTERS_ACTIONS.SET_TOPIC:
      return {
        ...state,
        topic: action.payload,
      };
    case FILTERS_ACTIONS.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case FILTERS_ACTIONS.SET_DIRECTION:
      return {
        ...state,
        direction: action.payload,
      };
    case FILTERS_ACTIONS.SET_DEPARTMENT:
      return {
        ...state,
        department: action.payload,
      };
    case FILTERS_ACTIONS.SET_ASSIGNEE:
      return {
        ...state,
        assignee: action.payload,
      };
    default:
      return state;
  }
}

const TicketsHeader = ({
  setShowCreateTicket,
  departments,
  setFilteredTickets,
  setFilteredOldTickets,
  tickets,
  oldTickets,
}) => {
  const [wrapperWidth, setWrapperWidth] = useState();
  const [filters, dispatchFilters] = useReducer(reducer, {
    product: null,
    topic: null,
    status: null,
    direction: null,
    department: null,
    assignee: null,
  });
  const [showAdvancedSettings, setShowAdvancedSettings] = useState();
  const wrapperRef = useRef();

  const {
    options: { productsOptions },
    getActiveUsersOptions
  } = useCommonDataContext();
  const usersOptions = getActiveUsersOptions();

  useEffect(() => {
    function handleResize() {
      setWrapperWidth(() => wrapperRef.current?.offsetWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFiltersChange = () => {
    setFilteredTickets(() => filterTickets(tickets, filters, departments));
    setFilteredOldTickets(() =>
      filterTickets(oldTickets, filters, departments)
    );
  };

  useEffect(() => {
    handleFiltersChange();
  }, [filters, tickets]);

  return (
    <TitleWrapper ref={wrapperRef}>
      <TitleInfoContainer>
        <TitleInfoWrapper>
          <Title>Tickets</Title>
          <TicketCreateWrapper
            onClick={() => setShowCreateTicket(() => true)}
            className="animation-scale"
          >
            <strong>Add</strong> <AddIcon className="fa fa-plus" />
          </TicketCreateWrapper>
        </TitleInfoWrapper>
      </TitleInfoContainer>
      <FiltersWrapper>
        <SelectInput
          color="#fff"
          placeholderColor={Colors.newGray}
          labelUp
          strong={false}
          name="Product"
          fontSize={14}
          selectFontSize={14}
          options={[NULL_OPTION, ...productsOptions]}
          width={60}
          margin="0"
          setSelected={(e) =>
            dispatchFilters({
              type: FILTERS_ACTIONS.SET_PRODUCT,
              payload: e.value,
            })
          }
          selectWidth={wrapperWidth * WIDTH_PERCENT_SMALL}
        />
        <SelectInput
          color="#fff"
          strong={false}
          placeholderColor={Colors.newGray}
          fontSize={14}
          selectFontSize={14}
          labelUp
          name="Topic"
          width={40}
          margin="0"
          setSelected={(e) =>
            dispatchFilters({
              type: FILTERS_ACTIONS.SET_TOPIC,
              payload: e.value,
            })
          }
          options={[
            NULL_OPTION,
            ...(getTopicsOptions(departments) || []),
            OTHER_SYSTEMS_OPTION,
          ]}
          selectWidth={wrapperWidth * WIDTH_PERCENT_LARGE}
        />
        <SelectInput
          color="#fff"
          strong={false}
          placeholderColor={Colors.newGray}
          fontSize={14}
          selectFontSize={14}
          selectWidth={wrapperWidth * WIDTH_PERCENT_SMALL}
          labelUp
          name="Status"
          setSelected={(e) =>
            dispatchFilters({
              type: FILTERS_ACTIONS.SET_STATUS,
              payload: e.value,
            })
          }
          margin="0"
          width={50}
          options={[NULL_OPTION, ...QUESTION_TICKET_STATUS_OPTIONS]}
        />
        {showAdvancedSettings && (
          <AdvancedSettingsCloser
            onClick={() => setShowAdvancedSettings(false)}
          />
        )}
        <SettingsWrapper>
          <GearButton onClick={() => setShowAdvancedSettings((prev) => !prev)}>
            <Icon
              color={Colors.newLightGray}
              rotate={showAdvancedSettings}
              size={20}
              name="fa fa-gear"
            />
          </GearButton>
          <Connector
            onClick={() => setShowAdvancedSettings((prev) => !prev)}
            visible={showAdvancedSettings}
          />
          <AdvancedSettings visible={showAdvancedSettings}>
            <SelectInput
              placeholderColor={Colors.newGray}
              fontSize={14}
              strong={false}
              selectFontSize={14}
              color={Colors.newGray}
              width={100}
              name="Direction"
              options={[NULL_OPTION, ...DIRECTIONS_OPTIONS]}
              setSelected={(e) =>
                dispatchFilters({
                  type: FILTERS_ACTIONS.SET_DIRECTION,
                  payload: e.value,
                })
              }
            />
            <SelectInput
              placeholderColor={Colors.newGray}
              fontSize={14}
              strong={false}
              selectFontSize={14}
              color={Colors.newGray}
              width={100}
              options={[
                NULL_OPTION,
                ...(departments?.map((d) => ({
                  label: d.name,
                  value: d._id,
                })) || []),
              ]}
              name="Deaprtment"
              setSelected={(e) =>
                dispatchFilters({
                  type: FILTERS_ACTIONS.SET_DEPARTMENT,
                  payload: e.value,
                })
              }
            />
            <SelectInput
              placeholderColor={Colors.newGray}
              fontSize={14}
              selectFontSize={14}
              strong={false}
              color={Colors.newGray}
              width={100}
              options={[NULL_OPTION, ...usersOptions]}
              name="Assignee"
              setSelected={(e) =>
                dispatchFilters({
                  type: FILTERS_ACTIONS.SET_ASSIGNEE,
                  payload: e.value,
                })
              }
            />
          </AdvancedSettings>
        </SettingsWrapper>
      </FiltersWrapper>
    </TitleWrapper>
  );
};

export default TicketsHeader;
