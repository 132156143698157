import { compareDates } from "@/common/functions/compareDates";
import { formatDate } from "@/common/functions/dateFormater";
import { findMarketById } from "../../../helpers/findMarketById";
import { countOrderItemsPrice } from "../../../helpers/countOrderItemsPrice";
import { countOriginalSum } from "../../../helpers/countOriginalSum";
import { countPolishCurrencySum } from "../../../helpers/countPolishCurrencySum";
import { checkCorrection } from "../../../helpers/checkCorrection";
import { checkInvoiceCorrection } from "../../../helpers/checkInvoiceCorrection";
import { checkValidInvoiceCorrection } from "../../../helpers/checkValidInvoiceCorrection";

export const getExcelData = (
  orders,
  correctionNumberFilterRef,
  markets,
  brutto
) => {
  const dataRaws = orders
    .sort((orderA, orderB) =>
      compareDates(
        new Date(orderA.claim.paid_date),
        new Date(orderB.claim.paid_date)
      )
    )
    .filter((order) =>
      correctionNumberFilterRef.current?.checked ? checkCorrection(order) : true
    )
    .map((order, i) => ({
      "No.": i + 1,
      Rynek: findMarketById(order.market, markets)?.name,
      "Miesiąc wykonania zwrotu": formatDate(order.claim.paid_date, "MM/YYYY"),
      "Data sprzedaży usługi": formatDate(
        order.shipping.status_delivered_at ||
          order.shipping.status_delivered_no_payment_at,
        "YYYY-MM-DD"
      ),
      "Data wykonania zwrotu środków": formatDate(
        order.claim.paid_date,
        "YYYY-MM-DD"
      ),
      "Nazwa Klienta": order._contact?.full_name,
      [brutto]: order.claim.price || countOrderItemsPrice(order),
      "Wartość zwrotu środków (pln)": order.claim.polish_currency || 0,
      "Korekta Faktury":
        checkInvoiceCorrection(order)?.invoice.correction_number ||
        checkValidInvoiceCorrection(order)?.valid_invoice.correction_number ||
        "----",
    }));

  const sumRaw = {
    "No.": "",
    Rynek: "Sum",
    "Miesiąc wykonania zwrotu": "",
    "Data sprzedaży usługi": "",
    "Data wykonania zwrotu środków": "",
    "Nazwa Klienta": "",
    [brutto]: countOriginalSum(orders, correctionNumberFilterRef),
    "Wartość zwrotu środków (pln)": countPolishCurrencySum(
      orders,
      correctionNumberFilterRef
    ),
    "Korekta Faktury": "",
  };

  dataRaws.push(sumRaw);

  return dataRaws;
};
