import { VERSION } from "../../common/constants/version";
import Api from "../api";

import { errorHandler } from "../common";
const { tokenManager, axios: api } = Api;

export const getAllFaqs = () =>
  errorHandler(async () => {
    const data = await api.get("/faq");
    return { data: data.data };
  });

export const createFaq = (faq) =>
  errorHandler(async () => {
    const data = await api.post("/faq", faq);
    return { data: data.data };
  });

export const updateFaq = (_id, faq) =>
  errorHandler(async () => {
    const data = await api.patch("/faq/" + _id, faq);
    return { data: data.data };
  });
