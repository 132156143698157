import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const SearchBar = styled.div`
  display: flex;
  border: 1px solid ${Colors.gray};
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  margin-bottom: 20px;
`;

export const TextArea = styled.textarea`
  flex-grow: 1;
  border: none;
  resize: none;
  outline: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Icon = styled.i`
  cursor: pointer;
  color: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate});
  transition: transform 0.2s;
`;
