const { calcTotalSalary } = require("./calcTotalSalary");

export const caclManagerEffectivePayment = (
  data,
  final = false,
) => {
  const effective = data?.data?.find(d => d.is_final === final);
  if (!effective?.team?.length) return 0;

  const teamEffectivePayment = effective.team.reduce(
    (prev, next) =>
      prev +
      (
        calcTotalSalary(
          next?.data,
          next
        ) ||
        0)
      ,
    0
  );

  return (
    Math.round(
      teamEffectivePayment * data._month_working_info.manager_percent
    ) / 100
  );
};
