export const TICKET_TYPES = [
  {
    label: "Question",
    value: "question",
  },
  {
    label: "Correction",
    value: "correction",
  },
  {
    label: "Document",
    value: "document",
  },
  {
    label: "Listening objections",
    value: "listening_objections",
  },
  {
    label: "Calling improvement",
    value: "calling_improvement",
  },
  {
    label: "Suspicion",
    value: "suspicion",
  },
  {
    label: "Todo notification",
    value: "todo_notification",
  },
];

export const TICKET_STATUS_OPTION = [
  { label: "New", value: "new" },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Solved",
    value: "solved",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Done",
    value: "done",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "To correct",
    value: "to_correct",
  },
  {
    label: "Declined",
    value: "declined",
  },
  {
    label: "Declined pending",
    value: "declined_pending",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Accepted pending",
    value: "accepted_pending",
  },
  { label: "Declined pending", value: "declined_pending" },
];
