export const HEADERS = [
  "Date",
  "Queue",
  "Consultant",
  "Break",
  "Calling",
  "Talking",
  "WrapTime",
  "WorkingSum",
];
