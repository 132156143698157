export const WIDTH_PERCENT_SMALL = 0.22;
export const WIDTH_PERCENT_LARGE = 0.32;

export const NULL_OPTION = {
  value: null,
  label: "None",
};

export const OTHER_SYSTEMS_OPTION = {
  value: "other_system",
  label: "Other systems",
};

export const DIRECTIONS = {
  INCOMING: "incoming",
  OUTGOING: "outgoins",
};

export const DIRECTIONS_OPTIONS = [
  { value: DIRECTIONS.INCOMING, label: "Incoming" },
  { value: DIRECTIONS.OUTGOING, label: "Outgoing" },
];

export const FILTERS_ACTIONS = {
  SET_PRODUCT: "set_product",
  SET_TOPIC: "set_topic",
  SET_STATUS: "set_status",
  SET_DIRECTION: "set_direction",
  SET_DEPARTMENT: "set_department",
  SET_ASSIGNEE: "set_assignee",
};
