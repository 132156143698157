import moment from "moment";
import React from "react";
import { COLORS_ARRAY } from "@/common/colors/colorsArray";
import { COLOR_CONFIG } from "./MarketsStatistics";

const MarketsStatisticInbound = ({ data = [] }) => {
  const calcRowSpanCountry = (value) => {
    return value.reduce((prev, next) => prev + next?.data?.length, 0);
  };

  return (
    <table className="styled-table" style={{ margin: "50px 0" }}>
      <thead>
        <tr>
          <th colSpan={10}>Market Stats Inbound</th>
        </tr>
        <tr>
          <th>Country</th>
          <th>Call Center Number</th>
          <th>Trigered Calls</th>
          <th>Not picked up</th>
          <th>Consultant</th>
          <th>Answerd</th>
          <th>Confirmed</th>
          <th>Shift</th>
          <th>Resign</th>
          <th>No action</th>
        </tr>
      </thead>
      <tbody className="queue">
        {Object.entries(data).length > 0 ? (
          Object.entries(data).map(([key, value], index) => (
            <>
              <tr key={20}>
                <td
                  rowSpan={calcRowSpanCountry(value)}
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.base,
                    writingMode: "vertical-rl",
                  }}
                >
                  {key}
                </td>
                <td
                  rowSpan={value[0]?.data?.length}
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {value[0].phone_number}
                </td>
                <td
                  rowSpan={value[0]?.data?.length}
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {value[0]?.count}
                </td>
                <td
                  rowSpan={value[0]?.data?.length}
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {value[0]?.not_picked_up}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data && value[0]?.data[0]?.name) || "-----"}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data && value[0]?.data[0]?.value?.length) || 0}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data &&
                    value[0]?.data[0]?.value.filter(
                      (a) =>
                        a.call_history && a.call_history.status === "confirmed"
                    ).length) ||
                    0}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data &&
                    value[0]?.data[0]?.value.filter(
                      (a) => a.call_history && a.call_history.status === "shift"
                    ).length) ||
                    0}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data &&
                    value[0]?.data[0]?.value.filter(
                      (a) =>
                        a.call_history && a.call_history.status === "resigned"
                    ).length) ||
                    0}
                </td>
                <td
                  style={{
                    backgroundColor: COLOR_CONFIG[index]?.light,
                  }}
                >
                  {(value[0]?.data &&
                    value[0]?.data[0]?.value.filter((a) => !a.call_history)
                      .length) ||
                    0}
                </td>
              </tr>
              {value[0].data?.map((consultantData, i) => {
                if (i > 0) {
                  return (
                    <tr style={{ backgroundColor: COLORS_ARRAY[index + 20] }}>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.name || "-----"}{" "}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.value.length || 0}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.value.filter(
                          (a) =>
                            a.call_history &&
                            a.call_history.status === "confirmed"
                        ).length || 0}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.value.filter(
                          (a) =>
                            a.call_history && a.call_history.status === "shift"
                        ).length || 0}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.value.filter(
                          (a) =>
                            a.call_history &&
                            a.call_history.status === "resigned"
                        ).length || 0}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            COLOR_CONFIG[index] &&
                            COLOR_CONFIG[index][
                              (i + 1) % 2 == 0 ? "dark" : "light"
                            ],
                        }}
                      >
                        {consultantData?.value.filter((a) => !a.call_history)
                          .length || 0}
                      </td>
                    </tr>
                  );
                }
              })}
              {value?.map((data, i) => {
                if (i > 0) {
                  return (
                    <>
                      <tr style={{ backgroundColor: COLORS_ARRAY[index + 20] }}>
                        <td
                          rowSpan={data.data.length}
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {data.phone_number}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {data?.data && data?.data[0]?.name}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {(data?.data && data?.data[0]?.value.length) || 0}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {(data?.data &&
                            data?.data[0]?.value.filter(
                              (a) =>
                                a.call_history &&
                                a.call_history.status === "confirmed"
                            ).length) ||
                            0}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {(data?.data &&
                            data?.data[0]?.value.filter(
                              (a) =>
                                a.call_history &&
                                a.call_history.status === "shift"
                            ).length) ||
                            0}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {(data?.data &&
                            data?.data[0]?.value.filter(
                              (a) =>
                                a.call_history &&
                                a.call_history.status === "resigned"
                            ).length) ||
                            0}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              COLOR_CONFIG[index] &&
                              COLOR_CONFIG[index][
                                (i + 1) % 2 == 0 ? "dark" : "light"
                              ],
                          }}
                        >
                          {(data?.data &&
                            data?.data[0]?.value.filter((a) => !a.call_history)
                              .length) ||
                            0}
                        </td>
                      </tr>
                      {data.data.map(
                        (consultantData, i) =>
                          i > 0 && (
                            <tr
                              style={{
                                backgroundColor: COLORS_ARRAY[index + 20],
                              }}
                            >
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData.name}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData?.value?.length || 0}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData?.value?.filter(
                                  (a) =>
                                    a.call_history &&
                                    a.call_history.status === "confirmed"
                                ).length || 0}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData?.value?.filter(
                                  (a) =>
                                    a.call_history &&
                                    a.call_history.status === "shift"
                                ).length || 0}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData?.value?.filter(
                                  (a) =>
                                    a.call_history &&
                                    a.call_history.status === "resigned"
                                ).length || 0}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    COLOR_CONFIG[index] &&
                                    COLOR_CONFIG[index][
                                      (i + 1) % 2 == 0 ? "dark" : "light"
                                    ],
                                }}
                              >
                                {consultantData?.value?.filter(
                                  (a) => !a.call_history
                                ).length || 0}
                              </td>
                            </tr>
                          )
                      )}
                    </>
                  );
                }
              })}
            </>
          ))
        ) : (
          <tr>
            <td colSpan={10}>There is no data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default MarketsStatisticInbound;
