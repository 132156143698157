export const HEADERS = [
  "Market",
  "Product",
  "Sent",
  "Returned",
  "Delivered",
  "Actions",
];

export const HEADERS_CSV = [
  "Market",
  "Product",
  "Sent",
  "Returned",
  "Delivered",
];
