import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const createCallCenterCandidate = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/call-center-candidate/create`, payload);

    return { data: data.data };
  });

export const updateCallCenterCandidate = (payload, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/call-center-candidate/update/${id}`, payload);

    return { data: data.data };
  });

export const getCallCenterCandidates = () =>
  errorHandler(async () => {
    const data = await api.get(`/call-center-candidate/get`);

    return { data: data.data };
  });

export const getCallCenterCandidatesForManager = () =>
  errorHandler(async () => {
    const data = await api.get(`/call-center-candidate/get-for-manager`);

    return { data: data.data };
  });

export const addCallCenterCandidateComment = (id, comment) =>
  errorHandler(async () => {
    const data = await api.post(`/call-center-candidate/add-comment/${id}`, { comment });

    return { data: data.data };
  });

export const markCallCenterCandidateMessagesSeen = (id) =>
  errorHandler(async () => {
    const data = await api.post(`/call-center-candidate/mark-messages-seen/${id}`);

    return { data: data.data };
  });

export const getAllPossibleCandidateFields = () =>
  errorHandler(async () => {
    const data = await api.get(`/call-center-candidate/get-all-possible-fields`);

    return { data: data.data };
  });
