export const getNumberOfRole = (role) => {
  if (role === "admin") return 1;

  if (role === "call_center_manager") return 6;

  if (role === "call_center_operator") return 7;

  if (role === "analyst") return 4;

  if (role === "head_cc_manager") return 2;

  if (role === "support") return 3;

  if (role === "finance_analyst") return 5;

  return 8;
};
