import Table from "@/common/components/Table";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getRaws } from "./helpers/getRaws";
import { HEADERS } from "./contstants/headers";
import { TableWrapper } from "./GamificationStatsTable.styled";

const GamificationStatsTable = ({ gamificationStats }) => {
  const {
    commonData: { users, markets, queues },
  } = useCommonDataContext();

  const raws = getRaws({
    gamificationStats,
    users,
    markets,
    queues,
  });

  return (
    <TableWrapper>
      <Table
        style={{ width: "100%" }}
        className="styled-table"
        headers={HEADERS}
        raws={raws}
      />
    </TableWrapper>
  );
};

export default GamificationStatsTable;
