import userManager from '@/API/userManager';
import { useRequestsContext } from '@/common/hooks/requestHook';
import React, { useEffect, useRef, useState } from 'react'
import { CommentsListWrapper, CommentsWrapper, EmptyCommentsWrapper, MessageInfoWrapper, MessageSenderWrapper, MessageSendingWrapper, SendCommentWrapper, Textarea, TranslateIcon, TranslationIconWrapper } from './RecruitmentChat.styled';
import { Colors } from '@/common/colors/colors';
import Icon from '../Icon';
import { findUserById } from '@/common/functions/findUserById';
import { formatDate } from '@/common/functions/dateFormater';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import MessageQueue, { useMessageQueue } from '@/common/messageProvider';
import { KEYS_CODES } from '../contactSearch/contstants/keysCodes';
import { addCallCenterCandidateComment, markCallCenterCandidateMessagesSeen } from '@/API/repositories/callCenterCandidate';

const RecruitmentChat = ({ candidate, chatHeight, reload }) => {
  const [comment, setComment] = useState();
  const [commentsList, setCommentsList] = useState(candidate?.messages || []);
  const [isOriginalText, setIsOriginalText] = useState(true);

  const bottomRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { commonData: { users } } = useCommonDataContext();
  const { addMessage, messages, removeMessage } = useMessageQueue();

  const currentUserId = userManager.getUser().id;

  const handleAddComment = async () => {
    setComment(() => "");
    setCommentsList((prev) => [
      ...prev,
      {
        original: comment,
        created_at: new Date(),
        sender: currentUserId,
      },
    ]);

    const response = await makeRequest(
      addCallCenterCandidateComment.bind(null, candidate._id, comment)
    );

    bottomRef.current?.scrollIntoView({ behavior: "smooth" });

    if (!response?.data) {
      setCommentsList(candidate?.messages || []);
      return addMessage("Something went wrong", "error");
    }
    await reload();
  };

  const handleEnterSubmit = (e, fn) => {
    if (
      e.keyCode === KEYS_CODES.enter &&
      e.shiftKey === false &&
      comment?.trim()
    ) {
      e.preventDefault();
      return fn();
    }
  };

  const handleMarkMessagesSeen = async () => {
    const response = await makeRequest(
      markCallCenterCandidateMessagesSeen.bind(null, candidate._id)
    );

    if (response?.data) {
      reload();
    }
  };

  useEffect(() => {
    handleMarkMessagesSeen();
  }, []);

  return (
    <>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <CommentsWrapper chatHeight={chatHeight} >
        <TranslationIconWrapper>
          <TranslateIcon
            onClick={() => setIsOriginalText((prev) => !prev)}
            className="fa fa-language"
          />
        </TranslationIconWrapper>
        {!commentsList?.length && (
          <EmptyCommentsWrapper>No comments yet</EmptyCommentsWrapper>
        )}
        {commentsList.map((message, idx) => (
          <CommentsListWrapper
            align={
              message.sender === currentUserId ? "flex-end" : "flex-start"
            }
            backgroundColor={
              message.sender === currentUserId
                ? Colors.purple
                : Colors.newGray
            }
          >
            <MessageInfoWrapper>
              <span>
                {isOriginalText
                  ? message.original
                  : message.translation?.trim() || message.original}
              </span>
              {hasUnfilledRequest(addCallCenterCandidateComment) &&
                idx === commentsList?.length - 1 && (
                  <div>
                    <Icon color="#fff" name="fa fa-spinner fa-spin" />
                  </div>
                )}
            </MessageInfoWrapper>
            <MessageSendingWrapper>
              <MessageSenderWrapper>
                {findUserById(message.sender, users)?.username ||
                  "System"}
              </MessageSenderWrapper>
              {formatDate(message.created_at, "YYYY-MM-DD HH:mm")}
            </MessageSendingWrapper>
          </CommentsListWrapper>
        ))}
        <div ref={bottomRef} />
      </CommentsWrapper>
      <SendCommentWrapper>
        <Textarea
          value={comment}
          onChange={(e) => setComment(() => e.target.value)}
          onKeyDown={(e) => handleEnterSubmit(e, handleAddComment)}
          placeholder="Add comment..."
        />
        <Icon
          onClick={() => {
            if (comment) {
              handleAddComment();
            }
          }}
          cursor={comment?.trim() ? "pointer" : ""}
          size={22}
          color={comment?.trim() ? Colors.purple : Colors.newDarkGray}
          name="fa fa-send"
        />
      </SendCommentWrapper>
    </>
  )
}

export default RecruitmentChat
