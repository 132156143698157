import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getContactWithOrders,
  markAsRodoDecision,
  updateContact,
} from "@/API/repositories/contact";
import Card from "@/common/components/Card";
import EditItem from "@/common/components/EditItem";
import Input from "@/common/components/Input";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import SelectInput from "@/common/components/SelectInput";
import { COUNTRIES } from "@/common/constants/countries";
import OrderCreate from "@/components/admin/order/OrderCreate";
import ChangeLog from "@/common/components/ChangeLog";
import CallingInfo from "@/common/components/CallingInfo";
import PopUp from "@/common/components/PopUp";
import { Colors } from "@/common/colors/colors";
import CreateCorrectionTicket from "@/components/admin/ticket/components/createCorrectionTicket/CreateCorrectionTicket";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import CustomButtonError from "@/common/components/buttons/CustomButtonError";
import CreateQuestionAdmin from "@/components/admin/ticket/components/createQuestionAdmin/CreateQuestionAdmin";
import {
  SHIPPING_ON_THE_WAY_STATUSES,
} from "@/common/constants/shipping";
import AdditionalOrderCreate from "@/common/components/additionalOrderCreate/AdditionalOrderCreate";
import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useNewMessageQueueContext } from "@/common/hooks/useNewMessageQueue";

const Wrapper = styled.div`
  padding: 20px;
  gap: 20px;
  flex-direction: column;
  display: flex;
`;

const Contact = () => {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [orders, setOrders] = useState(null);
  const fullNameRef = useRef();
  const orginalPhoneNUmberRef = useRef();
  const phoneNumberRef = useRef();
  const phoneForCourierRef = useRef();
  const emailRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const aidditionalInformationRef = useRef();
  const [copied, setCopied] = useState(false);
  const [showNewTicket, setShowNewTicket] = useState();
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [success, setSuccess] = useState();
  const [showAdditionalOrder, setShowAdditionalOrder] = useState();

  const navigate = useNavigate();
  const { addMessage } = useNewMessageQueueContext();

  const [show, setShowAdd] = useState(false);
  const [markAsRodo, setMarkAsRodo] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleCopyLinkForConsultant = async (e) => {
    e.preventDefault();

    let linke = "https://crm.europa-edu.eu/consultant/contact/" + id;
    if (contact.country === "ro") {
      linke = "https://crm.linguainstitute.eu/consultant/contact/" + id;
    }

    const textArea = document.createElement("textarea");
    textArea.value = linke;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }

    document.body.removeChild(textArea);

    setCopied(() => true);
    setTimeout(() => {
      setCopied(false);
    }, [3000]);
  };

  const getOrderItems = (order) => {
    return order.shipping?.order_items?.length
      ? order.shipping?.order_items
      : order.order_items;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {};

    if (phoneForCourierRef?.current?.value 
      && !validatePhoneForCourier(phoneForCourierRef.current.value)) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    payload.full_name = fullNameRef.current
      ? fullNameRef.current.value
      : contact.last_name;
    payload.phone_number = phoneNumberRef.current
      ? phoneNumberRef.current.value
      : contact.phone_number;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;
    payload.country = selectedCountry.value;
    payload.email = emailRef.current ? emailRef.current.value : contact.email;
    payload.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : contact.postal_code;
    payload.street = streetRef.current
      ? streetRef.current.value
      : contact.street;
    payload.city = cityRef.current ? cityRef.current.value : contact.city;
    payload.additional_info = aidditionalInformationRef.current
      ? aidditionalInformationRef.current.value
      : contact.additional_info;

    const response = await makeRequest(updateContact.bind(null, id, payload));

    if (response.data) {
      setSelectedCountry(() =>
        COUNTRIES.find((country) => country.value === response.data.country)
      );
      await getContactInfromation();

      addMessage("saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const handleMakeRodo = async (e) => {
    e.preventDefault();

    const response = await makeRequest(markAsRodoDecision.bind(null, id));

    if (response.data) {
      setMarkAsRodo(null);
      getContactInfromation();
    }
  };

  const getContactInfromation = async () => {
    setContact(() => null);

    const response = await makeRequest(getContactWithOrders.bind(null, id));
    if (response.data) {
      setSelectedCountry(() =>
        COUNTRIES.find((country) => country.value === response.data.country)
      );
      setContact(() => response.data);

      response.data.orders.forEach((order) => {
        let variants = "";

        order.order_items.forEach((item) => {
          if (variants.length > 0) {
            variants += ", " + item.product_variant;
          } else {
            variants = item.product_variant;
          }
        });

        order._variants = variants;
      });

      setOrders(() => response.data.orders);
    } else {
      navigate("/dashboard");
    }
  };

  const checkOnTheWay = (order) =>
    SHIPPING_ON_THE_WAY_STATUSES.includes(order?.shipping?.status); 

  const handleCheckAddOrder = () =>
    !orders?.some((order) => checkIsOrderEditable(order)) &&
    !orders?.some((order) => checkOnTheWay(order));

  const handleCheckAdditionalOrder = () =>
    !orders?.some((order) => checkIsOrderEditable(order)) &&
    orders?.some((order) => checkOnTheWay(order));

  useEffect(() => {
    getContactInfromation();
  }, [show, id]);

  return (
    <Wrapper>
      {hasUnfilledRequest(updateContact) ||
        hasUnfilledRequest(getContactWithOrders) ||
        (hasUnfilledRequest(markAsRodoDecision) && <Loading />)}
      {contact && (
        <div style={{ margin: "0 auto" }}>
          <button
            className={`btn btn-${copied ? "success" : "primary"}`}
            type="button"
            style={{ minWidth: "200px" }}
            onClick={(e) => handleCopyLinkForConsultant(e)}
          >
            {copied ? "Copied" : "Link For consultant"}
          </button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        {contact && (
          <>
            <form onSubmit={(e) => handleSave(e)}>
              <Card>
                <h3 style={{ margin: "10px 10px 20px 10px" }}>
                  <u>Contact:</u>
                </h3>
                <div>
                  <h5 style={{ textAlign: "center" }}>
                    Created at:
                    <h5 style={{ color: Colors.darkBlue }}>
                      {moment(contact.created_at).format("YYYY/MM/DD HH:mm")}
                    </h5>
                  </h5>
                </div>
                <Input
                  inputRef={fullNameRef}
                  name="Full name"
                  value={contact.full_name}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={orginalPhoneNUmberRef}
                  name="Orginal phone"
                  value={contact.orginal_phone_number}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={phoneNumberRef}
                  name="Phone"
                  value={contact.phone_number}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={phoneForCourierRef}
                  name="Phone for courier"
                  value={contact.phone_number_for_courier}
                  width={135}
                  inputWidth={180}
                />
                <SelectInput
                  name="Country"
                  selected={selectedCountry}
                  setSelected={setSelectedCountry}
                  options={COUNTRIES}
                  width={135}
                  selectWidth={180}
                />
                <Input
                  inputRef={emailRef}
                  name="E-mail"
                  value={contact.email}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={postalCodeRef}
                  name="Postal code"
                  value={contact.postal_code}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={streetRef}
                  name="Street"
                  value={contact.street}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={cityRef}
                  name="City"
                  value={contact.city}
                  width={135}
                  inputWidth={180}
                />
                <Input
                  inputRef={aidditionalInformationRef}
                  name="Additional info"
                  value={contact.additional_info}
                  width={135}
                  inputWidth={180}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "flex-end",
                    paddingRight: "18px",
                  }}
                >
                  <CustomButtonAdd
                    onClick={() => setShowCreateQuestion(true)}
                    defaultText="Ask a question"
                  />
                  <CustomButtonAdd
                    onClick={() => setShowNewTicket(true)}
                    defaultText={success ? "Success" : "To correct"}
                  />
                  <CustomButtonError
                    defaultText="Clear client"
                    onClick={() => setMarkAsRodo(true)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "10px 20px 30px",
                  }}
                >
                  <CustomButtonSave width={90} text="Save" />
                </div>
              </Card>
            </form>
          </>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "850px",
          }}
        >
          {orders && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ color: Colors.darkBlue }}>Orders</h3>
                {handleCheckAddOrder() && (
                  <CustomButtonAdd
                    text="Add"
                    onClick={(e) => setShowAdd(() => true)}
                  />
                )}
                {handleCheckAdditionalOrder() && (
                  <CustomButtonAdd
                    defaultText="additional +"
                    onClick={(e) => setShowAdditionalOrder(() => true)}
                  />
                )}
              </div>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>nr </th>
                    <th>Status</th>
                    <th> Created at</th>
                    <th> ConfimredBy</th>
                    <th>product</th>
                    <th>variants</th>
                    <th>value</th>
                    <th>shipping status</th>
                    <th style={{ width: "60px", textAlign: "center" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="queue">
                  {orders
                    .sort(
                      (a, b) =>
                        new Date(b.created_at).getDate() -
                        new Date(a.created_at).getDate()
                    )
                    .map((order, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{order.status}</td>
                          <td>
                            {moment(order.created_at).format(
                              "YYYY/MM/DD HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {order._confirmed_by?.name
                              ? order._confirmed_by.name +
                                " " +
                                order._confirmed_by.surname
                              : "-----"}
                          </td>
                          <td>{order._product.name}</td>
                          <td>
                            {getOrderItems(order).length
                              ? getOrderItems(order)
                                  .map((item) => item.product_variant)
                                  .join(", ")
                              : "--------"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {getOrderItems(order).length
                              ? getOrderItems(order)
                                  .reduce((prev, next) => prev + next.price, 0)
                                  ?.toFixed(2)
                              : "------"}
                          </td>
                          <td>
                            {order.shipping?.status
                              ? order.shipping.status
                              : "-----"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <EditItem
                              link={`/dashboard/order/${order._id}`}
                              width={60}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "20px",
              justifyContent: "right",
            }}
          >
            {contact?._changes?.length > 0 && (
              <ChangeLog changes={contact._changes} />
            )}
            {contact?._calling_information?.length > 0 && (
              <CallingInfo callings={contact._calling_information} />
            )}
          </div>
        </div>
      </div>
      {markAsRodo && (
        <PopUp setShow={setMarkAsRodo}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <h3 style={{ color: Colors.darkBlue }}>
              Are you sure to{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>DELETE</span>{" "}
              client data?
            </h3>
            <div
              style={{
                display: "flex",
                gap: "30px",
                margin: "20px",
                justifyContent: "center",
              }}
            >
              <button
                className="btn btn-success"
                onClick={() => setMarkAsRodo(null)}
              >
                No
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => handleMakeRodo(e)}
              >
                Yes
              </button>
            </div>
          </div>
        </PopUp>
      )}
      {show && contact && (
        <OrderCreate setShow={setShowAdd} contact={contact} />
      )}
      {hasUnfilledRequest(getContactWithOrders) && <Loading />}
      {showNewTicket && (
        <CreateCorrectionTicket
          setShow={setShowNewTicket}
          setSuccess={setSuccess}
        />
      )}
      {showCreateQuestion && (
        <CreateQuestionAdmin
          contact={contact}
          setShowQuestionTicket={setShowCreateQuestion}
          addMessage={addMessage}
        />
      )}
      {showAdditionalOrder && (
        <AdditionalOrderCreate
          setShow={setShowAdditionalOrder}
          orderById={orders?.find((order) => checkOnTheWay(order))?._id}
          reloadFn={getContactInfromation}
        />
      )}
    </Wrapper>
  );
};

export default Contact;
