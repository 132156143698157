import Icon from "@/common/components/Icon";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { sumByField } from "@/common/functions/sumByField";
import moment from "moment";
import { getClaimColor } from "./getClaimColor";
import { findClaimStatusConfig } from "./findClaimConfigs";
import { findCalimProvider } from "./findCalimProvider";
import { FlexCell } from "../components/claimTable/ClaimTable.styled";
import { CLAIM_STATUSES } from "@/common/constants/claims";

export const getNotPaidRaws = (validClaims, setSelectedClaim, setAddFile) => validClaims.map((claim, index) => {
  return (
    <tr
      style={{
        backgroundColor: getClaimColor(claim),
        fontWeight: "bold"
      }}
      key={claim._id}
    >
      <td>{index + 1}</td>
      <td>{claim._contact.country.toUpperCase()}</td>
      <td>{claim._contact.full_name}</td>
      <td>{claim._contact.email}</td>
      <td>
        {formatToDateTamplate(claim._order.shipping.status_delivered_at
          || claim._order.shipping.status_delivered_no_payment_at)}
      </td>
      <td>{formatToDateTamplate(claim.created_at)}</td>
      <td>{claim.type === "refund"
        ? formatToDateTamplate(moment(claim.created_at).add(14, 'days'))
        : "complaint"}
      </td>
      <td>{sumByField(claim.order_items, 'price')} {claim._order.shipping?.currency || claim._order.currency}</td>
      <td>{findCalimProvider(claim)}</td>
      <td>{findClaimStatusConfig(claim.status)?.label}</td>
      <td>
        <FlexCell>
          <Icon name="fa fa-edit" onClick={() => setSelectedClaim(claim)} />
          {[CLAIM_STATUSES.CHECK_IN_PROGRESS.value, CLAIM_STATUSES.TO_CHECK.value].includes(claim.status) && (
            <Icon onClick={() => setAddFile(claim._id)} name="fa fa-plus" />
          )}
        </FlexCell>
      </td>
    </tr>
  )
});
