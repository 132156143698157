import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import { SHIPPING_NOT_SEND_STATUSES } from "@/common/constants/shipping";
import { NOT_EDITABLE_STATUSES } from "@/common/constants/statuses";
import { NOT_FINISHED_QUEUE_STATUSES } from "@/common/constants/queue";
import MailsTimeline from "@/components/mailsTimeline/MailsTimeline";
import ShippingTimeline from "@/components/shippingTimeline/ShippingTimeline";
import { TableItemHover } from "@/common/styles/Hover";
import Icon from "@/common/components/Icon";
import { getOrderInvoiceColor } from "@/common/functions/getOrderInvoiceColor";
import { addMailConditionalStyles } from "@/common/functions/addMailConditionalStyles";
import { addShippingConditionalStyles } from "@/common/functions/addShippingConditionalStyles";

const Title = styled.h2`
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const OrderDisplay = ({ translation, orders, setOrder }) => {
  const [mailsTimeline, setMailsTimeline] = useState();
  const [shippingTimeline, setShippingTimeline] = useState();

  const checkIsOrderEditable = (order) =>
    NOT_FINISHED_QUEUE_STATUSES.includes(order?.status) ||
    ((!order?.shipping ||
      SHIPPING_NOT_SEND_STATUSES.includes(order?.shipping?.status)) &&
      !NOT_EDITABLE_STATUSES.includes(order?.status));

  return (
    <div style={{ minHeight: "230px" }}>
      <Title>{translation.orders}</Title>
      <table className="styled-table">
        <thead>
          <tr>
            <th>{translation.no}</th>
            <th>{translation.status}</th>
            <th>{translation.currency}</th>
            <th style={{ minWidth: "120px" }}>{translation.product}</th>
            <th>{translation.variants}</th>
            <th>{translation.value}</th>
            <th style={{ minWidth: "150px" }}>{translation.shipping_status}</th>
            <th>{translation.timeline}</th>
            <th>{translation.edit}</th>
          </tr>
        </thead>
        <tbody className="queue">
          {orders.map((order, i) => (
            <tr>
              <td>{i + 1}.</td>
              <td>{order.status}</td>
              <td>{order.currency}</td>
              <td>{order._product?.name || "-----"}</td>
              <td>
                {order.order_items?.map((e) => e.product_variant).join(", ") ||
                  "-----"}
              </td>
              <td>
                {order.order_items?.reduce(
                  (prev, next) => prev + next.price,
                  0
                ) || "-----"}
              </td>
              <td>{order.shipping?.status || "-----"}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <TableItemHover label={translation.invoice_status}>
                    <Icon
                      color={getOrderInvoiceColor(order?._invoice)}
                      cursor="default"
                      name="fa fa-file-invoice"
                    />
                  </TableItemHover>
                  <TableItemHover label={translation.mail_history}>
                    <Icon
                      onClick={() => setMailsTimeline(order?._mails)}
                      name="fa fa-envelope"
                      {...addMailConditionalStyles(order)}
                    />
                  </TableItemHover>
                  <TableItemHover label={translation.shipping_history}>
                    <Icon
                      onClick={() => setShippingTimeline(order?.shipping)}
                      name="fa fa-truck"
                      {...addShippingConditionalStyles(order)}
                    />
                  </TableItemHover>
                </div>
              </td>
              <td>
                {checkIsOrderEditable(order) ? (
                  <i
                    className="fa-solid fa-edit animation-scale"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOrder(() => order)}
                  />
                ) : (
                  "-----"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {mailsTimeline?.length && (
        <MailsTimeline setShow={setMailsTimeline} mails={mailsTimeline} />
      )}
      {shippingTimeline && (
        <ShippingTimeline
          setShow={setShippingTimeline}
          shipping={shippingTimeline}
        />
      )}
    </div>
  );
};

export default OrderDisplay;
