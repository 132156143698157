import { formatMonthName, formatToDateTamplate } from "@/common/functions/dateFormater";
import { generateExcelSheetBuffer } from "@/common/functions/getExcelBuffer";
import { FAILD_CLAIMS_HEADERS_MAP } from "../constants/headers";
import moment from "moment";

export const getFailedTransfersReportExcel = async (claimsWithSomeFailedTransfer, month) => {
	const sheetName = `EOU_${formatMonthName(month)}_nieudane_transakcje`;

	const claimsWithFailedTransferOnly = JSON.parse(JSON.stringify(claimsWithSomeFailedTransfer))
		.map(item => {
			item.transfers = item.transfers.filter(t => t.failed_at);

			return item
		});

	let idx = 1;
	const excelData = claimsWithFailedTransferOnly
		.map((claim) => claim.transfers
			.map((transfer) => {
				const fullClaim = claimsWithSomeFailedTransfer.find(c => c._id === claim._id)
				const nextTransfer = fullClaim.transfers.find(t => moment(t.initiated_at).isAfter(transfer.failed_at));

				return ({
					[FAILD_CLAIMS_HEADERS_MAP.IDX]: idx++,
					[FAILD_CLAIMS_HEADERS_MAP.MARKET]: claim.market.name,
					[FAILD_CLAIMS_HEADERS_MAP.NAME]: transfer.full_name,
					[FAILD_CLAIMS_HEADERS_MAP.FIRST_TRANSFER_AT]: formatToDateTamplate(transfer.initiated_at),
					[FAILD_CLAIMS_HEADERS_MAP.AMOUNT]: `${transfer.amount} ${transfer.currency}`,
					[FAILD_CLAIMS_HEADERS_MAP.PROVIDER]: transfer.provider,
					[FAILD_CLAIMS_HEADERS_MAP.BACK_DATE]: formatToDateTamplate(transfer.failed_at),
					[FAILD_CLAIMS_HEADERS_MAP.NEXT_TRANSFER_AT]: formatToDateTamplate(nextTransfer.initiated_at),
					[FAILD_CLAIMS_HEADERS_MAP.NEXT_AMOUNT]: `${nextTransfer.amount} ${transfer.currency}`,
					[FAILD_CLAIMS_HEADERS_MAP.NEXT_NAME]: nextTransfer.full_name !== transfer.full_name ? nextTransfer.full_name : '----',
				})
			}))
		.flat();

	const buffer = await generateExcelSheetBuffer(excelData, sheetName);

	return buffer;
}