import React from "react";
import styled from "styled-components";
import { Colors } from "../../colors/colors";

const Button = styled.button`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  margin-bottom: -30px;

  font-size: 14px;
  font-weight: bold;
  padding: 5px;

  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  background-color: transparent;
  border-radius: 10px;

  &:is(:hover) {
    cursor: pointer;
    color: white;
    border: 1px solid ${(props) => props.color};
    background-color: ${(props) => props.color};
    transform: scale(1.1);
  }

  &:disabled {
    color: ${Colors.darkGray2};
    border: 1px solid ${Colors.darkGray2};

    &:hover {
      cursor: default;
      color: ${Colors.darkGray2};
      border: 1px solid ${Colors.darkGray2};
      transform: none;
      background-color: transparent;
    }
  }
`;

const CustomButtonSave = ({
  text,
  width,
  height,
  styles = {},
  disabled = false,
  color = Colors.red,
}) => {
  return (
    <Button
      color={color}
      type="submit"
      disabled={disabled}
      style={{ width: `${width}px`, height: `${height}px`, ...styles }}
    >
      {text}
    </Button>
  );
};

export default CustomButtonSave;
