import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import { getLastTicketNotification } from "./getLastTicketNotification";
import { getMessageTime } from "./getMessageTime";

export const sortTickets = (tickets) => {
  return tickets
    ?.sort(
      (ticketA, ticketB) =>
        getMessageTime(getLastTicketNotification(ticketB)) -
        getMessageTime(getLastTicketNotification(ticketA))
    )
    .sort((ticketA, ticketB) => {
      if (ticketA.prioritized && !ticketB.prioritized) {
        return -1;
      }

      if (ticketB.prioritized && !ticketA.prioritized) {
        return 1;
      }

      return 0;
    })
    .sort((ticketA, ticketB) => {
      const aWeight =
        QUESTION_TICKET_STATUS_OPTIONS.find((o) => o.value === ticketA.status)
          ?.status_weight || 0;
      const bWeight =
        QUESTION_TICKET_STATUS_OPTIONS.find((o) => o.value === ticketB.status)
          ?.status_weight || 0;

      return bWeight - aWeight;
    });
};
