import React from "react";
import { formatToDateTimeFormaterTemplate } from "@/common/functions/dateFormater";
import { Colors } from "@/common/colors/colors";
import { IconsWrapper } from "@/components/admin/settings/test/styles/Test.styles";

const CurrentWeekScoreTable = ({
  data,
  handleGetRecording,
  handleGeToRate,
  setShowTicket,
  handleGetTranscription,
}) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Consultant</th>
          <th>Calling start at</th>
          <th>Number</th>
          <th>Client Full name</th>
          <th>Recording</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((elemnet, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{elemnet?.consultant?.username}</td>
              <td>{formatToDateTimeFormaterTemplate(elemnet.created_at)}</td>
              <td>{elemnet.type === "incoming" ? elemnet.from : elemnet.to}</td>
              <td>{elemnet?.contact?.full_name || "------"}</td>
              <td>
                <IconsWrapper>
                  <i
                    className="fa fa-ear-listen animation-scale"
                    style={{ cursor: "pointer", color: Colors.red }}
                    onClick={(e) => handleGetRecording(e, elemnet)}
                  />
                  <button
                    disabled={elemnet.listeningScoring}
                    onClick={(e) => handleGeToRate(e, elemnet)}
                    style={{
                      border: "none",
                      margin: "0",
                      padding: "0",
                      background: "transparent",
                    }}
                  >
                    <i
                      className="fa-solid fa-list-check animation-scale"
                      style={{ cursor: "pointer" }}
                    />
                  </button>
                  <button
                    disabled={!elemnet.listeningScoring}
                    onClick={(e) => setShowTicket(elemnet.listeningScoring)}
                    style={{
                      border: "none",
                      margin: "0",
                      padding: "0",
                      background: "transparent",
                    }}
                  >
                    <i
                      className="fa-solid fa-plus animation-scale"
                      style={{ cursor: "pointer" }}
                    />
                  </button>
                  <button
                    onClick={() => handleGetTranscription(elemnet)}
                    style={{
                      border: "none",
                      margin: "0",
                      padding: "0",
                      background: "transparent",
                    }}
                  >
                    <i
                      className="fa fa-scroll animation-scale"
                      style={{ cursor: "pointer", color: Colors.darkBlue }}
                    />
                  </button>
                </IconsWrapper>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CurrentWeekScoreTable;
