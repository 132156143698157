import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const InvoiceConfigTable = ({ invoiceConfigs, setInvoiceConfig }) => {
  return (
    <Table
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(invoiceConfigs, setInvoiceConfig)}
    />
  );
};

export default InvoiceConfigTable;
