import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

export const createKey = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/key/create", payload);

    return { data: data.data };
  });

export const updateKey = (payload) =>
  errorHandler(async () => {
    const data = await api.patch("/key/update", payload);

    return { data: data.data };
  });

export const getKeys = () =>
  errorHandler(async () => {
    const data = await api.get("/key/get-all");

    return { data: data.data };
  });

export const getKeysByType = (type) =>
  errorHandler(async () => {
    const data = await api.get(`/key/${type}`);

    return { data: data.data };
  });
