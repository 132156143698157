import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  gap: 40px;
`;

export const WrapperWithScroll = styled.div`
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  min-height: 80vh;
  gap: 30px;
`;
