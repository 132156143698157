import Table from "@/common/components/Table";
import React from "react";
import ValidInvoiceEdit from "./components/validInvoiceEdit/ValidInvoiceEdit";
import { getTableConfig } from "./helpers/getTableConfig";
import { useRequestsContext } from "@/common/hooks/requestHook";
import CorrectionInvoiceEdit from "./components/correctionInvoiceEdit/CorrectionInvoiceEdit";
import { getFileById } from "@/API/repositories/storedDocument";
import userManager from "@/API/userManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const RESTRICTED_ROLES = ["SUPPORT"];

const ValidInvoicesTable = ({
  tickets,
  handleSearch,
  setSelectedTicket,
  selectedTicket,
  selectedStatus,
  addMessage,
}) => {
  const { makeRequest } = useRequestsContext();
  const { commonData: { markets } } = useCommonDataContext();
  const isRestricted = RESTRICTED_ROLES.includes(userManager.getUser().role);

  const handleDownloadFile = async (ticket) => {
    const response = await makeRequest(
      getFileById.bind(null, ticket._stored_document._id)
    );

    if (response.data) {
      response.data.name =
        response.data.name.split("_")[1] || response.data.name;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const tableConfig = getTableConfig({
    tickets,
    setSelectedTicket,
    handleDownloadFile,
    markets,
    isRestricted,
  });

  return (
    <>
      <Table
        className="styled-table"
        headers={tableConfig[selectedStatus.value].headers}
        raws={tableConfig[selectedStatus.value].raws}
      />
      {selectedTicket?.valid_invoice?.invoice_type === "sales" && (
        <ValidInvoiceEdit
          addMessage={addMessage}
          handleSearch={handleSearch}
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
        />
      )}
      {selectedTicket?.valid_invoice?.invoice_type === "correction" && (
        <CorrectionInvoiceEdit
          addMessage={addMessage}
          setSelectedTicket={setSelectedTicket}
          selectedTicket={selectedTicket}
          handleSearch={handleSearch}
        />
      )}
    </>
  );
};

export default ValidInvoicesTable;
