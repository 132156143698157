import React from "react";
import { useNavigate } from "react-router-dom";
import userManager from "../../API/userManager";
import Api from "../../API/api";
import styled from "styled-components";
import { Colors } from "../colors/colors";

const { tokenManager } = Api;

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LogoutButton = styled.i`
  color: ${Colors.red};
  font-size: 16px;
`;

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    userManager.removeUser();
    tokenManager.removeToken();
    navigate("/login");
  };
  return (
    <Wrapper onClick={() => handleLogout()} className="animation-scale">
      <LogoutButton className="fa fa-right-from-bracket" />
    </Wrapper>
  );
};

export default Logout;
