export const HEADERS = [
  "No.",
  "Suspected",
  "Reporter",
  "Contact name",
  "Contact email",
  "Contact phone number",
  "Result",
  "Created at",
];
