import React from "react";
import { Colors } from "../../../common/colors/colors";

const CountConsultantSum = ({ data, monthWorkingData, workingDays }) => {
  return (
    <tr>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.attendance_at_work, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.holiday_leave, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + (next.special_leave || 0), 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.sick_leave, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.leave_on_reqests, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.break_during_the_day, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.excused_unpaid_leave, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.absence_without_excuse, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce((prev, next) => prev + next.manager_hours, 0)}h
        </strong>
      </td>
      <td>
        <strong>
          {data?.reduce(
            (prev, next) =>
              prev +
              ((next.attendance_at_work || 0) +
                (next.holiday_leave || 0) +
                (next.sick_leave || 0) +
                (next.leave_on_reqests || 0) +
                (next.manager_hours || 0) +
                (next.special_leave || 0)) +
              (next.excused_unpaid_leave || 0),
            0
          )}
          h / {(monthWorkingData?.contract_hours || 8) * workingDays}h
        </strong>
      </td>
    </tr>
  );
};

export default CountConsultantSum;
