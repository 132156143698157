import { COLORS_ARRAY } from "@/common/colors/colorsArray";

export const mapArchivesToChart = (archives) => {
  const map = new Map();

  archives.forEach((element) => {
    if (!map.has(element.market.name)) {
      return map.set(element.market.name, 1);
    }

    map.set(element.market.name, map.get(element.market.name) + 1);
  });

  return Array.from(map).map(([key, value], i) => ({
    label: key,
    data: [value],
    backgroundColor: COLORS_ARRAY[i],
  }));
};
