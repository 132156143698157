import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(95vh - 80px);
  gap: 30px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const RightFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
`;
