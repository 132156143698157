export const validatePhoneForCourier = (phone) => {
	const regex = /^[0-9\s-]+$/;
	const isValid = regex.test(phone);
	
	if (!isValid) {
		return false;
	}

	return true;
};
