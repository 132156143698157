import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  width: 1000px;
`;

const FreeDaysTable = ({ freeDays, setSelectedFreeDay }) => {
  return (
    <Wrapper>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th colSpan={4}>Free Days</th>
          </tr>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="queue">
          {freeDays && freeDays[0] ? (
            <>
              {freeDays.map((freeDay, i) => (
                <tr key={freeDay.date}>
                  <td>{freeDay.id}.</td>
                  <td>{freeDay.name}</td>
                  <td>{freeDay.date}</td>
                  <td>
                    <i
                      className="fa fa-edit animation-scale"
                      onClick={() => setSelectedFreeDay(freeDay)}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={4}>There is no Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default FreeDaysTable;
