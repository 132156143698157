import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

export const Wrapper = styled.form`
  display: flex;
  gap: 10px;
  align-items: center;
`;
