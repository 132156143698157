import React, { useState } from "react";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { removeDwUser } from "@/API/repositories/tickets";
import {
  DwListItem,
  DwListItemWrapper,
} from "@/components/admin/infobar/components/ticket/components/conversationGroup/ConversationGroup.styled";
import { getUserName } from "@/components/consultant/infobar/ticket/helpers/getUsername";
import { getUserById } from "@/components/consultant/infobar/ticket/helpers/getUserById";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const DwItem = ({ user, loadData, selectedTicket }) => {
  const [dwRemoving, setDwRemoving] = useState();

  const { makeRequest } = useRequestsContext();
  const {
    commonData: { users },
  } = useCommonDataContext();

  const handleRemoveDw = async (userToRemove) => {
    setDwRemoving((prev) => [...(prev || []), userToRemove]);

    const response = await makeRequest(
      removeDwUser.bind(null, selectedTicket._id, userToRemove.value)
    );

    if (response.data) {
      await loadData();
    }

    setDwRemoving((prev) =>
      prev?.filter((user) => user.value !== userToRemove.value)
    );
  };

  return (
    <DwListItemWrapper>
      {getUserName(getUserById(user.value, users))}
      <DwListItem
        onClick={() => handleRemoveDw(user)}
        className="animation-scale"
      >
        <i
          className={
            dwRemoving?.find(
              (removingUser) => removingUser.value === user.value
            )
              ? "fa-solid fa-spinner fa-spin"
              : "fa fa-x"
          }
        />
      </DwListItem>
    </DwListItemWrapper>
  );
};

export default DwItem;
