import { Colors } from "chart.js";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CustomSkeleton = ({
  count = 10,
  width = 300,
  height = 5,
  baseColor = Colors.lightlightBlue,
  highlightColor = Colors.lightBlue,
}) => {
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <Skeleton count={count} width={width} height={height} />
    </SkeletonTheme>
  );
};

export default CustomSkeleton;
