import { calcSalaryForOne } from "../../../helpers/calcSalaryForOne";
import { calcTotalSalary } from "../../../helpers/calcTotalSalary";
import { calcSalary } from "../../../helpers/calcSalary";

export const getRaws = (payment, consultantResults) => [
  ...payment.data
    .sort((a, b) => a.price - b.price)
    .map((score, i) => (
      <tr key={`${score}_${i}`}>
        <td>
          {score.price} {payment.salary_currency}
        </td>
        <td>
          {calcSalaryForOne(score, consultantResults._month_working_info) || 0}{" "}
          {payment.salary_currency}
        </td>
        <td>{score.orders_count} </td>
        <td>{score.delivered_count} </td>
        <td>
          {" "}
          {calcSalary(
            score.big_items_count,
            score.small_items_count,
            consultantResults._month_working_info
          )}{" "}
          {payment.salary_currency}
        </td>
      </tr>
    )),
  <tr>
    <td>Total:</td>
    <td></td>
    <td></td>
    <td></td>
    <td>
      {calcTotalSalary(payment.data, consultantResults._month_working_info)}{" "}
      {payment.salary_currency}
    </td>
  </tr>,
];
