import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import PopUp from "../../../../common/components/PopUp";
import { useAdminDashboardContext } from "../../../../common/hooks/adminDashboardHook";
import {
  DASHBOARD_VIEW_SETTINGS,
  DASHBOARD_REFRESH_SETTINGS,
  DASHBOARD_OLD_TICKETS_LIMIT_SETTINGS,
  DASHBOARD_OLD_TICKETS_SETTINGS,
  DASHBOARD_NOTIFICATIONS,
} from "../../../../common/constants/dashboardSettings";
import Input from "../../../../common/components/Input";
import userManager from "../../../../API/userManager";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";

const CHARTS_LIMIT = 3;

const ViewSettingsTitle = styled.h3`
  color: ${Colors.darkBlue};
  margin: -20px 0px 40px 0;
`;

const DashboardSettingsWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
`;

const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function SettingsForm() {
  let {
    setShowDashboardSettings,
    settingsRef,
    handleUpdateDashboardSettings,
    setDashboardSettings,
    dashboardSettings,
  } = useAdminDashboardContext();

  const { messages, removeMessage, addMessage } = useMessageQueue();

  const handleSettingsChanged = (value) => {
    let visibleViews = settingsRef.current.value.visible_views || [];

    if (visibleViews.map((visibleView) => visibleView.name).includes(value)) {
      visibleViews = visibleViews.filter((view) => view.name !== value);
    } else {
      if (visibleViews.length >= CHARTS_LIMIT) {
        return addMessage("You can only have 3 views visible at a time", "error");
      }

      visibleViews.push({ name: value });
    }

    settingsRef.current.value.visible_views = visibleViews;
    settingsRef = JSON.parse(JSON.stringify(settingsRef));
    setDashboardSettings(() => settingsRef.current.value);
  };

  const handleRefreshTimeChange = (e) => {
    settingsRef.current.value.refresh_time_seconds = e.target.value;
  };

  const handleOldTicketsLimitChange = (e) => {
    settingsRef.current.value.old_tickets_limit = e.target.value;
  };

  const handleShowOldTicketsChange = (e) => {
    settingsRef.current.value.show_old_tickets = e.target.checked;
  };

  const handleNotificationChange = (e) => {
    settingsRef.current.value.notifications = e.target.checked;
  };

  return (
    <PopUp setShow={setShowDashboardSettings}>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <ViewSettingsTitle>Dashboard settings</ViewSettingsTitle>
      <form
        onSubmit={(e) =>
          handleUpdateDashboardSettings(e, settingsRef.current.value)
        }
      >
        <DashboardSettingsWrapper>
          {DASHBOARD_VIEW_SETTINGS.filter((view) =>
            userManager.checkAccess(view.access)
          ).map((view) => (
            <Input
              key={view.value}
              name={view.label}
              id={view.value}
              width={180}
              type="checkbox"
              color={Colors.darkBlue}
              height={20}
              inputWidth={20}
              onChange={() => handleSettingsChanged(view.value)}
              isChecked={dashboardSettings.visible_views
                .map((visible_view) => visible_view.name)
                .includes(view.value)}
            />
          ))}

          <Input
            id={DASHBOARD_REFRESH_SETTINGS.value}
            type="number"
            inputWidth={80}
            width={180}
            min={60}
            color={Colors.darkBlue}
            onChange={(e) => handleRefreshTimeChange(e)}
            name={DASHBOARD_REFRESH_SETTINGS.label}
            value={settingsRef.current.value.refresh_time_seconds}
          />
        </DashboardSettingsWrapper>

        <Input
          id={DASHBOARD_NOTIFICATIONS.value}
          width={180}
          type="checkbox"
          color={Colors.darkBlue}
          height={20}
          inputWidth={20}
          onChange={(e) => handleNotificationChange(e)}
          name={DASHBOARD_NOTIFICATIONS.label}
          checked={settingsRef.current.value.notifications}
        />

        <Input
          id={DASHBOARD_OLD_TICKETS_SETTINGS.value}
          width={180}
          type="checkbox"
          color={Colors.darkBlue}
          height={20}
          inputWidth={20}
          onChange={(e) => handleShowOldTicketsChange(e)}
          name={DASHBOARD_OLD_TICKETS_SETTINGS.label}
          checked={settingsRef.current.value.show_old_tickets}
        />

        <Input
          id={DASHBOARD_OLD_TICKETS_LIMIT_SETTINGS.value}
          type="number"
          inputWidth={80}
          width={180}
          min={1}
          max={30}
          color={Colors.darkBlue}
          onChange={(e) => handleOldTicketsLimitChange(e)}
          name={DASHBOARD_OLD_TICKETS_LIMIT_SETTINGS.label}
          value={settingsRef.current.value.old_tickets_limit}
        />

        <Input
          id={DASHBOARD_REFRESH_SETTINGS.value}
          type="number"
          inputWidth={80}
          width={180}
          min={60}
          color={Colors.darkBlue}
          onChange={(e) => handleRefreshTimeChange(e)}
          name={DASHBOARD_REFRESH_SETTINGS.label}
          value={settingsRef.current.value.refresh_time_seconds}
          height={35}
        />
        <FlexRight>
          <button type="submit" className="btn btn-warning animation-scale">
            Save
          </button>
        </FlexRight>
      </form>
    </PopUp>
  );
}
