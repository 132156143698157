import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import React, { useRef, useState } from "react";
import AddAddsAccount from "./components/addAddsAccount/AddAddsAccount";
import {
  AddAccountItem,
  AddsAccountsList,
  ButtonWrapper,
  TextArea,
} from "./MetaAccountForm.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createMetaAccount, updateMetaAccount } from "@/API/repositories/meta";

const MetaAccountForm = ({ setShow, addMessage, loadData, account }) => {
  const [addsAccounts, setAddsAccounts] = useState(account?._add_account);
  const [isAddAddsAccountsOpen, setIsAddAddsAccountsOpen] = useState();

  const emailRef = useRef({ value: account?.email });
  const accessTokenRef = useRef();

  const { makeRequest } = useRequestsContext();

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.email = emailRef.current.value;
    payload.access_token = accessTokenRef.current.value;
    payload.addsAccounts = addsAccounts;

    const response = account
      ? await makeRequest(updateMetaAccount.bind(null, payload, account._id))
      : await makeRequest(createMetaAccount.bind(null, payload));

    if (response?.data) {
      setShow(() => false);
      loadData();
      return addMessage("Saved", "success");
    }

    addMessage("Something went wrong!", "error");
  };

  const handleRemoveAddsAccount = (addsAccount) => {
    setAddsAccounts((prev) =>
      prev.filter((acc) => acc.add_account_id !== addsAccount.add_account_id)
    );
  };

  return (
    <PopUp setShow={setShow}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Input
          inputRef={emailRef}
          value={account?.email}
          name="Email"
          type="email"
          color={Colors.darkBlue}
          width={70}
          required
          placeholder="Type email..."
        />
        <TextArea
          ref={accessTokenRef}
          defaultValue={account?.access_token}
          rows={5}
          cols={40}
          color={Colors.darkBlue}
          placeholder="Access token..."
        />
        <CustomButtonAdd
          style={{ margin: "10px 0" }}
          onClick={() => setIsAddAddsAccountsOpen(() => true)}
          text="add"
        />
        {!!addsAccounts?.length && (
          <AddsAccountsList>
            {addsAccounts.map((addsAccount) => (
              <AddAccountItem
                onClick={() => handleRemoveAddsAccount(addsAccount)}
              >
                {addsAccount.name + " - " + addsAccount.add_account_id}
              </AddAccountItem>
            ))}
          </AddsAccountsList>
        )}
        <ButtonWrapper>
          <CustomButtonSave text="Save" />
        </ButtonWrapper>
      </form>
      {isAddAddsAccountsOpen && (
        <AddAddsAccount
          setAddsAccounts={setAddsAccounts}
          setIsAddAddsAccountsOpen={setIsAddAddsAccountsOpen}
        />
      )}
    </PopUp>
  );
};

export default MetaAccountForm;
