export const handleGetLastOutgoingCall = (element) => {
  const filterByStartedAt = element.sort(
    (a, b) =>
      new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
  );

  const firstOutgoing = filterByStartedAt.find((a) => a.type === "outgoing");
  const firstIncoming = filterByStartedAt.find((a) => a.type === "incoming");

  if (firstOutgoing && firstIncoming) {
    const diff =
      new Date(firstOutgoing.started_at).getTime() -
      new Date(firstIncoming.started_at).getTime();

    if (diff > 0) {
      return firstOutgoing;
    }
  }

  return null;
};
