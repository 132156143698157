import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./DynamicMailContent.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { createDynamicMailContent, getAllDynamicMailContent, updateDynamicMailContent } from "@/API/repositories/dynamicMailContent";
import DynamicMailContentTable from "./components/dynamicMailContentTable/DynamicMailContentTable";
import DynamicMailContentForm from "./components/dynamicMailContentForm/DynamicMailContentForm";

const DynamicMailContent = () => {
  const [dynamicMailContent, setDynamicMailContent] = useState();
  const [isDynamicMailContentFormOpen, setIsDynamicMailContentFormOpen] = useState();
  const [selectedDynamicContent, setSelectedDynamicContent] = useState();

  const { messages, removeMessage, addMessage } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getAllDynamicMailContent);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDynamicMailContent(() => response.data);
  };

  const handleSetDynamicMailContentEdit = (content) => {
    setSelectedDynamicContent(() => content);
    setIsDynamicMailContentFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsDynamicMailContentFormOpen(() => false);
    setSelectedDynamicContent(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getAllDynamicMailContent,
        createDynamicMailContent,
        updateDynamicMailContent
      ) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <ContentWrapper>
        <CustomButtonAdd
          style={{ height: "fit-content" }}
          defaultText="Add dynamic mail content"
          onClick={() => setIsDynamicMailContentFormOpen(true)}
        />
      </ContentWrapper>
      {isDynamicMailContentFormOpen && (
        <DynamicMailContentForm
          selectedDynamicContent={selectedDynamicContent}
          setShow={handleCloseForm}
          addMessage={addMessage}
          reload={loadData}
        />
      )}
      {!!dynamicMailContent?.length && (
        <DynamicMailContentTable
          handleSetDynamicMailContentEdit={handleSetDynamicMailContentEdit}
          dynamicMailContent={dynamicMailContent}
        />
      )}
    </PageWrapper>
  );
};

export default DynamicMailContent;
