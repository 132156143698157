import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const Form = styled.form``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 40px;
`;

export const Icon = styled.i`
  color: ${Colors.darkBlue};
  cursor: pointer;
`;

export const DefaultInputsWrapper = styled.div`
  display: flex;
`;

export const AutoInputsWrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  visibility: ${(props) => props.visibility};
`;

export const Hover = styled.div`
  position: relative;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      display: flex;
      width: 100px;
      z-index: 2;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 12px;
      top: 0px;
      right: 80px;
      transform: translateX(50%);
      border-radius: 10px;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;
