import { formatToDateTamplate } from "@/common/functions/dateFormater";
import * as MomentRange from "moment-range";
import moment from "moment";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import { findUserById } from "@/pages/admin/gamificationNew/gamificationStatsTable/helpers/findUserById";
const momentRange = MomentRange.extendMoment(moment);

export const compareVacationDaysToSelectedDays = ({
  fromRef,
  toRef,
  markets,
  users,
  userId,
}) => {
  const fromDate = new Date(fromRef.current.value);
  const toDate = new Date(toRef.current.value);

  const userMarket = findMarketByContractType(
    markets,
    findUserById(userId, users)
  );

  const freeMarketDates = userMarket.free_days?.map((freeDay) =>
    formatToDateTamplate(freeDay.date)
  );

  const days = Array.from(momentRange.range(fromDate, toDate).by("day"));

  return days.reduce((acc, momentDate) => {
    const date = new Date(momentDate.toDate());

    if (
      date.getDay() === 6 ||
      date.getDay() === 0 ||
      freeMarketDates.includes(formatToDateTamplate(date))
    ) {
      return acc;
    }

    acc++;

    return acc;
  }, 0);
};
