import React, { useDeferredValue, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 30px 0px 0px 10px;
  font-size: 14px;
  overflow-x: auto;

  td {
    padding: 0px 10px;
    margin-bottom: 1px solid grey;
  }
  th {
    padding: 0px 10px;
  }
`;

const DataTable = ({ rows, columns, howManyToShow = 50 }) => {
  const [page, setPage] = useState(1);
  const [currentRows, setCurrentRows] = useState([]);
  const rowsToDisplay = useDeferredValue(currentRows);
  const [sortInfo, setSortInfo] = useState({});
  const pageRef = useRef();

  const navigate = useNavigate();

  const changePage = (e, number) => {
    e.preventDefault();
    setPage((prev) => prev + number);
  };

  const handleSort = (e, index) => {
    e.preventDefault();
    const decision = new Object();

    if (sortInfo.isAscending === true && index === sortInfo.index) {
      rows = rows.sort((a, b) => (!(a[index] < b[index]) ? 1 : -1));
      decision.isAscending = false;
    } else {
      rows = rows.sort((a, b) => (a[index] < b[index] ? 1 : -1));
      decision.isAscending = true;
    }

    decision.index = index;
    setSortInfo(() => decision);
    setCurrentRows(
      rows.slice((page - 1) * howManyToShow, page * howManyToShow)
    );
  };

  useEffect(() => {}, [rows]);

  useEffect(() => {
    setCurrentRows(
      rows.slice((page - 1) * howManyToShow, page * howManyToShow)
    );
  }, [page]);

  const handleSetPage = (e) => {
    e.preventDefault();
    let toSetPage = parseInt(e.target.value);
    if (toSetPage < 1) {
      toSetPage = 1;
    } else if (toSetPage > Math.ceil(rows.length / howManyToShow)) {
      toSetPage = Math.ceil(rows.length / howManyToShow);
    }

    setPage(() => toSetPage);
  };

  return (
    <Wrapper>
      {rows ? (
        <div>
          <table style={{ width: "100%" }} className="custom">
            <thead>
              <tr>
                {columns.map((column, i) => {
                  return (
                    <th
                      key={column}
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onClick={(e) => handleSort(e, i)}
                    >
                      {column}
                    </th>
                  );
                })}
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {rowsToDisplay.map((row, i) => {
                return (
                  <tr key={i}>
                    {row.map((row2, index) => {
                      return index < 9 ? (
                        <td
                          key={Math.random()}
                          style={{ textAlign: " center" }}
                        >
                          {row2}
                        </td>
                      ) : (
                        ""
                      );
                    })}
                    <td
                      style={{
                        textAlign: " center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to={`/dashboard/contact/${row[9]}`}
                        style={{
                          cursor: "pointer",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "15px",
                          color: "black",
                        }}
                      >
                        <i className="fa fa-address-book" />
                      </Link>
                      <Link
                        to={`/dashboard/order/${row[10]}`}
                        style={{
                          cursor: "pointer",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "15px",
                          color: "black",
                          marginLeft: "10px",
                        }}
                      >
                        <i className="fa fa-address-book-o" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: "30px" }}
          >
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <h5>
                <strong>Total:</strong> {rows.length}
              </h5>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="btn btn-primary m-2"
                onClick={(e) => changePage(e, -1)}
                disabled={page <= 0}
              >
                <strong>{"<"}</strong>
              </button>
              <input
                style={{ width: "60px", height: "40px" }}
                type="number"
                value={page}
                onChange={(e) => handleSetPage(e)}
              />
              <button
                className="btn btn-primary m-2"
                onClick={(e) => changePage(e, 1)}
                disabled={page > Math.floor(rows.length / howManyToShow)}
              >
                <strong>{">"}</strong>
              </button>
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </Wrapper>
  );
};

export default DataTable;
