export const generateData = ({ key, value, markets, products }) => {
  const result = [];
  const elementResult = [];

  elementResult.push(markets.find((m) => m._id === value.market)?.name);
  elementResult.push(products.find((m) => m._id === value.product)?.name);
  elementResult.push(key.split("-")[1]);
  elementResult.push(value.contacts);
  elementResult.push(value.cod_count);
  elementResult.push(
    value.contacts > 0
      ? Math.round(parseFloat(value.cod_sum / value.contacts) * 100) / 100
      : 0
  );
  elementResult.push(
    Math.round((value.cod_count / value.contacts) * 10000) / 100
  );
  elementResult.push(value.cod_delivered_count);
  elementResult.push(
    value.contacts > 0
      ? Math.round(parseFloat(value.cod_delivered_sum / value.contacts) * 100) /
          100
      : 0
  );
  elementResult.push(
    Math.round((value.cod_delivered_count / value.cod_count) * 10000) / 100
  );

  result.push(elementResult);

  return result;
};
