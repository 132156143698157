import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const InputsWrapper = styled.div`
  margin-bottom: 80px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
