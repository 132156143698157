import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import React, { useState } from "react";
import SearchBarResignReport from "./components/searchBarResignReport/SearchBarResignReport";
import ResignReportTable from "./components/ResignReportTable/ResignReportTable";

const SupportResignReport = () => {
  const [resigns, setResigns] = useState();

  const { addMessage, removeMessage, messages } = useMessageQueue();

  return (
    <div>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <SearchBarResignReport addMessage={addMessage} setResigns={setResigns} />
      {resigns && <ResignReportTable resigns={resigns} />}
    </div>
  );
};

export default SupportResignReport;
