import React, { useState, useEffect } from "react";
import Bell from "./components/bell/Bell";
import TicketView from "./components/ticketView/TicketView";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getAmountOfNotSeenMessages } from "./helpers/getAmountOfNotSeenMessages";
import { getConsultantTicketsAll } from "@/API/repositories/tickets";
import userManager from "@/API/userManager";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import Circle from "@/common/components/Circle";
import { CircleWrapper } from "./Tickets.styled";
import { sortTickets } from "./helpers/sortTickets";
import LanguagesChanger from "../../../../common/components/languagesChanger/LanguagesChanger";

const Tickets = ({ translation }) => {
  const [isTicketsViewOpen, setIsTicketsViewOpen] = useState(false);
  const [tickets, setTickets] = useState();
  const [oldTickets, setOldTickets] = useState();

  const { makeRequest } = useRequestsContext();
  const { messages, removeMessage, addMessage } = useMessageQueue();

  const currentUserId = userManager.getUser().id;

  const loadData = async () => {
    const response = await makeRequest(getConsultantTicketsAll);

    if (response.data?.activeTickets) {
      const { activeTickets } = response.data;
      const sortedActiveTickets = sortTickets(activeTickets);

      setTickets(() => sortedActiveTickets);

      if (tickets) {
        const previous = getAmountOfNotSeenMessages(tickets, currentUserId);
        const current = getAmountOfNotSeenMessages(
          activeTickets,
          currentUserId
        );

        if (previous < current) {
          addMessage("You have some new messages", "error");
        }
      }
    }

    if (response.data?.outdatedTickets) {
      const { outdatedTickets } = response.data;
      const sortedOutdatedTickets = sortTickets(outdatedTickets);

      setOldTickets(() => sortedOutdatedTickets);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      <CircleWrapper>
        <Circle refreshTime={30} getData={loadData} />
      </CircleWrapper>
      <Bell setIsTicketsViewOpen={setIsTicketsViewOpen} tickets={tickets} />
      <LanguagesChanger />
      {isTicketsViewOpen && (
        <TicketView
          translation={translation}
          loadData={loadData}
          tickets={tickets}
          oldTickets={oldTickets}
          setIsTicketViewOpen={setIsTicketsViewOpen}
        />
      )}
    </>
  );
};

export default Tickets;
