import styled from "styled-components";

export const QueueGroupsTr = styled.tr`
	position: relative;
`;

export const QueueGroupsConsultants = styled.td`
	display: block;
	max-width: 400px;
`;

export const QueueGroupsActions = styled.td`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
`;

export const QueueGroupAction = styled.i`
	font-size: 14px;
	cursor: pointer;
	color: ${(props) => props.color || "black"};
`;