import moment from "moment";
import React, { useRef, useState } from "react";
import {
  changeClaimStatus,
  findClaimById,
  markAsPaid,
  orderClaim,
  orderClaimDone,
} from "@/API/repositories/order";
import { addInvoiceCorrectionNumber } from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { PRIORITIES } from "@/common/constants/priorities";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import {
  DataStrong,
  DocumentElementWrapper,
  DocumentsWrapper,
  FileNameWrapper,
  FileUploadWrapper,
  Flex,
  FlexButtons,
  H5,
  Title,
  TopContentWrapper,
} from "./claimEditHrView.styles";
import { Link } from "react-router-dom";
import Icon from "@/common/components/Icon";
import { sumValuesWithCurrency } from "@/common/functions/sumValuesWithCurrency";
import MessageBox from "./components/MessageBox";
import InvoiceElement from "./components/InvoiceElement";
import { getDataForShipping } from "./helpers/getDataForShipping";
import { createOrderItemsDisplayList } from "@/common/functions/createOrderItemsDisplayList";
import AddMessageComponent from "./components/AddMessageComponent";
import AddCorrectionComponent from "./components/AddCorrectionComponent";
import CustomButtonError from "@/common/components/buttons/CustomButtonError";
import { checkIbanLength } from "./helpers/checkIbanLength";
import { BANK_ACCOUNT_COUNTRIES } from "@/common/constants/claims";
import {
  deleteFileById,
  getFileById,
  savePdfToAws,
} from "@/API/repositories/storedDocument";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import userManager from "@/API/userManager";

const RESTRICTED_ROLES = ["SUPPORT"];

const ClaimEditHrView = ({ setShow, data, addMessage, handleSearch }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [isAddCorrectionOpen, setIsAddCorrectionOpen] = useState(false);
  const [inputRef, setInputRef] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(
    PRIORITIES.find((priority) => priority.value === data.claim.priority) || {
      label: "none",
      value: "none",
    }
  );
  const [claimStatus, setClaimStatus] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const {
    commonData: { users, markets },
    queryValues: { isFetching },
  } = useCommonDataContext();

  const bankAccountRef = useRef();
  const ibanRef = useRef();
  const paymentStatusRef = useRef();
  const toCheckInfoRef = useRef();
  const correctedInfoRef = useRef();
  const newToCheckInfoRef = useRef();
  const newCheckedInfoRef = useRef();
  const polishCurrencyRef = useRef();
  const fileRef = useRef();
  const correctionNumberRef = useRef();
  const paidDateRef = useRef();
  const toggleRef = useRef();

  const isRestricted = RESTRICTED_ROLES.includes(userManager.getUser().role);

  const handleRefresh = async () => {
    const response = await makeRequest(findClaimById.bind(null, data._id));
    if (response.data) {
      setShow(() => response.data);
    }
  };

  const handleInputOpen = (inputRef, type, status) => {
    setIsInputOpen(() => true);
    setInputRef(() => inputRef);
    setClaimStatus(() => status);
    setMessageType(() => type);
  };

  const handleUploadFile = async () => {
    if (!fileRef.current?.files[0]) return;

    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);

    const response = await makeRequest(
      savePdfToAws.bind(null, data._id, formData)
    );

    if (response.data) {
      addMessage("Saved", "success");

      await handleRefresh();
    } else {
      addMessage("Error", "error");
    }
  };

  const handlePaid = async (e) => {
    e && e.preventDefault();

    const { country } = data._contact;

    const iban =
      ibanRef.current.value?.replace(/\s/g, "") ||
      data.claim.iban?.replace(/\s/g, "");

    if (toggleRef.current?.checked && iban?.length) {
      const prefix = iban.substring(0, 2);

      if (prefix !== country.toUpperCase()) {
        return addMessage("Bad iban prefix", "error");
      }

      if (iban && !checkIbanLength(iban, markets, data)) {
        return addMessage("Wrong iban length", "error");
      }
    }

    if (!ibanRef.current?.value.trim()) {
      return addMessage("Add iban", "error");
    }

    if (!paidDateRef.current?.value) {
      return addMessage("Add paid date", "error");
    }

    if (data._ticket?.invoice && !data._ticket.invoice.correction_number) {
      return addMessage("Add correction number", "error");
    }

    const payload = {};

    payload.iban =
      ibanRef.current?.value || data.claim.iban?.replaceAll(" ", "");
    payload.bank_account =
      bankAccountRef.current?.value ||
      data.claim.bank_account?.replaceAll(" ", "");
    payload.priority = selectedPriority.value;
    payload.polish_currency = polishCurrencyRef.current?.value;
    payload.paid_date = paidDateRef.current?.value;
    payload.ignore_iban_validation = !toggleRef.current?.checked;

    const response = await makeRequest(
      markAsPaid.bind(null, data._id, payload)
    );

    if (response.data) {
      addMessage("Saved", "success");
      setShow(() => false);
    }
  };

  const handleStatusChange = async (e) => {
    e.preventDefault();

    if (!inputRef.current?.value) {
      return addMessage("Please, add data", "error");
    }

    const payload = {};

    payload.original = inputRef.current?.value;
    payload.status = claimStatus;
    payload.message_type = messageType;
    const response = await makeRequest(
      changeClaimStatus.bind(null, data._id, payload)
    );

    if (response.data) {
      addMessage("Saved", "success");
      setIsInputOpen(() => false);

      setShow(null);

      await handleSearch();
    }
  };

  const handleUpdatingInvoiceCorrectionNumber = async () => {
    if (!correctionNumberRef.current?.value) {
      return addMessage("Enter correction number", "error");
    }

    const response = await makeRequest(
      addInvoiceCorrectionNumber.bind(
        null,
        data._ticket._id,
        correctionNumberRef.current.value
      )
    );

    if (!response.data) {
      addMessage("Couldn't add correction number");
    }

    if (response.data) {
      setIsAddCorrectionOpen(() => false);
      addMessage("Saved", "success");

      setShow(null);

      await handleSearch();
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {};

    const { country } = data._contact;

    const iban =
      ibanRef.current.value?.replace(/\s/g, "") ||
      data.claim.iban?.replace(/\s/g, "");

    if (toggleRef.current?.checked && iban?.length) {
      const prefix = iban.substring(0, 2);

      if (prefix !== country.toUpperCase()) {
        return addMessage("Bad iban prefix", "error");
      }

      if (iban && !checkIbanLength(iban, markets, data)) {
        return addMessage("Wrong iban length", "error");
      }
    }

    payload.iban =
      ibanRef.current?.value || data.claim.iban?.replaceAll(" ", "");
    payload.bank_account =
      bankAccountRef.current?.value ||
      data.claim.bank_account?.replaceAll(" ", "");
    payload.priority = selectedPriority.value;
    payload.polish_currency = polishCurrencyRef.current?.value;
    payload.paid_date = paidDateRef.current?.value;
    payload.ignore_iban_validation = !toggleRef.current?.checked;

    const response = await makeRequest(
      orderClaim.bind(null, data._id, payload)
    );

    if (response.data) {
      addMessage("Saved", "success");
      await handleRefresh();
    }
  };

  const handleMadeClaim = async (e) => {
    e.preventDefault();

    const payload = {};

    const { country } = data._contact;

    const iban =
      ibanRef.current.value?.replace(/\s/g, "") ||
      data.claim.iban?.replace(/\s/g, "");

    if (toggleRef.current?.checked && iban?.length) {
      const prefix = iban.substring(0, 2);

      if (prefix !== country.toUpperCase()) {
        return addMessage("Bad iban prefix", "error");
      }

      if (iban && iban.length && !checkIbanLength(iban, markets, data)) {
        return addMessage("Wrong iban length", "error");
      }
    }

    if (!+polishCurrencyRef.current?.value) {
      return addMessage("Claim price must be a number", "error");
    }

    if (!ibanRef.current?.value && !data.claim.iban) {
      return addMessage("Add iban", "error");
    }

    payload.bank_account =
      bankAccountRef.current?.value ||
      data.claim.bank_account?.replaceAll(" ", "");
    payload.iban =
      ibanRef.current?.value || data.claim.iban?.replaceAll(" ", "");
    payload.payment_status =
      paymentStatusRef.current?.value || data.claim.payment_status;
    payload.full_name = data._contact.full_name;
    payload.price =
      data?.claim?.price ||
      data.order_items.reduce((prev, next) => prev + next.price, 0);
    payload.sold_date = data.shipping.status_delivered_at;
    payload.max_claim_date = moment(data.shipping.status_delivered_at)
      .add("14", "days")
      .toDate();
    payload.mail = data._contact.email;
    payload.product_items = data.order_items.map(
      (item) => item.product_variant
    );
    payload.consultant = data.confirmed_by;
    payload.priority = selectedPriority.value;
    payload.polish_currency = polishCurrencyRef.current?.value;
    payload.paid_date = paidDateRef.current?.value;
    payload.ignore_iban_validation = !toggleRef.current?.checked;

    const response = await makeRequest(
      orderClaimDone.bind(null, data._id, payload)
    );

    if (response.data) {
      setShow(() => false);
    }
  };

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDeleteFile = async (storedDataId) => {
    const response = await makeRequest(deleteFileById.bind(null, storedDataId));

    if (response.data) {
      addMessage("Deleted", "success");
      await handleRefresh();
    } else {
      addMessage("Error", "error");
    }
  };

  return (
    <>
      {(hasUnfilledRequest(
        savePdfToAws,
        changeClaimStatus,
        markAsPaid,
        orderClaim,
        orderClaimDone,
        getFileById,
        deleteFileById
      ) ||
        isFetching) && <Loading />}
      <PopUp setShow={setShow}>
        <TopContentWrapper>
          <Title>Claim:</Title>
          <Link to={`/dashboard/order/${data._id}`}>
            <Icon name={"fa fa-address-card animation-scale"} size={32} />
          </Link>
        </TopContentWrapper>
        <form>
          <Flex>
            <div>
              <Flex>
                <H5>Status:</H5>
                <DataStrong>{data.claim.status?.toUpperCase()}</DataStrong>
              </Flex>
              <Flex>
                <H5>Name:</H5>
                <DataStrong>
                  {data._contact.full_name?.split(" ")[0]}
                </DataStrong>
              </Flex>
              <Flex>
                <H5>Surname:</H5>
                <DataStrong>
                  {data._contact.full_name?.split(" ")[1]}
                </DataStrong>
              </Flex>
              <Flex>
                <H5>Address:</H5>
                <DataStrong>{data._contact.street || "----"}, {data._contact.postal_code || "----"}, {data._contact.city || "----"}</DataStrong>
              </Flex>
              <Input
                type="date"
                color={Colors.darkBlue}
                value={
                  data.claim.paid_date
                    ? formatToDateTamplate(data.claim.paid_date)
                    : null
                }
                inputRef={paidDateRef}
                name="Paid date"
                width={140}
              />
              {BANK_ACCOUNT_COUNTRIES.includes(data._contact.country) && (
                <Input
                  color={Colors.darkBlue}
                  inputRef={bankAccountRef}
                  name="Bank Account"
                  width={140}
                  value={data.claim.bank_account?.replaceAll(" ", "")}
                />
              )}
              <Input
                color={Colors.darkBlue}
                inputRef={ibanRef}
                name="IBAN"
                width={140}
                value={data.claim.iban}
                required={data._contact.country === "sk"}
              />
              <Flex>
                <H5>Price:</H5>
                <DataStrong>
                  {data?.claim?.prcie || sumValuesWithCurrency(data)}
                </DataStrong>
              </Flex>
              <Input
                width={140}
                color={Colors.darkBlue}
                inputRef={polishCurrencyRef}
                type="number"
                name="Claim price"
                required={true}
                value={data.claim.polish_currency}
              />
              {data._ticket && data._ticket.invoice?.invoice_number && (
                <InvoiceElement
                  data={data}
                  setIsAddCorrectionOpen={setIsAddCorrectionOpen}
                />
              )}
              {data.claim.messages && data.claim.messages[0] && (
                <MessageBox users={users} data={data} />
              )}
            </div>
            <div>
              <Flex>
                <H5>Sold Date:</H5>
                <DataStrong>
                  {formatToDateTamplate(getDataForShipping(data.shipping))}
                </DataStrong>
              </Flex>
              <Flex>
                <H5>Max Claim Date:</H5>
                <DataStrong>
                  {formatToDateTamplate(
                    moment(getDataForShipping(data.shipping)).add("14", "days")
                  )}
                </DataStrong>
              </Flex>
              <Flex>
                <H5>Mail:</H5>
                <DataStrong>{data._contact.email}</DataStrong>
              </Flex>
              <Flex>
                <H5>Product:</H5>
                <DataStrong>{createOrderItemsDisplayList(data)}</DataStrong>
              </Flex>
              <Flex>
                <H5>Consultant:</H5>
                <DataStrong>{data._user.username}</DataStrong>
              </Flex>
              <SelectInput
                name="Priority"
                color={Colors.darkBlue}
                width={140}
                selected={selectedPriority}
                setSelected={setSelectedPriority}
                options={PRIORITIES}
              />
              {data.claim.to_check_info && (
                <Input
                  name="To correct info"
                  color={Colors.darkBlue}
                  value={data.claim.to_check_info}
                  width={140}
                  disabled={true}
                />
              )}
              <FileUploadWrapper>
                <input
                  ref={fileRef}
                  type="file"
                  accept="application/pdf"
                  style={{ width: "250px" }}
                />
                <CustomButtonAdd
                  defaultText={"Upload"}
                  onClick={() => handleUploadFile()}
                  disabled={hasUnfilledRequest(savePdfToAws)}
                />
              </FileUploadWrapper>
              <DocumentsWrapper>
                {data._stored_docs?.map((inital, i) => (
                  <DocumentElementWrapper>
                    <p>{i + 1}.</p>
                    <FileNameWrapper>{inital.id_in_bucket}</FileNameWrapper>
                    <Icon
                      name={"fa fa-file animation-scale"}
                      color={Colors.green}
                      onClick={() => handleDownloadFile(inital._id)}
                    />
                    <Icon
                      name={"fa fa-trash animation-scale"}
                      color={Colors.red}
                      onClick={() => handleDeleteFile(inital._id)}
                    />
                  </DocumentElementWrapper>
                ))}
              </DocumentsWrapper>
              <ToggleSwitch
                toggleRef={toggleRef}
                checked={!data.claim.ignore_iban_validation}
                text="Validate IBAN"
                width={200}
              />
            </div>
          </Flex>
          <FlexButtons>
            <button
              className="btn btn-success"
              type="button"
              onClick={handleSave}
            >
              Save
            </button>
            <CustomButtonAdd
              defaultText={"To check"}
              onClick={() =>
                handleInputOpen(newToCheckInfoRef, "to_check", "to_check")
              }
            />
            <CustomButtonAdd
              defaultText={"Checked"}
              onClick={() =>
                handleInputOpen(newCheckedInfoRef, "checked", "checked")
              }
              disabled={data.claim.status !== "to_check"}
            />
            <CustomButtonAdd
              defaultText={"To correct"}
              onClick={() =>
                handleInputOpen(toCheckInfoRef, "to_correct", "to_correct")
              }
            />
            <CustomButtonAdd
              defaultText={"Corrected"}
              onClick={() =>
                handleInputOpen(correctedInfoRef, "corrected", "created")
              }
              disabled={data.claim.status !== "to_correct"}
            />
            {!isRestricted && (
              <>
                <CustomButtonError
                  defaultText={"Paid"}
                  onClick={handlePaid}
                  disabled={data.claim.status === "paid"}
                />
                <CustomButtonError
                  onClick={handleMadeClaim}
                  type="submit"
                  defaultText={"Claim done"}
                  disabled={
                    data.claim.status === "done" ||
                    (data.claim.status !== "paid" &&
                      data.claim.status !== "checked")
                  }
                />
              </>
            )}
          </FlexButtons>
        </form>
      </PopUp>
      {isInputOpen && (
        <AddMessageComponent
          setIsInputOpen={setIsInputOpen}
          handleStatusChange={handleStatusChange}
          messageType={messageType}
          inputRef={inputRef}
        />
      )}
      {isAddCorrectionOpen && (
        <AddCorrectionComponent
          setIsAddCorrectionOpen={setIsAddCorrectionOpen}
          data={data}
          correctionNumberRef={correctionNumberRef}
          handleUpdatingInvoiceCorrectionNumber={
            handleUpdatingInvoiceCorrectionNumber
          }
        />
      )}
    </>
  );
};

export default ClaimEditHrView;
