import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const TicketsWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 80, 114, 0.78);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const TicketsCloser = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div`
  z-index: 2;
  margin: 10px;
  display: grid;
  grid-template-columns: 27% 1fr;
  grid-template-rows: max-content minmax(10%, 1fr) minmax(10%, 1fr) 40px 5% 150px;
  height: 90vh;
  width: 90vw;
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid ${Colors.lightGray};
`;

export const TitleWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  background-color: ${Colors.darkBlue};
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 20px;
`;

export const Title = styled.h3`
  color: white;
`;

export const TicketCreateWrapper = styled.div`
  padding: 8px;
  border-radius: 10px;
  background-color: ${Colors.purple};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddIcon = styled.i`
  cursor: pointer;
  font-size: 20px;
  color: white;
`;

export const Textarea = styled.textarea`
  width: 70%;
`;

export const OldTicketsTitleWrapper = styled.div`
  grid-row: 4 / 5;
`;

export const OldTicketsTitle = styled.div`
  position: sticky;
  height: 100%;
  padding: 10px;
  top: 0;
  padding: 8px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background-color: ${Colors.darkBlue};
  color: white;
  font-weight: bold;
`;

export const SubTitle = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 12px;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
