import React from "react";
import userManager from "@/API/userManager";
import {
  TicketsListWrapper,
  MessageItem,
  MessageText,
  OnlineRound,
  MessageSenderMarket,
  MessageSenderName,
  MessageSenderNameWrapper,
  MessageInfoWrapper,
  MessageStatus,
  PriorityWrapper,
  LastMessageTimeWrapper,
} from "./TicketsList.styled";
import { handleOnline } from "../../helpers/handleOnline";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import { getTicketBackgroundColor } from "../../helpers/getTicketBackgroundColor";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { Colors } from "@/common/colors/colors";
import { filterTicketsByText } from "./helpers/filterTicketsByText";
import { findLabelByTitle } from "../ticketView/helpers/findLabelByTitle";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import Icon from "@/common/components/Icon";
import { getMessageTime } from "../../helpers/getMessageTime";
import { getLastTicketNotification } from "../../helpers/getLastTicketNotification";
import moment from "moment";

const TicketsList = ({
  tickets,
  selectedTicket,
  handleSelectTicket,
  newTicketCreating,
  filterText,
  departments,
}) => {
  const currentUserId = userManager.getUser().id;
  const { dashboardSettings } = useAdminDashboardContext();

  return (
    <TicketsListWrapper
      gridRawEnd={dashboardSettings?.show_old_tickets ? 4 : 7}
    >
      {newTicketCreating && (
        <MessageItem backgroundColor={Colors.darkGray}>
          <CustomSkeleton count={2} height="10px" width="100%" />
        </MessageItem>
      )}
      {filterTicketsByText(filterText, tickets).map((ticket) => (
        <MessageItem
          onClick={() => handleSelectTicket(ticket)}
          backgroundColor={getTicketBackgroundColor(
            ticket,
            selectedTicket,
            currentUserId
          )}
          key={ticket._id}
          color={"black"}
        >
          <MessageSenderMarket>
            {ticket._orders && ticket._orders[0]?._product?.name && (
              <span>
                {ticket._orders[0]._product.name.split(" ")[1]}{" "}
                {ticket._orders[0]._product.name.split(" ")[0]}
              </span>
            )}
          </MessageSenderMarket>
          <MessageInfoWrapper>
            {ticket.prioritized && (
              <PriorityWrapper>
                <Icon color="#fff" size={10} name="fa fa-exclamation" />
              </PriorityWrapper>
            )}
            <MessageText>{findLabelByTitle(ticket, departments)}</MessageText>
            <MessageSenderNameWrapper>
              <MessageSenderName>
                {ticket._last_status_by?.username || "System"}
              </MessageSenderName>
              <OnlineRound
                backgroundColor={handleOnline(
                  ticket._last_status_by?.last_ping
                )}
              />
              <LastMessageTimeWrapper>
                {moment(
                  getMessageTime(getLastTicketNotification(ticket))
                ).format("YYYY-MM-DD HH:mm")}
              </LastMessageTimeWrapper>
            </MessageSenderNameWrapper>
          </MessageInfoWrapper>
          <MessageStatus
            bgColor={
              QUESTION_TICKET_STATUS_OPTIONS.find(
                (o) => o.value === ticket.status
              )?.color
            }
          >
            {
              QUESTION_TICKET_STATUS_OPTIONS.find(
                (o) => o.value === ticket.status
              )?.label
            }
          </MessageStatus>
        </MessageItem>
      ))}
    </TicketsListWrapper>
  );
};

export default TicketsList;
