import { findUserById } from "@/common/functions/findUserById";
import { calcSalaryForOne } from "./calcSalaryForOne";
import { calcSalary } from "./calcSalary";

export const getRaws = (team, users) =>
  team.map((data) => {
    return data.data
      .sort((a, b) => a._id - b._id)
      .map((element) => (
        <tr style={{ borderTop: "1px solid grey" }}>
          <td style={{ width: "60px", textAlign: "left" }}>
            {findUserById(data.consultant, users)?.username}
          </td>
          <td style={{ width: "60px", textAlign: "left" }}>{element.price} {data.currency}</td>
          <td style={{ width: "150px", textAlign: "center" }}>
            {Math.round(calcSalaryForOne(data, element) * 100) / 100 || 0} {data.salary_currency} {data.currency}
          </td>
          <td style={{ width: "150px", textAlign: "center" }}>
            {element.orders_count}
          </td>
          <td style={{ width: "150px", textAlign: "center" }}>
            {element.delivered_count}
          </td>
          <td style={{ width: "200px", textAlign: "center" }}>
            {Math.round(calcSalary(
              data,
              element.big_items_count,
              element.small_items_count
            ) * 100) / 100}{" "}
            {data.currency}
          </td>
        </tr>
      ));
  });
