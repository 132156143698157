import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const refundOrder = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(`/cms/refund/${orderId}`);

    return { data: data.data };
  });

export const getPaymentStatus = (paymentId) =>
  errorHandler(async () => {
    const data = await api.get(`/cms/payment-status/${paymentId}`);

    return { data: data.data };
  });

export const triggerStatus = (orderId, paymentId) =>
  errorHandler(async () => {
    const data = await api.patch(`/cms/trigger-status/${orderId}`, {
      paymentId
    });

    return { data: data.data };
  });
