import React from 'react'
import { CopyWrapper } from '../chat/Chat.styled'
import Icon from '@/common/components/Icon'

const CopyItem = ({ handleCopy, message }) => {
	return (
		<CopyWrapper>
			<Icon onClick={() => handleCopy(message)} size={14} name="fa fa-copy" />
		</CopyWrapper>
	)
}

export default CopyItem
