import React, { useMemo } from "react";
import { useAdminDashboardContext } from "../../../../common/hooks/adminDashboardHook";
import Table from "../../../../common/components/Table";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { getClaimsByStatusReport } from "../../../../API/repositories/reports";
import { groupByMarketAndStautsField } from "../../../../common/functions/groupByMarketAndStatusField";

const Claims = () => {
  const { data } = useAdminDashboardContext();
  const { claims } = data;
  const { hasUnfilledRequest } = useRequestsContext();

  const countByStatus = (items, status) => {
    return items.reduce(
      (prev, next) => prev + (next.claim.status === status ? 1 : 0),
      0
    );
  };

  const headersArray = [
    [<th colSpan={7}>Claims</th>],
    [
      <th>Market</th>,
      <th>Created</th>,
      <th>To correct</th>,
      <th>Corrected</th>,
      <th>To check</th>,
      <th>Checked</th>,
      <th>Paid</th>,
    ],
  ];

  const raws = useMemo(() => {
    if (claims) {
      return groupByMarketAndStautsField(claims)
        .sort((claimA, claimB) => {
          return claimA[0].localeCompare(claimB[0]);
        })
        .map((claim) => (
          <tr key={claim[0]}>
            <td>{claim[0]}</td>
            <td>{countByStatus(claim[1], "created") || 0}</td>
            <td>{countByStatus(claim[1], "to_correct") || 0}</td>
            <td>{countByStatus(claim[1], "corrected") || 0}</td>
            <td>{countByStatus(claim[1], "to_check") || 0}</td>
            <td>{countByStatus(claim[1], "checked") || 0}</td>
            <td>{countByStatus(claim[1], "paid") || 0}</td>
          </tr>
        ));
    }
  }, [claims]);

  const shouldShowLoading = useMemo(
    () => !claims && hasUnfilledRequest(getClaimsByStatusReport),
    [claims, hasUnfilledRequest]
  );

  return (
    <div>
      <Table
        stickyHeader={true}
        className="styled-table-dashboard"
        headersArray={headersArray}
        raws={raws}
        loading={shouldShowLoading}
      />
    </div>
  );
};

export default Claims;
