import React, {
  useState,
  useEffect,
  createContext,
  useMemo,
  useRef,
} from "react";
import io from "socket.io-client";
import Api from "../../API/api";

export const CallCRMApiContextVonage = createContext();

export const CallCRMApiVoipVonage = ({ children }) => {
  const [reconnectCount, setReconnectCount] = useState(0);

  const socket = useMemo(() => {
    return io(process.env.REACT_APP_CRM_SOCKET, {
      extraHeaders: {
        authorization: `Bearer ${Api.tokenManager.getToken()}`,
      },
    });
  }, [reconnectCount]);

  const breakRef = useRef();
  const startRef = useRef();
  const [hasPosibilityToCallNext, setHasPosibilityToCallNext] = useState();
  const [isCalling, setIsCalling] = useState(false);
  const [token, setToken] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [waiting, setWaiting] = useState(false);
  const [callingClientCredentials, setCallingClientCredentials] = useState();
  const [currentContactOutbound, setCurrentContactOutbound] = useState();
  const [showCallingDescription, setShowCallingDescription] = useState(false);
  const [showResultReCall, setShowResultReCall] = useState(null);
  const [callingClientOnceAgain, setCallingClientOnceAgain] = useState(null);
  const [voipError, setVoipError] = useState(false);
  const [ticketCallAnswered, setTicketCallAnswered] = useState(false);
  const [ticketCallNoAnswered, setTicketCallNoAnswered] = useState(false);
  const [isTalkingTicket, setIsTalkingTicket] = useState(false);
  const [showNoAsweredTicket, setShowNoAsweredTicket] = useState(false);
  const [incoming, setIncoming] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [countWrapTime, setCountWrapTime] = useState(false);
  const tokenRef = useRef({ shouldSetUpToken: true, setUpToken: () => {} });

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
      sendStartWorking();
      sendGetToken();
    });

    socket.on("vonage-token", (token) => {
      if (!tokenRef.current.shouldSetUpToken) {
        tokenRef.current.setUpToken = () => {
          setToken(token);
          tokenRef.current.setUpToken = () => {};
        };
      } else {
        setToken(() => token);
        tokenRef.current.setUpToken = () => {};
      }
    });

    socket.on("vonage-offline", () => {
      setIsConnected(false);
    });

    socket.on("vonage-callingToClient", (order) => {
      setWaiting(() => false);
      setCallingClientCredentials(() => order);
    });

    socket.on("vonage-connectedOutbound", (order) => {
      setWaiting(() => false);
      setCallingClientCredentials(() => null);
      setCurrentContactOutbound(() => order);

      setCountWrapTime(() => false);
      setCallingClientOnceAgain(() => null);
      setIsTalking(() => true);
      setShouldSetUpToken(false);
    });

    socket.on("vonage-recallCallingResult", (info) => {
      setCallingClientOnceAgain(() => null);
      setShowResultReCall(() => info);
    });

    socket.on("vonage-recallCallingInfo", (contact) => {
      setCallingClientOnceAgain(() => contact);
    });

    socket.on("vonage-dontCallNext", () => {
      setHasPosibilityToCallNext(() => false);
      setWaiting(() => false);
      setCallingClientCredentials(() => null);
      setCurrentContactOutbound(() => null);
      takeABreak();
      breakRef.current = true;
      startRef.current = false;
    });

    socket.on("vonage-sendToUserError", () => {
      setWaiting(() => false);
      setVoipError(() => true);
      setCallingClientCredentials(() => false);
    });

    socket.on("vonage-ticketCallNoAnswered", () => {
      setIsCalling((prev) => {
        if (prev) {
          setShowNoAsweredTicket(() => true);
        }
        return false;
      });
      setTicketCallNoAnswered(() => true);
    });

    socket.on("vonage-ticketCallAnswered", () => {
      setTicketCallAnswered(() => true);
      setIsTalkingTicket(() => true);
      setIsCalling(() => false);

      setCountWrapTime(() => false);
      setCallingClientOnceAgain(() => null);
      setIsTalking(() => true);
      setShouldSetUpToken(false);
    });

    socket.on("vonage-sendToUserInboundContact", (contactId) => {
      console.log(contactId);
      setIncoming(() => contactId);
      setWaiting(() => false);
      setCallingClientCredentials(() => null);

      setCountWrapTime(() => false);
      setCallingClientOnceAgain(() => null);
      setIsTalking(() => true);
      setShouldSetUpToken(false);
    });

    socket.on("vonage-callEnd", () => {
      setIsTalking(() => false);
      setCountWrapTime(() => true);
      setIsTalkingTicket(() => false);
      setTicketCallAnswered(() => false);
      setTicketCallNoAnswered(() => false);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [socket]);

  useEffect(() => {
    if (reconnectCount > 0) {
      sendReconnect();
    }
  }, [socket]);

  useEffect(() => {
    const listener = window.addEventListener("offline", (e) => {
      const onlineListener = window.addEventListener("online", (e) => {
        setReconnectCount((prev) => prev + 1);
        return () => window.removeEventListener("online", onlineListener);
      });
    });

    return () => window.removeEventListener("offline", listener);
  }, []);

  const sendCallToClient = async (contactId) => {
    socket.emit("vonage-send-call-to-client", { contactId });
  };

  const sendReconnect = async () => {
    socket.emit("vonage-reconnect");
  };

  const sendGetToken = async () => {
    socket.emit("vonage-create-token");
  };

  const sendLoginInformation = () => {
    socket.emit("vonage-login-to-dashboard");
  };

  const sendStartCalling = () => {
    socket.emit("vonage-start-calling");
  };

  const sendStopCalling = () => {
    socket.emit("vonage-stop-calling");
  };

  const handleCallNext = () => {
    setWaiting(() => true);
    socket.emit("vonage-call-next-person");
  };

  const handleBreak = () => {
    socket.emit("vonage-stop-calling-persons");
  };

  const sendStartWorking = () => {
    socket.emit("vonage-send-start-working");
  };

  const sendStopWorking = () => {
    socket.emit("vonage-send-stop-working");
  };

  const takeABreak = () => {
    socket.emit("vonage-take-a-break");
  };

  const callOneceAgain = () => {
    socket.emit("vonage-call-once-again");
  };

  const sendArchive = () => {
    socket.emit("vonage-send-archive");
  };

  const onUnmount = () => {
    socket.close();
  };

  const sendWantBreakAfterNextContact = () => {
    socket.emit("vonage-dont-call-next");
  };

  const endCall = (callId) => {
    socket.emit("vonage-end-call", callId);
  };

  const setShouldSetUpToken = (value) => {
    tokenRef.current.shouldSetUpToken = value;

    if ((value = true)) {
      tokenRef.current.setUpToken();
    }
  };

  return (
    <CallCRMApiContextVonage.Provider
      value={{
        sendStartCalling,
        sendStopCalling,
        currentContactOutbound,
        callingClientCredentials,
        showCallingDescription,
        setShowCallingDescription,
        setCallingClientCredentials,
        setCurrentContactOutbound,
        handleCallNext,
        handleBreak,
        waiting,
        setWaiting,
        sendStopWorking,
        sendStartWorking,
        sendLoginInformation,
        takeABreak,
        callOneceAgain,
        showResultReCall,
        setShowResultReCall,
        callingClientOnceAgain,
        setCallingClientOnceAgain,
        sendArchive,
        setCurrentContactOutbound,
        setCurrentContactOutbound,
        isConnected,
        token,
        sendGetToken,
        voipError,
        setVoipError,
        sendCallToClient,
        ticketCallAnswered,
        setTicketCallAnswered,
        ticketCallNoAnswered,
        setTicketCallNoAnswered,
        isTalkingTicket,
        setIsTalkingTicket,
        isCalling,
        setIsCalling,
        showNoAsweredTicket,
        setShowNoAsweredTicket,
        onUnmount,
        incoming,
        setIncoming,
        hasPosibilityToCallNext,
        setHasPosibilityToCallNext,
        sendWantBreakAfterNextContact,
        breakRef,
        startRef,
        setShouldSetUpToken,
        isTalking,
        setIsTalking,
        countWrapTime,
        setCountWrapTime,
        endCall,
      }}
    >
      {children}
    </CallCRMApiContextVonage.Provider>
  );
};
