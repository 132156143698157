import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");
const { axios: api } = Api;

export const getOrderWithFilter = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/get_order_with_filter`, payload);
    return { data: data.data };
  });

export const getOrderById = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/order/get_by_id/${_id}`);
    return { data: data.data };
  });

export const getOrderWithContact = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/order/get_order_with_contact/${_id}`);
    return { data: data.data };
  });

export const findClaimById = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/order/calim-by-id/${_id}`);
    return { data: data.data };
  });

export const getAllPosibleFiledsToFilter = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/order/get-all-posible-field`);
    return { data: data.data };
  });

export const sendShipping = (_id, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/create_shipping/${_id}`, payload);
    return { data: data.data };
  });

export const resendOrder = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(`/order/resend/${orderId}`);
    return { data: data.data };
  });

export const confirmOrder = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(`/order/confirm-admin/${orderId}`);
    return { data: data.data };
  });

export const confirmPaymentOrder = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/queue/confirm-order-payment`, payload);
    return { data: data.data };
  });

export const markOrdertAsConsultantDecision = (
  consultantId,
  entityId,
  decision
) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/queue/mark_order_as_consultant_decision/${entityId}`,
      {
        consultant: consultantId,
        decision: decision,
      }
    );
    return { data: data.data };
  });

export const createAdditionalOrder = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/consultant/create/additional_order`, {
      payload,
    });

    return { data: data.data };
  });

export const updateOrderByConsultant = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/consultant/edit/${id}`, {
      payload,
    });
    return { data: data.data };
  });
export const updateOrder = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/edit/${id}`, {
      payload,
    });
    return { data: data.data };
  });

export const createOrderWithShipping = (id, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/create_with_shipping/${id}`, {
      payload,
    });
    return { data: data.data };
  });

export const getConsultantOrders = (id, payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/order/get_order_with_filter/${id}?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const addProductToOrder = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/${id}/product`, payload);
    return { data: data.data };
  });

export const changeDeliveryDate = (id, date) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/update-delivery-date/${id}`, {
      delivery_date: date,
    });
    return { data: data.data };
  });

export const getLastMailedOrdersAndShifts = () =>
  errorHandler(async () => {
    const data = await api.get(`/order/last-mailed-and-shifted-orders`, {});
    return { data: data.data };
  });

export const markOrderAsResigned = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/mark-order-as-resigned`, payload);
    return { data: data.data };
  });

export const markOrderAsClaim = (_id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/mark-order-as-claim/` + _id, {
      ...payload,
    });
    return { data: data.data };
  });

export const changeClaimStatus = (_id, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/change-claim-status/${_id}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const markAsPaid = (_id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/order/mark-order-as-paid/` + _id, payload);
    return { data: data.data };
  });

export const searchOrdersByClaimFilter = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/search-by-claim/`, {
      ...payload,
    });
    return { data: data.data };
  });

export const orderClaim = (id, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/update-claim/${id}`, {
      ...payload,
    });
    return { data: data.data };
  });

export const orderClaimDone = (id, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/mark-claim-done/${id}`, {
      ...payload,
    });
    return { data: data.data };
  });

export const getClaimByOrderId = (id) =>
  errorHandler(async () => {
    const data = await api.get(`/order/get-claim-by-order/${id}`);

    return { data: data.data };
  });

export const searchNotEndedClaimsByProduct = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/order/find-claims-by-product?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getArchives = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/order/archives/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getAllArchives = () =>
  errorHandler(async () => {
    const data = await api.get("order/all-archives");

    return { data: data.data };
  });

export const addToQueue = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/order/add-archives`, payload);
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
