import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h4`
  margin: 20px;
  color: ${Colors.darkBlue};
  text-decoration: underline;
`;

export const ProductNameWrapper = styled.p`
  font-size: 20px;
  display: flex;
  padding: 0 30px;
`;

export const ProductNameLabel = styled.strong`
  margin-right: 20px;
  color: ${Colors.darkBlue};
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
