import React from "react";
import { SMS_TEMPLATE_TYPES } from "@/common/constants/CC";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { EditWrapper } from "./SMSTemplateTable.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";

const SMSTemplateTable = ({ data, handleSelectTemplate }) => {
  const {
    options: { productsOptions },
  } = useCommonDataContext();

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Number</th>
          <th>Template type</th>
          <th>Product</th>
          <th>Message</th>
          <th>Is active</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data.map((element) => (
          <tr key={element._id}>
            <td>{element.number}</td>
            <td>
              {
                SMS_TEMPLATE_TYPES?.find((type) => type.value === element?.type)
                  ?.label
              }
            </td>
            <td>
              {
                productsOptions?.find(
                  (product) => product.value === element.product
                )?.label
              }
            </td>
            <td>{element.message}</td>
            <td>
              {element.is_avaiable ? (
                <b style={{ color: "green" }}>Yes</b>
              ) : (
                <b style={{ color: "red" }}>No</b>
              )}
            </td>
            <td>
              <EditWrapper>
                <CustomButtonAdd
                  onClick={(e) => handleSelectTemplate(e, element)}
                  defaultText="Edit"
                />
              </EditWrapper>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SMSTemplateTable;
