import { Colors } from '@/common/colors/colors';
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd';
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import React, { useRef } from 'react'
import { ButtonWrapper } from '../../ValidClaims.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { addFileToValidClaim } from '@/API/repositories/validClaim';

const AddFile = ({ setAddFile, reload, addMessageToParent, claimId }) => {
  const fileRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleAddFile = async () => {
    if (!fileRef.current?.files?.length) {
      return addMessageToParent("Please select file", "error");
    }

    const formData = new FormData();

    formData.append("file", fileRef.current.files[0]);

    const response = await makeRequest(
      addFileToValidClaim.bind(null, claimId, formData)
    );

    if (!response?.data) {
      return addMessageToParent("Error while adding file", "error");
    }

    addMessageToParent("File added", "success");
    handleClose();
  }

  const handleClose = () => {
    setAddFile(null);
    reload();
  }

  return (
    <PopUp padding='8px 20px' setShow={handleClose}>
      {hasUnfilledRequest(addFileToValidClaim) && <Loading />}
      <Input
        width={75}
        fontSize='15px'
        inputFontSize='15px'
        inputRef={fileRef} 
        name='File' 
        type='file' 
        color={Colors.darkBlue}
      />
      <ButtonWrapper>
        <CustomButtonAdd
          onClick={handleAddFile}
          defaultText={"Save"}
          color={Colors.red}
        />
      </ButtonWrapper>
    </PopUp>
  )
}

export default AddFile
