import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { searchOrdersByClaimFilter } from "../../../API/repositories/order";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import Loading from "../../../common/components/Loading";
import SelectInput from "../../../common/components/SelectInput";
import { CLAIM_STATUS_OPTIONS } from "../../../common/constants/Accounting";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "../../../common/messageProvider";
import ClaimEditHrView from "../claimNotEnded/components/claimEditHrView./ClaimEditHrView";
import { PRIORITY_SORT } from "../../../common/constants/priorities";
import Table from "../../../common/components/Table";
import { SearchBar, Wrapper } from "./claimsSearch.styles";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { getToday } from "@/common/functions/getToday";

const ClaimsSearch = () => {
  const [edit, setEdit] = useState();
  const [data, setData] = useState();

  const todayMinus30Days = moment().subtract("1", "month").format("YYYY-MM-DD");
  const today = getToday();

  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();

  const deliveredFromRef = useRef();
  const deliveredToRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e?.preventDefault();

    setData(() => null);

    const payload = {};

    payload.deliveredFrom = deliveredFromRef.current
      ? moment(deliveredFromRef.current.value).startOf("date")
      : moment().subtract("1", "month").startOf("date");
    payload.deliveredTo = deliveredToRef.current
      ? moment(deliveredToRef.current.value).endOf("date")
      : moment().endOf("date");
    payload.status = selectedStatus?.value;
    payload.payment_status = selectedPaymentStatus?.value;

    const response = await makeRequest(
      searchOrdersByClaimFilter.bind(null, payload)
    );

    if (response.data) {
      const sortedData = response.data
        .sort(
          (a, b) =>
            new Date(a.claim.created_at).getTime() -
            new Date(b.claim.created_at).getTime()
        )
        .sort(
          (a, b) =>
            PRIORITY_SORT[a.claim.priority] - PRIORITY_SORT[b.claim.priority]
        );
      setData(() => sortedData);
    }
  };

  const getColor = (priority) => {
    switch (priority) {
      case "low_priority":
        return Colors.yellow;
      case "high_priority":
        return Colors.red;
      default:
        return "";
    }
  };

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const headers = [
    "No.",
    "Full name",
    "Mail",
    "Product",
    "Delivered at",
    "Claim to",
    "Claim status",
    "Created at",
    "Actions",
  ];
  const raws = useMemo(() => {
    if (data && data[0]) {
      return data.map((element, i) => (
        <tr
          key={element.claim.created_at}
          style={{ backgroundColor: getColor(element.claim.priority) }}
        >
          <td>{i + 1}</td>

          <td>{element._contact.full_name}</td>

          <td>{element._contact.email}</td>

          <td>{element._product.name}</td>

          <td>
            {moment(element.shipping.status_delivered_at).format("YYYY-MM-DD")}
          </td>

          <td>
            {moment(element.shipping.status_delivered_at)
              .add("14", "days")
              .format("YYYY-MM-DD")}
          </td>

          <td>{element.claim.status || "created"}</td>

          <td>{moment(element.claim.created_at).format("DD-MM-YYYY")}</td>

          <td>
            <i
              className="fa fa-edit animation-scale"
              style={{
                fontSize: "14px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setEdit(() => element)}
            ></i>
          </td>
        </tr>
      ));
    }
  }, [data]);

  useEffect(() => {
    handleSearch();
  }, [edit]);

  return (
    <Wrapper>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(searchOrdersByClaimFilter) && <Loading />}
      <SearchBar>
        <Input
          color={Colors.darkBlue}
          inputRef={deliveredFromRef}
          name="Delivered from"
          type="date"
          width={140}
          inputWidth={130}
          value={todayMinus30Days}
        />
        <Input
          color={Colors.darkBlue}
          inputRef={deliveredToRef}
          name="Delivered to"
          type="date"
          width={110}
          inputWidth={130}
          value={today}
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Status"
          width={80}
          selectWidth={120}
          selected={selectedStatus}
          setSelected={setSelectedStatus}
          options={CLAIM_STATUS_OPTIONS}
        />
        <CustomButtonAdd
          defaultText={"Search"}
          onClick={(e) => handleSearch(e)}
        />
      </SearchBar>
      {data && data.length > 0 && (
        <Table
          headers={headers}
          raws={raws}
          className="styled-table-2 styled-table"
        />
      )}
      {edit && (
        <ClaimEditHrView
          setShow={setEdit}
          data={edit}
          addMessage={addMessage}
        />
      )}
    </Wrapper>
  );
};

export default ClaimsSearch;
