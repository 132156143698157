import React, { useState } from 'react'
import SelectInput from '@/common/components/SelectInput'
import { Colors } from '@/common/colors/colors'
import { ReasoningWrapper, Textarea } from './Reasoning.styled'
import { SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import ClaimTagForm from '@/pages/admin/claimTag/components/claimTagForm/ClaimTagForm'
import { CLAIM_REASONS } from '@/common/constants/support'
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue'

const ADD_TAG_OPTION = 'add_tag'

const Reasoning = ({ 
  tagsOptions, 
  setSelectedTags, 
  selectedTags, 
  loadTags,
  claimReason,
  setClaimReason,
  feedbackRef,
  claim,
}) => {
  const [showAddTag, setShowAddTag] = useState(false)

  const { addMessage } = useNewMessageQueueContext();

  const handleSelectTag = (options) => {
    if (options.some(option => option.value === ADD_TAG_OPTION)) {
      return setShowAddTag(true)
    }

    setSelectedTags(options)
  }

  const reload = async () => {
    const previousTagsIds = tagsOptions?.map(tag => tag.value);
    const currentTags = await loadTags();
    const currentTagsIds = currentTags?.map(tag => tag.value);
    

    const newTagId = currentTagsIds.find(tag => !previousTagsIds?.includes(tag));
    const newTag = currentTags.find(tag => tag.value === newTagId);

    if (newTag) {
      setSelectedTags((prev) => [...prev, newTag]);
    }
  }

  return (
    <SectionWrapper>
      <ReasoningWrapper>
        <div>
          <Subtitle>
            Claim reason
          </Subtitle>
          <SelectInput
            disabled={claim}
            selected={claimReason}
            setSelected={setClaimReason}
            placeholder="Select claim reason"
            showLabel={false}
            selectWidth={180}
            selectFontSize={12}
            options={CLAIM_REASONS}
            margin='0'
            minHeight='18px'
            dropdownIndicatorPadding='4px'
            placeholderColor={Colors.darkGray2}
          />
        </div>
        <div>
          <Subtitle>
            Reason tag
          </Subtitle>
          <SelectInput
            disabled={claim}
            selected={selectedTags}
            multiple
            setSelected={handleSelectTag}
            options={[
              ...tagsOptions,
              {
                label: <span style={{ color: Colors.purple}}>+ Add tag</span>,
                value: ADD_TAG_OPTION
              }
            ]}
            placeholder="add tags"
            showLabel={false}
            selectWidth={180}
            selectFontSize={12}
            margin='0'
            minHeight='18px'
            dropdownIndicatorPadding='2px'
            placeholderColor={Colors.darkGray2}
          />
        </div>
        <div>
          <Subtitle>
            Client comment
          </Subtitle>
          <Textarea 
            disabled={claim} 
            defaultValue={feedbackRef.current?.value}  
            ref={feedbackRef} 
            placeholder="Enter client's comment here" 
          />
        </div>
      </ReasoningWrapper>
      {showAddTag && (
        <ClaimTagForm 
          setShow={setShowAddTag} 
          addMessage={addMessage}
          reload={reload}
        />
      )}
    </SectionWrapper>
  )
}

export default Reasoning
