export const CONSULTANT_HEADERS_ARRAY = [
  [
    <th>No.</th>,
    <th>Market</th>,
    <th>Name</th>,
    <th>Surname</th>,
    <th>Payment</th>,
  ],
];

export const MARKET_HEADERS_ARRAY = [
  [<th>No.</th>, <th>Market</th>, <th>Payment sum</th>],
];
