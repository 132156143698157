import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Input from "../../../common/components/Input";
import { getAllPosibleFiledsToFilter } from "../../../API/repositories/order";
import { SubTitle } from "../../../common/styles/Mails";

const BorderLine = styled.div`
  width: 100%;
  background-color: ${Colors.lightlightBlue};
  height: 1px;
`;

const ColorTitle = styled.h5`
  color: ${Colors.darkBlue};
`;

const OptionWrapper = styled.div`
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ARITHMETIC_OPTIONS = [
  {
    label: "equal",
    value: "$eq",
  },
  {
    label: "lesser",
    value: "$lt",
  },
  {
    label: "lesser or equal",
    value: "$lte",
  },
  {
    label: "grater",
    value: "$gt",
  },
  {
    label: "grater or equal",
    value: "$gte",
  },
];

const TIME_UNIT_OPTIONS = [
  {
    label: "Hours",
    value: "hours",
  },
  {
    label: "Days",
    value: "days",
  },
];

const FilterOptionFinderString = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    if (value.length > 0) {
      return dispatchFilters({
        type: "add",
        key: fieldData.label,
        value: value,
        type_field: "String",
      });
    }
    return dispatchFilters({
      type: "remove",
      key: fieldData.label,
      value: value,
    });
  };

  const handleOnChangeEnum = (value, site) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      value: {
        ...(filters[fieldData.label]?.value || {}),
        [site]: value.map((v) => v.value),
      },
      type_field: "String",
      enumValues: fieldData.value.enumValues,
    });
  };

  if (fieldData.value.enumValues) {
    const options = fieldData.value.enumValues.map((option) => {
      return { label: option, value: option };
    });
    const current = filters[fieldData.label]?.value;

    return (
      <OptionWrapper>
        <ColorTitle>{fieldData.label}:</ColorTitle>
        <BorderLine />
        <div>IN ARR</div>
        <SelectInput
          showLabel={false}
          options={options}
          selected={options.filter((option) =>
            current?.in?.includes(option.value)
          )}
          setSelected={(e) => handleOnChangeEnum(e, "in")}
          multiple={true}
          selectWidth={500}
        />
        <div>NOT IN ARR</div>
        <SelectInput
          showLabel={false}
          options={options}
          selected={options.filter((option) =>
            current?.nin?.includes(option.value)
          )}
          setSelected={(e) => handleOnChangeEnum(e, "nin")}
          multiple={true}
          selectWidth={500}
        />
      </OptionWrapper>
    );
  }

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        value={filters[fieldData.label]?.value}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderNumber = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      value: value,
      type_field: "Number",
      arithmetic: filters[fieldData.label]?.arithmetic,
    });
  };

  const handleOnChangeArithmetic = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      arithmetic: value,
      value: filters[fieldData.label]?.value,
      type_field: "Number",
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={ARITHMETIC_OPTIONS}
        showLabel={false}
        selected={ARITHMETIC_OPTIONS.find(
          (e) => e.value === filters[fieldData.label]?.arithmetic
        )}
        onChange={(e) => handleOnChangeArithmetic(e)}
      />
      <Input
        showLabel={false}
        type="number"
        value={filters[fieldData.label]?.value}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </OptionWrapper>
  );
};
const FilterOptionFinderDate = ({ fieldData, filters, dispatchFilters }) => {
  const [selectedUnit, setSelectedUnit] = useState(
    TIME_UNIT_OPTIONS.find(
      (unit) =>
        filters[fieldData?.label] && filters[fieldData?.label][unit?.value]
    )
  );
  const amountRef = useRef();

  const handleOnChange = (value, from) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Date",
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      [from]: value,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
    });
  };

  const handleOnChangeArithmetic = (value, from) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Date",
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
      [selectedUnit?.value]: amountRef.current?.value,
      [from]: value,
    });
  };

  const handleOnChangeUnit = (unit) => {
    setSelectedUnit(() =>
      TIME_UNIT_OPTIONS.find((option) => option.value === unit)
    );

    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Date",
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
      [unit]: amountRef.current?.value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectInput
            options={ARITHMETIC_OPTIONS}
            showLabel={false}
            selected={ARITHMETIC_OPTIONS.find(
              (e) => e.value === filters[fieldData.label]?.arithmetic_from
            )}
            setSelected={() => {}}
            onChange={(e) => handleOnChangeArithmetic(e, "arithmetic_from")}
            selectWidth={120}
          />
          <SelectInput
            options={TIME_UNIT_OPTIONS}
            name="Unit"
            selected={selectedUnit}
            selectWidth={120}
            width={20}
            setSelected={() => {}}
            onChange={(e) => handleOnChangeUnit(e)}
            color={Colors.darkBlue}
          />
          <Input
            name="Amount"
            type="number"
            inputRef={amountRef}
            disabled={!selectedUnit}
            value={filters[fieldData.label]?.[selectedUnit?.value]}
            onChange={(e) =>
              handleOnChange(e.target.value, selectedUnit?.value)
            }
            width={60}
            inputWidth={80}
            color={Colors.darkBlue}
          />
          <h5
            style={{
              width: "40px",
              color: Colors.darkBlue,
              margin: "0px 10px",
            }}
          >
            From:
          </h5>
          <Input
            showLabel={false}
            type="date"
            value={filters[fieldData.label]?.from}
            onChange={(e) => handleOnChange(e.target.value, "from")}
            inputWidth={120}
          />
          <h5
            style={{
              width: "40px",
              color: Colors.darkBlue,
              margin: "0px 10px",
            }}
          >
            To:
          </h5>
          <Input
            showLabel={false}
            type="date"
            value={filters[fieldData.label]?.to}
            onChange={(e) => handleOnChange(e.target.value, "to")}
            inputWidth={120}
          />
        </div>
      </div>
    </OptionWrapper>
  );
};

const FilterOptionExists = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Exists",
      isTrue: value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        type="checkbox"
        checked={filters[fieldData.label]?.isTrue}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderBool = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Bool",
      isTrue: value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        type="checkbox"
        checked={filters[fieldData.label]?.isTrue}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderObjectId = ({
  fieldData,
  filters,
  dispatchFilters,
  markets,
  products,
  consultants,
  queues,
}) => {
  const handleOnChangeEnum = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      value: value.map((v) => v.value),
      type_field: "Ref",
    });
  };
  let options = [];
  const current = filters[fieldData.label]?.value;

  if (
    fieldData.value.ref === "Product" ||
    fieldData.label.includes("product")
  ) {
    options = products;
  }

  if (fieldData.value.ref === "Market" || fieldData.label.includes("market")) {
    options = markets;
  }

  if (fieldData.value.ref === "User" || fieldData.label.includes("by")) {
    options = consultants;
  }

  if (fieldData.value.ref === "Queue") {
    options = queues;
  }

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={options}
        showLabel={false}
        selected={options.filter((option) => current?.includes(option.value))}
        setSelected={(e) => handleOnChangeEnum(e)}
        multiple={true}
        selectWidth={500}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinder = ({
  data,
  filters,
  dispatchFilters,
  markets,
  products,
  consultants,
  queues,
}) => {
  const getObjectIdFilterOption = (fieldData) => {
    if (fieldData.value.type === "Ref") {
      return (
        <FilterOptionFinderObjectId
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
          markets={markets}
          products={products}
          consultants={consultants}
          queues={queues}
        />
      );
    }

    if (fieldData.value.type === "Exists") {
      return (
        <FilterOptionExists
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    }

    switch (fieldData.value.ref) {
      case "Market":
      case "Product":
      case "User":
      case "Queue":
        return (
          <FilterOptionFinderObjectId
            fieldData={fieldData}
            filters={filters}
            dispatchFilters={dispatchFilters}
            markets={markets}
            queues={queues}
            products={products}
            consultants={consultants}
          />
        );
      default:
        return (
          <FilterOptionExists
            fieldData={fieldData}
            filters={filters}
            dispatchFilters={dispatchFilters}
          />
        );
    }
  };

  return data.map((fieldData) => {
    if (fieldData.value.type === "String" || fieldData.value.type === "Enum")
      return (
        <FilterOptionFinderString
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "Date")
      return (
        <FilterOptionFinderDate
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === "Number")
      return (
        <FilterOptionFinderNumber
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (["Boolean", "Bool"].includes(fieldData.value.type))
      return (
        <FilterOptionFinderBool
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (
      fieldData.value.type === "ObjectID" ||
      fieldData.value.type === "Ref" ||
      fieldData.value.type === "Exists"
    ) {
      return getObjectIdFilterOption(fieldData);
    }
  });
};

const CategoryFilter = ({
  filters,
  dispatchFilters,
  selectedData,
  setSelectedData,
  markets,
  products,
  consultants,
  queues,
}) => {
  const [data, setData] = useState();
  const { makeRequest } = useRequestsContext();

  const getData = async () => {
    const response = await makeRequest(getAllPosibleFiledsToFilter);

    if (response.data) {
      setData(() => [
        ...Object.entries(response.data.orderFields).map(([key, value]) => {
          return { label: "order." + key, value: value };
        }),
      ]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSelected = (e) => {
    dispatchFilters({ type: "validate", values: e });
    setSelectedData(e);
  };

  return (
    <div>
      {data && (
        <div>
          <SubTitle>Filters:</SubTitle>
          <SelectInput
            name="Field"
            color={Colors.darkBlue}
            width={30}
            selected={selectedData}
            setSelected={(e) => handleSelected(e)}
            options={data}
            multiple={true}
            selectWidth={840}
          />
          <FilterOptionFinder
            data={selectedData}
            dispatchFilters={dispatchFilters}
            filters={filters}
            markets={markets}
            products={products}
            queues={queues}
            consultants={consultants}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
