import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { preventFn } from "../functions/preventEvent";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;

  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(2, 80, 114, 0.78);
  z-index: 999;
`;

const MediaPlayer = ({ recording, setClose, noDownload = false }) => {
  const mediaPlayer = useMemo(
    () => (
      <audio
        controlsList={noDownload ? "nodownload" : ""}
        controls="controls"
        source
        src={[recording]}
        type="audio/mp3"
      />
    ),
    [recording]
  );

  useEffect(() => {
    document.addEventListener("contextmenu", preventFn);

    return () => document.removeEventListener("contextmenu", preventFn);
  }, []);

  return <Wrapper onClick={() => setClose(() => null)}>{mediaPlayer}</Wrapper>;
};

export default MediaPlayer;
