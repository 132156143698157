import React, { useEffect, useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import { SMS_TEMPLATE_TYPES } from "@/common/constants/CC";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import Input from "@/common/components/Input";
import {
  createSMSTemplate,
  updateSMSTemplate,
} from "@/API/repositories/calling";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  FormWrapper,
  MessageBoxWrapper,
  MessageLableWrapper,
  TextArea,
} from "./SMSForm.styled";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";

const SMSForm = ({
  setShow,
  mode,
  handleGetSmsTemplates,
  cloudTalkNumbersOptions,
  selectedTemplate,
}) => {
  const [selectedProducts, setSelectedProducts] = useState();
  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedTemplatesType, setSelectedTemplateType] = useState();

  const isActiveRef = useRef();
  const textAreaRef = useRef();
  const {
    options: { productsOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleEditSmsTemplate = async (e) => {
    e.preventDefault();

    const payload = Object();
    payload.type = selectedTemplatesType.value;
    payload.product = selectedProducts.value;
    payload.number = selectedNumber.label;
    payload.message = textAreaRef.current?.value || selectedTemplate?.message;
    payload.is_avaiable = isActiveRef.current?.checked;

    const response = await makeRequest(
      updateSMSTemplate.bind(null, selectedTemplate?._id, payload)
    );

    if (response.data) {
      await handleGetSmsTemplates();
      setShow(() => null);
    }
  };

  const handleCreateSmsTemplate = async (e) => {
    e.preventDefault();
    const payload = Object();
    payload.type = selectedTemplatesType.value;
    payload.product = selectedProducts.value;
    payload.number = selectedNumber.label;
    payload.message = textAreaRef.current.value;
    payload.is_avaiable = isActiveRef.current.checked || false;

    const response = await makeRequest(createSMSTemplate.bind(null, payload));

    if (response.data) {
      await handleGetSmsTemplates();
      setShow(() => null);
    }
  };

  const handleSubmit = (e) => {
    if (mode === "create") return handleCreateSmsTemplate(e);

    return handleEditSmsTemplate(e);
  };

  const handleSelectTemplateData = () => {
    setSelectedNumber(() =>
      cloudTalkNumbersOptions?.find(
        (product) => product.label === selectedTemplate.number
      )
    );
    setSelectedProducts(() =>
      productsOptions?.find(
        (product) => product.value === selectedTemplate.product
      )
    );
    setSelectedTemplateType(() =>
      SMS_TEMPLATE_TYPES?.find((type) => type.value === selectedTemplate.type)
    );
  };

  useEffect(() => {
    if (selectedTemplate) {
      handleSelectTemplateData();
    }
  }, [selectedTemplate]);

  return (
    <PopUp setShow={setShow}>
      <FormWrapper>
        <form onSubmit={(e) => handleSubmit(e)}>
          <h3 style={{ color: Colors.darkBlue }}>Edit: </h3>
          <SelectInput
            name="Type"
            width={120}
            options={SMS_TEMPLATE_TYPES}
            setSelected={setSelectedTemplateType}
            selected={selectedTemplatesType}
          />
          <SelectInput
            name="Product"
            width={120}
            options={productsOptions}
            setSelected={setSelectedProducts}
            selected={selectedProducts}
          />
          <SelectInput
            name="Number"
            width={120}
            options={cloudTalkNumbersOptions}
            setSelected={setSelectedNumber}
            selected={selectedNumber}
          />
          <MessageBoxWrapper>
            <MessageLableWrapper>
              <strong>Message:</strong>
            </MessageLableWrapper>
            <TextArea
              ref={textAreaRef}
              rows="4"
              cols="26"
              wrap
              style={{ marginLeft: "30px" }}
              required
              defaultValue={selectedTemplate?.message}
            />
          </MessageBoxWrapper>
          <Input
            inputRef={isActiveRef}
            type="checkbox"
            name="Acitve"
            width={120}
            checked={selectedTemplate?.is_avaiable}
          />
          <CustomButtonSave
            styles={{ float: "right", alignSelf: "flex-end" }}
            text="Save"
          />
        </form>
      </FormWrapper>
    </PopUp>
  );
};

export default SMSForm;
