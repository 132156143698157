import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

export const createPage = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/recruitment-page/create`, payload);

    return { data: data.data };
  });

export const updatePage = (payload, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/recruitment-page/update/${id}`, payload);

    return { data: data.data };
  });

export const getPages = () =>
  errorHandler(async () => {
    const data = await api.get(`/recruitment-page/get`);

    return { data: data.data };
  });
