import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  margin-top: 20px;
`;

export const SearchBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const SwitchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FlexForm = styled.form`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue}
`;
