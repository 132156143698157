import React, { useEffect, useRef, useState } from "react";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getLanguages } from "@/API/repositories/language";
import userManager from "@/API/userManager";
import {
  LanguageWrapper,
  LanguagesSelection,
  LanugageImage,
} from "./LanguagesChanger.styled";
import { DEFAULT_LANGUAGE } from "./constants/languages";
import { getFlagSource } from "./helpers/getFlagSource";
import { chnageUserSettings } from "@/API/repositories/user";
import translationManager from "@/API/translationManager";
import Api from "@/API/api";

const { tokenManager } = Api;

const LanguagesChanger = () => {
  const [currentLang, setCurrentLang] = useState(
    userManager?.getUser()?.lang || DEFAULT_LANGUAGE
  );
  const [isLanguagesOpen, setIsLanguagesOpen] = useState();
  const [languages, setLanguages] = useState();
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);

  const currentUserId = userManager?.getUser()?.id;

  const languageWrapperRef = useRef();

  const { makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getLanguages);

    if (response.data) {
      setLanguages(() => response.data);
    }
  };

  const handleSelectFlag = async (short) => {
    const payload = {};
    setIsLanguagesOpen(() => false);
    setIsUpdatingLanguage(() => true);

    payload.language = short;

    await makeRequest(chnageUserSettings.bind(null, currentUserId, payload));

    const token = tokenManager.getToken();
    const refreshToken = tokenManager.getRefreshToken();

    await tokenManager.refreshToken(token, refreshToken);

    const newToken = tokenManager.getToken();
    userManager.setUser(tokenManager.parseJwt(newToken));

    await translationManager.getLanguage();

    setCurrentLang(() => userManager.getUser().lang);
    setIsUpdatingLanguage(() => false);
    window.location.reload();
  };

  const checkClicked = (e) => {
    if (!e?.target) {
      return;
    }

    const clickedElement = e.target;

    if (languageWrapperRef.current.contains(clickedElement)) {
      return;
    }

    if (e.target.id === "languages") {
      return;
    }

    setIsLanguagesOpen(() => false);
  };

  useEffect(() => {
    document.addEventListener("click", checkClicked);

    return () => document.removeEventListener("click", checkClicked);
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <LanguageWrapper
        ref={languageWrapperRef}
        onClick={() => setIsLanguagesOpen((prev) => !prev)}
      >
        {!isUpdatingLanguage ? (
          <LanugageImage
            draggable={false}
            alt="flag"
            src={getFlagSource(currentLang)}
          />
        ) : (
          <i className="fa fa-spinner fa-spin" />
        )}
      </LanguageWrapper>
      <div>
        {isLanguagesOpen && !!languages?.length && !isUpdatingLanguage && (
          <LanguagesSelection id="languages">
            {languages
              .filter((language) => language.short !== currentLang)
              .map((language) => (
                <div
                  key={language.short}
                  onClick={() => handleSelectFlag(language.short)}
                >
                  <LanugageImage
                    draggable={false}
                    alt="flag"
                    src={getFlagSource(language.short)}
                  />
                </div>
              ))}
          </LanguagesSelection>
        )}
      </div>
    </>
  );
};

export default LanguagesChanger;
