import { ClAIM_METHODS } from "@/common/constants/validClaims"

export const findCalimProvider = (claim) => {
	if (claim.payment_method === ClAIM_METHODS.CASH.value) {
		return ClAIM_METHODS.CASH.label;
	}

	if (claim.currency === 'eur') {
		return 'ING';
	}

	return 'Revolut'
}