import React from "react";
import PopUp from "../../../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";

const Text = styled.h2`
  display: flex;
  color: ${Colors.darkBlue};
  text-align: center;
  justify-content: center;
`;

const Calling = ({ translation, fullName }) => {
  return (
    <PopUp canOffByClickingBackground={false}>
      <Text>
        {translation.calling} {fullName}
      </Text>
    </PopUp>
  );
};

export default Calling;
