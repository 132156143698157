export class MarketCreate {
  name;
  short;
  currency;
}

export class MarketUpdate extends MarketCreate {
  _id;
  regions;
  promition;
}
