import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;

  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(2, 80, 114, 0.78);
  z-index: ${(props) => props.zIndex};
`;

const Box = styled.div`
  min-width: ${(props) => props.minWidth};
  position: fixed;
`;

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid grey;
  background: white;
  padding: 10px 20px 50px 20px;
  max-height: ${(props) => props.maxHeight};
  overflow-y: scroll;
`;

const PopUp = ({
  setShow,
  children,
  canOffByClickingBackground = true,
  closeIcon = true,
  padding = "10px 20px 50px 20px",
  zIndex = 10,
  maxHeight = "90vh",
  crossHeight = "32px",
  minWidth = '500px'
}) => {
  return (
    <Wrapper zIndex={zIndex}>
      {canOffByClickingBackground && (
        <div
          onClick={() => setShow((prev) => !prev)}
          style={{ width: "100%", height: "100%" }}
        ></div>
      )}
      <Box minWidth={minWidth} >
        <Card maxHeight={maxHeight} style={{ padding: padding }}>
          {" "}
          {closeIcon && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
                color: "red",
              }}
            >
              <i 
                onClick={() => setShow((prev) => !prev)}
                className="fa fa-remove "
                style={{
                  fontSize: crossHeight,
                  marginRight: "-20px",
                  marginTop: "-5px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
          {children}
        </Card>
      </Box>
    </Wrapper>
  );
};

export default PopUp;
