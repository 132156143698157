import {
  DateWrapper,
  ShippingInfoWrapper,
  TimelineDataWrapper,
  TimelineRound,
} from "../ShippingTimeline.styled";
import { formatShippingData } from "./formatShippingData";

export const getShippingTimeline = (shipping) => (
  <TimelineDataWrapper>
    {!!formatShippingData(shipping)?.length
      ? formatShippingData(shipping).map((el, i) => (
          <DateWrapper key={el.date}>
            <ShippingInfoWrapper>{el.date}</ShippingInfoWrapper>
            <TimelineRound>{i + 1}</TimelineRound>
            <ShippingInfoWrapper>{el.label}</ShippingInfoWrapper>
          </DateWrapper>
        ))
      : "No shipping history yet"}
  </TimelineDataWrapper>
);
