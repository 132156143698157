export const getConsultantRows = (mainReportData) => {
  if (!mainReportData) return [];

  return mainReportData
    .map((data, i) => (
      <tr>
        <td>{i + 1}</td>
        <td>{data.market?.name}</td>
        <td>{data.name}</td>
        <td>{data.surname}</td>
        <td>
          {data.totalSum.toFixed(2)} {data.currency}
        </td>
      </tr>
    ));
};

export const getMarketRows = (marketsData) => {
  if (!marketsData) return [];

  return marketsData.map((data, i) => (
    <tr>
      <td>{i + 1}</td>
      <td>{data.name}</td>
      <td>
        {data.sum.toFixed(2)} {data.currency}
      </td>
    </tr>
  ));
};
