const ExcelJS = require("exceljs");

export const generateExcelSheetBuffer = async (excelData, sheetName) => {
	const workbook = new ExcelJS.Workbook();
	const sheet = workbook.addWorksheet(sheetName);

	sheet.defaultRowHeight = 20;

	sheet.getRow(1).border = {
		top: { style: "thin", color: { argb: "000000" } },
		left: { style: "thin", color: { argb: "000000" } },
		bottom: { style: "thin", color: { argb: "000000" } },
		right: { style: "thin", color: { argb: "000000" } },
	};

	sheet.getRow(1).fill = {
		type: "pattern",
		pattern: "solid",
		fgColor: { argb: "6dbf9e" },
	};

	sheet.getRow(1).font = {
		size: 12,
		bold: true,
	};

	sheet.getRow(1).height = 20;
	sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

	sheet.columns = Object.entries(excelData[0]).map(([key, value]) => ({
		header: key,
		key: key,
		width: key.split("").length * 1.2,
		numFmt: "0.00",
	}));

	excelData?.map((data, i) => {
		sheet.addRow({
			...data,
		});
		sheet.getRow(i + 2).height = 25;
		sheet.getRow(i + 2).alignment = {
			vertical: "middle",
			horizontal: "center",
		};
	});

	return await workbook.xlsx.writeBuffer()
}