import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
  height: 95vh;
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const ScoringWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
`;

export const Title = styled.h3`
  display: flex;
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;
