import React from "react";
import { DayNameWrapper } from "../calendar.styles";

const CALENDAR_DAYS_NAME = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const TopDates = () => {
  return (
    <>
      {CALENDAR_DAYS_NAME.map((dayName) => (
        <DayNameWrapper>{dayName}</DayNameWrapper>
      ))}
    </>
  );
};

export default TopDates;
