export const moveProductGroupToMarket = (data) => {
	const marketsMap = new Map();

	data.forEach(item => {
		const market = item.product_name.trim().split(' ').at(-1);

		if (!marketsMap.has(market)) {
			marketsMap.set(market, {
				to_pay: 0,
				to_check: 0,
				check_in_progress: 0,
				overdue: 0
			});
		}

		const prevData = marketsMap.get(market);

		marketsMap.set(market, {
			to_pay: prevData.to_pay + item.to_pay,
			to_check: prevData.to_check + item.to_check,
			check_in_progress: prevData.check_in_progress + item.check_in_progress,
			overdue: prevData.overdue + item.overdue
		});
	});

	return Array.from(marketsMap).map(([market, data]) => ({
		product_name: market,
		...data
	}));
}