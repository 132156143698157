import { getLastTicketNotification } from "./getLastTicketNotification";

export const isMessageSeen = (ticket, userId) => {
  const lastMessage = getLastTicketNotification(ticket);

  return (
    (lastMessage.sender || lastMessage.created_by) !== userId &&
    !lastMessage.seen?.includes(userId)
  );
};
