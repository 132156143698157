export const groupData = (data) => {
  const map = new Map();

  data.forEach((d) => {
    const market = d.queue.name;
    if (!map.has(market)) {
      return map.set(market, [d]);
    }
    map.get(market).push(d);
  });

  return Array.from(map).map(([name, value]) => ({ name, value }));
};
