import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 40px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
`;
