import React from "react";
import userManager from "@/API/userManager";
import { BellWrapper, BellIcon } from "./Bell.styled";
import { getAmountOfNotSeenMessages } from "../../helpers/getAmountOfNotSeenMessages";
import { getBellColor } from "./helpers/getBellColor";

const Bell = ({ setIsTicketsViewOpen, tickets }) => {
  const currentUserId = userManager.getUser().id;

  return (
    <BellWrapper
      onClick={() => setIsTicketsViewOpen(() => true)}
      className="animation-scale"
      color={getBellColor(getAmountOfNotSeenMessages(tickets, currentUserId))}
    >
      <BellIcon
        color={getBellColor(getAmountOfNotSeenMessages(tickets, currentUserId))}
        amount={getAmountOfNotSeenMessages(tickets, currentUserId)}
        className="fa fa-bell"
      />
    </BellWrapper>
  );
};

export default Bell;
