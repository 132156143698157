import React from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import userManager from "../../../API/userManager";
import { useNavigate } from "react-router-dom";
import Api from "../../../API/api";

const { tokenManager } = Api;

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  color: ${Colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Loguout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    userManager.removeUser();
    tokenManager.removeToken();
    navigate("/login");
  };

  return (
    <Wrapper className="animation-scale" onClick={() => handleLogout()}>
      <i className="fa fa-right-from-bracket " style={{ fontSize: "16px" }} />
    </Wrapper>
  );
};

export default Loguout;
