import React, { useRef, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import SelectInput from "../../../../common/components/SelectInput";
import moment from "moment";
import { COUNTRIES } from "../../../../common/constants/countries";
import { getToday } from "@/common/functions/getToday";

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 10px 0px;
`;

const ShipingInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0px 20px 0px 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
  width: 400px;
`;

const InputFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h5`
  margin: 10px 20px;
  font-weight: bold;
  text-decoration: underline;
`;

const ShippingView = ({
  addMessage,
  setPageView,
  setShippingData,
  shippingData,
  contact,
  translation,
}) => {
  const [isInvoiceSameData, setIsInvoiceSameData] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    shippingData
      ? { label: shippingData.country, value: shippingData.country_code }
      : COUNTRIES.find((country) => country.value === contact.country)
  );

  const nameRef = useRef();
  const phoneNumberRef = useRef();
  const surnameRef = useRef();
  const postalCodeRef = useRef();
  const streetRef = useRef();
  const emailRef = useRef();
  const commentRef = useRef();
  const cityRef = useRef();
  const suggestedDeliveryDateRef = useRef();
  const invoiceComapnyName = useRef();
  const invoiceCompanyStreet = useRef();
  const invoicePostalCode = useRef();
  const invoiceHouseNumber = useRef();
  const invoiceNIP = useRef();
  const invoiceVAT = useRef();

  const today = getToday();

  const handleNextPage = (e) => {
    e.preventDefault();

    let payload = Object();

    payload.name = nameRef.current ? nameRef.current.value : contact.first_name;
    payload.surname = surnameRef.current
      ? surnameRef.current.value
      : contact.last_name;
    payload.email = emailRef.current ? emailRef.current.value : contact.email;
    payload.phone_number = phoneNumberRef.current
      ? phoneNumberRef.current.value
      : contact.phone_number;

    if (
      streetRef.current &&
      postalCodeRef.current &&
      selectedOption.label &&
      selectedOption.value &&
      suggestedDeliveryDateRef.current &&
      cityRef.current
    ) {
      payload.postal_code = postalCodeRef.current.value;
      payload.street = streetRef.current.value;
      payload.country = selectedOption.label;
      payload.country_code = selectedOption.value;
      payload.suggested_delivery_date = suggestedDeliveryDateRef.current
        ? suggestedDeliveryDateRef.current.value
        : today;
      payload.comment = commentRef.current ? commentRef.current.value : "";
      payload.city = cityRef.current.value;

      setShippingData(() => payload);
      setPageView(3);
    } else {
      addMessage("please fill all fields", "error");
    }
  };

  return (
    <form onSubmit={(e) => handleNextPage(e)}>
      <Title>{translation.basicInfo}:</Title>
      <ShipingInfoWrapper>
        <InputFlexColumn>
          <InputWrapper>
            <label>{translation.name}:</label>
            <input
              ref={nameRef}
              type="text"
              required
              defaultValue={
                shippingData ? shippingData.name : contact.first_name
              }
            />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.surname}:</label>
            <input
              ref={surnameRef}
              type="text"
              required
              defaultValue={
                shippingData ? shippingData.surname : contact.last_name
              }
            />
          </InputWrapper>
          <InputWrapper>
            <label>Email:</label>
            <input
              ref={emailRef}
              type="email"
              required
              defaultValue={shippingData ? shippingData.email : contact.email}
              placeholder="type..."
            />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.postalCode}:</label>
            <input
              ref={postalCodeRef}
              type="text"
              required
              defaultValue={shippingData ? shippingData.postal_code : ""}
              placeholder="type..."
            />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.street}:</label>
            <input
              ref={streetRef}
              type="text"
              required
              defaultValue={shippingData ? shippingData.street : ""}
              placeholder="type..."
            />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.city}:</label>
            <input
              ref={cityRef}
              type="text"
              required
              defaultValue={shippingData ? shippingData.city : ""}
              placeholder="type..."
            />
          </InputWrapper>
        </InputFlexColumn>
        <InputFlexColumn>
          <SelectInput
            width={90}
            name={translation.country}
            selected={selectedOption}
            setSelected={setSelectedOption}
            options={COUNTRIES}
          />
          <InputWrapper>
            <label>{translation.countryCode}:</label>
            <input type="text" value={selectedOption.value} disabled required />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.deliveryDate}:</label>
            <input
              ref={suggestedDeliveryDateRef}
              type="date"
              min={today}
              defaultValue={
                shippingData ? shippingData.suggested_delivery_date : today
              }
              required
              style={{ width: "270px" }}
            />
          </InputWrapper>
          <InputWrapper>
            <label>{translation.comment}: </label>
            <textarea
              ref={commentRef}
              type="textarea"
              style={{
                height: "60px",
                resize: "none",
                borderRadius: "10px",
                width: "270px",
              }}
              placeholder="type comment..."
            />
          </InputWrapper>
        </InputFlexColumn>
      </ShipingInfoWrapper>
      {isInvoiceSameData ? (
        <div>
          <Title>Invoice: </Title>
          <ShipingInfoWrapper>
            <InputFlexColumn>
              <InputWrapper>
                <label>Company name:</label>
                <input ref={invoiceComapnyName} type="text" />
              </InputWrapper>
              <InputWrapper>
                <label>Street:</label>
                <input ref={invoiceCompanyStreet} type="text" />
              </InputWrapper>
              <InputWrapper>
                <label>Postal Code:</label>
                <input ref={invoicePostalCode} type="text" />
              </InputWrapper>
              <InputWrapper>
                <label>House number:</label>
                <input ref={invoiceHouseNumber} type="text" />
              </InputWrapper>
              <InputWrapper>
                <label>NIP:</label>
                <input ref={invoiceNIP} type="text" />
              </InputWrapper>
              <InputWrapper>
                <label>VAT:</label>
                <input ref={invoiceVAT} type="text" />
              </InputWrapper>
            </InputFlexColumn>
          </ShipingInfoWrapper>
        </div>
      ) : null}
      <Flex>
        <button className="btn btn-danger" onClick={() => setPageView(1)}>
          {translation.product}
        </button>
        <button type="submit" className="btn btn-success">
          {translation.confirm}
        </button>
      </Flex>
    </form>
  );
};

export default ShippingView;
