const marketFromContract = (contract) => contract?.split("_")[0].toLowerCase();

export function getDataByMarket(data, selectedMarkets, markets) {
  let result = [];

  for (let market of selectedMarkets) {
    const fullMarket = markets.find((m) => m._id === market.value);

    const sum = data
      .filter(
        (e) =>
          e?.market?._id === fullMarket._id ||
          marketFromContract(e?.contract_type) === fullMarket.short
      )
      .reduce((prev, next) => prev + next.totalSum, 0);
    const sumRounded = Math.round(sum * 100) / 100;

    if (sum === 0) continue;

    result.push({
      name: fullMarket.name,
      currency: fullMarket.currency,
      sum: sumRounded,
    });
  }

  return result;
}
