export function reducer(state, action) {
  if (action.type === "add")
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === "Number"
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.days && { days: parseInt(action.days) }),
        ...(action.hours && { hours: parseInt(action.hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };
  if (action.type === "validate") {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === "remove") {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === "init") {
    return {
      ...action.data,
    };
  }
}