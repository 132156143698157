import React from "react";
import { Title } from "../../InvoiceTicket.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { INVOICE_CREATION_TYPES } from "../../contstants/invoices";
import { CreateByButtonsWrapper } from "./InvoiceNotSet.styled";

const InvoiceNotSet = ({ setInvoiceCreateBy, translation }) => {
  return (
    <>
      <Title>Invoice ticket:</Title>
      <CreateByButtonsWrapper>
        <CustomButtonAdd
          onClick={() => setInvoiceCreateBy(INVOICE_CREATION_TYPES.VAT_ID)}
          defaultText={translation.create_by_vat}
        />
        <CustomButtonAdd
          onClick={() => setInvoiceCreateBy(INVOICE_CREATION_TYPES.COMPANY_ID)}
          defaultText={translation.create_by_different_id}
        />
      </CreateByButtonsWrapper>
    </>
  );
};

export default InvoiceNotSet;
