import styled from "styled-components";

export const ContactOrdersWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const ContactOrderWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
`;

export const ContactOrderItem = styled.span`
  width: 80px;
  text-align: center;
`;
