import { VERSION } from "../../common/constants/version";
import Api from "../api";

import { errorHandler } from "../common";
const { tokenManager, axios: api } = Api;
export const countPing = () =>
  errorHandler(async () => {
    const data = await api.get("/core/ping/" + VERSION);
    return { data: data.data };
  });
