import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { useNavigate } from "react-router-dom";
import { getRaws } from "./helpers/getRaws";

const TicketsSearchTable = ({ dataToDisplay, setMessages }) => {
  const navigate = useNavigate();

  return (
    <Table
      style={{ margin: "0 auto" }}
      className="styled-table"
      headers={HEADERS}
      raws={getRaws(dataToDisplay, navigate, setMessages)}
    />
  );
};

export default TicketsSearchTable;
