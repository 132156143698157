import { formatDate } from "@/common/functions/dateFormater";

export const formatShippingData = (shipping) => {
  const result = [];

  if (shipping.status_lms_sent_at) {
    result.push({
      label: "Sent at",
      date: formatDate(shipping.status_lms_sent_at, "YYYY-MM-DD HH:mm"),
    });
  }

  if (shipping.status_awizo_at) {
    result.push({
      label: "Awizo at",
      date: formatDate(shipping.status_awizo_at, "YYYY-MM-DD HH:mm"),
    });
  }

  if (shipping.status_delivered_at || shipping.status_delivered_no_payment_at) {
    result.push({
      label: "Delivered at",
      date: formatDate(
        shipping.status_delivered_at || shipping.status_delivered_no_payment_at,
        "YYYY-MM-DD HH:mm"
      ),
    });
  }

  if (shipping.status_return_at) {
    result.push({
      label: "Return at",
      date: formatDate(shipping.status_return_at, "YYYY-MM-DD HH:mm"),
    });
  }

  return result;
};
