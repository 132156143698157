export const TICKET_TYPES = [
  {
    label: "Invoice",
    value: "invoice",
  },
  {
    label: "Question",
    value: "question",
  },
  {
    label: "Correction",
    value: "correction",
  },
];
