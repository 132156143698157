import React, { useEffect, useRef, useState } from "react";
import { useRequestsContext } from "../../common/hooks/requestHook";
import {
  createMailTemplate,
  getMailTemplates,
  updateMailTemplate,
} from "../../API/repositories/mailTemplate";
import {
  ButtonCreateWrapper,
  ButtonSaveWrapper,
  Title,
  Wrapper,
} from "../../common/styles/Mails";
import PopUp from "../../common/components/PopUp";
import Loading from "../../common/components/Loading";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import Input from "../../common/components/Input";
import { Colors } from "../../common/colors/colors";

const MailTemplateElement = ({ element, setElement, addMessage, reload }) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const templateSengridIdRef = useRef();
  const templateSengridNameRef = useRef();

  const handleSave = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload._id = element._id || null;
    payload.template_sengrid_id =
      templateSengridIdRef.current?.value || element.template_sengrid_id;
    payload.template_sengrid_name =
      templateSengridNameRef.current?.value || element.template_sengrid_name;

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailTemplate.bind(null, payload));
    } else {
      response = await makeRequest(createMailTemplate.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  return (
    <PopUp setShow={setElement}>
      <Title>Mail Template</Title>
      <form onSubmit={(e) => handleSave(e)}>
        <Input
          inputRef={templateSengridIdRef}
          width={230}
          inputWidth={400}
          name="Sengrid Template Id"
          color={Colors.darkBlue}
          value={element.template_sengrid_id}
          requiredSign
          required
        />
        <Input
          inputRef={templateSengridNameRef}
          width={230}
          inputWidth={400}
          name="Sengrid Template Name"
          color={Colors.darkBlue}
          value={element.template_sengrid_name}
          requiredSign
          required
        />
        <ButtonSaveWrapper>
          <button className="btn btn-warning" type="submit">
            Save
          </button>
        </ButtonSaveWrapper>
      </form>
    </PopUp>
  );
};

const MailTemplate = () => {
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [element, setElement] = useState();
  const [filter, setFilter] = useState();

  const { addMessage, messages } = useMessageQueue();

  const handleLoadData = async () => {
    const response = await makeRequest(getMailTemplates);

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 3) {
      return setFilter(() => e.target.value);
    }

    return setFilter(() => null);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(
        getMailTemplates,
        updateMailTemplate,
        createMailTemplate
      ) && <Loading />}
      <MessageQueue messages={messages} />
      <Wrapper>
        <ButtonCreateWrapper>
          <button
            className="btn btn-warning animation-scale"
            onClick={() => setElement({})}
          >
            Create
          </button>
          <Input
            width={100}
            inputWidth={200}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            addMessage={addMessage}
            reload={handleLoadData}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={3}>Mail Templates</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Template Sengrid Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] ? (
              data
                .filter((e) =>
                  filter ? e.template_sengrid_name.includes(filter) : true
                )
                .map((element, i) => (
                  <tr key={element._id}>
                    <td>{i + 1}.</td>
                    <td>{element.template_sengrid_name}</td>
                    <td>
                      <i
                        className="fa fa-edit animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => setElement(element)}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={3}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default MailTemplate;
