import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  createComment,
  getCommentsForContact,
} from "../../../../API/repositories/comment";
import userManager from "../../../../API/userManager";
import Loading from "../../../../common/components/Loading";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import CommentElement from "./CommentElement";

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background-color: white;
`;

const CommentWrapper = styled.div`
  overflow-y: scroll;
  max-height: 200px;
`;

const Comments = ({
  comments,
  contactId,
  translation,
  handleGetDawnContact = () => {},
}) => {
  const [commentToDisplay, setCommentToDisplay] = useState(comments);

  const commentRef = useRef();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const hadnleAddComment = async (e) => {
    e.preventDefault();

    const comment = new Object();

    comment.user = userManager.getUser().id;
    comment.contact = contactId;
    comment.comment = commentRef.current.value;

    const response = await makeRequest(createComment.bind(null, comment));
    const comments = await makeRequest(
      getCommentsForContact.bind(null, contactId)
    );

    if (comments.data) {
      setCommentToDisplay(() => comments.data);
      await handleGetDawnContact();
    }
  };

  useEffect(() => {}, [commentToDisplay]);

  return (
    <Wrapper>
      {(hasUnfilledRequest(createComment) ||
        hasUnfilledRequest(getCommentsForContact)) && <Loading />}
      <form
        onSubmit={(e) => hadnleAddComment(e)}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <textarea
          ref={commentRef}
          required
          placeholder={translation.commentInfo}
          style={{ width: "100%", marginRight: "20px" }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className="btn btn-warning" type="text">
            {translation.add}
          </button>
        </div>
      </form>
      <CommentWrapper>
        {commentToDisplay.map(({ _id, _user, comment, created_at }) => (
          <CommentElement
            id={_id}
            name={_user ? _user.name + " " + _user.surname : ""}
            comment={comment}
            createdAt={created_at}
          />
        ))}
      </CommentWrapper>
    </Wrapper>
  );
};

export default Comments;
