import React from 'react'
import { GroupTableWrapper, LimitedCell } from '../../MailCategory.styled'
import Table from '@/common/components/Table'
import Icon from '@/common/components/Icon'

const GroupConfigTable = ({
  groupConfigs,
  templates,
  setShowGroupConfig
}) => {
  return (
    <GroupTableWrapper>
      <Table 
        className="styled-table"
        headers={["Id", "Name", "Template", "Weight", "Actions"]}
        raws={groupConfigs.map(config => (
          <tr key={config.id}>
            <LimitedCell>
              {config.id}
            </LimitedCell>
            <LimitedCell>
              {config.name || "----"}
            </LimitedCell>
            <LimitedCell>
              {templates.find(o => o.value === config.mail_template)?.label || "----"}
            </LimitedCell>
            <LimitedCell>
              {config.weight}
            </LimitedCell>
            <td>
              <Icon
                onClick={() => setShowGroupConfig(config)}
                name="fa fa-edit" 
              />
            </td>
          </tr>
        ))}
      />
    </GroupTableWrapper>
  )
}

export default GroupConfigTable
