import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ConversatgionGroupWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: ${Colors.lightGray};
  gap: 10px;
  padding: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: bold;
`;

export const ConversationGroupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConversationItem = styled.p`
  margin-bottom: 1px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${(props) => props.fontSize};
`;

export const ConversationType = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const ConversationDW = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const ConversatinDWInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SaveContactIcon = styled.i`
  font-size: 18px;
  margin: 10px;
  cursor: pointer;
`;

export const Hover = styled.div`
  position: relative;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      display: flex;
      width: 100px;
      z-index: 2;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 12px;
      top: 0px;
      right: 80px;
      transform: translateX(50%);
      border-radius: 10px;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 12px;
`;

export const SuccessButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  cursor: pointer;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 4px;
`;

export const ActionIcon = styled.i`
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 20px;
  transform: ${(props) => (props.rotate ? "rotateY(180deg)" : "")};
`;

export const Icon = styled.div`
  color: ${(props) => props.color};
`;

export const SubTitle = styled.h4`
  color: ${Colors.darkBlue};
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ChangeTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const ContactAddIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: 12px;
  width: 12px;
  height: 12px;
  font-size: 8px;
  background-color: ${Colors.green};
  color: white;
  border-radius: 50%;
`;

export const DwWrapper = styled.div`
  padding: 12px 12px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
`;

export const DwListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DwListItemWrapper = styled.div`
  display: flex;
  font-size: 12px;
  gap: 4px;
`;

export const DwListItem = styled.div`
  background-color: white;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 8px;
  cursor: pointer;
`;

export const PriorityAndDwWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
