import { Colors } from "@/common/colors/colors";

export const getUserRoleColor = (role) => {
  if (role === "admin") return Colors.violet;

  if (role === "call_center_manager") return Colors.orange;

  if (role === "call_center_operator") return Colors.green;

  if (role === "analyst") return Colors.darkBlue;

  if (role === "head_cc_manager") return Colors.lightBlue;

  if (role === "support") return "black";

  if (role === "finance_analyst") return Colors.red;

  return Colors.red;
};
