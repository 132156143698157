import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const SearchBarWrapper = styled.div`
  padding: 10px;
  display: flex;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue};
  min-width: 650px;
  justify-content: center;
  gap: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
  flex-direction: column;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionsWrapper = styled.p`
  display: flex;
  gap: 20px;
  justify-content: center;
`;
