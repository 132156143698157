import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const AddsAccountsList = styled.div`
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
`;

export const AddAccountItem = styled.div`
  margin: 4px;
  padding: 8px;
  color: white;
  width: fit-content;
  border-radius: 12px;
  cursor: pointer;
  background-color: ${Colors.green};
`;

export const ButtonWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
`;

export const TextArea = styled.textarea`
  resize: none;
`;
