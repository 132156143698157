export const getSelectCustomStyles = (width) => {
  return {
    container: (provided) => ({
      ...provided,
      marginTop: "10px",
      width: `${width}px`,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      width: `${width}px`,
    }),
    menu: (provided) => ({
      ...provided,
      width: `${width}px`,
    }),
  };
};