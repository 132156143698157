import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const ActionIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.color};
`;

export const OrderNavigateWrapper = styled.div`
  position: absolute;
  top: 10px;
  color: ${Colors.darkGray2};
`;

export const Icon = styled.i`
  cursor: pointer;
`;

export const LimitedWidthCell = styled.td`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FlexTableCell = styled.td`
  display: flex;
  justify-content: center;
  gap: 8px;
`;
