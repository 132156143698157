import React from "react";
import { DataStrong, Flex, FlexRight, H5 } from "../claimEditHrView.styles";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";

const InvoiceElement = ({ data, setIsAddCorrectionOpen }) => {
  return (
    <>
      <Flex>
        <H5>Invoice number:</H5>
        <DataStrong>{data._ticket.invoice.invoice_number}</DataStrong>
      </Flex>
      <Flex>
        <H5>Correction num:</H5>
        <DataStrong>
          {data._ticket.invoice.correction_number || "----"}
        </DataStrong>
      </Flex>
      <FlexRight>
        <CustomButtonAdd
          defaultText={"Add correction number"}
          onClick={() => setIsAddCorrectionOpen(() => true)}
        />
      </FlexRight>
    </>
  );
};

export default InvoiceElement;
