import React from "react";
import PopUp from "../../../common/components/PopUp";

const ReCallInfo = ({ showResultReCall, setShowResultReCall }) => {
  return (
    <PopUp setShow={setShowResultReCall}>
      <h3 style={{ color: "red", textAlign: "center" }}>
        Cannot connect with the client: {showResultReCall}
      </h3>
    </PopUp>
  );
};

export default ReCallInfo;
