import React from "react";
import Table from "@/common/components/Table";
import { Wrapper } from "@/common/styles/Mails";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";

const ResignReportTable = ({ resigns }) => {
  return (
    <Wrapper>
      <Table
        className="styled-table"
        stickyHeader
        headers={HEADERS}
        raws={getRaws(resigns)}
      />
    </Wrapper>
  );
};

export default ResignReportTable;
