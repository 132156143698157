import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const BorderLine = styled.div`
  width: 100%;
  background-color: ${Colors.lightlightBlue};
  height: 1px;
`;

export const ColorTitle = styled.h5`
  color: ${Colors.darkBlue};
`;

export const OptionWrapper = styled.div`
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

export const SaveSelectWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 100px;
`;
