import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div`
  border: 1px solid black;
  width: calc(100vw - 150px);
  max-width: 1000px;
  padding: 18px;
  font-style: italic;
  font-size: 18px;
  border-radius: 16px;
  white-space: pre-wrap;
`;
