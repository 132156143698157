import userManager from "@/API/userManager";
import { getUserDeaprtment } from "./getUserDepartment";
import {
  DIRECTIONS,
  OTHER_SYSTEMS_OPTION,
} from "../constants/filtersConstants";

export const filterTickets = (tickets, filters, departments) =>
  tickets?.filter((ticket) => {
    if (
      filters.product &&
      !ticket._orders?.some((o) => o._product?._id === filters.product)
    ) {
      return false;
    }

    if (filters.status && ticket.status !== filters.status) {
      return false;
    }

    if (
      filters.topic &&
      filters.topic !== OTHER_SYSTEMS_OPTION.value &&
      ticket[ticket.type]?.title !== filters.topic
    ) {
      return false;
    }

    if (
      filters.topic === OTHER_SYSTEMS_OPTION.value &&
      ticket.type === "question"
    ) {
      return false;
    }

    if (filters.direction) {
      const currentUserId = userManager.getUser().id;
      const currentUserDepartment = getUserDeaprtment(
        departments,
        currentUserId
      );
      const creatorDepartment = getUserDeaprtment(
        departments,
        ticket.created_by
      );
      const reciverDepartment = getUserDeaprtment(departments, ticket.reciver);

      if (filters.direction === DIRECTIONS.INCOMING) {
        if (
          reciverDepartment?._id !== currentUserDepartment?._id &&
          !ticket.department
        ) {
          return false;
        }

        if (
          ticket.department &&
          currentUserDepartment._id !== ticket.department
        ) {
          return false;
        }
      }

      if (filters.direction === DIRECTIONS.OUTGOING) {
        if (
          ticket.created_by !== currentUserId &&
          currentUserDepartment?._id !== creatorDepartment?._id
        ) {
          return false;
        }
      }
    }

    if (filters.department) {
      const department = departments?.find((d) => d._id === filters.department);

      if (
        !department?.topics?.some((t) => t.value === ticket[ticket.type]?.title)
      ) {
        return false;
      }
    }

    if (filters.assignee && ticket.reciver !== filters.assignee) {
      return false;
    }

    return true;
  });
