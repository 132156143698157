import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: scroll;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
