import React from "react";
import styled from "styled-components";
import { Colors } from "../colors/colors";

const Wrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  border: 1px dashed ${Colors.darkBlue};
`;

const Card = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Card;
