import styled from "styled-components";

export const TableWrapper = styled.div`
  max-width: 80vw;
  overflow-x: auto;
`;

export const ActionIcon = styled.i`
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

export const Success = styled.b`
  color: ${(props) => props.color};
`;
