import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Colors } from "../colors/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DEFAULT_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        font: {
          color: Colors.darkBlue,
        },
        color: Colors.darkBlue,
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const CustomBar = ({ data, options = {} }) => {
  return <Bar options={{ ...DEFAULT_OPTIONS, ...options }} data={data} />;
};

export default CustomBar;
