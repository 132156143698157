import styled from "styled-components";

export const FormWrapper = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageLableWrapper = styled.div`
  width: 120px;
  text-align: right;
  font-size: 18px;
`;

export const TextArea = styled.textarea`
  margin-left: 30px;
`;

export const ButtonWrapper = styled.div`
  float: right;
  align-self: flex-end;
`;
