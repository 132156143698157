import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
