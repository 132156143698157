import { Colors } from '@/common/colors/colors';
import Card from '@/common/components/Card';
import SelectInput from '@/common/components/SelectInput';
import { STATUSES_LABELS } from '@/common/constants/recruitmentStatuses';
import React, { useEffect, useState } from 'react'

const RecruitmentManagerSearchBar = ({ setVisibleCandidates, candidates }) => {
  const [selectedStatus, setSelectedStatus] = useState();

  const handleFilterStatus = (value) => {
    setSelectedStatus(value);
    setVisibleCandidates(candidates.filter(candidate => (
      !value?.value || candidate.status === value.value)))
  };

  useEffect(() => {
    handleFilterStatus(selectedStatus);
  }, [candidates])

  return (
   <div style={{ display: "flex", justifyContent: "center"}}>
     <Card>
      <SelectInput 
        name="Status" 
        options={[{ label: "None", value: null }, ...STATUSES_LABELS]} 
        color={Colors.darkBlue} 
        width={50}
        selected={selectedStatus}
        setSelected={handleFilterStatus}
      />
    </Card>
   </div>
  )
}

export default RecruitmentManagerSearchBar
