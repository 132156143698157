import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const ENTITY_NAME = "promotion";

export const getPromotions = () =>
  errorHandler(async () => {
    const data = await api.get(`/${ENTITY_NAME}/`);
    return { data: data.data };
  });

export const updatePromotion = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/${ENTITY_NAME}/${payload._id}`, payload);
    return { data: data.data };
  });

export const createPromotion = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}`, payload);
    return { data: data.data };
  });

export const testPromotion = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}/test-mail`, payload);
    return { data: data.data };
  });

export const handleCheckQueryPromotion = (filters) =>
  errorHandler(async () => {
    const data = await api.post(
      `/${ENTITY_NAME}/orders-to-promotion-by-query/`,
      filters
    );
    return { data: data.data };
  });

export const handleTestPromotionMail = (payload) =>
  errorHandler(async () => {
    const data = await api.post(
      `/${ENTITY_NAME}/test-promotion-mail/`,
      payload
    );
    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
