import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import React from "react";
import { ButtonsWrapper } from "./NotPickUpSearchBar.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { getToday } from "@/common/functions/getToday";

const NotPickUpSearchBar = ({
  dateFromRef,
  dateToRef,
  setSelectedMarkets,
  selectedMarkets,
  hanldeSearch,
}) => {
  const {
    options: { marketsOptions },
  } = useCommonDataContext();

  const today = getToday();

  return (
    <SearchBarCenter>
      <div>
        <Input
          name="Download from"
          width={140}
          color={Colors.darkBlue}
          inputRef={dateFromRef}
          type="date"
          inputWidth={140}
          value={today}
        />
        <Input
          name="Download to"
          width={140}
          color={Colors.darkBlue}
          inputRef={dateToRef}
          type="date"
          inputWidth={140}
          value={today}
        />
      </div>
      <SelectInput
        name="Markets"
        options={marketsOptions}
        color={Colors.darkBlue}
        width={100}
        setSelected={setSelectedMarkets}
        selected={selectedMarkets}
        multiple={true}
        selectWidth={180}
      />
      <ButtonsWrapper>
        <CustomButtonAdd
          defaultText="Search"
          onClick={(e) => hanldeSearch(e)}
        />
        <CustomButtonAdd
          defaultText="Add all"
          onClick={() => setSelectedMarkets(marketsOptions)}
        />
      </ButtonsWrapper>
    </SearchBarCenter>
  );
};

export default NotPickUpSearchBar;
