import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;

export const createMetaAccount = (payload) =>
  errorHandler(async () => {
    const data = await api.post("/meta/account", payload);

    return { data: data.data };
  });

export const updateMetaAccount = (payload, accountId) =>
  errorHandler(async () => {
    const data = await api.patch(`/meta/account/${accountId}`, payload);

    return { data: data.data };
  });

export const getMetaAccounts = () =>
  errorHandler(async () => {
    const data = await api.get("/meta/accounts");

    return { data: data.data };
  });
