import React, { useEffect, useState } from "react";
import { sendMailByMailType } from "../../../../API/repositories/mail";
import Loading from "../../../../common/components/Loading";
import { useRequestsContext } from "../../../../common/hooks/requestHook";

const MoreInfo = ({
  product,
  email,
  sendMail,
  mailError,
  mailSuccess,
  order,
  changeColor = true,
}) => {
  const [canSendMail, setCanSendMail] = useState(true);
  const [color, setColor] = useState("none");

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSendMoreInfo = async (e) => {
    setCanSendMail(false);
    setTimeout(() => {
      setCanSendMail(true);
      setColor(() => "#ffca2c");
    }, 10000);
    e.preventDefault();

    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "MORE_INFO_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      changeColor && setColor(() => "green");
    } else {
      changeColor && setColor(() => "red");
    }
  };

  return (
    <>
      {hasUnfilledRequest(sendMailByMailType) && <Loading />}
      <button
        style={{
          backgroundColor: color,
          border: color,
          transform: ["green", "red"].includes(color)
            ? "scale(1.1)"
            : "scale(1)",
        }}
        className="btn btn-warning"
        onClick={(e) => handleSendMoreInfo(e)}
        disabled={!canSendMail}
      >
        <i className="fa fa-paper-plane" style={{ marginRight: "10px" }}></i>
        {color === "green"
          ? mailSuccess
          : color === "red"
          ? mailError
          : sendMail}{" "}
        "More Info"
      </button>
    </>
  );
};

export default MoreInfo;
