import { VERSION } from "@/common/constants/version";
import {
  InfoWrapper,
  OnlineWrapper,
  RoleWrapper,
  VersionWrapper,
} from "../UserList.styled";
import { getUserRoleColor } from "./getUserRoleColor";
import { handleOnline } from "./handleOnline";
import { Colors } from "@/common/colors/colors";
import { findSuperior } from "@/common/functions/findSuperior";

export const getRaws = (formattedUsers, users) =>
  formattedUsers.map((element) => {
    const rowToDisplay = [];
    rowToDisplay.push(element.username);
    rowToDisplay.push(element.email);
    rowToDisplay.push(
      findSuperior(element._id, users)?.username || "------"
    );
    rowToDisplay.push(
      element.role ? (
        <RoleWrapper
          color={getUserRoleColor(element.role.toString().toLowerCase())}
        >
          {element.role.toString().toLowerCase()}
        </RoleWrapper>
      ) : (
        "-----"
      )
    );
    rowToDisplay.push(() => (
      <InfoWrapper>
        <OnlineWrapper backgroundColor={handleOnline(element.last_ping)} />
        <VersionWrapper
          color={element?.version === VERSION ? Colors.green : Colors.red}
        >
          {element.version || "----"}
        </VersionWrapper>
      </InfoWrapper>
    ));

    return {
      data: rowToDisplay,
      _id: element._id,
    };
  });
