import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  createBlackBoardInfo,
  editBlackBoardInfo,
} from "../../../../API/repositories/blackboardInfo";
import { Colors } from "../../../../common/colors/colors";
import Input from "../../../../common/components/Input";
import PopUp from "../../../../common/components/PopUp";
import SelectInput from "../../../../common/components/SelectInput";
import { formatDate } from "../../../../common/functions/dateFormater";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { MODE } from "./BlackboardList";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const STATUS_OPTIONS = [
  {
    label: "SUCCESS",
    value: "green",
  },
  {
    label: "DANGER",
    value: "red",
  },
  {
    label: "INFO",
    value: Colors.darkBlue,
  },
];

const Wrapper = styled.div`
  min-width: 1000px;
`;

const Title = styled.h3`
  margin: 0px 0px 40px 0px;
  color: ${Colors.darkBlue};
`;

const MESSAGE_TYPES = {
  CRM_QUICK: "Refresh the CRM now",
  CRM_HOURS:
    "Remember to check the hours in the CRM! If you have not entered a day (more than 3 days back) inform CC Managers via ticket. Documents insert in CRM (holidays, sick leave ect.) Hours -> Add document. ",
};

export const DASHBOARD_OPTIONS = [
  {
    label: "MAIN",
    value: "main",
  },
  {
    label: "LISTENING",
    value: "listening",
  },
];

const BlackboardAction = ({
  data = {},
  mode,
  reload,
  setShow,
  usersOptions,
  addMessage,
}) => {
  const [selectedType, setSelectedType] = useState(
    STATUS_OPTIONS.find((status) => status.value === data.color) ||
      STATUS_OPTIONS[1]
  );
  const [selectedDashboard, setSelectedDashboard] = useState(
    DASHBOARD_OPTIONS.find((status) => status.value === data.dashboard) ||
      DASHBOARD_OPTIONS[0]
  );
  const [selctedUsers, setSelectedUsers] = useState(
    usersOptions.filter((user) => data.users?.includes(user.value)) || []
  );
  const [selectedQueues, setSelectedQueues] = useState();
  const { options } = useCommonDataContext();
  const { queuesOptions } = options;
  const titleRef = useRef();
  const messageRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();

  const { makeRequest } = useRequestsContext();

  const setMessage = (type) => {
    messageRef.current.value = MESSAGE_TYPES[type];
  };

  const handleSelectedQueues = (queue) => {
    let selectedUsersIds = [];

    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedUsers(() =>
      usersOptions.filter((c) => selectedUsersIds.includes(c.value))
    );

    setSelectedQueues(() => queue);
  };

  const handleSave = async (e) => {
    e && e.preventDefault();

    data["title"] = titleRef.current?.value || data["title"];
    data["color"] = selectedType.value;
    data["from"] = fromRef.current?.value || data["from"];
    data["to"] = toRef.current?.value || data["to"];
    data["message"] = messageRef.current?.value || data["message"];
    data["users"] = selctedUsers.map((user) => user.value);
    data["dashboard"] = selectedDashboard.value;

    let response;

    if (mode === MODE.CREATE) {
      response = await makeRequest(createBlackBoardInfo.bind(null, data));
    }

    if (mode === MODE.EDIT) {
      response = await makeRequest(editBlackBoardInfo.bind(null, data));
    }

    if (response.data) {
      await reload(e);
      setShow(() => null);
      addMessage("Saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  return (
    <PopUp setShow={setShow}>
      <Wrapper>
        <Title>{mode} blackboard info</Title>
        <form
          onSubmit={handleSave}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1200px",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <Input
                inputRef={titleRef}
                name={"Title"}
                width={80}
                color={Colors.darkBlue}
                value={data.title}
                inputWidth={150}
                required
              />
              <SelectInput
                name={"Color"}
                selected={selectedType}
                setSelected={setSelectedType}
                width={80}
                color={Colors.darkBlue}
                options={STATUS_OPTIONS}
                selectWidth={150}
              />
            </div>
            <div>
              <Input
                type="datetime-local"
                inputRef={fromRef}
                name={"From"}
                width={80}
                inputWidth={180}
                color={Colors.darkBlue}
                value={data.from && formatDate(data.from, "YYYY-MM-DDTHH:mm")}
                required
              />
              <Input
                type="datetime-local"
                inputRef={toRef}
                name={"To"}
                width={80}
                color={Colors.darkBlue}
                value={data.to && formatDate(data.to, "YYYY-MM-DDTHH:mm")}
                required
                inputWidth={180}
              />
            </div>
            <div>
              <SelectInput
                name={"Dashboard"}
                selected={selectedDashboard}
                setSelected={setSelectedDashboard}
                width={80}
                selectWidth={150}
                color={Colors.darkBlue}
                options={DASHBOARD_OPTIONS}
              />
            </div>
          </div>
          <div style={{ width: "900px", marginLeft: "-20px", display: "flex" }}>
            <div style={{ minWidth: "720px" }}>
              <SelectInput
                showLabel={false}
                selected={selctedUsers}
                setSelected={setSelectedUsers}
                options={usersOptions}
                width={80}
                selectWidth={700}
                color={Colors.darkBlue}
                multiple={true}
                placeholder="Select user..."
              />
            </div>
            <SelectInput
              showLabel={false}
              selected={selectedQueues}
              setSelected={handleSelectedQueues}
              options={queuesOptions}
              width={80}
              selectWidth={180}
              color={Colors.darkBlue}
              multiple={true}
              placeholder="Select market..."
            />
          </div>
          <textarea
            ref={messageRef}
            style={{
              width: "900px",
              fontSize: "16px",
              marginTop: "20px",
              minHeight: "200px",
            }}
            defaultValue={data.message || ""}
            required
            placeholder="type message... (ENGLISH)"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              marginTop: "40px",
              gap: "20px",
            }}
          >
            <button
              type="button"
              className="btn btn-info"
              onClick={() => setMessage("CRM_HOURS")}
            >
              hours info
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => setMessage("CRM_QUICK")}
            >
              refresh info
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setSelectedUsers(null)}
            >
              Clear
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setSelectedUsers(usersOptions)}
            >
              Add All
            </button>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </div>
        </form>
      </Wrapper>
    </PopUp>
  );
};

export default BlackboardAction;
