import PopUp from '@/common/components/PopUp'
import React from 'react'
import { ConfirmButton, Header } from '../../ValidClaimForm.styled'
import { Colors } from '@/common/colors/colors'
import { SureButtonsWrapper } from './AreYouSure.styled'

const AreYouSure = ({ setShow, submit }) => {
	return (
		<PopUp setShow={setShow}>
			<Header>
				Are you sure you want to cancel this claim?
			</Header>
			<SureButtonsWrapper>
				<ConfirmButton
					background={Colors.green}
					onClick={() => submit(true)}
				>
					Yes
				</ConfirmButton>
				<ConfirmButton
					background={Colors.red}
					onClick={() => setShow(false)}
				>
					No
				</ConfirmButton>
			</SureButtonsWrapper>
		</PopUp>
	)
}

export default AreYouSure
