import Table from "@/common/components/Table";
import React from "react";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const SentOrdersTable = ({ data }) => {
  const {
    options: { marketsOptions },
    commonData: { products },
  } = useCommonDataContext();

  return (
    <Table
      className="styled-table"
      raws={getRaws(data, marketsOptions, products)}
      headers={HEADERS}
    />
  );
};

export default SentOrdersTable;
