import { compareDates } from "@/common/functions/compareDates";
import { formatDate } from "@/common/functions/dateFormater";
import { findMarketById } from "../../../helpers/findMarketById";
import { formatPrice } from "../../../helpers/formatPrice";
import { countOrderItemsPrice } from "../../../helpers/countOrderItemsPrice";
import { countOriginalSum } from "../../../helpers/countOriginalSum";
import { countPolishCurrencySum } from "../../../helpers/countPolishCurrencySum";
import { checkCorrection } from "../../../helpers/checkCorrection";
import { checkInvoiceCorrection } from "../../../helpers/checkInvoiceCorrection";
import { checkValidInvoiceCorrection } from "../../../helpers/checkValidInvoiceCorrection";

export const getCsvData = (orders, correctionNumberFilterRef, markets) => {
  const dataRaws = orders
    .sort((orderA, orderB) =>
      compareDates(
        new Date(orderA.claim.paid_date),
        new Date(orderB.claim.paid_date)
      )
    )
    .filter((order) =>
      correctionNumberFilterRef.current?.checked ? checkCorrection(order) : true
    )
    .map((order, i) => [
      i + 1,
      findMarketById(order.market, markets)?.name,
      formatDate(order.claim.paid_date, "MM/YYYY"),
      formatDate(
        order.shipping.status_delivered_at ||
          order.shipping.status_delivered_no_payment_at,
        "YYYY-MM-DD"
      ),
      formatDate(order.claim.paid_date, "YYYY-MM-DD"),
      order._contact?.full_name,

      order.claim.price || formatPrice(countOrderItemsPrice(order)),
      formatPrice(order.claim.polish_currency) || 0,
      checkInvoiceCorrection(order)?.invoice.correction_number ||
        checkValidInvoiceCorrection(order)?.valid_invoice.correction_number ||
        "----",
    ]);

  const sumRaw = [
    "",
    "Sum",
    "",
    "",
    "",
    "",
    formatPrice(countOriginalSum(orders, correctionNumberFilterRef)),
    formatPrice(countPolishCurrencySum(orders, correctionNumberFilterRef)),
    "",
  ];

  dataRaws.push(sumRaw);

  return dataRaws;
};
