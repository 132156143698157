import { RESIGN_OPTIONS } from "@/common/constants/resignOptions";
import { formatDate } from "@/common/functions/dateFormater";

export const getRaws = (resigns) =>
  resigns.map((order, i) => (
    <tr key={order._id}>
      <td>{i + 1}</td>
      <td>{formatDate(order.resignation_date, "YYYY-MM-DD HH:mm:ss")}</td>
      <td>{order.resigned_by.username}</td>
      <td>
        {RESIGN_OPTIONS.find((option) => option.value === order.resign_comment)
          ?.label || "----"}
      </td>
      <td>{order.contact.full_name}</td>
      <td>{order.contact.email}</td>
    </tr>
  ));
