import React, { useEffect, useRef, useState } from "react";
import {
  ChartWrapper,
  FLexColumne,
  TitleSection,
  TopContentWrapper,
  Wrapper,
} from "../shared.styles";
import Input from "@/common/components/Input";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SelectInput from "@/common/components/SelectInput";
import { getMailsForChart } from "@/API/repositories/mail";
import CustomLine from "@/common/charts/Line";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import MailTable from "./components/MailTable";

const MailStats = () => {
  const {
    options: { productsOptions },
  } = useCommonDataContext();
  const [selectedProduct, setSelectedProduct] = useState();
  const [data, setData] = useState();

  const timeRangeRef = useRef();
  const [chartData, setChartData] = useState();
  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    setData(() => null);
    setChartData(() => null);

    const payload = {};

    payload.product = selectedProduct?.value;
    payload.timeFrom = timeRangeRef.current.value;
    payload.timeTo = 0;

    const response = await makeRequest(getMailsForChart.bind(null, payload));

    if (response?.data?.chartData) {
      setChartData(() => response.data.chartData);
    }

    if (response?.data?.tableData) {
      setData(() => response.data.tableData);
    }
  };

  useEffect(() => {
    if (!selectedProduct?.value) {
      setSelectedProduct(() => productsOptions[0]);
    }
  }, [productsOptions]);

  useEffect(() => {
    if (selectedProduct?.value) handleLoadData();
  }, [selectedProduct]);

  return (
    <Wrapper width="800" style={{ height: "fit-content" }}>
      <TitleSection>
        <TopContentWrapper>
          <i className="fa fa-envelope" />
          Sent mails
        </TopContentWrapper>{" "}
        <TopContentWrapper>
          <Input
            showLabel={false}
            inputWidth={50}
            value={14}
            inputRef={timeRangeRef}
          />
          <SelectInput
            options={productsOptions}
            selected={selectedProduct}
            setSelected={setSelectedProduct}
            selectWidth={200}
            showLabel={false}
          />
          <i
            class="fa-solid fa-arrows-rotate animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => handleLoadData()}
          />{" "}
        </TopContentWrapper>
      </TitleSection>
      <ChartWrapper>
        {chartData && data ? (
          <FLexColumne>
            <CustomLine data={chartData} />
            <MailTable data={data} />
          </FLexColumne>
        ) : (
          <CustomSkeleton width={750} count={16} height={5} />
        )}
      </ChartWrapper>
    </Wrapper>
  );
};

export default MailStats;
