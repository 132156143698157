import React, { useEffect, useRef, useState } from "react";
import {
  createUser,
  updateUser,
} from "../../../../../../API/repositories/user";
import userManager from "../../../../../../API/userManager";
import Form from "../../../../../../common/components/Form";
import Input from "../../../../../../common/components/Input";
import Loading from "../../../../../../common/components/Loading";
import SelectInput from "../../../../../../common/components/SelectInput";
import { CURRENCY } from "../../../../../../common/constants/currency";
import { useRequestsContext } from "../../../../../../common/hooks/requestHook";
import { COMPANIES_OPTIONS } from "../../../../../../common/constants/Accounting";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import {
  AddManagerIconWrapper,
  BasicInfoWrapper,
  ButtonWrapper,
  ManagerInputWrapper,
  RequestsWrapper,
  RestInfoWrapper,
  SplitLine,
  Title,
  Wrapper,
} from "./UserForm.styled";
import {
  ACCESS,
  CONSULTANT_ACCESSES,
  CONSULTANT_ROLE,
  CONSULTNAT_ROLE,
  MANAGER_ROLE,
  ROLES,
  ROLE_HIDDEN_FIELDS,
} from "./constants";
import PopUp from "@/common/components/PopUp";
import { useSearchParams } from "react-router-dom";
import UserRequestsLog from "../userRequests/UserRequestsLog";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import Icon from "@/common/components/Icon";
import UserManagerForm from "./components/userManagerForm/UserManagerForm";
import moment from "moment";

const UserForm = ({ addMessage, users, languageOptions }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [managers, setManagers] = useState();
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState();
  const [selectedLanguageOption, setSelectedLanguageOption] = useState();
  const [selectedCompany, setSelectedComapny] = useState();
  const [isActive, setIsActive] = useState();
  const [showManagerForm, setShowManagerForm] = useState(false);

  const [selectedRolesOption, setSelectedRolesOption] = useState(
    ROLES.find((role) => CONSULTANT_ROLE === role.value)
  );
  const [selectedAccessOption, setSelectedAccessOption] = useState(
    ACCESS.filter((access) => CONSULTANT_ACCESSES.includes(access.label))
  );
  const [user, setUser] = useState();
  const [userManagers, setUserManagers] = useState(user?.managers);

  const nameRef = useRef();
  const surnameRef = useRef();
  const emailRef = useRef();
  const loginRef = useRef();
  const passwordRef = useRef();

  const contractTypeRef = useRef();
  const voipIdRef = useRef();
  const contractHoursRef = useRef();
  const callingSystemRef = useRef();
  const vacationDaysRef = useRef();
  const monthHoursRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const getCurrentManager = () => {
    const current = userManagers
      ?.find(m => 
        (moment(m.active_to).startOf('day').isSameOrAfter(moment().startOf('day')) 
          && moment(m.active_from).startOf('day').isSameOrBefore(moment().startOf('day')))
      )
      ?.manager

    return current;
  }

  const isUserAdmin = userManager.getUser().role === "ADMIN";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {};

    payload.name = nameRef.current.value.replace(" ", "");
    payload.surname = surnameRef.current.value.replace(" ", "");
    payload.email = emailRef.current.value.replace(" ", "");
    payload.username = loginRef.current.value.replace(" ", "");
    payload.password = passwordRef.current.value.replace(" ", "");
    payload.contract_type = contractTypeRef.current?.value.replace(" ", "");
    payload.role = selectedRolesOption.value;
    payload.managers = userManagers;

    payload.language = selectedLanguageOption.value;
    payload.company = selectedCompany.value;
    payload.active = isActive;
    payload.access = selectedAccessOption.map((data) => data.value);

    payload.voip_id = voipIdRef.current?.value;
    payload.salary_currency = selectedCurrencyOption?.value;
    payload.contract_hours = contractHoursRef.current?.value;
    payload.calling_system = callingSystemRef.current?.checked;
    payload.vacation_days = vacationDaysRef.current?.value;
    payload.month_hours = monthHoursRef.current?.value;

    const response =
      searchParams.get("mode") === "create"
        ? await makeRequest(createUser.bind(null, payload))
        : await makeRequest(updateUser.bind(null, user?._id, payload));

    if (!response?.data) {
      return addMessage("Couldn't update user", "error");
    }

    addMessage("Success", "success");
    setSearchParams({});
  };

  const handleGetUsers = async () => {
    const supperiors = handleMapToOptions(
      users.filter((user) => MANAGER_ROLE === user.role),
      "username",
      "_id"
    );

    setManagers(() => supperiors);
    const userId = searchParams.get("userId");
    const userToEdit = users.find((user) => user._id === userId);

    setSelectedRolesOption(
      ROLES.find((role) =>
        userToEdit
          ? role.value === userToEdit.role
          : role.value === CONSULTANT_ROLE
      )
    );

    setSelectedAccessOption(
      ACCESS.filter((access) =>
        userToEdit
          ? userToEdit.access.includes(access.label)
          : CONSULTANT_ACCESSES.includes(access.label)
      )
    );

    if (userToEdit) {
      setUser(() => userToEdit);
      setUserManagers(() => userToEdit?.managers);
      setSelectedCurrencyOption(() =>
        CURRENCY.find((curr) => curr.value === userToEdit.salary_currency)
      );
      setSelectedLanguageOption(() =>
        languageOptions.find((lang) => lang.value === userToEdit.language)
      );
      setSelectedComapny(() =>
        COMPANIES_OPTIONS.find(
          (company) => company.value === userToEdit.company
        )
      );
    }
  };

  useEffect(() => {
    setIsActive(() => (user ? user.active : true));
  }, [user]);

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <>
      {hasUnfilledRequest(createUser, updateUser) && <Loading />}
      <PopUp setShow={() => setSearchParams({})} padding="10px 20px 30px">
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Title>Basic Information</Title>
            <BasicInfoWrapper>
              <div>
                <Input
                  color={Colors.darkBlue}
                  inputRef={nameRef}
                  name="Name"
                  value={user?.name}
                  required
                  width={150}
                />
                <Input
                  color={Colors.darkBlue}
                  inputRef={surnameRef}
                  name="Surname"
                  value={user?.surname}
                  required
                  width={150}
                />
                <SelectInput
                  color={Colors.darkBlue}
                  name="Salary currency"
                  options={CURRENCY}
                  selected={selectedCurrencyOption}
                  setSelected={setSelectedCurrencyOption}
                  width={150}
                  required
                  hideStar
                />
                <Input
                  color={Colors.darkBlue}
                  inputRef={emailRef}
                  name="Email"
                  type="email"
                  value={user?.email}
                  required
                  width={150}
                />
                <Input
                  color={Colors.darkBlue}
                  inputRef={loginRef}
                  name="Username"
                  value={user?.username}
                  required
                  width={150}
                />
              </div>
              <div>
                <Input
                  color={Colors.darkBlue}
                  inputRef={passwordRef}
                  type="password"
                  name="Password"
                  value=""
                  required={!user}
                  width={150}
                />
                <SelectInput
                  color={Colors.darkBlue}
                  name="Language"
                  options={languageOptions}
                  setSelected={setSelectedLanguageOption}
                  selected={selectedLanguageOption}
                  width={150}
                  required
                  hideStar
                />
                <SelectInput
                  color={Colors.darkBlue}
                  name="Company"
                  options={COMPANIES_OPTIONS}
                  setSelected={setSelectedComapny}
                  selected={selectedCompany}
                  width={150}
                  required
                  hideStar
                />
                <SelectInput
                  color={Colors.darkBlue}
                  name="Roles"
                  options={ROLES}
                  setSelected={setSelectedRolesOption}
                  selected={selectedRolesOption}
                  setAccesses={setSelectedAccessOption}
                  width={150}
                  required
                  hideStar
                />
                <Input
                  color={Colors.darkBlue}
                  type="checkbox"
                  id="Active"
                  name="Active"
                  width={150}
                  inputWidth={20}
                  height={20}
                  onChange={() => setIsActive((prev) => !prev)}
                  isChecked={isActive}
                />
              </div>
            </BasicInfoWrapper>
            {isUserAdmin && (
              <SelectInput
                color={Colors.darkBlue}
                name="Access"
                options={ACCESS}
                setSelected={setSelectedAccessOption}
                selected={selectedAccessOption}
                multiple={true}
                selectWidth={720}
                width={250}
                required
                hideStar
              />
            )}
            {!ROLE_HIDDEN_FIELDS[selectedRolesOption.value].includes(
              CONSULTNAT_ROLE
            ) && (
              <>
                <SplitLine />
                <Title>Rest Information</Title>
                <RestInfoWrapper>
                  <div>
                    <Input
                      color={Colors.darkBlue}
                      inputRef={contractTypeRef}
                      name="Contract type"
                      value={user?.contract_type}
                      required
                      width={250}
                    />
                    <Input
                      color={Colors.darkBlue}
                      inputRef={contractHoursRef}
                      name="Contract hours"
                      value={user?.contract_hours}
                      required
                      width={250}
                    />
                    <Input
                      color={Colors.darkBlue}
                      inputRef={vacationDaysRef}
                      name="Vacation days"
                      value={user?.vacation_days}
                      required
                      width={250}
                    />
                    <Input
                      color={Colors.darkBlue}
                      inputRef={monthHoursRef}
                      name="Month hours"
                      value={user?.month_hours}
                      required
                      width={250}
                    />
                  </div>
                  <div>
                    <ManagerInputWrapper>
                      <SelectInput
                        color={Colors.darkBlue}
                        name="Superior"
                        options={managers}
                        setSelected={() => {}}
                        selected={managers?.find(o => o.value === getCurrentManager())}
                        width={150}
                        selectWidth={210}
                        disabled
                      />
                      <AddManagerIconWrapper onClick={() => setShowManagerForm(true)}>
                        <Icon size={18} name="fa fa-plus" />
                      </AddManagerIconWrapper>
                    </ManagerInputWrapper>
                    <Input
                      color={Colors.darkBlue}
                      inputRef={voipIdRef}
                      type="number"
                      name="Voip id"
                      value={user?.voip_id}
                      width={150}
                    />
                    <Input
                      color={Colors.darkBlue}
                      inputRef={callingSystemRef}
                      id="calling_system"
                      type="checkbox"
                      name="Calling system"
                      checked={user?.calling_system}
                      width={150}
                      inputWidth={20}
                      height={20}
                    />
                  </div>
                </RestInfoWrapper>
              </>
            )}
            <ButtonWrapper>
              <CustomButtonSave text="Save" styles={{ marginBottom: 0 }} />
            </ButtonWrapper>
          </Form>
          <RequestsWrapper>
            {user && <UserRequestsLog userId={user._id} />}
          </RequestsWrapper>
        </Wrapper>
        {showManagerForm && (
            <UserManagerForm
              addMessage={addMessage}
              managers={managers} 
              userManagers={userManagers}
              setShow={setShowManagerForm}
              setUserManagers={setUserManagers}
            />
          )
        }
      </PopUp>
    </>
  );
};

export default UserForm;
