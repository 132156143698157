import styled from "styled-components";

export const Embed = styled.embed`
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
