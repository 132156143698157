import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  gap: 10px;
`;

const THead = styled.thead`
  th {
    background-color: #5d2b62;
  }
`;

const TBody = styled.tbody`
  td {
    background-color: #ffefd0;
  }
`;

const IncomingCalls = ({ data = [] }) => {
  const navigate = useNavigate();

  return (
    <table
      className="styled-table"
      style={{ minWidth: "650px", maxWidth: "650px" }}
    >
      <THead>
        <tr>
          <th colSpan={7}>Inbound Queue</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>CC-Number</th>
          <th>Number</th>
          <th>Full Name</th>
          <th>Queue</th>
          <th>Started At</th>
          <th>Actions</th>
        </tr>
      </THead>
      <TBody className="queue">
        {data.length > 0 ? (
          data
            .sort(
              (a, b) =>
                new Date(b.start_waiting_at).getTime() -
                new Date(a.start_waiting_at).getTime()
            )
            .map((element, i) => (
              <tr key={i}>
                <td>{i + 1}.</td>
                <td>
                  {element.call_center_number
                    .match(/.{1,3}/g)
                    .map((substring) => substring + " ")}
                </td>
                <td>
                  {element.client_number
                    .match(/.{1,3}/g)
                    .map((substring) => substring + " ")}
                </td>
                <td>{element.client_name}</td>
                <td>{element.queue}</td>
                <td>
                  {moment(element.start_waiting_at).format("DD/MM HH:mm:ss")}
                </td>
                <td>
                  <IconsWrapper>
                    <i
                      className="fa fa-address-book animation-scale"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/dashboard/contact/" + element.contact)
                      }
                    />
                  </IconsWrapper>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={7}>There is no users</td>
          </tr>
        )}
      </TBody>
    </table>
  );
};

export default IncomingCalls;
