import moment from "moment"

export const findSuperior = (userId, users) => {
  const userManagers = users?.find(u => u._id === userId)?.managers;
  const currentManagerId = userManagers
    ?.find(manager => 
        moment().isSameOrAfter(moment(manager.active_from)?.startOf('day')) && moment().isSameOrBefore(moment(manager.active_to).endOf('day')))
    ?.manager;
    
  return users?.find(user => user._id === currentManagerId);
}