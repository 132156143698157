import { createContext } from "react";
import NewMessageQueue, { useNewMessageQueue } from "../newMessageProvider";

export const NewMessageQueueContext = createContext();

export const NewMessageQueueProvider = ({ children }) => {
  const { addMessage, removeMessage, messages } = useNewMessageQueue();

  return (
    <NewMessageQueueContext.Provider value={{
      addMessage,
    }}>
      <NewMessageQueue messages={messages} removeMessage={removeMessage} />
      {children}
    </NewMessageQueueContext.Provider>
  );
}