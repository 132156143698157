export const TODO_STATUSES = {
  CREATED: {
    value: "created",
    title: "Created",
    previous: null,
    next: "in_progress",
  },
  IN_PROGRESS: {
    value: "in_progress",
    title: "In progress",
    previous: "created",
    next: "done",
  },
  DONE: {
    value: "done",
    title: "Done",
    previous: "in_progress",
    next: null,
  },
};
