import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const CalendarWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 1000px;
`;

export const CalendarElementEmptyWrapper = styled.div`
  width: 150px;
  height: 150px;
`;

export const CalendarElementWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 15px;
  border: 2px solid ${Colors.darkBlue};
  padding: 5px;
  background-color: ${Colors.lightGray};
`;

export const TopContentCalendarElementWrapper = styled.div`
  display: flex;
  color: ${Colors.darkBlue};
  justify-content: space-between;
  h3 {
    font-size: 22px;
    font-weight: bold;
  }
`;

export const DayNameWrapper = styled.div`
  width: 150px;
  text-align: center;
  color: ${Colors.darkBlue};
  font-weight: bold;
  font-size: 18px;
`;

export const FreeDayNameWrapper = styled.div`
  width: 140px;
  text-align: center;
  p {
    font-weight: bold;
    color: red;
  }
`;

export const WorkingElement = styled.div`
  margin-top: 5px;
  width: 100%;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  font-size: 14px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin: 0 0 40px 0;
`;
