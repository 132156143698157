export const getHeaders = (orders) => {
  return [
    "No",
    "Rynek",
    "Miesiąc wykonania zwrotu",
    "Data sprzedaży usługi",
    "Data wykonania zwrotu środków",
    "Nazwa Klienta",
    `Wartość pobrania (Brutto ${orders[0].currency})`,
    "Wartość zwrotu środków (pln)",
    "Korekta Faktury",
  ];
};
