import Api from "../api";
import { errorHandler } from "../common";
const queryString = require("query-string");

const { axios: api } = Api;

export const getRequestsForUser = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/crm-requests-history?` +
        queryString.stringify(cleanObject(payload), {
          arrayFormat: "bracket",
        })
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
