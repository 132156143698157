import { Colors } from "@/common/colors/colors";

export const createDataSets = (selectedMarket, data) => {
  return [
    {
      label: "Hours",
      data: selectedMarket.value.consultants.map((c) => {
        const currentData = data.filter((d) => d.consultant === c);
        return currentData.reduce(
          (prev, next) => prev + next.attendance_at_work,
          0
        );
      }),
      backgroundColor: Colors.red,
    },
  ];
};
