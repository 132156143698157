export const HEADERS = [
  "Payment id",
  "Payment status",
  "Order status",
  "Items",
  "Completed at",
  "Refund to",
  "Actions",
];

export const ADD_DAYS = 14;
