export function validateIBAN(iban) {
  const ibanPatterns = {
    "SK": /^SK\d{22}$/,
    "RO": /^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$/,
    "HR": /^HR\d{19}$/,
    "CZ": /^CZ\d{22}$/,
    "LT": /^LT\d{18}$/,
    "HU": /^HU\d{26}$/,
    "LV": /^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$/,
    "DE": /^DE\d{20}$/,
  };

  const countryCode = iban.slice(0, 2).toUpperCase();

  if (!ibanPatterns[countryCode] || !ibanPatterns[countryCode].test(iban)) {
    return false;
  }

  // Rearrange the IBAN for checksum calculation
  const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

  // Convert characters to integers (A = 10, B = 11, ..., Z = 35)
  const convertedIBAN = rearrangedIBAN.split('').map(char => {
    const code = char.charCodeAt(0);
    if (code >= 65 && code <= 90) { // A-Z
      return code - 55;
    }
    return char;
  }).join('');

  // Perform the mod-97 operation
  let remainder = convertedIBAN;
  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97).toString() + remainder.slice(9);
  }
  remainder = parseInt(remainder, 10) % 97;

  // Valid IBANs will have a remainder of 1
  return remainder === 1;
}
