import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Colors } from "../../../../../common/colors/colors";
import { handleLoadLastSevenDaysHoursAndConfirmedOrders } from "../../../../../API/repositories/reports";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import CustomSkeleton from "../../../../../common/components/CustomSkeleton";

const Wrapper = styled.div`
  width: 400px;
  height: 370px;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  h3 {
    font-size: 16px;
    color: ${Colors.darkBlue};
  }
`;

const MonthSwapWrapper = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 10px;
    color: lightgrey;
    font-style: italic;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ConfirmedToHoursChart = ({ translations }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            color: Colors.darkBlue,
          },
          color: Colors.darkBlue,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
    },
  };

  const { makeRequest } = useRequestsContext();
  const [data, setData] = useState();

  const handleLoad = async () => {
    const response = await makeRequest(
      handleLoadLastSevenDaysHoursAndConfirmedOrders
    );

    if (response.data) {
      setData(() => ({
        labels: response.data.map((d) => d.date),
        datasets: [
          {
            label: "Confirmed",
            data: response.data.map((d) => d.confirmed),
            backgroundColor: Colors.red,
          },
          {
            label: "Hours",
            data: response.data.map((d) => d.hours),
            backgroundColor: Colors.yellow,
          },
        ],
      }));
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <Wrapper>
      <TopWrapper>
        <NameWrapper>
          <i
            className="fa fa-poll"
            style={{ color: Colors.darkBlue, fontSize: "30px" }}
          />
          <h3></h3>
        </NameWrapper>
        <MonthSwapWrapper>
          <h3>{translations["last 7 days results"]}</h3>
        </MonthSwapWrapper>
      </TopWrapper>
      <ChartWrapper>
        {data ? <Bar options={options} data={data} /> : <CustomSkeleton />}
      </ChartWrapper>
    </Wrapper>
  );
};

export default ConfirmedToHoursChart;
