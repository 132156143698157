import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import PopUp from "../../../../common/components/PopUp";
import SelectInput from "../../../../common/components/SelectInput";
import RichTextEditor from "@/common/components/richTextEditor/RichTextEditor";
import { getMarkdownFromQuill } from "@/common/functions/getMarkdownFromQuill";
import { setQuillContent } from "@/common/functions/setQuillContent";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: 15px;
  margin-bottom: -30px;
`;

const H3 = styled.h3`
  color: ${Colors.darkBlue};
  margin: 0 0 40px 0;
`;

const FAQ_TYPES_OPTIONS = [
  {
    label: "IT",
    value: "it",
  },
  {
    label: "SUPPORT",
    value: "support",
  },
  {
    label: "CC-MANAGERS",
    value: "cc_managers",
  },
];

const FaqAction = ({ onClose, data, onAction }) => {
  const questionRef = useRef();
  const quillRef = useRef();
  const [selectedOption, setSelectedOption] = useState(
    FAQ_TYPES_OPTIONS.find((e) => e.value === data.type)
  );

  useEffect(() => {
    setQuillContent(quillRef, data.original_answer);
  });

  const handleSave = async (e) => {
    e.preventDefault();

    if (!selectedOption?.value) {
      return;
    }

    const faq = {};
    faq.answer = getMarkdownFromQuill(quillRef);
    faq.question = questionRef.current?.value || data.original_question;
    faq.type = selectedOption.value;

    if (data._id) {
      onAction(e, data._id, faq);
    } else {
      onAction(e, faq);
    }
  };

  return (
    <PopUp setShow={onClose}>
      <H3>Create / Update</H3>
      <form onSubmit={(e) => handleSave(e)}>
        <SelectInput
          options={FAQ_TYPES_OPTIONS}
          selected={selectedOption}
          setSelected={setSelectedOption}
          name="Type"
          color={Colors.darkBlue}
        />
        <textarea
          ref={questionRef}
          style={{
            width: "900px",
            fontSize: "16px",
            marginTop: "20px",
            minHeight: "100px",
          }}
          defaultValue={data.original_question || ""}
          required
          placeholder="type question... (ENGLISH)"
        />
        <RichTextEditor ref={quillRef} />
        <ButtonWrapper>
          <button className="btn btn-warning" type="submit">
            Save
          </button>
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default FaqAction;
