import styled from "styled-components";
import { Colors } from "@/common/colors/colors";
import { BORDER_RADIUS } from "./constants/borderRadius";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 1260px;
  background-color: ${Colors.lightGray};
  border-radius: ${BORDER_RADIUS};
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
`;

export const AllDataContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
`;

export const DataWrapper = styled.div`
  width: 1200px;
  border-radius: ${BORDER_RADIUS};
  padding: 20px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: white;
  visibility: ${(props) => (props.visibilityHidden ? "hidden" : "visible")};

  border-top-left-radius: ${(props) => (props.selectedTop ? BORDER_RADIUS : 0)};
  border-bottom-left-radius: ${(props) =>
    props.selectedTop ? 0 : BORDER_RADIUS};
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  font-size: 24px;
  font-weight: bold;
  display: flex;
  gap: 4px;
  margin-bottom: 40px;
`;

export const TitleIcon = styled.i`
  font-size: 12px;
  position: relative;
`;

export const HoverRegulation = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  word-break: break-all;
  :hover {
    ::after {
      content: "${(props) => props.regulation}";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 400px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 12px;
      line-height: 22px;
      left: -50%;
      transform: translateX(-60%);
      top: 30px;
      border-radius: ${BORDER_RADIUS};
      z-index: 2;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;

export const ProgressBarHover = styled.div`
  position: relative;
  margin-bottom: 40px;
  :hover {
    ::after {
      content: "${(props) => props.label}";
      font-size: 14px;
      position: absolute;
      background-color: ${Colors.lightGray};
      color: ${Colors.darkGray2};
      padding: 8px 16px;
      left: ${(props) => `${props.percent}%`};
      top: -28px;
      border-radius: ${BORDER_RADIUS};
      z-index: 2;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;

export const GamifiedStatisticsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const GamifiedStatisticsItemWrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  :not(:last-child) {
    ::after {
      content: "";
      display: block;
      border: 1px solid ${Colors.gray};
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 0;
    }
  }
`;

export const SubTitle = styled.h4`
  display: flex;
  gap: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
  color: ${Colors.darkBlue};
`;

export const Info = styled.span`
  font-size: 12px;
  color: ${Colors.darkGray2};
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  background-color: white;
  border-radius: ${BORDER_RADIUS};
`;

export const UpdatedWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 12px;
  color: ${Colors.darkGray2};
`;
