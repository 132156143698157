export const sort = (array, type, field, direction) => {
  if (!direction) {
    direction = "asc";
  }

  if (type === "string") {
    return array.sort((a, b) => {
      const current = a[field] ? a[field] : "";
      const next = b[field] ? b[field] : "";

      return direction === "asc"
        ? current?.replace(" ", "").localeCompare(next?.replace(" ", ""))
        : next?.replace(" ", "").localeCompare(current?.replace(" ", ""));
    });
  }
  if (type === "number") {
    return array.sort((a, b) => {
      const current = a[field] ? a[field] : 0;
      const next = b[field] ? b[field] : 0;

      return direction === "asc" ? current - next : next - current;
    });
  }
  if (type === "date") {
    return array.sort((a, b) => {
      const from = a[field] ? new Date(a[field])?.getTime() : 0;
      const to = b[field] ? new Date(b[field])?.getTime() : 0;

      return direction === "asc" ? from - to : to - from;
    });
  }
};