import { Colors } from '@/common/colors/colors';
import styled from 'styled-components'

export const ReasoningWrapper = styled.div`
  display: flex;
  gap: 48px;
`;

export const Textarea = styled.textarea`
  width: 227px;
  height: 104px;
  padding: 10px;
  border: 1px solid ${Colors.darkGray2};
  border-radius: 6px;
  resize: none;
  font-size: 12px;
`;
