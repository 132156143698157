import { USERNAME_ROLES } from "../constants/usernameRoles";

export const getStatusChangeMessage = (message, users) => {
  const parts = message.split(" ");
  const role = users.find((user) => user.username === parts[0])?.role;

  if (!USERNAME_ROLES.includes(role?.toLowerCase())) parts[0] = role;

  return parts.join(" ");
};
