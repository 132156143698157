import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const CheckedDataWrapper = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${Colors.green};
  transition: color 0.3s;
  gap: 10px;

  :hover {
    color: ${Colors.red};
  }
`;

export const ContactsCloser = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${Colors.darkBlue};
  opacity: 0.2;
`;

export const ContactsWrapper = styled.div`
  height: 600px;
  width: 600px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
