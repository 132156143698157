import React, { useState } from "react";
import PopUp from "@/common/components/PopUp";
import Table from "@/common/components/Table";
import { useNavigate } from "react-router-dom";
import { HEADERS } from "./constants";
import { getRaws } from "./helpers";
import { getFileById } from "@/API/repositories/storedDocument";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getTranscriptionById } from "@/API/repositories/transcription";
import { TableWrapper } from "./CallHistoryTimeline.styled";
import { changeSuspicionData } from "@/API/repositories/tickets";

const CallHistoryTimeline = ({
  setShow,
  callHistoryTimeline,
  handleCloseTicketView,
  setAudio,
  setTranscription,
  currentSuspicionRecording,
  loadData,
}) => {
  const [loadingAudio, setLoadingAudio] = useState();
  const [transcriptingRecording, setTranscriptingRecording] = useState();
  const [checked, setChecked] = useState([]);

  const navigate = useNavigate();
  const { makeRequest } = useRequestsContext();

  const handleNavigateToOrder = (orderId) => {
    setShow(() => false);
    handleCloseTicketView();
    navigate(`/dashboard/order/${orderId}`);
  };

  const handleListenSuspicious = async (recordingId) => {
    setLoadingAudio(() => recordingId);
    const response = await makeRequest(
      getFileById.bind(null, recordingId, true)
    );
    setLoadingAudio(() => null);

    if (response.data) {
      handleChangeChecked(recordingId);
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setAudio(() => url);
    }
  };

  const handleTranscript = async (recordingId) => {
    setTranscriptingRecording(() => recordingId);
    const response = await makeRequest(
      getTranscriptionById.bind(null, recordingId)
    );
    setTranscriptingRecording(() => null);

    if (response.data) {
      handleChangeChecked(recordingId);
      setTranscription(() => response.data);
    }
  };

  const handleChangeChecked = (recordingId) => {
    if (!checked?.includes(recordingId)) {
      return setChecked((prev) => [...(prev || []), recordingId]);
    }
  };

  const handleChangeSuspiciousRecording = async (recordingId, consultantId) => {
    const payload = {};

    payload.previousRecordingId = currentSuspicionRecording;
    payload.recordingId = recordingId;
    payload.consultantId = consultantId;

    const response = await makeRequest(changeSuspicionData.bind(null, payload));

    if (!response?.data) {
      return;
    }

    loadData();
    setShow(() => false);
  };

  return (
    <PopUp padding="10px 20px 20px" setShow={setShow}>
      <TableWrapper>
        <Table
          stickyHeader
          className="styled-table"
          headers={HEADERS}
          raws={getRaws({
            callHistoryTimeline,
            checked,
            handleNavigateToOrder,
            handleListenSuspicious,
            handleTranscript,
            handleChangeSuspiciousRecording,
            transcriptingRecording,
            loadingAudio,
            currentSuspicionRecording,
          })}
        />
      </TableWrapper>
    </PopUp>
  );
};

export default CallHistoryTimeline;
