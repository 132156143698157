const { countOrderItemsPrice } = require("./countOrderItemsPrice");

export const countOriginalSum = (orders, correctionNumberFilterRef) =>
  orders
    .filter((order) =>
      correctionNumberFilterRef.current?.checked
        ? !!order._ticket?.invoice?.correction_number
        : true
    )
    .reduce((prev, curr) => prev + countOrderItemsPrice(curr), 0);
