import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { round } from "@/common/functions/round";

export const getExcelDataForMainInfo = (data) => [
  {
    TYP: data.contract_type,
    IMIĘ: data.name,
    NAZWISKO: data.surname,
    OD: formatToDateTamplate(data.from),
    DO: formatToDateTamplate(data.to),
    KONSULTANT: data.consultantHours,
    MANAGER: data.managerHours,
    "Suma godzin": data.hoursSum,
    DODATKOWY: data.additional_bonus,
    GRYWALIZACJA: data.gamification_bonus,
    "WSTĘPNE K": data.pre_consultant,
    "WYRÓWNANIE K": data.final_consultant,
    "WSTĘPNE M": data.pre_manager,
    "WYRÓWNANIE M": round(data.final_manager),
    Suma: data.sum,
  },
];
