import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import React, { useRef, useState } from 'react'
import { ButtonsWrapper } from '../../UserManagerForm.styled'
import CustomButtonSave from '@/common/components/buttons/CustomButtonSave'
import moment from 'moment'

const AddNewManager = ({ setShow, managers, handeSubmit, manager, userManagers = [] }) => {
  const [selectedManager, setSelectedManager] = useState(managers?.find(m => m.value === manager?.manager));
  const fromRef = useRef();
  const toRef = useRef();

  const handleSubmit = (e) => {
    e && e.preventDefault();

    const newManager = {
      manager: selectedManager.value,
      active_from: moment(fromRef.current.value).startOf('day').toDate(),
      active_to: moment(toRef.current.value).startOf('day').toDate(),
      _id: manager?._id || Math.max(...userManagers.map(m => m._id)) + 1,
    };

    handeSubmit(newManager);
    setShow(null);
  };

  return (
    <PopUp padding='10px 20px 24px' setShow={setShow}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <SelectInput 
          selected={selectedManager} 
          hideStar 
          required 
          setSelected={setSelectedManager}
          name="Managers"
          width={130} 
          color={Colors.darkBlue} 
          options={managers} 
        />
        <Input 
          value={manager ? moment(manager.active_from)?.format('YYYY-MM-DD') : null} 
          required 
          inputRef={fromRef} 
          name="Active from" 
          width={130} 
          color={Colors.darkBlue} 
          type="date" 
        />
        <Input 
          value={manager ? moment(manager.active_to)?.format('YYYY-MM-DD') : null} 
          required 
          inputRef={toRef} 
          name="Active to" 
          width={130} 
          color={Colors.darkBlue} 
          type="date"
        />
        <ButtonsWrapper>
          <CustomButtonSave styles={{ margin: 0, marginTop: "20px" }} text="Save" />
        </ButtonsWrapper>
      </form>
    </PopUp>
  )
}

export default AddNewManager
