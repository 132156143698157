import React, { useContext, useEffect, useState } from "react";
import PopUp from "../../../../../common/components/PopUp";
import { getContactWithOrders } from "../../../../../API/repositories/contact";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import styled from "styled-components";
import Comments from "../../comments/Comments";
import ContactDisplay from "./ContactDisplay";
import OrderDisplay from "./OrderDisplay";
import CallingTopBar from "./CallingTopBar";
import Loading from "../../../../../common/components/Loading";
import OrderShow from "./OrderShow";
import { CallCRMApiContext } from "../../../../../common/contexts/callingApiContext";
import Calling from "./Calling";
import NoAswer from "./NoAswer";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

const ContactView = ({
  contact,
  setContactView,
  translation,
  handleEndCall,
}) => {
  const [data, setData] = useState();
  const [currentContact, setCurrentContact] = useState();
  const [order, setOrder] = useState(null);
  const [talkingTime, setTalkingTime] = useState(0);

  const {
    sendCallToClient,
    isCalling,
    setIsCalling,
    isTalkingTicket,
    showNoAsweredTicket,
    setShowNoAsweredTicket,
  } = useContext(
    window.location.pathname.includes("vonage")
      ? CallCRMApiContextVonage
      : CallCRMApiContext
  );

  const handleCall = (e) => {
    e.preventDefault();
    sendCallToClient(contact);
  };

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadContact = async () => {
    const response = await makeRequest(
      getContactWithOrders.bind(null, contact)
    );

    if (response.data) {
      setData(() => response.data);
      setCurrentContact(() => response.data);
      if (order) {
        setOrder(() => response.data.orders?.find((o) => o._id === order._id));
      }
    }
  };

  useEffect(() => {
    handleLoadContact();
  }, []);

  return (
    <>
      {data && (
        <PopUp setShow={setContactView}>
          <FlexWrapper>
            <div>
              <ContactDisplay
                currentContact={currentContact}
                setCurrentContact={setCurrentContact}
                translation={translation}
                handleLoadContact={handleLoadContact}
              />
            </div>
            <div>
              <CallingTopBar
                isTalkingTicket={isTalkingTicket}
                isCalling={isCalling}
                setIsCalling={setIsCalling}
                talkingTime={talkingTime}
                setTalkingTime={setTalkingTime}
                handleEndCall={handleEndCall}
                handleCall={handleCall}
              />
              <OrderDisplay
                orders={currentContact.orders}
                translation={translation}
                setOrder={setOrder}
              />
              <Comments
                comments={data._comments}
                contactId={data._id}
                translation={translation}
              />
            </div>
          </FlexWrapper>
        </PopUp>
      )}
      {hasUnfilledRequest(getContactWithOrders) && <Loading />}
      {order && (
        <OrderShow
          order={order}
          setOrder={setOrder}
          contact={currentContact}
          translation={translation}
          handleLoadContact={handleLoadContact}
        />
      )}
      {isCalling && (
        <Calling
          fullName={currentContact.full_name}
          translation={translation}
        />
      )}
      {showNoAsweredTicket && (
        <NoAswer translation={translation} setClose={setShowNoAsweredTicket} />
      )}
    </>
  );
};

export default ContactView;
