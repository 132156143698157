import { Colors } from "@/common/colors/colors";

export const CUSTOM_SELECT_THEME = {
  primary: Colors.purple,
  primary50: Colors.lightPurple,
  primary25: Colors.lightPurple,
  neutral20: "#fff",
  neutral60: "#fff",
  neutral80: "#fff",
};
