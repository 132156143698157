import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 30px 30px -90px 30px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: -30px;
`;

export const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 30px;
  margin-top: -30px;
  font-weight: bold;
`;

export const BasicInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SplitLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin: 20px 0 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 110px;
`;

export const RestInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const RequestsWrapper = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
`;

export const ManagerInputWrapper = styled.div`
  display: flex;
  width: min-content;
  align-items: center;
`;

export const AddManagerIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 2px solid ${Colors.darkBlue};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
