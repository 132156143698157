export const moveProductGroupToMarket = (data) => {
	const marketAndMethodMap = new Map();

	data.forEach(item => {
		const market = item.product_name.trim().split(' ').at(-1);
		const paymentMethod = item.payment_method;

		const key = `${market}_${paymentMethod}`;

		if (!marketAndMethodMap.has(key)) {
			marketAndMethodMap.set(key, {
				to_pay: 0,
				to_check: 0,
				check_in_progress: 0,
				overdue: 0,
				payment_method: paymentMethod,
				priority: 0,
			});
		}

		const prevData = marketAndMethodMap.get(key);

		marketAndMethodMap.set(key, {
			to_pay: prevData.to_pay + item.to_pay,
			to_check: prevData.to_check + item.to_check,
			check_in_progress: prevData.check_in_progress + item.check_in_progress,
			overdue: prevData.overdue + item.overdue,
			payment_method: paymentMethod,
			priority: prevData.priority + item.priority,
		});
	});

	return Array.from(marketAndMethodMap).map(([market, data]) => ({
		product_name: market.split('_')[0],
		...data
	}));
}