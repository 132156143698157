import { Colors } from "../colors/colors";

export const QUESTION_TICKET_STATUS_OPTIONS = [
  {
    label: "New",
    value: "new",
    color: Colors.red,
    status_weight: 3,
  },
  {
    label: "Pending",
    value: "pending",
    color: Colors.blue,
    status_weight: 2,
  },
  {
    label: "Solved",
    value: "solved",
    color: Colors.darkGray2,
    status_weight: 1,
  },
];

export const PRIORITIES_OPTIONS = [
  {
    label: "None",
    value: false,
  },
  { label: "Priority", value: true },
];
