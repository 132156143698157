import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { calcBreak } from "./calcBreak";
import { calcCSV } from "./calcCsv";

export const calcData = (activity, queues) => {
  let result = [];

  activity.forEach((e) => {
    const elements = [];
    const elementsDateQueueMap = new Map();
    const elementsDateMap = new Map();

    e.data.forEach((d) => {
      const key = `${formatToDateTamplate(d.created_at)}_${d.queue || "any"}`;

      if (elementsDateQueueMap.has(key)) {
        elementsDateQueueMap.get(key).push(d);
        return;
      }
  
      elementsDateQueueMap.set(key, [d]);
    });

    e.data.forEach((d) => {
      const dateKey = formatToDateTamplate(d.created_at);

      if (elementsDateMap.has(dateKey)) {
        elementsDateMap.get(dateKey).push(d);
        return;
      }
  
      elementsDateMap.set(dateKey, [d]);
    });

    elementsDateQueueMap.forEach((values, keys) => {
      const element = {};
      const queue = keys.split("_")[1];
      const date = keys.split("_")[0];

      const allValues = elementsDateMap.get(date)

      element.consultant = values[0]._user[0]?.username;
      element.date = date;
      element.queue = queue;
      element.break = calcBreak(allValues, "break", queue);
      element.calling = calcBreak(allValues, "calling", queue);
      element.talking = calcBreak(allValues, "talking", queue);
      element.wraptime = calcBreak(allValues, "wraptime", queue);
      element.csv = calcCSV(allValues, keys, queues);

      elements.push(element);
    });

    result = [...result, ...elements];
  });

  return result;
};
