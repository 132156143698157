import userManager from "@/API/userManager";

export const getTextByUserLangAndType = (faq, type, otherType) => {
  const translation = faq.translation.find(
    (t) => t.lang === userManager.getUser().lang
  );

  if (translation) {
    return translation[type];
  }

  return faq[otherType];
};
