import { Colors } from "@/common/colors/colors";
import { sumByField } from "@/common/functions/sumByField";

export const getRaws = (data) => [
  data.map((item, i) => (
      <tr key={item.product_name + i}>
        <td>
          {item.product_name}
        </td>
        <td>
          {item.to_pay}
        </td>
        <td style={{ backgroundColor: item.overdue && Colors.orange }}>
          {item.overdue}
        </td>
        <td>
          {item.to_check}
        </td>
        <td>
          {item.check_in_progress}
        </td>
        <td>
          {item.to_correct}
        </td>
        <td>
          {item.correct_in_progress}
        </td>
        <td>
          {item.total}
        </td>
      </tr>
  )),
  <tr key={'unique'}>
    <td>
      Total
    </td>
    <td>
      {sumByField(data, 'to_pay')}
    </td>
    <td
      style={{ backgroundColor: sumByField(data, 'overdue') && Colors.orange }}
    >
      {sumByField(data, 'overdue')}
    </td>
    <td>
      {sumByField(data, 'to_check')}
    </td>
    <td>
      {sumByField(data, 'check_in_progress')}
    </td>
    <td>
      {sumByField(data, 'to_correct')}
    </td>
    <td>
      {sumByField(data, 'correct_in_progress')}
    </td>
    <td>
      {sumByField(data, 'total')}
    </td>
  </tr>
];