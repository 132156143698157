import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

const prefix = 'dynamic-mail-content';

export const getAllDynamicMailContent = () =>
  errorHandler(async () => {
    const data = await api.get(`/${prefix}/get`);
    return { data: data.data };
  });

export const createDynamicMailContent = (dynamicMailContent) =>
  errorHandler(async () => {
    const data = await api.post(`/${prefix}/create`, dynamicMailContent);
    return { data: data.data };
  });

export const updateDynamicMailContent = (dynamicMailContent, _id) =>
  errorHandler(async () => {
    const data = await api.patch(`/${prefix}/update/${_id}`, dynamicMailContent);
    return { data: data.data };
  });
