import { useRequestsContext } from "../hooks/requestHook";
import { getCallingStatistics } from "../../API/repositories/calling";
import { getQueueStackAndContacts } from "../../API/repositories/queue";
import { getSpecialMarketStatistcs } from "../../API/repositories/voip";
import { getClaimsByStatusReport } from "../../API/repositories/reports";
import { getInoicesByStatusesReport } from "../../API/repositories/reports";
import { getOrderSentReport } from "../../API/repositories/reports";
import { getAllArchives } from "../../API/repositories/order";
import { getHoursForCCManager } from "../../API/repositories/consultantHours";
import { getMarketWppkReportForConsultant } from "../../API/repositories/reports";
import { getBoundInfo } from "../../API/repositories/queue";
import { getMailsHistory } from "../../API/repositories/mail";
import { getUsers } from "../../API/repositories/user";
import moment from "moment";
import { getActiveTickets } from "@/API/repositories/tickets";

const ACTIVE_MINUTES = 15;

export const useAdminDasbhoardRequests = () => {
  const { makeRequest } = useRequestsContext();
  const loadCallingStatistics = async (_, setData) => {
    const currentLoggedUsers = await getLoggedInUsers();
    setData((prev) => ({ ...prev, logged_in_users: currentLoggedUsers }));

    const response = await makeRequest(getCallingStatistics);

    return response?.data || [];
  };

  const loadQueueLeads = async () => {
    const response = await makeRequest(getQueueStackAndContacts);

    return response?.data || [];
  };

  const loadVoipStatistics = async (dashboardSettings) => {
    const payload = {};

    payload.consultants = dashboardSettings?.visible_views.find(
      (view) => view.name === "voip_statistics"
    )?.users;
    payload.direction =
      dashboardSettings?.visible_views.find(
        (view) => view.name === "voip_statistics"
      ).calling_direction || "outgoing";
    payload.to = moment().endOf("day").toISOString();
    payload.from = moment()
      .subtract(
        dashboardSettings?.visible_views.find(
          (visibleView) => visibleView.name === "voip_statistics"
        )?.time || 1,
        "day"
      )
      .startOf("day")
      .toISOString();

    if (!payload.consultants) {
      const response = await makeRequest(getUsers);

      if (response.data) {
        payload.consultants = response.data.map((user) => user._id);
      }
    }

    const response = await makeRequest(
      getSpecialMarketStatistcs.bind(null, payload)
    );

    return response?.data || [];
  };

  const loadClaims = async () => {
    const response = await makeRequest(getClaimsByStatusReport);

    return response?.data || [];
  };

  const loadInvoices = async () => {
    const response = await makeRequest(getInoicesByStatusesReport);

    return response?.data || [];
  };

  const loadActiveTickets = async () => {
    const response = await makeRequest(getActiveTickets);

    return response?.data || [];
  };

  const loadSentOrders = async (dashboardSettings) => {
    const payload = {};

    payload.from = moment()
      .subtract(
        dashboardSettings?.visible_views.find(
          (visibleView) => visibleView.name === "sent_orders"
        )?.time || 1,
        "d"
      )
      .startOf("day")
      .toISOString();
    payload.to = moment().endOf("day").toISOString();

    const response = await makeRequest(getOrderSentReport.bind(null, payload));

    return response?.data || [];
  };

  const loadArchives = async () => {
    const response = await makeRequest(getAllArchives);

    return response?.data || [];
  };

  const loadConsultantHours = async () => {
    const payload = {};

    payload.accepted = false;
    payload.month = moment().startOf("month");
    payload.monthFormated = moment().format("MMMM_YYYY").toUpperCase();

    const response = await makeRequest(
      getHoursForCCManager.bind(null, payload)
    );

    return response?.data || [];
  };

  const loadEffectivityReport = async (dashboardSettings) => {
    const payload = {};

    payload.from = moment()
      .subtract(
        dashboardSettings?.visible_views.find(
          (visibleView) => visibleView.name === "effectivity_report"
        )?.time || 1,
        "d"
      )
      .toDate();
    payload.to = moment().toDate();
    payload.weights = false;
    payload.type = ["inbound", "outbound"];
    payload.ids = dashboardSettings?.visible_views.find(
      (view) => view.name === "effectivity_report"
    )?.users;

    if (!payload.ids) {
      const response = await makeRequest(getUsers);

      if (response.data) {
        payload.ids = response.data.map((user) => user._id);
      }
    }

    const response = await makeRequest(
      getMarketWppkReportForConsultant.bind(null, payload)
    );

    if (response.data) {
      return Object.entries(response.data).map(([key, value]) => {
        value._id = key;
        return value;
      });
    }

    return [];
  };

  const loadBoundInfo = async () => {
    const response = await makeRequest(getBoundInfo);

    return response?.data || [];
  };

  const loadMailsHistory = async (dashboardSettings) => {
    const response = await makeRequest(
      getMailsHistory.bind(null, {
        hours:
          dashboardSettings?.visible_views.find(
            (visibleView) => visibleView.name === "mail_history"
          )?.time || 1,
      })
    );

    return response?.data || [];
  };

  const getLoggedInUsers = async () => {
    const response = await makeRequest(getUsers);

    if (response.data) {
      const activeUsers = response.data?.filter((user) => {
        const now = moment();
        const activityDate = moment(user.last_ping);

        const diff = now.diff(activityDate, "minutes");

        if (diff <= ACTIVE_MINUTES && user.last_ping) {
          return true;
        }

        return false;
      });

      return activeUsers;
    }

    return [];
  };

  return {
    loadBoundInfo,
    loadArchives,
    loadMailsHistory,
    getLoggedInUsers,
    loadEffectivityReport,
    loadConsultantHours,
    loadSentOrders,
    loadVoipStatistics,
    loadClaims,
    loadInvoices,
    loadQueueLeads,
    loadCallingStatistics,
    loadActiveTickets,
  };
};
