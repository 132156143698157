import Api from "../api";
import { errorHandler } from "../common";

const { axios: api } = Api;

const prefix = 'dynamic-mail-attachment';

export const getAllDynamicMailAttachments = () =>
  errorHandler(async () => {
    const data = await api.get(`/${prefix}/get`);
    return { data: data.data };
  });

export const createDynamicMailAttachment = (dynamicMailAttachment) =>
  errorHandler(async () => {
    const data = await api.post(`/${prefix}/create`, dynamicMailAttachment);
    return { data: data.data };
  });

export const updateDynamicMailAttachment = (dynamicMailAttachment, _id) =>
  errorHandler(async () => {
    const data = await api.patch(`/${prefix}/update/${_id}`, dynamicMailAttachment);
    return { data: data.data };
  });
