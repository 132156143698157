import React from "react";
import { Colors } from "../colors/colors";

const Icon = ({
  name,
  size = 16,
  color = Colors.darkBlue,
  cursor = "pointer",
  onClick = () => {},
  rotate,
}) => {
  return (
    <i
      className={name}
      style={{
        transform: rotate ? "rotate(180deg)" : "",
        color: color,
        fontWeight: "bold",
        cursor: cursor,
        fontSize: `${size}px`,
        transition: "0.3s all",
      }}
      onClick={() => onClick()}
    />
  );
};

export default Icon;
