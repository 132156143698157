import React from 'react'
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { MultipleButtonsWrapper, SearchBar, SearchItem } from '@/pages/admin/ccHours/CCHours.styled';
import { Colors } from '@/common/colors/colors';
import { LAST_YEAR_MONTHS } from '@/common/constants/lastYearMonths';
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd';
import { COMPANIES_OPTIONS } from '@/common/constants/Accounting';
import moment from 'moment';

const HoursSearchBar = ({
  selectedCompany,
  setSelectedCompany,
  selectedMonth,
  setSelectedMonth,
  handleChangeRegex,
  acceptedRef,
  consultants,
  selectedConsultant,
  setSelectedConsultants,
  selectedQueues,
  handleSelectQueue,
  queuesOptions,
  hanldeSearch,
  setIsAddHoursForAnyOpen,
  handleGetAllMonthFiles,
  data
}) => {
  
  return (
    <SearchBar>
        <SearchItem>
          {selectedCompany && (
            <SelectInput
              name="Comapny"
              options={COMPANIES_OPTIONS}
              color={Colors.darkBlue}
              width={80}
              selectWidth={120}
              setSelected={setSelectedCompany}
              selected={selectedCompany}
            />
          )}
          <SelectInput
            name="Month"
            options={[
              {
                value: moment().add(1, "month").startOf("month"),
                label: moment().add(1, "month").format("MMMM YYYY"),
              },
              ...LAST_YEAR_MONTHS,
            ]}
            color={Colors.darkBlue}
            width={50}
            selectWidth={140}
            setSelected={setSelectedMonth}
            selected={selectedMonth}
          />
          <Input
            width={80}
            inputWidth={120}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
          <label>Accepted:</label>
          <input ref={acceptedRef} type="checkbox" />
        </SearchItem>
        <SelectInput
          showLabel={false}
          selectWidth={1000}
          options={consultants}
          selected={selectedConsultant}
          setSelected={setSelectedConsultants}
          multiple={true}
          placeholder={"Select consultants..."}
        />
        {queuesOptions && (
          <SelectInput
            showLabel={false}
            placeholder={"Select queues..."}
            selectWidth={1000}
            options={queuesOptions}
            setSelected={handleSelectQueue}
            selected={selectedQueues}
            multiple={true}
          />
        )}
        <MultipleButtonsWrapper>
          {data && acceptedRef.current?.checked && (
            <i
              className="animation-scale fa fa-file fa-solid"
              onClick={(e) => handleGetAllMonthFiles()}
              style={{ cursor: "pointer" }}
            />
          )}
          <CustomButtonAdd
            onClick={() => setIsAddHoursForAnyOpen(true)}
            defaultText="Add hours for any consultant"
          />
          <CustomButtonAdd
            defaultText={"Add all"}
            onClick={() => setSelectedConsultants(consultants)}
          />
          <CustomButtonAdd
            defaultText={"Search"}
            onClick={(e) => hanldeSearch(e)}
          />
        </MultipleButtonsWrapper>
      </SearchBar>
  )
}

export default HoursSearchBar
