import { Colors } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import { calcWorkingHours } from "@/common/functions/calcWorkingHours";
import { CSV_HEADERS } from "../../../../pages/admin/ccHours/components/consultantMonth/constants/headers";
import { getCSVBody } from "./getCSVBody";
import { sumByField } from "@/common/functions/sumByField";

export const getRaws = (
  {
    data,
    filterRegex,
    selectedConsultant,
    consultants,
    setShowConsultantMonth,
    selectedMonth,
    acceptedRef,
    selectedCompany,
    markets
  }
) => data
  .sort((a, b) => a._id.localeCompare(b._id))
  .filter((e) => {
    return filterRegex
      ? (
          e?._month_working_info?.contract_type ||
          e?._user?.contract_type
        )
          ?.toLowerCase()
          .includes(filterRegex.toLowerCase())
      : true;
  })
  .filter((c) =>
    selectedConsultant.map((c1) => c1.value).includes(c._id)
  )
  .map((consultantData, i) => {
    return (
      <tr key={i}>
        <td style={{ padding: "8px"}}>
          {consultants.find(
            (c) => c.value === consultantData._id
          )?.label || "------"}
        </td>
        <td>
          {sumByField(consultantData.data, "attendance_at_work")}
        </td>
        <td>
          {sumByField(consultantData.data, "holiday_leave")}
        </td>
        <td>
          {sumByField(consultantData.data, "special_leave")}
        </td>
        <td>
          {sumByField(consultantData.data, "sick_leave")}
        </td>
        <td>
          {sumByField(consultantData.data, "leave_on_reqests")}
        </td>
        <td>
          {sumByField(consultantData.data, "break_during_the_day")}
        </td>
        <td>
          {sumByField(consultantData.data, "excused_unpaid_leave")}
        </td>
        <td>
          {sumByField(consultantData.data, "absence_without_excuse")}
        </td>
        <td>
          {sumByField(consultantData.data, "manager_hours")}
        </td>
        <td>{calcWorkingHours(consultantData.data)}</td>
        <td>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-edit animation-scale"
              style={{
                fontSize: "18px",
                color: Colors.orange,
                cursor: "pointer",
              }}
              onClick={() =>
                setShowConsultantMonth(consultantData)
              }
            ></i>
            {acceptedRef.current?.checked && (
              <CSV
                header={CSV_HEADERS}
                data={getCSVBody(
                  consultantData,
                  markets,
                  selectedMonth
                )}
                filename={`${
                  consultants.find(
                    (c) => c.value === consultantData._id
                  )?.label || "------"
                } ${selectedMonth.label}_${
                  selectedCompany?.value
                }.csv`}
              />
            )}
          </div>
        </td>
        <td>
          {consultantData.foundTicket ? (
            <i
              style={{ color: Colors.green, fontSize: "20px" }}
              className="fa-sharp fa-solid fa-check"
            />
          ) : (
            "----"
          )}
        </td>
      </tr>
    );
  })