import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import CreateButton from "../../../../common/components/CreateButton";
import ListTable from "../../../../common/components/ListTable";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import MarketNew from "./MarketNew";
import MessageQueue, {
  useMessageQueue,
} from "../../../../common/messageProvider";
import { useCommonDataContext } from "../../../../common/hooks/commonDataContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const MarketList = () => {
  const [row, setRow] = useState(null);
  const column = ["no", "name", "short", "currency"];
  const [showNew, setShowNew] = useState(false);
  const { commonData, queryValues } = useCommonDataContext();
  const { isLoading } = queryValues;
  const { markets } = commonData;

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleGetMarkets = async () => {
    setRow(() => null);

    setRow(() =>
      markets.map((element) => {
        let rowToDisplay = [];
        rowToDisplay.push(element.name);
        rowToDisplay.push(element.short);
        rowToDisplay.push(element.currency);
        return {
          data: rowToDisplay,
          _id: element._id,
        };
      })
    );
  };

  useLayoutEffect(() => {
    handleGetMarkets();
  }, [showNew, markets]);

  return (
    <>
      {isLoading && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {row && (
        <Wrapper>
          <CreateButton onClick={() => setShowNew(true)} name={"market"} />
          <ListTable
            column={column}
            row={row}
            entityName={"market"}
          ></ListTable>
        </Wrapper>
      )}
      {showNew && (
        <PopUp setShow={setShowNew}>
          <MarketNew close={() => setShowNew(false)} addMessage={addMessage} />
        </PopUp>
      )}
    </>
  );
};

export default MarketList;
