import { Colors } from '@/common/colors/colors'
import PopUp from '@/common/components/PopUp'
import React, { useEffect } from 'react'

const AlertMessage = ({ setShow, message, setExperienceSet }) => {

  useEffect(() => {
    setExperienceSet(false);
  }, []);

  return (
    <PopUp setShow={setShow}>
      <p style={{ color: Colors.red, fontWeight: "bold", fontSize: "24px"}}>{message}</p>
    </PopUp>
  )
}

export default AlertMessage
