import React, { useEffect, useRef, useState } from "react";
import Card from "@/common/components/Card";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import { ButtonWrapper } from "../../SpeechToText.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { convertSpeechToText } from "@/API/repositories/speechToText";
import { getLanguages } from "@/API/repositories/language";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import SelectInput from "@/common/components/SelectInput";

const SpeechToTextSearchBar = ({ setText }) => {
  const [languageOptions, setLanguageOptions] = useState();
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState();
  const [selectedLanguageTo, setSelectedLanguageTo] = useState();

  const fileRef = useRef();

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleConvertSpeechToText = async () => {
    setText(() => null);

    if (
      !fileRef.current.files?.length ||
      !selectedLanguageFrom ||
      !selectedLanguageTo
    ) {
      return addMessage("Fill in all required data", "error");
    }

    const formData = new FormData();

    formData.append("file", fileRef.current.files[0]);
    formData.append("language_from", selectedLanguageFrom.value);
    formData.append("language_to", selectedLanguageTo.value);

    const response = await makeRequest(
      convertSpeechToText.bind(null, formData)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setText(() => response.data);
  };

  const loadData = async () => {
    const response = await makeRequest(getLanguages);

    if (response?.data) {
      setLanguageOptions(() =>
        handleMapToOptions(response.data, "label", "short")
      );
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card>
      {hasUnfilledRequest(convertSpeechToText, getLanguages) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <SelectInput
        width={140}
        name="Language from"
        color={Colors.darkBlue}
        options={languageOptions}
        selected={selectedLanguageFrom}
        setSelected={setSelectedLanguageFrom}
      />
      <SelectInput
        width={140}
        name="Language to"
        color={Colors.darkBlue}
        options={languageOptions}
        selected={selectedLanguageTo}
        setSelected={setSelectedLanguageTo}
      />
      <Input
        type="file"
        width={140}
        name="Recording"
        color={Colors.darkBlue}
        inputRef={fileRef}
        accept=".mp3,audio/*"
      />
      <ButtonWrapper>
        <CustomButtonAdd
          onClick={() => handleConvertSpeechToText()}
          defaultText="Convert to text"
        />
      </ButtonWrapper>
    </Card>
  );
};

export default SpeechToTextSearchBar;
