import styled from "styled-components";

export const BellWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
`;

export const BellIcon = styled.i`
  color: ${(props) => props.color};
  font-size: 16px;
  position: relative;

  ::after {
    content: "${(props) => (props.amount ? props.amount : "")}";
    color: ${(props) => props.color};
    font-size: 12px;
    top: -8px;
    left: 12px;
    position: absolute;
  }
`;
