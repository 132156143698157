import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TicketsListWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row-start: 2;
  grid-row-end: ${(props) => props.gridRawEnd};
  overflow: scroll;
  background-color: ${Colors.lightGray};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MessageItem = styled.div`
  padding: 8px 28px;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${Colors.newLightColor};
`;

export const MessageInfoWrapper = styled.div`
  width: 50%;
`;

export const MessageSender = styled.p`
  display: flex;
  gap: 2px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  align-items: center;
`;

export const MessageText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
`;

export const OnlineRound = styled.span`
  background-color: ${(props) => props.backgroundColor};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const MessageStatus = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 4px 10px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  background-color: ${(props) => props.bgColor};
`;

export const PriorityWrapper = styled.div`
  position: absolute;
  transform: translate(calc(-100% - 4px), 4px);
  background-color: ${Colors.red};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
