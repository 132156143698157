import React, { createContext, useState } from "react";

export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
      }}
    >
        {children}
    </AuthContext.Provider>
  );
};