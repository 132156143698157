import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const TodoListContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 80, 114, 0.78);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const TodoListCloser = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const TodoListWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 85vh;
  width: 85vw;
  border-radius: 10px;
  overflow: scroll;
  background-color: ${Colors.lightGray};
  overflow-x: hidden;
`;

export const TodoItemsWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 18px;
  min-height: calc(100% - 40px);
`;

export const AddTodoButtonWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  bottom: 50px;
  height: 0;
  z-index: 3;
`;

export const AddTodoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Colors.darkBlue};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.9;
    transform: scale(0.9);
  }
`;

export const CustomSkeletonWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 20px;
  left: 20px;
  overflow: hidden;
`;

export const TodoStatusWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
