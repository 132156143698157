import { calcConsultantHours } from "./calcConsultantHours";
import { round } from "../../../helpers/round";

export const calcHoursPayment = (consultantResults) => {
  const result = round(
    consultantResults?.data[0]._consultant_hours.reduce(
      (prev, next) => prev + calcConsultantHours(next),
      0
    ) * consultantResults?._month_working_info.hourly_rate
  );

  return consultantResults._month_working_info.contract_type
    ?.split("_")[0]
    ?.toLowerCase() === "ro"
    ? Math.min(result, 4200)
    : result;
};
