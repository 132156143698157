import Icon from "@/common/components/Icon";

export const getRaws = (dynamicMailAttachments, marketsOptions, handleSetDynamicMailAttachmentEdit) =>
  dynamicMailAttachments?.map((mailAttachment, i) => (
    <tr key={mailAttachment._id}>
      <td>{i + 1}</td>
      <td>{mailAttachment.title}</td>
      <td>{marketsOptions.find(m => m.value === mailAttachment.market)?.label}</td>
      <td>
        <Icon
          onClick={() => handleSetDynamicMailAttachmentEdit(mailAttachment)}
          name="fa fa-edit"
        />
      </td>
    </tr>
  ));
