import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;

const ENTITY_NAME = "mail-recruitment-category";

export const getCandidatesToMailByQuery = (filters) =>
  errorHandler(async () => {
    const data = await api.post(`/mail/candidates-to-mail-by-query/`, filters);
    return { data: data.data };
  });

export const getMailRecruitmentCategories = () =>
  errorHandler(async () => {
    const data = await api.get(`/${ENTITY_NAME}/`);
    return { data: data.data };
  });

export const updateMailRecruitmentCategory = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/${ENTITY_NAME}/${payload._id}`, payload);
    return { data: data.data };
  });

export const createMailRecruitmentCategory = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}`, payload);
    return { data: data.data };
  });
  
export const testRecruitmentCategoryEmail = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${ENTITY_NAME}/test-mail`, payload);
    return { data: data.data };
  });
