import { getPercent } from "./getPercent";
import { getUserGoal } from "./getUserGoal";

export const getStars = ({ user, gamificationUserStats, markets, queues }) => {
  const goal = getUserGoal({ gamificationUserStats, markets, queues, user });

  const {
    total_amount,
    delivered_amount,
    stars_percents,
    package_percents,
    listening_target,
    average_listening_score,
  } = gamificationUserStats;

  const sortedStarsPercents = stars_percents.sort(
    (starsPercentA, starsPercentB) => starsPercentB.stars - starsPercentA.stars
  );

  const sortedPackagePercents = package_percents.sort(
    (packagePercentA, packagePercentB) =>
      packagePercentB.stars - packagePercentA.stars
  );

  const confirmedStars = sortedStarsPercents.reduce((acc, step) => {
    if (step.percents <= getPercent(total_amount, goal)) {
      acc++;
    }

    return acc;
  }, 0);
  const deliveredStars = sortedPackagePercents.reduce((acc, step) => {
    if (step.percents <= getPercent(delivered_amount, total_amount)) {
      acc++;
    }

    return acc;
  }, 0);

  const hasExtraStar = average_listening_score >= listening_target;

  return hasExtraStar
    ? Math.min(deliveredStars, confirmedStars) + 1
    : Math.min(deliveredStars, confirmedStars);
};
