import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const CommunicationWrapper = styled.form`
  grid-row: 6 / 7;
  grid-column: 2 / 3;
  background-color: ${Colors.lightGray};
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  padding: 18px;
  position: relative;
`;

export const SplitLine = styled.div`
  width: 1px;
  height: 75%;
  padding: 10px 0;
  background-color: ${Colors.darkGray};
  position: absolute;
  left: 0px;
`;

export const AttachIcon = styled.label`
  cursor: pointer;
  font-size: 22px;
  color: ${Colors.newGray};
`;

export const FileInputWrapper = styled.div`
  flex-grow: 1;
  display: ${(props) => props.display};
  margin-left: 30px;
`;

export const RichTextEditorWrapper = styled.div`
  max-height: 140px;
  overflow: auto;
  width: 100%;
`;

export const FileInputNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FileInput = styled.input`
  border: 0;
  font-size: 12px;
  display: block;

  ::file-selector-button {
    display: none;
  }
`;

export const TextArea = styled.textarea`
  flex-grow: 1;
  font-size: 14px;
  background-color: ${(props) =>
    props.isDisabled ? Colors.lightGray : "white"};
  border: 1px solid ${Colors.darkGray};
  border-radius: 8px;
  min-height: 80px;
  max-height: 80px;
  resize: none;

  ::placeholder {
    font-size: ${(props) =>
    props.isDisabled ? "20px" : "16px"};
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  :focus {
    outline: none;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SendButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-size: 22px;
  color: ${Colors.purple};

  :focus {
    outline: none;
  }
`;
