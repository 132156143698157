export const sortHours = (hours) => {
  hours.sort((a, b) => {
    const [aHour, aMinute] = a.hour_time.split(":").map(Number);
    const [bHour, bMinute] = b.hour_time.split(":").map(Number);

    if (aHour !== bHour) {
      return aHour - bHour;
    } else {
      return aMinute - bMinute;
    }
  });

  return hours;
};
