import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./InvoiceTemplates.styled";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import TemplateForm from "./components/templateForm/TemplateForm";
import Loading from "@/common/components/Loading";
import {
  createInvoiceTemplate,
  getAllInvoiceTemplates,
  updateInvoiceTemplate,
} from "@/API/repositories/invoiceTemplate";
import TemplateTable from "./components/templateTable/TemplateTable";

const InvoiceTemplates = () => {
  const [templates, setTemplates] = useState();
  const [isTemplateFormOpen, setIsTemplateFormOpen] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const loadData = async () => {
    const response = await makeRequest(getAllInvoiceTemplates);

    if (!response?.data) {
      return addMessage("No data");
    }

    setTemplates(() => response.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        createInvoiceTemplate,
        updateInvoiceTemplate,
        getAllInvoiceTemplates
      ) && <Loading />}
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      <ContentWrapper>
        <CustomButtonAdd
          style={{ height: "fit-content", width: "fit-content" }}
          onClick={() => setIsTemplateFormOpen(() => true)}
          defaultText="Add invoice template"
        />
        {!!templates?.length && (
          <TemplateTable
            loadData={loadData}
            addMessage={addMessage}
            templates={templates}
          />
        )}
        {isTemplateFormOpen && (
          <TemplateForm
            loadData={loadData}
            addMessage={addMessage}
            setShow={setIsTemplateFormOpen}
          />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default InvoiceTemplates;
