export const OPTIONS = [
  {
    label: "Resign before sent",
    value: "resign_before_sent",
  },
  {
    label: "Resign after sent",
    value: "resign_after_sent",
  },
  {
    label: "All resigns",
    value: "all",
  },
];
