import React from "react";
import { Colors } from "../../../../../common/colors/colors";
import styled from "styled-components";
import SelectInput from "../../../../../common/components/SelectInput";
import PopUp from "../../../../../common/components/PopUp";

const Names = styled.div`
  min-width: 220px;
  color: ${Colors.darkBlue};
  font-size: 22px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  gap: 10px;
  p {
    font-weight: normal;
  }
`;

const ItemsPicker = ({
  translation,
  product,
  chosenVariants,
  setAddVariant,
  hadnleDelteProductItem,
  market,
}) => {
  const getVariantName = (variantName) => {
    return (
      product.variants.find((v) => v.short === variantName)?.name || "-----"
    );
  };
  return (
    <form style={{ width: "550px" }}>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Names>
            <p>{translation.product}:</p> {product.name}
          </Names>
          <i
            className="fa-solid fa-plus animation-scale"
            style={{
              color: Colors.darkBlue,
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => setAddVariant(() => true)}
          />
        </div>
        {chosenVariants.length > 0 && (
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <table className="styled-table">
              <thead>
                <tr>
                  <th>{translation.no}</th>
                  <th style={{ minWidth: "140px" }}>{translation.variant}</th>
                  <th style={{ minWidth: "140px" }}>{translation.short}</th>
                  <th style={{ minWidth: "150px" }}>{translation.price}</th>
                  <th>{translation.action}</th>
                </tr>
              </thead>
              <tbody className="queue">
                {chosenVariants.map((data, i) => (
                  <tr>
                    <td style={{ color: Colors.darkBlue }}>{i + 1}.</td>
                    <td style={{ color: Colors.darkBlue }}>
                      {getVariantName(data.product_variant)}
                    </td>
                    <td style={{ color: Colors.darkBlue }}>
                      {data.product_variant}
                    </td>
                    <td style={{ fontWeight: "bold", color: Colors.darkBlue }}>
                      {data.price} {market.currency}
                    </td>
                    <td>
                      <i
                        class="fa fa-remove animation-scale"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => hadnleDelteProductItem(e, i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </form>
  );
};

export default ItemsPicker;
