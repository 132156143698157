import { compareDates } from "@/common/functions/compareDates";
import { findClaimStatusConfig } from "./findClaimConfigs";
import { checkHighPriority } from "./checkHighPriority";
import { CLAIM_STATUSES } from "@/common/constants/claims";
import { checkOverdue } from "./checkOverdue";

export const sortValidClaims = (validClaims) => {
  return validClaims
    .sort((a, b) => {
      const aDate = new Date(a.transfers?.at(-1)?.completed_at || a.created_at);
      const bDate = new Date(b.transfers?.at(-1)?.completed_at || b.created_at);

      return compareDates(bDate, aDate)
    })
    .sort((a, b) => {
      if (a.status === CLAIM_STATUSES.PAID.value && b.status === CLAIM_STATUSES.PAID.value) return 0;

      const isAHighPriority = checkHighPriority(a);
      const isBHighPriority = checkHighPriority(b);

      if (isAHighPriority && !isBHighPriority) return -1;

      if (!isAHighPriority && isBHighPriority) return 1;

      const isAOverdue = checkOverdue(a);
      const isBOverdue = checkOverdue(b);

      if (isAOverdue && !isBOverdue) return -1;

      if (!isAOverdue && isBOverdue) return 1;

      return 0;
    })
    .sort((a, b) => {
      if (a.status === b.status) return 0;

      return findClaimStatusConfig(b.status)?.priority - findClaimStatusConfig(a.status)?.priority
    })
    .sort((a, b) => a._contact.country.localeCompare(b._contact.country))
}