import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const SearchBar = styled.form`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px;
  border: 1px solid ${Colors.darkBlue};
  border-radius: 20px;
  width: fit-content;
  margin: 0 auto 20px;
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const TitlePopUp = styled.h4`
  color: ${Colors.darkBlue};
  font-weight: bold;
  margin-bottom: 20px;
`;

export const MessagesBox = styled.div`
  padding: 20px 10px;
  width: 460px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${Colors.darkBlue};
`;

export const UserWrapper = styled.p`
  font-size: 14px;
  margin-right: 10px;
  width: 120px;
  text-align: left;
`;

export const FileUploadWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;
