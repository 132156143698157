import { generateTextToSpeach } from "@/API/repositories/transcription";
import { Colors } from "@/common/colors/colors";
import ButtonCustom from "@/common/components/ButtonCustom";
import CreateButton from "@/common/components/CreateButton";
import Input from "@/common/components/Input";
import Loading from "@/common/components/Loading";
import MediaPlayer from "@/common/components/MediaPlayer";
import PopUp from "@/common/components/PopUp";
import TextArea from "@/common/components/TextArea";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { ButtonSaveWrapper } from "@/common/styles/Mails";
import React, { useRef, useState } from "react";

const TextToSpeach = ({ setShow }) => {
  const [recording, setRecording] = useState();

  const textAreaRef = useRef();
  const languageRef = useRef();
  const audioDeviceProfileRef = useRef();
  const voiceTypeRef = useRef();
  const voiceNameRef = useRef();
  const speedRef = useRef();
  const pitchRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleGenerate = async (e) => {
    e?.preventDefault();

    const payload = {};
    payload.text = textAreaRef.current.value;
    payload.audioDeviceProfile = audioDeviceProfileRef.current.value;
    payload.language = languageRef.current.value;
    payload.voiceType = voiceTypeRef.current.value;
    payload.voiceName = voiceNameRef.current.value;
    payload.speed = speedRef.current.value;
    payload.pitch = pitchRef.current.value;

    const response = await makeRequest(
      generateTextToSpeach.bind(null, payload)
    );

    if (response.data) {
      setRecording(() => null);
      const blob = new Blob([new Uint8Array(response.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(url);
    }
  };

  return (
    <>
      {hasUnfilledRequest(generateTextToSpeach) && <Loading />}
      <PopUp setShow={setShow}>
        <form onSubmit={handleGenerate}>
          <h3 style={{ color: Colors.darkBlue, marginBottom: "20px" }}>
            Text to speach conversion:
            <a
              href="https://cloud.google.com/text-to-speech#demo"
              style={{ marginLeft: "20px" }}
            >
              Link to settings
            </a>
          </h3>
          <TextArea label="Text" textAreaRef={textAreaRef} required />
          <Input
            inputRef={languageRef}
            name="Language"
            required
            width={250}
            color={Colors.darkBlue}
            inputWidth={250}
            requiredSign
          />
          <Input
            inputRef={voiceTypeRef}
            name="Voice type"
            required
            width={250}
            color={Colors.darkBlue}
            requiredSign
            inputWidth={250}
          />
          <Input
            inputRef={voiceNameRef}
            name="Voice name"
            required
            requiredSign
            color={Colors.darkBlue}
            width={250}
            inputWidth={250}
          />
          <Input
            inputRef={audioDeviceProfileRef}
            name="Audio device profile"
            required
            requiredSign
            width={250}
            inputWidth={250}
            color={Colors.darkBlue}
            value={"headphone-class-device"}
          />
          <Input
            inputRef={speedRef}
            name="Speed"
            required
            width={250}
            requiredSign
            value={1}
            color={Colors.darkBlue}
            inputWidth={250}
          />
          <Input
            inputRef={pitchRef}
            name="Pitch"
            required
            width={250}
            inputWidth={250}
            requiredSign
            value={0}
            color={Colors.darkBlue}
          />
          <div style={{ display: "flex", justifyContent: "right" }}>
            <CreateButton name={"Voice"} type="submit" onClick={() => {}} />
          </div>
        </form>
        {recording && (
          <MediaPlayer recording={recording} setClose={setRecording} />
        )}
      </PopUp>
    </>
  );
};

export default TextToSpeach;
