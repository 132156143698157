import React, { useEffect, useState } from "react";
import { getCCEffectiveSalaryPaymentKDR } from "../../../API/repositories/reports";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { handleMapToOptions } from "../../../common/functions/handleMapToOptions";
import Loading from "../../../common/components/Loading";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import { generateMainData } from "./helpers/generateMainData";
import { generateSHortInfoData } from "./helpers/generateShortInfoData";
import CCPyamentKDRSearchBar from "./components/ccPaymentKDRSearchBar/CCPyamentKDRSearchBar";
import ShortInfoDataTable from "./components/shortInfoDataTable/ShortInfoDataTable";
import CCPaymentReportMainTable from "./components/ccPaymentReportMainTable/CCPaymentReportMainTable";
import { COMPANIES_OPTIONS } from "@/common/constants/Accounting";

const CCPaymentKDR = () => {
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);
  const [consultantsFilterOption, setConsultantFilterOption] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState();
  const [mainReportData, setMainReportData] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [shortInfoData, setShortInfoData] = useState();
  const [selectedCompany, setSelectedCompany] = useState(COMPANIES_OPTIONS[0]);

  const {
    commonData: { users, markets, queues },
  } = useCommonDataContext();

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getCCEffectiveSalaryPaymentKDR.bind(
        null,
        selectedMonth.value.toISOString(),
        selectedCompany.value
      )
    );

    if (response.data && users[0]) {
      const usersFromReport = response.data.result.map((result) => result._id);
      const result = users.filter((user) => usersFromReport.includes(user._id));
      const usersOptions = handleMapToOptions(result, "username", "_id");

      setConsultantFilterOption(() => usersOptions);
      setSelectedConsultant(() => usersOptions);

      const mainData = generateMainData({
        data: response.data,
        markets,
        queues,
        users,
      });

      setShortInfoData(() => generateSHortInfoData(mainData));
      setMainReportData(() => mainData);
    }
  };

  useEffect(() => {
    hanldeSearch();
  }, [users]);

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getCCEffectiveSalaryPaymentKDR) && <Loading />}
      <CCPyamentKDRSearchBar
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        hanldeSearch={hanldeSearch}
        consultantsFilterOption={consultantsFilterOption}
        setSelectedConsultant={setSelectedConsultant}
        selectedConsultant={selectedConsultant}
        setSelectedCompany={setSelectedCompany}
        selectedCompany={selectedCompany}
      />
      {shortInfoData &&
        shortInfoData.length > 0 &&
        users &&
        shortInfoData.map((data) => <ShortInfoDataTable data={data} />)}
      {mainReportData && mainReportData.length > 0 && (
        <div style={{ overflowX: "auto", maxWidth: "80vw" }}>
          <CCPaymentReportMainTable
            mainReportData={mainReportData}
            selectedConsultant={selectedConsultant}
          />
        </div>
      )}
    </WrapperWithScroll>
  );
};

export default CCPaymentKDR;
