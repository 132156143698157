import { Colors } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { getToday } from "@/common/functions/getToday";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import React, { useMemo } from "react";
import { HEADERS } from "../sentOrdersTable/constants/headers";
import ToggleSwitch from "@/common/components/ToggleSwitch";

const GroupOptions = [
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
];

const SentOrdersSearchBar = ({
  dateFromRef,
  dateToRef,
  handleSearch,
  selectedProduct,
  setSelectedProduct,
  selectedLangPair,
  setSelectedLangPair,
  selectedType,
  setSelectedType,
  results,
  onlyRef,
  monthlyRef,
}) => {
  const {
    commonData: { products },
  } = useCommonDataContext();
  const today = getToday();

  const variantsOptions = useMemo(() => {
    if (!selectedProduct) {
      return [];
    }
    setSelectedLangPair(() => []);
    return selectedProduct.value.variants.map((v) => ({
      label: `${v.name} (${v.short})`,
      value: v.short,
    }));
  }, [products, selectedProduct]);

  return (
    <SearchBarCenter>
      <div>
        <Input
          name="Delivered date From"
          width={200}
          color={Colors.darkBlue}
          inputRef={dateFromRef}
          type="date"
          value={today}
          required
          requiredSign
          inputWidth={140}
        />
        <Input
          name="Delivered date to"
          width={200}
          color={Colors.darkBlue}
          inputRef={dateToRef}
          type="date"
          value={today}
          inputWidth={140}
          requiredSign
          required
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Option"
          options={GroupOptions}
          width={200}
          setSelected={setSelectedType}
          selected={selectedType}
          selectWidth={140}
          required
        />
        <ToggleSwitch toggleRef={onlyRef} text={"Only lang pair"} width={210} />
        <div style={{ marginTop: "10px" }}>
          <ToggleSwitch toggleRef={monthlyRef} text={"Sum"} width={210} />
        </div>
      </div>
      <div>
        <SelectInput
          color={Colors.darkBlue}
          name="Product"
          options={products.map((p) => ({ label: p.name, value: p }))}
          width={100}
          setSelected={setSelectedProduct}
          selected={selectedProduct}
          required
        />
        <SelectInput
          multiple={true}
          color={Colors.darkBlue}
          name="Lang Pairs"
          options={variantsOptions}
          width={100}
          setSelected={setSelectedLangPair}
          selected={selectedLangPair}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {results.length > 0 && (
            <CSV header={HEADERS(monthlyRef.current.checked)} data={results} />
          )}
          <button className="btn btn-warning" onClick={(e) => handleSearch(e)}>
            Search
          </button>
        </div>
      </div>
    </SearchBarCenter>
  );
};

export default SentOrdersSearchBar;
