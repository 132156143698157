import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  acceptConsultantMonthHours,
  getHoursForCCManager,
  saveConsultantHoursByManager,
} from "@/API/repositories/consultantHours";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { saveAs } from "@/common/functions/saveAs";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import {
  TableWrapper,
  Wrapper,
} from "./CCManagerHours.styled";
import ConsultantMonth from "./components/consultantMonth/ConsultantMonth";
import AddHoursForAny from "./components/consultantMonth/components/addHoursForAny/AddHoursForAny";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { getPdfsByIds } from "@/API/repositories/storedDocument";
import userManager from "@/API/userManager";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { extractConsultantIds, filterMonthHourConsultants } from "@/common/functions/consultantMonthFilesHelpers";
import HoursTable from "@/common/components/hoursTable/HoursTable";
import HoursSearchBar from "@/common/components/hoursSearchBar/HoursSearchBar";
const JSZip = require("jszip");
const zip = new JSZip();

const CCManagerHours = () => {
  const [filterRegex, setFilterRegex] = useState();

  const [data, setData] = useState();
  const [consultantsFull, setConsultantFull] = useState();
  const [selectedConsultant, setSelectedConsultants] = useState();
  const [isAddHoursForAnyOpen, setIsAddHoursForAnyOpen] = useState();
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[0]);
  const [showConsultantMonth, setShowConsultantMonth] = useState(null);

  const acceptedRef = useRef();

  const {
    commonData: { users, markets },
    queryValues: { isLoading },
  } = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage, removeMessage, messages } = useMessageQueue();

  const currentUserId = userManager.getUser().id;

  const consultants = useMemo(() => {
    return handleMapToOptions
    (
      users?.filter(user => user.managers?.some(m => m.manager === currentUserId)) || [],
      'username',
      '_id'
    );
  }, [users, currentUserId]);

  const handleSetDefaultData = () => {
    setSelectedConsultants(consultants);
    setConsultantFull(users.filter(u => consultants.some(c => c.value === u._id)));
  };

  const hanldeSearch = async (e, shouldFindSelected = false) => {
    e && e.preventDefault();
    const payload = {};
    payload.month = selectedMonth.value.startOf("month");
    payload.monthFormated = moment(selectedMonth.value)
      .format("MMMM_YYYY")
      .toUpperCase();
    payload.accepted = acceptedRef.current?.checked || false;

    const response = await makeRequest(
      getHoursForCCManager.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);

      response.data.forEach((hours, i) => {
        const foundTicket = hours.data.some((hour) => hour._ticket);

        response.data[i].foundTicket = foundTicket;
      });

      if (shouldFindSelected) {
        setShowConsultantMonth(() =>
          response.data.find((d) => d._id === showConsultantMonth._id)
        );
      }
    }
  };

  const handleGetAllMonthFiles = async () => {
    if (!data) {
      return;
    }

    const selectedConsultantsIds = selectedConsultant.map((c) => c.value);
    const ids = extractConsultantIds(
      filterMonthHourConsultants(data, filterRegex, selectedConsultantsIds)
    )
    const query = { ids };

    const response = await makeRequest(getPdfsByIds.bind(null, query));

    if (response.data) {
      const files = response.data;

      files.forEach((file) => {
        zip.file(file.name, file.data.data);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(
          content,
          `documents_${selectedMonth.label
            .split(" ")
            .join("_")
            .toLowerCase()}.zip`
        );
      });

      files.forEach((file) => {
        zip.remove(file.name);
      });
    }
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 1) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  useEffect(() => {
    handleSetDefaultData();
  }, [consultants]);

  return (
    <Wrapper>
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      {(hasUnfilledRequest(
        getHoursForCCManager,
        acceptConsultantMonthHours,
        saveConsultantHoursByManager,
        getPdfsByIds
      ) ||
        isLoading) && <Loading />}
      <HoursSearchBar
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        handleChangeRegex={handleChangeRegex}
        acceptedRef={acceptedRef}
        consultants={consultants}
        selectedConsultant={selectedConsultant}
        setSelectedConsultants={setSelectedConsultants}
        hanldeSearch={hanldeSearch}
        setIsAddHoursForAnyOpen={setIsAddHoursForAnyOpen}
        handleGetAllMonthFiles={handleGetAllMonthFiles}
        data={data}
      />
      {data &&
        consultants &&
        data.length > 0 &&
        selectedConsultant.length > 0 && (
          <TableWrapper>
            <HoursTable
              data={data}
              filterRegex={filterRegex}
              selectedConsultant={selectedConsultant}
              consultants={consultants}
              setShowConsultantMonth={setShowConsultantMonth}
              selectedMonth={selectedMonth}
              acceptedRef={acceptedRef}
            />
          </TableWrapper>
        )}
      {showConsultantMonth && (
        <ConsultantMonth
          selectedMonth={selectedMonth}
          showConsultantMonth={showConsultantMonth}
          setShowConsultantMonth={setShowConsultantMonth}
          consultants={consultants}
          consultantsFull={consultantsFull}
          hanldeSearch={hanldeSearch}
        />
      )}
      {isAddHoursForAnyOpen && (
        <AddHoursForAny
          monthName={selectedMonth.value}
          setIsAddHoursForAnyOpen={setIsAddHoursForAnyOpen}
          hanldeSearch={hanldeSearch}
          addMessage={addMessage}
        />
      )}
    </Wrapper>
  );
};

export default CCManagerHours;
