import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { useRef, useState, useMemo } from "react";
import styled from "styled-components";
import {
  createMailingReport,
  deleteMailingReport,
  updateMailingReport,
} from "@/API/repositories/mailing-report";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import { useMessageQueue } from "@/common/messageProvider";
import SelectInput from "@/common/components/SelectInput";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import RecipientsList from "./recipientsList/RecipientsList";
import { REPORT_TYPE_OPTIONS } from "../../constants/report-types";
import AddRecipientPopUp from "./addRecipientPopUp/AddRecipientPopUp";
import {
  FormHeader,
  SelectTypeContainer,
  SubjectInputContainer,
  RecipientsListContainer,
  CronContainer,
  ButtonsContainer,
  SwitchContainer,
  DeleteButtonContainer,
  InfoText,
} from "./MailingReportForm.styles";
import CustomButtonError from "@/common/components/buttons/CustomButtonError";
import DeleteReportPopUp from "./deleteReportPopUp/DeleteReportPopUp";
import * as moment from "moment-timezone";

const GridForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: auto;
  grid-template-areas:
    "subject subject"
    "type active"
    "recipients recipients"
    "cron cron"
    "delete button";
`;

const MailingReportForm = ({ onClose, selectedReport, loadData }) => {
  const action = selectedReport ? "Update" : "Create";

  const [isAddRecipientPopUpVisible, setIsAddRecipientPopUpVisible] =
    useState(false);
  const [isDeletePopUpVisible, setIsDeletePopUpVisible] = useState(false);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueue();

  const [selectedType, setSelectedType] = useState(selectedReport?.type);
  const subjectRef = useRef();
  const [recipients, setRecipients] = useState(
    selectedReport?.recipients ?? []
  );
  const [cronTime, setCronTime] = useState(
    selectedReport?.cron_time ?? "0 10 * * *"
  );
  const [isActive, setIsActive] = useState(selectedReport?.active ?? false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      type: selectedType,
      subject: subjectRef.current.value,
      recipients: recipients,
      cron_time: cronTime,
      active: isActive,
    };

    const req = selectedReport ? updateMailingReport : createMailingReport;

    const response = await makeRequest(
      req.bind(null, data, selectedReport?._id)
    );

    if (!response?.data) {
      return addMessage("Something went wron", "error");
    }

    loadData();
    onClose();
  };

  const handleSelectType = (option) => {
    setSelectedType(option.value);
  };

  const defaultSelectedOption = selectedType
    ? { label: selectedType, value: selectedType }
    : undefined;

  const handleAddRecipient = (newRecipient) => {
    if (!newRecipient || recipients.find((r) => r === newRecipient)) return;
    setRecipients((prev) => [...prev, newRecipient]);
  };

  const handleDelete = async () => {
    const response = await makeRequest(
      deleteMailingReport.bind(null, selectedReport._id)
    );

    if (!response?.data) {
      return addMessage("Something went wron", "error");
    }

    loadData();
    onClose();
  };

  const timezoneHint = useMemo(
    () =>
      `Example: ${moment({ hour: 11 })
        .tz("UTC")
        .format("HH:mm")} in UTC = ${moment({ hour: 11 })
        .tz("Europe/Warsaw")
        .format("HH:mm")} in Warsaw`,

    []
  );

  return (
    <PopUp setShow={onClose} minWidth="600" width="600">
      {hasUnfilledRequest(updateMailingReport, createMailingReport) && (
        <Loading />
      )}
      <FormHeader>{`${action} mailing report`}</FormHeader>
      <GridForm onSubmit={handleSubmit}>
        <SwitchContainer>
          <ToggleSwitch
            text={"Active:"}
            checked={isActive}
            width={100}
            onChange={() => setIsActive((prevState) => !prevState)}
          />
        </SwitchContainer>

        <SelectTypeContainer>
          <SelectInput
            options={REPORT_TYPE_OPTIONS}
            required
            color={Colors.darkBlue}
            width={120}
            selectWidth={400}
            name="Type"
            setSelected={handleSelectType}
            selected={
              defaultSelectedOption ||
              REPORT_TYPE_OPTIONS.find((o) => o.value === selectedReport?.type)
            }
          />
        </SelectTypeContainer>

        <SubjectInputContainer>
          <Input
            inputRef={subjectRef}
            autoFocus
            required
            requiredSign={true}
            color={Colors.darkBlue}
            inputWidth={600}
            width={120}
            name="Subject"
            value={selectedReport?.subject}
          />
        </SubjectInputContainer>

        <RecipientsListContainer>
          <RecipientsList
            recipients={recipients}
            setRecipients={setRecipients}
          />
        </RecipientsListContainer>

        <CronContainer>
          <Cron
            clearButtonProps={{
              style: {
                backgroundColor: "transparent",
                border: "1px solid orange",
                boxShadow: "none",
                color: "orange",
                fontWeight: "bold",
                borderRadius: 10,
              },
            }}
            value={cronTime}
            setValue={setCronTime}
          />
          <InfoText>
            <p>INFO: Schedules like "Everyday at 10:00" are in UTC.</p>
            <p>{timezoneHint}</p>
          </InfoText>
        </CronContainer>

        <ButtonsContainer>
          <CustomButtonAdd
            color={Colors.orange}
            text={`recipient +`}
            onClick={() => setIsAddRecipientPopUpVisible(true)}
            style={{ height: 35 }}
          />
          <CustomButtonSave
            height={35}
            color={Colors.orange}
            text={`${action} report`}
          />
        </ButtonsContainer>
        {action === "Update" && (
          <DeleteButtonContainer>
            <CustomButtonError
              height={35}
              color={Colors.red}
              defaultText="Delete"
              onClick={() => setIsDeletePopUpVisible(true)}
            />
          </DeleteButtonContainer>
        )}
      </GridForm>
      {isDeletePopUpVisible && (
        <DeleteReportPopUp
          onClose={() => setIsDeletePopUpVisible(false)}
          onDelete={handleDelete}
        />
      )}
      {isAddRecipientPopUpVisible && (
        <AddRecipientPopUp
          onAdd={handleAddRecipient}
          onClose={() => setIsAddRecipientPopUpVisible(false)}
        />
      )}
    </PopUp>
  );
};

export default MailingReportForm;
