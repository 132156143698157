import { errorHandler } from "../common";
import Api from "../api";

const { axios: api } = Api;

export const getOutboundContact = (userId) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_contact`);
    return { data: data.data };
  });

export const getBoundInfo = () =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_current_bound_info`);
    return { data: data.data };
  });

export const removeConsultantFromContact = (boundInfo) =>
  errorHandler(async () => {
    const data = await api.delete(`/queue/deleteBoundInfo/` + boundInfo._id);
    return { data: data.data };
  });

export const createQueue = (queue) =>
  errorHandler(async () => {
    const data = await api.post(`/queue`, queue);
    return { data: data.data };
  });

export const getQueues = () =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_all`);
    return { data: data.data };
  });

export const getListOfQueuesInfos = () =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get-list-of-queues-infos`);
    return { data: data.data };
  });

export const updateQueue = (id, queue) =>
  errorHandler(async () => {
    const data = await api.patch(`/queue/${id}`, queue);
    return { data: data.data };
  });

export const getOneQueue = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_one/${_id}`);
    return { data: data.data };
  });

export const getQueueStackAndContacts = () =>
  errorHandler(async () => {
    const data = await api.get("/queue/queue_stack_and_contacts");

    return { data: data.data };
  });

export const getQueueStack = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/queue_stack/${_id}/1500`);
    return { data: data.data };
  });

export const getQueuePoints = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/points/${_id}`);
    return { data: data.data };
  });

export const getQueueDequeued = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/dequeued/${_id}`);
    return { data: data.data };
  });

export const getDrawnContact = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_contact_queue_information/${_id}`);
    return { data: data.data };
  });

export const markOrdertAsConsultantDecision = (
  consultantId,
  entityId,
  decision
) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/queue/mark_order_as_consultant_decision/${entityId}`,
      {
        consultant: consultantId,
        decision: decision,
      }
    );
    return { data: data.data };
  });

export const createShippingOrder = (orderId, shippingDetails, products) =>
  errorHandler(async () => {
    const data = await api.patch(`/queue/create_shipping/${orderId}`, {
      shipping: shippingDetails,
      products: products,
    });
    return { data: data.data };
  });

export const getQueueCurrentWorkers = (queueId) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/current_workers/${queueId}`);
    return { data: data.data };
  });

export const getUserCurrentContact = (userId) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_current_consultant_bound_info`);
    return { data: data.data };
  });

export const unplugContact = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(`/queue/unplug/${orderId}`);
    return { data: data.data };
  });

export const getCurrentInboundContact = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/current_inbound`, {});
    return { data: data.data };
  });

export const getInboundContact = (phoneNumber) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/get_inbound_contact/` + phoneNumber);
    return { data: data.data };
  });

export const markInBoundCallAsShiftOrder = (orderId, shiftDto) =>
  errorHandler(async () => {
    const data = await api.post(
      `/queue/mark_order_as_shift_from_inbound/` + orderId,
      { shiftDate: shiftDto }
    );
    return { data: data.data };
  });

export const markInBoundCallAsConfirmed = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(
      `/queue/mark_order_as_confirmed_from_inbound/` + orderId
    );
    return { data: data.data };
  });

export const markInboundCallAsResigned = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(
      `/queue/mark_order_as_resigned_from_inbound/` + orderId
    );
    return { data: data.data };
  });

export const markInboundCallAsInformed = (orderId) =>
  errorHandler(async () => {
    const data = await api.post(
      `/queue/mark_order_as_informed_from_inbound/${orderId}`
    );

    return { data: data.data };
  });

export const saveInboundCall = () =>
  errorHandler(async () => {
    const data = await api.get(`/queue/save_inbound_call/`);
    return { data: data.data };
  });

export const getHisotryOrderId = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/queue/order-history-by-id/${_id}`);
    return { data: data.data };
  });

export const markOnlinePaymentAsConfirmed = (orderId) =>
  errorHandler(async () => {
    const data = await api.patch(`/queue/mark-online-payment-as-confirmed/${orderId}`);

    return { data: data.data };
  });

export const getQueuePointsPosibleContacts = (payload, queueId) =>
  errorHandler(async () => {
    const data = await api.post(`/queue/queue-posible-contacts/${queueId}`, {
      ...payload,
    });
    return { data: data.data };
  });
