import MarketsStatisticInbound from "@/components/admin/voip/realtime/MarketsStatisticInbound";
import MarketsStatistics from "@/components/admin/voip/realtime/MarketsStatistics";
import React, { useContext } from "react";
import { RealtimeContext } from "../context/RealtimeContext";

const MarketStats = () => {
  const { marketStatistics, marketStatisticsInbound } =
    useContext(RealtimeContext);

  return (
    <>
      <MarketsStatistics data={marketStatistics} />
      <MarketsStatisticInbound data={marketStatisticsInbound} />
    </>
  );
};

export default MarketStats;
