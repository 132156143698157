import PopUp from "@/common/components/PopUp";
import React from "react";
import {
  TranscirptionTitle,
  TranscriptionText,
  TranscriptionWrapper,
} from "./Transcriptions.styled";

const Transcriptions = ({ transcriptions, setClose }) => {
  return (
    <PopUp zIndex={999} setShow={setClose}>
      {transcriptions.map((transcription) => (
        <TranscriptionWrapper key={transcription.lang}>
          <TranscirptionTitle>{transcription.lang}</TranscirptionTitle>
          <TranscriptionText>{transcription.text}</TranscriptionText>
        </TranscriptionWrapper>
      ))}
    </PopUp>
  );
};

export default Transcriptions;
