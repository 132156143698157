export const getSortedTicketData = (ticket) => {
  const messages = ticket[ticket.type].message;

  const allData = [
    ...(ticket?._stored_documents || []),
    ...(ticket?._holiday_document || []),
    ...messages,
  ];

  const allDataSorted = allData.sort(
    (elementA, elementB) =>
      new Date(elementA.created_at).getTime() -
      new Date(elementB.created_at).getTime()
  );

  return allDataSorted;
};
