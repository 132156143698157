import PopUp from "@/common/components/PopUp";
import React from "react";
import {
  DateWrapper,
  InfoWrapper,
  TimelineDataWrapper,
  TimelineRound,
  Title,
} from "./MailsTimeline.styled";
import { formatDate } from "@/common/functions/dateFormater";
import Card from "@/common/components/Card";
import { compareDates } from "@/common/functions/compareDates";

const MailsTimeline = ({ setShow, mails }) => {
  return (
    <PopUp padding="20px" setShow={setShow}>
      <Title>Mails history</Title>
      <Card>
        <TimelineDataWrapper>
          {!!mails?.length
            ? mails
                .sort((mailA, mailB) =>
                  compareDates(
                    new Date(mailA.created_at),
                    new Date(mailB.created_at)
                  )
                )
                .map((mail, i) => (
                  <DateWrapper key={mail._id}>
                    <InfoWrapper>
                      {formatDate(mail.created_at, "YYYY-MM-DD HH:mm")}
                    </InfoWrapper>
                    <TimelineRound>{i + 1}</TimelineRound>
                    <InfoWrapper width="300px">{mail.mailType}</InfoWrapper>
                    <InfoWrapper>
                      {mail.first_open_at ? "opened" : "not opened"}
                    </InfoWrapper>
                  </DateWrapper>
                ))
            : "No mails history yet"}
        </TimelineDataWrapper>
      </Card>
    </PopUp>
  );
};

export default MailsTimeline;
