import userManager from "@/API/userManager";
import { CLAIM_STATUSES } from "@/common/constants/claims";
import { ROLE_VALUES } from "@/components/admin/settings/user/components/userForm/constants";

export const chekcSubmitDisabled = (claimStatus, claim, fromSearch) => {
  const { role } = userManager.getUser();

  const payDisabledByRole = claim && (role === ROLE_VALUES.SUPPORT);

  const payDisabledByStatus = ![
    CLAIM_STATUSES.TO_PAY.value, 
    CLAIM_STATUSES.PAID_IN_PROGRESS.value, 
    CLAIM_STATUSES.NONE.value
  ].includes(claimStatus);

  const payDisabledBySearch = fromSearch && claimStatus !== CLAIM_STATUSES.NONE.value;

  return payDisabledByStatus || payDisabledByRole || payDisabledBySearch;
}