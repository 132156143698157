import translationManager from "@/API/translationManager";
import Table from "@/common/components/Table";
import React, { useEffect, useState } from "react";
import { getRaws } from "./helpers/getRaws";

const ManagerSummarize = ({ summarizeData }) => {
  const [translation, setTranslation] = useState({
    text1: "Month",
    text2: "Hours",
    text3: "Hours payment",
    text4: "Effective payment",
    text5: "Payment",
    text6: "Equalization",
    text7: "Manager payment summarize",
  });

  const headersArray = [
    [<th colSpan={6}>{translation.text7}</th>],
    [
      <th>{translation.text1}</th>,
      <th>{translation.text2}</th>,
      <th>{translation.text3}</th>,
      <th>{translation.text4}</th>,
      <th>{translation.text5}</th>,
      <th>{translation.text6}</th>,
    ],
  ];

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <Table
      className="styled-table"
      headersArray={headersArray}
      raws={getRaws(summarizeData)}
    />
  );
};

export default ManagerSummarize;
