import moment from "moment";

export const handleDisplayContacts = (contacts, selectedContactFileds) =>
  contacts.map((contact) => {
    let rowToDisplay = [];

    selectedContactFileds.forEach((selected) => {
      let value;

      if (selected.value === "created_at")
        value = moment(contact.created_at).format("YYYY-MM-DD HH:mm:ss");
      else {
        value = contact[selected.value];
      }

      rowToDisplay.push(value);
    });

    return { data: rowToDisplay, _id: contact._id };
  });
