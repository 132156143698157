import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import ToggleSwitch from "@/common/components/ToggleSwitch";
import React, { useRef, useState } from "react";

const SendAfterType = [
  { label: "MINUTES", value: "minutes" },
  { label: "SECONDS", value: "seconds" },
  { label: "HOURS", value: "hours" },
  { label: "DAYS", value: "days" },
];

const PromoTemplateEdit = ({
  edit,
  setEdit,
  templates = [],
  setTemplateConfig,
}) => {
  const [isControllGroup, setIsControllGroup] = useState(edit.isControllGroup);
  const [options, setOptions] = useState(templates);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState(
    () => templates.find((f) => f.value === edit.mailTemplate) || {}
  );
  const [selectedSendType, setSelectedSendType] = useState(
    SendAfterType.find((s) => s.value === edit.sendAfterType) ||
      SendAfterType[0]
  );
  const nameRef = useRef();
  const senderNameRef = useRef();
  const senderEmailRef = useRef();
  const weightRef = useRef();
  const sendAfterRef = useRef({ value: 0 });
  const subjectRef = useRef();
  const promoUrlRef = useRef();

  const handleSave = (e) => {
    e?.preventDefault();

    const payload = {
      ...edit,
      isControllGroup: isControllGroup,
      senderName: senderNameRef?.current?.value,
      senderEmail: senderEmailRef?.current?.value,
      weight: weightRef?.current?.value,
      sendAfter: sendAfterRef?.current?.value,
      sendAfterType: selectedSendType.value,
      mailTemplate: selectedMailTemplate.value,
      name: nameRef?.current?.value,
      subject: subjectRef?.current?.value,
      promoUrl: promoUrlRef?.current?.value,
    };

    if (edit.id) {
      setTemplateConfig((prev) => [
        ...prev.filter((p) => p.id !== edit.id),
        payload,
      ]);
      setEdit(() => false);
      return;
    }

    setTemplateConfig((prev) => [
      ...prev.filter((p, i) => p.id !== edit.id),
      { ...payload, id: prev.length + 1 },
    ]);
    setEdit(() => false);
  };

  return (
    <PopUp setShow={setEdit}>
      <form
        onSubmit={handleSave}
        style={{ minHeight: "600px", display: "flex", flexDirection: "column" }}
      >
        <h3>Mail Config Template {edit.id ? "Edit" : "Create"}</h3>
        <ToggleSwitch
          text={"Is Controll Group"}
          checked={isControllGroup}
          width={150}
          onChange={() => setIsControllGroup((prev) => !prev)}
        />

        <Input
          name="Name"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={nameRef}
          value={edit.name}
        />
        <Input
          name="Promo URL"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={promoUrlRef}
          value={edit.promoUrl}
          disabled={isControllGroup}
        />
        <Input
          name="Subject"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={subjectRef}
          value={edit.subject}
          disabled={isControllGroup}
        />
        <Input
          name="Sender Name"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={senderNameRef}
          value={edit.senderName}
          disabled={isControllGroup}
        />
        <Input
          name="Sender Email"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={senderEmailRef}
          value={edit.senderEmail}
          disabled={isControllGroup}
        />
        <Input
          name="Weight"
          type="number"
          width={150}
          required={true}
          requiredSign={true}
          inputRef={weightRef}
          value={edit.weight}
        />
        <SelectInput
          name={"Template"}
          width={150}
          selectWidth={290}
          required={true}
          options={options}
          selected={selectedMailTemplate}
          setSelected={setSelectedMailTemplate}
          disabled={isControllGroup}
        />
        <div style={{ display: "flex", justifyContent: "left" }}>
          <Input
            name="Send After"
            type="number"
            width={150}
            inputWidth={60}
            required={true}
            requiredSign={true}
            inputRef={sendAfterRef}
            value={edit.sendAfter}
            disabled={isControllGroup}
          />
          <SelectInput
            name={"Send After Type"}
            width={170}
            selectWidth={140}
            required={true}
            options={SendAfterType}
            selected={selectedSendType}
            setSelected={setSelectedSendType}
            disabled={isControllGroup}
          />
        </div>
        <div style={{ flex: "1" }} />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <CustomButtonSave text={"Save"} />
        </div>
      </form>
    </PopUp>
  );
};

export default PromoTemplateEdit;
