import { getMessagesAmount } from "./getMessagesAmount";

export const detectTicketChanged = (ticket, updatedTicket) => {
  if (getMessagesAmount(ticket) !== getMessagesAmount(updatedTicket))
    return true;

  if (ticket?.dw?.length !== updatedTicket?.dw?.length) return true;

  if (ticket?.status !== updatedTicket?.status) return true;

  if (ticket[ticket.type].title !== updatedTicket[updatedTicket.type].title)
    return true;

  if (
    ticket.order !== updatedTicket.order ||
    ticket.contact !== updatedTicket.contact
  )
    return true;

  if (
    ticket.suspicion_document?.first_download_at !==
    updatedTicket.suspicion_document?.first_download_at
  )
    return true;

  if (
    ticket.suspicion_document?.first_transcription_at !==
    updatedTicket.suspicion_document?.first_transcription_at
  )
    return true;

  if (ticket.suspicion?.is_bad !== updatedTicket.suspicion?.is_bad) return true;

  if (ticket.prioritized !== updatedTicket.prioritized) return true;

  return false;
};
