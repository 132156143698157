export const PRIORITIES = [
  {
    value: 'none',
    label: 'none',
  },
  {
    value: 'low_priority',
    label: 'low priority',
  },
  {
    value: 'high_priority',
    label: 'high priority'
  }
]

export const VALID_CLAIM_PRIORITIES = {
  NONE: {
    value: 'none',
    label: 'None',
  },
  HIGH: {
    value: 'high',
    label: 'High',
  }
};

export const PRIORITY_SORT = {
  'high_priority': 0,
  'low_priority': 1,
  'none': 2,
  'undefined': 2,
}