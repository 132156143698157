import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const THead = styled.thead`
  th {
    background-color: #0cd891;
  }
`;

const TBody = styled.tbody`
  td {
    background-color: #d4ffed;
  }
`;

const LoginNow = ({ data = [] }) => {
  const navigate = useNavigate();

  return (
    <table className="styled-table">
      <THead>
        <tr>
          <th colSpan={4}>Logged Users</th>
        </tr>
        <tr>
          <th>Id</th>
          <th>Username</th>
          <th>Logged at</th>
          <th>Actions</th>
        </tr>
      </THead>
      <TBody className="queue">
        {data.length > 0 ? (
          data.map((element, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{element.username}</td>
              <td>{moment(element.login_at).format("DD/MM HH:mm:ss")}</td>
              <td>
                <i
                  className="fa fa-user animation-scale"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/settings/user/show/" + element.consultant)
                  }
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6}>There is no users</td>
          </tr>
        )}
      </TBody>
    </table>
  );
};

export default LoginNow;
