import Table from '@/common/components/Table';
import React from 'react'
import { TableWrapper } from '../../ClaimTag.styled';
import Icon from '@/common/components/Icon';

const ClaimTagTable = ({ tags, setShow, setSelectedTag }) => {

  const headers = ["No.", "Label", "Actions"]
  const raws = tags.map((tag, index) => {
    return (
      <tr 
        key={`${tag._id}`}
      >
        <td>{index + 1}</td>
        <td>{tag.label}</td>
        <td>
          <Icon 
            onClick={() => {
              setShow(true);
              setSelectedTag(tag);
            }}
            name="fa fa-edit"
          />
        </td>
      </tr>
    )
  })
  
  return (
    <TableWrapper>
      <Table stickyHeader className="styled-table" headers={headers} raws={raws} />
    </TableWrapper>
  )
}

export default ClaimTagTable
