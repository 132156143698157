import React from "react";
import {
  ButtonWrapper,
  SearchBarStyle,
} from "../consultantListeningCalls.styles";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";

const SearchBar = ({ formik, translations }) => {
  return (
    <SearchBarStyle
      onSubmit={formik.handleSubmit}
      className="fade__in__animation"
    >
      <Input
        type="date"
        name={translations["From"]}
        id="fromDate"
        value={formik.values.fromDate}
        onChange={formik.handleChange}
        width={100}
        inputWidth={130}
        color={Colors.darkBlue}
        style={{
          fontStyle: "italic",
        }}
        required
        requiredSign
      />
      <Input
        type="date"
        name={translations["To"]}
        id="toDate"
        value={formik.values.toDate}
        onChange={formik.handleChange}
        width={90}
        inputWidth={130}
        color={Colors.darkBlue}
        style={{
          fontStyle: "italic",
        }}
        required
        requiredSign
      />
      <Input
        name={translations["Number"]}
        id="number"
        value={formik.values.number}
        onChange={formik.handleChange}
        width={60}
        inputWidth={220}
        color={Colors.darkBlue}
        placeholder={translations["Type without country code"]}
        style={{
          fontStyle: "italic",
        }}
      />
      <CustomButtonSave text="Search" styles={{ marginBottom: "0" }} />
    </SearchBarStyle>
  );
};

export default SearchBar;
