import { Colors } from "../../../../common/colors/colors";
import styled from "styled-components";

const InfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 180px;
`;

const QueueName = styled.p`
  font-size: 14px;
`;

const formatToWorking = (seconds) => {
  const hours = parseInt(seconds / 60 / 60);
  const minutes = parseInt((seconds / 60) % 60);
  const restSeconds = parseInt(seconds % 60);

  return (
    <h3 style={{ color: Colors.darkBlue }}>
      {hours < 10 ? "0" + hours : hours}:
      {minutes < 10 ? "0" + minutes : minutes}:
      {restSeconds < 10 ? "0" + restSeconds : restSeconds}
    </h3>
  );
};

const TimeInfo = ({ image, seconds, name }) => {
  return (
    <InfoWrapper>
      <img src={image} width={40} height={40} />
      <div>
        <QueueName>{name}</QueueName>
        {formatToWorking(seconds)}
      </div>
    </InfoWrapper>
  );
};

export default TimeInfo;
