import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getSpecialMarketStatistcs } from "@/API/repositories/voip";
import { Colors } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { CONSULTANT_ROLES } from "@/components/loginPage/LoginForm/loginForm";
import { CALLING_DIRECTION } from "@/common/constants/statuses";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import OutgoingTable from "./components/outgoingTable/OutgoingTable";
import IncomingTable from "./components/incomingTable/IncomingTable";
import { getTodayName } from "@/common/functions/getTodayName";
import CustomButtonAdd from "@/common/components/buttons/CustomButtonAdd";
import { PageWrapper } from "@/common/styles/Wrappers";
import { SearchBarRaw } from "@/common/styles/SearchBars";
import { getToday } from "@/common/functions/getToday";
import { formatDatesPayload } from "@/common/functions/formatDatesPayload";
import { formatDateRef } from "@/common/functions/formatDateRef";

const VoipStatistcs = () => {
  const [selctedUsers, setSelectedUsers] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [usersOptions, setUsersOptions] = useState();
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [data, setData] = useState();
  const [showCSV, setShowCSV] = useState(false);
  const fromRef = useRef();
  const toRef = useRef();
  const today = getToday();

  const { options, filterUsersByRoles, commonData } = useCommonDataContext();
  const { users } = commonData;
  const { queuesOptions } = options;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e && e.preventDefault();
    setShowCSV(() => false);

    if (selctedUsers.length === 0 || !selectedDirection?.value) {
      return;
    }

    const payload = formatDatesPayload({
      to: formatDateRef(toRef, today, "start"),
      from: formatDateRef(fromRef, today, "end"),
      consultants: selctedUsers.map((s) => s.value),
      direction: selectedDirection.value,
    });

    const response = await makeRequest(
      getSpecialMarketStatistcs.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleSelectedQueues = (queue) => {
    let selectedUsersIds = [];

    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedUsers(() =>
      usersOptions.filter((c) => selectedUsersIds.includes(c.value))
    );

    setSelectedQueues(() => queue);
  };

  const loadData = async (e) => {
    e && e.preventDefault();

    setUsersOptions(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  const handleSelectedDirection = (e) => {
    setData(() => null);

    setSelectedDirection(() => e);
  };

  const getCSVDATA = () => {
    let result = [];

    if (!data) return;

    data.forEach((d) => {
      result = [...result, ...d.csv];
    });

    return result;
  };

  useEffect(() => {
    loadData();
  }, [users]);

  return (
    <PageWrapper>
      <SearchBarRaw>
        <div style={{ minWidth: "400px" }}>
          <SelectInput
            showLabel={false}
            selected={selctedUsers}
            setSelected={setSelectedUsers}
            options={usersOptions}
            width={80}
            selectWidth={360}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select user..."
          />
        </div>
        <div>
          <SelectInput
            showLabel={false}
            selected={selectedQueues}
            setSelected={handleSelectedQueues}
            options={queuesOptions}
            width={80}
            selectWidth={180}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select market..."
          />
          <SelectInput
            showLabel={false}
            selected={selectedDirection}
            setSelected={handleSelectedDirection}
            options={CALLING_DIRECTION}
            width={80}
            selectWidth={180}
            color={Colors.darkBlue}
            placeholder="Select inbound"
          />
        </div>
        <div>
          <Input
            name="From"
            type="date"
            inputWidth={160}
            color={Colors.darkBlue}
            width={80}
            inputRef={fromRef}
            value={today}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="date"
            width={80}
            inputWidth={160}
            color={Colors.darkBlue}
            value={today}
          />
        </div>
        <div>
          <CustomButtonAdd
            defaultText="Search"
            onClick={(e) => handleSearch(e)}
          />
        </div>
        {showCSV && (
          <div>
            <CSV
              header={["date", "consultant", "status", "from", "to"]}
              data={getCSVDATA()}
              filename={`WORKING_VOIP_CSV_${getTodayName(fromRef, toRef)}.csv`}
            />
          </div>
        )}
      </SearchBarRaw>
      {data && selectedDirection?.value === "outgoing" && (
        <OutgoingTable data={data} />
      )}
      {data && selectedDirection?.value === "incoming" && (
        <IncomingTable data={data} />
      )}
      {hasUnfilledRequest(getSpecialMarketStatistcs) && <Loading />}
    </PageWrapper>
  );
};

export default VoipStatistcs;
