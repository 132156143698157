import { useRef } from "react";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import CustomButtonSave from "@/common/components/buttons/CustomButtonSave";

const AddRecipientPopUp = ({ onAdd, onClose }) => {
  const emailRef = useRef();

  const handleAddRecipient = () => {
    onAdd(emailRef.current.value);
    onClose();
  };

  return (
    <PopUp setShow={onClose}>
      <form onSubmit={handleAddRecipient}>
        <Input
          inputRef={emailRef}
          required
          requiredSign={true}
          color={Colors.darkBlue}
          inputWidth={300}
          width={150}
          type="email"
          name="Recipient email"
          autoFocus
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "0 10px 0 0",
          }}
        >
          <CustomButtonSave
            color={Colors.orange}
            text={`Add recipient`}
          />
        </div>
      </form>
    </PopUp>
  );
};

export default AddRecipientPopUp;
